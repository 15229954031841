import React, { Component } from "react";
import { Chart } from "frappe-charts/dist/frappe-charts.min.esm";
import Axios from "axios";

export default class ReportsPage extends Component {
	state = {
		tab: 0,
		loadingStats: true,
		thisMonth: 0
	};
	componentDidMount() {
		if (this.props.user) {
			Axios.get("/api/businessAccount/cases").then(d => {
				if (d.data.cases.length) this.renderGraph(d.data.cases);
				else this.setState({ loadingStats: false });
			});
		}
	}

	renderGraph(cases) {
		const today = new Date().toISOString();
		let allMonths = {};
		let byName = {};

		let thisMonth = 0;
		cases.forEach(v => {
			let m = v.createdAt.substr(0, 7);
			if (m === today.substr(0, 7)) thisMonth++;
			if (!allMonths[m])
				allMonths[m] = {
					orders: 0,
					notOrdered: 0
				};
			if (v.requestPR) allMonths[m].orders++;
			else allMonths[m].notOrdered++;
			if (v.user && v.user.firstName) {
				if (!byName[v.user.firstName]) byName[v.user.firstName] = 0;
				byName[v.user.firstName]++;
			}
		});

		let labels = [];
		let values = [];
		let valuesOrders = [];

		var monthName = [
			"Jan",
			"Feb",
			"Mar",
			"Apr",
			"May",
			"Jun",
			"Jul",
			"Aug",
			"Sep",
			"Oct",
			"Nov",
			"Dec"
		];
		const d = new Date();
		d.setDate(1);
		for (let i = 0; i <= 11; i++) {
			labels.push(monthName[d.getMonth()] + " " + d.getFullYear());
			if (
				allMonths[d.toISOString().substr(0, 7)] &&
				allMonths[d.toISOString().substr(0, 7)].notOrdered
			)
				values.push(allMonths[d.toISOString().substr(0, 7)].notOrdered);
			else values.push(0);

			if (
				allMonths[d.toISOString().substr(0, 7)] &&
				allMonths[d.toISOString().substr(0, 7)].orders
			)
				valuesOrders.push(allMonths[d.toISOString().substr(0, 7)].orders);
			else valuesOrders.push(0);

			d.setMonth(d.getMonth() - 1);
		}

		this.setState({ loadingStats: false, thisMonth }, () => {
			const data = {
				labels: labels.reverse(),
				datasets: [
					{
						name: "Added to dashboard",
						chartType: "bar",
						values: values.reverse()
					},
					{
						name: "Ordered reports",
						chartType: "bar",
						values: valuesOrders.reverse()
					}
				]
			};
			new Chart("#year-chart", {
				title: "Dashboard Reports - Last 12 Months",
				data: data,
				type: "bar", // or 'bar', 'line', 'scatter', 'pie', 'percentage'
				height: 300,
				barOptions: {
					stacked: 1
				},
				colors: ["#2196f3", "#743ee2"]
			});

			const userData = {
				labels: Object.keys(byName),
				datasets: [
					{
						name: "Dashboard Reports",
						type: "bar",
						values: Object.keys(byName).map(v => {
							return byName[v];
						})
					}
				]
			};

			new Chart("#user-chart", {
				title: "Dashboard Reports by User",
				data: userData,
				type: "bar", // or 'bar', 'line', 'scatter', 'pie', 'percentage'
				height: 300,
				colors: ["#743ee2"]
			});
		});
	}

	render() {
		return (
			<>
				<h2>Usage</h2>
				{this.state.loadingStats ? (
					<div>
						<img src="/img/ajax-loader.gif" alt="loader" />
						&nbsp; Loading...
					</div>
				) : (
					<div>
						<h3>Current Month Summary: {this.state.thisMonth}</h3>
						<div id="year-chart" />
						<div id="user-chart" />
					</div>
				)}
			</>
		);
	}
}
