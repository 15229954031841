/* eslint-disable react/no-direct-mutation-state */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component, Fragment } from "react";
import { FiPlusCircle, FiMinusCircle } from "react-icons/fi";
import moment from "moment";
import {
	FaCheckSquare,
	FaRegSquare,
	FaSortAmountDown,
	FaSortAmountUp
} from "react-icons/fa";
import clsx from "clsx";
import LeadsExpander from "./LeadsExpander";
import { FaInfoCircle } from "react-icons/fa";
import { withRouter } from "react-router-dom";
import NewCaseModal from "./NewCaseModal";
import axios from "axios";

class UserLeadsTable extends Component {
	state = {
		filter: "",
		sortBy: "createdAt",
		reverse: false,
		leads: this.props.leads,
		showInfo: false,
		accidentId: null,
		editNoteId: null,
		note: ""
	};
	componentDidMount() {
		this.changeSort("createdAt");
	}

	sorter(arr, p, reverse) {
		if (reverse) {
			return arr.slice(0).sort(function (a, b) {
				return a[p] < b[p] ? 1 : a[p] > b[p] ? -1 : 0;
			});
		}
		return arr.slice(0).sort(function (a, b) {
			return a[p] > b[p] ? 1 : a[p] < b[p] ? -1 : 0;
		});
	}

	changeSort(sortBy) {
		let reverse = false;
		if (this.state.sortBy === sortBy) reverse = !this.state.reverse;
		this.setState({
			sortBy,
			reverse,
			leads: this.sorter(this.state.leads, sortBy, reverse)
		});
	}

	sortIcons(w) {
		if (w === this.state.sortBy && this.state.reverse)
			return <FaSortAmountDown size="16" />;

		if (w === this.state.sortBy && !this.state.reverse)
			return <FaSortAmountUp size="16" />;
	}

	toggleExpand(v) {
		if (!v.expanded) v.expanded = true;
		else v.expanded = false;
		this.setState({ leads: this.state.leads });
	}

	saveNotes() {
		// console.log(this.state.editNoteId);
		axios.put("/api/leads/business_user_note", {
			id: this.state.editNoteId,
			business_user_note: this.state.note
		});
		this.state.leads.some((l) => {
			if (l._id === this.state.editNoteId) {
				l.business_user_note = this.state.note;
				return true;
			}
			return false;
		});
		this.setState({ leads: this.state.leads, editNoteId: null });
	}
	toggleSelect(k) {
		this.state.leads[k].selected = !this.state.leads[k].selected;
		this.setState({ leads: this.state.leads });
	}
	extractAccident(lead) {
		let out = {
			make: "",
			year: "",
			color: "",
			city: "",
			state: "",
			zip: "",
			towing_company: "",
			towing_location: "",
			damage_area_1: "",
			damage_severity_1: "",
			damage_area_2: "",
			damage_severity_2: "",
			air_bags: ""
		};
		if (lead.accident && lead.vin && lead.accident.vehicles) {
			lead.accident.vehicles.some((d) => {
				if (d.vin_code === lead.vin) {
					out = {
						make: d.vehicle_maker,
						year: d.vehicle_model_year,
						color: d.vehicle_color,
						city: lead.accident.city.replace(/,/g, ";"),
						state: lead.accident.state.replace(/,/g, ";"),
						zip: lead.accident.zip || "",
						towing_company: d.towing_company.replace(/,/g, ";"),
						towing_location: d.towing_location.replace(/,/g, ";"),
						damage_area_1: d.damage_area_1
							? d.damage_area_1.replace(/,/g, ";")
							: "",
						damage_area_2: d.damage_area_2
							? d.damage_area_2.replace(/,/g, ";")
							: "",
						damage_severity_1: d.damage_severity_1
							? d.damage_severity_1.replace(/,/g, ";")
							: "",
						damage_severity_2: d.damage_severity_2
							? d.damage_severity_2.replace(/,/g, ";")
							: "",
						air_bags: ""
					};
					d.participants.forEach((p) => {
						if (p.airbag)
							out.air_bags = out.air_bags + p.airbag.replace(/,/g, " - ") + "|";
					});
					out.air_bags = out.air_bags.substring(0, out.air_bags.length - 1);
					return true;
				}
				return false;
			});
		}
		return out;
	}
	leadsCSV() {
		const rows = [
			[
				// "Assigned to",
				"Lead Name",
				"Lead Last Name",
				"Phone",
				"Email",
				"Occupant",
				"Vin",
				"Message",
				"Accident Url",
				"Business Report Url",
				"Notification Lead Url",
				"Fault",
				"Injured",
				"Copy Requested",
				"CarBrain",
				"Source",
				"Created At",
				"Time",
				"Lead Level",
				"City",
				"State",
				"Zip",
				"Car Make",
				"Car Year",
				"Car Color",
				"Tow Company",
				"Tow Location",
				"Damage 1",
				"Damage 1 Level",
				"Damage 2",
				"Damage 2 Level",
				"Air bags",
				"Note"
			]
		];

		const selected = [];
		this.state.leads.forEach((v) => {
			if (v.selected) {
				selected.push(v);
			}
		});

		const source = selected.length ? selected : this.state.leads;

		source.forEach((l) => {
			const column = [];
			const accident = this.extractAccident(l);
			// column.push(this.decodeAssignedTo(l.assigned_to));
			column.push(l.firstName || "");
			column.push(l.lastName || "");
			column.push(l.phone || "");
			column.push(l.email || "");
			column.push(l.occupant || "");
			column.push(l.vin || "");
			column.push(l.message ? l.message.replace(/,/g, ";") : "");
			column.push(
				l.accidentURL && l.accidentURL !== "false" ? l.accidentURL : ""
			);
			column.push(
				l.businessReportURL && l.businessReportURL !== "false"
					? l.businessReportURL
					: ""
			);
			column.push("https://app.myaccident.org/notification/" + l.accidentId);
			column.push(l.fault);
			column.push(l.injured);
			column.push(l.copyRequested ? true : false);
			column.push(l.carBrain);
			column.push(l.source);
			column.push(moment(l.createdAt).format("MM/DD/YYYY"));
			column.push(moment(l.createdAt).format("HH:mm:ss"));
			column.push(l.lead_level);
			column.push(accident.city);
			column.push(accident.state);
			column.push(accident.zip);
			column.push(accident.make);
			column.push(accident.year);
			column.push(accident.color);
			column.push(accident.towing_company);
			column.push(accident.towing_location);
			column.push(accident.damage_area_1);
			column.push(accident.damage_severity_1);
			column.push(accident.damage_area_2);
			column.push(accident.damage_severity_2);
			column.push(accident.air_bags);
			column.push(l.business_user_note || "");

			rows.push(column);
		});

		let csvContent = "";
		let dataString = [];
		rows.forEach((infoArray, index) => {
			dataString = infoArray.join(",");
			csvContent += index < rows.length ? dataString + "\n" : dataString;
		});
		let download = (content, fileName, mimeType) => {
			let a = document.createElement("a");
			mimeType = mimeType || "application/octet-stream";
			a.href = URL.createObjectURL(
				new Blob([content], {
					type: mimeType
				})
			);
			a.setAttribute("download", fileName);
			document.body.appendChild(a);
			a.click();
			document.body.removeChild(a);
		};
		// console.log(csvContent);
		download(csvContent, "Leads Export.csv", "text/csv;encoding:utf-8");
	}
	render() {
		return (
			<>
				<NewCaseModal
					show={this.state.accidentId}
					close={() => this.setState({ accidentId: null })}
					accidentID={this.state.accidentId}
					user={this.props.user}
				/>
				<div
					className={clsx("modal", { show: this.state.editNoteId !== null })}
				>
					<h2>Edit notes</h2>
					<textarea
						aria-label="Note textarea"
						value={this.state.note}
						onChange={(e) => this.setState({ note: e.target.value })}
					/>
					<div className="footer">
						<button
							onClick={() => this.setState({ editNoteId: null })}
							className="outline"
						>
							Cancel
						</button>
						<button onClick={() => this.saveNotes()}>Save</button>
					</div>
				</div>
				<button onClick={() => this.leadsCSV()}>
					Download selected as CSV
				</button>
				<div style={{ clear: "both", height: 30 }} />

				<table>
					<thead>
						<tr>
							<th style={{ width: 40 }} />
							<th style={{ width: 40 }} />
							<th style={{ width: 220 }}>
								<a onClick={() => this.changeSort("createdAt")}>
									Date Added{this.sortIcons("createdAt")}
								</a>
							</th>
							<th style={{ width: 140 }}>Name</th>
							<th style={{ width: 80, textAlign: "center" }}>
								<a onClick={() => this.changeSort("source")}>
									Source{this.sortIcons("source")}
								</a>
							</th>
							<th style={{ width: 80, textAlign: "center" }}>
								Type{this.sortIcons("lead_level")}{" "}
								<a
									style={{ display: "inline" }}
									data-testid="info-icon-link"
									onClick={() => this.props.history.push("/lead-types")}
								>
									<FaInfoCircle />
								</a>
							</th>
							<th style={{ width: 100 }} />
							<th style={{ textAlign: "right" }}>Notes</th>
						</tr>
					</thead>
					<tbody>
						{this.state.leads.map((v, k) => (
							<Fragment key={v._id}>
								<tr>
									<td>
										{v.selected ? (
											<FaCheckSquare
												size={24}
												className="link"
												onClick={() => this.toggleSelect(k)}
											/>
										) : (
											<FaRegSquare
												size={24}
												className="link"
												onClick={() => this.toggleSelect(k)}
											/>
										)}
									</td>
									<td>
										<a onClick={() => this.toggleExpand(v)}>
											{v.expanded ? (
												<FiMinusCircle size={24} />
											) : (
												<FiPlusCircle size={24} />
											)}
										</a>
									</td>
									<td>{moment(v.createdAt).format("llll")}</td>
									<td>{v.name || v.firstName}</td>
									<td>
										<span className={"hotness " + v.lead_level}>
											{v.lead_level ? v.lead_level : "-"}
										</span>
									</td>
									<td>
										<span className="pending">{v.source}</span>
									</td>
									<td>
										{v.accidentId ? (
											<button
												onClick={() =>
													this.setState({ accidentId: v.accidentId })
												}
											>
												Add to cases
											</button>
										) : null}
									</td>
									<td>
										<a
											onClick={
												() =>
													this.setState({
														editNoteId: v._id,
														note: v.business_user_note || ""
													})
												// console.log(v)
											}
										>
											{v.business_user_note
												? v.business_user_note.length > 20
													? v.business_user_note.substr(0, 20) + "..."
													: v.business_user_note
												: "Add"}
										</a>
									</td>
								</tr>
								<tr
									className={clsx("expander", {
										show: v.expanded
									})}
								>
									<td colSpan="8">
										{v.expanded ? (
											<LeadsExpander
												data={v}
												removePhone={v.lead_level === "cold"}
											/>
										) : null}
									</td>
								</tr>
							</Fragment>
						))}
					</tbody>
				</table>
			</>
		);
	}
}

export default withRouter(UserLeadsTable);
