/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import axios from "axios";
import swal from "sweetalert2";
import { Link, useHistory } from "react-router-dom";
import moment from "moment";

export default function CmsPage({ user }) {
  const [loading, setLoading] = useState(true);
  const [posts, setPosts] = useState([]);
  const history = useHistory();
  useEffect(() => {
    if (!user || user.role.id !== 10) {
      history.push("/signin");
    } else {
      axios
        .get("/api/blog/posts")
        .then((d) => {
          console.log(d.data);
          setPosts(d.data.posts);
          setLoading(false);
        })
        .catch((e) => {
          swal.fire("Couldn't load posts", JSON.stringify(e), "error");
        });
    }
  }, []);

  const askDelete = (id) => {
    swal
      .fire({
        title: "Are you sure?",
        type: "question",
        showCancelButton: true,
      })
      .then(({ value }) => {
        if (value) {
          axios.delete("/api/blog/post/" + id).then((d) => {
            setPosts(posts.filter((v) => v._id !== id));
          });
        }
      });
  };

  const generateLink = (link) => {
    let domain = "http://localhost:3000/";
    if (window.location.host === "business-dev.myaccident.org")
      domain = "https://www-dev.myaccident.org/";
    if (window.location.host === "business.myaccident.org")
      domain = "https://myaccident.org/";
    return domain + link;
  };

  if (!user || user.role.id !== 10) return null;

  return (
    <div id="admin-page">
      <h2>CMS</h2>
      {loading ? (
        <div>
          <img
            src="/img/ajax-loader.gif"
            alt="loadingRequests..."
            className="loader-gif"
          />
          &nbsp;Loading posts...
        </div>
      ) : (
        <div>
          {!posts.length ? (
            <div>
              No posts in database. <Link to="/cms-edit">Add one?</Link>
            </div>
          ) : (
            <div>
              <Link to="/cms-edit" className="button">
                Add new page
              </Link>
              <br />
              <br />
              <table>
                <thead>
                  <tr>
                    <th>Title</th>
                    <th>Last update</th>
                    <th>Category</th>
                    <th>Template</th>
                    <th>Active</th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {posts.map((p) => (
                    <tr key={p._id}>
                      <td>
                        {p.title} -{" "}
                        <a
                          href={generateLink(p.seo_title)}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          open
                        </a>
                      </td>
                      <td>{moment(p.updated_at).format("lll")}</td>
                      <td>{p.category}</td>
                      <td>{p.template}</td>
                      <td>{p.active ? "Yes" : "No"}</td>
                      <td>
                        <Link
                          to={"/cms-edit/" + p._id}
                          className="button"
                          style={{ width: "49%", marginRight: "1%" }}
                        >
                          Edit
                        </Link>
                        <button
                          style={{ width: "50%" }}
                          className="red"
                          onClick={() => askDelete(p._id)}
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
