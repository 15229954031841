/* eslint-disable react/no-direct-mutation-state */
import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import HomePage from "./pages/home";
// import AboutPage from "./pages/about";
import Header from "./components/header";
import SignupPage from "./pages/signup";
import SignInPage from "./pages/signin";
import UsersPage from "./pages/users";
import AccountPage from "./pages/account";
import Axios from "axios";
import ForgotPage from "./pages/forgot";
import ResetPage from "./pages/reset";
import AdminMenu from "./components/AdminMenu";
import SearchPage from "./pages/search";
import AdminPage from "./pages/admin";
import OwnersPage from "./pages/owners";
// import Pricing from "./pages/pricing";
import Footer from "./components/Footer";
import AlertPage from "./pages/alert";
import PreAlertPage from "./pages/pre-alert";
import AdvertisementPage from "./pages/advertisement";
// import MarketingPage from "./components/advertisement/MarketingPage";
import AddCase from "./pages/add-case";
import DashboardTabs from "./pages/dashboard-tabs";
import SmsActivation from "./pages/sms-activation";
import PaymentCheck from "./components/PaymentCheck";
import WebCases from "./pages/web-cases";
import LeadTypes from "./pages/lead-types";
import SmsConversation from "./components/SmsConversation";
import MultiSMS from "./components/MultiSMS";
// import Leads from "./pages/leads";
import CmsPage from "./pages/cms";
import CmsDetail from "./pages/cmsDetail";
import AlertAdmin from "./pages/alert-admin";
// import AffiliateLanding from "./pages/affiliate-landing";
import AffiliateSignup from "./pages/affiliate-signup";
import BusinessAlertAdmin from "./pages/alert-admin-business";
import PoliceAgencies from "./pages/policeAgencies";
import PoliceAgenciesDetail from "./pages/policeAgenciesDetail";

class App extends React.Component {
  state = {
    user: undefined,
  };
  componentDidMount() {
    if (/^((?!chrome|android).)*safari/i.test(navigator.userAgent)) {
      document.getElementsByTagName("body")[0].classList.add("safari");
    }
    if (
      window.location.hostname === "localhost" ||
      window.location.hostname === "dev.myaccident.org"
    ) {
      document.getElementsByTagName("body")[0].classList.add("dev");
    }

    if (window.getCookie("x-auth")) {
      Axios.get("/api/businessAccount/me")
        .then((d) => {
          // console.log(d.data);
          if (
            d.data.user &&
            d.data.user.account_type !== "business" &&
            d.data.user.account_type !== "affiliate"
          ) {
            this.setState({ user: d.data.user });
            window.location.href = "https://app.myaccident.org";
            return false;
          } else {
            this.setState({ user: d.data.user });
          }
        })
        .catch((e) => {
          console.log(e);
          this.setState({ user: null });
          window.deleteCookie("x-auth");
          window.localStorage.removeItem("user");
        });
    } else {
      this.setState({ user: null });
      window.deleteCookie("x-auth");
      window.localStorage.removeItem("user");
    }

    if (!this.state.user) {
      document.getElementById("root").classList.add("page-width-fix");
    } else {
      document.getElementById("root").classList.remove("page-width-fix");
    }
  }

  componentDidUpdate = () => {
    if (!this.state.user) {
      document.getElementById("root").classList.add("page-width-fix");
    } else {
      document.getElementById("root").classList.remove("page-width-fix");
    }
  };

  signIn = (email, password) => {
    return new Promise((resolve, reject) => {
      Axios.post("/api/users/login", { email, password })
        .then((d) => {
          console.log(d.data);
          if (
            d.data.success &&
            (d.data.user.account_type === "business" ||
              d.data.user.account_type === "affiliate")
          ) {
            window.localStorage.setItem("user", JSON.stringify(d.data.user));
            window.localStorage.removeItem("me");
            this.setState({ user: d.data.user });
            resolve();
          } else {
            window.location.href = "https://app.myaccident.org";
          }
        })
        .catch((e) => {
          // console.log(e);
          reject(e);
        });
    });
  };

  signOut = () => {
    this.setState({ user: null });
    window.deleteCookie("x-auth");
    window.localStorage.removeItem("user");
    window.localStorage.removeItem("me");
  };

  smsCheckDone = () => {
    this.state.user.active = true;
    this.setState({ user: this.state.user });
    window.localStorage.setItem("user", JSON.stringify(this.state.user));
  };

  setPayment = (payment) => {
    this.state.user.payment = payment;
    this.setState({ user: this.state.user });
  };

  render() {
    if (this.state.user === undefined)
      return (
        <img
          src="/img/ajax-loader.gif"
          alt="loader"
          style={{ position: "fixed", top: 10, left: 10 }}
        />
      );
    if (
      this.state.user &&
      this.state.user.account_type === "business" &&
      !this.state.user.active
    ) {
      return (
        <Router>
          <Header user={this.state.user} signOut={this.signOut} />
          <SmsActivation
            user={this.state.user}
            onComplete={this.smsCheckDone}
          />
        </Router>
      );
    }
    return (
      <Router>
        <Header user={this.state.user} signOut={this.signOut} />
        {this.state.user &&
        (this.state.user.account_type === "business" ||
          this.state.user.account_type === "affiliate") ? (
          <AdminMenu user={this.state.user} />
        ) : null}
        {this.state.user && this.state.user.account_type === "business" ? (
          <PaymentCheck setPayment={this.setPayment} user={this.state.user} />
        ) : null}
        <Route
          exact
          path="/search"
          render={() => <SearchPage user={this.state.user} />}
        />
        {/*<Route exact path="/about" component={AboutPage} />*/}
        <Route
          exact
          path="/signup-business/:plan?"
          component={() => (
            <SignupPage signIn={this.signIn} user={this.state.user} />
          )}
        />
        <Route
          exact
          path="/signup/:plan?"
          component={() => <AffiliateSignup />}
        />

        {/*<Route*/}
        {/*  exact*/}
        {/*  path="/free-trial"*/}
        {/*  component={() => (*/}
        {/*    <SignupPage signIn={this.signIn} user={this.state.user} freeTrial />*/}
        {/*  )}*/}
        {/*/>*/}
        <Route
          exact
          path="/signin"
          component={() => (
            <SignInPage
              signIn={this.signIn}
              signOut={this.signOut}
              user={this.state.user}
            />
          )}
        />
        <Route exact path="/forgot" component={() => <ForgotPage />} />
        <Route
          exact
          path="/reset/:token"
          component={(props) => <ResetPage {...props} />}
        />
        <Route
          exact
          path="/"
          component={() => <HomePage user={this.state.user} />}
        />
        <Route
          exact
          path="/users"
          component={() => <UsersPage user={this.state.user} />}
        />
        <Route
          exact
          path="/account"
          component={() => (
            <AccountPage user={this.state.user} setPayment={this.setPayment} />
          )}
        />
        <Route
          exact
          path="/alert"
          component={() => (
            <AlertPage zoom={5} radius={16093} user={this.state.user} />
          )}
        />
        <Route
          exact
          path="/pre-alert"
          component={() => <PreAlertPage user={this.state.user} />}
        />
        <Route
          exact
          path="/admin"
          component={() => <AdminPage user={this.state.user} />}
        />
        <Route
          exact
          path="/alert-admin"
          component={() => <AlertAdmin user={this.state.user} />}
        />
        <Route
          exact
          path="/business-alert-admin"
          component={() => <BusinessAlertAdmin user={this.state.user} />}
        />
        <Route
          exact
          path="/cms"
          component={() => <CmsPage user={this.state.user} />}
        />
        <Route
          exact
          path="/cms-edit/:id?"
          component={() => <CmsDetail user={this.state.user} />}
        />
        <Route
          exact
          path="/police-agencies"
          component={() => <PoliceAgencies user={this.state.user} />}
        />
        <Route
          exact
          path="/police-agencies-edit/:id?"
          component={() => <PoliceAgenciesDetail user={this.state.user} />}
        />
        {/*<Route*/}
        {/*  exact*/}
        {/*  path="/pricing"*/}
        {/*  component={() => <Pricing user={this.state.user} />}*/}
        {/*/>*/}
        <Route
          exact
          path="/owners/:id"
          component={(props) => (
            <OwnersPage {...props} user={this.state.user} />
          )}
        />
        <Route
          exact
          path="/dashboard"
          component={(props) => (
            <DashboardTabs {...props} user={this.state.user} />
          )}
        />
        <Route
          exact
          path="/advertisement"
          component={(props) => (
            <AdvertisementPage {...props} user={this.state.user} />
          )}
        />
        {/*<Route*/}
        {/*  exact*/}
        {/*  path="/marketing/markets"*/}
        {/*  component={(props) => <MarketingPage {...props} markets={true} />}*/}
        {/*/>*/}
        {/*<Route*/}
        {/*  exact*/}
        {/*  path="/marketing"*/}
        {/*  component={(props) => <MarketingPage {...props} insurance={true} />}*/}
        {/*/>*/}
        {/*<Route*/}
        {/*  exact*/}
        {/*  path="/marketing/lawfirm"*/}
        {/*  component={(props) => <MarketingPage {...props} injury={true} />}*/}
        {/*/>*/}
        <Route
          exact
          path="/add-case/:id"
          component={(props) => <AddCase user={this.state.user} />}
        />
        <Route
          exact
          path="/web-cases"
          component={(props) => <WebCases user={this.state.user} />}
        />
        <Route
          exact
          path="/lead-types"
          component={(props) => <LeadTypes user={this.state.user} />}
        />
        {/*<Route*/}
        {/*  exact*/}
        {/*  path="/affiliate"*/}
        {/*  component={(props) => <AffiliateLanding />}*/}
        {/*/>*/}
        {/*<Route*/}
        {/*  exact*/}
        {/*  path="/signup-affiliate"*/}
        {/*  component={(props) => <AffiliateSignup />}*/}
        {/*/>*/}
        {/*<Route exact path="/leads" component={() => <Leads />} />*/}
        <Footer user={this.state.user} />
        {this.state.user && this.state.user.role.id === 10 ? (
          <>
            <SmsConversation />
            <MultiSMS />
          </>
        ) : null}
      </Router>
    );
  }
}

export default App;
