import React, { Component } from "react";
import { Redirect, withRouter } from "react-router-dom";
import clsx from "clsx";
import { Link } from "react-router-dom";
// import swal from "sweetalert2";

import CircleShape from "../components/Shared/CircleShape";

class SignInPage extends Component {
  state = {
    email: "",
    password: "",
    error: "",
    ajax: false,
  };
  signIn(e) {
    e.preventDefault();
    // if (window.isMobileDevice()) {
    // 	swal.fire(
    // 		"Sorry",
    // 		"The MyAccident Business Portal is only available from a desktop or laptop.",
    // 		"error"
    // 	);
    // 	return false;
    // }
    this.setState({ ajax: true });
    // this.setState({ ajax: false, error: "Wrong Username Or Password" });
    this.props
      .signIn(this.state.email, this.state.password)
      .then((d) => {
        this.props.history.push("/");
      })
      .catch((e) => {
        this.setState({ ajax: false, error: "Unknown email or password." });
        console.log(e);
        // console.log("signed in error", e.response.data.error);
      });
  }
  render() {
    if (this.props.user) return <Redirect to="/" />;
    return (
      <div className="public-page">
        <div
          id="signIn"
          className={clsx("page", "show", { ajax: this.state.ajax })}
        >
          <div className="SignIn__content">
            <CircleShape id="SignIn__circle" className="SignIn__circle-shape" />
            <div className="SignIn__form-wrapper">
              <h1>Sign In</h1>
              {this.state.error && (
                <div className="error">{this.state.error}</div>
              )}

              <form onSubmit={(e) => this.signIn(e)}>
                <fieldset>
                  <label>Email</label>
                  <input
                    type="email"
                    placeholder=""
                    required
                    onChange={(e) => {
                      this.setState({ email: e.target.value });
                    }}
                    value={this.state.email}
                    autoCorrect="false"
                  />
                </fieldset>
                <fieldset>
                  <label>Password</label>
                  <input
                    type="password"
                    placeholder=""
                    required
                    onChange={(e) => {
                      this.setState({ password: e.target.value });
                    }}
                    value={this.state.password}
                    autoCorrect="false"
                  />
                  <p className="SignIn__forgotpass-text">
                    <Link to="/forgot">Forgot password?</Link>
                  </p>
                  <button
                    disabled={!this.state.email || !this.state.password}
                    className="auth-btn"
                  >
                    Sign in
                  </button>
                </fieldset>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(SignInPage);
