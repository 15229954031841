// eslint-disable-next-line import/no-anonymous-default-export
export default {
  "611bd223d47d930019ce7f26": "elocal.webp",
  "613fc28c49687e0019dde34f": "tawk.png",
  "6418cf4f6616980019110e57": "legal-helpers.jpg",
  "64230b56c2433d0019df7872": "asklaw.png",
  "64272eccdac8620019569e9c": "letsmakealead.jpg",
  "642b5425da9c660019c2c7e7": "mdm.png",
  "6425e26ef363bd0019cdf4f1": "marketcall.png",
  "6499de88838a2800194b8891": "GMFO-Logo-green-1.png",
  "64d66838c828000019810b20": "Quantify.png",
  "651305eb47b4e20019e97014": "RingPartner.png",
  "6568d4489903de00193d3435": "RingPartner-inverted.png",
  "65bd3905a316c4001947cd2a": "install.png",
};
