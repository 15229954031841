import React, { useEffect, useRef, useState } from "react";

export default function PingPriceButton({ l, ping }) {
  const [show, setShow] = useState(false);

  const ref = useRef();
  useEffect(() => {
    if (show) ref.current.focus();
  }, [show]);

  let price = 0;
  let provider = "";
  if (l?.external_apis) {
    Object.keys(l.external_apis).forEach((key) => {
      if (Number(l.external_apis[key].price) > price) {
        price = l.external_apis[key].price;
        provider = key;
      }
    });
  }

  return (
    <>
      <div style={{ cursor: "pointer" }}>
        {provider ? (
          <div className="pending Accepted" onClick={() => setShow(true)}>
            ${price}: {provider}
          </div>
        ) : (
          <button onClick={() => ping()} disabled={l?.accepted}>
            PING
          </button>
        )}
      </div>
      {provider ? (
        <div
          className={show ? "dot-menu ping show" : "dot-menu ping"}
          tabIndex="0"
          onBlur={() =>
            setTimeout(() => {
              setShow(false);
            }, 150)
          }
          ref={ref}
        >
          {provider &&
            Object.keys(l?.external_apis).map((key) => (
              <span key={key}>
                <strong>{key}:</strong> ${l?.external_apis[key]?.price}
              </span>
            ))}
          <button onClick={() => ping()}>PING ALL AGAIN</button>
        </div>
      ) : null}
    </>
  );
}
