/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component, Fragment } from "react";
import { FiPlusCircle, FiMinusCircle } from "react-icons/fi";
import moment from "moment";
import UserExpander from "./UserExpander";
import { FaSortAmountDown, FaSortAmountUp } from "react-icons/fa";
import clsx from "clsx";
import { withRouter } from "react-router-dom";
import Axios from "axios";
import { MdCheckBox, MdCheckBoxOutlineBlank, MdClose } from "react-icons/md";
import Checkbox from "./Shared/Checkbox";
import Swal from "sweetalert2";

class AdminUsersTable extends Component {
  state = {
    filter: "",
    sortBy: "",
    reverse: false,
    users: this.props.users,
    prepaidLeadsUser: undefined,
    prepaidLeadsEdit: 0,
    ajax: false,
    leadValueUser: undefined,
    leadValueEdit: 0,
    showArchived: false,
  };

  sorter(arr, p, reverse) {
    if (reverse) {
      return arr.slice(0).sort(function (a, b) {
        return a[p] < b[p] ? 1 : a[p] > b[p] ? -1 : 0;
      });
    }
    return arr.slice(0).sort(function (a, b) {
      return a[p] > b[p] ? 1 : a[p] < b[p] ? -1 : 0;
    });
  }

  changeSort(sortBy) {
    let reverse = false;
    if (this.state.sortBy === sortBy) reverse = !this.state.reverse;
    this.setState({
      sortBy,
      reverse,
      users: this.sorter(this.state.users, sortBy, reverse),
    });
  }

  sortIcons(w) {
    if (w === this.state.sortBy && this.state.reverse)
      return <FaSortAmountUp size="16" />;

    if (w === this.state.sortBy && !this.state.reverse)
      return <FaSortAmountDown size="16" />;
  }

  toggleExpand(v) {
    if (!v.expanded) v.expanded = true;
    else v.expanded = false;
    this.setState({ users: this.state.users });
  }

  getInsightToken(id) {
    Axios.post("/api/users/getInsightsToken", { id }).then((d) => {
      if (d.data.success) {
        localStorage.setItem("me", this.props.user._id);
        window.location.href = "/";
      }
    });
  }

  savePrepaidLeads() {
    this.setState({ ajax: true });
    Axios.post("/api/users/setCredits", {
      id: this.state.prepaidLeadsUser,
      credits: Number(this.state.prepaidLeadsEdit),
    }).then((d) => {
      if (d.data.success) {
        this.state.users.some((v) => {
          if (v._id === this.state.prepaidLeadsUser) {
            v.prepaid_leads = Number(this.state.prepaidLeadsEdit);
            return true;
          }
          return false;
        });
        this.setState({
          users: this.state.users,
          prepaidLeadsUser: undefined,
          ajax: false,
        });
      }
    });
  }

  saveLeadValue() {
    this.setState({ ajax: true });
    Axios.post("/api/users/setLeadValue", {
      id: this.state.leadValueUser,
      lead_value: Number(this.state.leadValueEdit),
    }).then((d) => {
      this.state.users.some((v) => {
        if (v._id === this.state.leadValueUser) {
          v.lead_value = Number(this.state.leadValueEdit);
          return true;
        }
        return false;
      });
      this.setState({
        users: this.state.users,
        leadValueUser: undefined,
        ajax: false,
      });
    });
  }

  toggleArchive(u) {
    Axios.post("/api/users/setArchivedValue", {
      id: u._id,
      value: !u.archived,
    });
    this.state.users.some((v) => {
      if (v._id === u._id) {
        v.archived = !u.archived;
        return true;
      }
      return false;
    });
    this.setState({
      users: this.state.users,
    });
  }

  updatePrepaidCount(d) {
    this.state.users.some((v) => {
      if (v._id === d.detail.user) {
        v.prepaid_leads = d.detail.prepaid_leads;
        return true;
      }
      return false;
    });
    this.setState({ users: this.state.users });
  }

  componentDidMount() {
    window.addEventListener("update-prepaid-lead-count", (data) =>
      this.updatePrepaidCount(data)
    );
  }

  componentWillUnmount() {
    window.removeEventListener("update-prepaid-lead-count", () =>
      this.updatePrepaidCount()
    );
  }

  populatePrices() {
    Swal.showLoading();
    Axios.get("/api/leads/updateMorganConnectPrices").then((d) => {
      if (d.data.success) {
        Swal.fire(
          "Done",
          "Morgan Connect user prices updated successfully.",
          "success"
        );
      } else {
        Swal.fire("Error", "Unknown error happened.", "error");
      }
    });
  }

  render() {
    return (
      <>
        <div
          className={clsx(
            "modal",
            { show: this.state.prepaidLeadsUser },
            { ajax: this.state.ajax }
          )}
          data-testid={"edit-user-modal"}
        >
          <button
            className="close"
            onClick={() => this.setState({ prepaidLeadsUser: undefined })}
            data-testid={"close-edit-user"}
          >
            <MdClose />
          </button>
          <h2>Prepaid Leads</h2>
          <p>Set Number Of Leads For The Account:</p>
          <br />
          <input
            type="number"
            value={this.state.prepaidLeadsEdit}
            style={{ width: 100 }}
            min={0}
            onChange={(e) =>
              this.setState({ prepaidLeadsEdit: e.target.value })
            }
            data-testid="leads-input"
          />
          <div className="footer">
            <button
              onClick={() => this.setState({ prepaidLeadsUser: undefined })}
              className="outline"
              data-testid="cancel-prepaid-user"
            >
              Cancel
            </button>
            <button
              onClick={() => this.savePrepaidLeads()}
              disabled={
                this.state.prepaidLeadsEdit === "" ||
                Number(this.state.prepaidLeadsEdit) < 0
              }
            >
              Add Now
            </button>
          </div>
        </div>
        <div
          className={clsx(
            "modal",
            { show: this.state.leadValueUser },
            { ajax: this.state.ajax }
          )}
        >
          <button
            className="close"
            onClick={() => this.setState({ leadValueUser: undefined })}
            data-testid={"close-lead-user"}
          >
            <MdClose />
          </button>
          <h2>Lead Value</h2>
          <p>Enter lead value for this account:</p>
          <br />
          <input
            type="number"
            value={this.state.leadValueEdit}
            style={{ width: 100 }}
            min={0}
            onChange={(e) => this.setState({ leadValueEdit: e.target.value })}
            data-testid={"lead-value-input"}
          />
          <div className="footer">
            <button
              onClick={() => this.setState({ leadValueUser: undefined })}
              className="outline"
              data-testid={"cancel-lead-user"}
            >
              Cancel
            </button>
            <button
              onClick={() => this.saveLeadValue()}
              disabled={
                this.state.leadValueEdit === "" ||
                Number(this.state.leadValueEdit) < 0
              }
            >
              Save
            </button>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div>
            <Checkbox
              text="Show archived"
              onClick={() =>
                this.setState({ showArchived: !this.state.showArchived })
              }
              checked={this.state.showArchived}
            />
          </div>
          <a onClick={() => this.populatePrices()}>
            Populate prices from 60 day retainer stats
          </a>
        </div>
        <br />
        <table>
          <thead>
            <tr>
              <th></th>
              <th>
                <a onClick={() => this.changeSort("created_at")}>
                  Created At{this.sortIcons("created_at")}
                </a>
              </th>
              <th>Business Name</th>
              {/* <th>Plan</th> */}
              <th>Lead Value</th>
              <th style={{ textAlign: "center" }}>Archived</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {this.state.users
              .filter((v) => !v.archived || this.state.showArchived)
              .map((v) => (
                <Fragment key={v._id}>
                  <tr>
                    <td>
                      <a
                        onClick={() => this.toggleExpand(v)}
                        data-testid={`admin-toggle-expand-${v._id}`}
                      >
                        {v.expanded ? (
                          <FiMinusCircle
                            size={24}
                            data-testid={`admin-close-expanded-${v._id}`}
                          />
                        ) : (
                          <FiPlusCircle
                            size={24}
                            data-testid={`admin-show-expanded-${v._id}`}
                          />
                        )}
                      </a>
                    </td>
                    <td>{moment(v.created_at).format("ll")}</td>
                    <td>
                      {v.businessName} <br />
                      <span style={{ opacity: 0.5 }}>{v.email}</span>
                    </td>
                    {/* <td>{v.plan && v.plan.plan_name ? v.plan.plan_name : ""}</td> */}
                    <td>
                      <button
                        data-testid={`lead-value-button-${v._id}`}
                        onClick={() =>
                          this.setState(
                            {
                              leadValueUser: v._id,
                              leadValueEdit: v.lead_value || 0,
                            },
                            () => {
                              document.querySelector(".modal input").focus();
                            }
                          )
                        }
                      >
                        ${v.lead_value || 0}
                      </button>
                    </td>
                    <td style={{ textAlign: "center" }}>
                      <a
                        onClick={() => this.toggleArchive(v)}
                        data-testid={`admin-toggle-archive-${v._id}`}
                      >
                        {v.archived ? (
                          <MdCheckBox
                            size={24}
                            data-testid="admin-checked-archived-icon"
                          />
                        ) : (
                          <MdCheckBoxOutlineBlank
                            size={24}
                            data-testid="admin-unchecked-archived-icon"
                          />
                        )}
                      </a>
                    </td>
                    <td>
                      <button
                        style={{ width: "32%", marginRight: "1%" }}
                        data-testid={`edit-admin-user-${v._id}`}
                        onClick={() =>
                          this.setState(
                            {
                              prepaidLeadsUser: v._id,
                              prepaidLeadsEdit: v.prepaid_leads || 0,
                            },
                            () => {
                              document.querySelector(".modal input").focus();
                            }
                          )
                        }
                      >
                        Leads: {v.prepaid_leads || 0}
                      </button>
                      <button
                        style={{ width: "32%", marginRight: "1%" }}
                        onClick={() => this.getInsightToken(v._id)}
                        disabled={this.props.user._id === v._id}
                        data-testid={`admin-insight-token-${v._id}`}
                      >
                        Dashboard
                      </button>
                      <button
                        className="red"
                        disabled={this.props.user._id === v._id}
                        onClick={() => this.props.delete(v)}
                        style={{ width: "32%" }}
                        data-testid={`delete-user-${v._id}`}
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                  <tr
                    className={clsx("expander", {
                      show: v.expanded,
                    })}
                  >
                    <td colSpan="10">
                      {v.expanded ? (
                        <UserExpander
                          data={v}
                          toggleState={(s) =>
                            this.props.toggleUserState(v._id, s)
                          }
                          toggleAutoSMS={() => this.props.toggleAutoSMS(v)}
                          toggleRejectionTracking={() =>
                            this.props.toggleRejectionTracking(v)
                          }
                          saveAutoSMStext={(e) => {
                            e.preventDefault();
                            this.props.saveAutoSMStext(v, e);
                          }}
                          saveStatePrices={(e) =>
                            this.props.saveStatePrices(e, v)
                          }
                          setRetainer={(e) => this.props.setRetainer(e, v)}
                          toggleMorgan={() => this.props.toggleMorgan(v)}
                          saveMorganSource={(e) =>
                            this.props.saveMorganSource(e, v)
                          }
                          saveLac={(e) => this.props.saveLac(e, v)}
                        />
                      ) : null}
                    </td>
                  </tr>
                </Fragment>
              ))}
          </tbody>
        </table>
      </>
    );
  }
}

export default withRouter(AdminUsersTable);
