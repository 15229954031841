/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component, Fragment } from "react";
import { FiPlusCircle, FiMinusCircle } from "react-icons/fi";
import moment from "moment";
import AlertExpander from "./AlertExpander";
import { FaSortAmountDown, FaSortAmountUp } from "react-icons/fa";
import clsx from "clsx";
import { withRouter } from "react-router-dom";

class AdminAlertsTable extends Component {
	state = {
		filter: "",
		sortBy: "createdAt",
		reverse: false,
		alerts: this.props.alerts
	};
	componentDidMount() {
		this.changeSort("createdAt");
	}

	sorter(arr, p, reverse) {
		if (reverse) {
			return arr.slice(0).sort(function(a, b) {
				return a[p] < b[p] ? 1 : a[p] > b[p] ? -1 : 0;
			});
		}
		return arr.slice(0).sort(function(a, b) {
			return a[p] > b[p] ? 1 : a[p] < b[p] ? -1 : 0;
		});
	}

	changeSort(sortBy) {
		let reverse = false;
		if (this.state.sortBy === sortBy) reverse = !this.state.reverse;
		this.setState({
			sortBy,
			reverse,
			alerts: this.sorter(this.state.alerts, sortBy, reverse)
		});
	}

	sortIcons(w) {
		if (w === this.state.sortBy && this.state.reverse)
			return <FaSortAmountDown size="16" />;

		if (w === this.state.sortBy && !this.state.reverse)
			return <FaSortAmountUp size="16" />;
	}

	toggleExpand(v) {
		if (!v.expanded) v.expanded = true;
		else v.expanded = false;
		this.setState({ alerts: this.state.alerts });
	}
	businessName(v) {
		if (v.user && !v.user.accountOwner) return v.user.businessName;
		if (v.user && v.user.accountOwner) return v.user.accountOwner.businessName;
	}

	render() {
		return (
			<table>
				<thead>
					<tr>
						<th></th>
						<th>
							<a onClick={() => this.changeSort("createdAt")}>
								Date Added{this.sortIcons("createdAt")}
							</a>
						</th>
						<th>Requestor</th>
						<th>Business</th>
						<th>Period</th>
						<th>Vehicles</th>
						<th>Request PR</th>
					</tr>
				</thead>
				<tbody>
					{this.state.alerts.map(v => (
						<Fragment key={v._id}>
							<tr>
								<td>
									<a onClick={() => this.toggleExpand(v)} data-testid={`alerts-toggle-${v._id}`}>
										{v.expanded ? (
											<FiMinusCircle size={24} />
										) : (
											<FiPlusCircle size={24} />
										)}
									</a>
								</td>
								<td>{moment(v.createdAt).format("llll")}</td>
								<td>
									{v.user ? v.user.firstName + " " + v.user.lastName : ""}
								</td>
								<td>{this.businessName(v)}</td>
								<td>
									{moment(v.startDate).format('l')} -{" "}
									{moment(v.endDate).format('l')}
								</td>
								<td>
									{(v.vehicle1 ? v.vehicle1 : "") +
										(v.vehicle2 ? ", " + v.vehicle2 : "")}
								</td>
								<td>{v.requestPR ? "Y" : "N"}</td>
							</tr>
							<tr
								className={clsx("expander", {
									show: v.expanded
								})}
							>
								<td colSpan="7">
									{v.expanded ? <AlertExpander data={v} /> : null}
								</td>
							</tr>
						</Fragment>
					))}
				</tbody>
			</table>
		);
	}
}

export default withRouter(AdminAlertsTable);
