/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from "react";
import debounce from "lodash.debounce";
import MultiSelect from "./MultiSelect";
import moment from "moment";
export const availableStates = [
  "AL",
  "AK",
  "AZ",
  "AR",
  "CA",
  "CO",
  "CT",
  "DC",
  "DE",
  "FL",
  "GA",
  "HI",
  "ID",
  "IL",
  "IN",
  "IA",
  "KS",
  "KY",
  "LA",
  "ME",
  "MD",
  "MA",
  "MI",
  "MN",
  "MS",
  "MO",
  "MT",
  "NE",
  "NV",
  "NH",
  "NJ",
  "NM",
  "NY",
  "NC",
  "ND",
  "OH",
  "OK",
  "OR",
  "PA",
  "RI",
  "SC",
  "SD",
  "TN",
  "TX",
  "UT",
  "VT",
  "VA",
  "WA",
  "WV",
  "WI",
  "WY",
];

export const paid_google_direct_spanish =
  "21043151420|21046660234|21046660237|21046660240|21046660243|21046660246|21046660249|21046660252|21046660255|21046660258|21046660261|21046660264|21046660267|21046660270|21046660273|21046660276|21046660279|21046660282";

export const paid_google_primary =
  "17809540210|17809540228|17882053132|17809540225|17809540222|17805523147|17809540219|17809540213|17809540216|17809540231|16551110584|18139335740|18139345595|18139348550|18407120398|18403739412|18935997982|19003425084|17809540234|19003425081|19003425090|19003425087|19587772691|19579689762|19579689774|19579689765|19579689771|19579689768|19579689777|19611129254|19664261758|19664261884|19664261752|19664261755|19664261881|19665560063|19665560060|19665560057|19665560054|19665560066|19658805321|19658805315|19658805312|19658805309|19658805318|19671710873|19671710867|19671710870|19671710858|19671710861|19671710864";

export const paid_google_primary_spanish =
  "20340449643|20340449646|20340449649|20340449652|20362939915|20362939918|20362940044|20362940047|20362940050|20362940053|20362940056|20362940059|20362940062";

export const paid_google_truck =
  "21015991162|21013054310|21013054292|21010607255|21016102312|21010607258|21016102300|21015991171|21013054295|21015991174|21012950633|21012950762|21015991183|21012950624|21015991180|21012950618|21016102297|21013101779|21012950627|21013101770|21016102309|21012950615|21015991177|21010607261|21013054304|21013054319|21015991168|21013101773|21015991189|21016102294|21013054301|21013054307|21006805088|21012950621|21013054298|21012950639|21010607252|21016102306|21013101767|21013054313|21015991186|21016102303|21012950630|21013101776|21013054316|21013101785|21010607264|21012950636|21013101782|21015991165";

export const paid_google_competitors =
  "17816998781|17809367755|17888541011|16551180853|17817016019|17808014847|17808017922|17808027081|17808030165|17817062084|17817036410|17808066201|18139348559|17804245626|18139348556|18403740423|18403715736|19003425096|19003425102|19003425099|18139348553|19003425105|19579689783|19579689780|19579689798|19579689795|19579689792|19579689789|19579689786|19611133562|19664955241|19664955109|19664955118|19664955115|19664955112|19665787672|19003425108|19665787669|19665787663|19665787666|19666147354|19666147357|19666147360|19666147363|19665787660|19671791480|19671791465|19666147327|19671791477|19671791471|19671791474";

export const paid_google_competitors_spanish =
  "20576135162|20576135039|20576135036|20576135033";

export const paid_google_insurance_claims =
  "17696039214|19786600701|20766032796|20779424149|20772259175|20771769743|20769299472|20779424293|20772598994|20779424284|20772599009|20766032817|20779424281|20769299466|20772259181|20772259160|20779424296|20772259178|20779424290|17696039214|20772599000|20772599003|20772259172|20766032814|20772598997|20769299463|20766032799|20772259166|20769299454|20766032808|20772598985|20779424287|20772599012|20772259169|20772259157|20779424158|20772599006|20772259154|20766032805|20766032820|20766032802|20766032793|19786600701|20766032811|20772598988|20772598991|20769299475|20769299460|20779424152|20779424155|20769299457|20769299469|20772259163";

export const paid_google_camplj = "18080674054";

export const paid_google_direct =
  "20462691730|20494660506|20644401085|20644401082|20644401079|20644401076|20644401073|20644401070|20644401067|20644401064|20644401061|20644401058|20644115260|20644115257|20644115254|20644115251|20644115248|20644115245|20644115242|20644115239|20644115236|20644115233|20644115230|20641618250|20641618247|20641618244|20641618241|20641618070|20641618067|20641618064|20641457165|20641457162|20641457039|20641457036|20641457033|20641457030|20641457027|20641457024|20641457021|20641457018|20634375084|20634375081|20634375078|20634375075|20634375072|20634375069|20634375066|20634375063|20634375060|20634375057|20708348131|20708348143|20702734203|20702734209|20702534871|20708348119|20698316613|20698316628|20702734215|20702992545|20708348113|20702734212|20702534874|20702734206|20708348122|20698316625|20702535000|20702992536|20702534862|20702992551|20708348134|20698316622|20698316634|20698316760|20702734071|20702734074|20702534865|20698316631|20702992509|20702534877|20698316616|20702992542|20708348116|20702992539|20702535003|20708348128|20702734200|20702535006|20698316637|20708348140|20708348137|20702535009|20698316619|20702992548|20702534868|20702734218|20708348125|20701677014";

export const paid_google_medical =
  "21077785640|21071029164|21070903581|21080840305|21070903173|21081065470|21070903569|21080840083|21080941435|21070903653|21070903632|21070903908|21080941618|21077916890|21080941405|21070806204|21077685803|21080941633|21070904097|21077917199|21077785613|21070806129|21081064999|21071030391|21071029677|21077685797|21070903563|21071030802|21081065503|21080840056|21070806225|21077917391|21071030808|21077686022|21080941351|21081065173|21080941369|21071030538|21081065707|21081065200|21077917175|21080941438|21077916440|21070903194|21071029392|21077685986|21070903863|21080941348|21080748217|21081065425";

export const paid_bing_direct =
  "530895494|530900224|530900223|530900222|530900221|530900220|530900219|530900218|530900217|530900216|530900215|530900252|530900251|530900250|530900249|530900248|530900247|530900246|530900245|530900244|530900243|530900242|530900262|530900261|530900260|530900259|530900258|530900257|530900256|530900255|530900254|530900253|530900234|530900233|530900232|530900231|530900230|530900229|530900228|530900227|530900226|530900225|530900241|530900240|530900239|530900238|530900237|530900236|530900235|530895495|530969874|530969875|530969876|530969877|530969878|530969879|530969880|530969881|530969882|530969883|530969884|530969885|530969886|530969887|530969888|530969889|530969890|530969891|530969892|530969893|530969894|530969895|530969896|530969897|530969898|530969899|530969900|530969901|530969902|530969903|530969904|530969905|530969906|530969907|530969908|530969909|530969910|530969911|530969912|530969913|530969914|530969915|530969916|530969917|530969918|530969919|530969920|530969921";

export const paid_bing_primary =
  "530128614|530895442|530128619|530128631|530128623|530895419|530895422|530895444|530895410|530128632|530895440|530895421|530895413|530895412|530895441|530895443|530128618|530895424|530895452|530895414|530895407|530895451|530128626|530128617|530895450|530895453|530895432|530895439|530128609|530895437|530128616|530895406|530895436|530895435|530895438|530895469|530128615|530895467|530128620|530895468|530128625|530128606|530895423|530895464|530895411|530895420|530128627|530895465|530895433|530895466";

export const paid_bing_primary_spanish =
  "530895489|530895479|530895482|530895486|530895477|530895492|530895485|530895476|530895491|530895481|530895478|530895490|530895488|530895493|530895483|530895480|530895484|530895487";

export const paid_bing_competitors =
  "530895449|530128613|530128630|530128624|530895445|530895409|530128607|530895448|530895408|530895415|530895447|530895446|530128621|530895458|530895416|530895417|530895457|530895404|530895405|530895455|530895456|530895460|530128610|530895461|530128611|530895462|530895463|530895454|530895474|530128622|530895470|530895459|530128629|530128612|530895473|530895418|530128628|530895471|530895434|530895472|530895425|530895429|530895428|530895427|530895426|530895431|530895430";

export const paid_bing_competitors_spanish =
  "530895497|530895498|530895496|530895499";

export const paid_bing_insurance_claims = "530128608|530895475";

export const paid_bing_direct_spanish =
  "531227821|531227822|531227823|531227824|531227825|531227826|531227827|531227828|531227829|531227830|531227831|531227832|531227833|531227834|531227835|531227836|531227837|531227837";

export const facebook = "facebook";

export default function LeadFilter({ applyFilters, disabled, users }) {
  const [lead_level, setLeadLevel] = useState([
    "sold",
    "hot",
    "bad hot",
    "rejected",
    "unverified",
    "doa",
    "cancel",
    "ping-1",
    "ping-2",
    "ready",
  ]);
  const [source, setSource] = useState("");
  const [email, setEmail] = useState("");
  const [lead_type, setLeadType] = useState(["CALawyer", "ready"]);
  const [city, setCity] = useState("");
  const [phone, setPhone] = useState("");
  const [states, setStates] = useState([]);
  const [affiliates, setAffiliates] = useState("");
  // const [ass, setAss] = useState("");
  const [referrers, setReferrers] = useState([]);
  const [showStatesFilter, setShowStatesFilter] = useState(false);
  const [assArray, setAssArray] = useState([]);
  const [dateType, setDateType] = useState("limit");
  const [from, setFrom] = useState(moment().format("YYYY-MM-DD"));
  const [to, setTo] = useState(moment().format("YYYY-MM-DD"));
  const [combi, setCombi] = useState("");

  const applyDelayed = useCallback(
    debounce((filter) => applyFilters(filter), 750),
    []
  );

  const toggleState = (v) => {
    if (states.includes(v)) {
      setStates(states.filter((vv) => v !== vv));
    } else {
      states.push(v);
      setStates([...states]);
    }
  };

  useEffect(() => {
    let filter = {};

    if (lead_level.length) {
      filter.lead_level = {
        $in: lead_level.map((v) => (v === "sold cold" ? "cold" : v)),
      };
    }
    if (lead_type.length) {
      filter.lead_type = {
        $in: lead_type,
      };
    }
    if (source) filter.source = { $regex: source, $options: "i" };
    if (email) filter.email = { $regex: email, $options: "i" };
    if (city) filter.city = { $regex: city, $options: "i" };
    if (phone) filter.phone = { $regex: phone, $options: "i" };
    if (states.length) filter.state = { $in: states };
    if (affiliates) {
      if (affiliates === "MA") {
        filter.affiliate = { $exists: false };
      } else if (affiliates === "NMA") {
        filter.affiliate = { $exists: true };
      } else filter.affiliate = affiliates;
    }
    if (lead_level.includes("sold cold")) {
      filter.accepted = { $ne: null };
    }
    // console.log(lead_level);
    // if (ass) {
    //   filter.$or = [
    //     { accepted: ass },
    //     { assigned_to: { $in: ass } },
    //     { declined: { $in: ass } },
    //   ];
    // }

    if (assArray.length) {
      filter.accepted = {
        $in: assArray,
      };
    }

    if (referrers.length) {
      filter.referer = { $regex: referrers.join("|"), $options: "i" };
      if (referrers.includes("google")) {
        // filter.referer = { $or }   { $or: [{ filter.referer }] $exists: false };
        filter = {
          ...filter,
          ...{
            $or: [
              { referer: { $exists: false } },
              { referer: { $regex: referrers.join("|"), $options: "i" } },
            ],
          },
        };
        delete filter.referer;
        // console.log(filter);
      }
    }

    if (combi === "1") {
      filter = {};
      filter.accepted = { $eq: null };
      filter.lead_level = { $in: ["cold"] };
      filter.duplicate = false;
    }

    if (combi === "2") {
      filter = {};
      filter.accepted = { $eq: null };
      filter.lead_level = { $in: ["hot"] };
      filter.duplicate = false;
      filter.lead_type = {
        $in: ["dValue"],
      };
    }

    if (combi === "3") {
      filter = {};
      filter.accepted = { $eq: null };
      filter.lead_level = { $in: ["cold"] };
      filter.duplicate = false;
      filter.state = { $nin: ["MI", "NC"] };
      filter.lead_type = {
        $in: ["CALawyer"],
      };
    }

    if (combi === "4") {
      filter = {};
      filter.accepted = { $eq: null };
      filter.lead_level = { $in: ["hot"] };
      filter.duplicate = false;
      filter.state = { $nin: ["MI", "NC"] };
      filter.lead_type = {
        $in: ["dValue"],
      };
    }

    if (dateType !== "limit")
      filter.createdAt = {
        $gte: moment(from).startOf("day").toDate(),
        $lt: moment(to).endOf("day").toDate(),
      };

    // console.log(filter);

    if (email || city || phone) applyDelayed(filter);
    else applyFilters(filter);
  }, [
    lead_level,
    source,
    email,
    lead_type,
    city,
    states,
    phone,
    affiliates,
    // ass,
    assArray,
    referrers,
    from,
    to,
    dateType,
    combi,
  ]);

  return (
    <>
      <div className="filters">
        <select value={dateType} onChange={(e) => setDateType(e.target.value)}>
          <option value="limit">Show last X leads</option>
          <option value="period">Choose period</option>
        </select>
        {dateType === "period" ? (
          <>
            <div>
              From:
              <input
                type="date"
                value={from}
                style={{ width: 150 }}
                onChange={(e) => setFrom(e.target.value)}
              />
            </div>
            <div style={{ marginLeft: 10, marginRight: 10 }}>
              To:
              <input
                type="date"
                value={to}
                style={{ width: 150 }}
                onChange={(e) => setTo(e.target.value)}
              />
            </div>
          </>
        ) : null}{" "}
        <select
          value={combi}
          onChange={(e) => {
            setCombi(e.target.value);
          }}
        >
          <option value="">Combinations...</option>
          <option value="1">
            Only unassigned CALAWYER cold leads. Exclude: Duplicates
          </option>
          <option value="2">
            Only unassigned DiminishedValue hot leads. Exclude: duplicates
          </option>
          <option value="3">
            Only unassigned CALAWYER cold leads. Exclude: duplicates, MI, & NC
          </option>
          <option value="4">
            Only unassigned DiminishedValue hot leads. Exclude: duplicates, MI,
            & NC
          </option>
        </select>
      </div>
      <div style={{ clear: "both" }} />
      {combi === "" ? (
        <>
          <div className="filters">
            <MultiSelect
              options={[
                { value: "sold", title: "Sold" },
                { value: "hot", title: "Hot" },
                { value: "cold", title: "Cold" },
                { value: "sold cold", title: "Sold cold" },
                { value: "bad hot", title: "Bad hot" },
                { value: "rejected", title: "Rejected" },
                { value: "deleted", title: "Deleted" },
                { value: "unverified", title: "Unverified" },
                { value: "doa", title: "D.O.A." },
                { value: "cancel", title: "Cancel" },
                { value: "ping-1", title: "Ping 1" },
                { value: "ping-2", title: "Ping 2" },
                { value: "ready", title: "Ready" },
              ]}
              placeholder="All hotness..."
              initial={lead_level}
              accept={(v) => setLeadLevel([...v])}
            />
            <select
              value={source}
              onChange={(e) => setSource(e.target.value)}
              disabled={disabled}
            >
              <option value="">All Sources...</option>
              <option>find</option>
              <option>alert</option>
              <option>download</option>
              <option>car</option>
              <option>ad form</option>
              <option>calc</option>
              <option>tawk.to</option>
              <option>direct</option>
              <option>medical</option>
              <option>truck</option>
            </select>
            <MultiSelect
              options={[
                { value: "DefectiveAirbag", title: "DefectiveAirbag" },
                { value: "CALawyer", title: "CALawyer" },
                { value: "DUILawyer", title: "DUILawyer" },
                { value: "AutoBody", title: "AutoBody" },
                { value: "dValue", title: "Diminished Value" },
                { value: "AutoRepair", title: "AutoRepair" },
                { value: "NSInsurance", title: "NSInsurance" },
                { value: "Chiropractor", title: "Chiropractor" },
                { value: "Construction", title: "Construction" },
                { value: "MedMal", title: "MedMal" },
                { value: "WorkComp", title: "WorkComp" },
                { value: "SlipFall", title: "SlipFall" },
                { value: "WDeath", title: "WDeath" },
                { value: "CAMPLJ", title: "CAMPLJ" },
                { value: "ready", title: "Ready" },
              ]}
              placeholder="All lead type..."
              initial={lead_type}
              accept={(v) => setLeadType([...v])}
            />
            {/* <select
          value={lead_type}
          onChange={(e) => setLeadType(e.target.value)}
          disabled={disabled}
        >
          <option value="...">All types...</option>
          <option>CALawyer</option>
          <option>DUILawyer</option>
          <option>AutoBody</option>
          <option>AutoRepair</option>
          <option>NSInsurance</option>
          <option>Chiropractor</option>
          <option>CAMPLJ</option>
        </select> */}
            <div
              style={{ position: "relative", minWidth: 200, marginRight: 10 }}
            >
              <input
                placeholder="Find by email..."
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                disabled={disabled}
              />
              {email ? (
                <img
                  src="/img/ic-close.png"
                  alt="Clear search"
                  onClick={() => setEmail("")}
                />
              ) : null}
            </div>
            <div
              style={{ position: "relative", minWidth: 200, marginRight: 10 }}
            >
              <input
                placeholder="City..."
                value={city}
                onChange={(e) => setCity(e.target.value)}
                disabled={disabled}
              />
              {city ? (
                <img
                  src="/img/ic-close.png"
                  alt="Clear search"
                  onClick={() => setCity("")}
                />
              ) : null}
            </div>
            <div style={{ position: "relative", minWidth: 200 }}>
              <input
                placeholder="Phone..."
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                disabled={disabled}
              />
              {phone ? (
                <img
                  src="/img/ic-close.png"
                  alt="Clear search"
                  onClick={() => setPhone("")}
                />
              ) : null}
            </div>
          </div>
          <div style={{ clear: "both" }} />
          <div className="filters">
            <select
              value={affiliates}
              onChange={(e) => setAffiliates(e.target.value)}
              disabled={disabled}
            >
              <option value="">All Affiliates/Sources...</option>
              <option value="MA">MyAccident.org</option>
              <option value="NMA">All Affiliates</option>
              {users
                .filter(
                  (u) =>
                    u.account_type === "affiliate" &&
                    !u.archived &&
                    u.email !== "tawk.to@myaccident.org"
                )
                .map((u) => (
                  <option value={u._id} key={u._id}>
                    {u.email}
                  </option>
                ))}
            </select>
            {/* <select
          value={ass}
          onChange={(e) => setAss(e.target.value)}
          disabled={disabled}
        >
          <option value="">All Acc. / Ass. / Dec.</option>
          {users.map((u) => (
            <option value={u._id} key={u._id}>
              {u.email}
            </option>
          ))}
        </select> */}
            <MultiSelect
              options={users.map((u) => {
                return { value: u._id, title: u.firstName + " " + u.lastName };
              })}
              initial={assArray}
              accept={(v) => setAssArray([...v])}
              placeholder="All business users..."
            />
            <MultiSelect
              options={[
                {
                  value:
                    paid_google_direct_spanish +
                    "|" +
                    paid_google_primary +
                    "|" +
                    paid_google_primary_spanish +
                    "|" +
                    paid_google_competitors +
                    "|" +
                    paid_google_competitors_spanish +
                    "|" +
                    paid_google_insurance_claims +
                    "|" +
                    paid_google_camplj +
                    "|" +
                    paid_google_direct +
                    "|" +
                    paid_google_truck +
                    "|" +
                    paid_google_medical,
                  title: "All Paid Google",
                },
                {
                  value: paid_google_direct_spanish,
                  title: "Paid Google Direct Spanish",
                },
                {
                  value: paid_google_primary,
                  title: "Paid Google Primary",
                },
                {
                  value: paid_google_truck,
                  title: "Paid Google Truck",
                },
                {
                  value: paid_google_primary_spanish,
                  title: "Paid Google Primary Spanish",
                },
                {
                  value: paid_google_competitors,
                  title: "Paid Google Competitors",
                },
                {
                  value: paid_google_competitors_spanish,
                  title: "Paid Google Competitors Spanish",
                },
                {
                  value: paid_google_insurance_claims,
                  title: "Paid Google Insurance Claims",
                },
                {
                  value: paid_google_camplj,
                  title: "Paid Google CampLJ",
                },
                {
                  value: paid_google_direct,
                  title: "Paid Google Direct",
                },
                {
                  value: paid_google_medical,
                  title: "Paid Google Medical",
                },
                {
                  value:
                    paid_bing_primary +
                    "|" +
                    paid_bing_competitors +
                    "|" +
                    paid_bing_insurance_claims +
                    "|" +
                    paid_bing_primary_spanish +
                    "|" +
                    paid_bing_competitors_spanish +
                    "|" +
                    paid_bing_direct +
                    "|" +
                    paid_bing_direct_spanish,

                  title: "All Paid Bing",
                },
                {
                  value: paid_bing_direct_spanish,
                  title: "Paid Bing Direct Spanish",
                },
                {
                  value: paid_bing_primary,
                  title: "Paid Bing Primary",
                },
                {
                  title: "Paid Bing Competitors",
                  value: paid_bing_competitors,
                },
                {
                  value: paid_bing_insurance_claims,
                  title: "Paid Bing Insurance Claims",
                },
                {
                  value: paid_bing_primary_spanish,
                  title: "Paid Bing Primary Spanish",
                },
                {
                  value: paid_bing_competitors_spanish,
                  title: "Paid Bing Competitors Spanish",
                },
                {
                  value: paid_bing_direct,
                  title: "Paid Bing Direct",
                },
                {
                  value: facebook,
                  title: "Facebook",
                },
                { value: "google", title: "Google" },
                { value: "yahoo", title: "Yahoo" },
                { value: "duckduckgo", title: "DuckDuckGo" },
                { value: "bing", title: "Bing" },
                { value: "Advertiser=o", title: "Paid Microsoft O" },
                { value: "Advertiser=s", title: "Paid Microsoft S" },
                { value: "Advertiser=a", title: "Paid Microsoft A" },
                { value: "lang=es", title: "Spanish" },
              ]}
              placeholder="All referrers..."
              initial={referrers}
              accept={(v) => setReferrers([...v])}
            />
          </div>
          <div
            style={{
              border: "#DDD 1px solid",
              borderRadius: 4,
              width: showStatesFilter ? "auto" : 200,
            }}
          >
            <div
              style={{ padding: "0.5rem", cursor: "pointer" }}
              onClick={() => setShowStatesFilter(!showStatesFilter)}
            >
              States...
            </div>
            <div
              style={{ display: showStatesFilter ? "block" : "none" }}
              data-testid="states-container"
            >
              <div
                className={
                  disabled ? "filters states disabled" : "filters states"
                }
              >
                {availableStates.map((s) => (
                  <a
                    onClick={() => toggleState(s)}
                    className={states.includes(s) ? "active" : ""}
                    key={s}
                  >
                    {s}
                  </a>
                ))}
                {states.length ? (
                  <a
                    onClick={() => setStates([])}
                    style={{ fontSize: 11 }}
                    data-testid="clear-state-button"
                  >
                    Clear
                  </a>
                ) : null}
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
}
