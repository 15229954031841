/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-direct-mutation-state */
/* eslint-disable no-undef */
import React from "react";
import { FaRegSquare, FaCheckSquare } from "react-icons/fa";
import { MdClose } from "react-icons/md";

const marketsInfo = {
  net: {
    price: 499.99,
    name: "North East Texas",
    color: "#2C353C",
  },
  set: {
    price: 499.99,
    name: "South East Texas",
    color: "#465C6D",
  },
  ct: {
    price: 499.99,
    name: "Central Texas",
    color: "#5C809C",
  },
  st: {
    price: 499.99,
    name: "South Texas",
    color: "#1f2a31",
  },
  wt: {
    price: 499.99,
    name: "West Texas",
    color: "#3a4e5f",
  },
  nt: {
    price: 499.99,
    name: "North Texas",
    color: "#5c6d7b",
  },
};
const defaultMarkets = {
	net: false,
	set: false,
	ct: false,
	st: false,
	wt: false,
	nt: false
};

export default class AdvertisingOptions extends React.Component {
  state = {
    markets: { ...this.props.markets } || defaultMarkets,
    plan: this.props.plan || undefined,
    detailsModal: false,
  };

  resetMarkets = (plan) => {
    this.setState(
      {
        plan,
        markets: { ...defaultMarkets },
      },
      () => {
        this.props.choosePlan(plan, this.state.markets);
      }
    );
  };

  calculateMarkets() {
    let selected = 0;
    let price = 0;
    if (this.state.plan === "regions") {
      Object.keys(this.state.markets).forEach((v) => {
        if (this.state.markets[v]) {
          selected++;
          price = price + marketsInfo[v].price;
        }
      });
    }
    return {
      selected: selected + ` region${selected !== 1 ? "s" : ""} selected`,
      price,
    };
  }

  render() {
    return (
      <div>
        <div
          className={
            this.calculateMarkets().price > 10000 && !this.state.expensiveIgnore
              ? "modal show"
              : "modal"
          }
          style={{ textAlign: "left" }}
        >
          <h2>Your Choosen areas are more expensive then all Texas.</h2>
          <p>Would you like to switch to all Texas package?</p>
          <br />
          <br />
          <div className="footer">
            <button
              className="outline"
              onClick={() => this.setState({ expensiveIgnore: true })}
            >
              No
            </button>
            <button onClick={() => this.resetMarkets("texas")}>Yes</button>
          </div>
        </div>
        <div
          className={
            this.state.detailsModal
              ? "modal advertising-plans show"
              : "modal advertising-plans"
          }
        >
          <button
            className="close"
            onClick={() => this.setState({ detailsModal: undefined })}
          >
            <MdClose />
          </button>
          <br />
          <br />
          <h2 style={{ color: "#FFF", marginBottom: 50 }}>
            Direct advertising to over 50k accident report users monthly
          </h2>
          <img
            src="/img/customize-ad.png"
            alt="customize ad"
            className="customize-ad-png"
          />

          <ol className="ad-packages-notes-li">
            <li>
              <strong>Cases not clicks</strong>
              MyAccident.org generates over +50k unique new visitors each month.
              +1% or more request services provided by our advertisers.
            </li>
            <li>
              <strong>Exclusivity</strong>Visitors that click on your ad go
              directly to you. Also, MyAccident.org limits the number of Law
              Firms per market. (More information about our markets on the Next
              Page).
            </li>
            <li>
              <strong>Localized Ad Placement</strong>Your placement will be
              based on the market(s) you select.
            </li>
            <li>
              <strong>Real time Exclusive Leads Sent To YOU only</strong>
              Reach Leads Before Your Competition. We use SMS and Email
              Notification to notify your team immediately based on your
              preferences. The lead is exclusive and not sent to other members
              of our advertising network.
            </li>
            <li>
              <strong>Flexibility</strong>
              No long-term contracts and no setup fees.
            </li>
            <li>
              <strong>We don’t compete with you</strong>
              The vast majority of our users (99%+) have arrived at our site
              organically via google search. We do not compete for paid ad
              space. Our users are specifically researching how to find their
              accident report or get more information about their accident.
            </li>
            <li>
              <strong>
                We offer total transparency with real-time reports and Your Own
                Portal
              </strong>
              Customize, change your ad using your own portal. Check on your
              ad’s performance and other useful information about your market.
            </li>
            <li>
              <strong>Awesome support</strong>
              Our typical response time is less than 15 minutes.
            </li>
            <li>
              <strong>Use YOUR OWN reviews</strong>
              We link to and display YOUR Google reviews so you don't have to
              invest in yet another 'authentic’ review directory listing.
            </li>
          </ol>
          <div style={{ clear: "both", height: 30 }} />
          <div className="footer">
            <button style={{ visibility: "hidden" }}>No</button>
            <button onClick={() => this.setState({ detailsModal: false })}>
              Close
            </button>
          </div>
        </div>
        {this.props.changePlan ? null : (
          <h2 style={{ color: "#FFF" }}>
            Select your Ad plan. See Ad Details and features{" "}
            <a onClick={() => this.setState({ detailsModal: true })}>here</a>.
          </h2>
        )}
        <br />
        <br />
        <div id="ad-packages">
          <section>
            <div
              className={this.state.plan === "basic" ? "selected" : ""}
              onClick={() => this.resetMarkets("basic")}
              data-testid={"basic-plan-button"}
            >
              {this.state.plan === "basic" ? (
                <FaCheckSquare />
              ) : (
                <FaRegSquare />
              )}
              BASIC ADS ($199.95 p/month)
              <small>
                The Basic Ad plan will show your ad to all reports within a
                radius of 50 miles from your office location
              </small>
            </div>
            <div
              className={this.state.plan === "texas" ? "selected" : ""}
              onClick={() => this.resetMarkets("texas")}
              data-testid={"texas-plan-button"}
            >
              {this.state.plan === "texas" ? (
                <FaCheckSquare />
              ) : (
                <FaRegSquare />
              )}
              TEXAS ADS ($799.99 p/month)
              <small>
                The Texas Ad plan will show your ad to all reports in the state
                of Texas
              </small>
            </div>
            <div
              className={this.state.plan === "regions" ? "selected" : ""}
              onClick={() => this.resetMarkets("regions")}
              data-testid={"regions-plan-button"}
            >
              {this.state.plan === "regions" ? (
                <FaCheckSquare />
              ) : (
                <FaRegSquare />
              )}
              REGIONAL ADS ($499.99 p/month)
              <small>
                Use the Map to select region(s) you would like to advertise in.
                (You can select multiple)
              </small>
              {Object.keys(this.state.markets).map((v) => {
                if (this.state.markets[v])
                  return (
                    <li key={v}>
                      {marketsInfo[v].name} selected (${marketsInfo[v].price}{" "}
                      per month)
                    </li>
                  );
                return null;
              })}
            </div>
            <div
              className={this.state.plan === "no-plan" ? "selected" : ""}
              onClick={() => this.resetMarkets("no-plan")}
              data-testid={"no-plan-button"}
            >
              {this.state.plan === "no-plan" ? (
                <FaCheckSquare />
              ) : (
                <FaRegSquare />
              )}
              <small>
                I DO NOT WISH TO ADVERTISE WITH
                <br />
                MYACCIDENT.ORG AT THIS TIME
              </small>
            </div>
            {this.props.changePlan ? (
              <p>
                Allow 24 hours for changes to be reflected on your account.
                <br />
                <br />
              </p>
            ) : null}
            {this.props.changePlan ? (
              <button
                disabled={this.props.changePlanDisabled}
                onClick={this.props.changePlan}
              >
                Change plan
              </button>
            ) : null}
          </section>
        </div>
        {/* <div>
					{this.calculateMarkets().selected}, ${this.calculateMarkets().price}{" "}
					per month
				</div> */}

        <div>
          <div id="region-map" />
        </div>
        <div style={{ clear: "both", height: 30 }} />
        {/* <ul className="ad-packages-notes-li">
					<li>
						Your ad will display on all accident reports that occurred in the
						counties shown in the region(s) plan you select as illustrated on
						this map
					</li>
					<li>Ad’s are displayed evenly between advertising partners</li>
					<li>Markets have limited availability</li>
					<li>
						All leads generated on your ad are sent directly to your email/SMS
						and MyAccident.org BusinessDashboard.
					</li>
				</ul> */}
      </div>
    );
  }
}
