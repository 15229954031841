import React from "react";

export default function AlertExpander(props) {
  const u = props.data;
  const fields = [
    "caseId",
    "firstName",
    "lastName",
    "address",
    "radius",
    "legal_consult",
    "notify_email_count",
    "notify_sms_count",
    "type",
  ];
  if (u)
    return (
      <div className="exp">
        {fields.map((f) => {
          if (u[f])
            return (
              <p key={f}>
                <strong>{f}: </strong> {u[f]}
              </p>
            );
          return null;
        })}
        <p>
          <strong>Matches:</strong> {u.matches?.length}
        </p>
      </div>
    );
  return null;
}
