import React, { Component, Fragment } from "react";
import { Formik, Field, ErrorMessage, Form } from "formik";
import clsx from "clsx";
import swal from "sweetalert2";
import Axios from "axios";
import { Redirect, withRouter } from "react-router-dom";
import { MdClose } from "react-icons/md";
import { FaInfoCircle } from "react-icons/fa";
import ReactDatatable from "@ashvin27/react-datatable";

const emptyUser = {
	email: "",
	firstName: "",
	lastName: "",
	password: "",
	password2: "",
	canOrderReports: false,
	warningModal: ""
};

class UsersPage extends Component {
	adminUser = {
		_id: "admin",
		email: this.props.user ? this.props.user.email : "",
		firstName: this.props.user ? this.props.user.firstName : "",
		lastName: this.props.user ? this.props.user.lastName : "",
		canOrderReports: true
	};
	state = {
		modalMode: undefined,
		editUser: emptyUser,
		isAdmin:
			(this.props.user && this.props.user.role.id === 10) ||
			(this.props.user && this.props.user.role.id === 11)
				? true
				: false,
		users:
			this.props.user && this.props.user.users ? this.props.user.users : [],
		loading: true
	};
	columns = [
		{
			key: "owner",
			text: "",
			cell: v => {
				return v._id === "admin" ? (
					<span data-title="Account owner">
						<FaInfoCircle size={24} style={{ opacity: 0.5 }} />
					</span>
				) : (
					""
				);
			}
		},
		{
			key: "email",
			text: "Email",
			sortable: true
		},
		{
			key: "firstName",
			text: "First Name",
			sortable: true
		},
		{
			key: "lastName",
			text: "Last Name",
			sortable: true
		},
		{
			key: "organization",
			text: "Organization",
			sortable: true
		},
		{
			key: "canOrderReports",
			text: "Can Order Reports",
			sortable: true,
			cell: record => {
				return record.canOrderReports ? "Y" : "N";
			}
		},
		{
			key: "action",
			text: "",
			align: "right",
			sortable: false,
			cell: record => {
				if (record._id !== "admin")
					return (
						<Fragment>
							<button
								onClick={() => this.startEdit(record)}
								style={{ width: "49%", marginRight: "1%" }}
							>
								Edit
							</button>
							<button
								onClick={() => this.askDelete(record)}
								className="red"
								style={{ width: "50%" }}
							>
								Delete
							</button>
						</Fragment>
					);
				else
					return (
						<button onClick={() => this.props.history.push("/account")}>
							Edit
						</button>
					);
			}
		}
	];
	config = {
		page_size: 1000,
		length_menu: [10, 20, 50],
		// button: {
		// 	excel: true,
		// 	print: true
		// },
		show_length_menu: false,
		show_pagination: false,
		// filename: "Users",
		sort: { column: "email", order: "asc" }
	};
	componentDidMount() {
		if (this.state.isAdmin) {
			Axios.get("/api/businessAccount/me").then(d => {
				// console.log(d.data);
				d.data.user.users.unshift(this.adminUser);
				window.localStorage.setItem("user", JSON.stringify(d.data.user));
				this.setState({ users: d.data.user.users, loading: false });
			});
		} else this.setState({ loading: false });
	}

	closeModal() {
		this.setState({
			editUser: { ...emptyUser, ...{ time: +new Date() } },
			modalMode: undefined
		});
	}
	startEdit(editUser) {
		this.setState({
			editUser: {
				...editUser,
				...{ password: "", password2: "", time: +new Date() }
			},
			modalMode: "edit"
		});
	}
	askDelete(user) {
		swal
			.fire({
				type: "question",
				title: "Are you sure you want to remove " + user.firstName + "?",
				text: "This action cannot be undone.",
				showCancelButton: true,
				reverseButtons: true
			})
			.then(v => {
				if (v.value) {
					Axios.delete("/api/businessAccount/user/" + user._id);
					let key;
					this.state.users.some((vv, k) => {
						if (vv._id === user._id) {
							key = k;
							return true;
						}
						return false;
					});
					if (key !== undefined) {
						this.state.users.splice(key, 1);
						this.setState({ users: this.state.users });
					}
				}
			});
	}
	submit(user) {
		if (this.state.modalMode === "add") {
			user.canOrderReports = this.state.editUser.canOrderReports;
			// console.log(user);
			Axios.post("/api/businessAccount/user/addUser", user)
				.then(d => {
					// console.log(d.data);
					d.data.user.canOrderReports = this.state.editUser.canOrderReports;
					this.state.users.push(d.data.user);
					this.setState({ users: this.state.users });
					this.closeModal();
				})
				.catch(e => {
					swal.fire("Error", e.response.data.error, "error");
				});
		} else {
			if (user.password === "") {
				delete user.password;
				delete user.password2;
			}
			this.state.users.some((v, k) => {
				if (v._id === user._id) {
					v.firstName = user.firstName;
					v.lastName = user.lastName;
					v.email = user.email;
					v.organization = user.organization;
					v.canOrderReports = user.canOrderReports;
					v.phoneNumber = user.phoneNumber;
					return true;
				}
				return false;
			});
			this.setState({ users: this.state.users });
			Axios.put("/api/businessAccount/user/" + user._id, user)
				.then(d => {
					this.closeModal();
				})
				.catch(e => {
					swal.fire("Error", e.response.data.error, "error");
				});
		}
	}

	contactUs() {
		this.setState({ warningModal: false });
		swal
			.fire({
				title: "Upgrade Plan?",
				text: "Optional message for MyAccident Team:",
				input: "textarea",
				showCancelButton: true,
				reverseButtons: true,
				confirmButtonText: "Send"
			})
			.then(v => {
				if (v.hasOwnProperty("value")) {
					// console.log(v.value);
					Axios.post("/api/businessAccount/contact", { text: v.value }).then(
						d => {
							swal.fire(
								"Done!",
								"Your request has been sent. Someone will contact you shortly.",
								"success"
							);
						}
					);
				}
			});
	}

	addUser() {
		// console.log(this.props.user.plan.usersCount);
		// console.log(this.state.users.length);
		// if (this.state.users.length >= this.props.user.plan.usersCount) {
		this.setState({ warningModal: true });
		// } else {
		// this.setState({ modalMode: "add" });
		// }
	}
	changePassword(values, actions) {
		actions.setSubmitting(true);
		Axios.post("/api/businessAccount/passwordChange", {
			currentPassword: values.oldPassword,
			newPassword: values.password
		})
			.then(d => {
				// console.log(d.data);
				actions.resetForm();
				this.setState({ changePasswordModal: false });
				swal.fire("Done", "Your password has been changed.", "success");
			})
			.catch(e => {
				actions.setSubmitting(false);
				swal.fire("Error", e.response.data.error, "error");
			});
	}
	handleCanOrderReports() {
		// eslint-disable-next-line react/no-direct-mutation-state
		this.state.editUser.canOrderReports = !this.state.editUser.canOrderReports;
		this.setState({ editUser: this.state.editUser });
	}
	render() {
		if (this.props.user === null) return <Redirect to="/signin" />;
		if (this.state.loading)
			return (
				<div id="admin-page">
					<img
						src="/img/ajax-loader.gif"
						alt="Loading..."
						className="loader-gif"
					/>
					&nbsp;Loading...
				</div>
			);
		if (this.state.isAdmin) {
			return (
				<div id="admin-page">
					<div className={clsx("modal", { show: this.state.warningModal })}>
						<button
							className="close"
							onClick={() => this.setState({ warningModal: false })}
						>
							<MdClose />
						</button>
						<h2>Add user?</h2>
						{this.props.user.payment ? (
							<p>
								You will be additionally be charged $9.95 per month, per user.
								<br />
								<br />
								Are you sure you want to continue?
								<br />
								<br />
							</p>
						) : (
							<p>
								Your payment is not configured yet. It is not possible to add
								users.
							</p>
						)}
						<div className="footer">
							<button
								className="outline"
								onClick={() => this.setState({ warningModal: false })}
							>
								Cancel
							</button>
							{/* <button onClick={() => this.contactUs()}>
								Upgrade Your Plan
							</button> */}
							<button
								onClick={() =>
									this.setState({ warningModal: false, modalMode: "add" })
								}
								disabled={!this.props.user.payment}
							>
								Continue
							</button>
						</div>
					</div>

					<div
						className={clsx("modal", { show: this.state.changePasswordModal })}
					>
						<button
							className="close"
							onClick={() => this.setState({ changePasswordModal: false })}
						>
							<MdClose />
						</button>
						<h2>Change Password</h2>
						<Formik
							initialValues={{
								oldPassword: "",
								password: "",
								password2: ""
							}}
							validate={values => {
								let errors = {};
								if (!values.password) errors.password = "Password is required.";
								if (!values.password2)
									errors.password2 = "You need to repeat password.";
								else if (values.password !== values.password2)
									errors.password2 = "Passwords don't match";
								if (!values.oldPassword)
									errors.oldPassword = "Old password is required";
								return errors;
							}}
							onSubmit={(values, actions) => {
								this.changePassword(values, actions);
							}}
						>
							{({ errors, touched, isSubmitting }) => (
								<Form>
									<fieldset>
										<div className="row">
											<div className="column">
												<label>Old Password:</label>
												<ErrorMessage
													name="oldPassword"
													render={msg => <div className="error">{msg}</div>}
												/>
												<Field type="password" name="oldPassword" />
											</div>
										</div>
										<div className="row">
											<div className="column">
												<label>New Password:</label>
												<ErrorMessage
													name="password"
													render={msg => <div className="error">{msg}</div>}
												/>
												<Field type="password" name="password" />
											</div>
										</div>
										<div className="row">
											<div className="column">
												<label>Repeat New Password:</label>
												<ErrorMessage
													name="password2"
													render={msg => <div className="error">{msg}</div>}
												/>
												<Field type="password" name="password2" />
											</div>
										</div>
									</fieldset>
									<div className="footer">
										<button
											type="button"
											onClick={() =>
												this.setState({ changePasswordModal: false })
											}
											className="outline"
										>
											Cancel
										</button>
										<button
											type="submit"
											disabled={
												!(
													Object.keys(touched).length &&
													!Object.keys(errors).length
												) || isSubmitting
											}
										>
											{isSubmitting ? (
												<img src="/img/ajax-loader.gif" alt="Loading" />
											) : (
												"Change Password"
											)}
										</button>
									</div>
								</Form>
							)}
						</Formik>
					</div>
					<div className={clsx("modal", { show: this.state.modalMode })}>
						<button className="close" onClick={() => this.closeModal()}>
							<MdClose />
						</button>
						<h2>
							{this.state.modalMode === "add" ? "Add new user" : "Edit user"}
						</h2>
						<Formik
							initialValues={this.state.editUser}
							enableReinitialize={true}
							validate={values => {
								let errors = {};
								if (!values.email) errors.email = "Email field is required";
								if (this.state.modalMode === "add" && !values.password)
									errors.password = "Password is required.";
								if (this.state.modalMode === "add" && !values.password2)
									errors.password2 = "You need to repeat password.";
								else if (values.password !== values.password2)
									errors.password2 = "Passwords don't match";
								if (!values.firstName)
									errors.firstName = "First name is required.";
								if (!values.lastName)
									errors.lastName = "Last name is required.";
								return errors;
							}}
							onSubmit={(values, { setSubmitting }) => {
								this.submit(values);
							}}
						>
							{({
								values
								/* and other goodies */
							}) => (
								<Form>
									<fieldset>
										<label>First Name</label>
										<ErrorMessage
											name="firstName"
											render={msg => <div className="error">{msg}</div>}
										/>
										<Field name="firstName" type="text" />

										<label>Last Name</label>
										<ErrorMessage
											name="lastName"
											render={msg => <div className="error">{msg}</div>}
										/>
										<Field name="lastName" type="text" />

										<label>Email</label>
										<ErrorMessage
											name="email"
											render={msg => <div className="error">{msg}</div>}
										/>
										<Field name="email" type="email" />

										<label>Team Name</label>
										<ErrorMessage
											name="organization"
											render={msg => <div className="error">{msg}</div>}
										/>
										<Field
											name="organization"
											type="text"
											placeholder="Enter the team name for this employee"
										/>

										<label>Phone Number</label>
										<ErrorMessage
											name="phoneNumber"
											render={msg => <div className="error">{msg}</div>}
										/>
										<Field name="phoneNumber" type="text" />

										<label>
											Password{" "}
											{this.state.modalMode === "edit"
												? "(Leave empty for no change)"
												: ""}
										</label>
										<ErrorMessage
											name="password"
											render={msg => <div className="error">{msg}</div>}
										/>
										<Field type="password" name="password" />

										<label>Repeat Password</label>
										<ErrorMessage
											name="password2"
											render={msg => <div className="error">{msg}</div>}
										/>
										<Field type="password" name="password2" />

										<input
											type="checkbox"
											id="cbx"
											style={{ display: "none" }}
											readOnly
											checked={this.state.editUser.canOrderReports}
											className="inp-cbx"
										/>
										<label
											className="cbx"
											htmlFor="cbx"
											onClick={() => this.handleCanOrderReports()}
										>
											<span>
												<svg width="12px" height="10px" viewBox="0 0 12 10">
													<polyline points="1.5 6 4.5 9 10.5 1" />
												</svg>
											</span>
											<span>
												Super User{" "}
												<i data-title="A super user has additional permissions including the ability to order reports">
													<FaInfoCircle />
												</i>
											</span>
										</label>
									</fieldset>
									<div className="footer">
										<button
											type="button"
											onClick={() => this.closeModal()}
											className="outline"
										>
											Cancel
										</button>
										<button
											type="submit"
											disabled={
												values.firstName === "" ||
												values.lastName === "" ||
												values.email === ""
											}
										>
											{this.state.modalMode === "add"
												? "Add new user"
												: "Save changes"}
										</button>
									</div>
								</Form>
							)}
						</Formik>
					</div>
					<h1>Users</h1>
					<button onClick={() => this.addUser()} className="add-new">
						Add New User
					</button>
					<ReactDatatable
						config={this.config}
						records={this.state.users}
						columns={this.columns}
					/>
				</div>
			);
		} else {
			return <div id="admin-page">Currently only admin can manage users.</div>;
		}
	}
}
export default withRouter(UsersPage);
