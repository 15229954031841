/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import clsx from "clsx";
import { Redirect, withRouter } from "react-router";
import { Formik, Field, ErrorMessage, Form } from "formik";
import { debounce } from "lodash";
import Axios from "axios";
import PlanChooser from "../components/PlanChooser";
import IntlTelInput from "react-intl-tel-input";
import "react-intl-tel-input/dist/main.css";
import Select from "react-select";
import Swal from "sweetalert2";
import LicenceAgreement from "../components/LicenceAgreement";
import CircleShape from "../components/Shared/CircleShape";
import AdvertisingOptions from "../components/AdvertisingOptions";
import LeadPlans from "../components/LeadPlans";
import { FaCheck } from "react-icons/fa";

export const businessOptions = [
  {
    value: "lawyers",
    label: "Law Firm",
  },
  {
    value: "insurance",
    label: "Insurance Company",
  },
  // {
  // 	value: "transport",
  // 	label: "Transport Company"
  // },
  {
    value: "autorepair",
    label: "Auto Repair",
  },
  // {
  // 	value: "government",
  // 	label: "Government Organization"
  // },
  // {
  // 	value: "other",
  // 	label: "Other"
  // },
  {
    value: "chiropractors",
    label: "Chiropractors",
  },
];

const getData = (url, succesHandler, errorHandler) => {
  Axios.get(url)
    .then((response) => succesHandler(response.data))
    .catch((error) => errorHandler(error));
};

class SignupPage extends Component {
  state = {
    step: this.props.match.params.plan ? 2 : 1,
    phoneCode: "",
    ajax: false,
    smsSent: false,
    passedCodeCheck: false,
    plan: this.props.match.params.plan,
    adPlan: this.props.freeTrial ? "basic" : undefined,
    leadPlan: this.props.freeTrial ? 5 : 0,
    phoneNumber: "",
    businessType: { value: "lawyers", label: "Law Firm" },
    stateList: [],
    state: "",
    zip: "",
    zipError: "",
    otherType: "",
    businessName: "",
    accept1: false,
    accept2: false,
    quotaCheckPassed: true,
    markets: {},
  };
  componentDidMount() {
    this.checkEmail = debounce(this.checkEmail, 500);
    this.checkBusinessName = debounce(this.checkBusinessName, 500);
    this.checkQuota = debounce(this.checkQuota, 500);
    this.loadData();
  }
  handleStateChange = (state) => {
    this.setState({ state });
  };

  handleCityChange = (city) => {
    this.setState({ city });
  };

  loadData = () => {
    const stateUrl = "/api/search/states/list";

    getData(
      stateUrl,
      (response) => {
        this.setState({
          stateList: response.statesList.map((state) => {
            return { value: state.value, label: state.label };
          }),
        });
      },
      (error) => console.log(error)
    );
  };
  componentDidUpdate(props, prevState) {
    if (this.state.step !== prevState.step) window.scrollTo(0, 0);
  }
  basicInfoSubmit(data) {
    this.basicInfo = data;
    this.setState({ step: 5, businessName: data.businessName });
  }

  complete() {
    this.setState({ ajax: true });
    let data = {
      ...this.basicInfo,
      ...{
        phone: this.state.country_code + this.state.phoneNumber,
        businessPhone: this.state.phoneNumber,
        stripeToken: this.paymentInfo,
        plan: this.state.plan,
        adPlan: this.state.adPlan,
        leadPlan: this.state.leadPlan,
        markets: this.state.markets,
        state: this.state.state.label,
        zip: this.state.zip,
        businessType:
          this.state.businessType.value === "other"
            ? this.state.otherType
            : this.state.businessType.label,
      },
    };
    Axios.post("/api/businessAccount/signup", data)
      .then((d) => {
        this.setState({ ajax: false });
        this.props
          .signIn(this.basicInfo.email, this.basicInfo.password)
          .then((d) => {
            this.props.history.push("/");
          });
      })
      .catch((e) => {
        Swal.fire("Whoops!", String(e), "error");
        this.setState({ ajax: false });
      });
  }
  checkEmail(email) {
    return new Promise((resolve) => {
      if (!email) {
        resolve("Email is required.");
      }
      if (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
        Axios.get("/api/businessAccount/checkEmail?email=" + email).then(
          (d) => {
            // console.log(d.data);
            if (d.data.found) resolve("Email already registered.");
            else resolve("");
          }
        );
      } else {
        resolve("Email is not valid.");
      }
    });
  }
  checkQuota = () => {
    if (!this.state.zip) {
      this.setState({ zipError: "Zip is required." });
    }
    Axios.get(
      `/api/banner/check-quota?zip=${this.state.zip}&businessType=${this.state.businessType.value}`
    )
      .then((d) => {
        if (d.data.result && d.data.result > 0) {
          this.setState({ quotaCheckPassed: true, zipError: "" });
        } else {
          if (d.data.error) {
            this.setState({ zipError: d.data.error });
          } else {
            this.setState({
              zipError: `Quota number for ${this.state.zip} for ${this.state.businessType.label} is reached.`,
            });
          }
          this.setState({ quotaCheckPassed: false });
        }
      })
      .catch(() => {
        this.setState({
          quotaCheckPassed: false,
          zipError: `Quota number for ${this.state.zip} for ${this.state.businessType.label} is reached.`,
        });
      });
  };
  checkBusinessName(name) {
    return new Promise((resolve) => {
      if (!name) {
        resolve("Business name field is required");
      } else {
        Axios.get(
          "/api/businessAccount/checkBusinessName?businessName=" + name
        ).then((d) => {
          // console.log(d.data);
          if (d.data.found) resolve("Business name already registered.");
          else resolve("");
        });
      }
    });
  }
  handleBusinessChange = (val) => {
    this.setState({ businessType: val }, () => {
      // this.checkQuota();
    });
  };
  render() {
    const customSelectStyles = {
      menu: (provided) => ({
        ...provided,
        backgroundColor: "#2d353c",
        zIndex: "2",
      }),
      menuList: (provided) => ({
        ...provided,
        color: "white",
      }),
      menuPortal: (provided) => ({
        ...provided,
        backgroundColor: "#2d353c",
      }),
      singleValue: (provided) => ({
        ...provided,
        color: "white",
        top: "63%",
      }),
      option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected ? "#f29338" : "transparent",
      }),
      input: (provided) => ({
        ...provided,
        color: "white",
      }),
      valueContainer: (provided, state) => ({
        ...provided,
        backgroundColor: state.isFocused ? "#2d353c" : "#2d353c",
        height: "45px",
      }),
    };

    if (this.props.user) return <Redirect to="/" />;
    return (
      <div className="public-page">
        <div id="signUp" className={this.props.freeTrial ? "trial" : ""}>
          <div className="SignUp__content">
            <CircleShape id="SignUp__circle" className="SignUp__circle-shape" />
            <h1 className="SignUp__title">SignUp</h1>
            <div className="steps">
              <div
                className={clsx(
                  { active: this.state.step === 1 },
                  { done: this.state.step > 1 }
                )}
              >
                <h4>Step 1</h4>Features
              </div>
              {this.props.freeTrial ? null : (
                <div
                  className={clsx(
                    { active: this.state.step === 2 },
                    { done: this.state.step > 2 }
                  )}
                >
                  <h4>Step 2</h4>Ad Plans
                </div>
              )}
              {this.props.freeTrial ? null : (
                <div
                  className={clsx(
                    { active: this.state.step === 3 },
                    { done: this.state.step > 3 }
                  )}
                >
                  <h4>Step 3</h4>Lead Plan
                </div>
              )}
              <div
                className={clsx(
                  { active: this.state.step === 4 },
                  { done: this.state.step > 4 }
                )}
              >
                <h4>Step {this.props.freeTrial ? 2 : 4}</h4>Basic Info
              </div>
              <div
                className={clsx(
                  { active: this.state.step === 5 },
                  { done: this.state.step > 5 }
                )}
              >
                <h4>Step {this.props.freeTrial ? 3 : 5}</h4>Finish Registration
              </div>
            </div>
            {this.state.error && (
              <div className="error">{this.state.error}</div>
            )}

            <div
              className={clsx(
                "page SignUp__step2",
                { show: this.state.step === 1 },
                { ajax: this.state.ajax }
              )}
            >
              {this.props.freeTrial ? (
                <div id="plans">
                  <div className="box-holder">
                    <h2>FREE TRIAL</h2>
                    <div className="box">
                      <div className="top top2">
                        <div className="price">1 Month</div>
                      </div>
                      <img
                        src="/img/triangle-2.gif"
                        alt="triangle"
                        className="triangle"
                      />
                      <div className="li">
                        <FaCheck color="#f29338" size="20" />
                        <span>1 User Licence</span>
                      </div>
                      <div className="line2" />
                      <div className="li">
                        <FaCheck color="#f29338" size="20" />
                        <span>
                          Free Month of Basic Ads
                          <small style={{ maxWidth: 285 }}>
                            Show your ad to all reports within a radius of 30
                            miles from your office location
                          </small>
                        </span>
                      </div>
                      <div className="line2" />
                      <div className="li">
                        <FaCheck color="#f29338" size="20" />
                        <span>
                          Lead Starter Pack
                          <small>Get 5 leads on us</small>
                        </span>
                      </div>
                      <div className="line2" />
                      <div className="li">
                        <FaCheck color="#f29338" size="20" />
                        <span>
                          Alerts
                          <small style={{ maxWidth: 285 }}>
                            Instant notifications when the officer files the
                            report
                          </small>
                        </span>
                      </div>
                      <div className="line2" />
                      <div className="li">
                        <FaCheck color="#f29338" size="20" />
                        <span>
                          MyAccident <span className="super">TM</span>
                          <small style={{ maxWidth: 285 }}>
                            Business licence to search and find any accident in
                            the last 12 months
                          </small>
                        </span>
                      </div>
                      <div className="line2" />
                      <div className="li">
                        <FaCheck color="#f29338" size="20" />
                        <span>
                          Pro Search
                          <small>Advanced search options</small>
                        </span>
                      </div>
                      <div className="line2" />
                      <div className="li">
                        <FaCheck color="#f29338" size="20" />
                        <span>
                          MyAccident Complete:
                          <small>
                            TxDOT Certified Cr3 +<br />
                            DMV Owner Info + <br />
                            MyAccident Pro (Your Brand)
                          </small>
                        </span>
                      </div>
                      <div className="line2" />
                      * Trial subject to approval based on consultation
                      <div className="line2" />
                      <button
                        onClick={() =>
                          this.setState({ plan: "basic", step: 4 })
                        }
                      >
                        Start Trial
                      </button>
                    </div>
                  </div>
                </div>
              ) : (
                <PlanChooser
                  signUpPage
                  choosePlan={(plan) => this.setState({ plan, step: 2 })}
                />
              )}
              <div className="footer">
                <button
                  className="auth-btn back"
                  onClick={() => this.props.history.goBack()}
                >
                  Back
                </button>
                <button
                  className={`auth-btn next ${
                    !this.state.plan ? "disabled" : ""
                  }`}
                  onClick={() => this.setState({ step: 2 })}
                  disabled={!this.state.plan}
                >
                  Next Step
                </button>
              </div>
            </div>

            <div
              className={clsx(
                "page SignUp__step2",
                { show: this.state.step === 2 },
                { ajax: this.state.ajax }
              )}
            >
              {this.state.step === 2 ? (
                <AdvertisingOptions
                  choosePlan={(adPlan, markets) => {
                    this.setState({ adPlan, markets });
                    console.log("adPlan: " + adPlan);
                    console.log("markets: " + markets);
                  }}
                />
              ) : null}

              <div
                style={{
                  transition: "all 0.3s",
                  color: "#CCC",
                  opacity:
                    this.state.adPlan === "regions" &&
                    !Object.keys(this.state.markets).some((v) => {
                      return this.state.markets[v];
                    })
                      ? 1
                      : 0,
                }}
              >
                Select one or more regions on map
              </div>

              <div className="footer">
                <button
                  className="auth-btn back"
                  onClick={() => this.setState({ step: 1 })}
                >
                  Back
                </button>
                <button
                  className="auth-btn next"
                  onClick={() => this.setState({ step: 3 })}
                  disabled={
                    !this.state.adPlan ||
                    (this.state.adPlan === "regions" &&
                      !Object.keys(this.state.markets).some((v) => {
                        return this.state.markets[v];
                      }))
                  }
                >
                  Next Step
                </button>
              </div>
            </div>
            <div
              className={clsx(
                "page SignUp__step2",
                { show: this.state.step === 3 },
                { ajax: this.state.ajax }
              )}
            >
              <LeadPlans
                signUpPage
                choosePlan={(leadPlan) => this.setState({ leadPlan, step: 4 })}
              />
              <div className="footer">
                <button
                  className="auth-btn back"
                  onClick={() => this.setState({ step: 2 })}
                >
                  Back
                </button>
                <a
                  onClick={() => this.setState({ step: 4, leadPlan: 0 })}
                  style={{
                    textAlign: "right",
                    display: "block",
                    width: "100%",
                    marginTop: 13,
                    marginRight: 20,
                  }}
                >
                  Skip
                </a>
                <button
                  className="auth-btn next"
                  onClick={() => this.setState({ step: 4 })}
                  disabled={!this.state.leadPlan}
                >
                  Next Step
                </button>
              </div>
            </div>
            <div
              className={clsx(
                "page",
                { show: this.state.step === 4 },
                { ajax: this.state.ajax }
              )}
            >
              <Formik
                initialValues={{
                  businessName: "",
                  firstName: "",
                  lastName: "",
                  email: "",
                  password: "",
                  password2: "",
                  address: "",
                  zip: "",
                  city: "",
                  website: "",
                }}
                validate={(values) => {
                  let errors = {};
                  if (!values.address) errors.address = "Address is required";
                  if (!values.city) errors.city = "City is required";
                  if (!values.firstName)
                    errors.firstName = "First name field is required";
                  if (!values.lastName)
                    errors.lastName = "Last name field is required";
                  if (!values.password)
                    errors.password = "Password is required.";
                  if (!values.password2)
                    errors.password2 = "You need to repeat password.";
                  else if (values.password !== values.password2)
                    errors.password2 = "Passwords don't match";
                  return errors;
                }}
                onSubmit={(values, { setSubmitting }) => {
                  this.basicInfoSubmit(values);
                }}
              >
                {({ errors, touched }) => (
                  <Form>
                    <div className="form-input-group">
                      <fieldset>
                        <label>First Name</label>
                        <Field
                          type="text"
                          name="firstName"
                          autoComplete="off"
                        />
                        <ErrorMessage
                          name="firstName"
                          render={(msg) => <div className="error">{msg}</div>}
                        />
                      </fieldset>
                      <fieldset>
                        <label>Last Name</label>
                        <Field type="text" name="lastName" autoComplete="off" />
                        <ErrorMessage
                          name="lastName"
                          render={(msg) => <div className="error">{msg}</div>}
                        />
                      </fieldset>
                    </div>
                    <div className="form-input-group">
                      <fieldset>
                        <label>Email</label>
                        <Field
                          type="email"
                          name="email"
                          autoComplete="off"
                          validate={this.checkEmail}
                        />
                        <ErrorMessage
                          name="email"
                          render={(msg) => <div className="error">{msg}</div>}
                        />
                      </fieldset>
                      <fieldset>
                        <label>Password</label>
                        <Field type="password" name="password" />
                        <ErrorMessage
                          name="password"
                          render={(msg) => <div className="error">{msg}</div>}
                        />
                      </fieldset>
                    </div>
                    <div className="form-input-group">
                      <fieldset>
                        <label>Repeat Password</label>
                        <Field type="password" name="password2" />
                        <ErrorMessage
                          name="password2"
                          render={(msg) => <div className="error">{msg}</div>}
                        />
                      </fieldset>
                      <fieldset>
                        <label>Your Businesses Website</label>
                        <Field type="text" name="website" autoComplete="off" />
                        <ErrorMessage
                          name="website"
                          render={(msg) => <div className="error">{msg}</div>}
                        />
                      </fieldset>
                    </div>
                    <div className="form-input-group">
                      <fieldset>
                        <label>Business Name</label>
                        <Field
                          type="text"
                          name="businessName"
                          validate={this.checkBusinessName}
                          autoComplete="off"
                        />
                        <ErrorMessage
                          name="businessName"
                          render={(msg) => <div className="error">{msg}</div>}
                        />
                      </fieldset>
                      <fieldset>
                        <label>Business Type</label>
                        <Select
                          className="select"
                          value={this.state.businessType}
                          onChange={this.handleBusinessChange}
                          options={businessOptions}
                          styles={customSelectStyles}
                        />
                        {this.state.businessType.value === "other" ? (
                          <input
                            placeholder="Please specify..."
                            type="text"
                            value={this.state.otherType}
                            onChange={(e) =>
                              this.setState({ otherType: e.target.value })
                            }
                          />
                        ) : null}
                      </fieldset>
                    </div>
                    <article className="business-address-article">
                      <h3>Business Address</h3>
                      <div className="form-input-group">
                        <fieldset>
                          <label>Street Address</label>
                          <Field type="text" name="address" />
                          <ErrorMessage
                            name="address"
                            render={(msg) => <div className="error">{msg}</div>}
                          />
                        </fieldset>
                        <fieldset>
                          <label>Phone Number</label>
                          <IntlTelInput
                            onlyCountries={["us", "rs", "se"]}
                            preferredCountries={["us"]}
                            placeholder=""
                            onPhoneNumberChange={(a, phoneNumber, c) => {
                              // console.log(phoneNumber, c.dialCode);
                              this.setState({
                                country_code: c.dialCode,
                                phoneNumber,
                              });
                            }}
                          />
                        </fieldset>
                      </div>
                      <div className="form-input-group three-bottom">
                        <fieldset>
                          <label>State</label>
                          <Select
                            className="select"
                            value={this.state.state}
                            onChange={this.handleStateChange}
                            options={this.state.stateList}
                            placeholder=""
                            styles={customSelectStyles}
                          />
                        </fieldset>
                        <fieldset>
                          <label>City</label>
                          <Field type="text" name="city" />
                          <ErrorMessage
                            name="city"
                            render={(msg) => <div className="error">{msg}</div>}
                          />
                        </fieldset>
                        <fieldset>
                          <label>Zip Code</label>
                          <input
                            type="number"
                            name="zip"
                            // validate={this.checkQuota}
                            onChange={(e) => {
                              this.setState({ zip: e.target.value }, () => {
                                // this.checkQuota();
                              });
                            }}
                            value={this.state.zip}
                          />
                          {this.state.zipError ? (
                            <div className="error">{this.state.zipError}</div>
                          ) : null}
                        </fieldset>
                      </div>
                    </article>
                    <div className="footer">
                      <button
                        className={"auth-btn back"}
                        onClick={() =>
                          this.setState({ step: this.props.freeTrial ? 1 : 3 })
                        }
                      >
                        Back
                      </button>
                      <button
                        className={`auth-btn next ${
                          !(
                            Object.keys(touched).length &&
                            !Object.keys(errors).length
                          ) ||
                          this.state.state === "" ||
                          this.state.city === "" ||
                          this.state.zip === "" ||
                          this.state.phoneNumber.length < 5 ||
                          (this.state.businessType.value === "other" &&
                            this.state.otherType === "") ||
                          !this.state.quotaCheckPassed
                            ? "disabled"
                            : ""
                        }`}
                        type="submit"
                        disabled={
                          !(
                            Object.keys(touched).length &&
                            !Object.keys(errors).length
                          ) ||
                          this.state.state === "" ||
                          this.state.city === "" ||
                          this.state.zip === "" ||
                          this.state.phoneNumber.length < 5 ||
                          (this.state.businessType.value === "other" &&
                            this.state.otherType === "") ||
                          !this.state.quotaCheckPassed
                        }
                      >
                        Next Step
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
            <div
              className={clsx(
                "page SignUp__agreement",
                { show: this.state.step === 5 },
                { ajax: this.state.ajax }
              )}
            >
              <Formik
                initialValues={{
                  code: "",
                }}
                onSubmit={() => {
                  this.complete();
                }}
              >
                {({ values }) => (
                  <Form>
                    <div id="licence-agreement">
                      <LicenceAgreement />
                    </div>
                    <div className="checkbox-wrapper">
                      <input
                        type="checkbox"
                        id="cbx"
                        style={{ display: "none" }}
                        readOnly
                        checked={this.state.accept1}
                        className="inp-cbx"
                      />
                      <div
                        className="cbx checkbox"
                        htmlFor="cbx"
                        onClick={() =>
                          this.setState({ accept1: !this.state.accept1 })
                        }
                      >
                        <span>
                          <svg width="12px" height="10px" viewBox="0 0 12 10">
                            <polyline points="1.5 6 4.5 9 10.5 1" />
                          </svg>
                        </span>
                        <span className="agreement-text">
                          I AM AUTHORIZED BY '{this.state.businessName}' TO
                          ACCEPT THIS AGREEMENT
                        </span>
                      </div>
                      <input
                        type="checkbox"
                        id="cbx2"
                        style={{ display: "none" }}
                        readOnly
                        checked={this.state.accept2}
                        className="inp-cbx"
                      />
                      <div
                        className="cbx checkbox"
                        htmlFor="cbx2"
                        onClick={() =>
                          this.setState({ accept2: !this.state.accept2 })
                        }
                      >
                        <span>
                          <svg width="12px" height="10px" viewBox="0 0 12 10">
                            <polyline points="1.5 6 4.5 9 10.5 1" />
                          </svg>
                        </span>
                        <span className="agreement-text">
                          I AGREE TO FULLY COMPLY WITH THE TERMS CONTAINED IN
                          THIS AGREEMENT.{" "}
                        </span>
                      </div>
                    </div>
                    <div className="footer">
                      <button
                        className="auth-btn"
                        onClick={() =>
                          this.setState({ step: this.state.step - 1 })
                        }
                      >
                        Back
                      </button>
                      <button
                        className={`auth-btn ${
                          !this.state.phoneNumber ||
                          !this.state.accept1 ||
                          !this.state.accept2
                            ? "disabled"
                            : ""
                        }`}
                        disabled={
                          !this.state.phoneNumber ||
                          !this.state.accept1 ||
                          !this.state.accept2
                        }
                        type="submit"
                      >
                        Finish registration
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(SignupPage);
