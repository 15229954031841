import React from "react";

const FormButton = ({ text, clickHandler, className, disabled }) => (
	<button
		className={className ? className : ""}
		onClick={clickHandler}
		disabled={disabled}
	>
		<span>{text}</span>
	</button>
);

export default FormButton;
