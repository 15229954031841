/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef } from "react";

export default function ThreeDotsMenu({ show, hide, actions, onClick }) {
  const ref = useRef();
  useEffect(() => {
    if (show) ref.current.focus();
  }, [show]);
  return (
    <div
      className={show ? "dot-menu show" : "dot-menu"}
      tabIndex="0"
      onBlur={() => hide()}
      ref={ref}
    >
      {actions.map((a, k) => (
        <a
          key={k}
          onClick={() => {
            onClick(k);
            hide();
          }}
        >
          {a}
        </a>
      ))}
    </div>
  );
}
