/* eslint-disable react/no-direct-mutation-state */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component, Fragment } from "react";
import { FiPlusCircle, FiMinusCircle } from "react-icons/fi";
import moment from "moment";
import UserExpander from "./UserExpander";
import { FaSortAmountDown, FaSortAmountUp } from "react-icons/fa";
import clsx from "clsx";
import { withRouter } from "react-router-dom";
import Axios from "axios";
import { MdCheckBox, MdCheckBoxOutlineBlank, MdClose } from "react-icons/md";
import Checkbox from "./Shared/Checkbox";
import Swal from "sweetalert2";

class AdminAffiliateUsers extends Component {
  state = {
    filter: "",
    sortBy: "",
    reverse: true,
    users: this.props.users,
    ajax: false,
    editUser: null,
    showArchived: false,
  };

  sorter(arr, p, reverse) {
    if (reverse) {
      return arr.slice(0).sort(function (a, b) {
        return a[p] < b[p] ? 1 : a[p] > b[p] ? -1 : 0;
      });
    }
    return arr.slice(0).sort(function (a, b) {
      return a[p] > b[p] ? 1 : a[p] < b[p] ? -1 : 0;
    });
  }

  changeSort(sortBy) {
    let reverse = false;
    if (this.state.sortBy === sortBy) reverse = !this.state.reverse;
    this.setState({
      sortBy,
      reverse,
      users: this.sorter(this.state.users, sortBy, reverse),
    });
  }

  sortIcons(w) {
    if (w === this.state.sortBy && this.state.reverse)
      return <FaSortAmountUp size="16" />;

    if (w === this.state.sortBy && !this.state.reverse)
      return <FaSortAmountDown size="16" />;
  }

  toggleExpand(v) {
    if (!v.expanded) v.expanded = true;
    else v.expanded = false;
    this.setState({ users: this.state.users });
  }

  getInsightToken(id) {
    Axios.post("/api/users/getInsightsToken", { id }).then((d) => {
      if (d.data.success) {
        localStorage.setItem("me", this.props.user._id);
        window.location.href = "/";
      }
    });
  }

  saveUser() {
    this.setState({ ajax: true });
    Axios.post("/api/users/update", { ...this.state.editUser })
      .then((d) => {
        this.state.users.some((v, k) => {
          if (this.state.editUser._id === v._id) {
            this.state.users[k] = this.state.editUser;
            this.setState({
              users: this.state.users,
              ajax: false,
              editUser: null,
            });
            return true;
          }
          return false;
        });
      })
      .catch((e) => {
        this.setState({ ajax: false });
        let html = "";
        e.response.data.errors.forEach((v) => {
          html += v.msg + "<br />";
        });
        Swal.fire({
          title: "Some errors happened",
          html,
          type: "error",
        });
      });
  }

  toggleArchive(u) {
    Axios.post("/api/users/setArchivedValue", {
      id: u._id,
      value: !u.archived,
    });
    this.state.users.some((v) => {
      if (v._id === u._id) {
        v.archived = !u.archived;
        return true;
      }
      return false;
    });
    this.setState({
      users: this.state.users,
    });
  }

  render() {
    return (
      <>
        <div
          className={clsx(
            "modal",
            { show: this.state.editUser },
            { ajax: this.state.ajax }
          )}
          data-testid={"edit-user-modal"}
        >
          <button
            className="close"
            onClick={() => this.setState({ editUser: null })}
            data-testid={"close-edit-user"}
          >
            <MdClose />
          </button>
          <h2>Edit User</h2>
          <br />
          <div className="row">
            <div className="column">
              <label id="firstName">First name:</label>
              <input
                type="text"
                value={this.state.editUser?.firstName || ""}
                min={0}
                aria-labelledby="firstName"
                onChange={(e) =>
                  this.setState({
                    editUser: {
                      ...this.state.editUser,
                      firstName: e.target.value,
                    },
                  })
                }
              />
            </div>
            <div className="column">
              <label id="lastName">Last name:</label>
              <input
                type="text"
                value={this.state.editUser?.lastName || ""}
                min={0}
                aria-labelledby="lastName"
                onChange={(e) =>
                  this.setState({
                    editUser: {
                      ...this.state.editUser,
                      lastName: e.target.value,
                    },
                  })
                }
              />
            </div>
          </div>

          <div className="row">
            <div className="column">
              <label id="email">Email:</label>
              <input
                type="text"
                value={this.state.editUser?.email || ""}
                min={0}
                aria-labelledby="email"
                onChange={(e) =>
                  this.setState({
                    editUser: {
                      ...this.state.editUser,
                      email: e.target.value,
                    },
                  })
                }
              />
            </div>
            <div className="column">
              <label id="phone">Mobile Phone:</label>
              <input
                type="text"
                value={this.state.editUser?.phone || ""}
                min={0}
                aria-labelledby="phone"
                onChange={(e) =>
                  this.setState({
                    editUser: {
                      ...this.state.editUser,
                      phone: e.target.value,
                    },
                  })
                }
              />
            </div>
          </div>

          <div className="row">
            <div className="column">
              <label id="businessName">Business Name:</label>
              <input
                type="text"
                value={this.state.editUser?.businessName || ""}
                min={0}
                aria-labelledby="businessName"
                onChange={(e) =>
                  this.setState({
                    editUser: {
                      ...this.state.editUser,
                      businessName: e.target.value,
                    },
                  })
                }
              />
            </div>
            <div className="column">
              <label id="address">Address:</label>
              <input
                type="text"
                value={this.state.editUser?.address || ""}
                min={0}
                aria-labelledby="address"
                onChange={(e) =>
                  this.setState({
                    editUser: {
                      ...this.state.editUser,
                      address: e.target.value,
                    },
                  })
                }
              />
            </div>
          </div>

          <div className="row">
            <div className="column">
              <label id="legalName">Legal Name:</label>
              <input
                type="text"
                value={this.state.editUser?.legalName || ""}
                min={0}
                aria-labelledby="legalName"
                onChange={(e) =>
                  this.setState({
                    editUser: {
                      ...this.state.editUser,
                      legalName: e.target.value,
                    },
                  })
                }
              />
            </div>
            <div className="column">
              <label id="address2">Address 2:</label>
              <input
                type="text"
                value={this.state.editUser?.address2 || ""}
                min={0}
                aria-labelledby="address2"
                onChange={(e) =>
                  this.setState({
                    editUser: {
                      ...this.state.editUser,
                      address2: e.target.value,
                    },
                  })
                }
              />
            </div>
          </div>

          <div className="row">
            <div className="column">
              <label id="businessPhone">Business Phone:</label>
              <input
                type="text"
                value={this.state.editUser?.businessPhone || ""}
                min={0}
                aria-labelledby="businessPhone"
                onChange={(e) =>
                  this.setState({
                    editUser: {
                      ...this.state.editUser,
                      businessPhone: e.target.value,
                    },
                  })
                }
              />
            </div>
            <div className="column">
              <label id="state">State</label>
              <input
                type="text"
                value={this.state.editUser?.state || ""}
                min={0}
                aria-labelledby="state"
                onChange={(e) =>
                  this.setState({
                    editUser: {
                      ...this.state.editUser,
                      state: e.target.value,
                    },
                  })
                }
              />
            </div>
            <div className="column">
              <label id="zip">Zip</label>
              <input
                type="text"
                value={this.state.editUser?.zip || ""}
                min={0}
                aria-labelledby="zip"
                onChange={(e) =>
                  this.setState({
                    editUser: {
                      ...this.state.editUser,
                      zip: e.target.value,
                    },
                  })
                }
              />
            </div>
          </div>
          <div className="row">
            <div className="column">
              <label id="website">Website:</label>
              <input
                type="text"
                value={this.state.editUser?.website || ""}
                min={0}
                aria-labelledby="website"
                onChange={(e) =>
                  this.setState({
                    editUser: {
                      ...this.state.editUser,
                      website: e.target.value,
                    },
                  })
                }
              />
            </div>
          </div>

          <div className="row">
            <div className="column">
              <Checkbox
                checked={this.state.editUser?.active}
                text="Active"
                onClick={() =>
                  this.setState({
                    editUser: {
                      ...this.state.editUser,
                      active: !this.state.editUser.active,
                    },
                  })
                }
              />
            </div>
            <div className="column">
              <label>Affiliate id:</label>
              <div>{this.state.editUser?._id}</div>
            </div>
          </div>
          <div className="row">
            <div className="column">
              <label id="password">Set new password for user:</label>
              <input
                type="text"
                value={this.state.editUser?.password || ""}
                min={0}
                aria-labelledby="password"
                onChange={(e) =>
                  this.setState({
                    editUser: {
                      ...this.state.editUser,
                      password: e.target.value,
                    },
                  })
                }
              />
            </div>
          </div>
          <div className="footer">
            <button
              onClick={() => this.setState({ editUser: null })}
              className="outline"
            >
              Cancel
            </button>
            <button onClick={() => this.saveUser()}>Save</button>
          </div>
        </div>
        <Checkbox
          text="Show archived"
          onClick={() =>
            this.setState({ showArchived: !this.state.showArchived })
          }
          checked={this.state.showArchived}
        />
        <br />
        <table>
          <thead>
            <tr>
              <th></th>
              <th>
                <a onClick={() => this.changeSort("created_at")}>
                  Created At{this.sortIcons("created_at")}
                </a>
              </th>
              <th>Business Name</th>
              <th>Active</th>

              <th style={{ textAlign: "center" }}>Archived</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {this.state.users
              .filter((v) => !v.archived || this.state.showArchived)
              .map((v) => (
                <Fragment key={v._id}>
                  <tr>
                    <td>
                      <a
                        onClick={() => this.toggleExpand(v)}
                        data-testid={`affiliate-toggle-expand-${v._id}`}
                      >
                        {v.expanded ? (
                          <FiMinusCircle
                            size={24}
                            data-testid={`affiliate-close-expanded-${v._id}`}
                          />
                        ) : (
                          <FiPlusCircle
                            size={24}
                            data-testid={`affiliate-show-expanded-${v._id}`}
                          />
                        )}
                      </a>
                    </td>
                    <td>{moment(v.created_at).format("ll")}</td>
                    <td>
                      {v.businessName} <br />
                      <span style={{ opacity: 0.5 }}>{v.email}</span>
                    </td>
                    <td>{v.active ? "Yes" : "No"}</td>
                    <td style={{ textAlign: "center" }}>
                      <a
                        onClick={() => this.toggleArchive(v)}
                        data-testid={`affiliate-toggle-archive-${v._id}`}
                      >
                        {v.archived ? (
                          <MdCheckBox
                            size={24}
                            data-testid="affiliate-checked-archived-icon"
                          />
                        ) : (
                          <MdCheckBoxOutlineBlank
                            size={24}
                            data-testid="affiliate-unchecked-archived-icon"
                          />
                        )}
                      </a>
                    </td>
                    <td>
                      <button
                        style={{ width: "32%", marginRight: "1%" }}
                        data-testid={`edit-affiliate-user-${v._id}`}
                        onClick={() =>
                          this.setState(
                            {
                              editUser: { ...v },
                            },
                            () => {
                              document.querySelector(".modal input").focus();
                            }
                          )
                        }
                      >
                        Edit
                      </button>
                      <button
                        style={{ width: "32%", marginRight: "1%" }}
                        onClick={() => this.getInsightToken(v._id)}
                        disabled={this.props.user._id === v._id}
                        data-testid={`affiliate-insight-token-${v._id}`}
                      >
                        Dashboard
                      </button>
                      <button
                        className="red"
                        disabled={this.props.user._id === v._id}
                        onClick={() => this.props.delete(v)}
                        style={{ width: "32%" }}
                        data-testid={`delete-button-${v._id}`}
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                  <tr
                    className={clsx("expander", {
                      show: v.expanded,
                    })}
                  >
                    <td colSpan="8">
                      {v.expanded ? (
                        <UserExpander
                          data={v}
                          toggleState={(s) =>
                            this.props.toggleUserState(v._id, s)
                          }
                          toggleAutoSMS={() => this.props.toggleAutoSMS(v)}
                          toggleRejectionTracking={() =>
                            this.props.toggleRejectionTracking(v)
                          }
                          toggleAdoptContractor={() =>
                            this.props.toggleAdoptContractor(v)
                          }
                          saveAutoSMStext={(e) => {
                            e.preventDefault();
                            this.props.saveAutoSMStext(v, e);
                          }}
                          saveStatePrices={(e) =>
                            this.props.saveStatePrices(e, v)
                          }
                          updateUserWeight={(user) =>
                            this.props.updateUserWeight(user)
                          }
                          updateUserWeightName={(user) =>
                            this.props.updateUserWeightName(user)
                          }
                          users={this.props.allUsers}
                        />
                      ) : null}
                    </td>
                  </tr>
                </Fragment>
              ))}
          </tbody>
        </table>
      </>
    );
  }
}

export default withRouter(AdminAffiliateUsers);
