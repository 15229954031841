import React, { useEffect, useState } from "react";
import * as CanvasJSReact from "../../canvasjs.react";
import moment from "moment";
import { defaultGraphOptions } from "../LeadStats";
import Checkbox from "../Shared/Checkbox";

const CanvasJSChart = CanvasJSReact.CanvasJSChart;

const isPaid = (referer) => {
  return referer && referer.indexOf("campaignid=") > -1;
};

export default function Referer2({ leads, view, users }) {
  const [graphData, setGraphData] = useState({});
  // const [leadType, setLeadType] = useState("");
  const [includeColds, setIncludeColds] = useState(false);

  useEffect(() => {
    // console.log(leads);
    // console.log(users);
    // console.log(filterUsers);
    const gd = { ...defaultGraphOptions };
    let data = {};
    // console.log(keys);

    leads.forEach((v) => {
      let period;
      if (view === "daily")
        period = new moment(v.createdAt).format("YYYY-MM-DD");
      if (view === "monthly") period = v.createdAt.substr(0, 7);
      if (view === "weekly") {
        period =
          "Week " +
          moment(v.createdAt).isoWeek() +
          ", " +
          new Date(v.createdAt).getFullYear();
      }
      if (!data[period]) data[period] = { paid: 0, organic: 0 };

      if (
        (v.lead_level !== "cold" || includeColds) &&
        v.lead_type === "CALawyer" &&
        v.affiliate !== "611bd223d47d930019ce7f26"
      ) {
        data[period][isPaid(v.referer) ? "paid" : "organic"]++;
      }
    });

    gd.toolTip = {
      shared: true,
      // content: "{label}<hr />{name}: {y}",
    };
    // console.log(data);

    gd.data = [];
    gd.data.push({
      type: "stackedColumn",
      color: "#F00",
      showInLegend: true,
      name: "paid",
      dataPoints: Object.keys(data).map((vv) => {
        return {
          label: view === "daily" ? moment(vv).format("ll") : vv,
          y: data[vv]["paid"],
        };
      }),
    });

    gd.data.push({
      type: "stackedColumn",
      color: "#00F",
      showInLegend: true,
      name: "organic",
      dataPoints: Object.keys(data).map((vv) => {
        return {
          label: view === "daily" ? moment(vv).format("ll") : vv,
          y: data[vv]["organic"],
        };
      }),
    });

    setGraphData(gd);
  }, [leads, includeColds, view]);

  return (
    <div>
      <h2>Paid Vs Organic (by referer) </h2>
      <Checkbox
        text="Include colds"
        checked={includeColds}
        onClick={() => setIncludeColds(!includeColds)}
      />

      <br />
      <CanvasJSChart options={graphData} />
    </div>
  );
}
