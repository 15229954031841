/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import Axios from "axios";
import { Redirect, withRouter } from "react-router-dom";
import NewCaseModal from "../components/NewCaseModal";
import { FaSortAmountDown, FaSortAmountUp } from "react-icons/fa";
import moment from "moment";

class WebCases extends React.Component {
	state = {
		loading: true,
		list: [],
		accident: null,
		page: 1,
		sort: "created_at",
		order: -1,
		pages: 0
	};
	componentDidMount() {
		this.load();
	}

	load() {
		this.setState({ loading: true });
		Axios.get(
			`/api/stripe/payments?search&sort=${this.state.sort}&order=${this.state.order}&page=${this.state.page}&limit=10`
		).then(d => {
			// console.log(d.data.payments);
			this.setState({
				loading: false,
				list: d.data.payments,
				pages: d.data.pages
			});
		});
	}

	switchPage(p) {
		let page;
		switch (p) {
			case "first":
				page = 1;
				break;

			case "last":
				page = this.state.pages;
				break;

			case "next":
				page = this.state.page + 1;
				break;

			case "previous":
				page = this.state.page - 1;
				break;

			default:
				page = 1;
				break;
		}
		this.setState({ page }, () => {
			this.load();
		});
	}

	changeSort(sort) {
		let order = -1;
		if (this.state.sort === sort) order = this.state.order * -1;
		this.setState(
			{
				sort,
				order
			},
			() => {
				this.load();
			}
		);
	}

	sortIcons(w) {
		if (w === this.state.sort && this.state.order === -1)
			return <FaSortAmountUp size="16" />;

		if (w === this.state.sort && this.state.order === 1)
			return <FaSortAmountDown size="16" />;
	}

	render() {
		if (!this.props.user) return <Redirect to="/signin" />;
		if (this.state.loading)
			return (
				<div id="admin-page">
					<img
						src="/img/ajax-loader.gif"
						alt="loadingRequests..."
						className="loader-gif"
					/>
					&nbsp;Loading web cases...
				</div>
			);
		if (!this.state.list.length)
			return <div id="admin-page">No web cases found...</div>;
		return (
			<>
				{this.state.accident ? (
					<NewCaseModal
						show={this.state.accident}
						close={() => this.setState({ accident: null })}
						accidentID={this.state.accident.accident_id}
						user={this.props.user}
						firstName={this.state.accident.user.split(" ")[0]}
						lastName={
							this.state.accident.user.split(" ")[1]
								? this.state.accident.user.split(" ")[1]
								: ""
						}
						caseId={this.state.accident.user_email}
					/>
				) : null}

				<div id="admin-page">
					<table>
						<thead>
							<tr>
								<th>
									<a onClick={() => this.changeSort("created_at")}>
										Purchased {this.sortIcons("created_at")}
									</a>
								</th>
								<th>Amount</th>
								<th>Report Sent</th>
								<th>
									<a onClick={() => this.changeSort("user_email")}>
										Email {this.sortIcons("user_email")}
									</a>
								</th>
								<th>Paid</th>
								<th>Pending</th>
								<th>Refunded</th>
								<th>
									<a onClick={() => this.changeSort("user")}>
										Name {this.sortIcons("user")}
									</a>
								</th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							{this.state.list.map((v, k) => (
								<tr key={v.accident_id + "_" + k}>
									<td>{moment(v.created_at).format("ll")}</td>
									<td>${v.amount / 100}</td>
									<td>{v.report_sent ? "No" : "Yes"}</td>
									<td>{v.user_email}</td>
									<td>{v.paid ? "Yes" : "No"}</td>
									<td>{v.pending ? "Yes" : "No"}</td>
									<td>{v.refunded ? "Yes" : "No"}</td>
									<td>{v.user}</td>
									<td>
										<button onClick={() => this.setState({ accident: v })}>
											Add to dashboard
										</button>
									</td>
								</tr>
							))}
						</tbody>
					</table>
					<div id="pagination">
						<a
							onClick={() => this.switchPage("first")}
							className={this.state.page === 1 ? "disabled" : ""}
						>
							First
						</a>
						<a
							onClick={() => this.switchPage("previous")}
							className={this.state.page === 1 ? "disabled" : ""}
						>
							Previous
						</a>
						<span>{this.state.page}</span>
						<a
							onClick={() => this.switchPage("next")}
							className={this.state.pages === this.state.page ? "disabled" : ""}
						>
							Next
						</a>
						<a
							onClick={() => this.switchPage("last")}
							className={this.state.pages === this.state.page ? "disabled" : ""}
						>
							Last
						</a>
						<br />
						Total pages: {this.state.pages}
					</div>
				</div>
			</>
		);
	}
}

export default withRouter(WebCases);
