import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import Axios from "axios";
import { MdClose } from "react-icons/md";
import { withRouter } from "react-router-dom";
const getSalesPrice = (price) => {
  if (price === "0000000000") {
    return "Unknown price";
  } else {
    return (
      "$" +
      price
        .replace(/^0+/, "")
        .slice(0, -2)
        .replace(/\B(?=(\d{3})+(?!\d))/g, ".") +
      ",00"
    );
  }
};

const getWeight = (weight) => {
  if (weight === "000000") {
    return "Unknown Weight";
  } else {
    return (
      weight.replace(/^0+/, "").replace(/\B(?=(\d{3})+(?!\d))/g, ".") + " lbs"
    );
  }
};

const range = (i) => {
  let x = [];
  let j = 0;
  while (x.push(j++) < i) {}
  return x;
};

const displayTable = (data) => {
  const numberOfRounds = range(Math.floor(data.length / 4) + 1);
  return numberOfRounds.map((i) => {
    return (
      <div className="row" key={i}>
        {data[4 * i] ? (
          <div className="column">
            <div>
              <i>{data[4 * i]._attributes.label}</i>
            </div>
            <div>{data[4 * i]._text}</div>
          </div>
        ) : null}
        {data[4 * i + 1] ? (
          <div className="column">
            <div>
              <i>{data[4 * i + 1]._attributes.label}</i>
            </div>
            <div>
              {data[4 * i + 1]._attributes.label === "Vehicle Empty Weight"
                ? getWeight(data[4 * i + 1]._text)
                : data[4 * i + 1]._text}
            </div>
          </div>
        ) : null}
        {data[4 * i + 2] ? (
          <div className="column">
            <div>
              <i>{data[4 * i + 2]._attributes.label}</i>
            </div>
            <div>
              {data[4 * i + 2]._attributes.label === "Vehicle Gross Weight"
                ? getWeight(data[4 * i + 2]._text)
                : data[4 * i + 2]._text}
            </div>
          </div>
        ) : null}
        {data[4 * i + 3] ? (
          <div className="column">
            <div>
              <i>{data[4 * i + 3]._attributes.label} </i>
            </div>
            <div>
              {data[4 * i + 3]._attributes.label === "Vehicle Sales Price"
                ? getSalesPrice(data[4 * i + 3]._text)
                : data[4 * i + 3]._text}
            </div>
          </div>
        ) : (
          <div className="column">
            <div>
              <i />
            </div>
            <div />
          </div>
        )}
      </div>
    );
  });
};

export const stringTable = (data) => {
  let s = "";
  const numberOfRounds = range(Math.floor(data.length / 4) + 1);
  // eslint-disable-next-line array-callback-return
  numberOfRounds.map((i) => {
    s =
      s +
      `<div class='field'><i>${data[4 * i]._attributes.label}</i>${
        data[4 * i]._text
      }</div>`;
    if (data[4 * i + 1])
      s =
        s +
        `<div class='field'><i>${data[4 * i + 1]._attributes.label}</i>${
          data[4 * i + 1]._attributes.label === "Vehicle Empty Weight"
            ? getWeight(data[4 * i + 1]._text)
            : data[4 * i + 1]._text
        }</div>`;
    if (data[4 * i + 2])
      s =
        s +
        `<div class='field'><i>${data[4 * i + 2]._attributes.label}</i>${
          data[4 * i + 2]._attributes.label === "Vehicle Gross Weight"
            ? getWeight(data[4 * i + 2]._text)
            : data[4 * i + 2]._text
        }</div>`;
    if (data[4 * i + 3])
      s =
        s +
        `<div class='field'><i>${data[4 * i + 3]._attributes.label}</i>${
          data[4 * i + 3]._attributes.label === "Vehicle Sales Price"
            ? getSalesPrice(data[4 * i + 3]._text)
            : data[4 * i + 3]._text
        }</div>`;
  });

  return s;
};

class OwnersPage extends Component {
  state = {
    loading: true,
    owners: [],
    isDetailedOpen: null,
  };
  componentDidMount() {
    if (this.props.match.params.id)
      Axios.get(`/api/accident/${this.props.match.params.id}/detailed`)
        .then((d) => {
          if (d.data.accident && d.data.accident.vehicles) {
            let ownersArray = [];
            d.data.accident.vehicles.forEach((v) => {
              if (v && v.vin_code) {
                // console.log(v.vin_code);
                Axios.get(
                  `/api/business/public-data/find/vin?vin=${v.vin_code}`
                )
                  .then((res) => {
                    if (res.data.success) ownersArray.push(res.data);
                    else
                      ownersArray.push(
                        res.data.error + " (" + v.vin_code + ")"
                      );
                    this.setState(
                      { owners: ownersArray, loading: false },
                      () => {
                        console.log(this.state.owners);
                      }
                    );
                  })
                  .catch((e) => {
                    this.setState({ loading: false });
                  });
              }
            });
          }
        })
        .catch((e) => {
          this.setState({ loading: false });
        });
  }

  openDetailed = (index) => {
    this.setState({ isDetailedOpen: index });
  };

  closeDetailed = () => {
    this.setState({ isDetailedOpen: null });
  };

  download(filename, text) {
    let element = document.createElement("a");
    element.setAttribute(
      "href",
      "data:text/html;charset=utf-8," + encodeURIComponent(text)
    );
    element.setAttribute("download", filename);

    element.style.display = "none";
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  }
  prepareDownloadVIN(filename, index) {
    // owners[this.state.isDetailedOpen].data[0].dataitem.textdata.field)
    let html = `
			<!DOCTYPE html>
			<html>
			<head>
				<meta charset="utf-8">
				<meta http-equiv="X-UA-Compatible" content="IE=edge">
				<title>Owner Report</title>
				<meta name="viewport" content="width=device-width, initial-scale=1">
				<style>
				body {
					font-family:sans-serif;
				}
				#holder {
					display: flex;
    			flex-wrap: wrap;
				}
				.field {
					width:25%;
					float:left;
					padding:10px;
					box-sizing:border-box;
					border-bottom:#CCC 1px solid;
				}
				.field i {
					display:block;
					font-weight:bold;
				}
				</style>
			</head>

			<body>
			<h1>${filename}</h1>
			<div id="holder">
				${stringTable(this.state.owners[index].data[0].dataitem.textdata.field)}
			</div>
			</body>
			</html>
	`;
    // console.log(html);
    this.download(filename + ".html", html);
  }
  prepareDownloadAll() {
    let html = `
			<!DOCTYPE html>
			<html>
			<head>
				<meta charset="utf-8">
				<meta http-equiv="X-UA-Compatible" content="IE=edge">
				<title>Owner Report</title>
				<meta name="viewport" content="width=device-width, initial-scale=1">
				<style>
				body {
					font-family:sans-serif;
				}
				#holder {
					display: flex;
    			flex-wrap: wrap;
				}
				.field {
					width:25%;
					float:left;
					padding:10px;
					box-sizing:border-box;
					border-bottom:#CCC 1px solid;
				}
				.field i {
					display:block;
					font-weight:bold;
				}
				</style>
			</head>

			<body>`;
    this.state.owners.forEach((v) => {
      if (typeof owners === "string") {
        html = html + v + "<br />";
      } else {
        html =
          html +
          `<h1>${
            v.data[0].dataitem.textdata.field[22]._text +
            " " +
            v.data[0].dataitem.textdata.field[23]._text +
            " - " +
            v.data[0].dataitem.textdata.field[0]._text
          }</h1>`;
        html =
          html +
          `<div id="holder">${stringTable(
            v.data[0].dataitem.textdata.field
          )}</div><br /><br />`;
      }
    });
    html = html + `</body></html>`;

    this.download("Owners Report.html", html);
  }
  render() {
    const { owners } = this.state;

    if (this.props.user === null) return <Redirect to="/signin" />;
    if (this.state.loading)
      return (
        <div id="admin-page">
          <img
            src="/img/ajax-loader.gif"
            alt="Loading..."
            className="loader-gif"
          />
          &nbsp;Loading...
        </div>
      );

    return (
      <div id="admin-page">
        <button onClick={() => this.props.history.push("/")}>
          Back to Dashboard
        </button>
        <br />
        <br />
        <div
          className={
            this.state.isDetailedOpen !== null
              ? "modal search show"
              : "modal search"
          }
        >
          <button
            className="close"
            onClick={() => this.setState({ isDetailedOpen: null })}
          >
            <MdClose />
          </button>
          <h2>Detailed</h2>
          {this.state.isDetailedOpen !== null &&
            owners[this.state.isDetailedOpen] &&
            owners[this.state.isDetailedOpen].data &&
            owners[this.state.isDetailedOpen].data[0] &&
            owners[this.state.isDetailedOpen].data[0].dataitem &&
            owners[this.state.isDetailedOpen].data[0].dataitem.textdata &&
            owners[this.state.isDetailedOpen].data[0].dataitem.textdata.field &&
            displayTable(
              owners[this.state.isDetailedOpen].data[0].dataitem.textdata.field
            )}
        </div>
        <h1>Owners info</h1>
        {this.state.owners.length &&
        this.state.owners[0].data &&
        this.state.owners[0].data[0].dataitem ? (
          this.state.owners.map((owner, index) => {
            return (
              <>
                <button
                  onClick={() => this.prepareDownloadAll()}
                  style={{ position: "absolute", left: 300 }}
                >
                  Download
                </button>
                <div key={index} className="owner">
                  <h2>
                    {owner.data[0].dataitem.textdata.field[22]._text}{" "}
                    {owner.data[0].dataitem.textdata.field[23]._text}:{" "}
                    {owner.data[0].dataitem.textdata.field[35]._text}, plate:
                    {owner.data[0].dataitem.textdata.field[13]._text}
                  </h2>
                  <div className="row">
                    <div className="column">
                      <div className="bold">Current Owner:</div>
                      <div className="row">
                        <div className="column">Name:</div>
                        <div className="column">
                          {owner.data[0].dataitem.textdata.field[0]._text}
                        </div>
                      </div>
                      <div className="row">
                        <div className="column">Address:</div>
                        <div className="column">
                          {owner.data[0].dataitem.textdata.field[1]._text}
                        </div>
                      </div>
                      <div className="row">
                        <div className="column" />
                        <div className="column">
                          {owner.data[0].dataitem.textdata.field[2]._text},{" "}
                          {owner.data[0].dataitem.textdata.field[3]._text}{" "}
                          {owner.data[0].dataitem.textdata.field[4]._text}
                        </div>
                      </div>
                    </div>
                    <div className="column">
                      <div className="bold">Previous Owner:</div>
                      <div className="row">
                        <div className="column">Name:</div>
                        <div className="column">
                          {owner.data[0].dataitem.textdata.field[5]._text}
                        </div>
                      </div>
                      <div className="row">
                        <div className="column">Address:</div>
                        <div className="column">
                          {owner.data[0].dataitem.textdata.field[6]._text},{" "}
                          {owner.data[0].dataitem.textdata.field[7]._text}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="column">
                      <div className="bold">Vehicle Details:</div>
                      <div>
                        Model: {owner.data[0].dataitem.textdata.field[24]._text}
                      </div>
                      <div>
                        Type: {owner.data[0].dataitem.textdata.field[26]._text}
                      </div>
                      <div>
                        Color: {owner.data[0].dataitem.textdata.field[27]._text}
                      </div>
                      <div>
                        Salvage Status:{" "}
                        {owner.data[0].dataitem.textdata.field[54]._text}
                      </div>
                      <div>
                        Odometer:{" "}
                        {owner.data[0].dataitem.textdata.field[42]._text}
                      </div>
                    </div>
                    <div className="column">
                      <div />
                      <div>
                        Class: {owner.data[0].dataitem.textdata.field[29]._text}
                      </div>
                      <div>
                        Sales Price:{" "}
                        {getSalesPrice(
                          owner.data[0].dataitem.textdata.field[31]._text
                        )}
                      </div>
                      <div>
                        Weight:{" "}
                        {getWeight(
                          owner.data[0].dataitem.textdata.field[34]._text
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="column">
                      <div className="bold">Lien Holder Details:</div>
                      <div>None</div>
                    </div>
                    <div className="column">
                      <button
                        className="btn-flat button-detailed"
                        onClick={() => this.openDetailed(index)}
                      >
                        <div className="detailed">Detailed</div>
                      </button>{" "}
                      <button
                        className="btn-flat button-detailed"
                        style={{ marginLeft: "10px" }}
                        onClick={() =>
                          this.prepareDownloadVIN(
                            owner.data[0].dataitem.textdata.field[22]._text +
                              " " +
                              owner.data[0].dataitem.textdata.field[23]._text +
                              " - " +
                              owner.data[0].dataitem.textdata.field[0]._text,
                            index
                          )
                        }
                      >
                        <div className="detailed">Download</div>
                      </button>
                    </div>
                  </div>
                </div>
              </>
            );
          })
        ) : (
          <div>
            One or more errors ocurred while getting the public data.
            <br />
            {this.state.owners.map((o) => (
              <div>{o}</div>
            ))}
          </div>
        )}
      </div>
    );
  }
}
export default withRouter(OwnersPage);
