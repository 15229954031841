import React from "react";

const FormInput = ({
	type,
	name,
	changeHandler,
	value,
	placeholder,
	className
}) => (
	<input
		type={type}
		name={name}
		onChange={changeHandler}
		value={value}
		placeholder={placeholder ? placeholder : ""}
		className={className ? className : ""}
	/>
);

export default FormInput;
