import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import {
  MdDashboard,
  MdLocationSearching,
  MdSupervisorAccount,
  MdPayment,
  MdSettings,
  MdNotificationsActive,
  MdLock,
} from "react-icons/md";
import { FaAdversal } from "react-icons/fa";
export default class AdminMenu extends Component {
  render() {
    // console.log(this.props.user);
    return (
      <div id="admin-menu">
        <div className="logo">
          <img src="/img/logo.png" alt="Logo" />
        </div>
        <NavLink activeClassName="active" exact to={"/"}>
          <MdDashboard /> <span>Dashboard</span>
        </NavLink>
        {this.props.user.role.id !== 15 ? (
          <NavLink activeClassName="active" exact to={"/search"}>
            <MdLocationSearching />
            <span>Find Accidents</span>
          </NavLink>
        ) : null}
        {this.props.user.role.id !== 15 ? (
          <NavLink activeClassName="active" exact to={"/pre-alert"}>
            <MdNotificationsActive />
            <span>Set Up Alert</span>
          </NavLink>
        ) : null}
        {this.props.user &&
        (this.props.user.role.id === 10 || this.props.user.role.id === 11) ? (
          <NavLink activeClassName="active" exact to={"/users"}>
            <MdSupervisorAccount />
            <span>Users</span>
          </NavLink>
        ) : null}
        {this.props.user &&
        (this.props.user.role.id === 10 ||
          this.props.user.role.id === 11 ||
          this.props.user.role.id === 15) ? (
          <NavLink activeClassName="active" exact to={"/account"}>
            <MdPayment />
            <span>Account</span>
          </NavLink>
        ) : null}
        {this.props.user && this.props.user.role.id === 10 ? (
          <NavLink activeClassName="active" exact to={"/admin"}>
            <MdSettings />
            <span>Admin</span>
          </NavLink>
        ) : null}
        {this.props.user &&
        (this.props.user.role.id === 10 || this.props.user.role.id === 14) ? (
          <NavLink activeClassName="active" exact to={"/alert-admin"}>
            <MdSettings />
            <span>User Alerts</span>
          </NavLink>
        ) : null}
        {this.props.user &&
        (this.props.user.role.id === 10 || this.props.user.role.id === 14) ? (
          <NavLink activeClassName="active" exact to={"/business-alert-admin"}>
            <MdSettings />
            <span>Business Alerts</span>
          </NavLink>
        ) : null}
        {this.props.user && this.props.user.role.id === 10 ? (
          <NavLink activeClassName="active" exact to={"/cms"}>
            <MdSettings />
            <span>Admin - CMS</span>
          </NavLink>
        ) : null}
        {this.props.user && this.props.user.role.id === 10 ? (
          <NavLink activeClassName="active" exact to={"/police-agencies"}>
            <MdSettings />
            <span>Police Agencies</span>
          </NavLink>
        ) : null}

        {/* {this.props.user &&
				(this.props.user.email === "dacko.mmm@gmail.com" ||
					this.props.user.email === "andrew@myaccident.org") ? (
					<NavLink activeClassName="active" exact to={"/web-cases"}>
						<FaBriefcase />
						<span>Web Cases</span>
					</NavLink>
				) : null} */}
        {this.props.user.role.id !== 15 ? (
          <NavLink activeClassName="active" exact to={"/advertisement"}>
            <FaAdversal />
            <span>Advertisement</span>
            {this.props.user.sub_plan &&
            this.props.user.sub_plan === "Basic" ? (
              <MdLock
                style={{ position: "absolute", right: "-10px", top: "6px" }}
              />
            ) : null}
          </NavLink>
        ) : null}
      </div>
    );
  }
}
