/* eslint-disable react/jsx-no-target-blank */
import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
// import { FaCheck } from "react-icons/fa";
import CircleShape from "../components/Shared/CircleShape";
import BackToTop from "../components/Shared/BackToTop";
// import NumberOne from "../components/Shared/NumberOne";
// import NumberTwo from "../components/Shared/NumberTwo";
// import NumberThree from "../components/Shared/NumberThree";
import DashboardTabs from "./dashboard-tabs";
import InsuranceProgramHome from "../components/advertisement/InsuranceProgramHome";
// import PlanChooser from "../components/PlanChooser";
import LegalFooter from "../components/advertisement/LegalFooter";
import AffiliateDashboard from "../affiliate/AffiliateDashboard";

class HomePage extends Component {

  componentDidMount(){
    if(!this.props.user) {
      this.props.history.push("/signin");
    }
  }

  choosePlan(plan) {
    this.props.history.push("/signup/" + plan);
  }
  render() {
    if (this.props.user && this.props.user.account_type === "business")
      return <DashboardTabs {...this.props} />;
    if (this.props.user && this.props.user.account_type === "affiliate")
      return <AffiliateDashboard {...this.props} />;
    return (
      <div id="public-page" className="HomePage">
        <div className="MarketingPage">
          <InsuranceProgramHome />
        </div>
        <div className="dark-bg">
          <br />
          <br />
          <br />
          <br />
          <h1 className="section-h1">Accident Reports</h1>
          <br />
        </div>
        <div className="HomePage__content">
          <div className="HomePage__top-background">
            <div className="HomePage__top-background-content">
              <div className="HomePage__title-desc-wrapper">
                <h1
                  className="HomePage__main-title HomePage__title"
                  style={{ textAlign: "center" }}
                >
                  <span>#1 </span>for accident reporting in Texas
                </h1>
                <div className="HomePage__reasons">
                  <div className="HomePage__reasons_reason">
                    <img
                      src="/img/ic-search@3x.png"
                      alt="reason"
                      className="reason_image reason_image_clock"
                    />
                    <p className="reason_text">
                      Search and find any accident, within 12 months of history.
                    </p>
                  </div>
                  <div className="HomePage__reasons_reason">
                    <img
                      src="/img/ic-id@3x.png"
                      alt="reason"
                      className="reason_image reason_image_id"
                    />
                    <p className="reason_text">
                      An easy to understand 'Client Report', with your logo and
                      contact information to share with clients or prospective
                      clients.
                    </p>
                    <span className="reason_text reason_text_small">
                      * For all reports ordered between 9am and 5pm CT, Monday -
                      Friday
                    </span>
                  </div>
                  <div className="HomePage__reasons_reason">
                    <img
                      src="/img/ic-phone@3x.png"
                      alt="reason"
                      className="reason_image reason_image_phone"
                    />
                    <p className="reason_text">
                      Report not filed yet? Don't waste time checking each day:
                      Get instant alerts when the report has been filed AND get
                      notified if changes are made to your report.
                    </p>
                  </div>
                  <div className="HomePage__reasons_reason">
                    <img
                      src="/img/ic-clock@3x.png"
                      alt="reason"
                      className="reason_image reason_image_clock"
                    />
                    <p className="reason_text">
                      Timely accident reports delivery with SLA commitment - Let
                      us prove it to you for free : The 1st one is on us!*
                    </p>
                  </div>
                  {/* <div className="HomePage__reasons_reason">
											<img
												src="/img/ic-graph@3x.png"
												alt="reason"
												className="reason_image reason_image_graph"
											/>
											<p className="reason_text">
												Advertise your brand to over 50k unique new visitors
												searching for their accident reports monthly (Purchased
												Separately).{" "}
												<Link
													to="/marketing"
													target="_blank"
													style={{
														textDecorationLine: "underline",
														color: "#f29338"
													}}
												>
													Learn more.
												</Link>
											</p>
										</div> */}
                </div>
              </div>
              {/* <div className="HomePage__price-block">
									<div className="HomePage__price-block-inner">
										<h3 className="HomePage__price-block-title">It's about time</h3>
										<div className="HomePage__price-block-content">
											<p className="HomePage__price-block-price">$9.95</p>
											<small
												style={{
													marginTop: "-20px",
													marginBottom: "20px",
													display: "block"
												}}
											>
												Per month, per user
											</small>
											<Link
												to="/signup"
												className="HomePage__price-block-btn HomePage__btn"
											>
												<span>Sign Up Now</span>
											</Link>
										</div>
									</div>
								</div>
								{/* <div className="HomePage__price-block">
									<div className="HomePage__price-block-inner">
										<h3 className="HomePage__price-block-title">
											Check Availability
										</h3>
										<div className="HomePage__price-block-content">
											<ZipQuotaChecker />
										</div>
									</div>
								</div> */}
            </div>
            {/* <img src="/img/laptop.png" alt="laptop img" className="laptop-img" /> */}
          </div>

          <div className="HomePage__blue-background">
            <div className="HomePage__blue-background-content">
              <img
                src="/img/phone-home@2x.png"
                alt="phone img"
                className="phone-img"
              />
              <div className="HomePage__title-search-wrapper">
                <CircleShape
                  className="HomePage__circle-shape"
                  id="HomePage__circle"
                />
                <h2 className="HomePage__title search-title">
                  ACCIDENT REPORT <br /> SEARCH ENGINE
                </h2>
                <p className="HomePage__description search-description">
                  Search and find any accident that happened in the last 24
                  hours or up to 12 months of history. View detailed accident,
                  vehicle and driver information. Manage and retrieve official
                  CR3 and DMV reports using your own simple to use business
                  portal.
                </p>
                <div className="HomePage__search-block">
                  <h3>Find an accident in less than 5 secconds by</h3>
                  <div className="HomePage__search-block-content">
                    <ul>
                      <li>
                        <span className="checkmark"></span> Case
                      </li>
                      <li>
                        <span className="checkmark"></span> VIN/Licence Plate
                      </li>
                      <li>
                        <span className="checkmark"></span> Car Make/Year
                      </li>
                      <li>
                        <span className="checkmark"></span> Address
                      </li>
                    </ul>
                    <ul>
                      <li>
                        <span className="checkmark"></span> County
                      </li>
                      <li>
                        <span className="checkmark"></span> City
                      </li>
                      <li>
                        <span className="checkmark"></span> Date
                      </li>
                      <li>
                        <span className="checkmark"></span> Vehicle year
                      </li>
                    </ul>
                  </div>
                </div>
                <p className="all-reports">
                  * For all reports ordered between 9am and 5pm CT, Monday -
                  Friday
                </p>
              </div>
            </div>
          </div>
          <div className="HomePage__white-background HomePage__white-background-1">
            <div className="pricing-second">
              <div className="pricing-content">
                <p className="myaccident-complete">MYACCIDENT COMPLETE</p>
                <p className="my-accident-pro">
                  3 Reports in 1 - Try it now and your 1st order is on us!
                </p>
                <div className="pricing-content-second">
                  <div className="pricing-content-second-content">
                    <div className="oval">
                      <p className="oval-number">1</p>
                    </div>
                    <p className="my-accident-reports">
                      TxDOT Official CR3 <br />
                      'Peace Officer' Report
                    </p>
                    <img
                      src="/img/cr-3-report.png"
                      className="report-img report-img-1"
                      alt="report"
                    />
                  </div>

                  <p className="my-accident-plus">+</p>

                  <div className="pricing-content-second-content">
                    <div className="oval">
                      <p className="oval-number">2</p>
                    </div>
                    <p className="my-accident-reports">
                      DMV Owner <br />
                      Title Information
                    </p>
                    <img
                      src="/img/owner-info-report.png"
                      className="report-img report-img-2"
                      alt="report"
                    />
                  </div>

                  <p className="my-accident-plus">+</p>

                  <div className="pricing-content-second-content">
                    <div className="oval">
                      <p className="oval-number">3</p>
                    </div>
                    <p className="my-accident-reports">
                      MyAccident Business <br />
                      Pro Report
                    </p>
                    <img
                      src="/img/pdf-report.png"
                      className="report-img report-img-3"
                      alt="report"
                    />
                  </div>
                </div>
              </div>
              <br />
              <br />
            </div>
          </div>
          <img
            src="/img/gradient-triangle2.svg"
            style={{ height: 250, display: "block", width: "100%" }}
            alt="triangle"
          />

          <div className="dark-bg">
            <br />
            <br />
            <h1
              className="HomePage__main-title HomePage__title"
              style={{ fontSize: 60 }}
            >
              CHOOSE A USER PLAN THAT FITS YOUR FIRM
            </h1>
            <br />
            <br />
            <Link
              to="/signup"
              className="button"
              style={{
                width: 250,
                margin: "auto",
                background: "#f29338",
                display: "block",
                fontSize: "1.5rem",
              }}
            >
              Sign Up
            </Link>
            <br />
            <br />

            <br />
            <br />
            <LegalFooter />
            <br />
            <br />
          </div>
        </div>

        <BackToTop className="HomePage__back-to-top" />
      </div>
    );
  }
}

export default withRouter(HomePage);
