import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import axios from "axios";
import AdvertisementLocked from "./advertisement-locked";
import Lookup from "../components/Shared/Lookup";
import FormInput from "../components/Shared/FormInput";
import FormButton from "../components/Shared/FormButton";
import swal from "sweetalert2";

import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

// Image upload restrictions
const imageMaxSize = 1024 * 1024 * 5; // bytes
const acceptedFileTypes =
  "image/x-png, image/png, image/jpg, image/jpeg, image/gif";
const acceptedFileTypesArray = acceptedFileTypes.split(",").map((item) => {
  return item.trim();
});

const renderRating = (rating) => {
  if (rating <= 5 && rating > 4.5) {
    return (
      <div className="star-wrapper">
        <span className="rating">{rating}</span>
        <img alt="star" src="/img/star.png" className="star-img" />
        <img alt="star" src="/img/star.png" className="star-img" />
        <img alt="star" src="/img/star.png" className="star-img" />
        <img alt="star" src="/img/star.png" className="star-img" />
        <img alt="star" src="/img/star.png" className="star-img" />
      </div>
    );
  } else if (rating >= 3.5) {
    return (
      <div className="star-wrapper">
        <span className="rating">{rating}</span>
        <img alt="star" src="/img/star.png" className="star-img" />
        <img alt="star" src="/img/star.png" className="star-img" />
        <img alt="star" src="/img/star.png" className="star-img" />
        <img alt="star" src="/img/star.png" className="star-img" />
        <img alt="star" src="/img/star-empty.png" className="star-img" />
      </div>
    );
  } else if (rating >= 2.5) {
    return (
      <div className="star-wrapper">
        <span className="rating">{rating}</span>
        <img alt="star" src="/img/star.png" className="star-img" />
        <img alt="star" src="/img/star.png" className="star-img" />
        <img alt="star" src="/img/star.png" className="star-img" />
        <img alt="star" src="/img/star-empty.png" className="star-img" />
        <img alt="star" src="/img/star-empty.png" className="star-img" />
      </div>
    );
  } else if (rating >= 1.5) {
    return (
      <div className="star-wrapper">
        <span className="rating">{rating}</span>
        <img alt="star" src="/img/star.png" className="star-img" />
        <img alt="star" src="/img/star.png" className="star-img" />
        <img alt="star" src="/img/star-empty.png" className="star-img" />
        <img alt="star" src="/img/star-empty.png" className="star-img" />
        <img alt="star" src="/img/star-empty.png" className="star-img" />
      </div>
    );
  } else if (rating >= 0.5) {
    return (
      <div className="star-wrapper">
        <span className="rating">{rating}</span>
        <img alt="star" src="/img/star.png" className="star-img" />
        <img alt="star" src="/img/star-empty.png" className="star-img" />
        <img alt="star" src="/img/star-empty.png" className="star-img" />
        <img alt="star" src="/img/star-empty.png" className="star-img" />
        <img alt="star" src="/img/star-empty.png" className="star-img" />
      </div>
    );
  } else if (rating < 0.5) {
    return (
      <div className="star-wrapper">
        <span className="rating">{rating}</span>
        <img alt="star" src="/img/star-empty.png" className="star-img" />
        <img alt="star" src="/img/star-empty.png" className="star-img" />
        <img alt="star" src="/img/star-empty.png" className="star-img" />
        <img alt="star" src="/img/star-empty.png" className="star-img" />
        <img alt="star" src="/img/star-empty.png" className="star-img" />
      </div>
    );
  } else {
    return (
      <div className="star-wrapper">
        <span className="rating">n/a</span>
        <img alt="star" src="/img/star-empty.png" className="star-img" />
        <img alt="star" src="/img/star-empty.png" className="star-img" />
        <img alt="star" src="/img/star-empty.png" className="star-img" />
        <img alt="star" src="/img/star-empty.png" className="star-img" />
        <img alt="star" src="/img/star-empty.png" className="star-img" />
      </div>
    );
  }
};

export default class AttorneyAd extends Component {
  constructor(props) {
    super(props);
    this.state = {
      businessName: "",
      isBusinessNameSelected: false,
      businessID: "",
      businessRating: "",
      options: [],
      apiCall: false,
      optionsVisible: false,
      address: "",
      callToAction: "",
      tagline1: "",
      tagline2: "",
      phone: "",
      message: "",
      displaySuccess: false,
      error: "",
      file: null,
      imagePreviewUrl: "/img/image-placeholder.jpg",
      crop: {
        unit: "px",
        aspect: 1,
        width: 50,
        height: 50,
      },
      src: null,
      croppedImageUrl: null,
      myNewCroppedFile: "/img/image-placeholder.jpg",
      timer: false,
      image_url: "",
      finalImage: null,
      isImageLoaded: false,
      gotProps: false,
      isUserTyping: false,
      isAdOn: true,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.user && nextProps.user.ad && !prevState.gotProps) {
      return {
        businessName: nextProps.user.ad.business_name,
        businessID: nextProps.user.ad.business_id,
        isBusinessNameSelected: true,
        address: nextProps.user.ad.address,
        callToAction: nextProps.user.ad.call_to_action || "",
        tagline1: nextProps.user.ad.tagline1 || "",
        tagline2: nextProps.user.ad.tagline2 || "",
        phone: nextProps.user.ad.phone || "",
        message: nextProps.user.ad.message || "",
        image_url: nextProps.user.ad.image_url || "",
        isAdOn: nextProps.user.ad.visible,
        gotProps: true,
      };
    }
    return null;
  }

  componentDidMount = () => {
    if (this.state.businessID) {
      axios
        .get(`/api/banner/business?id=${this.state.businessID}`)
        .then((res) => {
          this.setState({
            businessRating: res.data.response.result.rating,
            address: res.data.response.result.formatted_address,
          });
        });
    }
  };

  changeHandler = (event) => {
    const { name, value } = event.target;

    this.setState({
      [name]: value,
      error: "",
    });
  };

  setBusinessName = (e) => {
    e.persist();
    if (!this.state.isUserTyping) {
      this.setState(
        {
          businessName: e.target.value,
          options: [],
          apiCall: true,
          optionsVisible: false,
          isBusinessNameSelected: false,
          isUserTyping: true,
        },
        () => {
          let url = `api/banner/business-name-lookup?input=${encodeURIComponent(
            e.target.value
          )}`;

          if (this.state.businessName.length > 1)
            axios.get(url).then((res) => {
              this.setState((state) => {
                const opt = state.options;
                console.log(res);
                if (
                  res.data &&
                  res.data.response &&
                  res.data.response.results
                ) {
                  res.data.response.results.forEach(async (result) => {
                    if (
                      opt.indexOf([
                        result.name,
                        result.place_id,
                        result.rating,
                        result.formatted_address,
                      ]) === -1
                    ) {
                      await opt.push([
                        result.name,
                        result.place_id,
                        result.rating,
                        result.formatted_address,
                      ]);
                    }
                  });
                }
                return {
                  options: opt,
                  apiCall: false,
                  optionsVisible: true,
                  isUserTyping: false,
                };
              });
            });
          else this.setState({ isUserTyping: false });
        }
      );
    } else {
      this.setState({
        businessName: e.target.value,
        options: [],
        apiCall: true,
        optionsVisible: false,
        isBusinessNameSelected: false,
      });
    }
  };

  closeAd = () => {
    this.setState({ displaySuccess: true }, () => {
      setTimeout(() => this.setState({ displaySuccess: false }), 4000);
    });
  };

  onSelectImage = (event) => {
    const { files } = event.target;
    if (files && files.length > 0) {
      const isVerified = this.verifyFile(files[0]);
      if (!isVerified) {
        window.alert("File is too large or is not a valid img format");
      }
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        this.setState({ src: reader.result, isImageLoaded: true })
      );
      reader.readAsDataURL(files[0]);
    }
  };

  onImageLoaded = (image) => {
    this.imageRef = image;
    this.setState({ isImageLoaded: true });
  };

  onCropComplete = (crop) => {
    this.makeClientCrop(crop);
  };

  onCropChange = (crop) => {
    this.setState({ crop });
  };

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        crop,
        "newFile.jpeg"
      );

      this.setState({ croppedImageUrl });
    }
  }

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    ctx.canvas.toBlob(
      (blob) => {
        const file = new File([blob], fileName, {
          type: "image/jpeg",
          lastModified: Date.now(),
        });
        this.setState({ finalImage: file });
      },
      "image/jpeg",
      1
    );
  }

  handleClick = () => {
    this.refs.fileInput.click();
  };

  handleRemove = () => {
    this.setState({
      src: null,
      croppedImageUrl: null,
      isImageLoaded: false,
    });
    this.refs.fileInput.value = "";
  };

  verifyFile = (file) => {
    if (file) {
      const currentFile = file;
      const currentFileType = currentFile.type;
      const currentFileSize = currentFile.size;
      if (!acceptedFileTypesArray.includes(currentFileType)) {
        return false;
      }
      if (currentFileSize > imageMaxSize) {
        return false;
      }
      return true;
    }
  };

  adSwitch = (e) => {
    e.preventDefault();
    if (this.state.gotProps) {
      axios
        .put(`api/banner/visibility?visible=${!this.state.isAdOn}`)
        .then((res) => this.setState({ isAdOn: !this.state.isAdOn }))
        .catch((err) => console.log(err));
    } else {
      this.setState({ isAdOn: !this.state.isAdOn });
    }
  };

  submitForm = (event) => {
    event.preventDefault();
    const {
      image_url,
      businessName,
      businessID,
      callToAction,
      tagline1,
      tagline2,
      phone,
      message,
      gotProps,
      finalImage,
      isAdOn,
    } = this.state;

    const phoneRegex = new RegExp(/^(\(?\+?[0-9]*\)?)?[0-9_\- ()]*$/);

    const url = gotProps ? "api/banner/update" : "/api/banner/create";

    if (phone && phone.length && !phoneRegex.test(phone)) {
      return this.setState({ error: "Phone number is not valid" });
    }

    this.setState({ error: "" });

    const data = {
      image_url,
      business_name: businessName,
      business_id: businessID,
      call_to_action: callToAction,
      tagline1,
      tagline2,
      phone,
      message,
      visible: isAdOn,
    };

    const formData = new FormData();
    formData.append("file", finalImage);
    if (finalImage) {
      axios
        .post(`/api/banner/image-upload`, formData)
        .then(async (response) => {
          await this.setState(
            {
              image_url: response.data.logo_url,
              imagePreviewUrl: response.data.logo_url,
              myNewCroppedFile: response.data.logo_url,
            },
            () => {
              data.image_url = response.data.logo_url;
              !gotProps
                ? axios
                    .post(url, data)
                    .then((response) => {
                      this.closeAd();
                    })
                    .catch((error) => new Error("Something went wrong", error))
                : axios
                    .put(url, data)
                    .then((response) => {
                      this.closeAd();
                    })
                    .catch((error) => new Error("Something went wrong", error));
            }
          );
        })
        .catch((error) => {
          console.log(error);
          swal.fire(
            "Error uploading file.",
            error.response.data.error,
            "error"
          );
        });
    } else {
      !gotProps
        ? axios
            .post(url, data)
            .then((response) => {
              this.closeAd();
            })
            .catch((error) => new Error("Something went wrong", error))
        : axios
            .put(url, data)
            .then((response) => {
              this.closeAd();
            })
            .catch((error) => new Error("Something went wrong", error));
    }
  };

  renderSuccessScreen = () =>
    this.state.displaySuccess && (
      <div className="success-screen">
        <img
          alt="success-screen"
          src="/img/checked.png"
          className="success-screen-img"
        />
        <h4 className="success-screen-title">SUCCESS!</h4>
        <p className="success-screen-text">
          {" "}
          Your ad has been successfully{" "}
          {this.state.gotProps ? "updated!" : "created!"}
        </p>
        <button
          className="success-screen-button"
          onClick={() => this.setState({ displaySuccess: false })}
        >
          <p className="success-screen-button-text">CLOSE WINDOW</p>
        </button>
      </div>
    );

  render() {
    if (this.props.user === null) return <Redirect to="/signin" />;
    if (
      (this.props.user.sub_plan &&
        this.props.user.sub_plan.nickname === "Basic") ||
      this.props.user.plan === "Basic"
    ) {
      return <AdvertisementLocked user={this.props.user} />;
    }
    const {
      businessName,
      isBusinessNameSelected,
      businessRating,
      address,
      callToAction,
      tagline1,
      tagline2,
      phone,
      message,
      error,
      src,
      crop,
      croppedImageUrl,
      myNewCroppedFile,
      options,
      apiCall,
      optionsVisible,
      image_url,
      isAdOn,
    } = this.state;
    const value = businessName && isBusinessNameSelected;
    return (
      <div className="attorneyWrapper">
        <div className={"attorneyAd"}>
          <div className="attorneyAd__content">
            <p className="attorneyAd__content-button-title">Create your ad</p>

            <div className="fileinput">
              <p style={{ marginTop: "10px", marginBottom: "5px" }}>
                Upload an image
              </p>
              <input
                type="file"
                onChange={this.onSelectImage}
                ref="fileInput"
                accept={acceptedFileTypes}
                multiple={false}
                style={{ width: "100%" }}
              />

              <div>
                {!src ? (
                  <div>
                    <div className={"thumbnail"}>
                      <img src={this.state.imagePreviewUrl} alt="..." />
                    </div>
                    <div className={"attorneyAd__content-buttons-one"}>
                      <button
                        onClick={() => this.handleClick()}
                        className={"upload-button"}
                      >
                        <p className={"upload-button-text"}>Add image</p>
                      </button>
                    </div>
                  </div>
                ) : (
                  <div>
                    <div className={"thumbnail"}>
                      {src && (
                        <ReactCrop
                          src={src}
                          crop={crop}
                          ruleOfThirds
                          onImageLoaded={this.onImageLoaded}
                          onComplete={this.onCropComplete}
                          onChange={this.onCropChange}
                          minWidth={50}
                          minHeight={50}
                        />
                      )}
                    </div>
                    <div className={"attorneyAd__content-buttons-two"}>
                      <button
                        onClick={() => this.handleClick()}
                        className="upload-button"
                      >
                        <p className="upload-button-text">CHANGE</p>
                      </button>
                      <button
                        onClick={() => this.handleRemove()}
                        className="upload-button upload-button-submit"
                      >
                        <p className="upload-button-text">REMOVE</p>
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>

            <form style={{ width: "100%" }}>
              <div className="form-input-group">
                <label style={{ zIndex: 1 }}>Business name</label>

                <Lookup
                  businessName={businessName}
                  setBusinessName={this.setBusinessName}
                  options={options}
                  onClick={(val) => {
                    this.setState({
                      businessName: val[0],
                      businessID: val[1],
                      businessRating: val[2],
                      address: val[3],
                      optionsVisible: false,
                      isBusinessNameSelected: true,
                      isUserTyping: false,
                    });
                  }}
                  isLoadingVisible={apiCall && businessName.length > 1}
                  areOptionsVisible={optionsVisible && businessName.length}
                />
              </div>

              <div className="form-input-group">
                <label>Headline 1</label>
                <FormInput
                  type="text"
                  name="callToAction"
                  value={callToAction}
                  changeHandler={this.changeHandler}
                  placeholder="Were you injured?"
                  className="form-input"
                />
              </div>
              <div className="form-input-group">
                <label>Headline 2</label>
                <FormInput
                  type="text"
                  name="tagline1"
                  value={tagline1}
                  changeHandler={this.changeHandler}
                  placeholder="No fees unless we win!"
                  className="form-input"
                />
              </div>
              <div className="form-input-group">
                <label>Hedline 3</label>
                <FormInput
                  type="text"
                  name="tagline2"
                  value={tagline2}
                  changeHandler={this.changeHandler}
                  placeholder="Call us today!"
                  className="form-input"
                />
              </div>
              <div className="form-input-group">
                <label>Your phone number</label>
                <FormInput
                  type="text"
                  name="phone"
                  value={phone}
                  changeHandler={this.changeHandler}
                  placeholder="(888) 123 4567"
                  className="form-input"
                />
              </div>

              <div className="form-input-group">
                <label>Default user's message</label>
                <textarea
                  type="text"
                  name="message"
                  value={message}
                  onChange={this.changeHandler}
                  placeholder='Optional (eg "I was injured and I want to talk to a lawyer")'
                  className="form-input message-input"
                />
              </div>
            </form>

            {error && <p className="error">{error}</p>}
            {!error && <p className="nothing"> - </p>}
            <FormButton
              className={`form-btn ${error.length ? "disabled" : ""}`}
              text="Submit"
              clickHandler={this.submitForm}
              disabled={!value}
            />
          </div>
          <div
            style={{
              position: "sticky",
              bottom: 0,
              width: "100%",
              height: "20px",
              backgroundColor: "#ffffff",
            }}
          ></div>
        </div>
        <div className="attorneyAd-switch-container">
          <p className="about-top">Set ad visibility</p>
          <p className="about-bottom">
            IF SWITCHED OFF, YOUR AD WILL NOT BE DISPLAYED TO USERS
          </p>
          <label className="switch">
            <input type="checkbox" checked={isAdOn} onChange={this.adSwitch} />
            <span className="slider"></span>
          </label>
        </div>
        <div className={"attorneyAd attorneyAd-preview"}>
          <span className="close-ad" onClick={() => {}} />

          <div className="attorneyAd__content">
            <p className="attorneyAd__content-button-title">Preview</p>
            <img
              className="abbot-img"
              alt="Crop"
              style={{ width: "80px", height: "80px" }}
              src={
                image_url
                  ? image_url
                  : croppedImageUrl
                  ? croppedImageUrl
                  : myNewCroppedFile
              }
            />
            <h3 className={"attorneyAd__content-title"}>{businessName}</h3>
            <p className="abbot-address">{address}</p>
            {renderRating(parseFloat(businessRating).toFixed(1))}

            {(callToAction || tagline1 || tagline2 || phone) && (
              <div className="text-wrapper">
                <p className="title">{callToAction}</p>
                <p className="text">
                  {tagline1}
                  <br />
                  {tagline2}
                </p>
                <p className="number">{phone}</p>
              </div>
            )}

            <div className="attorneyAd__content-form">
              <div className="form-input-group">
                <label>name</label>
                <p className="form-input form-input-uneditable">John Doe</p>
              </div>
              <div className="form-input-group">
                <label>phone</label>
                <p className="form-input form-input-uneditable">
                  (888) 123 4567
                </p>
              </div>
              <div className="form-input-group">
                <label>email</label>
                <p className="form-input form-input-uneditable">john@doe.com</p>
              </div>
              <div className="form-input-group" style={{ marginBottom: 0 }}>
                <label>Message</label>
                <p className="form-input message-input">{message}</p>
              </div>
              <p className="checkbox-text">
                By pressing Contact, you agree that {businessName} may call/text
                you about your inquiry. You don’t need to consent as condition
                of agreeing to hire a lawyer. You also agree to our{" "}
                <a
                  className="blue-text"
                  href="https://myaccident.org/terms-of-use"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Terms of Use
                </a>
                .
              </p>
            </div>

            <FormButton className={"form-btn"} text="Contact us" />
          </div>
        </div>
        {this.renderSuccessScreen()}
      </div>
    );
  }
}
