/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-has-content */
import React from "react";
import moment from "moment";
import PopupWindow from "./PopupWindow";
import NewCaseModal from "./NewCaseModal";
import MarkerMap from "./MarkerMap";

export default class AdminSearchResults extends React.Component {
	tableHeadTitles = [
		"",
		"Case ID",
		"Date/Time",
		"Address",
		"Vehicles",
		"#No Vehicles Involved",
		""
	];

	state = {
		showNewCaseModal: false,
		accidentID: ""
	};
	renderTableHead = () => {
		return (
			<thead>
				<tr>
					{this.tableHeadTitles.map(title => {
						return <th key={title}>{title}</th>;
					})}
				</tr>
			</thead>
		);
	};

	timeFilter = v => {
		const startTime = Number(
			this.props.filter.timeRange.start.replace(":", "")
		);
		const endTime = Number(this.props.filter.timeRange.end.replace(":", ""));
		return v.time > startTime && v.time < endTime;
	};

	vehicleCountFilter = v => {
		if (
			!this.props.filter.oneVehicle &&
			!this.props.filter.twoVehicles &&
			!this.props.filter.twoPlusVehicles
		)
			return true;
		if (this.props.filter.oneVehicle && v.vehicles.length === 1) return true;
		if (this.props.filter.twoVehicles && v.vehicles.length === 2) return true;
		return !!(this.props.filter.twoPlusVehicles && v.vehicles.length > 2);
	};

	otherFilters(v) {
		if (!this.props.filter.injuries && !this.props.filter.fatalities)
			return true;
		if (this.props.filter.injuries && v.possible_injury_count) return true;
		if (this.props.filter.fatalities && v.death_count) return true;
		return false;
	}

	renderTableBody = () => {
		return (
			<tbody>
				{this.props.accidents.map(accident => {
					const vehicles = accident.vehicles;
					const date = accident.date;
					const caseID = accident.caseID;
					const dateTimeOutput = moment(date).format("MM/DD/YYYY - h:mm:ss A");
					accident.time = Number(moment(date).format("HHmm"));

					const vehicleMaker = vehicles.filter(vehicle => {
						if (
							typeof vehicle.vehicle_maker !== "undefined" &&
							vehicle.vehicle_maker !== "UNKNOWN"
						) {
							return vehicle.vehicle_maker;
						}
						return null;
					});

					const validVehicleMaker = vehicleMaker.map(
						vehicle => vehicle.vehicle_maker + " " + vehicle.vehicle_model_year
					);

					if (
						this.timeFilter(accident) &&
						this.vehicleCountFilter(accident) &&
						this.otherFilters(accident)
					)
						return (
							<tr key={accident._id + "-" + accident.caseID}>
								<td>
									<button
										className="purple"
										onClick={() =>
											this.setState({
												showNewCaseModal: true,
												accidentID: accident.custom_id
											})
										}
									>
										Add To Dashboard
									</button>
								</td>
								<td>{caseID}</td>
								<td>{dateTimeOutput}</td>
								<td>{accident.location_address}</td>
								<td>{validVehicleMaker.join(", ")}</td>
								<td>{vehicles.length}</td>
								<td>
									<button
										data-testid={`view-button-${accident._id}`}
										onClick={() =>
											PopupWindow(
												"https://" +
													(window.location.hostname.startsWith("business")
														? "app"
														: "dev") +
													".myaccident.org/accident/" +
													accident.custom_id,
												"Accident",
												1200,
												700
											)
										}
									>
										View
									</button>
								</td>
							</tr>
						);
					return null;
				})}
			</tbody>
		);
	};

	render() {
		if (this.props.accidents === undefined) return null;
		return (
			<>
				<NewCaseModal
					show={this.state.showNewCaseModal}
					close={() => this.setState({ showNewCaseModal: false })}
					accidentID={this.state.accidentID}
					user={this.props.user}
				/>
				<MarkerMap
					user={this.props.user}
					accidents={this.props.accidents.filter(
						accident =>
							this.timeFilter(accident) &&
							this.vehicleCountFilter(accident) &&
							this.otherFilters(accident)
					)}
				/>
				<table className="striped admin-search-result-table">
					{this.renderTableHead()}
					{this.renderTableBody()}
				</table>
			</>
		);
	}
}
