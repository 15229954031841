import React, { Component } from "react";
import clsx from "clsx";
import { Formik, Field, ErrorMessage, Form } from "formik";
import Axios from "axios";
import swal from "sweetalert2";
import { MdClose } from "react-icons/md";
import { withRouter } from "react-router";

class NewCaseModal extends Component {
	state = {
		policeReport: this.props.user?.payment,
		data: {
			firstName: this.props.firstName || "",
			lastName: this.props.lastName || "",
			caseId: this.props.caseId || ""
		}
	};
	addCase(values) {
		Axios.post("/api/businessAccount/cases/create", {
			firstName: values.firstName,
			lastName: values.lastName,
			business_case_id: values.caseId,
			accidentId: this.props.accidentID,
			requestPR: this.state.policeReport
		})
			.then(d => {
				this.props.close();
				this.setState({
					policeReport: false,
					data: { firstName: "", lastName: "", caseId: "", time: +new Date() }
				});
				swal
					.fire({
						title: "Case added!",
						text:
							"Case is added to your account. You can find it in your dashboard.",
						type: "success",
						showCancelButton: true,
						cancelButtonText: "OK",
						confirmButtonText: "Go to dashboard",
						reverseButtons: true
					})
					.then(v => {
						window.lastActiveDashboardTab = 1;
						if (v.value) this.props.history.push("/");
						window.dispatchEvent(new Event("case-added"));
					});
			})
			.catch(e => {
				swal.fire("Error adding case!", e.response.data.error, "error");
			});
	}

	render() {
		return (
			<div className={clsx("modal", { show: this.props.show })}>
				<button className="close" onClick={() => this.props.close()} data-testid="close-btn">
					<MdClose />
				</button>
				<h2>Enter Client Information</h2>
				<Formik
					initialValues={this.state.data}
					enableReinitialize
					onSubmit={(values, { setSubmitting }) => {
						this.addCase(values);
					}}
				>
					{({
						values
						/* and other goodies */
					}) => (
						<Form>
							<fieldset>
								<label>Case #:</label>
								<ErrorMessage
									name="caseId"
									render={msg => <div className="error">{msg}</div>}
								/>
								<Field
									name="caseId"
									type="text"
									placeholder="Enter your firm's Case ID for this case"
								/>

								<label>Client First Name:</label>
								<ErrorMessage
									name="firstName"
									render={msg => <div className="error">{msg}</div>}
								/>
								<Field name="firstName" type="text" data-testid="firstName"
								/>

								<label>Client Last Name:</label>
								<ErrorMessage
									name="lastName"
									render={msg => <div className="error">{msg}</div>}
								/>
								<Field name="lastName" type="text" data-testid="lastName"/>

								<input
									type="checkbox"
									id="cbx"
									style={{ display: "none" }}
									readOnly
									checked={this.state.policeReport}
									className="inp-cbx"
								/>
								{this.props.user?.payment ? (
									<label
										className="cbx"
										htmlFor="cbx"
										onClick={() =>
											this.setState({ policeReport: !this.state.policeReport })
										}
									>
										<span>
											<svg width="12px" height="10px" viewBox="0 0 12 10">
												<polyline points="1.5 6 4.5 9 10.5 1" />
											</svg>
										</span>
										<span>Purchase Complete Pro Report ($18.95)</span>
									</label>
								) : null}
							</fieldset>
							<div className="footer">
								<button
									type="button"
									className="outline"
									onClick={() => this.props.close()}
									data-testid="cancel-footer-btn"
								>
									Cancel
								</button>
								<div>
									&nbsp; &nbsp;
									<button type="submit">Add Case</button>
								</div>
							</div>
						</Form>
					)}
				</Formik>
			</div>
		);
	}
}
export default withRouter(NewCaseModal);
