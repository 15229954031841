import React, { useEffect, useState } from "react";
import * as CanvasJSReact from "../../canvasjs.react";
import moment from "moment";
import { defaultGraphOptions } from "../LeadStats";
import Checkbox from "../Shared/Checkbox";
import {
  paid_bing_competitors,
  paid_bing_insurance_claims,
  paid_bing_primary,
  paid_bing_primary_spanish,
  paid_bing_competitors_spanish,
  paid_bing_direct,
  paid_google_camplj,
  paid_google_competitors,
  paid_google_competitors_spanish,
  paid_google_insurance_claims,
  paid_google_primary,
  paid_google_direct,
  paid_google_medical,
  paid_google_primary_spanish,
  paid_google_direct_spanish,
  paid_google_truck,
  paid_bing_direct_spanish,
} from "../LeadFilter";

const CanvasJSChart = CanvasJSReact.CanvasJSChart;
const colors = [
  "#4661EE",
  "#EC5657",
  "#1BCDD1",
  "#8FAABB",
  "#B08BEB",
  "#3EA0DD",
  "#F5A52A",
  "#23BFAA",
  "#FAA586",
  "#EB8CC6",
];
export const refererNormalizer = (input) => {
  if (!input) return "google.com";
  let output = input.replace("https://", "");
  output = output.replace("http://", "");
  output = output.replace("www.", "");
  output = output.replace("/", "");
  if (output.indexOf("google.") > -1) output = "google.com";
  if (output.indexOf("yahoo.") > -1) output = "yahoo.com";
  if (output.indexOf("bing.") > -1) output = "bing.com";
  if (output.indexOf("facebook") > -1) output = "facebook.com";

  if (
    output.match(
      paid_google_primary +
        "|" +
        paid_google_competitors +
        "|" +
        paid_google_insurance_claims +
        "|" +
        paid_google_camplj +
        "|" +
        paid_google_direct +
        "|" +
        paid_google_medical +
        "|" +
        paid_google_truck
    )
  )
    output = "paid google";

  if (
    output.match(
      paid_google_primary_spanish +
        "|" +
        paid_google_competitors_spanish +
        "|" +
        paid_google_direct_spanish
    )
  )
    output = "paid google spanish";

  if (
    output.match(
      paid_bing_competitors +
        "|" +
        paid_bing_primary +
        "|" +
        paid_bing_insurance_claims +
        "|" +
        paid_bing_competitors_spanish +
        "|" +
        paid_bing_primary_spanish +
        "|" +
        paid_bing_direct +
        "|" +
        paid_bing_direct_spanish
    )
  )
    output = "paid bing";

  if (
    output.indexOf("campaignid=413035768") > -1 &&
    output.indexOf("&Advertiser=o") > -1
  )
    output = "paid microsoft O";

  if (
    output.indexOf("campaignid=413035768") > -1 &&
    output.indexOf("&Advertiser=s") > -1
  )
    output = "paid microsoft S";

  if (
    output.indexOf("campaignid=413035768") > -1 &&
    output.indexOf("&Advertiser=a") > -1
  )
    output = "paid microsoft A";

  return output;
};

export default function Referer({ leads, view, users }) {
  const [graphData, setGraphData] = useState({});
  // const [leadType, setLeadType] = useState("");
  const [includeColds, setIncludeColds] = useState(true);

  useEffect(() => {
    // console.log(leads);
    // console.log(users);
    // console.log(filterUsers);
    const gd = { ...defaultGraphOptions };
    let data = {};
    let keys = {};
    leads.forEach((v) => {
      const k = refererNormalizer(v.referer);
      if (k) keys[k] = 0;
    });

    // console.log(keys);

    leads.forEach((v) => {
      let period;
      if (view === "daily")
        period = new moment(v.createdAt).format("YYYY-MM-DD");
      if (view === "monthly") period = v.createdAt.substr(0, 7);
      if (view === "weekly") {
        period =
          "Week " +
          moment(v.createdAt).isoWeek() +
          ", " +
          new Date(v.createdAt).getFullYear();
      }
      if (!data[period]) data[period] = { ...keys };

      if (v.referer && (v.lead_level !== "cold" || includeColds)) {
        data[period][refererNormalizer(v.referer)]++;
      }
    });

    gd.toolTip = {
      shared: true,
      // content: "{label}<hr />{name}: {y}",
    };
    // console.log(data);

    gd.data = [];
    Object.keys(keys).forEach((key, c) => {
      gd.data.push({
        type: "stackedColumn",
        color: colors[c] ? colors[c] : "#F00",
        showInLegend: true,
        name: key,
        dataPoints: Object.keys(data).map((vv) => {
          return {
            label: view === "daily" ? moment(vv).format("ll") : vv,
            y: data[vv][key],
          };
        }),
      });
    });
    setGraphData(gd);
  }, [leads, includeColds, view]);

  return (
    <div>
      <h2>Referrer</h2>
      <Checkbox
        text="Include colds"
        checked={includeColds}
        onClick={() => setIncludeColds(!includeColds)}
      />

      <br />
      <CanvasJSChart options={graphData} />
    </div>
  );
}
