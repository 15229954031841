/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { MdClose } from "react-icons/md";
import LeadTypes from "../pages/lead-types";

export default function LeadPlans({ choosePlan, signUpPage, currentLeadPlan }) {
	const but = (plan) => {
		return (
			<button
				onClick={() => choosePlan(plan)}
				disabled={currentLeadPlan === plan || (!signUpPage && plan === 0)}
				data-testid={`lead-plans-button-${plan}`}
			>
				{currentLeadPlan === plan ? "Current Plan" : "Select"}
			</button>
		);
	};
	const [modal1, setModal1] = useState(false);
	const [modal2, setModal2] = useState(false);

	return (
		<div>
			<div id="lead-plans">
				<div
					className={
						modal1 ? "modal advertising-plans show" : "modal advertising-plans"
					}
					data-testid={"lead-plans-modal1"}
				>
					<button className="close" onClick={() => setModal1(false)}>
						<MdClose />
					</button>
					<h2 style={{ color: "#FFF", marginBottom: 50 }}>
						OUR Clients Not Clicks Guarantee
					</h2>
					<p>
						We understand that ultimately you don’t want ‘Leads’ you want
						clients. We also know there are a lot of claims in the market about
						the quality of leads. Based on the last 6 months of our data and
						feedback from our clients we are seeing an average 1 in 5 of our
						leads turn into clients.
					</p>
					<p>
						We are willing to put our money where our mouth is and will
						guarantee the quality of our leads we send to your law firm.
					</p>
					<p>
						When you sign up, if you have not had a client after 5 leads, we
						will either fully refund you for the leads you have purchased OR
						send you another 5 for free. It’s up to you.
					</p>
					<p>
						We understand that MyAccident.org isn’t right for every law firm and
						the specific criteria each law firm has for their client intake
						process. The only criteria we ask is that you can help clients
						anywhere in the State of Texas. Leave the rest to us.
					</p>
					<div style={{ clear: "both", height: 30 }} />
					<div className="footer">
						<button style={{ visibility: "hidden" }}>No</button>
						<button onClick={() => setModal1(false)}>Close</button>
					</div>
				</div>

				<div
					className={
						modal2 ? "modal advertising-plans show" : "modal advertising-plans"
					}
					data-testid={"lead-plans-modal2"}
				>
					<button className="close" onClick={() => setModal2(false)}>
						<MdClose />
					</button>
					<LeadTypes fromPlans />
					<div className="footer">
						<button style={{ visibility: "hidden" }}>No</button>
						<button onClick={() => setModal2(false)}>Close</button>
					</div>
				</div>

				{signUpPage && (
					<div>
						<h2>Clients NOT Clicks</h2>
						<p>
							We <a onClick={() => setModal1(true)}>guarantee</a> at least 1 in
							5 of our leads will convert to clients. You can choose to get your
							money back or keep getting leads for free until that ratio is
							achieved.
						</p>
						<p>
							All leads from your own Ad are sent directly to you, however there
							are additional leads you can choose to purchase. You can learn
							more about our leads <a onClick={() => setModal2(true)}>here</a>.
						</p>
					</div>
				)}
				<br />
				<br />
				<div id="plans">
					{signUpPage ? (
						<div className="box-holder">
							<h2>Starter Pack</h2>
							<div className="box">
								<div className="top top2">
									<div className="price">
										{/* $1,000<span>/mo</span> */}5 Leads
									</div>
								</div>
								<img
									src="/img/triangle-2.gif"
									alt="triangle"
									className="triangle"
								/>
								{/* <div className="li">
									<FaCheck color="#f29338" size="20" />
									<span>5 Leads</span>
								</div> */}
								<div>
									{/* <div className="line2" /> */}
									<div>
										Enter promo code:
										<br />
										<input
											type="text"
											style={{
												width: "auto",
												color: "#000"
											}}
										/>
									</div>
								</div>
								<div className="line2" />
								{but(0)}
							</div>
						</div>
					) : null}
					<div className="box-holder">
						<h2>20 pack</h2>
						<div className="box">
							<div className="top top2">
								<div className="price">
									{/* $5,000<span>/mo</span> */}
									20 Leads
								</div>
							</div>
							<img
								src="/img/triangle-2.gif"
								alt="triangle"
								className="triangle"
							/>
							{/* <div className="li">
								<FaCheck color="#f29338" size="20" />
								<span>20 Leads</span>
							</div>
							<div className="line2" /> */}
							{but(20)}
						</div>
					</div>
					<div className="box-holder">
						<h2>50 Pack</h2>
						<div className="box">
							<div className="top top2">
								<div className="price">
									{/* $9,500<span>/mo</span> */}
									50 Leads
								</div>
							</div>
							<img
								src="/img/triangle-2.gif"
								alt="triangle"
								className="triangle"
							/>
							{/* <div className="li">
								<FaCheck color="#f29338" size="20" />
								<span>50 Leads</span>
							</div>
							<div className="line2" /> */}
							{but(50)}
						</div>
					</div>
					{!signUpPage ? (
						<div className="box-holder">
							<h2>100 Pack</h2>
							<div className="box">
								<div className="top top2">
									<div className="price">
										{/* $18,000<span>/mo</span> */}
										100 Leads
									</div>
								</div>
								<img
									src="/img/triangle-2.gif"
									alt="triangle"
									className="triangle"
								/>
								{/* <div className="li">
									<FaCheck color="#f29338" size="20" />
									<span>100 Leads</span>
								</div>
								<div className="line2" /> */}
								{but(100)}
							</div>
						</div>
					) : null}
				</div>
				{signUpPage && (
					<small>
						* Please note: If you choose to skip adding a lead plan package
						here, you can still purchase leads individually from us for $300 per
						lead.
					</small>
				)}
				<br />
				<br />
				<br />
			</div>
		</div>
	);
}
