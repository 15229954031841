/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import clsx from "clsx";
import { withRouter } from "react-router-dom";
import DatePicker from "react-datepicker";
import Axios from "axios";
import swal from "sweetalert2";
import { Redirect } from "react-router-dom";

const PreAlertPage = ({ history, user }) => {
  const [option, setOption] = useState(-1);
  const [monthYear, setMonthYear] = useState(new Date());
  const [vin, setVin] = useState("");
  const [caseId, setCaseId] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [business_case_id, setBusCaseId] = useState("");
  const [alertOnEmail, setAlertOnEmail] = useState(true);
  const [alertOnMobile, setAlertOnMobile] = useState(true);
  const [state, setState] = useState("");

  const next = () => {
    let startDate = new Date(monthYear.getFullYear(), monthYear.getMonth(), 1);
    let endDate = new Date(
      monthYear.getFullYear(),
      monthYear.getMonth() + 1,
      0
    );
    switch (option) {
      case 2:
        history.push("alert");
        break;
      case 1:
      case 0:
      default:
        Axios.post("/api/businessAccount/cases/vin-case-alert", {
          firstName,
          lastName,
          business_case_id,
          startDate,
          endDate,
          vin: option === 0 ? vin : null,
          caseId: option === 1 ? caseId : null,
          alertOnEmail,
          alertOnMobile,
          state: state.toUpperCase(),
        })
          .then((d) => {
            swal
              .fire({
                title: "Alert added!",
                text: "Alert is added to your account. You can find it in your dashboard.",
                type: "success",
                showCancelButton: true,
                cancelButtonText: "OK",
                confirmButtonText: "Go to dashboard",
                reverseButtons: true,
              })
              .then((v) => {
                if (v.value) history.push("/");
              });
          })
          .catch((e) => {
            console.log(e);
            swal.fire("Error adding alert!", e.response.data.error, "error");
          });
        break;
    }
  };
  const nextDisabled = () => {
    if (option < 0) return true;
    if (option === 0 && (!vin || vin.length !== 17)) return true;
    if (option === 1 && !caseId) return true;
    return false;
  };
  if (!user) return <Redirect to="/signin" />;

  return (
    <div id="admin-page">
      <h2>Set Up Report Alert</h2>
      <p>
        Texas Transportation Code (TTC) §550.062 requires a law enforcement
        officer who investigates a crash and completes a Texas Peace
        Officer&amp;s Report (CR3) to submit the report to the Texas Department
        of Transportation (TxDOT) not later than the 10th day after the date of
        the crash. If you can not find your report, it maybe that it has not yet
        been filed by the officer who worked on your case.
      </p>
      <p>
        Set up your MyAccident Alert and we will notify you when your report is
        filed.
      </p>
      <p></p>
      <div className="alert-options">
        <a
          onClick={() => setOption(0)}
          className={clsx({ selected: option === 0 })}
        >
          I know the VIN number of a Vehicle Involved
        </a>
        <div className={clsx("option-box", { show: option === 0 })}>
          <div className="row">
            <div className="column">
              Enter 17 characters long VIN number:
              <input value={vin} onChange={(e) => setVin(e.target.value)} />
            </div>
            <div className="column">
              Month/Year
              <DatePicker
                selected={monthYear}
                maxDate={new Date()}
                onChange={setMonthYear}
                dateFormat="MM/yyyy"
                showMonthYearPicker={true}
              />
            </div>
            <div className="column">
              State abbreviation:
              <input value={state} onChange={(e) => setState(e.target.value)} />
            </div>
          </div>
          <div className="row">
            <div className="column">
              Client First Name:
              <input
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
            </div>
            <div className="column">
              Client Last Name:
              <input
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </div>
            <div className="column">
              Business Case Id:
              <input
                value={business_case_id}
                onChange={(e) => setBusCaseId(e.target.value)}
              />
            </div>
          </div>
          <div className="row">
            <div className="column">
              <input
                type="checkbox"
                id="cbx"
                style={{ display: "none" }}
                readOnly
                checked={alertOnEmail}
                className="inp-cbx"
              />
              <label
                className="cbx"
                htmlFor="cbx"
                onClick={() => setAlertOnEmail(!alertOnEmail)}
              >
                <span>
                  <svg width="12px" height="10px" viewBox="0 0 12 10">
                    <polyline points="1.5 6 4.5 9 10.5 1" />
                  </svg>
                </span>
                <span>Receive alert on email</span>
              </label>
            </div>
            <div className="column">
              <input
                type="checkbox"
                id="cbx2"
                style={{ display: "none" }}
                readOnly
                checked={alertOnMobile}
                className="inp-cbx"
              />
              <label
                className="cbx"
                htmlFor="cbx2"
                onClick={() => setAlertOnMobile(!alertOnMobile)}
              >
                <span>
                  <svg width="12px" height="10px" viewBox="0 0 12 10">
                    <polyline points="1.5 6 4.5 9 10.5 1" />
                  </svg>
                </span>
                <span>Receive alert on mobile</span>
              </label>
            </div>
          </div>
        </div>
        <a
          onClick={() => setOption(1)}
          className={clsx({ selected: option === 1 })}
        >
          I know the CASE_ID of the Accident
        </a>
        <div className={clsx("option-box", { show: option === 1 })}>
          <div className="row">
            <div className="column">
              Enter the CASE_ID:
              <input
                value={caseId}
                onChange={(e) => setCaseId(e.target.value)}
              />
            </div>
            <div className="column">
              Month/Year
              <DatePicker
                selected={monthYear}
                maxDate={new Date()}
                onChange={setMonthYear}
                dateFormat="MM/yyyy"
                showMonthYearPicker={true}
              />
            </div>
            <div className="column">
              State abbreviation:
              <input value={state} onChange={(e) => setState(e.target.value)} />
            </div>
          </div>
          <div className="row">
            <div className="column">
              Client First Name:
              <input
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
            </div>
            <div className="column">
              Client Last Name:
              <input
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </div>
            <div className="column">
              Business Case Id:
              <input
                value={business_case_id}
                onChange={(e) => setBusCaseId(e.target.value)}
              />
            </div>
          </div>
          <div className="row">
            <div className="column">
              <input
                type="checkbox"
                id="cbx3"
                style={{ display: "none" }}
                readOnly
                checked={alertOnEmail}
                className="inp-cbx"
              />
              <label
                className="cbx"
                htmlFor="cbx3"
                onClick={() => setAlertOnEmail(!alertOnEmail)}
              >
                <span>
                  <svg width="12px" height="10px" viewBox="0 0 12 10">
                    <polyline points="1.5 6 4.5 9 10.5 1" />
                  </svg>
                </span>
                <span>Receive alert on email</span>
              </label>
            </div>
            <div className="column">
              <input
                type="checkbox"
                id="cbx4"
                style={{ display: "none" }}
                readOnly
                checked={alertOnMobile}
                className="inp-cbx"
              />
              <label
                className="cbx"
                htmlFor="cbx4"
                onClick={() => setAlertOnMobile(!alertOnMobile)}
              >
                <span>
                  <svg width="12px" height="10px" viewBox="0 0 12 10">
                    <polyline points="1.5 6 4.5 9 10.5 1" />
                  </svg>
                </span>
                <span>Receive alert on mobile</span>
              </label>
            </div>
          </div>
        </div>
        <a
          onClick={() => setOption(2)}
          className={clsx({ selected: option === 2 })}
        >
          I do not know either the case ID or the Vin number of a vehicle
          involved
        </a>
      </div>
      <div className="footer">
        <button style={{ visibility: "hidden" }} />
        <button disabled={nextDisabled()} onClick={next}>
          {option === 2 ? "Next step" : "Add alert"}
        </button>
      </div>
    </div>
  );
};

export default withRouter(PreAlertPage);
