/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Axios from "axios";
import * as CanvasJSReact from "../canvasjs.react";
import Swal from "sweetalert2";
import MultiSelect from "./MultiSelect";

const CanvasJS = CanvasJSReact.CanvasJS;
const CanvasJSChart = CanvasJSReact.CanvasJSChart;

CanvasJS.addColorSet("customColorSet", ["#2196f3"]);
export const defaultGraphOptions = {
  animationEnabled: true,
  colorSet: "customColorSet",
  title: {
    fontFamily: "Montserrat",
    fontSize: 18,
    fontWeight: "bold",
    fontColor: "#666666",
    horizontalAlign: "left",
    margin: 10,
    text: "",
  },
  axisX: {
    labelAngle: -70,
    gridDashType: "line",
    gridThickness: 2,
    gridColor: "#EEEEEE",
    lineColor: "#DDDDDD",
    labelFontColor: "#999999",
    tickColor: "#EEEEEE",
    tickThickness: 2,
  },
  axisY: {
    gridDashType: "line",
    gridThickness: 2,
    gridColor: "#EEEEEE",
    lineColor: "#FFFFFF",
    tickColor: "#FFFFFF",
    labelFontColor: "#999999",
  },
};

export default function LeadStatsNew() {
  const [loading, setLoading] = useState(true);
  const [view, setView] = useState("daily");
  const [data, setData] = useState({});
  const [leadType, setLeadType] = useState("");
  const [lead_level, setLeadLevel] = useState([
    "sold",
    "hot",
    "cold",
    "bad hot",
    "rejected",
    "unverified",
    "doa",
    "cancel",
    "ping-1",
    "ping-2",
    "ready",
  ]);
  const [referer, setReferer] = useState("");

  useEffect(() => {
    load(view, leadType, lead_level, referer);
  }, [view, leadType, lead_level, referer]);

  const load = (view, lead_type, lead_level, referer) => {
    setLoading(true);
    let url = `/api/leads/stats-calculated?view=${view}${
      window.isMobileDevice() ? "&isMobile=1" : ""
    }`;
    if (lead_type) url = url + "&leadType=" + lead_type;
    if (lead_level) url = url + "&leadLevel=" + lead_level.join(",");
    if (referer) url = url + "&referer=" + referer;

    Axios.get(url)
      .then((d) => {
        console.log(d.data);

        if (view === "weekly") {
          Object.keys(d.data.graphs.main).forEach((k) => {
            d.data.graphs.main[k].shift();
          });
          Object.keys(d.data.graphs.leadValueStats).forEach((k) => {
            d.data.graphs.leadValueStats[k].shift();
          });
          Object.keys(d.data.graphs.refererBreakdown).forEach((k) => {
            d.data.graphs.refererBreakdown[k].shift();
          });
          Object.keys(d.data.graphs.retailVsPingPost).forEach((k) => {
            d.data.graphs.retailVsPingPost[k].shift();
          });
        }

        setData(d.data.graphs);
        setLoading(false);
      })
      .catch((e) => {
        Swal.fire("Could not load leads", String(e), "error");
      });
  };

  if (loading)
    return (
      <div>
        <img
          src="/img/ajax-loader.gif"
          alt="loading stats..."
          className="loader-gif"
        />
        &nbsp;Loading stats...
      </div>
    );

  return (
    <div>
      <div className="row">
        <div className="column">
          Period:
          <select value={view} onChange={(e) => setView(e.target.value)}>
            <option value="daily">Daily</option>
            <option value="weekly">Weekly</option>
            <option value="monthly">Monthly</option>
            <option value="yearly">Yearly</option>
          </select>
        </div>
        <div className="column">
          Lead type:
          <select
            value={leadType}
            onChange={(e) => setLeadType(e.target.value)}
          >
            <option value="">All types...</option>
            <option>CALawyer</option>
            <option>DUILawyer</option>
            <option>AutoBody</option>
            <option>AutoRepair</option>
            <option>NSInsurance</option>
            <option>Chiropractor</option>
            <option>CAMPLJ</option>
          </select>
        </div>
        <div className="column">
          Hotness:
          <MultiSelect
            options={[
              { value: "sold", title: "Sold" },
              { value: "hot", title: "Hot" },
              { value: "warm", title: "Warm" },
              { value: "cold", title: "Cold" },
              { value: "sold cold", title: "Sold cold" },
              { value: "bad hot", title: "Bad hot" },
              { value: "rejected", title: "Rejected" },
              { value: "deleted", title: "Deleted" },
              { value: "unverified", title: "Unverified" },
              { value: "doa", title: "D.O.A." },
              { value: "cancel", title: "Cancel" },
              { value: "ping-1", title: "Ping 1" },
              { value: "ping-2", title: "Ping 2" },
              { value: "ready", title: "Ready" },
            ]}
            placeholder="All hotness..."
            initial={lead_level}
            accept={(v) => setLeadLevel([...v])}
          />
        </div>
        <div className="column">
          Referer:
          <select value={referer} onChange={(e) => setReferer(e.target.value)}>
            <option value="">All referers...</option>
            <option value="paid">Paid</option>
            <option value="organic">Organic</option>
            <option value="affiliates">Affiliates</option>
            <option value="chat">Chat</option>
            <option value="es">ES</option>
          </select>
        </div>
      </div>
      <br />
      <br />

      <CanvasJSChart
        options={{
          ...defaultGraphOptions,
          ...{
            data: [
              {
                type: "stackedColumn",
                color: "#CCC",
                showInLegend: true,
                name: "unverified",
                dataPoints: Object.keys(data.main.unverified).map((vv) => {
                  return {
                    label: data.main.unverified[vv].label,
                    y: data.main.unverified[vv].y,
                  };
                }),
              },
              {
                type: "stackedColumn",
                color: "#555",
                showInLegend: true,
                name: "rejected",
                dataPoints: Object.keys(data.main.rejected).map((vv) => {
                  return {
                    label: data.main.rejected[vv].label,
                    y: data.main.rejected[vv].y,
                  };
                }),
              },
              {
                type: "stackedColumn",
                color: "#000000",
                showInLegend: true,
                name: "bad hot",
                dataPoints: Object.keys(data.main.bad_hot).map((vv) => {
                  return {
                    label: data.main.bad_hot[vv].label,
                    y: data.main.bad_hot[vv].y,
                  };
                }),
              },
              {
                type: "stackedColumn",
                color: "#333333",
                showInLegend: true,
                name: "deleted",
                dataPoints: Object.keys(data.main.deleted).map((vv) => {
                  return {
                    label: data.main.deleted[vv].label,
                    y: data.main.deleted[vv].y,
                  };
                }),
              },
              {
                type: "stackedColumn",
                color: "#ff9800",
                showInLegend: true,
                name: "hot",
                dataPoints: Object.keys(data.main.hot).map((vv) => {
                  return {
                    label: data.main.hot[vv].label,
                    y: data.main.hot[vv].y,
                  };
                }),
              },
              {
                type: "stackedColumn",
                color: "#F44434",
                showInLegend: true,
                name: "sold",
                dataPoints: Object.keys(data.main.sold).map((vv) => {
                  return {
                    label: data.main.sold[vv].label,
                    y: data.main.sold[vv].y,
                  };
                }),
              },
              {
                type: "stackedColumn",
                color: "#9DD8EF",
                showInLegend: true,
                name: "cold",
                dataPoints: Object.keys(data.main.cold).map((vv) => {
                  return {
                    label: data.main.cold[vv].label,
                    y: data.main.cold[vv].y,
                  };
                }),
              },
              // {
              //   type: "stackedColumn",
              //   color: "#555",
              //   showInLegend: true,
              //   name: "doa",
              //   dataPoints: Object.keys(data.main.doa).map((vv) => {
              //     return {
              //       label: data.main.doa[vv].label,
              //       y: data.main.doa[vv].y,
              //       indexLabel: "$" + data.main.money[vv].y,
              //     };
              //   }),
              // },
            ],
          },
        }}
      />
      <br />
      <br />
      <h2>Referer Breakdown</h2>
      <CanvasJSChart
        options={{
          ...defaultGraphOptions,
          ...{
            data: [
              {
                type: "stackedColumn",
                color: "#4285F4",
                showInLegend: true,
                name: "paid",
                dataPoints: Object.keys(data.refererBreakdown.paid).map(
                  (vv) => {
                    return {
                      label: data.refererBreakdown.paid[vv].label,
                      y: data.refererBreakdown.paid[vv].y,
                    };
                  }
                ),
              },
              {
                type: "stackedColumn",
                color: "#EA4236",
                showInLegend: true,
                name: "organic",
                dataPoints: Object.keys(data.refererBreakdown.organic).map(
                  (vv) => {
                    return {
                      label: data.refererBreakdown.organic[vv].label,
                      y: data.refererBreakdown.organic[vv].y,
                    };
                  }
                ),
              },
              {
                type: "stackedColumn",
                color: "#333333",
                showInLegend: true,
                name: "calc",
                dataPoints: Object.keys(data.refererBreakdown.calc).map(
                  (vv) => {
                    return {
                      label: data.refererBreakdown.calc[vv].label,
                      y: data.refererBreakdown.calc[vv].y,
                    };
                  }
                ),
              },
              {
                type: "stackedColumn",
                color: "#FDBC00",
                showInLegend: true,
                name: "affiliates",
                dataPoints: Object.keys(data.refererBreakdown.affiliates).map(
                  (vv) => {
                    return {
                      label: data.refererBreakdown.affiliates[vv].label,
                      y: data.refererBreakdown.affiliates[vv].y,
                    };
                  }
                ),
              },
              {
                type: "stackedColumn",
                color: "#33A654",
                showInLegend: true,
                name: "chat",
                dataPoints: Object.keys(data.refererBreakdown.chat).map(
                  (vv) => {
                    return {
                      label: data.refererBreakdown.chat[vv].label,
                      y: data.refererBreakdown.chat[vv].y,
                    };
                  }
                ),
              },
              {
                type: "stackedColumn",
                color: "#FF6D01",
                showInLegend: true,
                name: "other",
                dataPoints: Object.keys(data.refererBreakdown.other).map(
                  (vv) => {
                    return {
                      label: data.refererBreakdown.other[vv].label,
                      y: data.refererBreakdown.other[vv].y,
                    };
                  }
                ),
              },
              {
                type: "stackedColumn",
                color: "#673AB7",
                showInLegend: true,
                name: "es",
                dataPoints: Object.keys(data.refererBreakdown.es).map((vv) => {
                  return {
                    label: data.refererBreakdown.es[vv].label,
                    y: data.refererBreakdown.es[vv].y,
                  };
                }),
              },
              {
                type: "stackedColumn",
                color: "#FFF",
                showInLegend: false,
                name: "dummy",
                dataPoints: Object.keys(data.refererBreakdown.other).map(
                  (vv) => {
                    return {
                      label: data.refererBreakdown.other[vv].label,
                      y: 0,
                      indexLabel:
                        "$" +
                        (data.refererBreakdown.other[vv]?.indexLabel &&
                        data.refererBreakdown.other[vv]?.indexLabel
                          ? Number(
                              data.refererBreakdown.other[vv].indexLabel
                            ).toFixed(2)
                          : 0),
                    };
                  }
                ),
              },
            ],
          },
        }}
      />
      <br />
      <br />
      <h2>Lead value stats</h2>
      <CanvasJSChart
        options={{
          ...defaultGraphOptions,
          ...{
            data: [
              {
                // type: "stackedColumn",
                color: "#F44434",
                showInLegend: true,
                name: "Pending Value",
                dataPoints: Object.keys(data.leadValueStats.pending_value).map(
                  (vv) => {
                    return {
                      label: data.leadValueStats.pending_value[vv].label,
                      y: data.leadValueStats.pending_value[vv].y,
                      indexLabel:
                        "$" +
                        Number(data.leadValueStats.pending_value[vv].y).toFixed(
                          2
                        ),
                    };
                  }
                ),
              },
              {
                // type: "stackedColumn",
                color: "#4caf50",
                showInLegend: true,
                name: "Settled Value",
                dataPoints: Object.keys(data.leadValueStats.settled_value).map(
                  (vv) => {
                    return {
                      label: data.leadValueStats.settled_value[vv].label,
                      y: data.leadValueStats.settled_value[vv].y,
                      indexLabel:
                        "$" +
                        Number(data.leadValueStats.settled_value[vv].y).toFixed(
                          2
                        ),
                    };
                  }
                ),
              },
            ],
          },
        }}
      />
      <br />
      <br />
      {/* <h2>Retail Vs. Ping Post</h2>
      <CanvasJSChart
        options={{
          ...defaultGraphOptions,
          ...{
            data: [
              {
                type: "stackedColumn",
                color: "#FF0102",
                showInLegend: true,
                name: "Retail",
                dataPoints: Object.keys(data.retailVsPingPost.retail).map(
                  (vv) => {
                    return {
                      label: data.retailVsPingPost.retail[vv].label,
                      y: data.retailVsPingPost.retail[vv].y,
                    };
                  }
                ),
              },
              {
                type: "stackedColumn",
                color: "#FFC000",
                showInLegend: true,
                name: "Ping Post",
                dataPoints: Object.keys(data.retailVsPingPost.affiliate).map(
                  (vv) => {
                    return {
                      label: data.retailVsPingPost.affiliate[vv].label,
                      y: data.retailVsPingPost.affiliate[vv].y,
                    };
                  }
                ),
              },
            ],
          },
        }}
      /> */}
    </div>
  );
}
