import React, { useEffect, useState } from "react";
import MultiSelect from "./MultiSelect";

const columns = [
  "Check box",
  "Expand",
  "Date added",
  "Name",
  "State",
  "Email",
  "Assigned",
  "Calls",
  "Emails",
  "Messages",
  "Ping",
  "Pending value",
  "Referer",
  "Hotness",
  "Source",
  "Type",
  "Assign Button",
  "Actions",
];
const getInitial = () => {
  const fromStorage = localStorage.getItem("columns");
  if (fromStorage) return JSON.parse(fromStorage);
  return columns.map((c, k) => k);
};

export default function ColumnSwitcher() {
  const [selected, setSelected] = useState(getInitial());
  const [style, setStyle] = useState("");

  useEffect(() => {}, []);

  useEffect(() => {
    let s = "";
    columns.forEach((v, k) => {
      if (!selected.includes(k))
        s =
          s +
          `#leadsTable tr:not(.expander) td:nth-of-type(${k + 1}){display:none;}
          #leadsTable th:nth-of-type(${k + 1}){display:none;}
      `;
    });
    setStyle(s);
    localStorage.setItem("columns", JSON.stringify(selected));
  }, [selected]);

  return (
    <div style={{ maxWidth: 250, margin: "10px 0" }}>
      Show columns:
      <MultiSelect
        options={columns.map((v, k) => {
          return { title: v, value: k };
        })}
        placeholder="Pick some..."
        initial={selected}
        accept={(v) => setSelected(v)}
      />
      <style>{style}</style>
    </div>
  );
}
