import React from "react";

export default function Lookup({
	businessName,
	setBusinessName,
	options,
	onClick,
	isLoadingVisible,
	areOptionsVisible
}) {
	return (
		<div className="form-input">
			<input
				value={businessName}
				onChange={setBusinessName}
				className={"lookup-input"}
				placeholder="Type your business name"
			/>
			<div
				className="lookup-break"
				style={
					!areOptionsVisible && !isLoadingVisible ? { display: "none" } : null
				}
			></div>
			<div
				className={"lookup-options"}
				style={!areOptionsVisible ? { display: "none" } : null}
			>
				{options && options.length ? (
					options.map((option, index) => (
						<div
							key={index}
							onClick={() => {
								onClick(option);
							}}
							className={"lookup-option"}
						>
							{option[0]}
						</div>
					))
				) : (
					<div className={"lookup-option"}>
						<p>No results</p>
					</div>
				)}
			</div>
			<div
				className={"lookup-options"}
				style={!isLoadingVisible ? { display: "none" } : null}
			>
				<div className={"lookup-option"}>
					<p>Loading...</p>
				</div>
			</div>
		</div>
	);
}
