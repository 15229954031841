import React, { useState } from "react";
import Axios from "axios";
import Swal from "sweetalert2";
import clsx from "clsx";

export default function AddLead({ users, loadLeads }) {
  const [addAjax, setAddAjax] = useState(false);
  const [data, setData] = useState({
    affiliate_id: "",
    ip_address: "1.2.3.4",
    state: "",
    zip: "",
    phone: "",
    email: "",
    firstName: "",
    lastName: "",
    city: "",
    description: "",
    ping_id: "",
  });
  const addLead = () => {
    setAddAjax(true);
    data.ping_id = data.ping_id || null;

    Axios.post(`${Axios.defaults.baseURL}api/users/affiliate/post`, data)
      .then((d) => {
        setAddAjax(false);
        Swal.fire("Lead successfully created!", "", "success");
        loadLeads();
        setData({
          affiliate_id: "",
          ip_address: "1.2.3.4",
          state: "",
          zip: "",
          phone: "",
          email: "",
          firstName: "",
          lastName: "",
          city: "",
          description: "",
          ping_id: "",
        });
      })
      .catch((e) => {
        setAddAjax(false);
        Swal.fire({
          title: "Lead not created!",
          html:
            "<blockquote style='text-align: left; font-size: 14px;'>" +
            JSON.stringify(
              e.response.data?.errors ? e.response.data.errors : e.response,
              null,
              2
            ) +
            "</blockquote>",
          type: "error",
        });
      });
  };

  return (
    <div
      style={{ maxWidth: 700, margin: "auto" }}
      className={clsx([{ ajax: addAjax }])}
    >
      <h2>Add lead</h2>
      <div className="row">
        <div className="column">
          <label htmlFor="first-name-input">First Name: *</label>
          <input
            id="first-name-input"
            value={data.firstName}
            onChange={(e) => {
              data.firstName = e.target.value;
              setData({ ...data });
            }}
          />
        </div>
        <div className="column">
          <label htmlFor="last-name-input">Last Name: *</label>
          <input
            id="last-name-input"
            value={data.lastName}
            onChange={(e) => {
              data.lastName = e.target.value;
              setData({ ...data });
            }}
          />
        </div>
      </div>

      <div className="row">
        <div className="column">
          <label htmlFor="phone-input">Phone: *</label>
          <input
            id="phone-input"
            value={data.phone}
            onChange={(e) => {
              data.phone = e.target.value;
              setData({ ...data });
            }}
          />
        </div>
        <div className="column">
          <label htmlFor="email-input">Email: *</label>
          <input
            id="email-input"
            value={data.email}
            onChange={(e) => {
              data.email = e.target.value;
              setData({ ...data });
            }}
          />
        </div>
      </div>
      <div className="row">
        <div className="column">
          <label htmlFor="city-input">City:</label>
          <input
            id="city-input"
            value={data.city}
            onChange={(e) => {
              data.city = e.target.value;
              setData({ ...data });
            }}
          />
        </div>
        <div className="column">
          <label htmlFor="zip-input">Zip:</label>
          <input
            id="zip-input"
            value={data.zip}
            onChange={(e) => {
              data.zip = e.target.value;
              setData({ ...data });
            }}
          />
        </div>
        <div className="column" style={{ width: 250 }}>
          <label htmlFor="state-input">State: *</label>
          <input
            id="state-input"
            value={data.state}
            maxLength={2}
            onChange={(e) => {
              data.state = e.target.value.toUpperCase();
              setData({ ...data });
            }}
          />
        </div>
      </div>
      <div className="row">
        <div className="column">
          <label htmlFor="ip-address-input">Ip address:</label>
          <input
            id="ip-address-input"
            value={data.ip_address}
            name={"Ip Address"}
            onChange={(e) => {
              data.ip_address = e.target.value;
              setData({ ...data });
            }}
          />
        </div>
      </div>
      <div className="row">
        <div className="column">
          <label htmlFor="description-input">Accident Description:</label>
          <input
            id="description-input"
            value={data.description}
            onChange={(e) => {
              data.description = e.target.value;
              setData({ ...data });
            }}
          />
        </div>
      </div>

      <div className="row">
        <div className="column">
          <label htmlFor="ping-id-input">Ping ID:</label>
          <input
            id="ping-id-input"
            value={data.ping_id}
            onChange={(e) => {
              data.ping_id = e.target.value;
              setData({ ...data });
            }}
          />
        </div>
      </div>

      <div className="row">
        <div className="column">
          <label htmlFor="affiliate-user-input">Affiliate User:</label>
          <select
            id="affiliate-user-input"
            value={data.affiliate_id}
            onChange={(e) => {
              data.affiliate_id = e.target.value;
              setData({ ...data });
            }}
          >
            <option value="">Select one...</option>
            {users
              .filter((u) => u.account_type === "affiliate" && !u.archived)
              .map((u) => (
                <option value={u._id} key={u._id} data-testid="select-option">
                  {u.email}
                </option>
              ))}
          </select>
        </div>
      </div>

      <div className="footer">
        <span />
        <button
          onClick={() => addLead()}
          disabled={
            !data.firstName ||
            !data.lastName ||
            !data.email ||
            !data.phone ||
            !data.state ||
            !data.affiliate_id
          }
        >
          Add Lead
        </button>
      </div>
    </div>
  );
}
