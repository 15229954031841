import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import clsx from "clsx";
import DashboardPage from "./dashboard";
import SearchStats from "../components/SearchStats";
import UserLeadsDashboard from "../components/UserLeadsDashboard";
import Axios from "axios";
import swal from "sweetalert2";
import UserLeadsTable from "../components/UserLeadsTable";

export default class DashboardTabs extends Component {
	loadLeads() {
		if (!this.state.loadingLeads) {
			this.setState({ loadingLeads: true });
			Axios.get("/api/leads/user/leads").then((d) => {
				this.setState({
					leads: d.data.leads,
					loadingLeads: false
				});
			});
		}
	}

	componentDidMount() {
		window.addEventListener("case-added", () => this.switchToDashboard());
		this.loadLeads();
	}

	componentWillUnmount() {
		window.removeEventListener("case-added", () => this.switchToDashboard());
	}

	switchToDashboard() {
		window.lastActiveDashboardTab = 1;
		this.setState({ tab: 1 });
	}
	state = {
		tab: window.lastActiveDashboardTab || 0,
		leads: []
	};

	acceptLead = (l) => {
		// console.log("ACCEPT");
		console.log(l);
		if (!this.props.user.payment && l.lead_level !== "free") {
			swal.fire(
				"Whoops",
				"You don't have any payments methods configured. You cannot purchase leads.",
				"warning"
			);
			return false;
		}

		Axios.get("/api/leads/user/accept?lead_id=" + l._id)
			.then((d) => {
				// console.log(d.data);
				this.loadLeads();
				if (d.data.success) {
					swal
						.fire({
							title: "Lead added!",
							text:
								"Lead is added to your account. You can find it in your dashboard.",
							type: "success",
							showCancelButton: true,
							cancelButtonText: "OK",
							confirmButtonText: "Go to leads",
							reverseButtons: true
						})
						.then((v) => {
							window.lastActiveDashboardTab = 2;
							this.setState({ tab: 2 });
						});
				} else {
					swal.fire("Error", d.data.error, "error");
				}
			})
			.catch((e) => {
				console.log(e);
				swal.fire("Error", e.response.data.error, "error");
			});
	};

	declineLead = (l) => {
		// console.log("DECLINE");
		// console.log(l);
		Axios.get("/api/leads/user/decline?lead_id=" + l._id)
			.then((d) => {
				// console.log(d.data);
				if (d.data.success) {
					this.loadLeads();
				} else {
					swal.fire("Error", d.data.error, "error");
				}
			})
			.catch((e) => {
				console.log(e);
				swal.fire("Error", e.response.data.error, "error");
			});
	};

	render() {
		if (!this.props.user) return <Redirect to="/signin" />;

		return (
			<div id="admin-page">
				<h1>Dashboard</h1>
				<div id="tabs">
					<button
						className={clsx({ active: this.state.tab === 0 })}
						onClick={() => {
							this.setState({ tab: 0 });
							window.lastActiveDashboardTab = 0;
							window.dispatchEvent(new Event("switch-to-stats"));
						}}
					>
						Stats
					</button>
					<button
						className={clsx({ active: this.state.tab === 1 })}
						onClick={() => {
							this.setState({ tab: 1 });
							window.lastActiveDashboardTab = 1;
						}}
					>
						Your Cases
					</button>
					<button
						className={clsx({ active: this.state.tab === 2 })}
						onClick={() => {
							this.setState({ tab: 2 });
							window.lastActiveDashboardTab = 2;
						}}
					>
						Leads
					</button>
				</div>
				<div className={clsx("tab", { show: this.state.tab === 0 })}>
					<UserLeadsDashboard
						leads={this.state.leads.filter((v) => {
							return !v.accepted;
						})}
						acceptLead={(l) => this.acceptLead(l)}
						declineLead={this.declineLead}
					/>
					<SearchStats {...this.props} />
				</div>
				<div className={clsx("tab", { show: this.state.tab === 1 })}>
					<DashboardPage {...this.props} />
				</div>
				<div className={clsx("tab", { show: this.state.tab === 2 })}>
					{this.state.loadingLeads ? (
						<div>
							<img
								src="/img/ajax-loader.gif"
								alt="loadingRequests..."
								className="loader-gif"
							/>
							&nbsp;Loading Leads...
						</div>
					) : (
						<>
							<UserLeadsDashboard
								leads={this.state.leads.filter((v) => {
									return !v.accepted;
								})}
								acceptLead={(l) => this.acceptLead(l)}
								declineLead={this.declineLead}
							/>

							<UserLeadsTable
								leads={this.state.leads.filter((v) => {
									return (
										v.accepted === this.props.user._id ||
										v.accepted === this.props.user.accountOwner
									);
								})}
								user={this.props.user}
							/>
						</>
					)}
				</div>
			</div>
		);
	}
}
