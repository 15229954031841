import swal from "sweetalert2";
import promiseAllSequential from "promise-all-sequential";
import Axios from "axios";
import md5 from "md5";
import moment from "moment";
import { hasCommercial } from "./components/Helpers";
import { sourceSubIDs } from "./poster";

/* istanbul ignore file */

//convert json to url query params
export const jsonToQueryString = (json) => {
  return (
    "?" +
    Object.keys(json)
      .map(function (key) {
        return encodeURIComponent(key) + "=" + encodeURIComponent(json[key]);
      })
      .join("&")
  );
};

export const fourLegalDate = (lead) => {
  if (lead.extra_details?.accidentDetails?.date) {
    return moment(lead.extra_details?.accidentDetails?.date).format(
      "MM/DD/YYYY"
    );
  }
  if (lead.alert) {
    return moment(lead.alert.startDate).format("MM/DD/YYYY");
  }
  return moment(lead.createdAt).format("MM/DD/YYYY");
};

export const pingAll = (l, callbackState, callbackData, updateLead) => {
  let scope_zip;
  let whoToPing = [];

  const pingProvidersConfig = {
    eLocal: async () => {
      /*
      // eLOCAL START
      */
      return new Promise((resolve) => {
        const formData = {
          proxy_url: "https://api.elocal.com/lead/ping",
          ping: {
            key: "df7a010d2a174d8ec7904917f51312c02388bf90",
            zip_code: scope_zip,
            need_id: l.lead_type === "DUILawyer" ? "8012-8162" : "8031-10217",
            tcpa_consent: true,
            sender_id: "",
            sender_origin_key: "",
            exclusive: true,
            leadid_identifier: "0",
          },
        };
        callbackData(
          `Contacting eLocal ping API, with data: <blockquote>${JSON.stringify(
            formData.ping,
            null,
            2
          )}</blockquote>`
        );

        Axios.post("/api/leads/proxy", formData)
          .then((d) => {
            // d.data.response.status = "success";
            // d.data.response.price = 10;
            callbackData(
              (d.data.response.status === "success" ? "✅" : "❌") +
                ` eLocal ping API response: <blockquote>${JSON.stringify(
                  d.data.response || d.data,
                  null,
                  2
                )}</blockquote><br><hr>`
            );
            resolve({
              price: d.data.response.price ? d.data.response.price : 0,
              provider: "eLocal",
              ping_token: d.data.response.token,
            });
          })
          .catch(console.log);
      });
      /*
      // eLOCAL END
      */
    },
    "4legal": async () => {
      /*
        // 4legal start
        */
      return new Promise((resolve) => {
        if (!l.alert && l.source !== "direct") {
          callbackData(
            ` ❌ There is no alert or startDate field for this lead.<br><hr>`
          );

          return resolve();
        }
        const trusted_form_cert_id =
          document.getElementById("xxTrustedFormCertUrl_0") &&
          document.getElementById("xxTrustedFormCertUrl_0").value !== ""
            ? document.getElementById("xxTrustedFormCertUrl_0").value
            : "";

        const formData = {
          proxy_url: "https://leads.4legalleads.com/apiJSON.php",
          Request: {
            Key: "66170c43dcf14e33edc357b2cb4e14688d986d7023995ffea3e0f6816bb717bb",
            Zip: scope_zip, //l.zip
            API_Action: "pingPostLead",
            SRC: "MA",
            Mode: "ping",
            Return_Best_Price: "1",
            Language: "English",
            TYPE: "31",
            Sub_ID: sourceSubIDs[l.source] ? sourceSubIDs[l.source] : "",
            TCPA: "Yes",
            Hash_Type: "md5",
            Email_Hash: md5(l.email),
            Phone_Hash: md5(l.phone),
            Have_Attorney: "No",
            Type_Of_Legal_Problem: "Auto Accident Injury",
            Format: "XML",
            Incident_Date: fourLegalDate(l),
            Doctor_Treatment: "Yes",
            Were_You_At_Fault: "No",
            User_Agent: l.user_agent || navigator.userAgent,
            Trusted_Form_URL: l.trusted_form_cert || trusted_form_cert_id,
          },
        };
        callbackData(
          `Contacting 4legal ping API, with data: <blockquote>${JSON.stringify(
            formData.Request,
            null,
            2
          )}</blockquote>`
        );

        Axios.post("/api/leads/proxy", formData)
          .then((d) => {
            // d.data.response.status = "success";
            // d.data.response.price = 10;
            callbackData(
              (d.data.response.status === "Matched" ? "✅" : "❌") +
                ` 4Legal ping API response: <blockquote>${JSON.stringify(
                  d.data.response || d.data,
                  null,
                  2
                )}</blockquote><br><hr>`
            );
            resolve({
              price: d.data.response.price ? d.data.response.price : 0,
              provider: "4legal",
              ping_token: d.data.response.lead_id
                ? d.data.response.lead_id
                : "",
            });
          })
          .catch(console.log);
      });
      /*
        // 4legal end
        */
    },
    ring: async () => {
      // ring partner start

      return new Promise((resolve) => {
        let resolved = false;
        const formData = {
          publisher_id: "DP11eb7235630139f0a40cc5749cc0ea1f",
          api_campaign_id:
            l.lead_type === "AutoBody"
              ? "6229f26ff4226340051db00c67c0d67827ed80c7"
              : "AC11e92e58745d44a08f061ff13b505353",
          zip: scope_zip,
          tcpa: 1,
          has_attorney: 0,
          received_treatment: 0,
          incident_occurred: moment(l.createdAt).format("YYYY-MM-DD"),
          physically_injured: 1,
          comments: l.notes,
          were_you_at_fault: 0,
          type_of_injury: "Car Accident",
          accident_type: "Car Accident",
          landing_page_url: "https://myaccident.org",
          MVA_Accident_Type: hasCommercial(l) ? "Commercial Vehicle" : "Car",
          county: l.county || "",
        };

        callbackData(
          `Contacting ring ping API, with data: <blockquote>${JSON.stringify(
            formData,
            null,
            2
          )}</blockquote>`
        );

        formData.proxy_url = "https://forms.ringpartner.com/ping";

        Axios.post("/api/leads/proxy", formData)
          .then((d) => {
            // d.data.status = "success";
            // d.data.response.price = 10;
            callbackData(
              (d.data.status === "ok" ? "✅" : "❌") +
                ` ring ping API response: <blockquote>${JSON.stringify(
                  d.data,
                  null,
                  2
                )}</blockquote><br><hr>`
            );
            resolved = true;
            resolve({
              price: d.data.bid ? d.data.bid : 0,
              provider: "ring",
              ping_token: d.data.lead_id ? d.data.lead_id : "",
            });
          })
          .catch(console.log);
        setTimeout(() => {
          if (!resolved) {
            callbackData(
              ` ❌ ring ping API didn't respond in 10 seconds. Ping is cancelled.<br><hr>`
            );
            resolve({
              price: 0,
              provider: "ring",
              ping_token: "",
            });
          }
        }, 10000);
      });
      /*
        // ring partner end
      */
    },
    bestCaseHE: async () => {
      /*
        // BestCase HighEnd partner start
        */
      return new Promise((resolve) => {
        /*let resolved = false;
        setTimeout(() => {
          if (!resolved) {
            callbackData(
              ` ❌ BestCase API didn't respond in 10 seconds. Ping is cancelled.<br><hr>`
            );
            resolve({
              price: 0,
              provider: "ring",
              ping_token: "",
            });
          }
        }, 10000);*/

        const formData = {
          convertToForm: {
            lp_campaign_id: "60996456ec912",
            lp_campaign_key: "qd3wNyFbvLBxkV2tWmc4",
            lp_s1: "MyAccident.org",
            lp_response: "JSON",
            zip_code: scope_zip,
            // email: "test1@test.com",
            // first_name: "test",
            // last_name: "test",
            has_attorney: "No",
            // ip_address: l.ip_address,
            treatment: "No",
            injury_year: moment(l.createdAt).format("YYYY"),
            injury_month: moment(l.createdAt).format("MM"),
            mva_physical_injury: "Yes",
            case_description: l.notes,
            mva_accident_fault: "No",
            external_lead_id: l._id,
            landing_page: "https://myaccident.org",
            // user_agent: "Chrome",
            mva_accident_type: hasCommercial(l)
              ? "Commercial Vehicle Accident"
              : "Car Accident",
          },
        };

        callbackData(
          `Contacting BestCase API, with data: <blockquote>${JSON.stringify(
            formData.convertToForm,
            null,
            2
          )}</blockquote>`
        );

        formData.proxy_url =
          "https://bestcaseleads.leadspediatrack.com/ping.do";

        Axios.post("/api/leads/proxy", formData)
          .then((d) => {
            // d.data.status = "success";
            // d.data.response.price = 10;
            callbackData(
              (d.data.result === "success" ? "✅" : "❌") +
                ` BestCase API response: <blockquote>${JSON.stringify(
                  d.data,
                  null,
                  2
                )}</blockquote><br><hr>`
            );

            resolve({
              price: d.data.price ? d.data.price : 0,
              provider: "bestCaseHE",
              ping_token: d.data.ping_id ? d.data.ping_id : "",
            });
          })
          .catch(console.log);
      });
    },
    bestCaseBS: async () => {
      /*
        // BestCase BackStop partner start
        */
      return new Promise((resolve) => {
        /*let resolved = false;
        setTimeout(() => {
          if (!resolved) {
            callbackData(
              ` ❌ BestCase API didn't respond in 10 seconds. Ping is cancelled.<br><hr>`
            );
            resolve({
              price: 0,
              provider: "ring",
              ping_token: "",
            });
          }
        }, 10000);*/

        const formData = {
          convertToForm: {
            lp_campaign_id: "609964afe4d49",
            lp_campaign_key: "NnzrfwLFj2GVWZ6qQpyb",
            lp_s1: "MyAccident.org",
            lp_response: "JSON",
            zip_code: scope_zip,
            // email: "test1@test.com",
            // first_name: "test",
            // last_name: "test",
            has_attorney: "No",
            // ip_address: l.ip_address,
            treatment: "No",
            injury_year: moment(l.createdAt).format("YYYY"),
            injury_month: moment(l.createdAt).format("MM"),
            mva_physical_injury: "Yes",
            case_description: l.notes,
            mva_accident_fault: "No",
            external_lead_id: l._id,
            landing_page: "https://myaccident.org",
            // user_agent: "Chrome",
            mva_accident_type: hasCommercial(l)
              ? "Commercial Vehicle Accident"
              : "Car Accident",
          },
        };

        callbackData(
          `Contacting BestCase API, with data: <blockquote>${JSON.stringify(
            formData.convertToForm,
            null,
            2
          )}</blockquote>`
        );

        formData.proxy_url =
          "https://bestcaseleads.leadspediatrack.com/ping.do";

        Axios.post("/api/leads/proxy", formData)
          .then((d) => {
            // d.data.status = "success";
            // d.data.response.price = 10;
            callbackData(
              (d.data.result === "success" ? "✅" : "❌") +
                ` BestCase API response: <blockquote>${JSON.stringify(
                  d.data,
                  null,
                  2
                )}</blockquote><br><hr>`
            );

            resolve({
              price: d.data.price ? d.data.price : 0,
              provider: "bestCaseBS",
              ping_token: d.data.ping_id ? d.data.ping_id : "",
            });
          })
          .catch(console.log);
      });
    },
    quantify: async () => {
      /*
        // Quantify start
        */
      const baseUrl = "https://leads.quantify.com/new_api/api.php";
      const request = {
        Key: "f30854e1f72146e17b6a51937a8790bb01d014c93f3ac8821de5b92398d02dad",
        Zip: scope_zip, //l.zip
        API_Action: "pingPostLead",
        SRC: "myaccident",
        Mode: "ping",
        Return_Best_Price: "1",
        TYPE: "25",
        Got_Atty: "No",
        Injury_Cause: "Auto Accident",
        Format: "JSON",
        Incident_Date: moment(l.createdAt).format("MM/DD/YYYY"),
        At_Fault: "No",
        Physical_Injury: "Yes",
      };

      return new Promise((resolve) => {
        const formData = {
          proxy_url: baseUrl + jsonToQueryString(request),
        };
        callbackData(
          `Contacting quantify ping API, with data: <blockquote>${JSON.stringify(
            request,
            null,
            2
          )}</blockquote>`
        );

        Axios.post("/api/leads/proxy", formData)
          .then((d) => {
            // d.data.response.status = "success";
            // d.data.response.price = 10;
            callbackData(
              (d.data.response.status === "Matched" ? "✅" : "❌") +
                ` quantify ping API response: <blockquote>${JSON.stringify(
                  d.data.response || d.data,
                  null,
                  2
                )}</blockquote><br><hr>`
            );
            resolve({
              price: d.data.response.price ? d.data.response.price : 0,
              provider: "quantify",
              ping_token: d.data.response.lead_id
                ? d.data.response.lead_id
                : "",
            });
          })
          .catch(console.log);
      });
      /*
        // 4legal end
        */
    },
    // otherProvider: async () => {
    //   /*
    //   // OTHER START
    //   */
    //   callbackData("Contacting other provider ping API...");
    //   return new Promise((resolve) => {
    //     setTimeout(() => {
    //       callbackData("Other ping API response...<br><br><hr>");
    //       resolve({
    //         price: 14,
    //         provider: "otherProvider",
    //       });
    //     }, 1000);
    //   });
    //   /*
    //   // OTHER END
    //   */
    // },
  };

  whoToPing = [pingProvidersConfig.eLocal, pingProvidersConfig.quantify];
  if (l.source !== "tawk.to" && l.source !== "Ad form")
    whoToPing.push(pingProvidersConfig["4legal"]);

  if (l.lead_type === "DUILawyer") whoToPing = [pingProvidersConfig.eLocal];
  if (l.lead_type === "AutoBody") whoToPing = [pingProvidersConfig.ring];

  if (!l.zip) {
    swal
      .fire({
        title: "Ping All Providers",
        text: "Confirm or enter the zip code:",
        input: "text",
        inputValue: l.zip || "",
        confirmButtonText: "Ping",
        inputValidator: (result) => !result && "You need to enter zip!",
      })
      .then(({ value }) => {
        if (value) {
          scope_zip = value;
          callbackState("running");
          promiseAllSequential(
            // Object.keys(pingProvidersConfig).map((v) => pingProvidersConfig[v])
            whoToPing //ping just specific in array
          )
            .then((result) => {
              let maxPrice = 0;
              let provider = "";
              result.forEach((v) => {
                if (v.price > maxPrice) {
                  maxPrice = v.price;
                  provider = v.provider;
                }
              });
              if (maxPrice) {
                callbackData(
                  `<br>Max price received: $${maxPrice} from ${provider}. Lead updated.`
                );

                let out = {};
                result.forEach((v) => {
                  out[v.provider] = { price: v.price };
                  if (v.ping_token) out[v.provider].ping_token = v.ping_token;
                });
                updateLead(out, scope_zip);
              } else {
                callbackData(`<br>All providers returned $0!`);
              }
              callbackData(`<br>All done!`);
              callbackState("done");
            })
            .catch(console.log);
        }
      })
      .catch(console.log);
  } else {
    scope_zip = l.zip;
    callbackState("running");
    promiseAllSequential(
      // Object.keys(pingProvidersConfig).map((v) => pingProvidersConfig[v])
      whoToPing //ping just specific in array
    )
      .then((result) => {
        let maxPrice = 0;
        let provider = "";
        result.forEach((v) => {
          if (v.price > maxPrice) {
            maxPrice = v.price;
            provider = v.provider;
          }
        });
        if (maxPrice) {
          callbackData(
            `<br>Max price received: $${maxPrice} from ${provider}. Lead updated.`
          );

          let out = {};
          result.forEach((v) => {
            out[v.provider] = { price: v.price };
            if (v.ping_token) out[v.provider].ping_token = v.ping_token;
          });
          updateLead(out, l.zip);
        } else {
          callbackData(`<br>All providers returned $0!`);
        }
        callbackData(`<br>All done!`);
        callbackState("done");
      })
      .catch(console.log);
  }
};
