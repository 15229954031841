/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { FaRegSquare, FaCheckSquare, FaChevronDown } from "react-icons/fa";
import { MdClose } from "react-icons/md";

export default function MultiSelect({
  options,
  placeholder,
  initial,
  accept,
  showFilter,
}) {
  const [show, setShow] = useState(false);
  const [filter, setFilter] = useState("");
  const [selected, setSelected] = useState(initial);

  const toggle = (w) => {
    if (selected?.includes(w)) {
      setSelected(selected.filter((v) => w !== v));
    } else {
      selected.push(w);
      setSelected([...selected]);
    }
  };

  const cancel = () => {
    setShow(false);
    setSelected([...initial]);
  };

  const _accept = () => {
    accept(selected);
    setShow(false);
  };
  return (
    <div
      className={show ? "multi-select-dropdown show" : "multi-select-dropdown"}
      data-testid={placeholder}
    >
      <div
        className="placeholder"
        onClick={() => {
          if (!show) setShow(true);
          else cancel();
        }}
      >
        {selected?.length ? selected.length + " selected" : placeholder}
        {!show ? (
          <FaChevronDown size={11} style={{ float: "right", marginTop: 4 }} />
        ) : null}
      </div>
      <div className="content">
        {showFilter ? (
          <div style={{ position: "relative" }}>
            <input
              type="text"
              placeholder="Search..."
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
              style={{ marginTop: 10 }}
            />
            <a
              onClick={() => setFilter("")}
              data-testid={"clear-filter"}
              style={{
                position: "absolute",
                right: 7,
                top: 15,
                color: "#666",
                opacity: filter.length ? 1 : 0,
                transition: "all 0.3s",
              }}
            >
              <MdClose />
            </a>
          </div>
        ) : null}
        <div className="options">
          {options
            .filter((v) => {
              if (filter)
                return v.title.toLowerCase().indexOf(filter.toLowerCase()) > -1;
              return v;
            })
            .map((v) => (
              <a
                key={v.value}
                onClick={() => toggle(v.value)}
                className={selected?.includes(v.value) ? "selected" : ""}
              >
                {selected?.includes(v.value) ? (
                  <FaCheckSquare />
                ) : (
                  <FaRegSquare />
                )}{" "}
                {v.title}
              </a>
            ))}
        </div>
        <button className="close" onClick={() => cancel()}>
          <MdClose />
        </button>
        <div className="row">
          <div className="column">
            <a onClick={() => setSelected([])}>Clear</a>
          </div>
          <div className="column" style={{ textAlign: "right" }}>
            <button className="accept" onClick={() => _accept()}>
              Apply
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
