import React from "react";
import { FaCheck } from "react-icons/fa";
import { withRouter } from "react-router";
import swal from "sweetalert2";
import Axios from "axios";

class PlanChooser extends React.Component {
	state = {
		tab: 0,
		adHover: false,
		includeAdvertising: this.props.plan && this.props.plan === "Gold"
	};
	contactUs(plan) {
		swal
			.fire({
				title: "Change Plan?",
				text: "Optional message for MyAccident Team:",
				input: "textarea",
				showCancelButton: true,
				reverseButtons: true,
				confirmButtonText: "Send"
			})
			.then((v) => {
				if (v.hasOwnProperty("value")) {
					// console.log(v.value);
					// console.log(plan);
					Axios.post("/api/businessAccount/contact", {
						text:
							`User would like to change MAIN account plan to ${plan}. Message: ` +
							v.value
					}).then((d) => {
						swal.fire(
							"Done!",
							"Your request has been sent. Someone will contact you shortly.",
							"success"
						);
					});
				}
			});
	}

	choosePlan(plan) {
		// let plan = "basic";
		// if (this.state.includeAdvertising) plan = "gold";
		// if (this.state.subplan === "texas") plan = "texas";
		// if (this.state.subplan === "regions") plan = "regions";
		// console.log(plan, this.state.markets);
		// this.props.choosePlan(plan, this.state.markets);
		// this.setState({ regionsPicker: false });
		this.props.choosePlan(plan);
	}

	but(plan) {
		// console.log(plan);
		// console.log(this.props.plan);
		if (this.props.signUpPage) {
			return <button onClick={() => this.choosePlan(plan)}>Choose Plan</button>;
		} else {
			return (
				<button
					onClick={() => this.contactUs(plan)}
					disabled={this.props.plan === plan}
				>
					{this.props.plan === plan ? "Current plan" : "Change plan"}
				</button>
			);
		}
	}

	render() {
		return (
			<>
				<div id="plans">
					<div className="box-holder">
						<h2>BASIC</h2>
						<div className="box">
							<div className="top top2">
								<div className="price">
									$9.95<span>/mo</span>
								</div>
								<div className="line" />
								PER USER
							</div>
							<img
								src="/img/triangle-2.gif"
								alt="triangle"
								className="triangle"
							/>
							<div className="li">
								<FaCheck color="#f29338" size="20" />
								<span>1 User Licence</span>
							</div>
							<div className="line2" />
							<div className="li">
								<FaCheck color="#f29338" size="20" />
								<span>
									Alerts
									<small style={{ maxWidth: 285 }}>
										Instant notifications when the officer files the report
									</small>
								</span>
							</div>
							<div className="line2" />
							<div className="li">
								<FaCheck color="#f29338" size="20" />
								<span>
									MyAccident <span className="super">TM</span>
									<small style={{ maxWidth: 285 }}>
										Business licence to search and find any accident in the last
										12 months
									</small>
								</span>
							</div>
							<div className="line2" />
							<div className="li">
								<FaCheck color="#f29338" size="20" />
								<span>
									Pro Search
									<small>Advanced search options</small>
								</span>
							</div>
							<div className="line2" />
							<div className="li">
								<FaCheck color="#f29338" size="20" />
								<span>
									MyAccident Complete:
									<small>
										TxDOT Certified Cr3 +<br />
										DMV Owner Info + <br />
										MyAccident Pro (Your Brand)
									</small>
									{/* <p
										className="price"
										style={{ display: "flex", marginLeft: 4, marginTop: 0 }}
									>
										$18.95
										<small style={{ marginTop: 10, color: "#f29338" }}>
											/per order
										</small>
									</p> */}
								</span>
							</div>
							{/* {this.props.signUpPage ? (
								<div>
									<div className="line2" />
									<div>
										Enter promo code:
										<br />
										<input
											type="text"
											style={{
												width: "auto",
												color: "#000"
											}}
										/>
									</div>
								</div>
							) : null} */}
							<div className="line2" />
							{this.but("basic")}
						</div>
					</div>
					<div className="box-holder">
						<h2>Teams</h2>
						<div className="box">
							<div className="top top2">
								<div className="price">
									$29.95<span>/mo</span>
								</div>
								<div className="line" />5 USERS
							</div>
							<img
								src="/img/triangle-2.gif"
								alt="triangle"
								className="triangle"
							/>
							<div className="li">
								<FaCheck color="#f29338" size="20" />
								<span>5 User Licence</span>
							</div>
							<div className="line2" />
							<div className="li">
								<FaCheck color="#f29338" size="20" />
								<span>
									Alerts
									<small style={{ maxWidth: 285 }}>
										Instant notifications when the officer files the report
									</small>
								</span>
							</div>
							<div className="line2" />
							<div className="li">
								<FaCheck color="#f29338" size="20" />
								<span>
									MyAccident <span className="super">TM</span>
									<small style={{ maxWidth: 285 }}>
										Business licence to search and find any accident in the last
										12 months
									</small>
								</span>
							</div>
							<div className="line2" />
							<div className="li">
								<FaCheck color="#f29338" size="20" />
								<span>
									Pro Search
									<small>Advanced search options</small>
								</span>
							</div>
							<div className="line2" />
							<div className="li">
								<FaCheck color="#f29338" size="20" />
								<span>
									MyAccident Complete:
									<small>
										TxDOT Certified Cr3 +<br />
										DMV Owner Info + <br />
										MyAccident Pro (Your Brand)
									</small>
									{/* <p
										className="price"
										style={{ display: "flex", marginLeft: 4, marginTop: 0 }}
									>
										$18.95
										<small style={{ marginTop: 10, color: "#f29338" }}>
											/per order
										</small>
									</p> */}
								</span>
							</div>
							<div className="line2" />
							<div className="li">
								<FaCheck color="#f29338" size="20" />
								<span>
									Business Support Chat:
									<small>9am-5pm Monday-Friday</small>
								</span>
							</div>
							{/* {this.props.signUpPage ? (
								<div>
									<div className="line2" />
									<div>
										Enter promo code:
										<br />
										<input
											type="text"
											style={{
												width: "auto",
												color: "#000"
											}}
										/>
									</div>
								</div>
							) : null} */}
							<div className="line2" />
							{this.but("teams")}
						</div>
					</div>
					<div className="box-holder">
						<h2>Unlimited</h2>
						<div className="box">
							<div className="top top2">
								<div className="price">
									$99.95<span>/mo</span>
								</div>
								<div className="line" />
								UNLIMITED
							</div>
							<img
								src="/img/triangle-2.gif"
								alt="triangle"
								className="triangle"
							/>
							<div className="li">
								<FaCheck color="#f29338" size="20" />
								<span>Unlimited Users</span>
							</div>
							<div className="line2" />
							<div className="li">
								<FaCheck color="#f29338" size="20" />
								<span>
									Alerts
									<small style={{ maxWidth: 285 }}>
										Instant notifications when the officer files the report
									</small>
								</span>
							</div>
							<div className="line2" />
							<div className="li">
								<FaCheck color="#f29338" size="20" />
								<span>
									MyAccident <span className="super">TM</span>
									<small style={{ maxWidth: 285 }}>
										Business licence to search and find any accident in the last
										12 months
									</small>
								</span>
							</div>
							<div className="line2" />
							<div className="li">
								<FaCheck color="#f29338" size="20" />
								<span>
									Pro Search
									<small>Advanced search options</small>
								</span>
							</div>
							<div className="line2" />
							<div className="li">
								<FaCheck color="#f29338" size="20" />
								<span>
									MyAccident Complete:
									<small>
										TxDOT Certified Cr3 +<br />
										DMV Owner Info + <br />
										MyAccident Pro (Your Brand)
									</small>
								</span>
							</div>
							<div className="line2" />
							<div className="li">
								<FaCheck color="#f29338" size="20" />
								<span>
									Business Support Chat:
									<small>9am-5pm Monday-Friday</small>
								</span>
							</div>
							<div className="line2" />
							<div className="li">
								<FaCheck color="#f29338" size="20" />
								<span>Dedicated Customer Success Manager</span>
							</div>
							{/* {this.props.signUpPage ? (
								<div>
									<div className="line2" />
									<div>
										Enter promo code:
										<br />
										<input
											type="text"
											style={{
												width: "auto",
												color: "#000"
											}}
										/>
									</div>
								</div>
							) : null} */}
							<div className="line2" />
							{this.but("unlimited")}
						</div>
					</div>

					{/* <img
						src="/img/customize-ad.png"
						style={{
							opacity: this.state.adHover ? 1 : 0,
							top: this.state.adHover ? 0 : "-100vh"
						}}
						alt="customize ad"
						className="customize-ad-popup"
						onClick={() => this.setState({ adHover: false })}
					/> */}
				</div>
			</>
		);
	}
}
export default withRouter(PlanChooser);
