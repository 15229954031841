import Axios from "axios";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";

export default function Blacklist() {
	const [ajax, setAjax] = useState(true);
	const [list, setList] = useState([]);
	const [number, setNumber] = useState("");
	useEffect(() => {
		Axios.get("api/blacklist/get").then((d) => {
			setList(d.data.list);
			setAjax(false);
		});
	}, []);

	const submit = (e) => {
		e.preventDefault();
		Axios.post("api/blacklist/add", { number }).then((d) => {
			list.push(number);
			setList([...list]);
			setNumber("");
		});
	};

	const askRemove = async (n) => {
		const { value } = await Swal.fire({
			title: "Are you sure?",
			text: "This operation cannot be undone.",
			type: "question",
			showCancelButton: true,
			reverseButtons: true
		});
		if (value) {
			Axios.post("api/blacklist/delete", { number: n }).then((d) => {
				setList(list.filter((v) => v !== n));
			});
		}
	};

	if (ajax)
		return (
			<div>
				<img
					src="/img/ajax-loader.gif"
					alt="loading alerts..."
					className="loader-gif"
				/>
				&nbsp;Loading blacklist...
			</div>
		);
	return (
		<div>
			<h2>Blacklisted numbers</h2>
			<form onSubmit={(e) => submit(e)}>
				<label>
					Add new number:
					<br />
					<input
						style={{ width: 250, marginRight: 5 }}
						value={number}
						onChange={(e) => setNumber(e.target.value)}
					/>
					<button disabled={!number}>Add</button>
				</label>
			</form>
			<br />
			{list.length === 0 ? (
				<div>No numbers in blacklist.</div>
			) : (
				<table>
					<tbody>
						{list.map((l) => (
							<tr key={l}>
								<td>{l}</td>
								<td style={{ width: 100 }}>
									<button onClick={() => askRemove(l)} data-testid={`remove-${l}`} >remove</button>
								</td>
							</tr>
						))}
					</tbody>
				</table>
			)}
		</div>
	);
}
