/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef } from "react";
import { availableStates } from "./LeadFilter";
import { MdCheckBox, MdCheckBoxOutlineBlank } from "react-icons/md";
import Checkbox from "./Shared/Checkbox";
import SubIDWeightControl from "./SubIDWeightControl";

export default function UserExpander(props) {
  const u = props.data;
  // console.log(u);
  const fields = [
    "businessEmail",
    "businessName",
    "businessPhone",
    "businessType",
    "firstName",
    "lastName",
    "phoneNumber",
    "address",
    "city",
    "zip",
    "state",
    "customerID",
    "website",
    "logo_url",
    "_id",
  ];
  // console.log(u);
  const SMStext = useRef();
  const morganSource = useRef();
  const lac = useRef();
  useEffect(() => {
    // console.log("EFF");
    if (u.autoSMSText) SMStext.current.value = u.autoSMSText;
    if (u.morganSource && morganSource.current)
      morganSource.current.value = u.morganSource;
    if (u.lac && lac.current) lac.current.value = u.lac;

    if (u.statePrices && Object.keys(u.statePrices).length) {
      document
        .querySelector(`#statePricesForm_${u._id}`)
        .querySelectorAll("input")
        .forEach((i) => {
          const s = i.name.split(".");

          if (i.name === "rejectionApproval")
            return (i.value = u.rejectionApproval);

          if (u.statePrices[s[0]]?.[s[1]]) i.value = u.statePrices[s[0]][s[1]];
          // console.log(i.name);
        });
    }
  }, [u, u.states, u.statePrices]);

  const calculateAverage = () => {
    let count = 0;
    let sum = 0;
    Object.keys(u.statePrices).forEach((v) => {
      if (u.statePrices[v]?.bidPrice) {
        sum = sum + Number(u.statePrices[v].bidPrice);
        count++;
      }
    });
    return (sum / count).toFixed(2);
  };

  return (
    <>
      <div className="exp two-columns">
        {fields.map((f) => {
          if (u[f])
            return (
              <p key={f}>
                <strong>{f}: </strong> {u[f]}
              </p>
            );
          return null;
        })}
        <p>
          <strong>Cases:</strong> {u.cases?.length}
        </p>
        <p>
          <strong>Can order reports:</strong> {u.canOrderReports ? "Yes" : "No"}
        </p>

        {u.account_type === "business" ? (
          <>
            <div style={{ clear: "both", paddingTop: 30 }}>
              Lead Acquisition Cost:
              <form onSubmit={(e) => props.saveLac(e)}>
                $
                <input
                  type="text"
                  style={{ width: 100 }}
                  ref={lac}
                  name="lac"
                />{" "}
                <button style={{ width: 100 }}>save</button>
              </form>
            </div>
            <div
              style={{
                clear: "both",
                paddingTop: 30,
                display: "flex",
                gap: 10,
              }}
            >
              <div>
                <Checkbox
                  checked={!u.retainer}
                  text="Pay By Lead"
                  onClick={() => props.setRetainer(false)}
                />
              </div>
              <div>
                <Checkbox
                  checked={u.retainer}
                  text="Retainer"
                  onClick={() => props.setRetainer(true)}
                />
              </div>
            </div>
            <div
              style={{
                marginTop: 20,
                display: "flex",
                gap: 10,
                justifyContent: "flex-start",
                border: u.morganConnect ? "#CCC 1px solid" : "",
                padding: u.morganConnect ? 10 : 0,
                borderRadius: 5,
              }}
            >
              <div style={{ width: 230 }}>
                <Checkbox
                  checked={u.morganConnect}
                  text="Morgan Connect"
                  onClick={() => props.toggleMorgan()}
                />
              </div>
              {u.morganConnect ? (
                <form
                  onSubmit={(e) => props.saveMorganSource(e)}
                  style={{ flex: 1 }}
                >
                  <div>
                    <input
                      type="text"
                      placeholder="Enter Morgan additional_marketing_source_information"
                      name="morganSource"
                      ref={morganSource}
                    />
                    <button>save</button>
                  </div>
                </form>
              ) : (
                <div />
              )}
            </div>
          </>
        ) : null}

        {u.account_type === "affiliate" ? (
          <div style={{ clear: "both", paddingTop: 30 }}>
            <a
              style={{ display: "flex", alignItems: "center" }}
              onClick={() => props.toggleRejectionTracking()}
            >
              {u.rejectionTracking ? (
                <MdCheckBox size={24} />
              ) : (
                <MdCheckBoxOutlineBlank size={24} />
              )}
              <strong style={{ marginLeft: 5 }}>Rejection tracking</strong>
            </a>
            {u._id !== "64272eccdac8620019569e9c" ? (
              <a
                style={{ display: "flex", alignItems: "center" }}
                onClick={() => props.toggleAdoptContractor()}
              >
                {u.adoptContractor ? (
                  <MdCheckBox size={24} />
                ) : (
                  <MdCheckBoxOutlineBlank size={24} />
                )}
                <strong style={{ marginLeft: 5 }}>
                  Adopt A Contractor Source
                </strong>
              </a>
            ) : null}
          </div>
        ) : null}

        <div style={{ clear: "both", paddingTop: 30 }}>
          <a
            style={{ display: "flex", alignItems: "center" }}
            onClick={() => props.toggleAutoSMS()}
          >
            {u.autoSMS ? (
              <MdCheckBox size={24} />
            ) : (
              <MdCheckBoxOutlineBlank size={24} />
            )}
            <strong style={{ marginLeft: 5 }}>Autosend SMS</strong>
          </a>
          <form onSubmit={(e) => props.saveAutoSMStext(e)}>
            <textarea name={"autoSMSText"} ref={SMStext} />
            <button style={{ width: 100, float: "right" }}>SAVE</button>
          </form>
        </div>
        <div style={{ clear: "both", paddingTop: 10 }}>
          <strong>Operates in states:</strong>
          <br />
          <br />
          <div className="filters states">
            <a
              className={u.states?.includes("ALL") ? "active" : ""}
              onClick={() => props.toggleState("ALL")}
              style={{ width: 100 }}
            >
              All states
            </a>
            {availableStates.map((s) => (
              <a
                className={u.states?.includes(s) ? "active" : ""}
                key={s}
                onClick={() => props.toggleState(s)}
              >
                {s}
              </a>
            ))}
          </div>
        </div>
        {u.states?.length ? (
          <form
            onSubmit={(e) => props.saveStatePrices(e)}
            id={"statePricesForm_" + u._id}
          >
            <div className="filters states statePrices">
              {u.states.map((s) => (
                <div key={s}>
                  {s} {u.account_type === "affiliate" ? "BID price" : "price"}:
                  <br />
                  $
                  <input
                    type={u.account_type === "affiliate" ? "hidden" : "text"}
                    name={s + ".price"}
                  />
                  <input
                    type={u.account_type === "affiliate" ? "text" : "hidden"}
                    name={s + ".bidPrice"}
                  />
                  {u.retainer ? (
                    <div>
                      {s} Retainer price:
                      <br />
                      $<input type="text" name={s + ".retainerPrice"} />
                    </div>
                  ) : null}
                </div>
              ))}
            </div>
            {u.account_type === "affiliate" ? (
              <div style={{ clear: "both", marginLeft: 10 }}>
                {u.statePrices ? (
                  <p>
                    <strong>Average price per lead:</strong>{" "}
                    {calculateAverage()}
                  </p>
                ) : null}
                <div style={{ clear: "both" }} />
                <p>
                  <strong>Rejection Approval %:</strong>
                  <input style={{ width: 50 }} name="rejectionApproval" />
                </p>
              </div>
            ) : null}
            <div style={{ clear: "both" }} />
            <button style={{ width: 100, marginLeft: 10 }}>SAVE</button>
          </form>
        ) : null}
        {u.account_type === "affiliate" ? (
          <SubIDWeightControl
            user={u}
            updateUserWeight={(user) => props.updateUserWeight(user)}
            updateUserWeightName={(user) => props.updateUserWeightName(user)}
            users={props.users}
          />
        ) : null}
      </div>
    </>
  );
}
