import React, { useEffect, useState } from "react";
import * as CanvasJSReact from "../../canvasjs.react";
import moment from "moment";
import { defaultGraphOptions } from "../LeadStats";

const CanvasJSChart = CanvasJSReact.CanvasJSChart;

export default function Ghosting({ leads, view, users }) {
  const [graphData, setGraphData] = useState({});

  const [affiliates, setAffiliates] = useState("");

  useEffect(() => {
    // console.log(leads);
    // console.log(users);
    // console.log(filterUsers);
    const gd = { ...defaultGraphOptions };
    let data = {};
    leads.forEach((v) => {
      let period;
      if (view === "daily")
        period = new moment(v.createdAt).format("YYYY-MM-DD");
      if (view === "monthly") period = v.createdAt.substr(0, 7);
      if (view === "weekly") {
        period =
          "Week " +
          moment(v.createdAt).isoWeek() +
          ", " +
          new Date(v.createdAt).getFullYear();
      }
      if (!data[period])
        data[period] = {
          ghosted: 0,
          answered: 0,
        };

      if (affiliates === "MA" && !v.affiliate) {
        if (
          v.lead_level === "hot" ||
          v.lead_level === "bad hot" ||
          v.lead_level === "sold" ||
          v.lead_level === "rejected" ||
          v.lead_level === "deleted"
        )
          data[period].answered++;

        if (v.lead_level === "doa") data[period].ghosted++;
      }

      if (affiliates === "NMA" && v.affiliate) {
        if (
          v.lead_level === "hot" ||
          v.lead_level === "bad hot" ||
          v.lead_level === "sold" ||
          v.lead_level === "rejected" ||
          v.lead_level === "deleted"
        )
          data[period].answered++;

        if (v.lead_level === "doa") data[period].ghosted++;
      }

      if (
        affiliates !== "NMA" &&
        affiliates !== "MA" &&
        v.affiliate === affiliates
      ) {
        if (
          v.lead_level === "hot" ||
          v.lead_level === "bad hot" ||
          v.lead_level === "sold" ||
          v.lead_level === "rejected" ||
          v.lead_level === "deleted"
        )
          data[period].answered++;

        if (v.lead_level === "doa") data[period].ghosted++;
      }
      if (!affiliates) {
        if (
          v.lead_level === "hot" ||
          v.lead_level === "bad hot" ||
          v.lead_level === "sold" ||
          v.lead_level === "rejected" ||
          v.lead_level === "deleted"
        )
          data[period].answered++;

        if (v.lead_level === "doa") data[period].ghosted++;
      }
    });

    gd.toolTip = {
      shared: true,
      // content: "{label}<hr />{name}: {y}",
    };

    gd.data = [
      {
        type: "stackedColumn",
        color: "#444",
        showInLegend: true,
        name: "Ghosted",
        dataPoints: Object.keys(data).map((vv) => {
          return {
            label: view === "daily" ? moment(vv).format("ll") : vv,
            y: data[vv].ghosted,
          };
        }),
      },
      {
        type: "stackedColumn",
        color: "orange",
        showInLegend: true,
        name: "Answered",
        dataPoints: Object.keys(data).map((vv) => {
          return {
            label: view === "daily" ? moment(vv).format("ll") : vv,
            y: data[vv].answered,
          };
        }),
      },
      {
        type: "line",
        color: "#555",
        showInLegend: true,
        name: "Ghosted %",
        dataPoints: Object.keys(data).map((vv) => {
          return {
            label: view === "daily" ? moment(vv).format("ll") : vv,
            y:
              (data[vv].ghosted / (data[vv].answered + data[vv].ghosted)) * 100,
          };
        }),
      },
    ];

    setGraphData(gd);
  }, [leads, affiliates, view]);

  return (
    <div>
      <h2>Ghosting</h2>
      <select
        value={affiliates}
        onChange={(e) => setAffiliates(e.target.value)}
        style={{ maxWidth: 300 }}
        data-testid="select-option"
      >
        <option value="">All Affiliates/Sources...</option>
        <option value="MA">MyAccident.org</option>
        <option value="NMA">All Affiliates</option>
        {users
          .filter((u) => u.account_type === "affiliate")
          .map((u) => (
            <option value={u._id} key={u._id}>
              {u.email}
            </option>
          ))}
      </select>

      <br />
      {process.env.JEST_WORKER_ID !== undefined ? null : (
        <CanvasJSChart options={graphData} />
      )}
    </div>
  );
}
