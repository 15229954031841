import React, { Component } from "react";
import clsx from "clsx";
import Axios from "axios";

import CircleShape from '../components/Shared/CircleShape';

export default class ForgotPage extends Component {
	state = {
		email: "",
		ajax: false,
		sent: false
	};
	sendLink(e) {
		e.preventDefault();
		this.setState({ ajax: true, error: "" });
		Axios.post("/api/businessAccount/passwordForgot", { email: this.state.email })
			.then(d => {
				console.log(d.data);
				this.setState({ ajax: false, sent: true });
			})
			.catch(e => {
				this.setState({ ajax: false, sent: false, error: e.response.data.error });
			});
	}
	render() {
		return (
			<div id="forgotPass" className={clsx("page show", { ajax: this.state.ajax })}>
				<div className="ForgotPass__content">
					<CircleShape id="ForgotPass__circle" className="ForgotPass__circle-shape"/>
					<div className="ForgotPass__form-wrapper">
						<h1>Forgot Password?</h1>
						{this.state.error && <div className="error">{this.state.error}</div>}
						{this.state.sent ? (
							<div>Password reset link was sent to your email.</div>
						) : (
							<form onSubmit={e => this.sendLink(e)}>
								<fieldset>
									<label>Email</label>
									<input
										type="email"
										placeholder=""
										required
										onChange={e => {
											this.setState({ email: e.target.value });
										}}
										value={this.state.email}
									/>
									<button disabled={!this.state.email} className="auth-btn forgot-pass-btn">Submit</button>
								</fieldset>
							</form>
						)}
					</div>
				</div>
			</div>
		);
	}
}
