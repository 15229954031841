/* eslint-disable react-hooks/exhaustive-deps */

import React from "react";
import { MdClose } from "react-icons/md";
import Expander from "./Expander";
import NewCaseModal from "./NewCaseModal";

class MarkerMap extends React.Component {
	// this.map;
	markers = [];
	icon = {
		url: "https://myaccident.org/images/location-pin-02.webp",
		scaledSize: new window.google.maps.Size(50, 50),
		origin: new window.google.maps.Point(0, 0),
		anchor: new window.google.maps.Point(25, 25)
	};
	state = {
		selected: undefined,
		addCaseModal: false
	};

	drawMarkers() {
		if (this.markers.length) {
			this.markers.forEach((m) => {
				m.setMap(null);
			});
		}

		this.markers = [];

		const { accidents } = this.props;
		if (accidents && accidents.length) {
			accidents.forEach((accident) => {
				// console.log(accident);
				if (accident.location_lat !== 0 || accident.location_lng !== 0) {
					this.markers.push(
						new window.google.maps.Marker({
							position: {
								lat: accident.location_lat,
								lng: accident.location_lng
							},
							map: this.map,
							icon: this.icon,
							metadata: accident
						})
					);
				}
			});
		}

		if (this.markers.length > 1) {
			let bounds = new window.google.maps.LatLngBounds();
			this.markers.forEach((m) => {
				bounds.extend(m.getPosition());
			});
			this.map.fitBounds(bounds);
		} else if (this.markers.length === 1) {
			this.map.setCenter({
				lat: accidents[0].location_lat,
				lng: accidents[0].location_lng
			});
			this.map.setZoom(15);
		}

		this.markers.forEach((m, k) => {
			if (m) {
				m.addListener("click", () => {
					console.log(m.metadata);
					this.setState({ selected: m.metadata });
				});
			}
		});
	}

	componentDidMount() {
		const initMap = () => {
			this.map = new window.google.maps.Map(
				document.getElementById("map-search-results"),
				{
					center: {
						lat: 31.9686,
						lng: -99.9018
					},
					zoom: 6,
					options: {
						streetViewControl: false,
						mapTypeControl: false,
						fullscreenControl: false,
						draggableCursor: "default"
					},
					gestureHandling: "greedy"
				}
			);
		};
		if (!this.map) initMap();
	}
	componentDidUpdate(prevProps, prevState) {
		if (
			prevProps.accidents &&
			prevProps.accidents.length !== this.props.accidents.length
		)
			this.drawMarkers();
	}

	render() {
		return (
			<div>
				<div className={this.state.selected ? "modal show" : "modal"}>
					<button
						className="close"
						onClick={() => this.setState({ selected: undefined })}
					>
						<MdClose />
					</button>
					<h2>Accident</h2>
					<Expander
						data={{
							accident: this.state.selected ? this.state.selected : {}
						}}
					/>
					<div className="footer">
						<button
							onClick={() => this.setState({ selected: undefined })}
							className="outline"
						>
							Cancel
						</button>
						<button onClick={() => this.setState({ addCaseModal: true })}>
							Add to dashboard
						</button>
					</div>
				</div>

				<NewCaseModal
					show={this.state.addCaseModal}
					close={() => this.setState({ addCaseModal: false })}
					accidentID={
						this.state.selected ? this.state.selected.custom_id : null
					}
					user={this.props.user}
				/>

				<div id="map-search-results" />
			</div>
		);
	}
}

export default MarkerMap;
