import moment from "moment";
import React, { useState } from "react";
import { MdClose } from "react-icons/md";
import Swal from "sweetalert2";

export default function HotnessStatus({ lead, assignedTo }) {
  const [show, setShow] = useState(false);

  const boxColorClass = (n) => {
    if (
      !lead.callStatus ||
      !lead.callStatus.attempts ||
      !lead.callStatus.attempts[n]
    )
      return "";
    if (!lead.callStatus.attempts[n].answered) return "blue";
    if (
      lead.callStatus.attempts[n].answered &&
      lead.callStatus.qualified === "not_qualified"
    )
      return "black";
    if (
      lead.callStatus.attempts[n].answered &&
      lead.callStatus.qualified === "qualified"
    )
      return "green";
    return "";
  };
  if (
    lead.assigned_to.includes("63ff9a5e5fda3100197bbd70") ||
    lead.assigned_to.includes("6397b827c1b12a0019162957") ||
    lead.assigned_to.includes("63ff9a5e5fda3100197bbd70") ||
    (lead.callStatus && lead.callStatus.attempts && lead.callStatus.attempts.length)
  )
    return (
      <div className="lead-status-holder">
        <div
          className={"lead-status " + lead.lead_level}
          onClick={() => setShow(true)}
          tabIndex={0}
          onBlur={() => setShow(false)}
        >
          <span className={boxColorClass(0)} />
          <span className={boxColorClass(1)} />
          <span className={boxColorClass(2)} />
          <span className={boxColorClass(3)} />
          <span className={boxColorClass(4)} />
        </div>
        <div className={show ? "tooltip show" : "tooltip"}>
          <button className="close" onClick={() => setShow(false)}>
            <MdClose />
          </button>
          <strong>
            Lead: {lead.firstName} {lead.lastName} ({lead.phone})
          </strong>
          <br />
          <strong>Assigned to:</strong> {assignedTo}
          <br />
          {lead.assigned_date && lead.assigned_date.length ? (
            <>
              Assigned {moment(lead.assigned_date[0].date).format("lll")} <br />
            </>
          ) : null}
          <br />
          {lead.callStatus?.attempts?.map((v, k) => (
            <span key={k}>
              {k + 1}. Call attempt - {moment(v.date).format("lll")} -{" "}
              {v.answered
                ? "ANSWERED " +
                  (lead.callStatus.qualified === "qualified"
                    ? " -  QUALIFIED"
                    : "NOT QUALIFIED")
                : "NO ANSWER"}
              <br />
            </span>
          ))}
          {lead.callStatus?.notes ? (
            <>
              <br />
              NOTES: {lead.callStatus.notes}
            </>
          ) : null}
          {lead.rejectNotes ? (
            <>
              <br />
              REJECT NOTES: {lead.rejectNotes}
            </>
          ) : null}
        </div>
      </div>
    );

  return (
    <span
      className={"hotness " + lead.lead_level}
      style={{ cursor: lead.rejectNotes ? "pointer" : "" }}
      onClick={() => {
        if (lead.rejectNotes)
          Swal.fire({
            title: "Rejected Note",
            html: `<div style="white-space:pre">${lead.rejectNotes}</div>`,
            type: "info",
          });
      }}
    >
      {lead.lead_level ? lead.lead_level : "-"}
    </span>
  );
}
