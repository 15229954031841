/* eslint-disable jsx-a11y/anchor-is-valid */
import Axios from "axios";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { MdClose, MdEdit } from "react-icons/md";
import { loadAffiliateStats, renderResult } from "./AffiliateStats";
import Swal from "sweetalert2";

export default function SubIDWeightControl({
  user,
  updateUserWeight,
  users,
  updateUserWeightName,
}) {
  const [show, setShow] = useState(false);
  const [saving, setSaving] = useState(true);
  const [data, setData] = useState({});

  // useEffect(() => {
  //   console.log(user);
  // }, [user]);

  useEffect(() => {
    document
      .getElementById(`weightForm${user._id}`)
      .querySelectorAll("input")
      .forEach((i) => {
        if (user.subIDWeight && user.subIDWeight[i.name])
          i.value = user.subIDWeight[i.name];
        else i.value = "";
      });

    /* istanbul ignore next */
    if (show && users && users.length) {
      setSaving(true);
      const usersObj = {};
      users.forEach((u) => {
        usersObj[u._id] = u;
      });

      loadAffiliateStats(false, usersObj, []).then((d) => {
        // console.log(d);
        setSaving(false);
        setData(d.result);
      });
    }
  }, [show, user, users]);

  const save = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const data = Object.fromEntries(formData);
    Object.keys(data).forEach((k) => (data[k] = Number(data[k])));
    delete data.xxTrustedFormToken;
    delete data.xxTrustedFormCertUrl;
    delete data.xxTrustedFormPingUrl;
    // console.log(data);

    // setSaving(true);
    Axios.post("api/businessAccount/saveStates/" + user._id, {
      subIDWeight: data,
    }).then((d) => {
      updateUserWeight(d.data.user);
      setSaving(false);
      setShow(false);
    });
  };

  const editName = async (k) => {
    const { value } = await Swal.fire({
      title: "Enter name for:",
      text: k,
      input: "text",
      inputValue: user.subIDNames?.[k] ? user.subIDNames[k] : "",
      type: "question",
      showCancelButton: true,
      reverseButtons: true,
      inputValidator: (value) => {
        /* istanbul ignore next */
        if (!value) return "Name is mandatory";
      },
    });
    if (value) {
      if (!user.subIDNames) user.subIDNames = {};
      user.subIDNames[k] = value;

      Axios.post("api/businessAccount/saveStates/" + user._id, {
        subIDNames: user.subIDNames,
      }).then((d) => {
        updateUserWeightName(user);
      });
    }
  };

  return (
    <div>
      <button
        style={{ margin: 10, width: "auto" }}
        onClick={() => setShow(true)}
        data-testid="open-button"
      >
        SubID Weight Control
      </button>
      <div className={clsx(["modal search", { show, ajax: saving }])}>
        <button className="close" onClick={() => setShow(false)}>
          <MdClose />
        </button>
        <h2>SubId Weight Control</h2>
        <form onSubmit={(e) => save(e)} id={`weightForm${user._id}`}>
          <table>
            <thead>
              <tr>
                <th>Affiliate User</th>
                <th>Weight</th>
                <th style={{ textAlign: "center" }}>1 day</th>
                <th style={{ textAlign: "center" }}>7 day</th>
                <th style={{ textAlign: "center" }}>14 day</th>
                <th style={{ textAlign: "center" }}>30 day</th>
                <th style={{ textAlign: "center" }}>60 day</th>
                <th style={{ textAlign: "center" }}>90 day</th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(user.subIDWeight).map((k) => (
                <tr
                  key={k}
                  style={{
                    alignItems: "center",
                    border: "none",
                  }}
                >
                  <td style={{ flex: 1, textAlign: "right" }}>
                    {k !== "unknown" && k !== "new" ? (
                      <div>
                        {user.subIDNames && user.subIDNames[k]
                          ? user.subIDNames[k]
                          : k}
                        <a onClick={() => editName(k)} data-testid="edit-name">
                          <MdEdit />
                        </a>
                      </div>
                    ) : null}
                    <div style={{ opacity: 0.5 }}>{k}</div>
                  </td>
                  <td style={{ flex: 1, padding: 5 }}>
                    <input
                      type="number"
                      style={{ margin: 0, width: 100 }}
                      name={k}
                    />{" "}
                    %
                  </td>
                  {data[user._id]?.bySubID?.[k] ? (
                    <>{renderResult(data[user._id].bySubID[k])}</>
                  ) : null}
                </tr>
              ))}
            </tbody>
          </table>
          <div className="footer">
            <button
              onClick={() => setShow(false)}
              className="outline"
              style={{ width: "auto", padding: "0.6em 0.9em" }}
              type="button"
            >
              Cancel
            </button>
            <button
              style={{ width: "auto", padding: "0.6em 0.9em" }}
              data-testid="save-button"
            >
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
