import React, { Component } from "react";
import Axios from "axios";
// import * as CanvasJSReact from "../canvasjs.react";
import Checkbox from "./Shared/Checkbox";
import moment from "moment";
import LeadsByLawFirm from "./StatGraphs/LeadsByLawFirm";
import FunnelSuccess from "./StatGraphs/FunnelSuccess";
import Ghosting from "./StatGraphs/Ghosting";
import OpsBreakdown from "./StatGraphs/OpsBreakdown";
// import RetailVsPingPost from "./StatGraphs/RetailVsPingPost";
import Referer2 from "./StatGraphs/Referer2";
import GooglePaid from "./StatGraphs/GooglePaid";

// const CanvasJS = CanvasJSReact.CanvasJS;
// const CanvasJSChart = CanvasJSReact.CanvasJSChart;

// CanvasJS.addColorSet("customColorSet", ["#2196f3"]);
export const defaultGraphOptions = {
  animationEnabled: true,
  colorSet: "customColorSet",
  title: {
    fontFamily: "Montserrat",
    fontSize: 18,
    fontWeight: "bold",
    fontColor: "#666666",
    horizontalAlign: "left",
    margin: 10,
    text: "",
  },
  axisX: {
    labelAngle: -70,
    gridDashType: "line",
    gridThickness: 2,
    gridColor: "#EEEEEE",
    lineColor: "#DDDDDD",
    labelFontColor: "#999999",
    tickColor: "#EEEEEE",
    tickThickness: 2,
  },
  axisY: {
    gridDashType: "line",
    gridThickness: 2,
    gridColor: "#EEEEEE",
    lineColor: "#FFFFFF",
    tickColor: "#FFFFFF",
    labelFontColor: "#999999",
  },
};

export default class LeadStats extends Component {
  state = {
    loading: true,
    graphData: {},
    graphDataValue: {},
    view: "daily",
    from: moment()
      .subtract("days", window.isMobileDevice() ? 7 : 30)
      .startOf("day"),
    to: moment(),
    leadType: "",
    leads: [],
  };
  componentDidMount() {
    this.loadLeads("");
  }
  loadLeads(leadType) {
    // this.setState({ loading: true });
    let data = {};
    let url = `/api/leads/stats?from=${this.state.from}&to=${this.state.to}`;
    if (leadType) url = url + "&leadType=" + leadType;
    Axios.get(url).then((d) => {
      this.setState({ leads: d.data.leads });
      d.data.leads.forEach((v) => {
        // console.log(new moment(v.createdAt).format("YYYY-MM-DD"));
        let period;
        if (this.state.view === "daily")
          period = new moment(v.createdAt).format("YYYY-MM-DD");
        if (this.state.view === "monthly") period = v.createdAt.substr(0, 7);
        if (this.state.view === "weekly") {
          period =
            "Week " +
            moment(v.createdAt).isoWeek() +
            ", " +
            moment(v.createdAt).format("YYYY");

          if (
            period === "Week 52, 2023" &&
            (v.createdAt.startsWith("2023-01-01") ||
              v.createdAt.startsWith("2022-12-31"))
          )
            period = "Week 52, 2022";

          if (v.createdAt.startsWith("2019-12-3")) period = "Week 52, 2019";
        }
        if (!data[period])
          data[period] = {
            hot: 0,
            sold: 0,
            cold: 0,
            doa: 0,
            rejected: 0,
            unverified: 0,
            money: 0,
            deleted: 0,
            // CALawyer: 0,
            // DUILawyer: 0,
            // AutoBody: 0,
            // NSInsurance: 0,
            // "bad hot": 0,
            value: 0,
            value2: 0,
          };
        if (v.lead_level === "hot") {
          data[period].hot++;
          data[period].money = data[period].money + 300;
        }
        if (v.lead_level === "sold") {
          data[period].sold++;
          data[period].money = data[period].money + 100;
        }
        if (v.lead_level === "cold") {
          data[period].cold++;
          data[period].money = data[period].money + 4.5;
        }
        if (v.lead_level === "doa") data[period].doa++;
        if (v.lead_level === "bad hot") data[period]["bad hot"]++;
        if (v.lead_level === "rejected") data[period].rejected++;
        if (v.lead_level === "deleted") data[period].deleted++;
        if (v.lead_level === "unverified") data[period].unverified++;
        if (v.value) {
          // if (v.lead_type === "CALawyer")
          //   data[period].CALawyer = data[period].CALawyer + v.value;
          // if (v.lead_type === "DUILawyer")
          //   data[period].DUILawyer = data[period].DUILawyer + v.value;
          // if (v.lead_type === "AutoBody")
          //   data[period].AutoBody = data[period].AutoBody + v.value;
          // if (v.lead_type === "NSInsurance")
          //   data[period].NSInsurance = data[period].NSInsurance + v.value;
          data[period].value = data[period].value + v.value;
        }
        if (v.value2) {
          data[period].value2 = data[period].value2 + v.value2;
        }
      });
      const graphData = { ...defaultGraphOptions };
      const graphDataValue = { ...defaultGraphOptions };
      graphData.data = [
        {
          type: "stackedColumn",
          color: "#CCC",
          showInLegend: true,
          name: "unverified",
          dataPoints: Object.keys(data).map((vv) => {
            return {
              label: this.state.view === "daily" ? moment(vv).format("ll") : vv,
              y: data[vv].unverified,
            };
          }),
        },
        {
          type: "stackedColumn",
          color: "#555",
          showInLegend: true,
          name: "rejected",
          dataPoints: Object.keys(data).map((vv) => {
            return {
              label: this.state.view === "daily" ? moment(vv).format("ll") : vv,
              y: data[vv].rejected,
            };
          }),
        },
        {
          type: "stackedColumn",
          color: "#000000",
          showInLegend: true,
          name: "bad hot",
          dataPoints: Object.keys(data).map((vv) => {
            return {
              label: this.state.view === "daily" ? moment(vv).format("ll") : vv,
              y: data[vv]["bad hot"],
            };
          }),
        },
        {
          type: "stackedColumn",
          color: "#333333",
          showInLegend: true,
          name: "deleted",
          dataPoints: Object.keys(data).map((vv) => {
            return {
              label: this.state.view === "daily" ? moment(vv).format("ll") : vv,
              y: data[vv]["deleted"],
            };
          }),
        },
        {
          type: "stackedColumn",
          color: "#ff9800",
          showInLegend: true,
          name: "hot",
          dataPoints: Object.keys(data).map((vv) => {
            return {
              label: this.state.view === "daily" ? moment(vv).format("ll") : vv,
              y: data[vv].hot,
            };
          }),
        },
        {
          type: "stackedColumn",
          color: "#F44434",
          showInLegend: true,
          name: "sold",
          dataPoints: Object.keys(data).map((vv) => {
            return {
              label: this.state.view === "daily" ? moment(vv).format("ll") : vv,
              y: data[vv].sold,
            };
          }),
        },
        {
          type: "stackedColumn",
          color: "#9DD8EF",
          showInLegend: true,
          name: "cold",
          dataPoints: Object.keys(data).map((vv) => {
            return {
              label: this.state.view === "daily" ? moment(vv).format("ll") : vv,
              y: data[vv].cold,
            };
          }),
        },
        {
          type: "stackedColumn",
          color: "#555",
          showInLegend: true,
          name: "doa",
          dataPoints: Object.keys(data).map((vv) => {
            return {
              label: this.state.view === "daily" ? moment(vv).format("ll") : vv,
              y: data[vv].doa,
              indexLabel: "$" + data[vv].money,
            };
          }),
        },
      ];
      graphDataValue.data = [
        {
          // type: "stackedColumn",
          color: "#F44434",
          showInLegend: true,
          name: "Pending Value",
          dataPoints: Object.keys(data).map((vv) => {
            return {
              label: this.state.view === "daily" ? moment(vv).format("ll") : vv,
              y: data[vv].value,
              indexLabel: "$" + Number(data[vv].value).toFixed(2),
            };
          }),
        },
        {
          // type: "stackedColumn",
          color: "#4caf50",
          showInLegend: true,
          name: "Settled Value",
          dataPoints: Object.keys(data).map((vv) => {
            return {
              label: this.state.view === "daily" ? moment(vv).format("ll") : vv,
              y: data[vv].value2,
              indexLabel: "$" + Number(data[vv].value2).toFixed(2),
            };
          }),
        },
        // {
        //   type: "stackedColumn",
        //   color: "#9DD8EF",
        //   showInLegend: true,
        //   name: "AutoBody",
        //   dataPoints: Object.keys(data).map((vv) => {
        //     return {
        //       label: this.state.view === "daily" ? moment(vv).format("ll") : vv,
        //       y: data[vv].AutoBody,
        //     };
        //   }),
        // },
        // {
        //   type: "stackedColumn",
        //   color: "#EBEFF0",
        //   showInLegend: true,
        //   name: "NSInsurance",
        //   dataPoints: Object.keys(data).map((vv) => {
        //     return {
        //       label: this.state.view === "daily" ? moment(vv).format("ll") : vv,
        //       y: data[vv].NSInsurance,
        //       indexLabel:
        //         "$" +
        //         Number(
        //           data[vv].CALawyer +
        //             data[vv].DUILawyer +
        //             data[vv].AutoBody +
        //             data[vv].NSInsurance
        //         ).toFixed(2),
        //     };
        //   }),
        // },
      ];
      this.setState({
        loading: false,
        graphData,
        graphDataValue,
      });

      // console.log(data);
    });
  }
  changeView(view) {
    let from;
    if (view === "monthly")
      from = moment()
        .subtract("months", window.isMobileDevice() ? 5 : 12)
        .startOf("day");
    if (view === "daily")
      from = moment()
        .subtract("days", window.isMobileDevice() ? 7 : 30)
        .startOf("day");
    if (view === "weekly")
      from = moment()
        .subtract("weeks", window.isMobileDevice() ? 5 : 12)
        .startOf("day");
    this.setState({ view, from, loading: true }, () => {
      this.loadLeads();
    });
  }

  changeFilter(leadType) {
    this.setState({ leadType, loading: true });
    this.loadLeads(leadType);
  }
  render() {
    if (this.state.loading)
      return (
        <div>
          <img
            src="/img/ajax-loader.gif"
            alt="loading stats..."
            className="loader-gif"
          />
          &nbsp;Loading stats...
        </div>
      );
    return (
      <div>
        <div className="row">
          <div className="column">
            <Checkbox
              text="Daily"
              checked={this.state.view === "daily"}
              onClick={() => this.changeView("daily")}
            />
          </div>
          <div className="column">
            <Checkbox
              text="Weekly"
              checked={this.state.view === "weekly"}
              onClick={() => this.changeView("weekly")}
            />
          </div>
          <div className="column">
            <Checkbox
              text="Monthly"
              checked={this.state.view === "monthly"}
              onClick={() => this.changeView("monthly")}
            />
          </div>
          <div className="column">
            <select
              value={this.state.leadType}
              onChange={(e) => this.changeFilter(e.target.value)}
            >
              <option value="">All types...</option>
              <option>CALawyer</option>
              <option>DUILawyer</option>
              <option>AutoBody</option>
              <option>AutoRepair</option>
              <option>NSInsurance</option>
              <option>Chiropractor</option>
              <option>CAMPLJ</option>
            </select>
          </div>
        </div>
        {/* <br />
        <br />
        <CanvasJSChart options={this.state.graphData} />
        <br />
        <br />
        <h2>Lead value stats</h2>
        <CanvasJSChart options={this.state.graphDataValue} /> */}
        <br />
        <br />
        <OpsBreakdown
          leads={this.state.leads}
          view={this.state.view}
          from={this.state.from}
          to={this.state.to}
        />
        <br />
        <br />
        {/* <RetailVsPingPost leads={this.state.leads} view={this.state.view} />
        <br />
        <br /> */}
        <LeadsByLawFirm
          leads={this.state.leads}
          view={this.state.view}
          users={this.props.users}
        />
        <br />
        <br />
        <FunnelSuccess
          leads={this.state.leads}
          view={this.state.view}
          users={this.props.users}
        />
        <br />
        <br />
        <Ghosting
          leads={this.state.leads}
          view={this.state.view}
          users={this.props.users}
        />
        <br />
        <br />
        <Referer2
          leads={this.state.leads}
          view={this.state.view}
          users={this.props.users}
        />
        <br />
        <br />
        <GooglePaid
          leads={this.state.leads}
          view={this.state.view}
          users={this.props.users}
        />
      </div>
    );
  }
}
