import React, { Component } from "react";
import { Formik, Field, ErrorMessage, Form } from "formik";
import clsx from "clsx";
import Axios from "axios";
import { Link } from "react-router-dom";
export default class ResetPage extends Component {
	state = {
		ajax: false,
		done: true,
		error: ""
	};
	changePassword(values) {
		this.setState({ ajax: true, error: "" });
		Axios.post("/api/businessAccount/passwordReset/" + this.props.match.params.token, { password: values.password })
			.then(d => {
				this.setState({ ajax: false, done: true });
			})
			.catch(e => {
				this.setState({ ajax: false, error: e.response.data.error });
			});
	}
	render() {
		return (
			<div className={clsx("page show", { ajax: this.state.ajax })}>
				<h1>Reset Password</h1>
				{this.state.error && <div className="error">{this.state.error}</div>}
				{this.state.done ? (
					<div>
						Password reset was successful. You can <Link to="/signin">Sign In</Link> now with new password.
					</div>
				) : (
					<Formik
						initialValues={{
							password: "",
							password2: ""
						}}
						validate={values => {
							let errors = {};
							if (!values.password) errors.password = "Password is required.";
							if (!values.password2) errors.password2 = "You need to repeat password.";
							else if (values.password !== values.password2) errors.password2 = "Passwords don't match";
							return errors;
						}}
						onSubmit={(values, { setSubmitting }) => {
							this.changePassword(values);
						}}
					>
						{({
							values,
							errors,
							touched,
							handleChange,
							handleBlur,
							handleSubmit,
							isSubmitting
							/* and other goodies */
						}) => (
							<Form>
								<fieldset>
									<label>Password</label>
									<ErrorMessage name="password" render={msg => <div className="error">{msg}</div>} />
									<Field type="password" name="password" />

									<label>Repeat Password</label>
									<ErrorMessage name="password2" render={msg => <div className="error">{msg}</div>} />
									<Field type="password" name="password2" />
								</fieldset>
								<button type="submit" disabled={!(Object.keys(touched).length && !Object.keys(errors).length)}>
									Change Password
								</button>
							</Form>
						)}
					</Formik>
				)}
			</div>
		);
	}
}
