/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-no-target-blank */
import React, { Component, Fragment } from "react";
import Axios from "axios";
import swal from "sweetalert2";
import clsx from "clsx";
import {
  FaRegSquare,
  FaCheckSquare,
  FaStickyNote,
  FaInfoCircle,
  FaSortAmountDown,
  FaSortAmountUp,
} from "react-icons/fa";
import { FiPlusCircle, FiMinusCircle } from "react-icons/fi";
import { Link } from "react-router-dom";
import Expander from "../components/Expander";
import { MdClose } from "react-icons/md";
import moment from "moment";
import SendReportsToUser from "../components/SendReportsToUser";

export default class DashboardPage extends Component {
  state = {
    cases: [],
    loading: false,
    note: "",
    editNoteId: null,
    selected: {},
    // goldOrPlatinumUser:
    // 	this.props.user.plan.plan_name &&
    // 	(this.props.user.plan.plan_name.toLowerCase().indexOf("gold") ||
    // 		this.props.user.plan.plan_name.toLowerCase().indexOf("platinum")),
    filter: "",
    sortBy: "createdAt",
    reverse: true,
    matches: [],
    selectedMatch: undefined,
    matchedAlert: {},
  };

  toggleSelect(v) {
    if (this.state.selected[v._id]) delete this.state.selected[v._id];
    // eslint-disable-next-line react/no-direct-mutation-state
    else this.state.selected[v._id] = v;

    this.setState({ selected: this.state.selected });
  }

  sorter(arr, p, reverse) {
    if (reverse) {
      return arr.slice(0).sort(function (a, b) {
        return a[p] < b[p] ? 1 : a[p] > b[p] ? -1 : 0;
      });
    }
    return arr.slice(0).sort(function (a, b) {
      return a[p] > b[p] ? 1 : a[p] < b[p] ? -1 : 0;
    });
  }

  changeSort(sortBy) {
    let reverse = false;
    if (this.state.sortBy === sortBy) reverse = !this.state.reverse;
    this.setState({
      sortBy,
      reverse,
      cases: this.sorter(this.state.cases, sortBy, reverse),
    });
  }

  componentDidMount() {
    if (this.props.match && this.props.match.params.id)
      this.loadDashboardUser(this.props.match.params.id);
    else this.loadDashboard();
    // console.log(this.props.user);
    window.addEventListener("case-added", () => this.loadDashboard());
  }

  componentWillUnmount() {
    window.removeEventListener("case-added", () => this.loadDashboard());
  }

  loadDashboard = () => {
    if (!this.state.loading) {
      this.setState({ loading: true });
      Axios.get("/api/businessAccount/cases").then((d) => {
        d.data.cases.forEach((v) => {
          v.vehicle1 =
            v.accident && v.accident.vehicles && v.accident.vehicles[0]
              ? v.accident.vehicles[0].vehicle_maker
              : "";
          v.vehicle2 =
            v.accident && v.accident.vehicles && v.accident.vehicles[1]
              ? v.accident.vehicles[1].vehicle_maker
              : "";
          v.accidentIdDate = v.accident
            ? new Date(v.accident.date)
            : new Date(v.startDate);
          v.userName = v.user.accountOwnerName ? "Admin" : v.user.firstName;
          v.lastName = v.lastName ? v.lastName : "";
          v.business_case_id = v.business_case_id ? v.business_case_id : "";
          v.moreVehicles =
            (v.accident &&
              v.accident.vehicles &&
              v.accident.vehicles.length > 2) ||
            v.moreVehicles
              ? "Y"
              : "N";
          if (v.vehicle1Maker) v.vehicle1 = v.vehicle1Maker;
          if (v.vehicle2Maker) v.vehicle2 = v.vehicle2Maker;
        });
        this.setState({
          cases: this.sorter(
            d.data.cases,
            this.state.sortBy,
            this.state.reverse
          ),
          loading: false,
        });
      });
    }
  };
  loadDashboardUser(id) {
    if (!this.state.loading) {
      this.setState({ loading: true });
      Axios.get("/api/businessAccount/users/one?id=" + id).then((d) => {
        // console.log(d.data);
        d.data.user.cases.forEach((v) => {
          v.vehicle1 =
            v.accident && v.accident.vehicles && v.accident.vehicles[0]
              ? v.accident.vehicles[0].vehicle_maker
              : "";
          v.vehicle2 =
            v.accident && v.accident.vehicles && v.accident.vehicles[1]
              ? v.accident.vehicles[1].vehicle_maker
              : "";
          v.accidentIdDate = v.accident
            ? new Date(v.accident.date)
            : new Date(v.startDate);
          v.userName = v.user.accountOwnerName ? "Admin" : v.user.firstName;
          v.lastName = v.lastName ? v.lastName : "";
          v.business_case_id = v.business_case_id ? v.business_case_id : "";
          v.moreVehicles =
            (v.accident &&
              v.accident.vehicles &&
              v.accident.vehicles.length > 2) ||
            v.moreVehicles
              ? "Y"
              : "N";
          if (v.vehicle1Maker) v.vehicle1 = v.vehicle1Maker;
          if (v.vehicle2Maker) v.vehicle2 = v.vehicle2Maker;
        });
        this.setState({
          cases: this.sorter(
            d.data.user.cases,
            this.state.sortBy,
            this.state.reverse
          ),
          loading: false,
        });
      });
    }
  }
  askDelete() {
    swal
      .fire({
        type: "question",
        title: "Are you sure you want to remove selected accidents?",
        text: "This action cannot be undone.",
        showCancelButton: true,
        reverseButtons: true,
      })
      .then((v) => {
        if (v.value) {
          Object.keys(this.state.selected).forEach((k) => {
            // console.log(this.state.selected[k]);
            let id = this.state.selected[k]._id;
            Axios.delete("/api/businessAccount/cases/delete?id=" + id)
              .then(() => {
                let key;
                this.state.cases.some((vv, kk) => {
                  if (vv._id === id) {
                    key = kk;
                    return true;
                  }
                  return false;
                });
                if (key !== undefined) {
                  this.state.cases.splice(key, 1);
                  delete this.state.selected[id];
                  this.setState({
                    cases: this.state.cases,
                    selected: this.state.selected,
                  });
                }
              })
              .catch((e) => {
                swal.fire("Error", e.response.data.error, "error");
              });
          });
        }
      });
  }
  saveNotes() {
    Axios.put("/api/businessAccount/cases/notes", {
      id: this.state.editNoteId,
      notes: this.state.note,
    });
    let cases = [];
    this.state.cases.forEach((v) => {
      if (v._id === this.state.editNoteId) v.notes = this.state.note;
      cases.push(v);
    });
    this.setState({ editNoteId: null, cases });
  }
  orderReport(v) {
    if (!this.props.user.payment) {
      swal.fire(
        "Whoops",
        "You don't have any payments methods configured. You cannot make order.",
        "warning"
      );
      return false;
    }
    swal
      .fire({
        type: "question",
        title: "Order a report?",
        html:
          "Confirm Purchase: $18.95 will be billed to your account. <br /><br /><small>Texas Transportation Code (TTC) §550.062 requires a law enforcement officer who investigates a crash and completes a Texas Peace Officers Report (CR3) to submit the report to the Texas Department of Transportation (TxDOT) not later than the 10th day after the date of the crash. (TTC) §550.065 (c)(4) limits the release of a crash report to any person directly concerned in the accident or having a proper interest therein. In order to ensure compliance with TTC §550.065 (c)(4), TxDOT requires that a requestor of a crash report certify how they meet the requirements of §550.065 (c)(4) before they may obtain a non-redacted crash report.</small>",
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonText: "accept",
      })
      .then((confirm) => {
        if (confirm.value) {
          Axios.post("/api/businessAccount/cases/request", {
            caseId: v._id,
            accident_id: v.accident.custom_id,
          })
            .then((d) => {
              // console.log(d.data);
              // console.log(v);
              this.state.cases.some((vv) => {
                if (v._id === vv._id) {
                  vv.status = "Pending";
                  return true;
                }
                return false;
              });
              this.setState({ cases: this.state.cases });
            })
            .catch((e) => {
              console.log(e);
              swal.fire(
                "There was a problem making this order.",
                "Please contact support.",
                "error"
              );
            });
        }
      });
  }
  businessReportLink(v) {
    // let url;
    let id;
    // if (window.location.hostname === "business-dev.myaccident.org")
    //   url = "https://app-dev.myaccident.org/business/";
    // else if (window.location.hostname === "business.myaccident.org")
    //   url = "https://app.myaccident.org/business/";
    // else url = "http://localhost:1234/business/";

    if (v.caseId === "SAMPLE-CASE") id = v.accident.custom_id;
    else id = v._id;
    return (
      Axios.defaults.baseURL +
      "business/" +
      id +
      "/" +
      (this.props.match && this.props.match.params.id
        ? this.props.match.params.id
        : this.props.user._id)
    );
  }

  renderCR3column(v) {
    if (!v.accident) {
      return (
        <td colSpan="2" className="center">
          {v.matches && v.matches.length ? (
            <button
              className="matches"
              onClick={() =>
                this.setState({ matches: v.matches, matchedAlert: v })
              }
            >
              Matches: {v.matches.length}
            </button>
          ) : (
            <span className="pending">Alert Pending</span>
          )}
        </td>
      );
    } else {
      if (v.status === "Pending")
        return (
          <Fragment>
            <td className="center">
              <Link to={"/owners/" + v.accident.custom_id}>View</Link>
            </td>
            <td className="center">
              <span
                data-title-right="CR3 report will be uploaded within 1 hour"
                className="pending"
              >
                Pending
              </span>
            </td>
          </Fragment>
        );
      else if (v.status === "Complete")
        return (
          <Fragment>
            <td className="center">
              <Link to={"/owners/" + v.accident.custom_id}>View</Link>
            </td>
            <td className="center">
              <a href={v.report_url} target="_blank" rel="noopener noreferrer">
                View
              </a>
            </td>
          </Fragment>
        );
      else {
        if (
          this.props.user.canOrderReports ||
          this.props.user.role.id === 10 ||
          this.props.user.role.id === 11
        )
          return (
            <td colSpan="2" className="center">
              <a
                href="#"
                onClick={() => this.orderReport(v)}
                className="button"
              >
                Order
              </a>
            </td>
          );
        else return <td colSpan="2" />;
      }
    }
  }

  filterFunc(v) {
    if (this.state.filter === "") return true;
    if (
      v.user.firstName &&
      v.user.firstName.toLowerCase().indexOf(this.state.filter.toLowerCase()) >
        -1
    )
      return true;
    if (
      v.business_case_id &&
      v.business_case_id
        .toLowerCase()
        .indexOf(this.state.filter.toLowerCase()) > -1
    )
      return true;
    if (
      v.lastName &&
      v.lastName.toLowerCase().indexOf(this.state.filter.toLowerCase()) > -1
    )
      return true;
    if (
      v.accident &&
      v.accident.vehicles &&
      v.accident.vehicles[0] &&
      v.accident.vehicles[0].vehicle_maker &&
      v.accident.vehicles[0].vehicle_maker
        .toLowerCase()
        .indexOf(this.state.filter.toLowerCase()) > -1
    )
      return true;
    if (
      v.accident &&
      v.accident.vehicles &&
      v.accident.vehicles[1] &&
      v.accident.vehicles[1].vehicle_maker &&
      v.accident.vehicles[1].vehicle_maker
        .toLowerCase()
        .indexOf(this.state.filter.toLowerCase()) > -1
    )
      return true;
    return false;
  }

  sortIcons(w) {
    if (w === this.state.sortBy && this.state.reverse)
      return <FaSortAmountUp size="16" />;

    if (w === this.state.sortBy && !this.state.reverse)
      return <FaSortAmountDown size="16" />;
  }

  toggleExpand(v) {
    if (!v.expanded) v.expanded = true;
    else v.expanded = false;
    this.setState({ cases: this.state.cases });
  }

  confirmMatch() {
    // console.log(this.state.matches[this.state.selectedMatch]);
    // console.log(this.state.matchedAlert);
    Axios.post("/api/businessAccount/cases/create", {
      firstName: this.state.matchedAlert.firstName,
      lastName: this.state.matchedAlert.lastName,
      business_case_id: this.state.matchedAlert.business_case_id,
      accidentId: this.state.matches[this.state.selectedMatch].custom_id,
      requestPR: false,
    })
      .then((d) => {
        console.log(d);
        Axios.delete(
          "/api/businessAccount/cases/delete?id=" + this.state.matchedAlert._id
        ).then((d) => {
          this.loadDashboard();
          swal.fire(
            "Done!",
            "Alert is converted to case. Reloading the dashboard now.",
            "success"
          );
        });

        this.setState({
          matches: [],
          selectedMatch: undefined,
        });
      })
      .catch((e) => {
        swal.fire(
          "Error!",
          "Could not convert alert to case. Network error happened.",
          "error"
        );
      });
  }

  render() {
    // console.log(this.state);

    if (this.state.loading)
      return (
        <div>
          <img
            src="/img/ajax-loader.gif"
            alt="Loading..."
            className="loader-gif"
          />
          &nbsp;Loading...
        </div>
      );

    if (!this.state.cases || !this.state.cases.length)
      return <div>No cases added.</div>;
    return (
      <div>
        <div
          className={clsx("modal", { show: this.state.editNoteId !== null })}
        >
          <h2>Edit notes</h2>
          <textarea
            value={this.state.note}
            onChange={(e) => this.setState({ note: e.target.value })}
          />
          <div className="footer">
            <button
              onClick={() => this.setState({ editNoteId: null })}
              className="outline"
            >
              Cancel
            </button>
            <button onClick={() => this.saveNotes()}>Save</button>
          </div>
        </div>
        <div className={clsx("modal", { show: this.state.matches.length })}>
          <button
            className="close"
            onClick={() =>
              this.setState({ matches: [], selectedMatch: undefined })
            }
          >
            <MdClose />
          </button>
          <h2>Possible alert matches</h2>
          {this.state.matches.map((m, k) => (
            <div
              className={
                this.state.selectedMatch === k ? "match selected" : "match"
              }
              key={k}
            >
              <button onClick={() => this.setState({ selectedMatch: k })}>
                Select
              </button>
              <p>
                <strong>View:</strong>{" "}
                <a
                  href={"https://app.myaccident.org/accident/" + m.custom_id}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Accident Link
                </a>
              </p>
              <p>
                <strong>Date:</strong> {moment(m.date).format("llll")}
              </p>
              <p>
                <strong>Address:</strong> {m.location_address}
              </p>
              <p>
                <strong>CaseID:</strong> {m.caseID}
              </p>
              {m.vehicles.map((v, k) => (
                <p key={k}>
                  <strong>Vehicle {k + 1}:</strong>
                  {v.vehicle_maker +
                    " " +
                    v.vehicle_model_year +
                    " " +
                    v.vehicle_color}
                </p>
              ))}
            </div>
          ))}
          <div className="footer">
            <button
              onClick={() =>
                this.setState({ matches: [], selectedMatch: undefined })
              }
              className="outline"
            >
              Cancel
            </button>
            <button
              onClick={() => this.confirmMatch()}
              disabled={this.state.selectedMatch === undefined}
            >
              Confirm Match
            </button>
          </div>
        </div>
        <SendReportsToUser
          sendToUserCase={this.state.sendToUserCase}
          close={() => this.setState({ sendToUserCase: undefined })}
          user={this.props.user}
        />
        <input
          type="text"
          value={this.state.filter}
          onChange={(e) => this.setState({ filter: e.target.value })}
          placeholder="Search in records..."
          style={{ maxWidth: 250 }}
        />
        {this.state.cases.length && (
          <table>
            <thead>
              <tr>
                {this.props.user.canOrderReports ||
                this.props.user.role.id === 10 ||
                this.props.user.role.id === 11 ? (
                  <th></th>
                ) : null}
                <th></th>
                <th>
                  <a onClick={() => this.changeSort("userName")}>
                    User {this.sortIcons("userName")}
                  </a>
                </th>
                <th>
                  <a onClick={() => this.changeSort("createdAt")}>
                    Date Added {this.sortIcons("createdAt")}
                  </a>
                </th>
                <th>
                  <a onClick={() => this.changeSort("business_case_id")}>
                    Case # {this.sortIcons("business_case_id")}
                  </a>
                </th>
                <th>
                  <a onClick={() => this.changeSort("lastName")}>
                    Client Last Name {this.sortIcons("lastName")}
                  </a>
                </th>
                <th>
                  <a onClick={() => this.changeSort("accidentIdDate")}>
                    Accident Date {this.sortIcons("accidentIdDate")}
                  </a>
                </th>
                <th>
                  <a onClick={() => this.changeSort("vehicle1")}>
                    Vehicle #1 {this.sortIcons("vehicle1")}
                  </a>
                </th>
                <th>
                  <a onClick={() => this.changeSort("vehicle2")}>
                    Vehicle #2 {this.sortIcons("vehicle2")}
                  </a>
                </th>
                <th className="center img">
                  <img src="/img/mya-report.png" alt="my accident report" />
                </th>
                <th className="center img">
                  <img src="/img/pro-report.png" alt="pro report" />
                </th>
                <th className="center img">
                  <img src="/img/txdmv.png" alt="txdmv report" />
                </th>
                <th className="center img">
                  <img src="/img/cr3.png" alt="cr3 report" />
                </th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {this.state.cases.map((v, k) =>
                this.filterFunc(v) ? (
                  <Fragment key={v._id}>
                    <tr>
                      {this.props.user.canOrderReports ||
                      this.props.user.role.id === 10 ||
                      this.props.user.role.id === 11 ? (
                        <td>
                          {v.status !== "Pending" ? (
                            this.state.selected[v._id] ? (
                              <FaCheckSquare
                                size={24}
                                className="link"
                                onClick={() => this.toggleSelect(v)}
                              />
                            ) : (
                              <FaRegSquare
                                size={24}
                                className="link"
                                onClick={() => this.toggleSelect(v)}
                              />
                            )
                          ) : (
                            <span data-title='You can not delete a accident record while the order is still in "Pending" status.'>
                              <FaInfoCircle
                                size={24}
                                style={{ opacity: 0.5 }}
                              />
                            </span>
                          )}
                        </td>
                      ) : null}
                      <td>
                        <a onClick={() => this.toggleExpand(v)}>
                          {v.expanded ? (
                            <FiMinusCircle size={24} />
                          ) : (
                            <FiPlusCircle size={24} />
                          )}
                        </a>
                      </td>
                      <td>
                        {v.user.accountOwnerName ? "Admin" : v.user.firstName}
                      </td>
                      <td>{new Date(v.createdAt).toLocaleDateString()}</td>
                      <td>{v.business_case_id}</td>
                      <td>{v.lastName}</td>
                      <td>
                        {v.accident
                          ? new Date(v.accident.date).toLocaleDateString()
                          : new Date(v.startDate).toLocaleDateString() +
                            " - " +
                            new Date(v.endDate).toLocaleDateString()}
                      </td>
                      <td>{v.vehicle1}</td>
                      <td>{v.vehicle2}</td>
                      <td className="center">
                        {v.accident ? (
                          <a
                            href={
                              "https://app.myaccident.org/accident/" +
                              v.accident.custom_id
                            }
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            View
                          </a>
                        ) : (
                          ""
                        )}
                      </td>
                      <td className="center">
                        {v.accident &&
                        (v.status === "Pending" || v.status === "Complete") ? (
                          <a
                            href={this.businessReportLink(v)}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            View
                          </a>
                        ) : (
                          ""
                        )}
                      </td>
                      {/* <td className="center">
												{v.accident &&
													(v.status === "Pending" ||
														v.status === "Complete" ||
														"")}
											</td> */}
                      {this.renderCR3column(v)}
                      <td>
                        <FaStickyNote
                          size="24"
                          className="link note"
                          onClick={() =>
                            this.setState({
                              note: v.notes || "",
                              editNoteId: v._id,
                            })
                          }
                        />
                      </td>
                      <td>
                        {v.status === "Complete" ? (
                          <button
                            onClick={() => this.setState({ sendToUserCase: v })}
                          >
                            EMAIL REPORTS
                          </button>
                        ) : null}
                      </td>
                    </tr>
                    <tr className={clsx("expander", { show: v.expanded })}>
                      <td colSpan={15}>
                        {v.expanded ? <Expander data={v} /> : null}
                      </td>
                    </tr>
                  </Fragment>
                ) : null
              )}
            </tbody>
          </table>
        )}
        <div className="dashboard-footer">
          <button
            style={{
              opacity: Object.keys(this.state.selected).length === 0 ? 0 : 1,
            }}
            disabled={Object.keys(this.state.selected).length === 0}
            className="red"
            onClick={() => this.askDelete()}
          >
            Delete
          </button>
        </div>
      </div>
    );
  }
}
