import React, { useEffect, useState } from "react";
import * as CanvasJSReact from "../../canvasjs.react";
import moment from "moment";
import { defaultGraphOptions } from "../LeadStats";
import {
  paid_google_camplj,
  paid_google_competitors,
  paid_google_competitors_spanish,
  paid_google_insurance_claims,
  paid_google_primary,
  paid_google_primary_spanish,
  paid_google_direct,
  paid_google_truck,
  paid_google_medical,
} from "../LeadFilter";
// import Checkbox from "../Shared/Checkbox";

const CanvasJSChart = CanvasJSReact.CanvasJSChart;

const isPaid = (referer) => {
  return (
    referer &&
    referer.match(
      paid_google_primary +
        "|" +
        paid_google_primary_spanish +
        "|" +
        paid_google_camplj +
        "|" +
        paid_google_insurance_claims +
        "|" +
        paid_google_competitors +
        "|" +
        paid_google_competitors_spanish +
        "|" +
        paid_google_direct +
        "|" +
        paid_google_medical +
        "|" +
        paid_google_truck
    )
  );
};

export default function GooglePaid({ leads, view, users }) {
  const [graphData, setGraphData] = useState({});
  // const [leadType, setLeadType] = useState("");
  // const [includeColds, setIncludeColds] = useState(false);

  useEffect(() => {
    // console.log(leads);
    // console.log(users);
    // console.log(filterUsers);
    const gd = { ...defaultGraphOptions };
    let data = {};
    // console.log(keys);

    leads.forEach((v) => {
      let period;
      if (view === "daily")
        period = new moment(v.createdAt).format("YYYY-MM-DD");
      if (view === "monthly") period = v.createdAt.substr(0, 7);
      if (view === "weekly") {
        period =
          "Week " +
          moment(v.createdAt).isoWeek() +
          ", " +
          new Date(v.createdAt).getFullYear();
      }
      if (!data[period]) data[period] = { value: 0 };

      if (v.lead_level !== "cold" && isPaid(v.referer)) {
        data[period].value = data[period].value + v.value;
      }
    });

    gd.toolTip = {
      shared: true,
      // content: "{label}<hr />{name}: {y}",
    };
    // console.log(data);

    gd.data = [];
    // gd.data.push({
    //   type: "column",
    //   color: "#F00",
    //   showInLegend: true,
    //   name: "google count",
    //   dataPoints: Object.keys(data).map((vv) => {
    //     return {
    //       label: view === "daily" ? moment(vv).format("ll") : vv,
    //       y: data[vv]["paid"],
    //     };
    //   }),
    // });

    gd.data.push({
      type: "column",
      color: "#00F",
      showInLegend: true,
      name: "paid google pending value",
      dataPoints: Object.keys(data).map((vv) => {
        return {
          label: view === "daily" ? moment(vv).format("ll") : vv,
          y: data[vv]["value"],
        };
      }),
    });

    setGraphData(gd);
  }, [leads, view]);

  return (
    <div>
      <h2>PAID GOOGLE REVS.</h2>
      {/* <Checkbox
        text="Include colds"
        checked={includeColds}
        onClick={() => setIncludeColds(!includeColds)}
      />
 */}
      <br />
      <CanvasJSChart options={graphData} />
    </div>
  );
}
