/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { MdClose, MdPerson, MdLocationCity } from "react-icons/md";
import moment from "moment";
import AlertUserExpander from "./AlertUserExpander";
import { withRouter } from "react-router-dom";

function UserLeadsDashboard({ leads, acceptLead, declineLead, history }) {
	const [preview, setPreview] = useState(undefined);
	const accept = () => {
		acceptLead(preview);
		setPreview(false);
	};
	const decline = () => {
		declineLead(preview);
		setPreview(false);
	};

	const mask = (inp) => {
		return inp.replace(/([a-z,A-Z,0-9])/gi, "x");
	};

	const justFirstName = (firstName, name, lastName) => {
		if (firstName && lastName)
			return firstName + " " + lastName.substr(0, 1) + ".";
		else if (name) {
			const n = name.split(" ");
			return n[0] + " " + (n[1] ? n[1].substr(0, 1) + "." : "");
		} else {
			return "";
		}
	};

	const prices = { cold: "$25", warm: "$100", hot: "$300", free: "$0" };

	if (leads && leads.length) {
		return (
			<>
				<div className={preview ? "modal show" : "modal"}>
					<button className="close" onClick={() => setPreview(false)}>
						<MdClose />
					</button>
					<h2>Purchase lead?</h2>
					{preview && preview.lead_level !== "free" ? (
						<p>
							{preview.lead_level === "free"
								? `This is a free lead. Your credit card will not be billed when you click "Purchase Lead".`
								: `This is a ${preview.lead_level} lead and will cost ${
										prices[preview.lead_level]
								  }. Your credit card will be billed when you click "Purchase Lead".`}

							<br />
							<br />
						</p>
					) : null}
					{preview ? (
						<div className="exp">
							<p>
								<strong>Name: </strong>
								<span>
									{justFirstName(
										preview.firstName,
										preview.name,
										preview.lastName
									)}
								</span>
							</p>
							<p>
								<strong>Phone: </strong>
								<span className="blurred">{mask(preview.phone)}</span>
							</p>
							<p>
								<strong>Email: </strong>
								<span className="blurred">{mask(preview.email)}</span>
							</p>
							{preview.city ? (
								<p>
									<strong>City: </strong>
									<span>{preview.city}</span>
								</p>
							) : null}
							<p>
								<strong>Message: </strong> {preview.message}
							</p>
							{preview.hasOwnProperty("injured") ? (
								<p>
									<strong>Injured: </strong>{" "}
									{preview.injured ? "Yes" : "Unknown"}
								</p>
							) : null}
							{preview.hasOwnProperty("fault") ? (
								<p>
									<strong>Fault: </strong> {preview.fault ? "Yes" : "Unknown"}
								</p>
							) : null}

							<p>
								<strong>Source: </strong>
								{preview.source}
							</p>
							{preview.accidentURL ? (
								<p>
									<strong>Accident URL: </strong>
									<a href={preview.accidentURL}>Link</a>
								</p>
							) : null}
							<p>
								<strong>Created At:</strong>{" "}
								{moment(preview.createdAt).format("llll")}
							</p>
							<p>
								<strong>Lead Level:</strong>{" "}
								<span className={"hotness " + preview.lead_level}>
									{preview.lead_level}
								</span>
							</p>
							{preview.alert ? (
								<div
									style={{ clear: "both", marginTop: 10, overflow: "hidden" }}
								>
									<h3>Alert Info:</h3>
									<AlertUserExpander data={preview.alert} blurred />
								</div>
							) : null}
							<div
								style={{ clear: "both", marginTop: 10, overflow: "hidden" }}
							/>
						</div>
					) : null}
					{preview && preview.lead_level !== "bad hot" ? (
						<div className="footer">
							<button onClick={() => decline()} className="outline">
								DECLINE LEAD
							</button>
							<button onClick={() => accept()}>
								PURCHASE LEAD
								{preview && preview.lead_level !== "free"
									? " - " + prices[preview.lead_level]
									: null}
							</button>
						</div>
					) : null}
				</div>
				<div id="user-leads">
					<h3>
						New leads that you might be interested in:
						<a
							onClick={() => history.push("/lead-types")}
							style={{ display: "inline", float: "right", cursor: "pointer" }}
						>
							More info
						</a>
					</h3>
					{leads.map((l) => (
						<div className={"lead " + l.lead_level} key={l._id}>
							{l.lead_level === "free" ? (
								<div className="ribbon">FREE</div>
							) : null}
							{l.city ? <MdLocationCity size="72" /> : <MdPerson size="72" />}
							<br />
							{l.city ? (
								<span>
									{l.city}
									<br />
								</span>
							) : null}
							<span>{justFirstName(l.firstName, l.name, l.lastName)}</span>
							<br />
							<br />
							{l.alert && l.alert.startDate ? (
								<div>
									Accident Date:
									<br />
									{moment(l.alert.startDate).format("lll")}
								</div>
							) : null}
							<br />
							Lead Created:
							<br />
							{moment(l.createdAt).format("lll")}
							<br />
							<button
								onClick={() => {
									setPreview(l);
									// console.log(l);
								}}
							>
								View lead details
							</button>
						</div>
					))}
				</div>
			</>
		);
	}
	return null;
}

export default withRouter(UserLeadsDashboard);
