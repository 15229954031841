import React, { useEffect, useState } from "react";
import * as CanvasJSReact from "../../canvasjs.react";
import moment from "moment";
import { defaultGraphOptions } from "../LeadStats";
import MultiSelect from "../MultiSelect";

const CanvasJSChart = CanvasJSReact.CanvasJSChart;

export default function LeadsByLawFirm({ leads, view, users }) {
  const [graphData, setGraphData] = useState({});
  const [filterUsers, setFilterUsers] = useState([]);
  useEffect(() => {
    // console.log(leads);
    // console.log(users);
    // console.log(filterUsers);
    const gd = { ...defaultGraphOptions };
    let data = {};
    leads.forEach((v) => {
      let period;
      if (view === "daily")
        period = new moment(v.createdAt).format("YYYY-MM-DD");
      if (view === "monthly") period = v.createdAt.substr(0, 7);
      if (view === "weekly") {
        period =
          "Week " +
          moment(v.createdAt).isoWeek() +
          ", " +
          new Date(v.createdAt).getFullYear();
      }
      if (!data[period])
        data[period] = {
          rejected: 0,
          sold: 0,
        };

      if (filterUsers.length) {
        if (
          filterUsers.includes(v.affiliate) ||
          filterUsers.includes(v.accepted)
        ) {
          if (v.lead_level === "sold") data[period].sold++;
          if (v.lead_level === "rejected") data[period].rejected++;
        }
      } else {
        if (v.lead_level === "sold") data[period].sold++;
        if (v.lead_level === "rejected") data[period].rejected++;
      }
    });

    gd.toolTip = {
      shared: true,
      // content: "{label}<hr />{name}: {y}",
    };

    gd.data = [
      {
        type: "stackedColumn",
        color: "#F00",
        showInLegend: true,
        name: "Leads",
        dataPoints: Object.keys(data).map((vv) => {
          return {
            label: view === "daily" ? moment(vv).format("ll") : vv,
            y: data[vv].sold,
          };
        }),
      },
      {
        type: "stackedColumn",
        color: "#555",
        showInLegend: true,
        name: "Rejected leads",
        dataPoints: Object.keys(data).map((vv) => {
          return {
            label: view === "daily" ? moment(vv).format("ll") : vv,
            y: data[vv].rejected,
          };
        }),
      },
      {
        type: "line",
        color: "#555",
        showInLegend: true,
        name: "Rejection %",
        dataPoints: Object.keys(data).map((vv) => {
          return {
            label: view === "daily" ? moment(vv).format("ll") : vv,
            y: (data[vv].rejected / (data[vv].sold + data[vv].rejected)) * 100,
          };
        }),
      },
    ];
    setGraphData(gd);
  }, [leads, filterUsers, view]);

  return (
    <div>
      <h2>Leads By Law Firm</h2>
      <div
        style={{
          position: "absolute",
          zIndex: 1,
          background: "#fff",
        }}
      >
        <MultiSelect
          options={users.map((v) => {
            return { value: v._id, title: v.email };
          })}
          placeholder="All Acc. / Ass. / Dec."
          initial={filterUsers}
          accept={(v) => setFilterUsers([...v])}
          showFilter={true}
        />
      </div>
      <br />
      <br />
      <br />
      <br />
      <CanvasJSChart options={graphData} />
    </div>
  );
}
