import React from "react";
import { injectStripe, CardNumberElement, CardExpiryElement, CardCVCElement } from "react-stripe-elements";

class NewCardForm extends React.Component {
	state = {
		nameOnCard: "",
		loading: false
	};
	handleSubmit = ev => {
		ev.preventDefault();
		this.setState({
			loading: true,
			disabled: true,
			success: false,
			fail: false,
			error: null
		});

		this.props.stripe
			.createSource({
				type: "card",
				owner: {
					name: this.state.nameOnCard
				}
			})
			.then(async res => {
				if (res.error) {
					return this.setState({
						loading: false,
						success: false,
						fail: true,
						error: res.error.message,
						disabled: false
					});
				}
				this.props.addSource(res.source);
			})
			.catch(() => {
				this.setState({
					loading: false,
					success: false,
					fail: true,
					disabled: false,
					error: "Please provide all the details"
				});
			});
	};

	render() {
		return (
			<form onSubmit={e => this.handleSubmit(e)}>
				<div className="row">
					<div className="column">
						<label htmlFor="nameOnCard">Name On Card:</label>
						<input id="nameOnCard" type="text" value={this.state.nameOnCard} onChange={e => this.setState({ nameOnCard: e.target.value })} />
					</div>
					<div className="column" />
				</div>

				<div className="row">
					<label className="column">
						Card number
						<CardNumberElement className="stripe_input" onChange={this.handleChange} />
					</label>

					<label className="column">
						Expiration date
						<CardExpiryElement className="stripe_input_exp stripe_input" onChange={this.handleChange} />
					</label>
					<label className="column">
						CVC
						<CardCVCElement className="stripe_input" onChange={this.handleChange} />
					</label>
				</div>

				<div className="error">{this.state.error}</div>
				<div className="footer">
					<button type="button" onClick={() => this.props.close()} className="outline">
						Cancel
					</button>
					<button type="submit">{this.state.loading ? <img src="/img/ajax-loader.gif" alt="Loading" /> : "Add card"}</button>
				</div>
			</form>
		);
	}
}
export default injectStripe(NewCardForm);
