import React from "react";
import AlertUserExpander from "./AlertUserExpander";
import moment from "moment/moment";

const calcValues = ["Low", "Mid", "High"];

export const formatPhoneNumber = (phoneNumberString) => {
  var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    var intlCode = match[1] ? "+1 " : "";
    return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
  }
  return null;
};

export default function LeadsExpander({ data, removePhone }) {
  const v = data;
  const accidentDetails = v?.extra_details?.accidentDetails;

  let fields = [
    "firstName",
    "lastName",
    "name",
    "email",
    "city",
    "message",
    "phone",
    "type",
    "occupant",
    "damage",
    "injuredCount",
    "compensation",
    "zip",
    "cancelReason",
    "trusted_form_cert",
  ];

  let gotVehicle;
  let gotVehicleIndex;
  let atFaultVehicleIndex;
  if (removePhone) fields = fields.filter((v) => v !== "phone");
  if (v?.phone) v.phone = formatPhoneNumber(v.phone);

  if (v?.accident && v.accident.vehicles) {
    v.accident.vehicles.forEach((d, k) => {
      if (v.vin && d.vin_code === v.vin) {
        gotVehicle = d;
        gotVehicleIndex = k;
      }
      if (
        d.contributing_factor1 &&
        d.contributing_factor1 !== "N" &&
        d.contributing_factor1 !== "NONE" &&
        d.contributing_factor1 !== ""
      )
         atFaultVehicleIndex = k;
      if (
        gotVehicleIndex === undefined &&
        data.occupant === d.vehicle_maker + " " + d.vehicle_model_year
      ) {
        gotVehicleIndex = k;
      }
    });
  }
  // console.log(atFaultVehicleIndex);
  // console.log(v);
  if (v)
    return (
      <div className="exp two-columns" key={v._id}>
        <p>
          <strong>Preferred call time:</strong>{" "}
          {v.preferredCallTime ? v.preferredCallTime : "ASAP (not confirmed)"}
        </p>

        <div style={{ clear: "both" }} />
        {fields.map((f) => {
          if (v[f])
            return (
              <p key={f}>
                <strong>{f}: </strong>
                {v[f]}
              </p>
            );
          return null;
        })}
        {v.vin ? (
          <p>
            <strong>VIN: </strong> {v.vin.toUpperCase()}
          </p>
        ) : null}
        <p>
          <strong>Legal Consult: </strong> {v.legal_consult ? "Yes" : "No"}
        </p>
        <p>
          <strong>Injured: </strong> {v.injured ? "Yes" : "Unknown"}
        </p>
        {v.lead_level === "cold" ? (
          <p>
            <strong>Fault: </strong>
            {v.fault === undefined ? "Unknown" : ""}
            {v.fault === true ? "Yes" : ""}
            {v.fault === false ? "No" : ""}
          </p>
        ) : (
          <p>
            <strong>Fault:</strong>{" "}
            {v.fault ||
            (atFaultVehicleIndex !== undefined &&
              atFaultVehicleIndex === gotVehicleIndex)
              ? "Yes"
              : "No/Unknown"}
          </p>
        )}
        <p>
          <strong>Copy Requested: </strong> {v.copyRequested ? "Yes" : "No"}
        </p>
        <p>
          <strong>Car Brain: </strong> {v.carBrain ? "Yes" : "No"}
        </p>
        <p>
          <strong>Lead Level:</strong>{" "}
          <span className={"hotness " + v.lead_level}>{v.lead_level}</span>
        </p>
        {v.accident && (
          <p>
            <strong>City:</strong> {v.accident.city}
          </p>
        )}
        {v.accident && (
          <p>
            <strong>State:</strong> {v.accident.state}
          </p>
        )}
        {v.accident && (
          <p>
            <strong>ZIP:</strong> {v.accident.zip}
          </p>
        )}
        {v.accidentId ? (
          <p>
            <strong>Accident Url:</strong>{" "}
            <a
              href={"https://app.myaccident.org/accident/" + v.accidentId}
              target="_blank"
              rel="noopener noreferrer"
            >
              Link
            </a>
          </p>
        ) : null}
        <div style={{ clear: "both" }} />
        <p>
          <strong>Unsubscribed:</strong> {v.unsub ? "Yes" : "No"}
        </p>
        {v.lead_type === "CALawyer" && v.lead_level === "hot" ? (
          <p>
            <strong>Consent to lawyer contact:</strong> Yes
          </p>
        ) : (
          ""
        )}
        <div style={{ clear: "both" }} />
        {window.location.hostname.indexOf("localhost") > -1 ? (
          <p>
            <strong>Referer:</strong>
            {v.referer ? v.referer : ""}
          </p>
        ) : null}

        {v.source === "download" ? (
          <>
            <p>
              <strong>PDF Url:</strong>{" "}
              <a
                href={"https://app.myaccident.org/pdf/" + v._id}
                target="_blank"
                rel="noopener noreferrer"
              >
                Link
              </a>
            </p>
            <p>
              <strong>Convert to notification Url:</strong>{" "}
              <a
                href={"https://app.myaccident.org/notification/" + v.accidentId}
                target="_blank"
                rel="noopener noreferrer"
              >
                Link
              </a>
            </p>
          </>
        ) : null}
        {v.accidentURL && v.accidentURL !== "false" ? (
          <p>
            <strong>Accident Url:</strong>{" "}
            <a href={v.accidentURL} target="_blank" rel="noopener noreferrer">
              Link
            </a>
          </p>
        ) : null}
        {v.businessReportURL && v.businessReportURL !== "false" ? (
          <p>
            <strong>Business Report Url:</strong>{" "}
            <a
              href={v.businessReportURL}
              target="_blank"
              rel="noopener noreferrer"
            >
              Link
            </a>
          </p>
        ) : null}
        {v.alert ? (
          <div style={{ clear: "both", marginTop: 10, overflow: "hidden" }}>
            <h3>Alert Info:</h3>
            <AlertUserExpander data={v.alert} removePhone={removePhone} />
          </div>
        ) : null}
        {v.ad_owner ? (
          <div style={{ clear: "both", margin: 10, overflow: "hidden" }}>
            <h3>Ad form submission info:</h3>
            <br />
            <p>
              <strong>Business Name: </strong> {v.ad_owner.businessName}
            </p>
            <p>
              <strong>Name: </strong> {v.ad_owner.firstName}{" "}
              {v.ad_owner.lastName}
            </p>
            <p>
              <strong>Email: </strong> {v.ad_owner.email}
            </p>
          </div>
        ) : null}
        {v.accident && v.accident.vehicles ? (
          <div style={{ clear: "both", overflow: "hidden" }}>
            <h3>
              Vehicles Summary, total vehicles: {v.accident.vehicles.length}
            </h3>
            <table className="vehicle-summary">
              <thead>
                <tr>
                  <th></th>
                  <th></th>
                  <th>Year Make</th>
                  <th>Commercial</th>
                  <th>Commercial Type</th>
                  <th>Description</th>
                  <th>At fault</th>
                </tr>
              </thead>
              <tbody>
                {v.accident.vehicles.map((vv, kk) => (
                  <tr key={kk}>
                    <td>{kk === gotVehicleIndex ? "➡️" : ""}</td>
                    <td>{kk + 1}.</td>
                    <td>
                      {vv.vehicle_model_year} {vv.vehicle_maker}
                    </td>
                    <td>{vv.vehicle_commercial}</td>
                    <td>{vv.vehicle_type}</td>
                    <td>{vv.vehicle_cargo_body}</td>
                    <td>{atFaultVehicleIndex === kk ? "Y" : "N"}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : null}
        {gotVehicle ? (
          <div style={{ clear: "both", overflow: "hidden" }}>
            <h3>Occupant vehicle summary:</h3>
            <br />
            <p>
              <strong>Make: </strong> {gotVehicle.vehicle_maker}
            </p>
            <p>
              <strong>Year: </strong> {gotVehicle.vehicle_model_year}
            </p>
            <p>
              <strong>Color: </strong> {gotVehicle.vehicle_color}
            </p>
            <p>
              <strong>VIN: 123</strong>{" "}
              {gotVehicle.vin_code ? gotVehicle.vin_code.toUpperCase() : ""}
            </p>
            <div style={{ clear: "both" }} />
            <p>
              <strong>Towing company: </strong> {gotVehicle.towing_company}
            </p>
            <p>
              <strong>Towing location: </strong> {gotVehicle.towing_location}
            </p>
            <p>
              <strong>Damage area 1: </strong> {gotVehicle.damage_area_1}
            </p>
            <p>
              <strong>Damage severity 1: </strong>{" "}
              {gotVehicle.damage_severity_1}
            </p>
            <p>
              <strong>Damage area 2: </strong> {gotVehicle.damage_area_2}
            </p>
            <p>
              <strong>Damage severity 2: </strong>{" "}
              {gotVehicle.damage_severity_2}
            </p>
            {/* <p>
							<strong>Milage: </strong> {gotVehicle.milage}
						</p> */}
            <div style={{ clear: "both" }} />
            <h3>Participants:</h3>
            <br />
            {gotVehicle.participants.map((p) => (
              <div style={{ clear: "both" }} key={p.position}>
                <p>
                  <strong>Type:</strong> {p.type}
                </p>
                <p>
                  <strong>Position:</strong> {p.position}
                </p>
                <p>
                  <strong>Air bag:</strong> {p.airbag}
                </p>
                <p>
                  <strong>Charge:</strong> {p.charge}
                </p>
              </div>
            ))}
          </div>
        ) : null}
        <div style={{ clear: "both", overflow: "hidden" }} />
        {v.calc_data && v.source === "calc" ? (
          <div>
            <h3>CALC data:</h3>
            <p>
              <strong>Fault:</strong> {v.calc_data.fault ? "Yes" : "No"}
            </p>
            <p>
              <strong>Commercial vehicle involved:</strong>{" "}
              {v.calc_data.commercial ? "Yes" : "No"}
            </p>
            <p>
              <strong>
                What degree of injuries did you sustain in your accident?
              </strong>{" "}
              {calcValues[v.calc_data.injuries]}
            </p>
            <p>
              <strong>Did you experience a loss of income?</strong>{" "}
              {calcValues[v.calc_data.earnings]}
            </p>
            <p>
              <strong>Do you expect future medical Expenses?</strong>{" "}
              {calcValues[v.calc_data.medical]}
            </p>
            <p>
              <strong>Did you experience pain and suffering?</strong>{" "}
              {calcValues[v.calc_data.pain]}
            </p>
            <p>
              <strong>Calc Email:</strong> {v.calc_id ? v.calc_id : ""}
            </p>
          </div>
        ) : null}

        {v.calc_data && v.source === "lejeune" ? (
          <>
            <div>
              <h3>Questionary data:</h3>
              <strong>
                Were you or a loved one stationed at Camp Lejeune between 1953
                and 1987?
              </strong>{" "}
              {v.calc_data.q1 ? "Yes" : "No"}
            </div>
            <div>
              <strong>
                Did You Or A Loved One Develop A Serious Illness Due To Toxic
                Drinking Water At Camp Lejeune?
              </strong>{" "}
              {v.calc_data.q2 ? "Yes" : "No"}
            </div>

            <div>
              <strong>
                Did you or a loved one spend at least 30 days at Camp Lejeune
                between 1953-1987?
              </strong>{" "}
              {v.calc_data.q3 ? "Yes" : "No"}
            </div>
          </>
        ) : null}

        {accidentDetails && v.source === "direct" ? (
          <div>
            <h3>Accident Details:</h3>
            <p>
              <strong>Fault:</strong> {accidentDetails.fault ? "Yes" : "No"}
            </p>
            <p>
              <strong>Commercial vehicle involved:</strong>{" "}
              {accidentDetails.commercial ? "Yes" : "No"}
            </p>
            <p>
              <strong>
                What degree of injuries did you sustain in your accident?
              </strong>{" "}
              {calcValues[accidentDetails.injuries]}
            </p>
            <p>
              <strong>Did you experience a loss of income?</strong>{" "}
              {calcValues[accidentDetails.earnings]}
            </p>
            <p>
              <strong>Do you expect future medical Expenses?</strong>{" "}
              {calcValues[accidentDetails.medical]}
            </p>
            <p>
              <strong>Did you experience pain and suffering?</strong>{" "}
              {calcValues[accidentDetails.pain]}
            </p>
            {accidentDetails.vin ? (
              <p>
                <strong>VIN: </strong> {accidentDetails.vin.toUpperCase()}
              </p>
            ) : null}
            {accidentDetails.caseID ? (
              <p>
                <strong>case ID: </strong> {accidentDetails.caseID}
              </p>
            ) : null}
            {accidentDetails.vehicleMake?.value ? (
              <p>
                <strong>Vehicle make: </strong> {accidentDetails.vehicleMake?.value}
              </p>
            ) : null}
            {accidentDetails.vehicleYear ? (
              <p>
                <strong>Vehicle year: </strong> {accidentDetails.vehicleYear}
              </p>
            ) : null}
            <p>
              <strong>Date of accident: </strong>{" "}
              {moment(accidentDetails.date).format("MM/DD/YYYY")}
            </p>
            <p>
              <strong>City: </strong>{" "}
              {accidentDetails.city}
            </p>            <p>
              <strong>Email: </strong>{" "}
              {accidentDetails.email}
            </p>            <p>
              <strong>Phone: </strong>{" "}
              {accidentDetails.phone}
            </p>
          </div>
        ) : null}

        <div style={{ clear: "both", marginTop: 10, overflow: "hidden" }} />
      </div>
    );
  return null;
}
