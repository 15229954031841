/* eslint-disable react/no-direct-mutation-state */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* global google */
import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import AdminSearchResults from "../components/AdminSearchResults";
import { getData } from "../components/communicationService";
import swal from "sweetalert2";
import moment from "moment";
import { MdCancel } from "react-icons/md";
import { FaRegSquare, FaCheckSquare } from "react-icons/fa";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import FormRadioBtn from "../components/FormRadioBtn";
import TimeRangeSlider from "react-time-range-slider";
import Autocomplete from "react-google-autocomplete";
import { Range } from "rc-slider";
import "rc-slider/assets/index.css";

export default class SearchPage extends Component {
  state = {
    searchBy: "id",
    // searchBy: "location",
    caseId: "",
    crashId: "",
    vin: "",
    plate: "",
    searching: false,
    accidents: undefined,
    address: "",
    datePickerOpen: false,
    startDate: moment()
      .subtract(7, "days")
      .hour(0)
      .minute(0)
      .second(0)
      .toDate(),
    // startDate: moment().year(2019).month(11).day(1).hour(0).minute(0).toDate(),
    endDate: moment().hour(23).minute(59).second(59).toDate(),
    vehicle_maker: { value: "any", label: "Any car maker" },
    vehicle_maker_list: [],
    vehicle_year_from: 2000,
    vehicle_year_to: 2021,
    city: { value: "", label: "" },
    cityList: [],
    county: { value: "", label: "" },
    countyList: [],
    useMap: false,
    radius: { value: 16093, label: "10 miles" },
    radius_selected: 16093,
    marker_position: null,
    advanced: false,
    filter: {
      timeRange: {
        start: "00:00",
        end: "23:59",
      },
      oneVehicle: false,
      twoVehicles: false,
      twoPlusVehicles: false,
      injuries: false,
      fatalities: false,
    },
    oneVehicleCount: 0,
    twoVehicleCount: 0,
    twoPlusVehicleCount: 0,
    towed: false,
    severity_from: 0,
    severity_to: 7,
    state: "",
  };
  handleStartDateChange = (date) => {
    this.setState({ startDate: new Date(date) });
  };

  handleEndDateChange = (date) => {
    if (moment(this.state.startDate) < moment(date)) {
      this.setState({ endDate: new Date(date) });
    }
  };
  handleVehicleMaker = (selectedVehicle) => {
    this.setState({ vehicle_maker: selectedVehicle });
  };

  handleCountyChange = (county) => {
    this.setState({ county });

    if (county.value) {
      google.maps.event.clearListeners(this.mapSearchVehicle, "click");
    }
  };

  handleCityChange = (city) => {
    this.setState({ city });

    if (city.value) {
      google.maps.event.clearListeners(this.mapSearchVehicle, "click");
    }
  };

  loadData = () => {
    const vehicleMakerUrl = "/api/vehicle-maker-list";
    const cityUrl = "/api/search/cities/list";
    const countyUrl = "/api/search/counties/list";

    getData(
      vehicleMakerUrl,
      (response) => {
        this.setState({
          vehicleMakerList: response.vehicleMakerList.map((vehicleMaker) => {
            return {
              label: vehicleMaker,
              value: vehicleMaker,
            };
          }),
        });
      },
      (error) => console.log(error)
    );

    getData(
      cityUrl,
      (response) => {
        this.setState({
          cityList: response.citiesList.map((city) => {
            return { value: city.value, label: city.label };
          }),
        });
      },
      (error) => console.log(error)
    );

    getData(
      countyUrl,
      (response) => {
        this.setState({
          countyList: response.countiesList.map((county) => {
            return { value: county.value, label: county.label };
          }),
        });
      },
      (error) => console.log(error)
    );
  };

  findAccident = (e) => {
    e.preventDefault();
    const {
      zip,
      address,
      road_ref,
      marker_position,
      startDate,
      endDate,
      vehicle_maker,
      vehicle_year_from,
      vehicle_year_to,
      radius_selected,
      vin,
      city,
      county,
      caseId,
      crashId,
      plate,
      searchBy,
      advanced,
      towed,
      severity_from,
      severity_to,
      state,
    } = this.state;

    const baseUrl = "/api/search/";
    let url;
    let query = null;
    if (advanced)
      query = `&vehicle_maker=${
        vehicle_maker ? vehicle_maker.value.toUpperCase() : ""
      }&vehicle_year_from=${vehicle_year_from}&vehicle_year_to=${vehicle_year_to}&towed=${towed}&severity_from=${severity_from}&severity_to=${severity_to}`;

    if (searchBy === "id") {
      if (plate) {
        url = `/api/public-data/find/license?plate=${plate}`;
      } else if (vin) {
        url = `${baseUrl}vin?vin=${vin}`;
      } else if (crashId) {
        url = `${baseUrl}crashid?crashID=${crashId}`;
      } else if (caseId) {
        url = `${baseUrl}caseid?caseID=${caseId.substr(-6)}&?page=1`;
      }

      if (state) url = url + "&state=" + state;
    }

    if (searchBy === "location") {
      if (moment(startDate) > moment(endDate)) {
        swal.fire("Error", "Invalid date range. Please try again.", "error");
        return false;
      }
      let addressSearch = zip || address || road_ref;
      if (addressSearch && addressSearch.indexOf(",") > -1)
        addressSearch = addressSearch.split(",")[0];

      if (addressSearch) {
        url = `${baseUrl}address?address=${addressSearch}&startDate=${startDate}&endDate=${endDate}${
          query !== null ? query : ""
        }`;
      } else if (marker_position && radius_selected) {
        //api/accident/find-with-vehicle
        url = `/api/search/map?lat=${marker_position.lat()}&lng=${marker_position.lng()}&radius=${radius_selected}&startDate=${startDate}&endDate=${endDate}${
          query !== null ? query : ""
        }`;
      } else if (county.value || city.value) {
        let idQuery = county.value ? "countyID" : "cityID";

        url = `${baseUrl}ids?${idQuery}=${
          county.value || city.value
        }&startDate=${startDate}&endDate=${endDate}${
          query !== null ? query : ""
        }`;
      }
    }

    this.setState({ searching: true });

    getData(
      url,
      (response) => {
        this.setState({ searching: false });
        let accidents;
        if (response.accidents) accidents = response.accidents;
        if (response.accident) accidents = [response.accident];
        let totalItems = response.totalAccidents ? response.totalAccidents : 0;
        if (!accidents) {
          this.setState({ accidents: undefined });
          swal.fire(
            "Error",
            "There are no results for search terms. Please try again.",
            "error"
          );
          return false;
        }
        if (!accidents.length) {
          this.setState({ accidents: undefined });
          swal.fire(
            "Error",
            "There are no results for search terms. Please try again.",
            "error"
          );
        }

        this.setState({ accidents, totalItems });
        this.countTotals(accidents);
        window.scrollTo({ top: 0 });
      },
      (error) => {
        swal.fire(
          "Search Error",
          error.response?.data?.errors[0]?.msg || String(error),
          "error"
        );
        this.setState({ searching: false });
      }
    );
  };

  handleVehicleYear = (event) => {
    this.setState({
      vehicle_year_from: event[0],
      vehicle_year_to: event[1],
    });
  };

  handleSeverityRange = (event) => {
    this.setState({
      severity_from: event[0],
      severity_to: event[1],
    });
  };

  resetMarker = () => {
    this.marker.setMap(null);
    this.circle.setMap(null);

    this.setState({ marker_position: null });
  };
  initMap = () => {
    this.mapSearchVehicle = new window.google.maps.Map(
      document.getElementById("map-canvas-search-vehicle"),
      {
        center: {
          lat: 31.9686,
          lng: -99.9018,
        },
        zoom: 6,
        options: {
          streetViewControl: false,
          mapTypeControl: false,
          fullscreenControl: false,
          draggableCursor: "default",
        },
        gestureHandling: "greedy",
      }
    );

    this.mapSearchVehicle.addListener("click", (event) => {
      this.placeMarker(event.latLng);
    });
  };

  placeMarker = (location) => {
    if (this.marker) {
      this.marker.setMap(null);
      this.circle.setMap(null);
    }

    let marker = new window.google.maps.Marker({
      position: location,
      map: this.mapSearchVehicle,
      draggable: true,
    });

    let circle = new window.google.maps.Circle({
      map: this.mapSearchVehicle,
      radius: this.state.radius_selected,
      fillColor: "#AA0000",
    });

    circle.bindTo("center", marker, "position");

    this.marker = marker;
    this.circle = circle;

    if (this.mapSearchVehicle.zoom < 9) {
      this.mapSearchVehicle.setZoom(9);
      this.mapSearchVehicle.setCenter(marker.position);
    }

    this.mapInitilized = true;

    this.setState({ marker_position: marker.position, address: "" });
  };

  handleRadius = (radius) => {
    this.setState({
      radius,
      radius_selected: radius.value,
    });

    if (this.marker) {
      this.marker.setMap(null);
      this.circle.setMap(null);
    }
  };

  timeChangeHandler = (time) => {
    this.state.filter.timeRange = time;
    // console.log(time);
    this.setState({
      filter: this.state.filter,
    });
  };

  componentDidMount() {
    this.loadData();
    this.initMap();
  }

  countTotals(a) {
    // console.log(a);
    let oneVehicleCount = 0;
    let twoVehicleCount = 0;
    let twoPlusVehicleCount = 0;
    a.forEach((v) => {
      switch (v.vehicles.length) {
        case 0:
          break;
        case 1:
          oneVehicleCount++;
          break;
        case 2:
          twoVehicleCount++;
          break;
        default:
          twoPlusVehicleCount++;
          break;
      }
    });
    this.setState({
      oneVehicleCount,
      twoVehicleCount,
      twoPlusVehicleCount,
    });
  }

  vehicleFilter = (w) => {
    this.state.filter[w] = !this.state.filter[w];
    this.setState({ filter: this.state.filter });
  };

  usTime = (t) => {
    let h = Number(t.split(":")[0]);
    let m = t.split(":")[1];
    let out = "";
    if (h === 0) out = "12";
    else if (h < 13) out = h;
    else out = h - 12;
    return out + ":" + m + " " + (h > 11 ? "PM" : "AM");
  };

  onPlaceSelected = async (place) => {
    if (place.formatted_address) {
      const { name, vicinity } = place;

      if (name !== vicinity) {
        this.setState({
          address: `${name}, ${vicinity || ""}`,
        });
      } else {
        this.setState({ address: place.formatted_address });
      }
    }
  };

  render() {
    if (this.props.user === null) return <Redirect to="/signin" />;
    if (this.props.user.account_type === "regular") return <Redirect to="/" />;
    const {
      caseId,
      crashId,
      vin,
      plate,
      address,
      startDate,
      endDate,
      vehicle_maker,
      vehicleMakerList,
      vehicle_year_from,
      vehicle_year_to,
      city,
      cityList,
      county,
      countyList,
      radius_selected,
      marker_position,
      useMap,
      filter,
      towed,
      severity_from,
      severity_to,
      state,
    } = this.state;
    return (
      <div id="admin-page">
        <div className={useMap ? "modal search show" : "modal search"}>
          <div id="map-canvas-search-vehicle" />
          <br />
          <FormRadioBtn
            handleRadius={this.handleRadius}
            radiusSelected={radius_selected}
            resetMarker={this.resetMarker}
            markerPosition={marker_position}
            close={() => this.setState({ useMap: false })}
          />
        </div>

        <div id="search-controls">
          <div className="typeChooser">
            <button
              className={this.state.searchBy === "id" ? "selected" : ""}
              onClick={() => this.setState({ searchBy: "id" })}
            >
              ID Numbers
            </button>
            <button
              className={this.state.searchBy === "location" ? "selected" : ""}
              onClick={() => this.setState({ searchBy: "location" })}
            >
              Location
            </button>
          </div>
          {this.state.searchBy === "id" ? (
            <div className="searchBy">
              <form onSubmit={this.findAccident}>
                <label disabled={crashId || vin || plate}>Case ID</label>
                <input
                  onChange={(e) => this.setState({ caseId: e.target.value })}
                  value={caseId}
                  disabled={crashId || vin || plate}
                />
                <button
                  onClick={() => this.setState({ caseId: "" })}
                  className="clear-search"
                  type="button"
                  disabled={!caseId}
                >
                  <MdCancel style={{ pointerEvents: "none" }} />
                </button>
                <label disabled={caseId || vin || plate}>Crash ID</label>
                <input
                  onChange={(e) => this.setState({ crashId: e.target.value })}
                  value={crashId}
                  disabled={caseId || vin || plate}
                />
                <button
                  onClick={() => this.setState({ crashId: "" })}
                  className="clear-search"
                  type="button"
                  disabled={!crashId}
                >
                  <MdCancel style={{ pointerEvents: "none" }} />
                </button>
                <label disabled={caseId || crashId || plate}>VIN</label>
                <input
                  onChange={(e) => this.setState({ vin: e.target.value })}
                  value={vin}
                  disabled={caseId || crashId || plate}
                />
                <button
                  onClick={() => this.setState({ vin: "" })}
                  className="clear-search"
                  type="button"
                  disabled={!vin}
                >
                  <MdCancel style={{ pointerEvents: "none" }} />
                </button>

                <label>State</label>
                <input
                  onChange={(e) => this.setState({ state: e.target.value })}
                  value={state}
                />
                <button
                  onClick={() => this.setState({ state: "" })}
                  className="clear-search"
                  type="button"
                  disabled={!state}
                >
                  <MdCancel style={{ pointerEvents: "none" }} />
                </button>

                <label disabled={caseId || crashId || vin}>Texas Plate</label>
                <input
                  onChange={(e) => this.setState({ plate: e.target.value })}
                  value={plate}
                  disabled={caseId || crashId || vin}
                />
                <button
                  onClick={() => this.setState({ plate: "" })}
                  className="clear-search"
                  type="button"
                  disabled={!plate}
                >
                  <MdCancel style={{ pointerEvents: "none" }} />
                </button>

                <button
                  className="search-button"
                  disabled={!crashId && !caseId && !plate && !vin}
                >
                  SEARCH NOW
                </button>
              </form>
            </div>
          ) : null}
          {this.state.searchBy === "location" ? (
            <div className="searchBy">
              <form onSubmit={this.findAccident}>
                <label disabled={city.value || county.value || marker_position}>
                  Address
                </label>
                <Autocomplete
                  componentRestrictions={{ country: "us" }}
                  onChange={(e) => this.setState({ address: e.target.value })}
                  onPlaceSelected={this.onPlaceSelected}
                  types={["(regions)"]}
                  placeholder=""
                  value={address}
                  autoComplete={false}
                  disabled={city.value || county.value || marker_position}
                />
                <button
                  onClick={() => this.setState({ address: "" })}
                  className="clear-search"
                  type="button"
                  disabled={!address}
                >
                  <MdCancel style={{ pointerEvents: "none" }} />
                </button>

                {marker_position ? (
                  <div>
                    <input
                      value={`${
                        marker_position
                          ? marker_position.lat().toString().substr(0, 8)
                          : ""
                      }, ${
                        marker_position
                          ? marker_position.lng().toString().substr(0, 8)
                          : ""
                      }`}
                      disabled
                    />
                    <button
                      onClick={() => this.resetMarker()}
                      className="clear-search"
                      type="button"
                      disabled={!marker_position}
                    >
                      <MdCancel style={{ pointerEvents: "none" }} />
                    </button>
                  </div>
                ) : null}

                <a
                  onClick={() => this.setState({ useMap: true })}
                  className="clear"
                  style={{ marginTop: -20 }}
                >
                  Select Area on Map
                </a>

                <label>Start date:</label>
                <DatePicker
                  dateFormat={
                    startDate.toTimeString().startsWith("00:00:00")
                      ? "MMMM d, yyyy"
                      : "MMMM d, yyyy hh:mm aa"
                  }
                  className="datepicker"
                  id="startdate"
                  onChange={this.handleStartDateChange}
                  popperPlacement={"bottom"}
                  selected={startDate}
                />
                <label>End date:</label>
                <DatePicker
                  dateFormat={
                    endDate.toTimeString().startsWith("23:59:59")
                      ? "MMMM d, yyyy"
                      : "MMMM d, yyyy hh:mm aa"
                  }
                  className="datepicker"
                  id="enddate"
                  onChange={this.handleEndDateChange}
                  popperPlacement={"bottom"}
                  selected={endDate}
                />
                <a
                  onClick={() =>
                    this.setState({
                      advanced: !this.state.advanced,
                    })
                  }
                  className="advanced-link"
                >
                  {this.state.advanced ? "Hide" : "Show"} advanced options
                </a>
                <div
                  className={this.state.advanced ? "advanced show" : "advanced"}
                >
                  <label>Vehicle make</label>
                  <Select
                    className="vehicle-select"
                    value={vehicle_maker}
                    onChange={this.handleVehicleMaker}
                    options={vehicleMakerList}
                  />
                  <a
                    className="clear"
                    onClick={() =>
                      this.setState({
                        vehicle_maker: {
                          label: "Any car maker",
                          value: "any",
                        },
                      })
                    }
                  >
                    Clear
                  </a>
                  <label>
                    Vehicle year: {vehicle_year_from} - {vehicle_year_to}
                    <div
                      style={{
                        padding: "6px 4px",
                        width: "215px",
                        position: "absolute",
                        zIndex: 5,
                      }}
                    >
                      <Range
                        min={1950}
                        max={2021}
                        defaultValue={[vehicle_year_from, vehicle_year_to]}
                        onChange={(e) => this.handleVehicleYear(e)}
                        railStyle={{ backgroundColor: "#00b7ff" }}
                        handleStyle={[
                          {
                            borderColor: "#00b7ff",
                            backgroundColor: "#00b7ff",
                            width: "1rem",
                            height: "1rem",
                          },
                          {
                            borderColor: "#00b7ff",
                            backgroundColor: "#00b7ff",
                            width: "1rem",
                            height: "1rem",
                          },
                        ]}
                      />
                    </div>
                  </label>
                  <input style={{ pointerEvents: "none" }} />

                  <div style={{ height: 10, clear: "both" }} />

                  <label>Vehicle being towed?</label>
                  <input style={{ pointerEvents: "none" }} />
                  {towed ? (
                    <a
                      onClick={() => this.setState({ towed: !towed })}
                      className="towed-link"
                    >
                      <FaCheckSquare size={24} /> Yes
                    </a>
                  ) : (
                    <a
                      onClick={() => this.setState({ towed: !towed })}
                      className="towed-link"
                    >
                      <FaRegSquare size={24} /> No
                    </a>
                  )}
                  <div style={{ height: 10, clear: "both" }} />

                  <label>
                    Crash Severity Range: {severity_from} - {severity_to}
                    <div
                      style={{
                        padding: "6px 4px",
                        width: "215px",
                        position: "absolute",
                        zIndex: 5,
                      }}
                    >
                      <Range
                        min={0}
                        max={7}
                        defaultValue={[severity_from, severity_to]}
                        onChange={(e) => this.handleSeverityRange(e)}
                        railStyle={{ backgroundColor: "#00b7ff" }}
                        handleStyle={[
                          {
                            borderColor: "#00b7ff",
                            backgroundColor: "#00b7ff",
                            width: "1rem",
                            height: "1rem",
                          },
                          {
                            borderColor: "#00b7ff",
                            backgroundColor: "#00b7ff",
                            width: "1rem",
                            height: "1rem",
                          },
                        ]}
                      />
                    </div>
                  </label>
                  <input style={{ pointerEvents: "none" }} />

                  <div style={{ height: 10, clear: "both" }} />

                  <label disabled={address || county.value || marker_position}>
                    City
                  </label>
                  <Select
                    className="city-search-select"
                    value={city}
                    onChange={this.handleCityChange}
                    options={cityList}
                    isDisabled={address || county.value || marker_position}
                  />
                  <a
                    className="clear"
                    onClick={() =>
                      this.setState({
                        city: { label: "", value: "" },
                      })
                    }
                  >
                    Clear
                  </a>

                  <label disabled={address || city.value || marker_position}>
                    County
                  </label>
                  <Select
                    className="county-search-select"
                    value={county}
                    onChange={this.handleCountyChange}
                    // clearInputValue={}
                    options={countyList}
                    isDisabled={address || city.value || marker_position}
                  />

                  <a
                    className="clear"
                    onClick={() =>
                      this.setState({
                        county: { label: "", value: "" },
                      })
                    }
                  >
                    Clear
                  </a>
                </div>
                <button
                  className="search-button"
                  disabled={
                    !address && !city.value && !county.value && !marker_position
                  }
                >
                  SEARCH NOW
                </button>
              </form>
            </div>
          ) : null}
          {this.state.accidents && this.state.accidents.length ? (
            <div className="filters">
              <h3>Filter your results</h3>
              <label>Time of Accident:</label>
              <small>
                From {this.usTime(filter.timeRange.start)} to{" "}
                {this.usTime(filter.timeRange.end)}
              </small>
              <TimeRangeSlider
                disabled={false}
                format={24}
                maxValue={"23:59"}
                minValue={"00:00"}
                onChange={this.timeChangeHandler}
                step={15}
                value={this.state.filter.timeRange}
              />
              <label>By Vehicles Involved:</label>
              <a
                className={
                  this.state.filter.oneVehicle
                    ? "filter-link selected"
                    : "filter-link"
                }
                onClick={() => this.vehicleFilter("oneVehicle")}
              >
                {this.state.filter.oneVehicle ? (
                  <FaCheckSquare />
                ) : (
                  <FaRegSquare />
                )}{" "}
                1 vehicle ({this.state.oneVehicleCount})
              </a>
              <a
                className={
                  this.state.filter.twoVehicles
                    ? "filter-link selected"
                    : "filter-link"
                }
                onClick={() => this.vehicleFilter("twoVehicles")}
              >
                {this.state.filter.twoVehicles ? (
                  <FaCheckSquare />
                ) : (
                  <FaRegSquare />
                )}{" "}
                2 vehicles ({this.state.twoVehicleCount})
              </a>
              <a
                className={
                  this.state.filter.twoPlusVehicles
                    ? "filter-link selected"
                    : "filter-link"
                }
                onClick={() => this.vehicleFilter("twoPlusVehicles")}
              >
                {this.state.filter.twoPlusVehicles ? (
                  <FaCheckSquare />
                ) : (
                  <FaRegSquare />
                )}{" "}
                2+ vehicles ({this.state.twoPlusVehicleCount})
              </a>
              <label>Show Only Accidents with:</label>
              <a
                className={
                  this.state.filter.injuries
                    ? "filter-link selected"
                    : "filter-link"
                }
                onClick={() => this.vehicleFilter("injuries")}
              >
                {this.state.filter.injuries ? (
                  <FaCheckSquare />
                ) : (
                  <FaRegSquare />
                )}{" "}
                Injuries
              </a>
              <a
                className={
                  this.state.filter.fatalities
                    ? "filter-link selected"
                    : "filter-link"
                }
                onClick={() => this.vehicleFilter("fatalities")}
              >
                {this.state.filter.fatalities ? (
                  <FaCheckSquare />
                ) : (
                  <FaRegSquare />
                )}{" "}
                Fatalities
              </a>
            </div>
          ) : null}
        </div>
        <div id="search-results">
          {this.state.searching ? (
            <div>
              <img
                src="/img/ajax-loader.gif"
                alt="Loading..."
                className="loader-gif"
              />
              &nbsp;Searching...
            </div>
          ) : (
            <AdminSearchResults
              accidents={this.state.accidents}
              user={this.props.user}
              filter={this.state.filter}
            />
          )}
        </div>
      </div>
    );
  }
}
