import React from 'react';

const BackToTop = ({ className }) => {

  const backToTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="18" viewBox="0 0 30 18" className={className} onClick={backToTop}>
      <path fill="#AAA" fillRule="evenodd" d="M2.24 17.366l-.704-.725a2 2 0 0 1 0-2.787L14.283.738a1 1 0 0 1 1.434 0l12.747 13.116a2 2 0 0 1 0 2.787l-.705.725a2 2 0 0 1-2.872-.004L15 7.136 5.113 17.362a2 2 0 0 1-2.872.004z"/>
    </svg>
  );
}

export default BackToTop;