/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import Axios from "axios";
import React, { useState, useEffect, useRef } from "react";
import { FiPlusCircle, FiMinusCircle } from "react-icons/fi";
import { MdClose } from "react-icons/md";
import {
  // FaRegSquare,
  // FaCheckSquare,
  FaSortAmountDown,
  FaSortAmountUp,
} from "react-icons/fa";
import moment from "moment";
import AlertExpander from "../components/AlertExpander";
import clsx from "clsx";
import swal from "sweetalert2";
import JoditEditor from "jodit-react";

export default function BusinessAlertAdmin() {
  const [loading, setLoading] = useState(true);
  const [list, setList] = useState([]);
  const [matches, setMatches] = useState(undefined);
  const [ajax, setAjax] = useState(false);
  const [emailModalData, setEmailModalData] = useState(null);
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [emailText, setEmailText] = useState("");
  const [sortBy, setSortBy] = useState("createdAt");
  const [reverse, setReverse] = useState(true);
  const selectedAlert = useRef(null);

  // const [users, setUsers] = useState({});
  useEffect(() => {
    // let _users = {};
    // Axios.get("/api/businessAccount/users/all").then((d) => {
    //   d.data.users.forEach((u) => {
    //     _users[u._id] = u;
    //   });
    //   console.log(_users);
    // setUsers(_users);
    load();
    // });
  }, []);

  const load = () => {
    setLoading(true);
    Axios.get("/api/businessAccount/cases/alert").then((d) => {
      console.log(d.data);
      setLoading(false);
      setList(sorter(d.data.alerts, sortBy, reverse));
    });
  };

  const businessName = (v) => {
    if (v.user && !v.user.accountOwner) return v.user.businessName;
    if (v.user && v.user.accountOwner) return v.user.accountOwner.businessName;
    if (v.businessName) return v.businessName;
  };

  const toggleExpand = (v) => {
    if (!v.expanded) v.expanded = true;
    else v.expanded = false;
    setList([...list]);
  };

  const loadMatches = (v) => {
    setAjax(true);
    setMatches(v);
    selectedAlert.current = v;
    setEmail(v.user && v.user.email ? v.user.email : "");
    Axios.get("/api/businessAccount/cases/alert/fetch?id=" + v._id).then(
      (d) => {
        setAjax(false);
        if (d.data.success) {
          setMatches(d.data.alert);
        } else {
          swal.fire(
            "Error",
            "Couldn't get possible matches from server.",
            "error"
          );
        }
      }
    );
  };

  const sendSMS = async (alert) => {
    let accidents = alert.matches
      .filter((m) => m.selected)
      .map((match, i) => {
        return `\n\nAccident ${i + 1} - https://app.myaccident.org/accident/${
          match.custom_id
        }`;
      });

    let phone;
    if (alert.user && alert.user.phone) {
      phone = alert.user.phone;
    } else {
      phone = alert.phone;
    }

    let firstName;
    if (alert.user && alert.user.firstName) {
      firstName = alert.user.firstName;
    } else {
      firstName = alert.firstName;
    }

    swal.queue([
      {
        title: "Send notification",
        text: `Sending notification to ${phone} . Please confirm!`,
        input: "textarea",
        inputValue: `MyAccident Update!\n Hi ${firstName}, we have found a report that looks to be a match for your alert.\nPlease click on the link and review the accident information here:\n${accidents}\n\nPlease let us know if this is not the correct accident report. Have a great day,\nBen`,
        showCancelButton: true,
        confirmButtonText: "Confirm!",
        cancelButtonText: "Cancel",
        reverseButtons: true,
        preConfirm: async (input) => {
          try {
            const response = await Axios.post(
              `/api/businessAccount/cases/alert/notify/sms`,
              {
                alert,
                input,
              }
            );
            if (response.data.success) {
              setList(list.filter((a) => a._id !== matches._id));
            }

            swal.insertQueueStep({
              type: response.data.success ? "success" : "error",
              title: response.data.success ? "Success" : "Error",
              text: response.data.message,
              confirmButtonText: "Ok!",
            });
          } catch (e) {
            swal.insertQueueStep({
              type: "error",
              title: "Unable to send the report",
              confirmButtonText: "Ok!",
            });
          }
        },
      },
    ]);
  };

  const sendEmail = () => {
    setAjax(true);
    matches.user = { ...matches.user, ...{ email } };
    Axios.post("/api/businessAccount/cases/alert/notify/email", {
      subject,
      input: btoa(unescape(encodeURIComponent(emailText))),
      iAlert: matches,
    })
      .then((d) => {
        setAjax(false);
        setEmailModalData(null);
        setMatches(undefined);
        setList(list.filter((a) => a._id !== matches._id));
      })
      .catch((e) => {
        setAjax(false);
        swal.fire("Could not send email.", "API error", "error");
      });
  };

  const toggleMatchSelect = (k) => {
    matches.matches[k].selected = !matches.matches[k].selected;
    setMatches({ ...matches });
  };

  const selectAllMatches = () => {
    matches.matches.forEach((m) => {
      m.selected = true;
    });
    setMatches({ ...matches });
  };

  const selectNoneMatches = () => {
    matches.matches.forEach((m) => {
      m.selected = false;
    });
    setMatches({ ...matches });
  };

  const sorter = (arr, p, reverse) => {
    if (reverse) {
      return arr.slice(0).sort(function (a, b) {
        return a[p] < b[p] ? 1 : a[p] > b[p] ? -1 : 0;
      });
    }
    return arr.slice(0).sort(function (a, b) {
      return a[p] > b[p] ? 1 : a[p] < b[p] ? -1 : 0;
    });
  };

  const changeSort = (_sortBy) => {
    let _reverse = false;
    if (sortBy === _sortBy) _reverse = !reverse;
    setSortBy(_sortBy);
    setReverse(_reverse);
    setList([...sorter(list, _sortBy, _reverse)]);
  };

  const sortIcons = (w) => {
    if (w === sortBy && reverse) return <FaSortAmountDown size="16" />;

    if (w === sortBy && !reverse) return <FaSortAmountUp size="16" />;
  };

  const eliminate = () => {
    setAjax(true);
    const eliminate = matches.matches
      .filter((m) => m.selected)
      .map((m) => m.custom_id);

    Axios.post("/api/businessAccount/cases/alert/hide/matches", {
      id: selectedAlert.current._id,
      hidden: eliminate,
    })
      .then((d) => {
        matches.matches = matches.matches.filter((m) => !m.selected);
        setMatches({ ...matches });

        list.some((v) => {
          if (v._id === selectedAlert.current._id) {
            v.matches_no = v.matches_no - eliminate.length;
            return true;
          }
          return false;
        });
        setList([...list]);
        setAjax(false);
      })
      .catch((e) => {
        setAjax(false);
        swal.fire("Could not eliminate.", "API error", "error");
      });
  };

  if (loading)
    return (
      <div id="admin-page">
        <img
          src="/img/ajax-loader.gif"
          alt="loadingRequests..."
          className="loader-gif"
        />
        &nbsp;Loading alerts...
      </div>
    );
  return (
    <div id="admin-page">
      <div className={clsx("modal", { show: matches, ajax })}>
        <button className="close" onClick={() => setMatches(undefined)}>
          <MdClose />
        </button>
        <h2>Possible alert matches</h2>
        <div className="selection-buttons">
          <button onClick={() => selectAllMatches()}>Select all</button>
          <button onClick={() => selectNoneMatches()}>Select none</button>
        </div>
        {matches ? (
          <div>
            {matches.matches.map((m, k) => (
              <div key={k} className={m.selected ? "match selected" : "match"}>
                <p>
                  <strong>View:</strong>{" "}
                  <a
                    href={"https://app.myaccident.org/accident/" + m.custom_id}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Accident Link
                  </a>
                </p>
                <p>
                  <strong>Date:</strong> {moment(m.date).format("llll")}
                </p>
                <p>
                  <strong>Address:</strong> {m.location_address}
                </p>
                <p>
                  <strong>CaseID:</strong> {m.caseID}
                </p>
                {m.vehicles
                  ? m.vehicles.map((v, k) => (
                      <p key={k}>
                        <strong>Vehicle {k + 1}:</strong>
                        {v.vehicle_maker +
                          " " +
                          v.vehicle_model_year +
                          " " +
                          v.vehicle_color}
                      </p>
                    ))
                  : null}
                <button type="button" onClick={() => toggleMatchSelect(k)}>
                  Select
                </button>
              </div>
            ))}
          </div>
        ) : null}
        <div className="footer">
          <button onClick={() => setMatches(undefined)} className="outline">
            Cancel
          </button>
          <button onClick={() => setEmailModalData(matches)}>Send Email</button>
          <button onClick={() => sendSMS(matches)}>Send SMS</button>
          <button onClick={() => eliminate()} className="red">
            Eliminate
          </button>
        </div>
      </div>

      <div
        className={clsx([
          "modal",
          "search",
          { show: emailModalData },
          { ajax },
        ])}
      >
        <button className="close" onClick={() => setEmailModalData(null)}>
          <MdClose />
        </button>
        <h2>Send email</h2>
        <p>
          Send email to{" "}
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </p>
        <p>
          Email subject:{" "}
          <input
            type="text"
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
          />
        </p>
        <br />
        <JoditEditor
          value={emailText}
          config={{
            readonly: false, // all options from https://xdsoft.net/jodit/doc/
          }}
          tabIndex={1} // tabIndex of textarea
          onBlur={(newContent) => setEmailText(newContent)} // preferred to use only this option to update the content for performance reasons
          onChange={(newContent) => {}}
        />
        <div className="footer">
          <button onClick={() => setEmailModalData(null)} className="outline">
            Cancel
          </button>
          <button onClick={() => sendEmail()} disabled={!email || !subject}>
            Send Email
          </button>
        </div>
      </div>

      <table>
        <thead>
          <tr>
            <th></th>
            <th>
              <a onClick={() => changeSort("createdAt")}>
                Date Added {sortIcons("createdAt")}
              </a>
            </th>
            <th>Requestor</th>
            <th>Business</th>
            <th>Period</th>
            <th>Vehicles</th>
            <th>
              <a onClick={() => changeSort("matches_no")}>
                Matches {sortIcons("matches_no")}
              </a>
            </th>
          </tr>
        </thead>
        <tbody>
          {list.map((v) => (
            <React.Fragment key={v._id}>
              <tr>
                <td>
                  <a onClick={() => toggleExpand(v)}>
                    {v.expanded ? (
                      <FiMinusCircle size={24} />
                    ) : (
                      <FiPlusCircle size={24} />
                    )}
                  </a>
                </td>
                <td>{moment(v.createdAt).format("llll")}</td>
                <td>
                  {v.user ? v.user.firstName + " " + v.user.lastName : ""}
                </td>
                <td>{businessName(v)}</td>
                <td>
                  {new Date(v.startDate).toLocaleDateString()} -{" "}
                  {new Date(v.endDate).toLocaleDateString()}
                </td>
                <td>
                  {(v.vehicle1 ? v.vehicle1 : "") +
                    (v.vehicle2 ? ", " + v.vehicle2 : "")}
                </td>
                <td>
                  <button onClick={() => loadMatches(v)} className="matches">
                    {v.matches_no || 0}
                  </button>
                </td>
              </tr>
              <tr
                className={clsx("expander", {
                  show: v.expanded,
                })}
              >
                <td colSpan="7">
                  {v.expanded ? <AlertExpander data={v} /> : null}
                </td>
              </tr>
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );
}
