/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import Axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { FiMinusCircle, FiPlusCircle } from "react-icons/fi";
import RetainerStatsGraph from "./StatGraphs/RetainerStatsGraph";

export default function RetainerStats({ users }) {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});
  const [leads, setLeads] = useState([]);

  useEffect(() => {
    const errors = [];
    Axios.get(
      `/api/leads/stats?from=${moment()
        .subtract(90, "days")
        .startOf("day")}&to=${moment().endOf("day")}&justSold=1`
    ).then((d) => {
      const result = {};
      setLeads(d.data.leads);
      d.data.leads.forEach((l) => {
        l.retainer_price = 0;

        if (
          l.lead_level === "sold" &&
          l.accepted &&
          users[l.accepted]?.retainer
        ) {
          if (users[l.accepted]) {
            if (users[l.accepted].retainer) {
              if (
                (users[l.accepted].statePrices &&
                  users[l.accepted].statePrices[l.state] &&
                  users[l.accepted].statePrices[l.state].retainerPrice) ||
                (users[l.accepted].statePrices &&
                  users[l.accepted].statePrices["ALL"] &&
                  users[l.accepted].statePrices["ALL"].retainerPrice)
              )
                l.retainer_price = users[l.accepted].statePrices["ALL"]
                  ? Number(users[l.accepted].statePrices["ALL"].retainerPrice)
                  : Number(
                      users[l.accepted]?.statePrices[l.state].retainerPrice
                    );
              else
                errors.push(
                  `Retainer price for business user ${
                    users[l.accepted].businessName
                  } and state ${l.state} not found`
                );
            }
          } else errors.push(`Business user not found ${l.accepted}`);

          if (!result.morganConnect)
            result.morganConnect = {
              "7_day_total": 0,
              "7_day_retainer": 0,
              "7_day_lead_revenue": 0,
              "14_day_total": 0,
              "14_day_retainer": 0,
              "14_day_lead_revenue": 0,
              "30_day_total": 0,
              "30_day_retainer": 0,
              "30_day_lead_revenue": 0,
              "60_day_total": 0,
              "60_day_retainer": 0,
              "60_day_lead_revenue": 0,
              "90_day_total": 0,
              "90_day_retainer": 0,
              "90_day_lead_revenue": 0,
              byState: {},
            };

          if (!result[l.accepted])
            result[l.accepted] = {
              "7_day_total": 0,
              "7_day_retainer": 0,
              "7_day_lead_revenue": 0,
              "14_day_total": 0,
              "14_day_retainer": 0,
              "14_day_lead_revenue": 0,
              "30_day_total": 0,
              "30_day_retainer": 0,
              "30_day_lead_revenue": 0,
              "60_day_total": 0,
              "60_day_retainer": 0,
              "60_day_lead_revenue": 0,
              "90_day_total": 0,
              "90_day_retainer": 0,
              "90_day_lead_revenue": 0,
              byState: {},
            };

          if (!result[l.accepted].byState[l.state]) {
            result[l.accepted].byState[l.state] = {};
            result[l.accepted].byState[l.state]["7_day_total"] = 0;
            result[l.accepted].byState[l.state]["7_day_retainer"] = 0;
            result[l.accepted].byState[l.state]["7_day_lead_revenue"] = 0;
            result[l.accepted].byState[l.state]["14_day_total"] = 0;
            result[l.accepted].byState[l.state]["14_day_retainer"] = 0;
            result[l.accepted].byState[l.state]["14_day_lead_revenue"] = 0;
            result[l.accepted].byState[l.state]["30_day_total"] = 0;
            result[l.accepted].byState[l.state]["30_day_retainer"] = 0;
            result[l.accepted].byState[l.state]["30_day_lead_revenue"] = 0;
            result[l.accepted].byState[l.state]["60_day_total"] = 0;
            result[l.accepted].byState[l.state]["60_day_retainer"] = 0;
            result[l.accepted].byState[l.state]["60_day_lead_revenue"] = 0;
            result[l.accepted].byState[l.state]["90_day_total"] = 0;
            result[l.accepted].byState[l.state]["90_day_retainer"] = 0;
            result[l.accepted].byState[l.state]["90_day_lead_revenue"] = 0;
          }

          if (!result.morganConnect.byState[l.state]) {
            result.morganConnect.byState[l.state] = {};
            result.morganConnect.byState[l.state]["7_day_total"] = 0;
            result.morganConnect.byState[l.state]["7_day_retainer"] = 0;
            result.morganConnect.byState[l.state]["7_day_lead_revenue"] = 0;
            result.morganConnect.byState[l.state]["14_day_total"] = 0;
            result.morganConnect.byState[l.state]["14_day_retainer"] = 0;
            result.morganConnect.byState[l.state]["14_day_lead_revenue"] = 0;
            result.morganConnect.byState[l.state]["30_day_total"] = 0;
            result.morganConnect.byState[l.state]["30_day_retainer"] = 0;
            result.morganConnect.byState[l.state]["30_day_lead_revenue"] = 0;
            result.morganConnect.byState[l.state]["60_day_total"] = 0;
            result.morganConnect.byState[l.state]["60_day_retainer"] = 0;
            result.morganConnect.byState[l.state]["60_day_lead_revenue"] = 0;
            result.morganConnect.byState[l.state]["90_day_total"] = 0;
            result.morganConnect.byState[l.state]["90_day_retainer"] = 0;
            result.morganConnect.byState[l.state]["90_day_lead_revenue"] = 0;
          }

          const dur = moment
            .duration({ to: moment(), from: l.createdAt })
            .asDays();

          if (dur <= 7) {
            result[l.accepted]["7_day_total"]++;
            result[l.accepted].byState[l.state]["7_day_total"]++;

            if (l.retainerSuccess) {
              result[l.accepted]["7_day_retainer"]++;
              result[l.accepted].byState[l.state]["7_day_retainer"]++;

              result[l.accepted]["7_day_lead_revenue"] =
                result[l.accepted]["7_day_lead_revenue"] + l.retainer_price;

              result[l.accepted].byState[l.state]["7_day_lead_revenue"] =
                result[l.accepted].byState[l.state]["7_day_lead_revenue"] +
                l.retainer_price;
            }

            if (users[l.accepted].morganConnect) {
              result.morganConnect["7_day_total"]++;
              result.morganConnect.byState[l.state]["7_day_total"]++;

              if (l.retainerSuccess) {
                result.morganConnect["7_day_retainer"]++;
                result.morganConnect.byState[l.state]["7_day_retainer"]++;

                result.morganConnect["7_day_lead_revenue"] =
                  result.morganConnect["7_day_lead_revenue"] + l.retainer_price;

                result.morganConnect.byState[l.state]["7_day_lead_revenue"] =
                  result.morganConnect.byState[l.state]["7_day_lead_revenue"] +
                  l.retainer_price;
              }
            }
          }
          if (dur <= 14) {
            result[l.accepted]["14_day_total"]++;
            result[l.accepted].byState[l.state]["14_day_total"]++;

            if (l.retainerSuccess) {
              result[l.accepted]["14_day_retainer"]++;
              result[l.accepted].byState[l.state]["14_day_retainer"]++;

              result[l.accepted]["14_day_lead_revenue"] =
                result[l.accepted]["14_day_lead_revenue"] + l.retainer_price;

              result[l.accepted].byState[l.state]["14_day_lead_revenue"] =
                result[l.accepted].byState[l.state]["14_day_lead_revenue"] +
                l.retainer_price;
            }

            if (users[l.accepted].morganConnect) {
              result.morganConnect["14_day_total"]++;
              result.morganConnect.byState[l.state]["14_day_total"]++;

              if (l.retainerSuccess) {
                result.morganConnect["14_day_retainer"]++;
                result.morganConnect.byState[l.state]["14_day_retainer"]++;

                result.morganConnect["14_day_lead_revenue"] =
                  result.morganConnect["14_day_lead_revenue"] +
                  l.retainer_price;

                result.morganConnect.byState[l.state]["14_day_lead_revenue"] =
                  result.morganConnect.byState[l.state]["14_day_lead_revenue"] +
                  l.retainer_price;
              }
            }
          }

          if (dur <= 30) {
            result[l.accepted]["30_day_total"]++;
            result[l.accepted].byState[l.state]["30_day_total"]++;

            if (l.retainerSuccess) {
              result[l.accepted]["30_day_retainer"]++;
              result[l.accepted].byState[l.state]["30_day_retainer"]++;

              result[l.accepted]["30_day_lead_revenue"] =
                result[l.accepted]["30_day_lead_revenue"] + l.retainer_price;

              result[l.accepted].byState[l.state]["30_day_lead_revenue"] =
                result[l.accepted].byState[l.state]["30_day_lead_revenue"] +
                l.retainer_price;
            }

            if (users[l.accepted].morganConnect) {
              result.morganConnect["30_day_total"]++;
              result.morganConnect.byState[l.state]["30_day_total"]++;

              if (l.retainerSuccess) {
                result.morganConnect["30_day_retainer"]++;
                result.morganConnect.byState[l.state]["30_day_retainer"]++;

                result.morganConnect["30_day_lead_revenue"] =
                  result.morganConnect["30_day_lead_revenue"] +
                  l.retainer_price;

                result.morganConnect.byState[l.state]["30_day_lead_revenue"] =
                  result.morganConnect.byState[l.state]["30_day_lead_revenue"] +
                  l.retainer_price;
              }
            }
          }
          if (dur <= 60) {
            result[l.accepted]["60_day_total"]++;
            result[l.accepted].byState[l.state]["60_day_total"]++;

            if (l.retainerSuccess) {
              result[l.accepted]["60_day_retainer"]++;
              result[l.accepted].byState[l.state]["60_day_retainer"]++;

              result[l.accepted]["60_day_lead_revenue"] =
                result[l.accepted]["60_day_lead_revenue"] + l.retainer_price;

              result[l.accepted].byState[l.state]["60_day_lead_revenue"] =
                result[l.accepted].byState[l.state]["60_day_lead_revenue"] +
                l.retainer_price;
            }

            if (users[l.accepted].morganConnect) {
              result.morganConnect["60_day_total"]++;
              result.morganConnect.byState[l.state]["60_day_total"]++;

              if (l.retainerSuccess) {
                result.morganConnect["60_day_retainer"]++;
                result.morganConnect.byState[l.state]["60_day_retainer"]++;

                result.morganConnect["60_day_lead_revenue"] =
                  result.morganConnect["60_day_lead_revenue"] +
                  l.retainer_price;

                result.morganConnect.byState[l.state]["60_day_lead_revenue"] =
                  result.morganConnect.byState[l.state]["60_day_lead_revenue"] +
                  l.retainer_price;
              }
            }
          }

          result[l.accepted]["90_day_total"]++;
          result[l.accepted].byState[l.state]["90_day_total"]++;

          if (l.retainerSuccess) {
            result[l.accepted]["90_day_retainer"]++;

            result[l.accepted].byState[l.state]["90_day_retainer"]++;

            result[l.accepted]["90_day_lead_revenue"] =
              result[l.accepted]["90_day_lead_revenue"] + l.retainer_price;

            result[l.accepted].byState[l.state]["90_day_lead_revenue"] =
              result[l.accepted].byState[l.state]["90_day_lead_revenue"] +
              l.retainer_price;
          }

          if (users[l.accepted].morganConnect) {
            result.morganConnect["90_day_total"]++;
            result.morganConnect.byState[l.state]["90_day_total"]++;

            if (l.retainerSuccess) {
              result.morganConnect["90_day_retainer"]++;
              result.morganConnect.byState[l.state]["90_day_retainer"]++;

              result.morganConnect["90_day_lead_revenue"] =
                result.morganConnect["90_day_lead_revenue"] + l.retainer_price;

              result.morganConnect.byState[l.state]["90_day_lead_revenue"] =
                result.morganConnect.byState[l.state]["90_day_lead_revenue"] +
                l.retainer_price;
            }
          }
        }
      });
      setData(result);
      setLoading(false);
      if (errors.length) {
        const uniq = [...new Set(errors)];
        Swal.fire({
          title: "Stats might be calculated wrong because:",
          html: `<div style="overflow:auto; max-height:500px; font-size:13px">${uniq.join(
            "<br />"
          )}</div>`,
          type: "warning",
        });
      }
    });
  }, []);

  const toggleMorgan = () => {
    data.morganConnect.expanded = !data.morganConnect.expanded;
    setData({ ...data });
  };

  const toggleStates = (w) => {
    data[w].states = !data[w].states;
    setData({ ...data });
  };

  const renderResults = (input) => {
    return (
      <>
        <td style={{ textAlign: "center" }}>
          <strong>
            {(
              (input["7_day_retainer"] / input["7_day_total"]) * 100 || 0
            ).toFixed(2)}
            %
          </strong>
          <div style={{ opacity: 0.5 }}>
            total: {input["7_day_total"]}
            <br />
            retained: {input["7_day_retainer"]}
            <br />
            total: ${input["7_day_lead_revenue"]}
            <br />
            average per lead:$
            {(input["7_day_lead_revenue"] / input["7_day_total"] || 0).toFixed(
              2
            )}
          </div>
        </td>
        <td style={{ textAlign: "center" }}>
          <strong>
            {(
              (input["14_day_retainer"] / input["14_day_total"]) * 100 || 0
            ).toFixed(2)}
            %
          </strong>
          <div style={{ opacity: 0.5 }}>
            total: {input["14_day_total"]}
            <br />
            retained: {input["14_day_retainer"]}
            <br />
            total: ${input["14_day_lead_revenue"]}
            <br />
            average per lead:$
            {(
              input["14_day_lead_revenue"] / input["14_day_total"] || 0
            ).toFixed(2)}
          </div>
        </td>
        <td style={{ textAlign: "center" }}>
          <strong>
            {(
              (input["30_day_retainer"] / input["30_day_total"]) * 100 || 0
            ).toFixed(2)}
            %
          </strong>
          <div style={{ opacity: 0.5 }}>
            total: {input["30_day_total"]}
            <br />
            retained: {input["30_day_retainer"]}
            <br />
            total: ${input["30_day_lead_revenue"]}
            <br />
            average per lead:$
            {(
              input["30_day_lead_revenue"] / input["30_day_total"] || 0
            ).toFixed(2)}
          </div>
        </td>
        <td style={{ textAlign: "center" }}>
          <strong>
            {(
              (input["60_day_retainer"] / input["60_day_total"]) * 100 || 0
            ).toFixed(2)}
            %
          </strong>
          <div style={{ opacity: 0.5 }}>
            total: {input["60_day_total"]}
            <br />
            retained: {input["60_day_retainer"]}
            <br />
            total: ${input["60_day_lead_revenue"]}
            <br />
            average per lead:$
            {(
              input["60_day_lead_revenue"] / input["60_day_total"] || 0
            ).toFixed(2)}
          </div>
        </td>
        <td style={{ textAlign: "center" }}>
          <strong>
            {(
              (input["90_day_retainer"] / input["90_day_total"]) * 100 || 0
            ).toFixed(2)}
            %
          </strong>
          <div style={{ opacity: 0.5 }}>
            total: {input["90_day_total"]}
            <br />
            retained: {input["90_day_retainer"]}
            <br />
            total: ${input["90_day_lead_revenue"]}
            <br />
            average per lead:$
            {(
              input["90_day_lead_revenue"] / input["90_day_total"] || 0
            ).toFixed(2)}
          </div>
        </td>
      </>
    );
  };

  if (loading)
    return (
      <div>
        <h2>Average Conversion To Retainer Rate</h2>
        <img
          src="/img/ajax-loader.gif"
          alt="loading stats..."
          className="loader-gif"
        />
        &nbsp;Loading stats...
      </div>
    );

  return (
    <div>
      <h2>Average Conversion To Retainer Rate</h2>
      <table>
        <thead>
          <tr>
            <th>Affiliate User</th>
            <th style={{ textAlign: "center" }}>7 day</th>
            <th style={{ textAlign: "center" }}>14 day</th>
            <th style={{ textAlign: "center" }}>30 day</th>
            <th style={{ textAlign: "center" }}>60 day</th>
            <th style={{ textAlign: "center" }}>90 day</th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(data).map((u, i) => (
            <React.Fragment key={u}>
              {u !== "morganConnect" && !users[u].morganConnect ? (
                <>
                  <tr>
                    <td>
                      <strong>
                        {users[u]?.businessName || `Unknown user ${u}`}
                      </strong>
                      <div style={{ opacity: 0.5 }}>{users[u]?.email}</div>
                      <br />
                      <a
                        onClick={() => toggleStates(u)}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: 10,
                        }}
                        data-testid={`toggle-${i}`}
                      >
                        {data[u].states ? (
                          <FiMinusCircle size={24} />
                        ) : (
                          <FiPlusCircle size={24} />
                        )}
                        <strong>Show states</strong>
                      </a>
                    </td>
                    {renderResults(data[u])}
                  </tr>
                  {data[u].states ? (
                    <>
                      {Object.keys(data[u].byState).map((s) => (
                        <tr key={s}>
                          <td style={{ paddingLeft: 50 }}>
                            <strong>{s}</strong>
                          </td>
                          {renderResults(data[u].byState[s])}
                        </tr>
                      ))}
                    </>
                  ) : null}
                </>
              ) : null}
              {u === "morganConnect" ? (
                <>
                  <tr>
                    <td>
                      <a
                        onClick={() => toggleMorgan()}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: 10,
                        }}
                        data-testid={`toggle-morgan-${i}`}
                      >
                        {data.morganConnect.expanded ? (
                          <FiMinusCircle size={24} />
                        ) : (
                          <FiPlusCircle size={24} />
                        )}
                        <strong>Morgan Connect</strong>
                      </a>
                      <br />
                      <a
                        onClick={() => toggleStates("morganConnect")}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: 10,
                        }}
                        data-testid={`toggle-states-morgan-${i}`}
                      >
                        {data.morganConnect.states ? (
                          <FiMinusCircle size={24} />
                        ) : (
                          <FiPlusCircle size={24} />
                        )}
                        <strong>Show states</strong>
                      </a>
                    </td>
                    {renderResults(data[u])}
                  </tr>
                  {data[u].states ? (
                    <>
                      {Object.keys(data[u].byState).map((s) => (
                        <tr key={s}>
                          <td style={{ paddingLeft: 50 }}>
                            <strong>{s}</strong>
                          </td>
                          {renderResults(data[u].byState[s])}
                        </tr>
                      ))}
                    </>
                  ) : null}
                  {data.morganConnect.expanded ? (
                    <>
                      {Object.keys(data).map((uu) => (
                        <React.Fragment key={uu}>
                          {users[uu]?.morganConnect ? (
                            <tr>
                              <td style={{ paddingLeft: 50 }}>
                                <strong>
                                  {users[uu]?.businessName ||
                                    `Unknown user ${uu}`}
                                </strong>
                                <div style={{ opacity: 0.5 }}>
                                  {users[uu]?.email}
                                </div>
                                <a
                                  onClick={() => toggleStates(uu)}
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: 10,
                                  }}
                                >
                                  {data[uu].states ? (
                                    <FiMinusCircle size={24} />
                                  ) : (
                                    <FiPlusCircle size={24} />
                                  )}
                                  <strong>Show states</strong>
                                </a>
                              </td>
                              {renderResults(data[uu])}
                            </tr>
                          ) : null}
                          {data[uu].states && uu !== "morganConnect" ? (
                            <>
                              {Object.keys(data[uu].byState).map((s) => (
                                <tr key={s}>
                                  <td style={{ paddingLeft: 130 }}>
                                    <strong>{s}</strong>
                                  </td>
                                  {renderResults(data[uu].byState[s])}
                                </tr>
                              ))}
                            </>
                          ) : null}
                        </React.Fragment>
                      ))}
                    </>
                  ) : null}
                </>
              ) : null}
            </React.Fragment>
          ))}
        </tbody>
      </table>
      <br />
      <br />
      <h2>Graph</h2>{" "}
      {process.env.JEST_WORKER_ID !== undefined ? null : (
        <RetainerStatsGraph leads={leads} users={users} />
      )}
    </div>
  );
}
