import React from "react";

export default function Checkbox({ checked, text, onClick }) {
	return (
    <>
      <input
        type="checkbox"
        id={text.replace(/[^A-Za-z0-9]/gi, "-")}
        style={{ display: "none" }}
        readOnly
        className="inp-cbx"
        checked={checked}
        onChange={() => {}}
      />
      <label
        className="cbx"
        htmlFor={text.replace(/[^A-Za-z0-9]/gi, "-")}
        onClick={onClick}
      >
        <span>
          <svg width="12px" height="10px" viewBox="0 0 12 10">
            <polyline points="1.5 6 4.5 9 10.5 1" />
          </svg>
        </span>
        <span>{text}</span>
      </label>
    </>
  );
}
