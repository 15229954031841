import React, { useState, useEffect } from "react";
import Axios from "axios";
import swal from "sweetalert2";

export default function Invoices() {
	useEffect(() => {
		Axios.get("/api/billing/user")
			.then(d => {
				setLoading(false);
			})
			.catch(e => {
				swal.fire("Error", "Unknown server happened.", "error");
			});
	}, []);
	const [loading, setLoading] = useState(true);
	if (loading)
		return (
			<div>
				<img
					src="/img/ajax-loader.gif"
					alt="loading alerts..."
					className="loader-gif"
				/>
				&nbsp;Loading Invoices...
			</div>
		);

	return <div>Invoices</div>;
}
