import React from "react";
import PlanChooser from "../components/PlanChooser";

export default function AdvertisementLocked({ user }) {
	return (
		<div id="admin-page">
			<h1>Advertisement not available</h1>
			Advertising is not available on the Basic Plan. Upgrade to promote your
			company on MyAccident.org.
			<br />
			<br />
			<PlanChooser plan={user.sub_plan ? user.sub_plan.nickname : user.plan} />
		</div>
	);
}
