/* eslint-disable react-hooks/exhaustive-deps */
// import clsx from "clsx";
import React, { useEffect, useState } from "react";
// import AffiliateLeads from "./AffiliateLeads";
// import AffiliatePaymentHistory from "./AffiliatePaymentHistory";
// import AffiliatePingPost from "./AffiliatePingPost";
import AffiliateStats from "../components/AffiliateStats";
import Axios from "axios";

export default function AffiliateDashboard({ user }) {
  const [users, setUsers] = useState({});
  const [loading, setLoading] = useState(true);

  const loadUsers = () => {
    setLoading(true);
    Axios.get("/api/businessAccount/users/all").then((d) => {
      d.data.users.forEach((u) => {
        users[u._id] = u;
      });
      setUsers({ ...users });
      setLoading(false);
    });
  };

  useEffect(() => {
    loadUsers();
  }, []);

  if (loading)
    return (
      <div id="admin-page">
        <h2>Affiliate Conversion %</h2>
        <img
          src="/img/ajax-loader.gif"
          alt="loading stats..."
          className="loader-gif"
        />
        &nbsp;Loading stats...
      </div>
    );
  return (
    <div id="admin-page">
      <AffiliateStats users={users} single={user._id} />
    </div>
  );

  // const [tab, setTab] = useState(0);
  // return (
  //   <div id="admin-page">
  //     <div id="tabs">
  //       <button
  //         className={clsx({ active: tab === 0 })}
  //         onClick={() => setTab(0)}
  //       >
  //         Leads
  //       </button>
  //       <button
  //         className={clsx({ active: tab === 1 })}
  //         onClick={() => setTab(1)}
  //       >
  //         History
  //       </button>
  //       <button
  //         className={clsx({ active: tab === 2 })}
  //         onClick={() => setTab(2)}
  //       >
  //         PING/POST API
  //       </button>
  //     </div>

  //     <div className={clsx("tab", { show: tab === 0 })}>
  //       {tab === 0 ? <AffiliateLeads user={user} /> : null}
  //     </div>
  //     <div className={clsx("tab", { show: tab === 1 })}>
  //       {tab === 1 ? <AffiliatePaymentHistory user={user} /> : null}
  //     </div>
  //     <div className={clsx("tab", { show: tab === 2 })}>
  //       {tab === 2 ? <AffiliatePingPost user={user} /> : null}
  //     </div>
  //   </div>
  // );
}
