import React, { useEffect } from "react";

export default function LeadTypes({ fromPlans }) {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	return (
		<div id={fromPlans ? "" : "admin-page"}>
			<div id="lead-types-page">
				<h1>Introduction</h1>
				<p>
					This document is intended for use by Law Firm’s who are subscribed to
					the MyAccident.org business service. As a subscribed business user you
					can opt in to receive notifications about leads that are generated on
					the MyAccident.org website by its users, who have requested to be
					contacted by a law firm regarding an accident they were involved in.
					There are 3 ways in which the user may request to be contacted. The
					following as an explanation of each type of lead and some
					recommendations regarding outreach.
				</p>
				<br />
				<br />
				<h2>1. Car Lead</h2>
				<img
					src="/img/lead-types-screens/car-lead-1.png"
					style={{ width: "40%", float: "right" }}
					alt="car lead"
				/>
				<p>
					This lead is generated when a user clicks on “Compensation Calculator”
					on a vehicle on our website. Our system automatically identifies
					accidents that contain injuries where the other driver is at fault.
					The user provides their Name, email and their cell phone number.
				</p>
				<div style={{ clear: "both" }} />
				<img
					src="/img/lead-types-screens/car-lead-2.png"
					style={{ width: "40%", float: "right" }}
					alt="car lead"
				/>
				<p>These leads contain the following information:</p>
				<ol>
					<li>Contact information</li>
					<li>User has confirmed they were hurt</li>
					<li>They were not at fault</li>
					<li>Insurance Status of all drivers</li>
					<li>MyAccident Report Link</li>
					<li>Client PDF report</li>
				</ol>
				<br />
				<br />
				<div style={{ clear: "both" }} />
				<h2>2. Download Lead</h2>
				<img
					src="/img/lead-types-screens/download-lead-1.png"
					style={{ width: "40%", float: "right" }}
					alt="download lead"
				/>
				<p>
					This lead is generated when a user requests a free legal consultation
					regarding their accident. The user has found their accident report on
					our site and during the download process they are asked “Would you
					like a free case evaluation?” Our system asks them which vehicle the
					user was driving or a passenger of. Based on the user's response, a
					message will appear alerting them to the fact that they may be
					entitled to compensation.
				</p>
				<div style={{ clear: "both" }} />
				<br />
				<img
					src="/img/lead-types-screens/download-lead-2.png"
					style={{ width: "40%", float: "right" }}
					alt="download lead"
				/>
				<p>These leads contain the following information:</p>
				<ol>
					<li>Contact information</li>
					<li>User has confirmed they were hurt</li>
					<li>They were not at fault</li>
					<li>Insurance Status of all drivers</li>
					<li>MyAccident Report Link</li>
					<li>Client PDF report</li>
				</ol>

				<br />
				<br />
				<div style={{ clear: "both" }} />
				<h2>3. Alert Lead</h2>
				<img
					src="/img/lead-types-screens/alert-lead-1.png"
					style={{ width: "40%", float: "right" }}
					alt="alert lead"
				/>
				<p>
					When a user can not find their accident report, often it is because
					the police have not yet filed the report. MyAccident sets up an alert
					for them which is a computer monitoring system. As soon as it finds a
					match it automatically notifies the user that the report has been
					found and send them a link. During the alert set up process we ask the
					user if they were Injured and IF they would like to get a free legal
					consultation.
				</p>
				<div style={{ clear: "both" }} />
				<br />
				<img
					src="/img/lead-types-screens/alert-lead-2.png"
					style={{ width: "40%", float: "right" }}
					alt="alert lead"
				/>
				<p>These leads contain the following information:</p>
				<ol>
					<li>Contact information</li>
					<li>Injury Status</li>
				</ol>
				<div style={{ clear: "both" }} />
				<br />
				<br />
				<h2>4. Email Leads</h2>
				<p>
					If a user does not elect to have a legal consultation they MUST
					consent to allow us to share their information for the purpose of
					being contacted. These leads may be contacted using approved email
					communications.
				</p>
			</div>
		</div>
	);
}
