/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import swal from "sweetalert2";
import Axios from "axios";

const PaymentCheck = ({ history, setPayment, user }) => {
	useEffect(() => {
		if (user.active && user.role.id !== 10) {
			Axios.get("/api/stripe/customer/retrieve").then(d => {
				if (!d.data.success) {
					setPayment(false);
					swal
						.fire({
							title: "Payment method not set",
							text:
								"You currently don't have any payment methods set in your account. You can explore around but you can not order reports or add new users until you set up your payment method.",
							type: "warning",
							showCancelButton: true,
							cancelButtonText: "Later",
							confirmButtonText: "Set Up now",
							reverseButtons: true,
							allowOutsideClick: false
						})
						.then(v => {
							if (v.value) {
								window.lastAccountTab = 1;
								history.push("/account");
							}
						});
				} else setPayment(true);
			});
		}
		if (user.active && user.role.id === 10) setPayment(true);
	}, [user]);

	return <div></div>;
};
export default withRouter(PaymentCheck);
