/* eslint-disable react/jsx-no-target-blank */
import React from "react";
export default function LicenceAgreement() {
	return (
		<div className="Agreement">
			<h1 className="agreement-title">
				MYACCIDENT.ORG PBC
			</h1>
			<h3>
				BUSINESS USER AGREEMENT - Version 1.0 August 14th 2019
			</h3>
			<p>
				Welcome to MyAccident.org, the online accident report website owned by MyAccidnet.org PBC, a registered Delaware company located at {' '}
				<span className="white-text">
					6060 N Central Expy Suite 500, Dallas, TX 75202.
				</span>
				{' '}
				We are excited to have you as a user of our product and services. The following terms and conditions (collectively, these &#39;Terms of Service&#39;) apply to your use of MyAccident.org
				including any applicable free trials, any content, functionality and services offered on or via MyAccident.org (the &#39;Service&#39;). We want to keep our relationship with you as lean and
				informal as possible, but please read the Terms of Service carefully before you start using MyAccident.org, because by signing up to the Service and completing your registration you accept
				and agree to be bound and abide by these Terms of Service. Should you disagree with any of the provisions herein, you must not use the Service, but please let us know at{" "}
				{' '} <a href="mailto:customersuccess@myaccident.org">
					customersuccess@myaccident.org
				</a>
				{' '}
				We&#39;ll be happy to hear your comments and suggestions.
			</p>
			<h3 id="h.q6g6fugg8q01">
				Changes to the Terms of Service and the Service
			</h3>
			<p>
				MyAccidnet.org PBC 
				reserves the right to update the Service and these Terms of Service from time to time, at our discretion and without notice. Nevertheless, this document is public on the MyAccident.org
				website (&lsquo;Website&rsquo;), and you will be able to track the changes for any new version. Your continued use of the Service following the publishing of updated Terms of Service means
				that you accept and agree to the changes.
			</p>
			<p>
				These Terms of Service were last updated on August 11th, 2019. They are effective between you and MyAccident.org PBC as of the date you accepted them (&lsquo;Effective Date&rsquo;).
			</p>
			<h3 id="h.oq128tl5iekz">
				Accessing the Service, Security and Privacy
			</h3>
			<p>
				We&#39;re working hard on improving MyAccident.org, but we can&#39;t guarantee that the Service will be up and running 24/7. We also reserve the right to suspend or restrict access to some
				features to users, including in the case of system failure, maintenance, repair or reasons beyond our control. In any case, we will not be liable if for any reason all or any part of the
				Service is unavailable at any time or for any period.
			</p>
			<p>
				To access the Service you have to register by entering your company information, email address and choosing a password as part of our security procedures. You must treat such information as
				confidential, not disclosing it to any third party. There is a password reset procedure in case you forget your password, but please notify us of any breach of security. We highly recommend
				to choose a strong password (e.g. &#39;itslongWITHCAPSand99numbers!!&#39;) and you log out from your account at the end of every session. We will not be responsible for any loss or
				disclosure of your data resulting from your use of the Service, including from your failure to adequately secure your log-in credentials.
			</p>
			<p>
				It is a condition of your use of the Service that all the information you provide on the Service is correct, current and complete. In the future, you may be asked to provide certain
				registration details or other information. As is common for online services, we reserve the right to disable any user account, at any time in our sole discretion for any or no reason,
				including, if in our opinion you have failed to comply with any provision of these Terms of Service.
			</p>
			<p>
				You shall use the Service for your internal business purposes as contemplated by these Terms of Service and shall not: (i) tamper with the security of the Service or with the accounts of any
				of our other customers, (ii) log into a server or account that you are not authorised to access, (iii) attempt to probe, scan or test the vulnerability of the Service or to breach the
				security or authentication measures without proper authorization; (iv) render any part of the Service unusable; (v) lease, distribute, license, sell or otherwise commercially exploit the
				Service or make the Service available to a third party other than as contemplated in these Terms of Service; (vi) use the Service for timesharing or service bureau purposes or otherwise for
				the benefit of a third party; or (vii) provide to third parties any evaluation or Free Trial (as defined below) version of the Service without our prior written consent.
			</p>
			<p>
				In addition, you agree to comply with all applicable laws and regulations in your use of the Service and that in particular you abide by all laws relating to the access and use of the
				provided by the Service.
			</p>
			<p>
				You specifically agree to abide by Texas Transport Code:
			</p>
			<p>
				Texas Transportation Code (TTC) &sect;550.062 requires a law enforcement officer who investigates a crash and completes a Texas Peace Officer&#39;s Report (CR3) to submit the report to the
				Texas Department of Transportation (TxDOT) not later than the 10th day after the date of the crash. (TTC) &sect;550.065 (c)(4) limits the release of a crash report to any person directly
				concerned in the accident or having a proper interest therein. In order to ensure compliance with TTC &sect;550.065 (c)(4), TxDOT requires that a requestor of a crash report certify how they
				meet the requirements of &sect;550.065 (c)(4) before they may obtain a non-redacted crash report.
			</p>
			<p>
				We use SSL encrypted browsing for all logged-in users, but we cannot guarantee that all use will be secure. We also do not guarantee that the Service or any content provided on the Service
				is error free.
			</p>
			<p>
				We take the protection of your personal data very seriously. For information on how we use your personal data please see our {' '}
				<a 
					target="_blank" 
					rel="noopener noreferrer"	
					href="https://myaccident.org/legal/privacy-policy"
				>
					Privacy Policy.
				</a>
			</p>
			<p>
				In providing the Service My Accident.org uses third parties to process personal data, such as credit card data, on your behalf and does not store this data at rest (?) in its infrastructure.
			</p>
			<h3 id="h.rxmq6e5ywg36">
				Free Trial
			</h3>
			<p>
				MyAccident.org PBC
				may make all or part of the Service available to you on a trial basis free of charge (&lsquo;Free Trial&rsquo;). The Free Trial shall begin when you submit a registration to us and shall
				terminate on the earlier of (i) the Free Trial expiration date specified by us upon receiving your registration, or (ii) the date you subscribe to a MyAccident.org Paid service. The Service
				is provided &ldquo;as-is&rdquo; and without warranty of any kind during the Free Trial. Your data you enter during the Free Trial will be permanently deleted within 6 months following the
				end of the Free Trial.
			</p>
			<h3 id="h.jaly18unfzp3">
				Purchase of the MyAccident.org Service
			</h3>
			<p>
				If you agree to pay the fee for access to one of the MyAccident.org subscriptions (&lsquo;MyAccident.org Business Service&rsquo;), the applicable non refundable fee will be charged by
				MyAccident.org to the company or other corporate entity designated by you in accordance with the payment method you have chosen for your purchase, in advance either as a one off annual
				payment or on a monthly basis, depending on your chosen subscription. If you are paying by credit card, by designating a card to be billed, you confirm that you are authorized to make such
				purchase and that you are the holder of such card (i.e. that the card is issued in your name). All prices stated on the Website are inclusive of any applicable sales taxes and fees.
				MyAccident.org accepts a variety of different payment methods, so please check the Website for the best way for you to pay.
			</p>
			<p>
				We will make the Service available to you during the term of your subscription to the MyAccident.org service. You agree that your purchase of the MyAccident.org Business Service is neither
				contingent upon the delivery of any future functionality or features nor dependent upon any oral or written public comments made by us with respect to future functionality or features.
			</p>
			<p>
				If you subscribe you may give additional users access to the Service for an additional fee. In the future MyAccident.org will allow plans which will allow you to add users up to the number
				of permitted users specified in the applicable MyAccident.org Business Service (&lsquo;Users&rsquo;). The Users may include you and your employees, consultants, contractors and agents. You
				must ensure that all Users comply with these Terms of Service and you are responsible for such compliance. If you exceed the number of Users permitted by your MyAccident.org Service, you
				will be in breach of these Terms of Service and we will have the right to terminate or suspend your access to the Service or pursue any other remedy available to us.
			</p>
			<h3 id="h.rqc67pytgj58">
				Prices
			</h3>
			<p>
				MyAccident.org
				&nbsp;may change the price for the MyAccident.org Business Service from time to time. In respect of the MyAccidnet.org Business Service, such changed price will take effect after the expiry
				of the then current paid for period (i.e. the term that you have already paid for). Any price change will be communicated to you at least 14 days in advance so that you have an opportunity
				to elect to not renew. If you do not wish to be bound by such changed price relating to your MyAccident.org Business Service you may terminate your subscription of your MyAccident.org
				Business Service in accordance with the Section of these Terms of Service entitled &ldquo;Term and termination&rdquo; below. Your continued use of the MyAccident.org Business Service after
				the communication of such price change to you constitutes an acceptance of such new price.
			</p>
			<h3 id="h.hkrc868g51v7">
				Automatic subscription renewal
			</h3>
			<p>
				Your subscription to the MyAccident.org Business Service will automatically renew at the end of each subscription term unless you terminate your subscription prior to the end of such
				subscription term in accordance with the Section entitled &ldquo;Term and termination&rdquo;. At the time of renewal the payment method you have designated to be charged for the purchase of
				the MyAccident.org Business Service will automatically be charged our then current fees for the applicable subscription.Non-use of Myaccident.org does not constitute an implied cancellation.{" "}
			</p>
			<h3 id="h.oewmbs77qffz">
				Disclaimer of Warranties, Limitations of Liability and Indemnification.
			</h3>
			<p>
				Your use of the Service is at your sole risk. The Service is provided &#39;as is&#39; and &#39;as available&#39;. We disclaim all warranties of any kind, express or implied, including,
				without limitation, the warranties of merchantability, fitness for a particular purpose and non-infringement. To the maximum extent permitted by law, we are not liable for damages, direct or
				consequential, resulting from your use of the Service, and you agree to defend, indemnify and hold us harmless from any claims, losses, liability costs and expenses (including but not
				limited to attorney&#39;s fees) arising from your violation of any third-party&#39;s rights to the extent that such violation arises from your use of the Service Because the Service is not
				error or bug free, you agree that you will use it carefully and avoid using it in ways which might result in any loss of your or any third party&#39;s property or information.
			</p>
			<p>
				Nothing in this agreement shall exclude either party&rsquo;s liability for death or personal injury caused by its negligence or for fraud or fraudulent misrepresentation.
			</p>
			<p>
				MyAccident.org &nbsp;is committed to excellence and providing all of its visitors and users the best service possible. However, service and materials are provided by Myaccident.org are on an
				&quot;as is&quot; basis and Myaccident.org expressly disclaims any and all warranties, express or implied, including without limitation warranties of merchantability and fitness for a
				particular purpose, with respect to the service or any materials and products. In no event shall Myaccident.org be liable for any direct, indirect, incidental, punitive, or consequential
				damages of any kind whatsoever with respect to the service, the materials and the products.
			</p>
			<p>
				Solicitation and Barratry
			</p>
			<p>
				It is strictly prohibited to use the MyAccident.org service for the purpose of solicitation.
			</p>
			<p>
				Specifically you should not research accidents and attempt to find out the personal contact information of those involved in an accident for the purposes of soliciting them to your services.
				All normal Barratry and Solicitation practices should be strictly followed when using the service.
			</p>
			<p>
				contact{" "}
				<a href="mailto:customersuccess@myaccident.org">
					customersuccess@myaccident.org
				</a>{" "}
				if you have any specific questions regarding this.
			</p>
			<h3 id="h.tglygaozmfdp">
				Third Party Websites and Providers
			</h3>
			<p>
				Our Service may contain links to third party websites, products or services that are not owned or controlled by us. We have no control over, and assume no responsibility for the content,
				privacy policies, or practices of any third party website or service.
			</p>
			<p>
				If you use our Service in conjunction with the applications or services of third parties, your use of such applications or services is subject to the terms and conditions specified by those
				third parties and we assume no responsibility for your use of the same.
			</p>
			<h3 id="h.h9l33txx9e0l">
				Term and termination
			</h3>
			<p>
				These Terms of Service will become effective on the Effective Date and will remain effective until terminated by you or MyAccident.org PBC. You may cancel your subscription of the
				MyAccident.org Service at any time by visiting your Account &nbsp;page, which termination shall have effect at the expiry of the then-current subscription period that you have already paid
				for (e.g. one month, one quarter or a year). MyAccident.org PBC will not refund any remaining portion of subscription fees you have already paid for. MyAccident.org reserves the right to
				terminate these Terms of Service or suspend your MyAccident.org account at any time in case of unauthorized, or suspected unauthorized use of the Service whether in contravention of this
				Agreement or otherwise. If MyAccident.org PBC terminates these Terms of Service, or suspends your MyAccident.org account for any of the reasons set out in this section, then to the maximum
				extent permitted by law, MyAccident.org shall have no liability or responsibility to you, and will not refund any amounts that you have previously paid.
			</p>
			<h3 id="h.az46mfg2bh2q">
				Confidentiality
			</h3>
			<p>
				You and MyAccident.org PBC each agree to keep confidential all confidential information provided to it by the other party in connection with these Terms of Service and to protect such
				confidential information in the same manner as it protects its own confidential information. Neither of you nor MyAccident.org PBC will disclose or use any confidential information of the
				other party for any purpose outside the scope of these Terms of Service except with the disclosing party&rsquo;s consent or as required by law.
			</p>
			<h3 id="h.ow0ndusrw9vt">
				Data
			</h3>
			<p>
				You retain all right, title and interest in and to all data (including personal data) that you upload to MyAccident.org or otherwise provide to MyAccident.org PBC through your use of the
				Service.
			</p>
			<p>
				MyAccident.org does not allow anyone engaged in data mining, extraction of MyAccident.org &nbsp;information with any automated device/software, unauthorized access of Myaccident.org data, or
				illegal activity.
			</p>
			<p>
				Myaccident.org includes databases that are &nbsp;obtained directly or indirectly from government agencies or other such subdivisions under various provisions of Freedom of Information and
				Public Access Laws. Some information has restrictions on its use and it is your responsibility to be informed about such restrictions.{" "}
			</p>
			<p>
				Myaccident.org is not a consumer reporting agency and data provided by myaccident.org does not constitute a consumer report as that term is defined in the Fair Credit Reporting Act (FCRA),
				15 U.S.C.A. sec 1681 et seq. Myaccident.org is merely a conduit for public records and is NOT the ultimate custodian of any public records. All errors or complaints should be addressed to
				the governmental custodian. Myaccident.org will provide custodian contact information, if requested.
			</p>
			<h3 id="h.ih3lsu8gsztq">
				Intellectual property
			</h3>
			<p>
				MyAccident.org respects intellectual property rights, and expects you to do the same. The Service and the content provided through MyAccident.org is the property of MyAccident.org or MyAccident.org
				licensors and protected by intellectual property rights (including but not limited to copyright). By registering to use the Service we grant you a non-exclusive non-transferable licence to
				use the Service and you acknowledge that, except for the rights granted hereunder, you do not have a right to use the Service (including but not limited to its content) in any manner not
				covered by these Terms of Service.
			</p>
			<p>
				In particular, you shall not: (i) modify, translate, or create derivative works based on MyAccident.org or any element of the Service; (ii) create any link to MyAccident.org or frame or
				mirror any content contained or accessible from the Service, (iii) reverse engineer, de-compile, disassemble or otherwise attempt to discover the source code or underlying ideas or
				algorithms of MyAccident.org; (iv) or access the Service in order to (a) build a competitive product or service, or (b) copy any ideas, features, functions or graphics of the Service.
			</p>
			<p>
				Furthermore, you must not infringe any third party&#39;s intellectual property rights in using MyAccident.org or the Service. Further, you may not remove or alter any copyright, trademark or
				other intellectual property notices contained on or provided through the Service.
			</p>
			<h3 id="h.8fqtntevlkmp">
				Copyright infringement and take down
			</h3>
			<p>
				If you are a copyright holder who believes that any of the products, services or content which are directly available via the Service are infringing copies of your work, please let us know.
				Pursuant to The Digital Millennium Copyright Act, 17 United States Code 512(c)(3), a notice of alleged copyright infringement should be sent to MyAccident.org&rsquo;s designated copyright
				agent at the following address:{' '} 
				<span className="white-text">
					MyAccident.org PBC &nbsp;Attn: Legal Department,
					6060 N Central Expy Suite 500, Dallas, TX 75206
				</span>{' '}
				<a href="mailto:customersuccess@myaccident.org">
					customersuccess@myaccident.org
				</a>
			</p>
			<p>
				A notification of claimed copyright infringement must be addressed to the above and include the following: A physical or electronic signature of the owner (or person authorized to act on
				behalf of the owner) of the copyright that is allegedly infringed; Specific identification of each copyrighted work claimed to have been infringed; A description of where the material
				believed to be infringed is located on the Service (please be as detailed as possible and provide a URL to help us locate the material you are reporting); Contact information for the
				complaining party, such as a complete name, address, telephone number, and email address; A statement that the complaining party has a good faith belief that use of the work(s) in the manner
				complained of is not authorized by the copyright owner, its agent, or the law; and A statement that the information in the notification is accurate, and under penalty of perjury, that the
				complaining party is authorized to act on behalf of the owner of an exclusive right that is allegedly infringed.
			</p>
			<h3 id="h.q4bnke4d99zj">
				Technology limitations and modifications
			</h3>
			<p>
				MyAccident.org PBC
				will make reasonable efforts to keep the Service operational. However, certain technical difficulties or maintenance may, from time to time, result in temporary interruptions. MyAccident.org
				PBC reserves the right at any time and from time to time to modify or discontinue, temporarily or permanently, functions and features of the Service with or without notice.
			</p>
			<h3 id="h.4hvm1yqu835o">
				Assignment
			</h3>
			<p>
				MyAccident.org PBC
				may assign this Agreement or any part of it without restrictions. You may not assign this Agreement or any part of it to any third party.
			</p>
			<h3 id="h.320bf5m6w5kb">
				Entire agreement
			</h3>
			<p>
				These Terms of Service constitute all the terms and conditions agreed upon between you and MyAccident.org PBC and supersede any prior agreements in relation to the subject matter of these
				Terms of Service, whether written or oral. Any additional or different terms or conditions in relation to the subject matter of these Terms of Service in any written or oral communication
				from you to MyAccident.org PBC are void. You agree and accept that you have not accepted the terms and conditions of these Terms of Service in reliance of or to any oral or written
				representations made by MyAccident.org not contained in this Agreement.
			</p>
			<h3 id="h.vtd955qsuv28">
				Severability
			</h3>
			<p>
				Should for any reason or to any extent any provision of these Terms of Service be held invalid or unenforceable, such invalidity or enforceability shall not in any manner affect or render
				invalid or unenforceable the remaining provisions of these Terms of Service and the application of that provision shall be enforced to the extent permitted by law.
			</p>
			<h3 id="h.gy5j5rlipbw9">
				Amendment and Waiver
			</h3>
			<p>
				MyAccident.org PBC
				may revise these Terms of Service from time to time. Any waiver of any right or remedy under these Terms of Service by MyAccident.org must be in writing and signed by us. No delay in
				exercising any right or remedy shall operate as a waiver of such right or remedy or any other right or remedy. A waiver on one occasion shall not be construed as a waiver of any right or
				remedy on any future occasion.
			</p>
			<h3 id="h.rwqwul4fnqkv">
				Notices
			</h3>
			<p>
				Any notices under these Terms of Service shall be in writing and shall be deemed to have been delivered: (i) upon personal delivery; (ii) the fifth business day after mailing; or (iii) the
				third business day after sending by email. Notices to MyAccident.org shall be addressed as follows:
			</p>
			<p className="white-text">
				MyAccident.org PBC
			</p>
			<p className="white-text">
				Attn: Legal Department
			</p>
			<p className="white-text last">
				6060 N Central Expy Suite 500, Dallas, TX 75206
			</p>
			<p>
				Mandatory arbitration; exceptions to mandatory arbitration, waiver of class action rights; limitations period; venue and choice of law
			</p>
			<p>
				i. You and MyAccident.org agree that any dispute, claim or controversy arising out of or relating in any way to the Service or your use thereof, including our Agreement, shall be determined
				by mandatory binding arbitration under Delaware law. You also agree that, other than what is outlined below, &nbsp;you and MyAccident.org PBC are each waiving the right to a trial by jury
				and the right to participate in a class or multi-party action. This arbitration provision shall survive termination of these Terms of Service and the termination of your subscription to a
				MyAccident.org Business Service.
			</p>
			<p>
				ii. You and MyAccident.org PBC agree that (a) any claims seeking to enforce, protect, or determine the validity or ownership of any intellectual property rights, and (b) any claims related
				to allegations of theft, piracy or unauthorized use of the Service are NOT subject to mandatory arbitration. Instead, you and MyAccident.org agree that the preceding claims (including but
				not limited to claims for injunctive or equitable relief) shall be exclusively decided by Courts of competent jurisdiction in Delaware, USA and that applicable Delaware or US federal law
				shall govern, without regarding to choice of law principals. iii. YOU AND MYACCIDENT.ORG AGREE THAT EACH MAY BRING CLAIMS AGAINST THE OTHER ONLY IN YOUR OR ITS INDIVIDUAL CAPACITY, AND NOT
				AS A CLASS MEMBER OR IN ANY REPRESENTATIVE CAPACITY OR PROCEEDING. Further, the arbitrator shall not consolidate any other person&#39;s claims with your claims, and may not otherwise preside
				over any form of a multi-party or class proceeding. If this specific provision is found to be unenforceable in any way, then the entirety of this arbitration section shall be null and void.
				The arbitrator may not award declaratory or injunctive relief. iv. Any arbitration must be commenced by filing a demand for arbitration within ONE (1) YEAR after the date the party asserting
				the claim first knows or reasonably should know of the act, omission or default giving rise to the claim; and there shall be no right to any remedy for any claim not asserted within that
				time period. If applicable law prohibits a one-year limitations period for asserting claims, any claim must be asserted within the shortest time period permitted by applicable law. Your
				arbitration fees and your share of arbitrator compensation will be limited to those fees set forth in the applicable arbitration rules with the remainder paid by MyAccident.org PBC. Any
				arbitration costs or fees deemed &#39;excessive&#39; will be paid by MyAccident.org. v. You and MyAccident.org PBC further agree that applicable laws of Delaware shall exclusively govern any
				dispute without regard to choice or conflicts of law rules. The sole and exclusive venue for the resolution of any dispute, whether or not subject to mandatory arbitration as described
				above, shall lie in Delaware.
			</p>
			<h3 id="h.corw67qpfbhx">
				English version prevails
			</h3>
			<p>
				In the event that these Terms of Service are translated into other languages and there is a discrepancy between the two language versions, the English language version shall prevail to the
				extent that such discrepancy is the result of an error in translation.
			</p>
		</div>
	);
}
