/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import Axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { FiMinusCircle, FiPlusCircle } from "react-icons/fi";
import { MdCheckBox, MdCheckBoxOutlineBlank } from "react-icons/md";
import useLocalStorageState from "../hooks/useLocalStorageState";
import affiliateIcons from "../shared/affiliateIcons";

const durations = [1, 7, 14, 30, 60, 90];

export const loadAffiliateStats = (single, users, hidden) => {
  return new Promise((resolve) => {
    Axios.get(
      `/api/leads/affiliate-stats?from=${moment()
        .subtract(6, "months")
        .startOf("month")
        .startOf("day")}&to=${moment().endOf("day")}${
        single ? "&affiliate=" + single : "&justAffiliates=1"
      }`
    ).then((d) => {
      // setData(result);
      // setMonthLabels(ML);
      // setLoading(false);
      // console.log(result);
      if (d.data.errors.length && !single) {
        const uniq = [...new Set(d.data.errors)];
        Swal.fire({
          title: "Margins might be calculated wrong because:",
          html: `<div style="overflow:auto; max-height:500px; font-size:13px">${uniq.join(
            "<br />"
          )}</div>`,
          type: "warning",
        });
      }
      resolve({ result: d.data.result, ML: d.data.ML });
    });
  });
};

export const renderResult = (data) => {
  return (
    <>
      {durations.map((d) => (
        <td style={{ textAlign: "center" }} key={d}>
          <strong>
            {(
              (data[d + "_day_sold"] / data[d + "_day_total"]) * 100 || 0
            ).toFixed(2)}
            %
          </strong>
          <div style={{ opacity: 0.5 }}>
            total: {data[d + "_day_total"]}
            <br />
            sold: {data[d + "_day_sold"]}
          </div>
        </td>
      ))}
    </>
  );
};

export const renderResultBid = (data) => {
  return (
    <>
      {durations.map((d) => (
        <td style={{ textAlign: "center" }} key={d}>
          <strong>
            {data[d + "_day_pings"]
              ? (
                  (data[d + "_day_total"] / data[d + "_day_pings"]) * 100 || 0
                ).toFixed(2)
              : 0}
            %
          </strong>
          <div style={{ opacity: 0.5 }}>
            total pings: {data[d + "_day_pings"]}
            <br />
            won: {data[d + "_day_total"]}
            <br />
            no bid: {data[d + "_day_no_bid"]}
          </div>
        </td>
      ))}
    </>
  );
};

export default function AffiliateStats({ users, single }) {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});
  const [showHideOptions, setShowHideOptions] = useState(false);
  const [hidden, setHidden] = useLocalStorageState(
    single ? "hiddenAffiliates2" : "hiddenAffiliates",
    []
  );
  const [monthLabels, setMonthLabels] = useState([]);

  useEffect(() => {
    loadAffiliateStats(single, users, hidden).then((d) => {
      setLoading(false);
      setData(d.result);
      setMonthLabels(d.ML);
    });
  }, []);

  const average = (statePrices) => {
    if (!statePrices) return "Not defined";
    let count = 0;
    let sum = 0;
    Object.keys(statePrices).forEach((v) => {
      if (statePrices[v]?.bidPrice) {
        sum = sum + Number(statePrices[v].bidPrice);
        count++;
      }
    });
    return "$" + (sum / count).toFixed(2);
  };

  const renderAffiliateIcon = (id) => {
    if (affiliateIcons[id])
      return (
        <img
          src={`/img/${affiliateIcons[id]}`}
          alt={affiliateIcons[id]}
          title={affiliateIcons[id]}
          width={32}
          height={32}
          style={{ borderRadius: 5, marginTop: 3 }}
        />
      );
    return null;
  };

  const renderMonthResult = (data) => {
    if (data) {
      return (
        <>
          <strong>
            {((data["sold"] / data["total"]) * 100 || 0).toFixed(2)}%
          </strong>
          <div style={{ opacity: 0.5 }}>
            total: {data["total"]}
            <br />
            sold: {data["sold"]}
          </div>
        </>
      );
    }
    return "";
  };

  const toggleStates = (w) => {
    data[w].states = !data[w].states;
    setData({ ...data });
  };

  const toggleSubID = (w) => {
    data[w].subID = !data[w].subID;
    setData({ ...data });
  };

  const toggleSubIDState = (w, s) => {
    data[w].bySubID[s].subIDState = !data[w].bySubID[s].subIDState;
    setData({ ...data });
  };

  const toggleSubIDBid = (w) => {
    data[w].subIDBid = !data[w].subIDBid;
    setData({ ...data });
  };

  const toggleStatesBid = (w) => {
    data[w].statesBid = !data[w].statesBid;
    setData({ ...data });
  };

  const toggleStatesMonth = (w) => {
    data[w].statesMonth = !data[w].statesMonth;
    setData({ ...data });
  };

  const toggleHide = (u) => {
    if (hidden.includes(u)) {
      setHidden(hidden.filter((uu) => uu !== u));
    } else {
      hidden.push(u);
      setHidden([...hidden]);
    }
  };

  const toggleSubIDBidStates = (w, s) => {
    data[w].bySubID[s].byStates = !data[w].bySubID[s].byStates;
    setData({ ...data });
  };

  if (loading)
    return (
      <div>
        <h2>Affiliate Conversion %</h2>
        <img
          src="/img/ajax-loader.gif"
          alt="loading stats..."
          className="loader-gif"
        />
        &nbsp;Loading stats...
      </div>
    );

  return (
    <div>
      {!single ? (
        <a
          onClick={() => setShowHideOptions(!showHideOptions)}
          style={{ float: "right" }}
        >
          Show All/Hide options
        </a>
      ) : null}
      <h2>Affiliate Conversion %</h2>
      <table>
        <thead>
          <tr>
            <th style={{ width: 50 }}>State</th>
            <th style={{ width: 50 }}></th>
            {showHideOptions ? (
              <th style={{ textAlign: "center" }}>Hide</th>
            ) : null}
            <th>Affiliate User</th>
            <th style={{ textAlign: "center" }}>1 day</th>
            <th style={{ textAlign: "center" }}>7 day</th>
            <th style={{ textAlign: "center" }}>14 day</th>
            <th style={{ textAlign: "center" }}>30 day</th>
            <th style={{ textAlign: "center" }}>60 day</th>
            <th style={{ textAlign: "center" }}>90 day</th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(data)
            .filter((u) => showHideOptions || !hidden.includes(u))
            .map((u) => (
              <React.Fragment key={u}>
                <tr>
                  <td style={{ textAlign: "center" }}>
                    <a onClick={() => toggleStates(u)}>
                      {data[u].states ? (
                        <FiMinusCircle size={24} />
                      ) : (
                        <FiPlusCircle size={24} />
                      )}
                    </a>
                  </td>
                  <td style={{ textAlign: "center" }}>
                    {renderAffiliateIcon(u)}
                  </td>
                  {showHideOptions ? (
                    <td style={{ textAlign: "center" }}>
                      <a onClick={() => toggleHide(u)}>
                        {hidden.includes(u) ? (
                          <MdCheckBox size={24} />
                        ) : (
                          <MdCheckBoxOutlineBlank size={24} />
                        )}
                      </a>
                    </td>
                  ) : null}
                  <td>
                    <strong>
                      {users[u]?.businessName || `Unknown user ${u}`}
                    </strong>
                    <div style={{ opacity: 0.5 }}>{users[u]?.email}</div>
                  </td>
                  {renderResult(data[u])}
                </tr>
                {data[u].states ? (
                  <>
                    {Object.keys(data[u].byState).map((s) => (
                      <tr key={s}>
                        <td></td>
                        <td></td>
                        <td>
                          <strong>{s}</strong>
                        </td>
                        {renderResult(data[u].byState[s])}
                      </tr>
                    ))}
                  </>
                ) : null}
              </React.Fragment>
            ))}
        </tbody>
      </table>
      <br />
      <br />
      <h2>Affiliate Conversion % (By Month)</h2>
      <table>
        <thead>
          <tr>
            <th style={{ width: 50 }}>State</th>
            <th style={{ width: 50 }}></th>
            {showHideOptions ? (
              <th style={{ textAlign: "center" }}>Hide</th>
            ) : null}
            <th>Affiliate User</th>
            {monthLabels.map((m) => (
              <th key={m} style={{ textAlign: "center" }}>
                {m}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {Object.keys(data)
            .filter((u) => showHideOptions || !hidden.includes(u))
            .map((u) => (
              <React.Fragment key={u}>
                <tr>
                  <td style={{ textAlign: "center" }}>
                    <a onClick={() => toggleStatesMonth(u)}>
                      {data[u].statesMonth ? (
                        <FiMinusCircle size={24} />
                      ) : (
                        <FiPlusCircle size={24} />
                      )}
                    </a>
                  </td>
                  <td style={{ textAlign: "center" }}>
                    {renderAffiliateIcon(u)}
                  </td>
                  {showHideOptions ? (
                    <td style={{ textAlign: "center" }}>
                      <a onClick={() => toggleHide(u)}>
                        {hidden.includes(u) ? (
                          <MdCheckBox size={24} />
                        ) : (
                          <MdCheckBoxOutlineBlank size={24} />
                        )}
                      </a>
                    </td>
                  ) : null}
                  <td>
                    <strong>
                      {users[u]?.businessName || `Unknown user ${u}`}
                    </strong>
                    <div style={{ opacity: 0.5 }}>{users[u]?.email}</div>
                  </td>
                  {monthLabels.map((m) => (
                    <td key={u + m} style={{ textAlign: "center" }}>
                      {data[u].months && data[u].months[m]
                        ? renderMonthResult(data[u].months[m])
                        : "0"}
                    </td>
                  ))}
                </tr>
                {data[u].statesMonth ? (
                  <>
                    {Object.keys(data[u].byStateMonths).map((s) => (
                      <tr key={s}>
                        <td></td>
                        <td></td>
                        <td>
                          <strong>{s}</strong>
                        </td>
                        {monthLabels.map((m) => (
                          <React.Fragment key={m}>
                            {data[u].byStateMonths[s][m] ? (
                              <td style={{ textAlign: "center" }}>
                                {renderMonthResult(data[u].byStateMonths[s][m])}
                              </td>
                            ) : (
                              <td style={{ textAlign: "center" }}>0</td>
                            )}
                          </React.Fragment>
                        ))}
                      </tr>
                    ))}
                  </>
                ) : null}
              </React.Fragment>
            ))}
        </tbody>
      </table>
      {!single ? (
        <>
          <br />
          <br />
          <h2>Affiliate Margin Analysis</h2>
          <table>
            <thead>
              <tr>
                <th style={{ width: 50 }}></th>
                {showHideOptions ? (
                  <th style={{ textAlign: "center" }}>Hide</th>
                ) : null}
                <th>Affiliate User</th>
                <th style={{ textAlign: "center" }}>
                  Average # leads to
                  <br />
                  Create 1 sold lead
                </th>
                <th style={{ textAlign: "center" }}>Average lead price</th>
                <th style={{ textAlign: "center" }}>7 day margin</th>
                <th style={{ textAlign: "center" }}>14 day margin</th>
                <th style={{ textAlign: "center" }}>30 day margin</th>
                <th style={{ textAlign: "center" }}>60 day margin</th>
                <th style={{ textAlign: "center" }}>90 day margin</th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(data)
                .filter((u) => showHideOptions || !hidden.includes(u))
                .map((u) => (
                  <tr key={u}>
                    <td style={{ textAlign: "center" }}>
                      {renderAffiliateIcon(u)}
                    </td>
                    {showHideOptions ? (
                      <td style={{ textAlign: "center" }}>
                        <a onClick={() => toggleHide(u)}>
                          {hidden.includes(u) ? (
                            <MdCheckBox size={24} />
                          ) : (
                            <MdCheckBoxOutlineBlank size={24} />
                          )}
                        </a>
                      </td>
                    ) : null}
                    <td>
                      <strong>
                        {users[u]?.businessName || "Unknown user"}
                      </strong>
                      <div style={{ opacity: 0.5 }}>{users[u]?.email}</div>
                    </td>
                    <td style={{ textAlign: "center" }}>
                      {Math.round(
                        1 / (data[u]["30_day_sold"] / data[u]["30_day_total"])
                      )}
                    </td>
                    <td style={{ textAlign: "center" }}>
                      {average(users[u]?.statePrices)}
                    </td>
                    <td style={{ textAlign: "center" }}>
                      <strong>
                        {Number(
                          ((data[u]["7_day_lead_refund"] +
                            data[u]["7_day_lead_revenue"] -
                            data[u]["7_day_lead_cost"]) /
                            data[u]["7_day_lead_revenue"]) *
                            100 || 0
                        ).toFixed(2)}
                        %
                      </strong>
                      <div style={{ opacity: 0.5 }}>
                        total: $
                        {(
                          data[u]["7_day_lead_refund"] +
                            data[u]["7_day_lead_revenue"] -
                            data[u]["7_day_lead_cost"] ||
                          0 ||
                          0
                        ).toFixed(2)}
                      </div>
                    </td>
                    <td style={{ textAlign: "center" }}>
                      <strong>
                        {Number(
                          ((data[u]["14_day_lead_refund"] +
                            data[u]["14_day_lead_revenue"] -
                            data[u]["14_day_lead_cost"]) /
                            data[u]["14_day_lead_revenue"]) *
                            100 || 0
                        ).toFixed(2)}
                        %
                      </strong>
                      <div style={{ opacity: 0.5 }}>
                        total: $
                        {(
                          data[u]["14_day_lead_refund"] +
                            data[u]["14_day_lead_revenue"] -
                            data[u]["14_day_lead_cost"] ||
                          0 ||
                          0
                        ).toFixed(2)}
                      </div>
                    </td>
                    <td style={{ textAlign: "center" }}>
                      <strong>
                        {Number(
                          ((data[u]["30_day_lead_refund"] +
                            data[u]["30_day_lead_revenue"] -
                            data[u]["30_day_lead_cost"]) /
                            data[u]["30_day_lead_revenue"]) *
                            100 || 0
                        ).toFixed(2)}
                        %
                      </strong>
                      <div style={{ opacity: 0.5 }}>
                        total: $
                        {(
                          data[u]["30_day_lead_refund"] +
                            data[u]["30_day_lead_revenue"] -
                            data[u]["30_day_lead_cost"] || 0
                        ).toFixed(2)}
                      </div>
                    </td>
                    <td style={{ textAlign: "center" }}>
                      <strong>
                        {Number(
                          ((data[u]["60_day_lead_refund"] +
                            data[u]["60_day_lead_revenue"] -
                            data[u]["60_day_lead_cost"]) /
                            data[u]["60_day_lead_revenue"]) *
                            100 || 0
                        ).toFixed(2)}
                        %
                      </strong>
                      <div style={{ opacity: 0.5 }}>
                        total: $
                        {(
                          data[u]["60_day_lead_refund"] +
                            data[u]["60_day_lead_revenue"] -
                            data[u]["60_day_lead_cost"] || 0
                        ).toFixed(2)}
                      </div>
                    </td>
                    <td style={{ textAlign: "center" }}>
                      <strong>
                        {Number(
                          ((data[u]["90_day_lead_refund"] +
                            data[u]["90_day_lead_revenue"] -
                            data[u]["90_day_lead_cost"]) /
                            data[u]["90_day_lead_revenue"]) *
                            100 || 0
                        ).toFixed(2)}
                        %
                      </strong>
                      <div style={{ opacity: 0.5 }}>
                        total: $
                        {(
                          data[u]["90_day_lead_refund"] +
                            data[u]["90_day_lead_revenue"] -
                            data[u]["90_day_lead_cost"] || 0
                        ).toFixed(2)}
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </>
      ) : null}
      <br />
      <br />
      <h2>Affiliate Conversion By subID</h2>

      <table>
        <thead>
          <tr>
            <th style={{ width: 50 }}>SubId</th>
            <th style={{ width: 50 }}></th>
            {showHideOptions ? (
              <th style={{ textAlign: "center" }}>Hide</th>
            ) : null}
            <th>Affiliate User</th>
            <th style={{ textAlign: "center" }}>1 day</th>
            <th style={{ textAlign: "center" }}>7 day</th>
            <th style={{ textAlign: "center" }}>14 day</th>
            <th style={{ textAlign: "center" }}>30 day</th>
            <th style={{ textAlign: "center" }}>60 day</th>
            <th style={{ textAlign: "center" }}>90 day</th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(data)
            .filter((u) => (data[u].bySubID ? true : false))
            .map((u) => (
              <React.Fragment key={u}>
                <tr>
                  <td style={{ textAlign: "center" }}>
                    <a onClick={() => toggleSubID(u)}>
                      {data[u].subID ? (
                        <FiMinusCircle size={24} />
                      ) : (
                        <FiPlusCircle size={24} />
                      )}
                    </a>
                  </td>
                  <td style={{ textAlign: "center" }}>
                    {renderAffiliateIcon(u)}
                  </td>
                  {showHideOptions ? (
                    <td style={{ textAlign: "center" }}>
                      <a onClick={() => toggleHide(u)}>
                        {hidden.includes(u) ? (
                          <MdCheckBox size={24} />
                        ) : (
                          <MdCheckBoxOutlineBlank size={24} />
                        )}
                      </a>
                    </td>
                  ) : null}
                  <td>
                    <strong>
                      {users[u]?.businessName || `Unknown user ${u}`}
                    </strong>
                    <div style={{ opacity: 0.5 }}>{users[u]?.email}</div>
                  </td>
                  {renderResult(data[u])}
                </tr>
                {data[u].subID ? (
                  <>
                    {Object.keys(data[u].bySubID).map((s) => (
                      <React.Fragment key={s}>
                        <tr>
                          <td></td>
                          <td>
                            <a onClick={() => toggleSubIDState(u, s)}>
                              {data[u].bySubID[s].subIDState ? (
                                <FiMinusCircle size={24} />
                              ) : (
                                <FiPlusCircle size={24} />
                              )}
                            </a>
                          </td>
                          <td>
                            {single ? (
                              <strong>{s}</strong>
                            ) : (
                              <>
                                {s !== "unknown" ? (
                                  <div>
                                    {users[u]?.subIDNames &&
                                    users[u]?.subIDNames[s]
                                      ? users[u]?.subIDNames[s]
                                      : s}
                                  </div>
                                ) : (
                                  "Unknown"
                                )}
                                <div style={{ opacity: 0.5 }}>{s}</div>
                              </>
                            )}
                          </td>
                          {renderResult(data[u].bySubID[s])}
                        </tr>
                        {data[u].bySubID[s].subIDState ? (
                          <>
                            {Object.keys(data[u].bySubID[s].byState).map(
                              (ss) => (
                                <tr key={ss}>
                                  <td></td>
                                  <td></td>
                                  <td>{ss}</td>
                                  {renderResult(data[u].bySubID[s].byState[ss])}
                                </tr>
                              )
                            )}
                          </>
                        ) : null}
                      </React.Fragment>
                    ))}
                  </>
                ) : null}
              </React.Fragment>
            ))}
        </tbody>
      </table>

      <br />
      <br />
      <h2>BID Analysis</h2>

      <table>
        <thead>
          <tr>
            <th style={{ width: 50 }}>SubId</th>
            <th style={{ width: 50 }}>States</th>
            <th style={{ width: 50 }}></th>
            {showHideOptions ? (
              <th style={{ textAlign: "center" }}>Hide</th>
            ) : null}
            <th>Affiliate User</th>
            <th style={{ textAlign: "center" }}>1 day</th>
            <th style={{ textAlign: "center" }}>7 day</th>
            <th style={{ textAlign: "center" }}>14 day</th>
            <th style={{ textAlign: "center" }}>30 day</th>
            <th style={{ textAlign: "center" }}>60 day</th>
            <th style={{ textAlign: "center" }}>90 day</th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(data)
            .filter((u) => (data[u].bySubID ? true : false))
            .map((u) => (
              <React.Fragment key={u}>
                <tr>
                  <td style={{ textAlign: "center" }}>
                    <a onClick={() => toggleSubIDBid(u)}>
                      {data[u].subIDBid ? (
                        <FiMinusCircle size={24} />
                      ) : (
                        <FiPlusCircle size={24} />
                      )}
                    </a>
                  </td>
                  <td style={{ textAlign: "center" }}>
                    <a onClick={() => toggleStatesBid(u)}>
                      {data[u].statesBid ? (
                        <FiMinusCircle size={24} />
                      ) : (
                        <FiPlusCircle size={24} />
                      )}
                    </a>
                  </td>
                  <td style={{ textAlign: "center" }}>
                    {renderAffiliateIcon(u)}
                  </td>
                  {showHideOptions ? (
                    <td style={{ textAlign: "center" }}>
                      <a onClick={() => toggleHide(u)}>
                        {hidden.includes(u) ? (
                          <MdCheckBox size={24} />
                        ) : (
                          <MdCheckBoxOutlineBlank size={24} />
                        )}
                      </a>
                    </td>
                  ) : null}
                  <td>
                    <strong>
                      {users[u]?.businessName || `Unknown user ${u}`}
                    </strong>
                    <div style={{ opacity: 0.5 }}>{users[u]?.email}</div>
                  </td>
                  {renderResultBid(data[u])}
                </tr>
                {data[u].subIDBid ? (
                  <>
                    {Object.keys(data[u].bySubID).map((s) => (
                      <React.Fragment key={s}>
                        <tr>
                          <td></td>
                          <td></td>
                          <td>
                            <a onClick={() => toggleSubIDBidStates(u, s)}>
                              {data[u].bySubID[s].byStates ? (
                                <FiMinusCircle size={24} />
                              ) : (
                                <FiPlusCircle size={24} />
                              )}
                            </a>
                          </td>
                          <td>
                            {single ? (
                              <strong>{s}</strong>
                            ) : (
                              <>
                                {s !== "unknown" ? (
                                  <div>
                                    {users[u]?.subIDNames &&
                                    users[u]?.subIDNames[s]
                                      ? users[u]?.subIDNames[s]
                                      : s}
                                  </div>
                                ) : (
                                  "Unknown"
                                )}
                                <div style={{ opacity: 0.5 }}>{s}</div>
                              </>
                            )}
                          </td>
                          {renderResultBid(data[u].bySubID[s])}
                        </tr>

                        {data[u].bySubID[s].byStates ? (
                          <>
                            {Object.keys(data[u].bySubID[s].byState).map(
                              (ss) => (
                                <tr key={ss}>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td>{ss}</td>
                                  {renderResultBid(
                                    data[u].bySubID[s].byState[ss]
                                  )}
                                </tr>
                              )
                            )}
                          </>
                        ) : null}
                      </React.Fragment>
                    ))}
                  </>
                ) : null}

                {data[u].statesBid ? (
                  <>
                    {Object.keys(data[u].byState).map((s) => (
                      <tr key={s}>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>
                          <strong>{s}</strong>
                        </td>
                        {renderResultBid(data[u].byState[s])}
                      </tr>
                    ))}
                  </>
                ) : null}
              </React.Fragment>
            ))}
        </tbody>
      </table>
    </div>
  );
}
