import React from "react";
import moment from "moment";
import { formatPhoneNumber } from "./LeadsExpander";

export default function AlertUserExpander(props) {
  const v = props.data;
  let fields = [
    "created_at",
    "caseId",
    "case_id",
    "lastName",
    "firstName",
    "phone",
    "email",
    "startDate",
    "endDate",
    "address",
    "city",
    "vin",
    "vehicle1Maker",
    "vehicle1Year",
    "vehicle2Maker",
    "vehicle2Year",
    "notify_email_count",
    "notify_sms_count",
    "type",
    "legal_consult",
    // "hasLawyer",
  ];

  const blurred = [];
  if (props.blurred) {
    blurred.push("firstName", "lastName", "phone", "email", "address");
  }
  if (props.removePhone) fields = fields.filter((v) => v !== "phone");
  if (v?.phone) v.phone = formatPhoneNumber(v.phone);

  const mask = (inp, f) => {
    if (f === "created_at" || f === "startDate" || f === "endDate")
      return moment(inp).format("llll");
    if (typeof inp === "boolean") {
      return inp ? "Yes" : "No";
    }
    if (blurred.includes(f)) return inp.replace(/([a-z,A-Z,0-9])/gi, "x");
    return inp;
  };

  if (v)
    return (
      <div className="exp two-columns" key={v._id}>
        {fields.map((f) => {
          if (v[f])
            return (
              <p key={f}>
                <strong>{f}: </strong>{" "}
                <span className={blurred.includes(f) ? "blurred" : ""}>
                  {mask(v[f], f)}
                </span>
              </p>
            );
          return null;
        })}

        <p>
          <strong>Injured: </strong> {v.injured ? "Yes" : "Unknown"}
        </p>
        <p>
          <strong>Fault: </strong> {v.fault ? "Yes" : "Unknown"}
        </p>
        <p>
          <strong>HasLawyer: </strong> {v.hasLawyer ? "Yes" : "Unknown"}
        </p>
        {v.user && typeof v.user === "object" ? (
          <div style={{ clear: "both", marginTop: 10, overflow: "hidden" }}>
            <p>
              <strong>Name: </strong>{" "}
              <span className={blurred ? "blurred" : ""}>
                {v.user.firstName + " " + v.user.lastName}
              </span>
            </p>
            <p>
              <strong>Email: </strong>{" "}
              <span className={blurred ? "blurred" : ""}>{v.user.email}</span>
            </p>
            <p>
              <strong>Phone: </strong>{" "}
              <span className={blurred ? "blurred" : ""}>{v.user.phone}</span>
            </p>
            <p>
              <strong>Verified: </strong> {v.user.verified ? "Yes" : "No"}
            </p>
          </div>
        ) : null}
        <div style={{ clear: "both", marginTop: 10, overflow: "hidden" }} />
      </div>
    );
  return null;
}
