/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import axios from "axios";
import Checkbox from "../components/Shared/Checkbox";
import marked from "marked";
import swal from "sweetalert2";
import { useHistory, useParams, Redirect } from "react-router-dom";

const markdown = `You can use markdown language to write posts. Some examples:

&nbsp;  


# H1 Title

## Smaller tittle


You can set **bold** text as well as *italic*.

You can create links, like this [myaccident].

Easily create list is this:
- one
- two
- three


[myaccident]: https://myaccident.org`;

export default function CmsDetail({ user }) {
  const history = useHistory();
  const params = useParams();
  const [loading, setLoading] = useState(
    params.id !== undefined ? true : false
  );

  const [post, setPost] = useState({
    title: "",
    seo_title: "",
    content: markdown,
    active: false,
    template: "full-width",
    category: "",
    is_html: false,
    description: "",
  });

  useEffect(() => {
    if (params.id) {
      axios.get("/api/blog/post/" + params.id).then((d) => {
        if (d.data.post) {
          setPost(d.data.post);
          setLoading(false);
        } else {
          swal
            .fire("Error!", "Post with that ID was not found!", "error")
            .then(() => history.push("/cms"));
        }
      });
    }
  }, []);

  const changeInput = (name, value) => {
    post[name] = value;
    if (name === "title" && !params.id) {
      post.seo_title = value.replace(/\W/g, "-").toLowerCase();
    }
    setPost({ ...post });
  };

  const save = () => {
    console.log(post);
    post.content = btoa(unescape(encodeURIComponent(post.content)));
    axios.post("/api/blog/post", post).then((d) => {
      // console.log(d.data);
      history.push("/cms");
    });
  };

  if (!user || user.role.id !== 10) {
    console.log("HERE");
    return <Redirect to="/signin" />;
  }

  return (
    <div id="admin-page">
      {loading ? (
        <div>
          <img
            src="/img/ajax-loader.gif"
            alt="loadingRequests..."
            className="loader-gif"
          />
          &nbsp;Loading post...
        </div>
      ) : (
        <div>
          <div className="row">
            <div className="column">
              <h2>{params.id ? "Edit post" : "Create new post"}</h2>
              Title:
              <input
                value={post.title}
                onChange={(e) => changeInput("title", e.target.value)}
              />
              SEO URL:
              <input
                value={post.seo_title}
                onChange={(e) => changeInput("seo_title", e.target.value)}
              />
              <br />
              SEO description:
              <input
                value={post.description}
                onChange={(e) => changeInput("description", e.target.value)}
              />
              <br />
              <div className="row">
                <div className="column">
                  <Checkbox
                    text="Active"
                    checked={post.active}
                    onClick={() => changeInput("active", !post.active)}
                  />
                </div>
                <div className="column">
                  <Checkbox
                    text="Write post as HTML"
                    checked={post.is_html}
                    onClick={() => changeInput("is_html", !post.is_html)}
                  />
                </div>
              </div>
              <br />
              Template:
              <select
                value={post.template}
                onChange={(e) => changeInput("template", e.target.value)}
              >
                <option value="full-width">Full width page</option>
                <option value="full-width-no-updated">
                  Full width page without 'last updated on' in header
                </option>
                <option value="blog">Blog post page</option>
                <option value="with-form">With form on left side</option>
                <option value="with-form-dui">
                  With DUI form on left side
                </option>
              </select>
              <br />
              Category:
              <select
                value={post.category}
                onChange={(e) => changeInput("category", e.target.value)}
              >
                <option value=""></option>
                <option value="attorney">attorney</option>
                <option value="compensation">compensation</option>
                <option value="insurance">insurance</option>
                <option value="legal">legal</option>
                <option value="police">police</option>
                <option value="safety">safety</option>
              </select>
              Post content:
              <textarea
                value={post.content}
                onChange={(e) => changeInput("content", e.target.value)}
                rows={28}
                style={{ fontFamily: "monospace" }}
              />
            </div>
            <div className="column">
              <h2>Post Preview</h2>
              <div
                style={{
                  border: "#DDD 1px solid",
                  padding: 20,
                  borderRadius: 5,
                  maxHeight: "calc(100vh - 200px)",
                  overflow: "auto",
                }}
              >
                <h1>{post.title}</h1>
                <div
                  dangerouslySetInnerHTML={{
                    __html: post.is_html ? post.content : marked(post.content),
                  }}
                  id="post-preview"
                />
              </div>
            </div>
          </div>
          <div>
            <button
              disabled={!post.title || !post.content || !post.seo_title}
              onClick={() => save()}
            >
              Save
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
