import Axios from "axios";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useRouteMatch } from "react-router";
import Swal from "sweetalert2";

export default function AffiliateSignup() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "onBlur" });
  const [ajax, setAjax] = useState(false);
  const [success, setSuccess] = useState(false);
  const route = useRouteMatch();

  const onSubmit = (data) => {
    setAjax(true);
    Axios.post("/api/users/affiliate/signup", { ...data })
      .then((d) => {
        setAjax(false);
        setSuccess(true);
      })
      .catch((e) => {
        let html = "";
        setAjax(false);
        e.response.data.errors.forEach((v) => {
          html += v.msg + "<br />";
        });
        Swal.fire({
          title: "Some errors happened",
          html,
          type: "error",
        });
      });
  };

  if (success)
    return (
      <div className="dark-page">
        <div className="content">
          <div className="green-gradient">
            <h1 className="HomePage__main-title HomePage__title">
              <span>Thank You!!!</span>
            </h1>
          </div>
          <br />
          <br />
          <br />
          <br />
          <p style={{ textAlign: "center" }}>
            We have received your submission and will be contacting you shortly!
          </p>
        </div>
      </div>
    );
  // console.log(watch("test"));
  return (
    <div className="dark-page">
      <div className="content">
        <div className="green-gradient">
          <h1 className="HomePage__main-title HomePage__title">
            {route.url === "/signup" ? (
              <span>Law firm Interest Questionnaire</span>
            ) : (
              <span>Affiliate/Publisher Interest Questionnaire</span>
            )}
          </h1>
        </div>
        <br />
        <br />
        <br />
        <br />
        <p style={{ textAlign: "center" }}>
          Thanks for your interest in joining our Partner Network. Please fill
          out the bellow information and we will be in contact with you shortly.
        </p>
        <br />
        <br />
        <form onSubmit={handleSubmit(onSubmit)} className={ajax ? "ajax" : ""}>
          <div className="content-box">
            <h2>Business Information</h2>
            <div className="row">
              <div className="column">
                <label>* Business name</label>
                <input {...register("businessName", { required: true })} />
                {errors.businessName && (
                  <span className="error">Business address is required</span>
                )}
              </div>
              <div className="column">
                <label>* Address 1</label>
                <input {...register("address", { required: true })} />
                {errors.address && (
                  <span className="error">Address is required</span>
                )}
              </div>
            </div>

            <div className="row">
              <div className="column">
                <label>* Legal name</label>
                <input {...register("legalName", { required: true })} />
                {errors.legalName && (
                  <span className="error">Legal name is required</span>
                )}
              </div>
              <div className="column">
                <label>Address 2</label>
                <input {...register("address2")} />
              </div>
            </div>

            <div className="row">
              <div className="column">
                <label>* Business phone</label>
                <input {...register("businessPhone", { required: true })} />
                {errors.businessPhone && (
                  <span className="error">Business phone is required</span>
                )}
              </div>
              <div className="column">
                <div className="row">
                  <div className="column">
                    <label>State</label>
                    <input {...register("state")} />
                  </div>
                  <div className="column">
                    <label>Zip Code</label>
                    <input {...register("zip")} />
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="column">
                <label>Website</label>
                <input {...register("website")} />
              </div>
              <div className="column"></div>
            </div>
          </div>
          <br />
          <br />
          <div className="content-box">
            <h2>Contact Information</h2>
            <div className="row">
              <div className="column">
                <label>* First name</label>
                <input {...register("firstName", { required: true })} />
                {errors.firstName && (
                  <span className="error">First name is required</span>
                )}
              </div>
              <div className="column">
                <label>* Last name</label>
                <input {...register("lastName", { required: true })} />
                {errors.lastName && (
                  <span className="error">Last name is required</span>
                )}
              </div>
            </div>
            <div className="row">
              <div className="column">
                <label>* Email</label>
                <input {...register("email", { required: true })} />
                {errors.email && (
                  <span className="error">Email is required</span>
                )}
              </div>
              <div className="column">
                <label>* Mobile phone</label>
                <input {...register("phone", { required: true })} />
                {errors.phone && (
                  <span className="error">Mobile phone is required</span>
                )}
              </div>
            </div>
          </div>
          <br />
          <p style={{ textAlign: "center" }}>
            <button>Submit</button>
          </p>
        </form>
      </div>
    </div>
  );
}
