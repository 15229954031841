import React from 'react';

const CircleShape = ({ className, id }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="367" height="475" viewBox="0 0 367 475" className={className}>
    <defs>
      <linearGradient id={id} x1="50%" x2="50%" y1="0%" y2="100%">
        <stop offset="0%" stopColor="#FFF" stopOpacity=".084"/>
        <stop offset="100%" stopColor="#FFF" stopOpacity="0"/>
      </linearGradient>
    </defs>
    <circle cx="245.5" cy="1070.5" r="192.5" fill="none" fillRule="nonzero" stroke={`url(#${id})`} strokeWidth="90" transform="rotate(45 1247.02 644.343)"/>
  </svg>
);

export default CircleShape;