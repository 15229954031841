import React, { useEffect, useState, useCallback } from "react";
import * as CanvasJSReact from "../../canvasjs.react";
import moment from "moment";
import { defaultGraphOptions } from "../LeadStats";
import { MdClose } from "react-icons/md";
import Axios from "axios";

const CanvasJSChart = CanvasJSReact.CanvasJSChart;

export default function OpsBreakdown({ leads, view, from, to }) {
  const [graphData, setGraphData] = useState({});
  const [editor, setEditor] = useState(false);
  const [costs, setCosts] = useState({});

  const reDrawGraph = useCallback(
    (co) => {
      const gd = { ...defaultGraphOptions };
      let data = {};
      leads.forEach((v) => {
        let period;
        if (view === "daily")
          period = new moment(v.createdAt).format("YYYY-MM-DD");
        if (view === "monthly") period = v.createdAt.substr(0, 7);
        if (view === "weekly") {
          period =
            "Week " +
            moment(v.createdAt).isoWeek() +
            ", " +
            new Date(v.createdAt).getFullYear();
        }
        if (!data[period])
          data[period] = {
            pending_revs: 0,
            google_spend: 0,
            profit: 0,
          };
        // fixed_cost: 0,

        if (v.value) {
          data[period].pending_revs = Math.floor(
            data[period].pending_revs + v.value
          );
        }

        // const namedDay = new moment(v.createdAt).format("dddd");
        // if (namedDay !== "Sunday" && namedDay !== "Saturday")
        //   data[period].fixed_cost = 3333;

        // data[period].google_spend =
        //   Math.floor(Math.random() * (3000 - 1000 + 1)) + 1000;

        data[period].google_spend = co[period] || 0;

        data[period].profit =
          data[period].pending_revs -
          // data[period].fixed_cost -
          data[period].google_spend;
      });

      gd.toolTip = {
        shared: true,
        // content: "{label}<hr />{name}: {y}",
      };

      gd.data = [
        // {
        //   type: "stackedColumn",
        //   color: "#FFC000",
        //   showInLegend: true,
        //   name: "Operating costs",
        //   dataPoints: Object.keys(data).map((vv) => {
        //     return {
        //       label: view === "daily" ? moment(vv).format("ll") : vv,
        //       y: data[vv].fixed_cost,
        //       toolTipContent:
        //         "<span style='color:#FFC000'>Operating Costs:</span> $" +
        //         data[vv].fixed_cost,
        //     };
        //   }),
        // },
        {
          type: "stackedColumn",
          color: "#595959",
          showInLegend: true,
          name: "Paid Traffic",
          dataPoints: Object.keys(data).map((vv) => {
            return {
              label: view === "daily" ? moment(vv).format("ll") : vv,
              y: data[vv].google_spend,
              toolTipContent:
                "<span style='color:#595959'>Paid Traffic:</span> $" +
                data[vv].google_spend,
            };
          }),
        },
        {
          type: "line",
          color: "#FF0102",
          showInLegend: true,
          name: "Pending Revs",
          dataPoints: Object.keys(data).map((vv) => {
            return {
              label: view === "daily" ? moment(vv).format("ll") : vv,
              y: data[vv].pending_revs,
              toolTipContent:
                "<span style='color:#FF0102'>Pending Revs:</span> $" +
                data[vv].pending_revs,
            };
          }),
        },
        {
          type: "stackedColumn",
          color: "#4472C3",
          showInLegend: true,
          name: "Profit",
          dataPoints: Object.keys(data).map((vv) => {
            return {
              label: view === "daily" ? moment(vv).format("ll") : vv,
              y: data[vv].profit > 0 ? data[vv].profit : 0,
              toolTipContent:
                "<span style='color:#4472C3'>Profit:</span> $" +
                data[vv].profit,
            };
          }),
        },
        {
          type: "stackedColumn",
          color: "#FFF",
          showInLegend: false,
          toolTipContent: null,
          name: "Profit2",
          dataPoints: Object.keys(data).map((vv) => {
            return {
              label: view === "daily" ? moment(vv).format("ll") : vv,
              y: 0,
              indexLabel: "$" + data[vv].profit,
              indexLabelFontColor: data[vv].profit > 0 ? "green" : "red",
            };
          }),
        },
      ];
      setGraphData(gd);
    },
    [leads, view]
  );

  useEffect(() => {
    // console.log(leads);
    // console.log(users);
    // console.log(filterUsers);
    // console.log(from, to);
    Axios.get(
      `/api/leads/googleAdsCost?from=${from.format(
        "YYYY-MM-DD"
      )}&to=${to.format("YYYY-MM-DD")}`
    ).then((d) => {
      // console.log(d.data);
      setCosts(d.data.costs);
      reDrawGraph(d.data.costs);
    });
  }, [leads, view, from, to, reDrawGraph]);

  const save = (e) => {
    e.preventDefault();
    setEditor(false);
    reDrawGraph(costs);
    Axios.post(`/api/leads/googleAdsCost`, { costs });
  };

  if (view !== "daily") return null;

  return (
    <div>
      <div
        className={
          editor
            ? "modal search show google-spends"
            : "modal search google-spends"
        }
      >
        <button className="close" onClick={() => setEditor(false)}>
          <MdClose />
        </button>
        <h2>Edit Paid Traffic</h2>
        <form onSubmit={(e) => save(e)}>
          <div>
            {Object.keys(costs).map((c) => (
              <span key={c}>
                {c} <br />
                <input
                  type="number"
                  value={costs[c]}
                  onChange={(e) =>
                    setCosts({ ...costs, ...{ [c]: Number(e.target.value) } })
                  }
                />
              </span>
            ))}
          </div>
          <div className="footer">
            <button
              onClick={() => setEditor(false)}
              className="outline"
              type="button"
            >
              Cancel
            </button>
            <button>Save</button>
          </div>
        </form>
      </div>

      <h2>Ops Breakdown</h2>
      <button
        onClick={() => setEditor(true)}
        disabled={!Object.keys(costs).length}
      >
        Edit Paid Traffic
      </button>
      <br />
      <br />
      <CanvasJSChart options={graphData} />
    </div>
  );
}
