import React from "react";
import FormButton from "./FormButton";

const FormRadioBtn = ({ handleRadius, radiusSelected, resetMarker, markerPosition, close }) => {
	const radioBtnInfo = {
		"5": 8046,
		"10": 16093,
		"15": 24140,
		"20": 32168,
		"30": 48280,
		"50": 80467
	};

	return (
		<div className="form-radio-btns">
			<label>Radius:</label>
			{Object.entries(radioBtnInfo).map(label => {
				return (
					<label onClick={() => handleRadius({ value: label[1], label: `${label[0]} miles` })} key={label[0]} className="inline">
						<input className="with-gap" name="group1" type="radio" defaultChecked={radiusSelected === label[1] ? true : false} />
						<span className="radio-btn-label">{label[0]} miles</span>
					</label>
				);
			})}
			<div className="footer">
				<FormButton action={resetMarker} text="Clear marker" className="outline" disabled={!markerPosition} />
				<button onClick={close}>Done</button>
			</div>
		</div>
	);
};

export default FormRadioBtn;
