/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import axios from "axios";
import swal from "sweetalert2";
import { useHistory, useParams, Redirect } from "react-router-dom";

export default function PoliceAgenciesDetail({ user }) {
  const history = useHistory();
  const params = useParams();
  const [loading, setLoading] = useState(true);

  const [post, setPost] = useState(null);

  useEffect(() => {
    if (params.id) {
      axios.get("/api/police-agency/" + params.id).then((d) => {
        if (d.data.post) {
          setPost(d.data.post);
          setLoading(false);
        } else {
          swal
            .fire("Error!", "Post with that ID was not found!", "error")
            .then(() => history.push("/police-agencies"));
        }
      });
    }
  }, []);

  const changeInput = (name, value) => {
    post[name] = value;
    setPost({ ...post });
  };

  const save = () => {
    // console.log(post);
    post.autogenerated_paragraph = btoa(
      unescape(encodeURIComponent(post.autogenerated_paragraph))
    );
    post.way_to_contact_paragraph = btoa(
      unescape(encodeURIComponent(post.way_to_contact_paragraph))
    );
    axios.post("/api/police-agency", post).then((d) => {
      // console.log(d.data);
      history.push("/police-agencies");
    });
  };

  const addFAQ = () => {
    if (!post.faq) post.faq = [];
    post.faq.push({ q: "", a: "" });
    setPost({ ...post });
  };

  const changeFAQ = (k, w, v) => {
    post.faq[k][w] = v;
    setPost({ ...post });
  };

  const removeFAQ = (k) => {
    post.faq = post.faq.filter((v, kk) => kk !== k);
    setPost({ ...post });
  };

  if (!user || user.role.id !== 10) {
    // console.log("HERE");
    return <Redirect to="/signin" />;
  }

  return (
    <div id="admin-page">
      {loading ? (
        <div>
          <img
            src="/img/ajax-loader.gif"
            alt="loadingRequests..."
            className="loader-gif"
          />
          &nbsp;Loading post...
        </div>
      ) : (
        <div>
          <h2>{post.name}</h2>
          Name:
          <input
            value={post.name}
            onChange={(e) => changeInput("name", e.target.value)}
          />
          Autogenerated Paragraph:
          <textarea
            value={post.autogenerated_paragraph}
            onChange={(e) =>
              changeInput("autogenerated_paragraph", e.target.value)
            }
            rows={10}
            style={{ fontFamily: "monospace" }}
          />
          Way to contact paragraph:
          <textarea
            value={post.way_to_contact_paragraph}
            onChange={(e) =>
              changeInput("way_to_contact_paragraph", e.target.value)
            }
            rows={10}
            style={{ fontFamily: "monospace" }}
          />
          Additional HTML that goes after the form:
          <textarea
            value={post.additional_html}
            onChange={(e) => changeInput("additional_html", e.target.value)}
            rows={30}
            style={{ fontFamily: "monospace" }}
          ></textarea>
          Address:
          <input
            value={post.address}
            onChange={(e) => changeInput("address", e.target.value)}
          />
          City:
          <input
            value={post.city}
            onChange={(e) => changeInput("city", e.target.value)}
          />
          State:
          <input
            value={post.state}
            onChange={(e) => changeInput("state", e.target.value)}
          />
          Non Emergency phone:
          <input
            value={post.non_emergency}
            onChange={(e) => changeInput("non_emergency", e.target.value)}
          />
          Report method inperson:
          <input
            value={post.report_method_inperson}
            onChange={(e) =>
              changeInput("report_method_inperson", e.target.value)
            }
          />
          Report method online
          <input
            value={post.report_method_requestform}
            onChange={(e) =>
              changeInput("report_method_requestform", e.target.value)
            }
          />
          Report method telephone
          <input
            value={post.report_method_telephone}
            onChange={(e) =>
              changeInput("report_method_telephone", e.target.value)
            }
          />
          Report method email
          <input
            value={post.report_method_email}
            onChange={(e) => changeInput("report_method_email", e.target.value)}
          />
          F.A.Q.{" "}
          <button style={{ padding: "3px 10px" }} onClick={() => addFAQ()}>
            +
          </button>
          <br />
          <br />
          {post.faq?.length ? (
            <>
              {post.faq.map((f, k) => (
                <div
                  key={k}
                  style={{
                    border: "#DDD 1px solid",
                    padding: 10,
                    marginBottom: 10,
                    borderRadius: 5,
                  }}
                >
                  Question:{" "}
                  <button
                    style={{ padding: "3px 10px" }}
                    onClick={() => removeFAQ(k)}
                    className="red"
                  >
                    -
                  </button>
                  <br />
                  <input
                    value={f.q}
                    onChange={(e) => changeFAQ(k, "q", e.target.value)}
                  />
                  Answer:
                  <br />
                  <textarea
                    value={f.a}
                    onChange={(e) => changeFAQ(k, "a", e.target.value)}
                  />
                </div>
              ))}
            </>
          ) : null}
          <div>
            <button onClick={() => save()}>Save</button>
          </div>
        </div>
      )}
    </div>
  );
}
