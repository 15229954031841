import React, { useEffect, useState } from "react";
import * as CanvasJSReact from "../../canvasjs.react";
import moment from "moment";
import { defaultGraphOptions } from "../LeadStats";
import Checkbox from "../Shared/Checkbox";

const CanvasJSChart = CanvasJSReact.CanvasJSChart;

export default function FunnelSuccess({ leads, view, users }) {
  const [graphData, setGraphData] = useState({});
  // const [leadType, setLeadType] = useState("");
  const [includeColds, setIncludeColds] = useState(false);

  useEffect(() => {
    // console.log(leads);
    // console.log(users);
    // console.log(filterUsers);
    const gd = { ...defaultGraphOptions };
    let data = {};
    leads.forEach((v) => {
      let period;
      if (view === "daily")
        period = new moment(v.createdAt).format("YYYY-MM-DD");
      if (view === "monthly") period = v.createdAt.substr(0, 7);
      if (view === "weekly") {
        period =
          "Week " +
          moment(v.createdAt).isoWeek() +
          ", " +
          new Date(v.createdAt).getFullYear();
      }
      if (!data[period])
        data[period] = {
          not_sold: 0,
          sold: 0,
        };

      if (v.lead_type === "CALawyer") {
        if (v.lead_level === "sold") data[period].sold++;
        if (!includeColds && v.lead_level !== "sold" && v.lead_level !== "cold")
          data[period].not_sold++;
        if (includeColds && v.lead_level !== "sold") data[period].not_sold++;
      }
    });

    gd.toolTip = {
      shared: true,
      // content: "{label}<hr />{name}: {y}",
    };

    gd.data = [
      {
        type: "stackedColumn",
        color: "#00F",
        showInLegend: true,
        name: "Not sold leads",
        dataPoints: Object.keys(data).map((vv) => {
          return {
            label: view === "daily" ? moment(vv).format("ll") : vv,
            y: data[vv].not_sold,
          };
        }),
      },
      {
        type: "stackedColumn",
        color: "#F00",
        showInLegend: true,
        name: "Sold leads",
        dataPoints: Object.keys(data).map((vv) => {
          return {
            label: view === "daily" ? moment(vv).format("ll") : vv,
            y: data[vv].sold,
          };
        }),
      },
      {
        type: "line",
        color: "#555",
        showInLegend: true,
        name: "Sold %",
        dataPoints: Object.keys(data).map((vv) => {
          return {
            label: view === "daily" ? moment(vv).format("ll") : vv,
            y: (data[vv].sold / (data[vv].sold + data[vv].not_sold)) * 100,
          };
        }),
      },
    ];
    setGraphData(gd);
  }, [leads, includeColds, view]);

  return (
    <div>
      <h2>Funnel Success</h2>
      <Checkbox
        text="Include colds"
        checked={includeColds}
        onClick={() => setIncludeColds(!includeColds)}
      />

      {/* <select
        value={leadType}
        onChange={(e) => setLeadType(e.target.value)}
        style={{ maxWidth: 300 }}
      >
        <option value="">All types...</option>
        <option>CALawyer</option>
        <option>DUILawyer</option>
        <option>AutoBody</option>
        <option>AutoRepair</option>
        <option>NSInsurance</option>
        <option>Chiropractor</option>
      </select> */}

      <br />
      {process.env.JEST_WORKER_ID !== undefined ? null : (
        <CanvasJSChart options={graphData} />
      )}
    </div>
  );
}
