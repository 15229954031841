/* eslint-disable default-case */
/* eslint-disable no-unexpected-multiline */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-direct-mutation-state */
/* eslint-disable no-undef */

import React, { Component, Fragment } from "react";
import { FiPlusCircle, FiMinusCircle, FiMoreHorizontal } from "react-icons/fi";
import moment from "moment";
import {
  FaSortAmountDown,
  FaSortAmountUp,
  FaRegSquare,
  FaCheckSquare,
  FaUser,
  FaPhone,
  FaComments,
  FaEnvelope,
  FaCar,
  FaTruck,
  FaClock,
} from "react-icons/fa";
import clsx from "clsx";
import { withRouter } from "react-router-dom";
import {
  MdClose,
  MdRefresh,
  MdCheckBox,
  MdCheckBoxOutlineBlank,
} from "react-icons/md";
import Axios from "../shared/axios";
import LeadsExpander from "./LeadsExpander";
import Checkbox from "./Shared/Checkbox";
import swal from "sweetalert2";
import ThreeDotsMenu from "./ThreeDotsMenu";
import PingPriceButton from "./PingPriceButton";
import { pingAll } from "../pinger";
import { poster } from "../poster";
import { hasCommercial } from "./Helpers";
import { refererNormalizer } from "./StatGraphs/Referer";
import { capitalizeFirstLetter } from "./MultiSMS";
import HotnessStatus from "./HotnessStatus";
import affiliateIcons from "../shared/affiliateIcons";

// jeff@jeffweinstein.com
// josue.reyes@benabbott.com
// dan@hw-lawfirm.com
// jim@torklaw.com
// bspinks@tyler.net
// jdm@dehoyosinjury.com
// dsalazar@hurtinhouston.com
const sheets = {
  "5f45773731eca4208532f16e": "1vizmkNysXzjSrEt1UpfoejPBZzVeK6ISFUQdW2fJCHs",
  "5e179ad9d7b5f52a74f62ab3": "1tULuuZeIZgHQzbLD5f7kRF9Du6RLm4gbRSBAe-XhL34",
  "5f1863a7b22f9c59f0abddd5": "1mj6O42GgZnwJp8iKpFHJxdc2j5_BbAb1ri5dGPU-oLE",
  "5f8de9f032878c4ad4b22d79": "1EZuOOYyk512HoVS9U0S5CU1myR1iYOOZ4LkbPX7l97o",
  "5f11d0eec17e89260db7b93f": "1No-RWETrhoD1rEn-AymPuLM7aVnr9QtDtK6FOW2TZKA",
  "5ff3569d2b78ab3ed6cb9e68": "15uTFIbrLE524hTOaBDyIxVc7td-cTA2kDO3fWrqaiY8",
  "6005bab2512f7c0fd513b624": "1bGSiEfF7H25-C4qoprRpLaF76RwhAxeXkJ9Fh8fh8xw",
};
const leadValueDeduct = {
  "6037cb1630a4d2001997e032": 0.4,
  "5fd69ae52533130bac0f143f": 0.4,
};

const refererIcons = {
  "paid google": "paid-google.webp",
  "paid google spanish": "es_ads.png",
  "paid google truck": "truck.png",
  "paid bing": "bing_paid.png",
  "google.com": "google.webp",
  "yahoo.com": "yahoo.webp",
  "duckduckgo.com": "duckduckgo.webp",
  "bing.com": "bing.webp",
  "paid microsoft O": "microsoft_o.webp",
  "paid microsoft S": "microsoft_s.webp",
  "paid microsoft A": "microsoft_a.webp",
  "facebook.com": "facebook.webp",
  "lang=es": "es.png",
};

const rejectReasons = {
  "bad hot": {
    1: "Looking for information",
    2: "Does Not Want Lawyer",
    3: "Not injured",
    4: "At-Fault Driver",
    5: "Invalid Phone Number",
    6: "SPAM Lead",
    7: "Wrong Category",
    8: "Wrong Phone Number",
    9: "Already Has Lawyer",
    10: "Expired SOL",
    11: "Non-MVA Related",
    12: "Duplicate Lead",
    custom: "Custom",
  },
  rejected: {
    1: "Does not want to speak with anyone/not interested",
    2: "At-fault",
    3: "Unable to contact",
    4: "Not Injured (no injuries in vehicle)",
    5: "Hit and Run/DEF left scene",
    6: "Did Not Hit Call Duration Requirement",
    7: "Sent Unverified",
    8: "Already has a lawyer",
    9: "Outside SOL",
    10: "Non-MVA Related",
    12: "Duplicate Lead",
    custom: "Custom",
  },
  deleted: {
    1: "Just looking for report",
    2: "Does not want to speak with anyone/not interested",
    3: "Not injured (no injuries in vehicle)",
    4: "At-fault/single car accident",
    5: "Looking for information",
    6: "Wrong number/invalid number",
    7: "Call drop",
    8: "Language/country not supported",
    9: "Already has a lawyer",
    10: "Outside SOL",
    11: "Non-MVA Related",
    12: "Duplicate Lead",
    custom: "Custom",
  },
};

window.swal = swal;
class AdminLeadsTable extends Component {
  state = {
    filter: "",
    sortBy: "createdAt",
    reverse: false,
    leads: this.props.leads,
    selected: undefined,
    targetLeadUser: [],
    targetLeadHotness: "cold",
    edit: undefined,
    editNoteId: null,
    callModal: undefined,
    emailModal: undefined,
    emailText: "",
    emailSubject: "",
    googleConnected: false,
    sheetName:
      moment().day(1).format("MM/DD") + "-" + moment().day(7).format("MM/DD"),
    eLocalModal: "",
    pingerModalState: "",
    pingerModalData: "",
    rejectNotes: "",
    rejectReason: "1",
    sending: false,
    autoSMS: "",
    populatedRejects: false,
    populating: undefined,
  };
  currentPing = 0;
  multiPingLeads = [];
  lastSelect = -1;
  /* istanbul ignore next */
  componentDidMount() {
    this.changeSort("createdAt");
    window.moment = moment;
    window.addEventListener("sms-sent", ({ detail }) => {
      this.state.leads.some((v) => {
        if (v._id === detail.lead) {
          v.sms_count = detail.sms_count;
          v.sms_count_read = detail.sms_count;
          return true;
        }
        return false;
      });
      this.setState({ leads: this.state.leads });
    });
    window.addEventListener("sms-mark-read", ({ detail }) => {
      this.state.leads.some((v) => {
        if (v._id === detail.lead) {
          v.sms_count_read = v.sms_count;
          v.tempShow = true;
          return true;
        }
        return false;
      });
      this.setState({ leads: this.state.leads });
    });
    this.initGoogleApi();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!this.state.selected && prevState.selected !== this.state.selected) {
      this.setState({ populatedRejects: false, rejectNotes: "" });
    }
  }

  initGoogleApi() {
    if (window.location.hostname !== "localhost")
      gapi.load("client:auth2", () => this.loadClient());
  }

  loadClient() {
    gapi.client
      .init({
        apiKey: "AIzaSyDHsA0Y85T5OPMaRUSO61nrQzJdS9V3l-M",
        clientId:
          "959807066369-4s7sr1hvkblv0ng9sqo7g09rq9umsq9f.apps.googleusercontent.com",
        discoveryDocs: [
          "https://sheets.googleapis.com/$discovery/rest?version=v4",
        ],
        scope: "https://www.googleapis.com/auth/spreadsheets",
      })
      .then(
        () => {
          // Listen for sign-in state changes.
          gapi.auth2
            .getAuthInstance()
            .isSignedIn /* istanbul ignore next */
            .listen((status) => this.updateSignInStatus(status));

          // Handle the initial sign-in state.
          this.updateSignInStatus(
            gapi.auth2.getAuthInstance().isSignedIn.get()
          );
          // authorizeButton.onclick = handleAuthClick;
          // signoutButton.onclick = handleSignoutClick;
        },
        /* istanbul ignore next */
        (error) => {
          swal.fire("error", JSON.stringify(error, null, 2), "error");
        }
      );
  }
  updateSignInStatus(googleConnected) {
    this.setState({ googleConnected });
  }

  sorter(arr, p, reverse) {
    if (reverse) {
      return arr.slice(0).sort(function (a, b) {
        return a[p] < b[p] ? 1 : a[p] > b[p] ? -1 : 0;
      });
    }
    return arr.slice(0).sort(function (a, b) {
      return a[p] > b[p] ? 1 : a[p] < b[p] ? -1 : 0;
    });
  }

  changeSort(sortBy) {
    let reverse = false;
    if (this.state.sortBy === sortBy) reverse = !this.state.reverse;
    this.setState({
      sortBy,
      reverse,
      leads: this.sorter(this.state.leads, sortBy, reverse),
    });
  }

  sortIcons(w) {
    if (w === this.state.sortBy && this.state.reverse)
      return <FaSortAmountDown size="16" />;

    if (w === this.state.sortBy && !this.state.reverse)
      return <FaSortAmountUp size="16" />;
  }

  toggleExpand(v) {
    if (!v.expanded) v.expanded = true;
    else v.expanded = false;
    this.setState({ leads: this.state.leads });
  }

  toggleMoreMenu(v) {
    if (!v.moreMenu) v.moreMenu = true;
    /* istanbul ignore next */ else v.moreMenu = false;
    this.setState({ leads: this.state.leads });
  }

  user(ids, accepted) {
    let users = [];
    ids.forEach((id) => {
      this.props.users.some((v) => {
        if (v._id === id && v._id !== accepted) {
          users.push(v.businessName);
          return true;
        }
        return false;
      });
    });
    return users.join(",<br>");
  }

  userInfo(id) {
    let user = {};
    this.props.users.some((v) => {
      if (v._id === id) {
        user = v;
        return true;
      }
      /* istanbul ignore next */
      return false;
    });
    return user;
  }

  reassign() {
    let rejectNotes = "";

    if (
      this.state.targetLeadHotness === "bad hot" ||
      this.state.targetLeadHotness === "rejected" ||
      this.state.targetLeadHotness === "deleted"
    ) {
      /* istanbul ignore next */
      if (this.state.rejectReason === "custom")
        rejectNotes = this.state.rejectNotes;
      /* istanbul ignore next */ else
        rejectNotes =
          rejectReasons[this.state.targetLeadHotness][this.state.rejectReason] +
          (this.state.rejectNotes ? "\n--\n" + this.state.rejectNotes : "");
    } else {
      rejectNotes = this.state.rejectNotes;
    }

    Axios.app
      .post("/api/leads/asign", {
        lead_id: this.state.selected._id,
        assigned_to: this.state.targetLeadUser,
        lead_level: this.state.targetLeadHotness,
        rejectNotes,
        rejectedReason: this.state.rejectReason,
      })
      .then((d) => {
        // this.callExternalAPIs(this.state.selected);
        this.addToSheets(this.state.selected);
        this.state.leads.some((v) => {
          if (v._id === this.state.selected._id) {
            v.lead_level = this.state.targetLeadHotness;
            v.assigned_to = this.state.targetLeadUser;
            v.rejectNotes = this.state.rejectNotes;
            v.declined = [];
            /* istanbul ignore next */
            if (v.lead_level === "rejected") {
              v.value = 0;
              v.value1 = 0;
            }
            return true;
          }
          return false;
        });
        this.setState({
          leads: this.state.leads,
          selected: undefined,
          targetLeadUser: [],
          rejectNotes: "",
        });
      })
      .catch((e) => {
        /* istanbul ignore next */
        console.log(e);
      });
  }
  save(e) {
    if (e) e.preventDefault();
    let key;
    this.state.leads.some((v, k) => {
      if (v._id === this.state.edit._id) {
        key = k;
        return true;
      }
      /* istanbul ignore next */
      return false;
    });
    this.state.leads[key] = this.state.edit;
    delete this.state.leads[key].moreMenu;
    return new Promise((resolve) => {
      Axios.app
        .put("/api/leads/update", {
          ...this.state.edit,
          lead_id: this.state.edit._id,
        })
        .then((d) => {
          this.setState({ leads: this.state.leads, edit: undefined });
          resolve();
        })
        .catch((e) => {
          /* istanbul ignore next */
          console.log(e);
        });
    });
  }
  handleMultiSelect(id) {
    if (this.state.targetLeadUser.includes(id)) {
      let key;
      this.state.targetLeadUser.some((v, k) => {
        if (v === id) {
          key = k;
          return true;
        }
        /* istanbul ignore next */
        return false;
      });
      this.state.targetLeadUser.splice(key, 1);
    } else {
      this.state.targetLeadUser.push(id);
    }

    let u;
    if (this.state.targetLeadUser.length === 1) {
      this.props.users.some((v) => {
        if (v._id === this.state.targetLeadUser[0]) {
          u = v;
          return true;
        }
        /* istanbul ignore next */
        return false;
      });
      /* istanbul ignore next */
    }

    this.setState({
      targetLeadUser: this.state.targetLeadUser,
      autoSMS: u && u.autoSMS && u.autoSMSText ? u.autoSMSText : "",
    });
  }
  toggleSelect(k) {
    this.lastSelect = k;
    this.state.leads[k].selected = !this.state.leads[k].selected;
    this.setState({ leads: this.state.leads });
  }
  anySelected() {
    return this.state.leads.some((v) => {
      if (v.selected) return true;
      return false;
    });
  }
  /* istanbul ignore next */
  shiftSelect(k) {
    this.state.leads.forEach((l, kk) => {
      if (this.lastSelect < k && this.lastSelect < kk && k >= kk)
        l.selected = true;
      if (this.lastSelect > k && this.lastSelect > kk && k <= kk)
        l.selected = true;
    });
    this.lastSelect = k;

    this.setState({ leads: this.state.leads });

    if (window.getSelection) {
      window.getSelection().removeAllRanges();
    } else if (document.selection) {
      document.selection.empty();
    }
  }
  toggleSelectAll() {
    /* istanbul ignore next */
    if (this.anySelected()) {
      this.state.leads.forEach((l) => {
        l.selected = false;
      });
    } else {
      this.state.leads.forEach((l) => {
        l.selected = true;
      });
    }
    this.setState({ leads: this.state.leads });
  }
  delete() {
    swal
      .fire({
        type: "question",
        title: "Are you sure you want to delete selected leads?",
        text: "This action cannot be undone.",
        showCancelButton: true,
        reverseButtons: true,
      })
      .then((v) => {
        if (v.value) {
          let idsToDelete = [];
          this.state.leads.forEach((v) => {
            if (v.selected) idsToDelete.push(v._id);
          });
          Axios.app.post("/api/leads/delete", { idsToDelete });
          this.setState(
            {
              leads: this.state.leads.filter((v) => {
                return !idsToDelete.includes(v._id);
              }),
            },
            () => this.props.updateLeads(this.state.leads)
          );
        }
      })
      .catch((e) => {
        /* istanbul ignore next */
        console.log(e);
      });
  }
  /* istanbul ignore next */
  unsubscribe() {
    swal
      .fire({
        type: "question",
        title: "Are you sure you want to unsubscribe selected leads?",
        text: "This action cannot be undone.",
        showCancelButton: true,
        reverseButtons: true,
      })
      .then((v) => {
        if (v.value) {
          let ids = [];
          this.state.leads.forEach((v) => {
            if (v.selected) ids.push(v._id);
          });
          this.setState(
            {
              leads: this.state.leads.map((v) => {
                if (ids.includes(v._id)) {
                  v.firstName = "Unsubscribed";
                  v.lastName = "User";
                  v.email = "";
                  v.phone = "";
                  v.selected = false;
                }
                return v;
              }),
            },
            () => this.props.updateLeads(this.state.leads)
          );
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }

  superAssignDo(lead, callbackDone) {
    let rejectNotes = "";

    if (
      this.state.targetLeadHotness === "bad hot" ||
      this.state.targetLeadHotness === "rejected" ||
      this.state.targetLeadHotness === "deleted"
    ) {
      /* istanbul ignore next */
      if (this.state.rejectReason === "custom")
        rejectNotes = this.state.rejectNotes;
      else
        rejectNotes =
          rejectReasons[this.state.targetLeadHotness][this.state.rejectReason] +
          (this.state.rejectNotes ? "\n--\n" + this.state.rejectNotes : "");
    } else {
      rejectNotes = this.state.rejectNotes;
    }

    let lead_value = 0;
    /* istanbul ignore next */
    if (lead.value) lead_value = lead.value;
    else
      this.props.users.some((u) => {
        if (u._id === this.state.targetLeadUser[0]) {
          lead_value = u.lead_value;

          if (
            u.statePrices &&
            u.statePrices[lead.state] &&
            u.statePrices[lead.state].price
          ) {
            lead_value = u.statePrices[lead.state].price;
          }

          return true;
        }
        /* istanbul ignore next */
        return false;
      });

    /* istanbul ignore next */
    if (leadValueDeduct[this.state.targetLeadUser[0]])
      lead_value = Math.floor(
        lead_value * leadValueDeduct[this.state.targetLeadUser[0]]
      );
    Axios.app
      .post("/api/leads/super-lead", {
        lead_id: lead._id,
        assigned_to: this.state.targetLeadUser,
        lead_level: this.state.targetLeadHotness,
        rejectNotes,
        lead_value,
      })
      .then(async (d) => {
        if (d.data.success) {
          this.addToSheets(lead);
          this.setState({ pingerModalData: "" });

          /* istanbul ignore next */
          if (this.state.autoSMS) {
            await Axios.app.post("/api/twilio/sms/send", {
              lead_id: lead._id,
              text: this.state.autoSMS.replace(
                /\[FIRST_NAME\]/g,
                capitalizeFirstLetter(lead.firstName)
              ),
            });
            lead.sms_count = lead.sms_count + 1;
            lead.sms_count_read = lead.sms_count;
            this.setState({
              autoSMS: "",
              leads: [...this.state.leads],
            });
          }

          /* istanbul ignore next */
          await poster(
            lead,
            this.state.targetLeadHotness === "ping-1" ||
              this.state.targetLeadHotness === "ping-2"
              ? "sold"
              : this.state.targetLeadHotness,
            this.state.targetLeadUser[0],
            (pingerModalState) => this.setState({ pingerModalState }),
            (pingerModalData) =>
              this.setState({
                pingerModalData:
                  this.state.pingerModalData + "<br>" + pingerModalData,
              }),
            (external_apis, value) => {
              lead_value = value;
              if (leadValueDeduct[this.state.targetLeadUser[0]])
                lead_value = Math.floor(
                  lead_value * leadValueDeduct[this.state.targetLeadUser[0]]
                );

              Axios.app
                .put("/api/leads/update", {
                  ...lead,
                  value: lead_value,
                  lead_id: lead._id,
                })
                .then((dd) => {})
                .catch((e) => {
                  console.log(e);
                });
            }
          );

          /* istanbul ignore next */
          this.state.leads.some(async (v) => {
            if (v._id === lead._id) {
              v.lead_level = this.state.targetLeadHotness;
              v.assigned_to = this.state.targetLeadUser;
              v.declined = [];
              v.accepted = this.state.targetLeadUser[0];
              v.value = lead_value;
              v.rejectNotes = this.state.rejectNotes;
              if (v.lead_level === "rejected") {
                v.value = 0;
                v.value1 = 0;
              }

              window.dispatchEvent(
                new CustomEvent("update-prepaid-lead-count", {
                  detail: {
                    user: this.state.targetLeadUser[0],
                    prepaid_leads: d.data.prepaid_leads,
                  },
                })
              );
              return true;
            }
            return false;
          });
          this.setState({
            leads: [...this.state.leads],
          });
          if (callbackDone) callbackDone();
        } else {
          /* istanbul ignore next */
          swal.fire("error", d.data.error, "error");
          /* istanbul ignore next */
          if (callbackDone) callbackDone();
        }
      })
      .catch((e) => {
        /* istanbul ignore next */
        swal.fire("error", JSON.stringify(e.response.data), "error");
      });
  }
  superAssign() {
    swal
      .fire({
        type: "question",
        title: "Are you sure you want to super assign this lead?",
        text: "This action cannot be undone.",
        showCancelButton: true,
        reverseButtons: true,
      })
      .then((v) => {
        if (v.value) {
          this.superAssignDo(this.state.selected, () => {
            this.setState({
              selected: undefined,
              targetLeadUser: [],
              rejectNotes: "",
            });
          });
        }
      })
      .catch((e) => {
        /* istanbul ignore next */
        console.log(e);
      });
  }

  /* istanbul ignore next */
  multiSuperAssign() {
    swal
      .fire({
        type: "question",
        title: "Are you sure you want to super assign multiple leads?",
        text: "This action cannot be undone.",
        showCancelButton: true,
        reverseButtons: true,
      })
      .then((v) => {
        if (v.value) this.startRecursive(this.state.selected[0]);
      })
      .catch((e) => {
        /* istanbul ignore next */
        console.log(e);
      });
  }

  /* istanbul ignore next */
  startRecursive(lead) {
    this.superAssignDo(lead, () => {
      this.state.selected.shift();
      if (this.state.selected.length) {
        this.setState({ selected: [...this.state.selected] });
        this.startRecursive(this.state.selected[0]);
      } else {
        swal.fire("Success!", "Multiple leads assigned.", "success");
        this.setState({
          selected: undefined,
          targetLeadUser: [],
          rejectNotes: "",
        });
      }
    });
  }

  saveNotes() {
    let lead;
    this.state.leads.forEach((v) => {
      if (v._id === this.state.editNoteId) {
        v.notes = document.getElementById("notes").value;
        lead = v;
      }
    });

    Axios.app
      .put("/api/leads/update", {
        ...lead,
        lead_id: lead._id,
      })
      .then((d) => {})
      .catch((e) => {
        /* istanbul ignore next */
        swal.fire("Could not save notes.", e.response.data.error, "error");
      });

    this.setState({ editNoteId: null, leads: this.state.leads }, () => {});
  }
  autoAssign() {
    if (
      this.state.selected.lead_level === "cold" ||
      this.state.selected.lead_level === "free"
    ) {
      /* istanbul ignore next */
      swal.fire(
        "Not doable",
        "Auto assign cannot be applied to cold or free leads.",
        "info"
      );
    } else {
      swal
        .fire({
          type: "question",
          title: "Are you sure you want to auto assign this lead?",
          text: "This action cannot be undone.",
          showCancelButton: true,
          reverseButtons: true,
        })
        .then((v) => {
          if (v.value) {
            Axios.app
              .post("/api/leads/auto-assign", {
                lead_id: this.state.selected._id,
              })
              .then((d) => {
                if (d.data.success) {
                  this.state.leads.some((v) => {
                    if (v._id === this.state.selected._id) {
                      v.assigned_to = d.data.lead.assigned_to;
                      return true;
                    }
                    return false;
                  });
                  this.setState({
                    leads: this.state.leads,
                    selected: undefined,
                    targetLeadUser: [],
                  });
                }
              });
          }
        })
        .catch((e) => {
          /* istanbul ignore next */
          console.log(e);
        });
    }
  }
  openSmsConversation(lead) {
    window.dispatchEvent(
      new CustomEvent("open-sms-conversation", {
        detail: lead,
      })
    );
  }
  increaseCallCount() {
    Axios.app.post("/api/leads/counter/call", {
      lead_id: this.state.callModal._id,
    });

    if (!this.state.callModal.call_count && this.state.callModal !== 0) {
      this.state.callModal.call_count = 0;
      this.state.callModal.firstCall = new Date();
    }
    /* istanbul ignore next */
    if (!this.state.callModal.callList) this.state.callModal.callList = [];
    this.state.callModal.callList.push(new Date());

    this.state.callModal.call_count++;
    this.setState({ callModal: this.state.callModal, leads: this.state.leads });
  }

  sendEmail() {
    this.setState({ emailAjax: true });
    Axios.app
      .post("/api/leads/counter/email", {
        lead_id: this.state.emailModal._id,
        text: this.state.emailText,
        subject: this.state.emailSubject,
      })
      .then((d) => {
        if (d.data.success) {
          this.state.emailModal.email_count = d.data.lead.email_count;
          this.setState({
            emailAjax: false,
            emailText: "",
            emailModal: undefined,
            leads: this.state.leads,
            emailSubject: "",
          });
        } else swal.fire("Error", "Could not send email", "error");
      })
      .catch((e) => {
        /* istanbul ignore next */
        swal.fire("Error", "Could not send email", "error");
      });
  }
  duplicate(lead, key) {
    swal
      .fire({
        type: "question",
        title: "Are you sure you want to duplicate this lead?",
        showCancelButton: true,
        reverseButtons: true,
      })
      .then((v) => {
        if (v.value) {
          delete lead._id;
          delete lead.__v;
          delete lead.assigned_to;
          delete lead.accepted;
          delete lead.declined;
          lead.duplicate = true;
          Axios.app
            .post("/api/leads/duplicate", { lead })
            .then((d) => {
              if (d.data.success) {
                this.state.leads.splice(key, 0, d.data.lead);
                this.setState({ leads: this.state.leads });
              } else swal.fire("Error", "Could not duplicate lead", "error");
            })
            .catch((e) => {
              /* istanbul ignore next */
              swal.fire("Error", "Could not duplicate lead", "error");
            });
        }
      })
      .catch((e) => {
        /* istanbul ignore next */
        console.log(e);
      });
  }
  /* istanbul ignore next */
  findState(lead) {
    if (!lead) return "";
    if (lead.state) return lead.state;
    if (lead.accident) return lead.accident.state;
    if (lead.city && lead.city.indexOf(", ") > -1)
      return lead.city
        .split(", ")
        [lead.city.split(", ").length - 2].slice(0, 2);
    else return lead.city || "";
  }
  /* istanbul ignore next */
  addToSheets(lead) {
    if (this.state.googleConnected) {
      this.state.targetLeadUser.forEach((u) => {
        if (sheets[u]) {
          this._addToSheets(u, lead);
        }
        if (u === "611bd223d47d930019ce7f26") this.addToELocalSheet(lead);
      });
    }
  }

  /* istanbul ignore next */
  _addToSheets(u, lead) {
    let sheetExists = false;
    gapi.client.sheets.spreadsheets
      .get({ spreadsheetId: sheets[u] })
      .then((d) => {
        let dd = JSON.parse(d.body);
        dd.sheets.some((v) => {
          if (v.properties.title === this.state.sheetName) {
            sheetExists = true;
            return true;
          }
          return false;
        });
        if (!sheetExists) {
          gapi.client.sheets.spreadsheets
            .batchUpdate({
              spreadsheetId: sheets[u],
              resource: {
                requests: [
                  {
                    addSheet: { properties: { title: this.state.sheetName } },
                  },
                ],
              },
            })
            .then((d) => {
              this.addDataToSheet(u, lead);
            });
        } else {
          this.addDataToSheet(u, lead);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }

  /* istanbul ignore next */
  addDataToSheet(u, lead) {
    let vehicle = {};
    let airbags = "";
    if (lead.accident && lead.vin) {
      lead.accident.vehicles.forEach((v) => {
        if (v.vin_code === lead.vin) {
          vehicle = v;
          if (v.participants) {
            v.participants.forEach((vv) => {
              airbags = airbags + vv.airbag + "; ";
            });
          }
        }
      });
    }
    gapi.client.sheets.spreadsheets.values
      .append({
        spreadsheetId: sheets[u],
        range: "'" + this.state.sheetName + "'!A:A",
        valueInputOption: "USER_ENTERED",
        majorDimension: "ROWS",
        insertDataOption: "INSERT_ROWS",
        // resource: {
        values: [
          [
            lead.firstName,
            lead.lastName,
            this.state.targetLeadHotness === "cold"
              ? "mailer"
              : this.state.targetLeadHotness,
            lead.email,
            this.state.targetLeadHotness === "hot" ||
            this.state.targetLeadHotness === "warm"
              ? lead.phone
              : "",
            lead.occupant,
            lead.vin,
            "https://app.myaccident.org/accident/" + lead.accidentId,
            lead.source,
            lead.createdAt,
            lead.accident ? lead.accident.date : "",
            lead.city || lead.accident.city,
            lead.accident ? lead.accident.state : "",
            vehicle.vehicle_maker,
            vehicle.vehicle_model_year,
            vehicle.vehicle_color,
            vehicle.damage_area_1,
            vehicle.damage_severity_1,
            vehicle.damage_area_2,
            vehicle.damage_severity_2,
            airbags,
          ],
        ],
        // }
      })
      .then((d) => {
        console.log("Lead added to spreadsheet");
      })
      .catch((e) => {
        console.log(e);
      });
  }

  /* istanbul ignore next */
  addToELocalSheet(l) {
    gapi.client.sheets.spreadsheets.values
      .append({
        spreadsheetId: "1DHSaeI6JqLj0gRajsKEDRZikmI9qtvn0Ab8C0CDSZGg",
        range: "'Affiliate Rejection Tracker'",
        valueInputOption: "USER_ENTERED",
        majorDimension: "ROWS",
        insertDataOption: "INSERT_ROWS",
        // resource: {
        values: [
          [
            moment(l.createdAt).format("MM/DD/YYYY"),
            '=IF(INDIRECT("R[0]C[-1]", FALSE)="","",INDIRECT("R[0]C[-1]", FALSE)-$G$4)',
            l.firstName,
            l.lastName,
          ],
        ],
      })
      .then(() => {
        console.log("Added");
      })
      .catch((e) => {
        swal.fire("Error adding to spreadsheet", JSON.stringify(e), "error");
      });
  }
  /* istanbul ignore next */
  ping(l) {
    if (!this.multiPingLeads.length) this.setState({ pingerModalData: "" });
    pingAll(
      l,
      (pingerModalState) => {
        this.setState({ pingerModalState });
        if (pingerModalState === "done") {
          this.currentPing++;
          if (this.multiPingLeads[this.currentPing]) {
            this.ping(this.multiPingLeads[this.currentPing]);
          } else {
            this.multiPingLeads = [];
          }
        }
      },
      (pingerModalData) =>
        this.setState({
          pingerModalData:
            this.state.pingerModalData + "<br>" + pingerModalData,
        }),
      (external_apis, zip) => {
        Axios.app
          .put("/api/leads/update", {
            ...l,
            lead_id: l._id,
            external_apis,
            zip,
          })
          .then((dd) => {
            l.external_apis = external_apis;
            l.zip = zip;
            this.setState({ leads: this.state.leads });
          })
          .catch((e) => {
            console.log(e);
          });
      }
    );
  }

  multiPing() {
    this.setState({ pingerModalData: "" });
    this.currentPing = 0;
    this.multiPingLeads = this.state.leads.filter((v) => v.selected);
    this.ping(this.multiPingLeads[this.currentPing]);
  }

  assignToCC(k, id, emailTo) {
    swal
      .fire({
        type: "question",
        title: "Are you sure you want to assign to CC selected leads?",
        text: "This action cannot be undone.",
        showCancelButton: true,
        reverseButtons: true,
        input: "checkbox",
        inputPlaceholder: "Send Auto SMS",
        inputValue: "checked",
      })
      .then(({ value, dismiss }) => {
        if (!dismiss) {
          this.state.leads[k].assigned_to = [id];
          this.state.leads[k].emailTo = emailTo;
          this.setState({ leads: this.state.leads });
          Axios.app
            .post("/api/leads/asign/cc", this.state.leads[k])
            .then()
            .catch((e) => {
              /* istanbul ignore next */
              swal.fire(
                "Assign to CC failed",
                "Backend error. Check browser console for the details.",
                "error"
              );
              /* istanbul ignore next */
              this.state.leads[k].assigned_to = [];
              /* istanbul ignore next */
              this.setState({ leads: this.state.leads });
            });

          if (value === 1) {
            let text;
            this.props.users.some((u) => {
              if (u._id === id) {
                text = u.autoSMSText;
                return true;
              }
              return false;
            });
            if (!text)
              return swal.fire(
                "Error",
                "CC user doesn't have autosms text",
                "error"
              );

            let firstName =
              this.state.leads[k].firstName || this.state.leads[k].name || "";
            let city = this.state.leads[k].city || "unknown city";
            let st = this.state.leads[k].state || "unknown state";
            text = text
              .replace(/\[FIRST_NAME\]/g, capitalizeFirstLetter(firstName))
              .replace(/\[CITY\]/g, capitalizeFirstLetter(city))
              .replace(/\[STATE\]/g, st.toUpperCase());

            Axios.app
              .post("/api/twilio/sms/send", {
                lead_id: this.state.leads[k]._id,
                text,
              })
              .then((d) => {
                this.state.leads[k].sms_count++;
                this.state.leads[k].sms_count_read++;
                this.setState({ leads: [...this.state.leads] });
              })
              .catch((e) => {
                swal.fire("SMS send failed", String(e), "error");
              });
          }
        }
      });
  }

  killCC(k) {
    swal
      .fire({
        type: "question",
        title: "Are you sure you want to cease Call Center operation?",
        text: "This action cannot be undone.",
        showCancelButton: true,
        reverseButtons: true,
      })
      .then(({ dismiss }) => {
        if (!dismiss) {
          // console.log(this.state.leads[k]);
          setTimeout(() => {
            swal.showLoading();
            Axios.app
              .post("/api/leads/kill-cc", this.state.leads[k])
              .then((d) => {
                if (d.data.success)
                  swal.fire({
                    title: "Success",
                    text: "Call Center ceased.",
                    type: "success",
                  });
                else
                  swal.fire({
                    title: "Error",
                    text: d.data?.message || "Unknown error.",
                    type: "error",
                  });
              })
              .catch((e) => {
                /* istanbul ignore next */
                swal.fire(
                  "Cease CC failed",
                  "Backend error. Check browser console for the details.",
                  "error"
                );
              });
          }, 200);
        }
      });
  }

  multiDOA() {
    swal
      .fire({
        type: "question",
        title: "Are you sure you want to set selected leads to DOA?",
        text: "This action cannot be undone.",
        showCancelButton: true,
        reverseButtons: true,
      })
      .then((v) => {
        if (v.value) {
          const ids = [];
          this.state.leads.forEach((v) => {
            if (v.selected) {
              ids.push(v._id);
              v.lead_level = "doa";
            }
          });
          this.setState({ leads: this.state.leads });
          Axios.app
            .put("/api/leads/update/leadLevel", { ids })
            .then(() => {
              swal.fire("Success", "Lead levels set to D.O.A.", "success");
            })
            .catch((e) => {
              /* istanbul ignore next */
              swal.fire(
                "MultiDOA failed on backend",
                "Backend error. Check browser console for the details.",
                "error"
              );
            });
        }
      })
      .catch((e) => {
        /* istanbul ignore next */
        console.log(e);
      });
  }

  multiSMS() {
    window.dispatchEvent(
      new CustomEvent("open-multi-sms", {
        detail: {
          leads: this.state.leads.filter((v) => v.selected),
        },
      })
    );
  }

  renderAffiliateSourceColumn(v) {
    if (v.callback)
      /* istanbul ignore next */
      return (
        <>
          <span
            className="pending"
            style={{ cursor: "pointer" }}
            onClick={() =>
              this.setState({
                edit: JSON.parse(JSON.stringify(v)),
              })
            }
          >
            <FaClock
              style={{
                position: "relative",
                top: 2,
                left: 2,
                marginRight: 5,
              }}
            />
            {moment(v.callback_date + " " + v.callback_time).calendar()}{" "}
            {v.callback_timezone}
          </span>
          {v.affiliate && (
            <span className="pending Accepted">{this.user([v.affiliate])}</span>
          )}
        </>
      );

    if (v.affiliate)
      return (
        <span className="pending Accepted">{this.user([v.affiliate])}</span>
      );

    return (
      <span className="pending" title={v.ad_owner ? v.ad_owner.email : ""}>
        {v.source}
        {v.carBrain && (
          <FaCar
            style={{ position: "relative", top: 2, left: 2 }}
            title="CarBrain lead"
          />
        )}
        {v.ad_owner ? (
          <FaUser style={{ position: "relative", top: 2, left: 2 }} />
        ) : null}
        {hasCommercial(v) ? (
          <span className="commercial" title="Commercial vehicle involved">
            <FaTruck />
          </span>
        ) : null}
      </span>
    );
  }

  sendCallbackEmail() {
    this.setState({ sending: true });
    Axios.app
      .put("/api/leads/update/time/update", {
        callback_date: this.state.edit.callback_date,
        callback_time: this.state.edit.callback_time,
        callback_timezone: this.state.edit.callback_timezone,
        id: this.state.edit._id,
      })
      /* istanbul ignore next */
      .then(() => {
        /* istanbul ignore next */
        this.save()
          .then(() => {
            this.setState({ sending: false });
            swal.fire("Email sent!", "...and lead is updated.", "success");
          })
          .catch((e) => {
            /* istanbul ignore next */
            console.log(e);
          });
      })
      .catch((e) => {
        /* istanbul ignore next */
        this.setState({ sending: false });
        /* istanbul ignore next */
        swal.fire("Email was not sent!", JSON.stringify(e, null, 2), "error");
      });
  }
  /* istanbul ignore next */
  shouldComponentUpdate(next) {
    if (!next.unreadFilter && this.props.unreadFilter) {
      this.state.leads.forEach((v) => {
        delete v.tempShow;
      });
      this.setState({ leads: this.state.leads });
      return true;
    }
    return true;
  }
  /* istanbul ignore next */
  parseReferer(ref) {
    const devices = { c: "desktop", m: "mobile" };
    const out = { keyword: "", device: "" };
    if (!ref) return out;
    const u = new URLSearchParams(ref);
    out.keyword = u.get("keyword") || "";
    out.device = devices[u.get("device")] || "";
    return out;
  }

  unreadFilterCheck(v) {
    if (v.tempShow) return true;
    if (this.props.unreadFilter) {
      /* istanbul ignore next */
      return v.sms_count !== v.sms_count_read;
    }
    return true;
  }

  renderRefererIcon = (l) => {
    /* istanbul ignore next */
    if(l.affiliate === "6526dfe35ffad700192c0df9") {
      return (
        <img
          src={'/img/oncore.png'}
          alt={"Oncore"}
          title={"Oncore"}
          width={32}
          height={32}
        />
      )
    }
    if (l.source === "tawk.to")
      return (
        <img
          src={`/img/tawk.png`}
          alt={"Tawk.to"}
          title={"Tawk.to"}
          width={32}
          height={32}
          style={{ borderRadius: 5, marginTop: 3 }}
        />
      );

    if (affiliateIcons[l.affiliate])
      return (
        <img
          src={`/img/${affiliateIcons[l.affiliate]}`}
          alt={affiliateIcons[l.affiliate]}
          title={affiliateIcons[l.affiliate]}
          width={32}
          height={32}
          style={{ borderRadius: 5, marginTop: 3 }}
        />
      );

    let name = refererNormalizer(l.referer);
    let icon = refererIcons[name];
    if (icon)
      return (
        <img
          src={"/img/" + icon}
          alt={name}
          title={name}
          width={32}
          height={32}
          style={{ borderRadius: 5, marginTop: 3 }}
        />
      );
    return "";
  };

  toggleAutoSMS() {
    /* istanbul ignore next */
    if (this.state.autoSMS) return this.setState({ autoSMS: "" });

    let u;
    this.props.users.some((v) => {
      if (v._id === this.state.targetLeadUser[0]) {
        u = v;
        return true;
      }
      return false;
    });

    if (this.state.targetLeadUser.length !== 1) {
      this.setState({ autoSMS: "" });
      return swal.fire(
        "Error",
        "AutoSMS can work if only one business user is selected.",
        "error"
      );
    }

    if (!u.autoSMSText) {
      this.setState({ autoSMS: "" });
      return swal.fire(
        "Error",
        "This user doesn't have SMS text configured.",
        "error"
      );
    }

    this.setState({ autoSMS: u.autoSMSText });
  }

  populateRejectionReasons = () => {
    this.setState(
      { rejectNotes: "", populatedRejects: !this.state.populatedRejects },
      async () => {
        if (this.state.populatedRejects) {
          this.setState({ populating: "loading" });
          const response = await Axios.api.get(
            `/leads/${this.state.selected?._id}/generateRejection`,
            {
              params: {
                category:
                  rejectReasons[this.state.targetLeadHotness][
                    this.state.rejectReason
                  ],
              },
            }
          );
          const rejectionReason = response.data?.rejectionReason.replace(
            /\n/g,
            ""
          );
          this.setState({ rejectNotes: rejectionReason, populating: "done" });
        }
      }
    );
  };

  render() {
    return (
      <>
        {/* <button
          onClick={() =>
            this.addToELocalSheet({
              firstName: "TEST1",
              lastName: "TEST2",
              createdAt: moment(),
            })
          }
        >
          TEST add to elocal sheet
        </button> */}
        <div className={this.state.eLocalModal ? "modal show" : "modal"}>
          <h2>Contacting E-Local API:</h2>
          <div dangerouslySetInnerHTML={{ __html: this.state.eLocalModal }} />
        </div>
        <div className={this.state.selected ? "modal show" : "modal"}>
          <button
            className="close"
            onClick={() => this.setState({ selected: undefined })}
            data-testid="close-assign-lead-modal"
          >
            <MdClose />
          </button>
          <h2>Assign or Re-assign lead</h2>
          <div className="row">
            <div className="column">
              <label>Target Users:</label>
              <div className="multi-select">
                {this.props.users
                  .filter((u) => !u.archived && u.account_type !== "affiliate")
                  .filter((u) => {
                    let st = this.findState(this.state.selected);
                    if (!st) return true;
                    if (u.states?.includes("ALL")) return true;
                    if (u.states?.includes(st)) return true;
                    return false;
                  })
                  .map((u, k) => (
                    <a
                      onClick={() => this.handleMultiSelect(u._id)}
                      key={u._id}
                      className={
                        this.state.targetLeadUser.includes(u._id)
                          ? "selected"
                          : ""
                      }
                      data-testid="assign-lead-user-anchor"
                    >
                      {this.state.targetLeadUser.includes(u._id) ? (
                        <FaCheckSquare />
                      ) : (
                        <FaRegSquare />
                      )}
                      {u.businessName}
                      {u.leadEmail ? (
                        <FaEnvelope
                          title={"lead email: " + u.leadEmail}
                          style={{ marginLeft: 10 }}
                        />
                      ) : null}
                      {u.leadPhone ? (
                        <FaPhone
                          title={"lead phone: " + u.leadPhone}
                          style={{ marginLeft: 5 }}
                        />
                      ) : null}
                    </a>
                  ))}
              </div>
            </div>
            <div className="column">
              <label>Lead hotness:</label>
              <select
                value={this.state.targetLeadHotness}
                onChange={(e) =>
                  this.setState({
                    targetLeadHotness: e.target.value,
                    rejectReason: "1",
                  })
                }
                title="lead-hotness-selector"
              >
                <option value="sold">Sold</option>
                <option value="hot">Hot</option>
                {/* <option value="free">Free</option> */}
                <option value="bad hot">Bad hot</option>
                <option value="rejected">Rejected</option>
                <option value="deleted">Deleted</option>
                <option value="unverified">Unverified</option>
                <option value="doa">D.O.A.</option>
                <option value="cold">Cold</option>
                <option value="ping-1">Ping 1</option>
                <option value="ping-2">Ping 2</option>
                <option value="ready">Ready</option>
              </select>
              {this.state.targetLeadHotness === "sold" ? (
                <a
                  style={{ display: "flex", alignItems: "center" }}
                  onClick={() => this.toggleAutoSMS()}
                >
                  {this.state.autoSMS ? (
                    <MdCheckBox size={24} />
                  ) : (
                    <MdCheckBoxOutlineBlank size={24} />
                  )}
                  <strong style={{ marginLeft: 5 }}>Send Auto SMS</strong>
                </a>
              ) : null}
              {this.state.targetLeadHotness === "bad hot" ||
              this.state.targetLeadHotness === "rejected" ||
              this.state.targetLeadHotness === "deleted" ? (
                <div>
                  <label>Reject Reason:</label>
                  <select
                    value={this.state.rejectReason}
                    onChange={(e) => {
                      this.setState({ rejectReason: String(e.target.value) });
                    }}
                  >
                    {Object.keys(
                      rejectReasons[this.state.targetLeadHotness]
                    ).map((v) => (
                      <option value={v} key={v}>
                        {rejectReasons[this.state.targetLeadHotness][v]}
                      </option>
                    ))}
                  </select>
                  <div className="reject-notes">
                    {this.state.populating === "loading" &&
                    !this.state.rejectNotes ? (
                      <div className="loading-dots__container">
                        <div className="loading-dots"></div>
                      </div>
                    ) : null}
                    <label>Notes:</label>
                    <textarea
                      maxLength={200}
                      value={this.state.rejectNotes || ""}
                      onChange={(e) => {
                        this.setState({ rejectNotes: e.target.value });
                      }}
                    />
                  </div>
                </div>
              ) : (
                <>
                  <br />
                  <br />
                  <br />
                  <br />
                </>
              )}
              {this.state.targetLeadHotness === "bad hot" &&
              this.state.targetLeadUser.length === 1 &&
              this.state.targetLeadUser.includes("5e0b72ffb03648236344b7f4") ? (
                <div className="populate-rejection">
                  <a onClick={this.populateRejectionReasons}>
                    {this.state.populatedRejects ? (
                      <FaCheckSquare />
                    ) : (
                      <FaRegSquare />
                    )}
                    <span>Populate Rejection Reason</span>
                  </a>
                </div>
              ) : null}
              <p>
                {this.state.targetLeadUser.length} user
                {this.state.targetLeadUser.length === 1
                  ? ""
                  : "s"} selected.{" "}
                <a onClick={() => this.setState({ targetLeadUser: [] })}>
                  Clear all.
                </a>
              </p>
              <br />
              <small>
                * super assign is enabled only when 1 user is selected and when
                lead type is not "bad hot"
                <br />
                ** normal assign is enabled when at least one user is selected
              </small>
            </div>
          </div>
          <div className="gapi">
            {this.state.googleConnected ? (
              <div>
                Google sheets API connected.{" "}
                <a onClick={() => gapi.auth2.getAuthInstance().signOut()}>
                  Disconnect
                </a>
                .
                {this.state.targetLeadUser.map((v) => {
                  if (sheets[v])
                    return (
                      <div key={sheets[v]}>
                        Lead will be added to{" "}
                        <a
                          href={
                            "https://docs.google.com/spreadsheets/d/" +
                            sheets[v] +
                            "/edit#gid=0"
                          }
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          this spreadsheet
                        </a>
                        , tab "{this.state.sheetName}".
                      </div>
                    );
                  return null;
                })}
              </div>
            ) : (
              <div>
                Google sheets API is NOT connected. Leads will not be added to
                dedicated google sheets.{" "}
                <a onClick={() => gapi.auth2.getAuthInstance().signIn()}>
                  Click here to connect
                </a>
                .
              </div>
            )}
          </div>
          <div className="footer">
            <button
              onClick={() => this.setState({ selected: undefined })}
              className="outline"
              disabled={this.state.pingerModalState}
            >
              Cancel
            </button>
            <button
              disabled={
                !this.state.targetLeadUser.length ||
                this.state.targetLeadUser.length > 1 ||
                this.state.targetLeadHotness === "bad hot" ||
                this.state.targetLeadHotness === "deleted" ||
                (this.state.targetLeadHotness === "cold" &&
                  !this.state.targetLeadUser.includes(
                    "6362c709e874c300193e716a"
                  ) &&
                  !this.state.targetLeadUser.includes(
                    "63ffe62a5fda3100197c3d42"
                  )) ||
                this.state.targetLeadHotness === "doa" ||
                this.state.targetLeadHotness === "hot" ||
                this.state.pingerModalState ||
                ((this.state.targetLeadHotness === "rejected" ||
                  this.state.targetLeadHotness === "bad hot" ||
                  this.state.targetLeadHotness === "deleted") &&
                  this.state.rejectReason === "custom" &&
                  !this.state.rejectNotes)
              }
              onClick={() => {
                Array.isArray(this.state.selected)
                  ? this.multiSuperAssign()
                  : this.superAssign();
              }}
              className="red"
            >
              SUPER ASSIGN
            </button>
            {Array.isArray(this.state.selected) ? null : (
              <button
                onClick={() => this.reassign()}
                disabled={
                  !this.state.targetLeadUser.length ||
                  this.state.pingerModalState ||
                  ((this.state.targetLeadHotness === "rejected" ||
                    this.state.targetLeadHotness === "deleted" ||
                    this.state.targetLeadHotness === "bad hot") &&
                    this.state.rejectReason === "custom" &&
                    !this.state.rejectNotes)
                }
              >
                Assign
              </button>
            )}
            {Array.isArray(this.state.selected) ? null : (
              <button
                onClick={() => this.autoAssign()}
                disabled={this.state.pingerModalState}
              >
                Auto Assign
              </button>
            )}
          </div>
        </div>
        <div
          className={
            this.state.pingerModalState === "running" ||
            this.state.pingerModalState === "done"
              ? "modal search show"
              : "modal search"
          }
        >
          <div
            dangerouslySetInnerHTML={{ __html: this.state.pingerModalData }}
          />
          {this.state.pingerModalState === "running" ? (
            <img src="img/ajax-loader.gif" alt="Loader" />
          ) : null}
          {this.state.pingerModalState === "done" ? (
            <div className="footer">
              <div />
              <button
                onClick={() =>
                  this.setState({ pingerModalData: "", pingerModalState: "" })
                }
              >
                Done
              </button>
            </div>
          ) : null}
        </div>
        <div className={this.state.edit ? "modal show" : "modal"}>
          <button
            className="close"
            onClick={() => this.setState({ edit: undefined })}
            data-testid="close-edit-lead-modal"
          >
            <MdClose />
          </button>
          <h2>Edit lead</h2>
          {this.state.edit ? (
            <form onSubmit={(e) => this.save(e)}>
              {this.state.edit.firstName ? (
                <div className="row">
                  <div className="column">
                    <label htmlFor="firstName-input">First Name:</label>
                    <input
                      value={this.state.edit.firstName}
                      onChange={(e) => {
                        this.state.edit.firstName = e.target.value;
                        this.setState({ edit: this.state.edit });
                      }}
                      id="firstName-input"
                    />
                  </div>
                  <div className="column">
                    <label htmlFor="lastName-input">Last Name:</label>
                    <input
                      value={this.state.edit.lastName}
                      onChange={(e) => {
                        this.state.edit.lastName = e.target.value;
                        this.setState({ edit: this.state.edit });
                      }}
                      id="lastName-input"
                    />
                  </div>
                </div>
              ) : (
                <div className="row">
                  <div className="column">
                    <label>Name</label>
                    <input
                      value={this.state.edit.name}
                      onChange={(e) => {
                        this.state.edit.name = e.target.value;
                        this.setState({ edit: this.state.edit });
                      }}
                    />
                  </div>
                </div>
              )}
              <div className="row">
                <div className="column">
                  <label htmlFor="phone-input">Phone:</label>
                  <input
                    value={this.state.edit.phone}
                    onChange={(e) => {
                      this.state.edit.phone = e.target.value;
                      this.setState({ edit: this.state.edit });
                    }}
                    id="phone-input"
                  />
                </div>
                <div className="column">
                  <label htmlFor="email-input">Email:</label>
                  <input
                    value={this.state.edit.email}
                    onChange={(e) => {
                      this.state.edit.email = e.target.value;
                      this.setState({ edit: this.state.edit });
                    }}
                    id="email-input"
                  />
                </div>
              </div>
              <div className="row">
                <div className="column">
                  <label htmlFor="city-input">City:</label>
                  <input
                    value={this.state.edit.city}
                    onChange={(e) => {
                      this.state.edit.city = e.target.value;
                      this.setState({ edit: this.state.edit });
                    }}
                    id="city-input"
                  />
                </div>
                <div className="column">
                  <label htmlFor="zip-input">Zip:</label>
                  <input
                    value={this.state.edit.zip}
                    onChange={(e) => {
                      this.state.edit.zip = e.target.value;
                      this.setState({ edit: this.state.edit });
                    }}
                    id="zip-input"
                  />
                </div>
                <div className="column" style={{ width: 250 }}>
                  <label htmlFor="state-input">State:</label>
                  <input
                    value={this.state.edit.state}
                    onChange={(e) => {
                      this.state.edit.state = e.target.value;
                      this.setState({ edit: this.state.edit });
                    }}
                    id="state-input"
                  />
                </div>
                <div className="column">
                  <label htmlFor="county-input">County:</label>
                  <input
                    value={this.state.edit.county}
                    onChange={(e) => {
                      this.state.edit.county = e.target.value;
                      this.setState({ edit: this.state.edit });
                    }}
                    id="county-input"
                  />
                </div>
              </div>

              <div className="row">
                <div className="column">
                  <label htmlFor="ip-address-input">Ip address:</label>
                  <input
                    value={this.state.edit.ip_address}
                    onChange={(e) => {
                      this.state.edit.ip_address = e.target.value;
                      this.setState({ edit: this.state.edit });
                    }}
                    id="ip-address-input"
                  />
                </div>
                <div className="column">
                  <label>Referrer:</label>
                  {this.renderRefererIcon(this.state.edit)}
                </div>
                <div className="column">
                  <label>Keyword:</label>
                  {this.parseReferer(this.state.edit.referer).keyword}
                </div>
                <div className="column">
                  <label>Device:</label>
                  {this.parseReferer(this.state.edit.referer).device}
                </div>
                <div className="column">
                  <label>Retainer Success:</label>
                  <Checkbox
                    text=""
                    checked={this.state.edit.retainerSuccess}
                    onClick={() => {
                      this.state.edit.retainerSuccess =
                        !this.state.edit.retainerSuccess;
                      this.setState({ edit: this.state.edit });
                    }}
                    data-testid="callback-retainerSuccess"
                  />
                </div>
              </div>
              <div className={this.state.sending ? "row ajax" : "row"}>
                <div
                  className="column"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Checkbox
                    text="Call Back"
                    checked={this.state.edit.callback}
                    onClick={() => {
                      this.state.edit.callback = !this.state.edit.callback;
                      this.setState({ edit: this.state.edit });
                    }}
                    data-testid="callback-checkbox"
                  />
                </div>
                <div className="column">
                  <label htmlFor="date-input">Date requested:</label>
                  <input
                    id="date-input"
                    disabled={!this.state.edit.callback}
                    value={this.state.edit.callback_date || ""}
                    type="date"
                    onChange={(e) => {
                      this.state.edit.callback_date = e.target.value;
                      this.setState({ edit: this.state.edit });
                    }}
                  />
                  <div className="row">
                    <div className="column">
                      {" "}
                      <label htmlFor="time-input">Time:</label>
                      <input
                        id="time-input"
                        disabled={!this.state.edit.callback}
                        value={this.state.edit.callback_time || ""}
                        type="time"
                        onChange={(e) => {
                          this.state.edit.callback_time = e.target.value;
                          this.setState({ edit: this.state.edit });
                        }}
                      />
                    </div>
                    <div className="column">
                      <label htmlFor="tz-input">TZ:</label>
                      <input
                        id="tz-input"
                        disabled={!this.state.edit.callback}
                        maxLength={2}
                        value={this.state.edit.callback_timezone || ""}
                        onChange={(e) => {
                          this.state.edit.callback_timezone = e.target.value;
                          this.setState({ edit: this.state.edit });
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div
                  className="column"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <button
                    disabled={
                      !this.state.edit.callback ||
                      !this.state.edit.callback_date ||
                      !this.state.edit.callback_time ||
                      !this.state.edit.callback_timezone
                    }
                    type="button"
                    onClick={() => {
                      this.sendCallbackEmail();
                    }}
                  >
                    Send
                  </button>
                </div>
              </div>
              {this.state.edit.affiliate &&
              this.props.users.filter(
                (u) =>
                  u._id === this.state.edit.affiliate && u.rejectionTracking
              ).length ? (
                <div className="row">
                  <div className="column">
                    <label htmlFor="rejection-tracking">
                      Rejection Tracking:
                    </label>
                    <select
                      id="rejection-tracking"
                      value={this.state.edit.rejectionTracking}
                      onChange={(e) => {
                        this.state.edit.rejectionTracking = e.target.value;
                        this.setState({ edit: this.state.edit });
                      }}
                    >
                      <option>Not Submitted</option>
                      <option>Submitted</option>
                      <option>Approved</option>
                      <option>Not Approved</option>
                    </select>
                  </div>
                </div>
              ) : null}

              <div className="row">
                <div className="column">
                  <label htmlFor="message-input">Message:</label>
                  <input
                    value={this.state.edit.message}
                    onChange={(e) => {
                      this.state.edit.message = e.target.value;
                      this.setState({ edit: this.state.edit });
                    }}
                    id="message-input"
                  />
                </div>
              </div>

              <div className="row">
                <div className="column">
                  <label htmlFor="description-input">Description:</label>
                  <input
                    value={this.state.edit.description}
                    onChange={(e) => {
                      this.state.edit.description = e.target.value;
                      this.setState({ edit: this.state.edit });
                    }}
                    id="description-input"
                  />
                </div>
              </div>

              <div className="row">
                <div className="column">
                  <label htmlFor="preferred-language-input">
                    Preferred language:
                  </label>
                  <input
                    value={this.state.edit.preferred_language}
                    onChange={(e) => {
                      this.state.edit.preferred_language = e.target.value;
                      this.setState({ edit: this.state.edit });
                    }}
                    id="preferred-language-input"
                  />
                </div>
              </div>

              <div className="row">
                <div className="column">
                  <label htmlFor="pending-value-input">Pending Value:</label>
                  <input
                    value={this.state.edit.value}
                    onChange={(e) => {
                      this.state.edit.value = e.target.value;
                      this.setState({ edit: this.state.edit });
                    }}
                    id="pending-value-input"
                  />
                </div>
                <div className="column">
                  <label htmlFor="settled-value-input">Settled Value:</label>
                  <input
                    value={this.state.edit.value2 || ""}
                    onChange={(e) => {
                      this.state.edit.value2 = e.target.value;
                      this.setState({ edit: this.state.edit });
                    }}
                    id="settled-value-input"
                  />
                </div>
              </div>

              <div className="row">
                <div className="column">
                  <Checkbox
                    text="Injured"
                    checked={this.state.edit.injured}
                    onClick={() => {
                      this.state.edit.injured = !this.state.edit.injured;
                      this.setState({ edit: this.state.edit });
                    }}
                    data-testid="injured-checkbox"
                  />
                </div>
                <div className="column">
                  <Checkbox
                    text="Fault"
                    checked={this.state.edit.fault}
                    onClick={() => {
                      this.state.edit.fault = !this.state.edit.fault;
                      this.setState({ edit: this.state.edit });
                    }}
                    data-testid="fault-checkbox"
                  />
                </div>
              </div>
              {this.state.edit.firstCall ? (
                <div className="row">
                  <div className="column">
                    <label>First Call:</label>
                    {moment(this.state.edit.createdAt).from(
                      this.state.edit.firstCall,
                      true
                    )}{" "}
                    after lead was created
                  </div>
                </div>
              ) : null}
              {this.state.edit.callList && this.state.edit.callList.length ? (
                <div className="row">
                  <div className="column">
                    <label>Call list:</label>
                    {this.state.edit.callList.map((c) => (
                      <div key={c}>{moment(c).format("LLL")}</div>
                    ))}
                  </div>
                </div>
              ) : null}
              {this.state.edit.affiliate ? (
                <div
                  style={{
                    backgroundColor: "#F0F0F0",
                    marginTop: 20,
                    padding: 10,
                    borderRadius: 5,
                  }}
                >
                  <h3>Affiliate Details</h3>
                  <br />
                  <label>Affiliate Name</label>
                  {this.userInfo(this.state.edit.affiliate).firstName}{" "}
                  {this.userInfo(this.state.edit.affiliate).lastName}{" "}
                  {this.userInfo(this.state.edit.affiliate).businessName}
                  <br />
                  <br />
                  <label>Affiliate ID</label>
                  {this.state.edit.affiliate}
                  <br />
                  <br />
                  <label>Lead Value</label>${this.state.edit.value}
                  <br />
                  <br />
                  <div className="row">
                    <div className="column">
                      <Checkbox
                        text="Pending"
                        checked={this.state.edit.status === "pending"}
                        onClick={() => {
                          this.state.edit.status = "pending";
                          this.state.edit.value2 = "";
                          this.setState({ edit: this.state.edit });
                        }}
                      />
                    </div>
                    <div className="column">
                      <Checkbox
                        text="Accepted"
                        checked={this.state.edit.status === "accepted"}
                        onClick={() => {
                          this.state.edit.status = "accepted";
                          this.state.edit.value2 = this.state.edit.value;
                          this.setState({ edit: this.state.edit });
                        }}
                      />
                    </div>
                    <div className="column">
                      <Checkbox
                        text="Rejected"
                        checked={this.state.edit.status === "rejected"}
                        onClick={() => {
                          this.state.edit.status = "rejected";
                          this.state.edit.value2 = "";
                          this.setState({ edit: this.state.edit });
                        }}
                      />
                    </div>
                  </div>
                  <br />
                  <br />
                  <label htmlFor="rejected-notes-textarea">Reject notes:</label>
                  <textarea
                    value={this.state.edit.rejectNotes || ""}
                    onChange={(e) => {
                      this.state.edit.rejectNotes = e.target.value;
                      this.setState({ edit: this.state.edit });
                    }}
                    id="rejected-notes-textarea"
                  />
                </div>
              ) : null}

              <div className="footer">
                <button
                  onClick={() => this.setState({ edit: undefined })}
                  className="outline"
                  type="button"
                >
                  Cancel
                </button>
                <button>Save</button>
              </div>
            </form>
          ) : null}
        </div>
        <div
          className={clsx("modal", { show: this.state.editNoteId !== null })}
        >
          <h2>Edit notes</h2>
          <textarea
            // value={this.state.note}
            // onChange={(e) => this.setState({ note: e.target.value })}
            id="notes"
          />
          <div className="footer">
            <button
              onClick={() => this.setState({ editNoteId: null })}
              className="outline"
              data-testid="cancel-edit-note-modal"
            >
              Cancel
            </button>
            <button onClick={() => this.saveNotes()}>Save</button>
          </div>
        </div>

        <div
          className={clsx("modal", {
            show: this.state.callModal !== undefined,
          })}
        >
          <h2>Call Lead</h2>
          {this.state.callModal ? (
            <div>
              <p>First Name: {this.state.callModal.firstName}</p>
              <p>Last Name: {this.state.callModal.lastName}</p>
              <p>
                Phone:{" "}
                <a href={"tel://" + this.state.callModal.phone}>
                  {this.state.callModal.phone}
                </a>
              </p>
              <p>Call count: {this.state.callModal.call_count || 0}</p>
              <br />
              <button
                onClick={() => this.increaseCallCount()}
                data-testid="call-count-button"
              >
                Increase Call Count
              </button>
            </div>
          ) : null}
          <div className="footer">
            <button
              onClick={() => this.setState({ callModal: undefined })}
              className="outline"
              style={{ visibility: "hidden" }}
            >
              Cancel
            </button>
            <button onClick={() => this.setState({ callModal: undefined })}>
              OK
            </button>
          </div>
        </div>

        <div
          className={clsx("modal", {
            show: this.state.emailModal !== undefined,
            ajax: this.state.emailAjax,
          })}
        >
          <h2>Email Lead</h2>
          {this.state.emailModal ? (
            <div data-testid="lead-email-modal-content">
              <p>First Name: {this.state.emailModal.firstName}</p>
              <p>Last Name: {this.state.emailModal.lastName}</p>
              <p>Email to: {this.state.emailModal.email}</p>
              <br />
              <input
                type="text"
                value={this.state.emailSubject}
                onChange={(e) =>
                  this.setState({ emailSubject: e.target.value })
                }
                placeholder="Enter subject here"
              />
              <br />
              <textarea
                value={this.state.emailText}
                onChange={(e) => this.setState({ emailText: e.target.value })}
                rows="10"
                placeholder="Enter email text here"
              />
            </div>
          ) : null}
          <div className="footer">
            <button
              onClick={() => this.setState({ emailModal: undefined })}
              className="outline"
            >
              Cancel
            </button>
            <button
              onClick={() => this.sendEmail()}
              disabled={
                !(this.state.emailModal && this.state.emailModal.email) ||
                !this.state.emailText ||
                !this.state.emailSubject
              }
            >
              SEND
            </button>
          </div>
        </div>

        <button
          style={{ float: "left", height: 38 }}
          onClick={() => this.props.refresh()}
          data-testid="refresh-leads"
        >
          <MdRefresh size="18" />
        </button>
        {this.anySelected() ? (
          <>
            <button style={{ marginLeft: 5 }} onClick={() => this.multiDOA()}>
              Multi D.O.A.
            </button>
            <button style={{ marginLeft: 5 }} onClick={() => this.multiSMS()}>
              Multi SMS
            </button>
            <button style={{ marginLeft: 5 }} onClick={() => this.multiPing()}>
              Multi Ping
            </button>
            <button
              style={{ marginLeft: 5 }}
              onClick={() =>
                this.setState({
                  selected: this.state.leads.filter((v) => v.selected),
                })
              }
            >
              Multi Assign
            </button>
            <button
              className="red"
              style={{ marginLeft: 5, display: "none" }}
              onClick={() => this.unsubscribe()}
            >
              Unsubscribe
            </button>
            <button
              className="red"
              style={{ marginLeft: 5 }}
              onClick={() => this.delete()}
            >
              Delete Selected
            </button>
          </>
        ) : null}
        <div style={{ position: "relative", float: "right" }}>
          <button
            onClick={() => {
              this.setState({ csvMenu: true });
            }}
          >
            Download CSV
          </button>
          <ThreeDotsMenu
            show={this.state.csvMenu}
            actions={["Standard", "Full"]}
            onClick={(kk) => {
              switch (kk) {
                case 0:
                  this.props.leadsCSV();
                  // console.log("download standard");
                  break;

                case 1:
                  this.props.leadsCSV(true);
                  // console.log("download full");
                  break;
              }
            }}
            hide={() => {
              this.setState({ csvMenu: false });
            }}
          />
        </div>
        <div style={{ clear: "both", height: 10 }} />
        <table id="leadsTable">
          <thead>
            <tr>
              <th data-testid="select-all-leads">
                <a onClick={() => this.toggleSelectAll()}>
                  {this.anySelected() ? <FaRegSquare /> : <FaCheckSquare />}
                </a>
              </th>
              <th></th>
              <th>
                <a onClick={() => this.changeSort("createdAt")}>
                  Date Added{this.sortIcons("createdAt")}
                </a>
              </th>
              <th>
                <a onClick={() => this.changeSort("firstName")}>
                  Name{this.sortIcons("firstName")}
                </a>
              </th>
              <th>State</th>
              <th>
                <a onClick={() => this.changeSort("email")}>
                  Email{this.sortIcons("email")}
                </a>
              </th>
              <th>Acc. / Ass. / Dec.</th>
              <th>
                <FaPhone size="20" />
              </th>
              <th>
                <FaEnvelope size="20" />
              </th>
              <th>
                <FaComments size="20" />
              </th>
              <th style={{ textAlign: "center" }}>Ping</th>
              <th>P.Value</th>
              <th>Referrer</th>
              <th style={{ textAlign: "center" }}>Status</th>
              <th style={{ textAlign: "center" }}>Affiliate/Source</th>
              <th style={{ textAlign: "center" }}>Lead Type</th>
              <th />
              <th />
            </tr>
          </thead>
          <tbody>
            {this.state.leads.map((v, k) => (
              <Fragment key={v._id}>
                <tr
                  style={{
                    display: this.unreadFilterCheck(v) ? "" : "none",
                  }}
                >
                  <td data-testid="lead-table-checkbox">
                    {v.selected ? (
                      <FaCheckSquare
                        size={24}
                        className="link"
                        onClick={() => this.toggleSelect(k)}
                      />
                    ) : (
                      <FaRegSquare
                        size={24}
                        className="link"
                        onClick={(e) => {
                          if (e.shiftKey) {
                            this.shiftSelect(k);
                          } else this.toggleSelect(k);
                        }}
                      />
                    )}
                  </td>
                  <td>
                    <a onClick={() => this.toggleExpand(v)}>
                      {v.expanded ? (
                        <FiMinusCircle size={24} />
                      ) : (
                        <FiPlusCircle size={24} />
                      )}
                    </a>
                  </td>
                  <td>{moment(v.createdAt).format("lll")}</td>
                  <td>
                    {v.duplicate ? (
                      <a
                        onClick={() =>
                          this.props.showDuplicates(v.email + "|" + v.phone)
                        }
                      >
                        *
                      </a>
                    ) : (
                      ""
                    )}{" "}
                    {v.firstName} {v.lastName}
                  </td>
                  <td>{this.findState(v)}</td>
                  <td>{v.email}</td>
                  <td>
                    {v.accepted ? (
                      <div
                        className="pending Accepted"
                        dangerouslySetInnerHTML={{
                          __html: this.user([v.accepted]),
                        }}
                      />
                    ) : null}
                    <div
                      dangerouslySetInnerHTML={{
                        __html: this.user(v.assigned_to, v.accepted),
                      }}
                    />
                    <div
                      className="declined"
                      dangerouslySetInnerHTML={{
                        __html: this.user(v.declined),
                      }}
                    />
                  </td>
                  <td>
                    <a onClick={() => this.setState({ callModal: v })}>
                      {v.call_count || 0}
                    </a>
                  </td>
                  <td>
                    <a onClick={() => this.setState({ emailModal: v })}>
                      {v.email_count || 0}
                    </a>
                  </td>
                  <td>
                    <a
                      onClick={() => this.openSmsConversation(v)}
                      className={
                        v.sms_count !== v.sms_count_read ? "badge" : ""
                      }
                    >
                      {v.sms_count || 0}
                    </a>
                  </td>
                  <td>
                    <PingPriceButton l={v} ping={() => this.ping(v)} />
                    {/* {v.e_lead_price ? (
                      <div
                        className="pending Accepted"
                        onClick={() => this.ping(v)}
                        style={{ cursor: "pointer" }}
                      >
                        ${v.e_lead_price}
                      </div>
                    ) : (
                      <button onClick={() => this.ping(v)}>ping</button>
                    )} */}
                  </td>
                  <td>
                    <span className={v.value ? "hotness hot" : "pending"}>
                      ${v.value || 0}
                    </span>
                  </td>
                  <td style={{ textAlign: "center" }}>
                    {this.renderRefererIcon(v)}
                  </td>
                  <td>
                    <HotnessStatus
                      lead={v}
                      assignedTo={
                        this.user([v.accepted]) || this.user(v.assigned_to)
                      }
                    />
                  </td>
                  <td style={{ textAlign: "center" }}>
                    {this.renderAffiliateSourceColumn(v)}
                  </td>
                  <td>
                    <span className="pending">
                      {v.lead_type ? v.lead_type : "CALawyer"}
                    </span>
                  </td>
                  <td>
                    <button
                      disabled={v.accepted}
                      onClick={() =>
                        this.setState({
                          selected: v,
                          targetLeadUser: JSON.parse(
                            JSON.stringify(v.assigned_to)
                          ),
                          targetLeadHotness: v.lead_level
                            ? v.lead_level
                            : "cold",
                          rejectNotes: v.rejectNotes || "",
                        })
                      }
                    >
                      {v.assigned_to.length ? "Re-Assign" : "Assign"}
                    </button>
                  </td>
                  <td>
                    <button
                      onClick={() => this.toggleMoreMenu(v)}
                      style={{ background: v.notes ? "#00b7ff" : "#d7dee2" }}
                      data-testid="lead-three-dots-menu"
                    >
                      <FiMoreHorizontal />
                    </button>
                    <ThreeDotsMenu
                      show={v.moreMenu}
                      actions={[
                        "Edit",
                        "Notes",
                        "Duplicate",
                        // "Assign to CC - Jeff",
                        "Assign to CC - Affiliate M&M",
                        "Assigned to CC - General Affiliate",
                        "Add to spreadsheet",
                        "Kill CC",
                      ]}
                      onClick={(kk) => {
                        switch (kk) {
                          case 0:
                            this.setState({
                              edit: JSON.parse(JSON.stringify(v)),
                            });
                            break;

                          case 1:
                            this.setState({
                              editNoteId: v._id,
                            });
                            document.getElementById("notes").value =
                              v.notes || "";
                            break;

                          case 2:
                            this.duplicate({ ...v }, k);
                            break;

                          // case 3:
                          //   this.assignToCC(
                          //     k,
                          //     "610c1a1e73d69b001925584c",
                          //     "1210001588@arcoreengine-dot-conversionsupportlive-hrd.appspotmail.com"
                          //   );
                          //   break;
                          case 3:
                            this.assignToCC(
                              k,
                              "6397b827c1b12a0019162957",
                              "1210005850@armailstnr.appspotmail.com"
                            );
                            break;
                          case 4:
                            this.assignToCC(
                              k,
                              "63ff9a5e5fda3100197bbd70",
                              "1210005809@armailstnr.appspotmail.com"
                            );
                            break;
                          case 5:
                            this.addToELocalSheet(v);
                            break;
                          case 6:
                            this.killCC(k);
                            break;
                        }
                      }}
                      hide={() => {
                        v.moreMenu = false;
                        this.setState({ leads: [...this.state.leads] });
                      }}
                    />
                  </td>
                </tr>
                <tr
                  className={clsx("expander", {
                    show: v.expanded,
                  })}
                >
                  <td colSpan="18">
                    {v.expanded ? <LeadsExpander data={v} /> : null}
                  </td>
                </tr>
              </Fragment>
            ))}
          </tbody>
        </table>
      </>
    );
  }
}

export default withRouter(AdminLeadsTable);
