/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef, useMemo } from "react";
import Axios from "axios";
import moment from "moment";
import clsx from "clsx";
import { MdClose } from "react-icons/md";
import swal from "sweetalert2";
import {
  FaRegSquare,
  FaCheckSquare,
  FaSortAmountDown,
  FaSortAmountUp,
} from "react-icons/fa";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FiPlusCircle, FiMinusCircle, FiRefreshCw } from "react-icons/fi";
import EmailEditor from "../components/EmailEditor";
import Checkbox from "../components/Shared/Checkbox";

function useIsInViewport(ref) {
  const [isIntersecting, setIsIntersecting] = useState(false);

  const observer = useMemo(
    () =>
      new IntersectionObserver(([entry]) =>
        setIsIntersecting(entry.isIntersecting)
      ),
    []
  );

  useEffect(() => {
    observer.observe(ref.current);

    return () => {
      observer.disconnect();
    };
  }, [ref, observer]);

  return isIntersecting;
}

const alertField = (value, field) => {
  if (typeof value === "boolean") return value ? "Yes" : "No";
  if (field === "startDate" || field === "endDate")
    return moment(value).format("lll");
  return value;
};

const Expander = ({ alert }) => {
  const fields = [
    "case_id",
    "firstName",
    "lastName",
    "city",
    "zip",
    "phone",
    "hasLawyer",
    "injured",
    "fault",
    "startDate",
    "endDate",
    "radius",
    "legal_consult",
    "type",
    "vehicle1Maker",
    "vehicle1Year",
  ];
  console.log(alert);
  if (alert)
    return (
      <div className="exp two-columns">
        {fields.map((f) => {
          if (alert.hasOwnProperty(f))
            return (
              <p key={f}>
                <strong>{f}: </strong> {alertField(alert[f], f)}
              </p>
            );
          return null;
        })}
        <p>
          <strong>Matches:</strong> {alert.matches_no}
        </p>
      </div>
    );
  return null;
};

const perPage = 100;

export default function AlertAdmin() {
  const [loading, setLoading] = useState(true);
  // const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [list, setList] = useState([]);
  const [matches, setMatches] = useState(undefined);
  const [editNoteId, setEditNoteId] = useState(null);
  const [completedFilter, setCompletedFilter] = useState("false");
  const [matchesFilter, setMatchesFilter] = useState("");
  const [ajax, setAjax] = useState(false);
  const [sortBy, setSortBy] = useState("created_at");
  const [reverse, setReverse] = useState(true);
  const [startDate, setStartDate] = useState(
    moment().subtract(14, "days").hour(0).minute(0).second(0).toDate()
  );
  const [endDate, setEndDate] = useState(
    moment().hour(23).minute(59).second(59).toDate()
  );
  const [dataFilter, setDataFilter] = useState("");
  const [caseId, setCaseId] = useState("");
  const [phone, setPhone] = useState("");
  const [emailModalData, setEmailModalData] = useState(null);
  const [hideAuto, setHideAuto] = useState(false);
  const [email, setEmail] = useState("");
  const [badWords, setBadWords] = useState(1);
  const [edit, setEdit] = useState(null);
  const bottom = useRef(null);
  const selectedAlert = useRef(null);
  const noMoreResults = useRef(false);
  const [infinite, setInfinite] = useState(false);
  const isInViewport2 = useIsInViewport(bottom);

  const getAlerts = (p) => {
    if (p === 1) setLoading(true);
    let url = `/api/alerts/all?limit=${perPage}&sort=created_at&order=-1&startDate=${startDate}&endDate=${endDate}&badWords=${badWords}&page=${p}`;
    if (completedFilter !== "") url = url + `&completed=${completedFilter}`;
    if (dataFilter !== "") url = url + `&data=${dataFilter}`;
    if (caseId !== "") url = url + `&caseId=${caseId}`;
    if (phone !== "") url = url + `&phone=${phone}`;
    if (email !== "") url = url + `&email=${email}`;
    if (hideAuto) url = url + `&hideAuto=1`;
    if (matchesFilter !== "") url = url + `&matches=${matchesFilter}`;

    Axios.get(url).then((d) => {
      setLoading(false);
      setInfinite(false);
      if (p === 1) setList(sorter(d.data.alerts, sortBy, reverse));
      else setList([...list, ...sorter(d.data.alerts, sortBy, reverse)]);
      if (d.data.alerts.length === 0) noMoreResults.current = true;
      setTimeout(() => {
        if (document.getElementById("caseIdField"))
          document.getElementById("caseIdField").value = caseId;

        if (document.getElementById("phoneField"))
          document.getElementById("phoneField").value = phone;

        if (document.getElementById("emailField"))
          document.getElementById("emailField").value = email;
      }, 10);
    });
  };

  const findField = (w, d) => {
    if (d.user && d.user[w]) return d.user[w];
    if (d[w]) return d[w];
    return "-";
  };

  const saveNotes = () => {
    list.some((v) => {
      if (v._id === editNoteId) {
        v.notes = document.getElementById("notes").value;
        return true;
      }
      return false;
    });
    setList(list);
    Axios.post("/api/alert/notes", {
      id: editNoteId,
      notes: document.getElementById("notes").value,
    })
      .then((d) => {
        setEditNoteId(null);
      })
      .catch((e) => {
        swal.fire("Could not save notes.", e.response.data.error, "error");
      });
  };

  const toggleComplete = (key) => {
    list[key].completed = !list[key].completed;
    setList([...list]);
    Axios.get("/api/alert/status/toggle?id=" + list[key]._id);
  };

  const sendSMS = async (alert) => {
    let accidents = alert.matches
      .filter((m) => m.selected)
      .map((match, i) => {
        return `\n\nAccident ${i + 1} - https://app.myaccident.org/accident/${
          match.custom_id
        }`;
      });

    let phone;
    if (alert.user && alert.user.phone) {
      phone = alert.user.phone;
    } else {
      phone = alert.phone;
    }

    let firstName;
    if (alert.user && alert.user.firstName) {
      firstName = alert.user.firstName;
    } else {
      firstName = alert.firstName;
    }

    swal.queue([
      {
        title: "Send notification",
        text: `Sending notification to ${phone} . Please confirm!`,
        input: "textarea",
        inputValue: `MyAccident Update!\nHi ${firstName}, we have found a report that looks to be a match for your alert.\nPlease click on the link and review the accident information here:${accidents}\n\nPlease let us know if this is not the correct accident report. Have a great day,\nBen`,
        showCancelButton: true,
        confirmButtonText: "Confirm!",
        cancelButtonText: "Cancel",
        preConfirm: async (input) => {
          try {
            const response = await Axios.post(`/api/alert/notify/sms`, {
              alert,
              input,
            });
            if (response.data.success) {
              list.some((v) => {
                if (v._id === matches._id) {
                  v.notify_sms_count = v.notify_sms_count + 1;
                  return true;
                }
                return false;
              });
              setList([...list]);
            }

            swal.insertQueueStep({
              type: response.data.success ? "success" : "error",
              title: response.data.success ? "Success" : "Error",
              text: response.data.message,
              confirmButtonText: "Ok!",
            });
          } catch (e) {
            swal.insertQueueStep({
              type: "error",
              title: "Unable to send the report",
              confirmButtonText: "Ok!",
            });
          }
        },
      },
    ]);
  };

  const editSearchInput = (k) => {
    swal
      .fire({
        input: "text",
        title: "Edit search input",
        showCancelButton: true,
        inputValue: list[k].search_input,
      })
      .then((v) => {
        if (v.value !== undefined) {
          list[k].search_input = v.value;
          setList([...list]);
          Axios.put("/api/alert", list[k]).then((d) => {
            if (d.data.success && d.data.alert) {
              list[k] = d.data.alert;
              setList([...list]);
            }
          });
        }
      });
  };
  const editCityInput = (k) => {
    swal
      .fire({
        input: "text",
        title: "Edit city",
        showCancelButton: true,
        inputValue: list[k].city,
      })
      .then((v) => {
        if (v.value !== undefined) {
          list[k].city = v.value;
          setList([...list]);
          Axios.put("/api/alert", list[k]).then((d) => {
            if (d.data.success && d.data.alert) {
              list[k] = d.data.alert;
              setList([...list]);
            }
          });
        }
      });
  };

  const loadMatches = (v) => {
    setMatches(v);
    setAjax(true);
    selectedAlert.current = v;
    Axios.get("/api/alert/fetch?id=" + v._id).then((d) => {
      setAjax(false);
      if (d.data.success) {
        setMatches(d.data.alert);
      } else {
        swal.fire(
          "Error",
          "Couldn't get possible matches from server.",
          "error"
        );
      }
    });
  };

  // const changeLimit = (l) => {
  //   setLimit(l);
  //   getAlerts(l);
  // };

  const toggleSelect = (k) => {
    list[k].selected = !list[k].selected;
    setList([...list]);
  };

  const toggleExpand = (k) => {
    list[k].expanded = !list[k].expanded;
    setList([...list]);
  };

  const askDelete = () => {
    swal
      .fire({
        type: "question",
        title: "Are you sure you want to delete selected alerts?",
        text: "This action cannot be undone.",
        showCancelButton: true,
        reverseButtons: true,
      })
      .then((v) => {
        if (v.value) {
          const ids = list.filter((v) => v.selected).map((v) => v._id);
          // console.log("SEND TO BACKEND", ids);
          Axios.post("/api/alerts/delete", { ids }).then((d) => {
            // console.log(d.data);
            const newList = list.filter((v) => !v.selected);
            setList([...newList]);
          });
        }
      });
  };

  const markComplete = () => {
    const ids = list.filter((v) => v.selected).map((v) => v._id);
    // console.log("SEND TO BACKEND", ids);
    Axios.put("/api/alerts/completed", { ids }).then((d) => {
      // console.log(d.data);
      list.forEach((v) => {
        v.completed = v.selected ? true : false;
        v.selected = false;
      });
      setList([...list]);
    });
  };

  const toggleMatchSelect = (k) => {
    matches.matches[k].selected = !matches.matches[k].selected;
    setMatches({ ...matches });
  };

  const selectAllMatches = () => {
    matches.matches.forEach((m) => {
      m.selected = true;
    });
    setMatches({ ...matches });
  };

  const selectNoneMatches = () => {
    matches.matches.forEach((m) => {
      m.selected = false;
    });
    setMatches({ ...matches });
  };

  const sorter = (arr, p, reverse) => {
    if (reverse) {
      return arr.slice(0).sort(function (a, b) {
        return a[p] < b[p] ? 1 : a[p] > b[p] ? -1 : 0;
      });
    }
    return arr.slice(0).sort(function (a, b) {
      return a[p] > b[p] ? 1 : a[p] < b[p] ? -1 : 0;
    });
  };

  const changeSort = (_sortBy) => {
    let _reverse = false;
    if (sortBy === _sortBy) _reverse = !reverse;
    setSortBy(_sortBy);
    setReverse(_reverse);
    setList([...sorter(list, _sortBy, _reverse)]);
  };

  const sortIcons = (w) => {
    if (w === sortBy && reverse) return <FaSortAmountDown size="16" />;

    if (w === sortBy && !reverse) return <FaSortAmountUp size="16" />;
  };

  const eliminate = () => {
    setAjax(true);
    const eliminate = matches.matches
      .filter((m) => m.selected)
      .map((m) => m.custom_id);

    Axios.post("/api/alerts/hide/matches", {
      id: selectedAlert.current._id,
      hidden: eliminate,
    })
      .then((d) => {
        matches.matches = matches.matches.filter((m) => !m.selected);
        setMatches({ ...matches });

        list.some((v) => {
          if (v._id === selectedAlert.current._id) {
            v.matches_no = v.matches_no - eliminate.length;
            return true;
          }
          return false;
        });
        setList([...list]);
        setAjax(false);
      })
      .catch((e) => {
        setAjax(false);
        swal.fire("Could not eliminate.", "API error", "error");
      });
  };

  const searchButtonClick = (e) => {
    e.preventDefault();
    setCaseId(document.getElementById("caseIdField").value);
    setPhone(document.getElementById("phoneField").value);
    setEmail(document.getElementById("emailField").value);
  };

  const startEdit = (v) => {
    setEdit(true);
    // console.log(v);
    const inputs = document.querySelectorAll("#editForm input");
    // console.log(inputs);
    inputs.forEach((i) => {
      i.value = v[i.name];
    });
  };

  const saveEdit = (e) => {
    if (e) e.preventDefault();
    const inputs = document.querySelectorAll("#editForm input");
    const data = {};
    // console.log(inputs);
    inputs.forEach((i) => {
      // console.log(i.name, i.value);
      data[i.name] = i.value;
    });
    list.some((v, k) => {
      if (v._id === data._id) {
        list[k] = { ...v, ...data, fullEdit: true };
        console.log(list[k]);
        Axios.put("/api/alert", list[k]).then((d) => {
          if (d.data.success && d.data.alert) {
            list[k] = d.data.alert;
            setList([...list]);
            setEdit(false);
          }
        });

        return true;
      }
      return false;
    });
  };

  useEffect(() => {
    noMoreResults.current = false;
    getAlerts(1);
  }, [
    completedFilter,
    startDate,
    endDate,
    dataFilter,
    caseId,
    phone,
    hideAuto,
    email,
    badWords,
    matchesFilter,
  ]);

  useEffect(() => {
    if (isInViewport2 && !infinite && !loading && !noMoreResults.current) {
      setInfinite(true);
      getAlerts(page + 1);
      setPage(page + 1);
    }
  }, [isInViewport2]);

  return (
    <div id="admin-page">
      <h1>Alerts</h1>
      <div className={edit ? "modal show" : "modal"}>
        <button className="close" onClick={() => setEdit(false)}>
          <MdClose />
        </button>
        <h2>Edit Alert</h2>
        <form onSubmit={(e) => saveEdit(e)} id="editForm">
          <input name="_id" type="hidden" />
          <div className="row">
            <div className="column">
              <label>First Name:</label>
              <input name="firstName" />
            </div>
            <div className="column">
              <label>Last Name:</label>
              <input name="lastName" />
            </div>
          </div>
          <div className="row">
            <div className="column">
              <label>Phone:</label>
              <input name="phone" />
            </div>
            <div className="column">
              <label>Email:</label>
              <input name="email" />
            </div>
          </div>
          <div className="row">
            <div className="column">
              <label>City:</label>
              <input name="city" />
            </div>
            <div className="column">
              <label>Zip:</label>
              <input name="zip" />
            </div>
            <div className="column" style={{ width: 250 }}>
              <label>State:</label>
              <input name="state" />
            </div>
          </div>
          {/* <div className="row">
            <div className="column">
              <label>StartDate:</label>
              <input name="startDate" />
            </div>
            <div className="column">
              <label>EndDate:</label>
              <input name="endDate" />
            </div>
          </div>
 */}
          <div className="footer">
            <button
              onClick={() => setEdit(false)}
              className="outline"
              type="button"
            >
              Cancel
            </button>
            <button type="submit">Save</button>
          </div>
        </form>
      </div>
      <div className={clsx("modal", { show: editNoteId })}>
        <h2>Edit notes</h2>
        <textarea
          // value={note} onChange={(e) => setNote(e.target.value)}
          id="notes"
        />
        <div className="footer">
          <button onClick={() => setEditNoteId(null)} className="outline">
            Cancel
          </button>
          <button onClick={() => saveNotes()}>Save</button>
        </div>
      </div>
      <div className={clsx("modal", { show: matches, ajax })}>
        <button className="close" onClick={() => setMatches(undefined)}>
          <MdClose />
        </button>
        <h2>Possible alert matches</h2>
        <div className="selection-buttons">
          <button onClick={() => selectAllMatches()}>Select all</button>
          <button onClick={() => selectNoneMatches()}>Select none</button>
        </div>
        {matches ? (
          <div>
            {matches.matches.map((m, k) => (
              <div key={k} className={m.selected ? "match selected" : "match"}>
                <p>
                  <strong>View:</strong>{" "}
                  <a
                    href={"https://app.myaccident.org/accident/" + m.custom_id}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Accident Link
                  </a>
                </p>
                <p>
                  <strong>Date:</strong> {moment(m.date).format("llll")}
                </p>
                <p>
                  <strong>Address:</strong> {m.location_address}
                </p>
                <p>
                  <strong>CaseID:</strong> {m.caseID}
                </p>
                {m.vehicles
                  ? m.vehicles.map((v, k) => (
                      <p key={k}>
                        <strong>Vehicle {k + 1}:</strong>
                        {v.vehicle_maker +
                          " " +
                          v.vehicle_model_year +
                          " " +
                          v.vehicle_color}
                      </p>
                    ))
                  : null}
                <button type="button" onClick={() => toggleMatchSelect(k)}>
                  Select
                </button>
              </div>
            ))}
          </div>
        ) : null}
        <div className="footer">
          <button onClick={() => setMatches(undefined)} className="outline">
            Cancel
          </button>
          <button onClick={() => setEmailModalData(matches)}>
            Send Email ({matches ? matches.notify_email_count : ""})
          </button>
          <button onClick={() => sendSMS(matches)}>
            Send SMS ({matches ? matches.notify_sms_count : ""})
          </button>
          <button onClick={() => eliminate()} className="red">
            Eliminate
          </button>
        </div>
      </div>
      <EmailEditor
        alert={emailModalData}
        close={() => setEmailModalData(null)}
        matches={matches ? matches.matches : []}
        onSuccess={(withSMS) => {
          list.some((v) => {
            if (v._id === matches._id) {
              v.notify_email_count = v.notify_email_count + 1;
              if (withSMS) v.notify_sms_count = v.notify_sms_count + 1;

              return true;
            }
            return false;
          });
          setList([...list]);
          setMatches(undefined);
        }}
      />
      {loading ? (
        <>
          <img
            src="/img/ajax-loader.gif"
            alt="loadingRequests..."
            className="loader-gif"
          />
          &nbsp;Loading...
        </>
      ) : (
        <div>
          <div className="row">
            <div className="column">
              <label>Matches:</label>
              <select
                value={matchesFilter}
                onChange={(e) => setMatchesFilter(e.target.value)}
              >
                <option value="">All</option>
                <option value="0">equals 0</option>
                <option value="1">more than 0</option>
              </select>
            </div>
            <div className="column">
              <label>Completed:</label>
              <select
                value={completedFilter}
                onChange={(e) => setCompletedFilter(e.target.value)}
              >
                <option value="">All</option>
                <option value="true">Completed</option>
                <option value="false">Not Completed</option>
              </select>
            </div>
            <div className="column">
              <label>Start date:</label>
              <DatePicker
                dateFormat={"MMMM d, yyyy"}
                className="datepicker"
                id="startdate"
                onChange={setStartDate}
                popperPlacement={"bottom"}
                selected={startDate}
                maxDate={endDate}
              />
            </div>
            <div className="column">
              <label>End date:</label>
              <DatePicker
                dateFormat={"MMMM d, yyyy"}
                className="datepicker"
                id="enddate"
                onChange={setEndDate}
                popperPlacement={"bottom"}
                selected={endDate}
                maxDate={new Date()}
                minDate={startDate}
              />
            </div>
            <div className="column">
              <label>Data:</label>
              <select
                value={dataFilter}
                onChange={(e) => setDataFilter(e.target.value)}
              >
                <option value="">All</option>
                <option value="true">Yes</option>
                <option value="false">No</option>
              </select>
            </div>
            <div className="column">
              <label>Bad words:</label>
              <select
                value={badWords}
                onChange={(e) => setBadWords(e.target.value)}
              >
                <option value="0">Show all</option>
                <option value="1">Without bad words</option>
                <option value="2">Only bad words</option>
              </select>
            </div>
          </div>
          <form onSubmit={(e) => searchButtonClick(e)}>
            <div className="row">
              <div className="column">
                <label>Case Id:</label>
                <input placeholder="Case ID..." id="caseIdField" />
              </div>
              <div className="column">
                <label>Phone:</label>
                <input placeholder="Phone..." id="phoneField" />
              </div>
              <div className="column">
                <label>Email:</label>
                <input placeholder="Email..." id="emailField" />
              </div>
              <div className="column">
                <label>&nbsp;</label>
                <Checkbox
                  text="Hide auto alerts"
                  checked={hideAuto}
                  onClick={() => setHideAuto(!hideAuto)}
                />
              </div>
              <div className="column">
                <label>&nbsp;</label>
                <button type="submit">Search</button>
              </div>
            </div>
          </form>
          <div className="selection-buttons">
            <button
              onClick={() => {
                setPage(1);
                getAlerts(1);
              }}
            >
              <FiRefreshCw />
            </button>
            <button
              className="red"
              disabled={!list.some((v) => v.selected)}
              onClick={() => askDelete()}
            >
              Delete selected
            </button>
            <button
              disabled={!list.some((v) => v.selected)}
              onClick={() => markComplete()}
            >
              Mark as completed
            </button>
          </div>
          {list.length ? (
            <>
              <table>
                <thead>
                  <tr>
                    <td />
                    <td />
                    <td>
                      <a onClick={() => changeSort("created_at")}>
                        Date Added {sortIcons("created_at")}
                      </a>
                    </td>
                    <td>
                      <a onClick={() => changeSort("firstName")}>
                        Name {sortIcons("firstName")}
                      </a>
                    </td>
                    <td>
                      <a onClick={() => changeSort("state")}>
                        State {sortIcons("state")}
                      </a>
                    </td>
                    <td>
                      <a onClick={() => changeSort("email")}>
                        Email {sortIcons("email")}
                      </a>
                    </td>
                    <td>
                      <a onClick={() => changeSort("city")}>
                        City {sortIcons("city")}
                      </a>
                    </td>
                    <td>
                      <a onClick={() => changeSort("matches_no")}>
                        Matches {sortIcons("matches_no")}
                      </a>
                    </td>
                    <td>
                      <a onClick={() => changeSort("notify_email_count")}>
                        Emails Sent {sortIcons("notify_email_count")}
                      </a>
                    </td>
                    <td>
                      <a onClick={() => changeSort("notify_sms_count")}>
                        Sms Sent {sortIcons("notify_sms_count")}
                      </a>
                    </td>
                    <td>
                      <a onClick={() => changeSort("type")}>
                        Type {sortIcons("type")}
                      </a>
                    </td>
                    <td>
                      <a onClick={() => changeSort("search_input")}>
                        Search input {sortIcons("search_input")}
                      </a>
                    </td>
                    <td>Notes</td>
                    <td />
                    <td>
                      <a onClick={() => changeSort("completed")}>
                        Completed {sortIcons("completed")}
                      </a>
                    </td>
                  </tr>
                </thead>
                <tbody>
                  {list.map((v, k) => (
                    <React.Fragment key={v._id}>
                      <tr>
                        <td>
                          {v.selected ? (
                            <FaCheckSquare
                              size={24}
                              className="link"
                              onClick={() => toggleSelect(k)}
                            />
                          ) : (
                            <FaRegSquare
                              size={24}
                              className="link"
                              onClick={() => toggleSelect(k)}
                            />
                          )}
                        </td>
                        <td>
                          <a onClick={() => toggleExpand(k)}>
                            {v.expanded ? (
                              <FiMinusCircle size={24} />
                            ) : (
                              <FiPlusCircle size={24} />
                            )}
                          </a>
                        </td>
                        <td>{moment(v.created_at).format("lll")}</td>
                        <td>
                          {v.duplicate ? "*" : ""} {v.firstName} {v.lastName}
                        </td>
                        <td>{v.state}</td>
                        <td>{findField("email", v)}</td>
                        <td>
                          <a
                            onClick={() => editCityInput(k)}
                            // eslint-disable-next-line no-script-url
                            href="javascript:"
                          >
                            {v.city ? v.city : ""}
                          </a>
                        </td>
                        <td>
                          <button
                            onClick={() => loadMatches(v)}
                            className="matches"
                          >
                            {v.matches_no || 0}
                          </button>
                        </td>
                        <td>{v.notify_email_count}</td>
                        <td>{v.notify_sms_count}</td>
                        <td>{v.type}</td>
                        <td>
                          <a
                            onClick={() => editSearchInput(k)}
                            // eslint-disable-next-line no-script-url
                            href="javascript:"
                          >
                            {v.search_input}
                          </a>
                        </td>
                        <td>
                          <a
                            onClick={() => {
                              document.getElementById("notes").value =
                                v.notes || "";
                              setEditNoteId(v._id);
                            }}
                          >
                            {v.notes
                              ? v.notes.substring(0, 50) +
                                (v.notes.length > 50 ? "..." : "")
                              : "Add"}
                          </a>
                        </td>
                        <td>
                          <button
                            onClick={() => {
                              startEdit({ ...v });
                            }}
                          >
                            Edit
                          </button>
                        </td>
                        <td style={{ textAlign: "center" }}>
                          {v.completed ? (
                            <FaCheckSquare
                              size={24}
                              color="#00b7ff"
                              onClick={() => toggleComplete(k)}
                            />
                          ) : (
                            <FaRegSquare
                              size={24}
                              color="#00b7ff"
                              onClick={() => toggleComplete(k)}
                            />
                          )}
                        </td>
                      </tr>
                      <tr
                        className={clsx("expander", {
                          show: v.expanded,
                        })}
                      >
                        <td colSpan="14">
                          {v.expanded ? <Expander alert={v} /> : null}
                        </td>
                      </tr>
                    </React.Fragment>
                  ))}
                </tbody>
              </table>
              {/* <div style={{ margin: 10, textAlign: "center" }}>
                Showing page {page} alerts. Show last:{" "}
                <a onClick={() => setPage(1)}>1</a>{" "}
                <a onClick={() => setPage(2)}>2</a>{" "}
                <a onClick={() => setPage(3)}>3</a>{" "}
                <a onClick={() => setPage(4)}>4</a>{" "}
                <a onClick={() => setPage(5)}>5</a>
              </div> */}
            </>
          ) : (
            <div>
              <br />
              <br />
              No alerts for current search criteria.
            </div>
          )}
        </div>
      )}
      <div ref={bottom} />
      {infinite && !loading ? (
        <div style={{ textAlign: "center", margin: 20 }}>
          <img
            src="/img/ajax-loader.gif"
            alt="loadingRequests..."
            className="loader-gif"
          />{" "}
          loading more...
        </div>
      ) : null}
    </div>
  );
}
