import React from "react";
import { Redirect, withRouter } from "react-router-dom";
import NewCaseModal from "../components/NewCaseModal";

const AddCase = props => {
	if (props.user === null) return <Redirect to="/signin" />;
	return (
		<div id="admin-page">
			<h1>Add Case</h1>
			<NewCaseModal
				show={true}
				close={() => props.history.push("/")}
				accidentID={props.match.params.id}
				user={props.user}
			/>
		</div>
	);
};
export default withRouter(AddCase);
