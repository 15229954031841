/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import Axios from "axios";
import ReactDatatable from "@ashvin27/react-datatable";
import { Redirect } from "react-router-dom";
import clsx from "clsx";
import { withRouter } from "react-router-dom";
import AdminUsersTable from "../components/AdminUsersTable";
import AdminAlertsTable from "../components/AdminAlertsTable";
import AdminLeadsTable from "../components/AdminLeadsTable";
import { MdClose } from "react-icons/md";
// import UserAlertsList from "../components/UserAlertsList";
import swal from "sweetalert2";
import moment from "moment";
import LeadStats from "../components/LeadStats";
import SendReportsToUser from "../components/SendReportsToUser";
import Blacklist from "../components/Blacklist";
import LeadFilter from "../components/LeadFilter";
import AdminAffiliateUsers from "../components/AdminAffiliateUsers";
import AddLead from "../components/AddLead";
import ColumnSwitcher from "../components/ColumnSwitcher";
import AffiliateStats from "../components/AffiliateStats";
import RetainerStats from "../components/RetainerStats";
import LeadStatsNew from "../components/LeadStatsNew";
import { refererNormalizer } from "../components/StatGraphs/Referer";
import Swal from "sweetalert2";
import Bidding from "../components/Bidding";
class AdminPage extends Component {
  state = {
    loadingRequests: true,
    loadingAlerts: true,
    loadingUsers: true,
    loadingLeads: true,
    requests: [],
    alerts: [],
    users: [],
    leads: [],
    tab: window.lastActiveAdminTab || 0,
    alertToLead: undefined,
    targetLeadUser: "",
    targetLeadHotness: "cold",
    sendToUser: undefined,
    leadLimit: 100,
    leadsFilter: {},
    unreadFilter: false,
    unreadCount: 0,
    showDuplicates: "",
    // newLeadModal: false
  };
  columnsRequests = [
    {
      key: "createdAt",
      text: "Date",
      sortable: true,
      cell: (v) => {
        return new Date(v.createdAt).toLocaleString();
      },
    },
    {
      key: "name",
      text: "Requestor",
      sortable: true,
      cell: (v) => {
        if (v.user_id) return v.user_id.firstName + " " + v.user_id.lastName;
      },
    },
    {
      key: "business",
      text: "Business",
      sortable: true,
      cell: (v) => {
        if (v.user_id && !v.user_id.accountOwner) return v.user_id.businessName;
        if (v.user_id && v.user_id.accountOwner)
          return v.user_id.accountOwner.businessName;
      },
    },
    {
      key: "user_email",
      text: "Email",
      sortable: true,
    },
    {
      key: "crashId",
      text: "Crash Id",
      sortable: true,
      cell: (v) => {
        return v.accident && v.accident.crash_id ? v.accident.crash_id : "";
      },
    },
    {
      key: "status",
      text: "Status",
      sortable: true,
    },
    {
      key: "accident_id",
      text: "Link",
      cell: (v) =>
        v.case_id ? (
          <a
            href={"https://app.myaccident.org/accident/" + v.case_id.accidentId}
            target="_blank"
            rel="noopener noreferrer"
          >
            Link
          </a>
        ) : v.accident ? (
          <a
            href={"https://app.myaccident.org/accident/" + v.accident._id}
            target="_blank"
            rel="noopener noreferrer"
          >
            Link
          </a>
        ) : (
          ""
        ),
    },
    {
      key: "buttons",
      cell: (v) =>
        v.status === "Pending" ? (
          <button onClick={() => this.request(v)} className="red">
            Upload
          </button>
        ) : (
          <div style={{ display: "flex" }}>
            <button
              onClick={() =>
                this.setState({
                  sendToUser: {
                    report_url: v.url,
                    accident_id: v.case_id.accidentId,
                    email: v.user_email,
                  },
                })
              }
            >
              Email
            </button>
            <a
              className="button"
              href={v.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              View
            </a>
          </div>
        ),
    },
  ];

  config = {
    page_size: 1000,
    length_menu: [10, 20, 50],
    show_length_menu: false,
    show_pagination: false,
    show_filter: false,
    sort: { column: "createdAt", order: "desc" },
  };

  request(v) {
    // console.log(v);
    this.itemToUpload = v;
    window.document.getElementById("file-input").click();
  }
  uploadDoc(e) {
    console.log(this.itemToUpload);
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    Axios.post(`/api/businessAccount/upload/report`, formData, {
      params: {
        report_id: this.itemToUpload._id,
        user_email: this.itemToUpload.user_email,
        accident_id: this.itemToUpload.case_id.accidentId,
      },
    })
      .then((response) => {
        // console.log(response);
        // console.log(response.data.download_link);
        if (response.data.success) {
          this.state.requests.some((v) => {
            if (v._id === this.itemToUpload._id) {
              v.status = "Complete";
              v.url = response.data.download_link;
              return true;
            }
            return false;
          });
          this.setState({ requests: this.state.requests });
        } else {
          swal.fire("Error uploading file.", response.data.error, "error");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  loadLeads() {
    this.setState({ loadingLeads: true });
    let filter = encodeURI(JSON.stringify(this.state.leadsFilter));
    if (this.state.showDuplicates) {
      const email = this.state.showDuplicates.split("|")[0];
      const phone = this.state.showDuplicates.split("|")[1].replace("+1", "");

      filter = encodeURI(
        `{"$or":[{"phone" : {"$regex":"${phone}", "$options":"i"}},{"email" : {"$regex":"${email}", "$options":"i"}}]}`
      );
      // console.log(filter);
      // return;
    }

    Axios.get(
      "/api/leads/fetch?limit=" +
        this.state.leadLimit +
        "&sort=createdAt&order=-1" +
        "&filter=" +
        filter
    ).then((d) => {
      // console.log(d.data);
      let unreadCount = 0;
      d.data.leads.forEach((v) => {
        if (!v.lead_level) v.lead_level = "";
        if (!v.firstName && !v.lastName && v.name) {
          v.firstName = v.name.split(" ")[0] || "";
          v.lastName = v.name.split(" ")[1] || "";
        }
        if (v.sms_count !== v.sms_count_read) unreadCount++;
      });
      this.setState({ leads: d.data.leads, loadingLeads: false, unreadCount });
    });
  }
  setLeadLimit(leadLimit) {
    this.setState({ leadLimit }, () => {
      this.loadLeads();
    });
  }
  deleteUser(user) {
    swal
      .fire({
        type: "question",
        title: "Are you sure you want to remove " + user.firstName + "?",
        text: "This action cannot be undone.",
        showCancelButton: true,
        reverseButtons: true,
      })
      .then((v) => {
        if (v.value) {
          Axios.delete("/api/businessAccount/delete-user?user_id=" + user._id);
          let key;
          this.state.users.some((vv, k) => {
            if (vv._id === user._id) {
              key = k;
              return true;
            }
            return false;
          });
          if (key !== undefined) {
            this.state.users.splice(key, 1);
            this.setState({ users: this.state.users });
          }
        }
      });
  }

  loadUsers() {
    this.setState({ loadingUsers: true });
    Axios.get("/api/businessAccount/users/all").then((d) => {
      // console.log(d.data);
      this.setState({
        users: d.data.users,
        // users: d.data.users.filter(v => {
        // 	return !v.accountOwner;
        // }),
        loadingUsers: false,
      });
    });
  }

  componentDidMount() {
    // this.loadLeads();
    Axios.get("/api/businessAccount/report/list").then((d) => {
      // console.log(d.data);
      this.setState({ requests: d.data.reports, loadingRequests: false });
    });
    Axios.get("/api/businessAccount/cases/alert").then((d) => {
      // console.log(d.data);
      this.setState({ alerts: d.data.alerts, loadingAlerts: false });
    });

    this.loadUsers();

    window.addEventListener("sms-mark-read", ({ detail }) => {
      this.state.leads.some((v) => {
        if (v._id === detail.lead) {
          v.sms_count_read = v.sms_count;
          return true;
        }
        return false;
      });

      let unreadCount = 0;
      this.state.leads.forEach((v) => {
        if (v.sms_count !== v.sms_count_read) unreadCount++;
      });

      this.setState({
        leads: this.state.leads,
        unreadCount,
      });
    });
  }

  // convertToLead = () => {
  // 	// console.log(this.state.alertToLead);
  // 	// console.log(this.state.targetLeadUser);
  // 	// console.log(this.state.targetLeadHotness);
  // 	Axios.post("/api/leads/convert-alert", {
  // 		alert_id: this.state.alertToLead._id,
  // 		lead_level: this.state.targetLeadHotness,
  // 		user_id: this.state.targetLeadUser
  // 	}).then(d => {
  // 		// console.log(d.data);
  // 		if (d.data.success) {
  // 			this.loadLeads();
  // 			this.setState({ alertToLead: undefined, newLeadModal: false });
  // 		}
  // 	});
  // };

  decodeAssignedTo(ids) {
    let users = [];
    ids.forEach((id) => {
      this.state.users.some((v) => {
        if (v._id === id) {
          users.push(v.businessName);
          return true;
        }
        return false;
      });
    });
    return users.join("; ").replace(/,/g, ";");
  }

  decodeReferer(l) {
    let out = "";
    out = refererNormalizer(l.referer);
    this.state.users.some((u) => {
      if (u._id === l.affiliate) {
        out = u.businessName;
        return true;
      }
      return false;
    });

    return out.replace(/,/g, ";");
  }

  extractAccident(lead) {
    let out = {
      make: "",
      year: "",
      color: "",
      city: "",
      state: "",
      zip: "",
      towing_company: "",
      towing_location: "",
      damage_area_1: "",
      damage_severity_1: "",
      damage_area_2: "",
      damage_severity_2: "",
      air_bags: "",
      commercial: "N",
      vehicles_involved: [],
    };

    if (lead.accident && lead.accident.vehicles) {
      lead.accident.vehicles.forEach((v) => {
        if (v.vehicle_commercial === "Yes") {
          out.commercial = "Y";
        }
        out.vehicles_involved.push(v.vehicle_maker);
      });
    }

    if (lead.accident && lead.vin && lead.accident.vehicles) {
      lead.accident.vehicles.some((d) => {
        if (d.vin_code === lead.vin) {
          out.make = d.vehicle_maker;
          out.year = d.vehicle_model_year;
          out.color = d.vehicle_color;
          out.city =
            lead.accident.city && lead.accident.city.replace(/,/g, ";");
          out.state =
            lead.accident.state && lead.accident.state.replace(/,/g, ";");
          out.zip = lead.accident.zip || "";
          out.towing_company =
            d.towing_company && d.towing_company.replace(/,/g, ";");
          out.towing_location = d.towing_location
            ? d.towing_location.replace(/,/g, ";")
            : "";
          out.damage_area_1 = d.damage_area_1
            ? d.damage_area_1.replace(/,/g, ";")
            : "";
          out.damage_area_2 = d.damage_area_2
            ? d.damage_area_2.replace(/,/g, ";")
            : "";
          out.damage_severity_1 = d.damage_severity_1
            ? d.damage_severity_1.replace(/,/g, ";")
            : "";
          out.damage_severity_2 = d.damage_severity_2
            ? d.damage_severity_2.replace(/,/g, ";")
            : "";
          out.air_bags = "";
          d.participants.forEach((p) => {
            if (p.airbag)
              out.air_bags = out.air_bags + p.airbag.replace(/,/g, " - ") + "|";
          });
          out.air_bags = out.air_bags.substring(0, out.air_bags.length - 1);
          return true;
        }
        return false;
      });
    }

    return out;
  }

  leadsCSV(full) {
    const rows = [
      [
        "Assigned to",
        "Lead Name",
        "Lead Last Name",
        "Phone",
        "Email",
        "Occupant",
        "Vin",
        "Message",
        "Accident Url",
        "Business Report Url",
        "Notification Lead Url",
        "Fault",
        "Injured",
        "Copy Requested",
        "CarBrain",
        "Source",
        "Created At",
        "Time",
        "Lead Level",
        "City",
        "State",
        "Zip",
        "Car Make",
        "Car Year",
        "Car Color",
        "Tow Company",
        "Tow Location",
        "Damage 1",
        "Damage 1 Level",
        "Damage 2",
        "Damage 2 Level",
        "Air bags",
        "Commercial",
        "Vehicles Involved",
        "Referrer",
        "P.Value",
      ],
    ];

    if (full)
      rows[0].push(
        "Retainer Success",
        "Message",
        "Description",
        "Notes",
        "Reject Notes",
        "Cancel Reason",
        "SubID"
      );

    const selected = [];
    this.state.leads.forEach((v) => {
      if (v.selected) {
        selected.push(v);
      }
    });

    const source = selected.length ? selected : this.state.leads;

    source.forEach((l) => {
      const column = [];
      const accident = this.extractAccident(l);
      column.push(this.decodeAssignedTo(l.assigned_to));
      column.push(l.firstName || "");
      column.push(l.lastName || "");
      column.push(l.phone || "");
      column.push(l.email || "");
      column.push(l.occupant || "");
      column.push(l.vin || "");
      column.push(
        l.message
          ? l.message.replace(/,/g, ";").replace(/(?:\r\n|\r|\n)/g, "|")
          : ""
      );
      column.push(
        l.accidentURL && l.accidentURL !== "false" ? l.accidentURL : ""
      );
      column.push(
        l.businessReportURL && l.businessReportURL !== "false"
          ? l.businessReportURL
          : ""
      );
      column.push("https://app.myaccident.org/notification/" + l.accidentId);
      column.push(l.fault);
      column.push(l.injured);
      column.push(l.copyRequested ? true : false);
      column.push(l.carBrain);
      column.push(l.source);
      column.push(moment(l.createdAt).format("MM/DD/YYYY"));
      column.push(moment(l.createdAt).format("HH:mm:ss"));
      column.push(l.lead_level);
      column.push(l.city ? l.city.replace(/,/g, ";") : "");
      column.push(l.state);
      column.push(l.zip);
      column.push(accident.make);
      column.push(accident.year);
      column.push(accident.color);
      column.push(accident.towing_company);
      column.push(accident.towing_location);
      column.push(accident.damage_area_1);
      column.push(accident.damage_severity_1);
      column.push(accident.damage_area_2);
      column.push(accident.damage_severity_2);
      column.push(accident.air_bags);
      column.push(accident.commercial);
      column.push(accident.vehicles_involved.join(";"));
      column.push(this.decodeReferer(l));
      column.push(l.value);
      if (full) {
        column.push(l.retainerSuccess ? "Yes" : "No");
        column.push(l.message ? l.message.replace(/,/g, ";") : "");
        column.push(l.description ? l.description.replace(/,/g, ";") : "");
        column.push(l.notes ? l.notes.replace(/,/g, ";") : "");
        column.push(l.rejectNotes ? l.rejectNotes.replace(/,/g, ";") : "");
        column.push(l.cancelReason ? l.cancelReason.replace(/,/g, ";") : "");
        column.push(l.subID ? l.subID.replace(/,/g, ";") : "");
      }

      rows.push(column);
    });

    let csvContent = "";
    let dataString = [];
    rows.forEach((infoArray, index) => {
      dataString = infoArray.join(",");
      csvContent += index < rows.length ? dataString + "\n" : dataString;
    });
    let download = (content, fileName, mimeType) => {
      let a = document.createElement("a");
      mimeType = mimeType || "application/octet-stream";
      a.href = URL.createObjectURL(
        new Blob([content], {
          type: mimeType,
        })
      );
      a.setAttribute("download", fileName);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    };
    // console.log(csvContent);
    download(csvContent, "Leads Export.csv", "text/csv;encoding:utf-8");
  }

  toggleUnreadFilter() {
    this.setState({
      unreadFilter: !this.state.unreadFilter,
      // leads: this.state.leads.filter((l) => l.sms_count !== l.sms_count_read),
    });
  }

  toggleUserState(id, country) {
    // console.log(id, country);
    this.state.users.some((u) => {
      if (u._id === id) {
        if (country === "ALL") u.states = ["ALL"];
        else {
          if (u.states?.[0] === "ALL") u.states = [];
          if (!u.states) u.states = [country];
          else {
            if (u.states.includes(country))
              u.states = u.states.filter((s) => s !== country);
            else u.states.push(country);
          }
        }
        this.setState({ users: [...this.state.users] });
        console.log("SAVE USER STATES", id, u.states);
        Axios.post("api/businessAccount/saveStates/" + id, {
          states: u.states,
        }).then((d) => {
          console.log(d.data);
        });
        return true;
      }
      return false;
    });
  }

  saveAutoSMStext(user, form) {
    this.state.users.some((v) => {
      if (v._id === user._id) {
        v.autoSMSText = form.target.autoSMSText.value;
        return true;
      }
      return false;
    });
    this.setState({ users: [...this.state.users] });
    Axios.post("api/businessAccount/saveStates/" + user._id, {
      autoSMSText: form.target.autoSMSText.value,
    });
    //   .then((d) => {
    //   console.log(d.data);
    // });

    // console.log("SAVE", user, form.target.autoSMSText.value);
  }

  toggleAutoSMS(user) {
    this.state.users.some((v) => {
      if (v._id === user._id) {
        v.autoSMS = !v.autoSMS;
        return true;
      }
      return false;
    });
    this.setState({ users: [...this.state.users] });
    Axios.post("api/businessAccount/saveStates/" + user._id, {
      autoSMS: user.autoSMS,
    });
    //   .then((d) => {
    //   console.log(d.data);
    // });
    // console.log("SAVE", user);
  }

  toggleRejectionTracking(user) {
    this.state.users.some((v) => {
      if (v._id === user._id) {
        v.rejectionTracking = !v.rejectionTracking;
        return true;
      }
      return false;
    });
    this.setState({ users: [...this.state.users] });
    Axios.post("api/businessAccount/saveStates/" + user._id, {
      rejectionTracking: user.rejectionTracking,
    });
    //   .then((d) => {
    //   console.log(d.data);
    // });
    // console.log("SAVE", user);
  }

  toggleAdoptContractor(user) {
    let letsMakeALeadUser;

    this.state.users.some((v) => {
      if (v._id === "64272eccdac8620019569e9c") {
        letsMakeALeadUser = v;
        return true;
      }
      return false;
    });

    if (!letsMakeALeadUser)
      return Swal.fire("Error", "Let's make a lead user not found!", "error");

    this.state.users.some((v) => {
      if (v._id === user._id) {
        v.adoptContractor = !v.adoptContractor;
        v.statePrices = v.adoptContractor
          ? { ...letsMakeALeadUser.statePrices }
          : {};
        v.states = v.adoptContractor ? [...letsMakeALeadUser.states] : [];
        v.rejectionApproval = v.adoptContractor
          ? letsMakeALeadUser.rejectionApproval
          : "";
        return true;
      }
      return false;
    });

    this.setState({
      users: [...this.state.users],
    });

    // statePrices: user.statePrices,
    Axios.post("api/businessAccount/saveStates/" + user._id, {
      adoptContractor: user.adoptContractor,
      statePrices: user.statePrices,
      states: user.states,
    });
    //   .then((d) => {
    //   console.log(d.data);
    // });
    // console.log("SAVE", user);
  }

  saveStatePrices(e, user) {
    e.preventDefault();
    const formData = new FormData(e.target);
    const data = Object.fromEntries(formData);
    const formatted = {};
    const rejectionApproval = data.rejectionApproval;
    delete data.rejectionApproval;
    Object.keys(data).forEach((v) => {
      const s = v.split(".");
      if (!formatted[s[0]]) formatted[s[0]] = {};
      formatted[s[0]][s[1]] = data[v];
    });

    this.state.users.some((v) => {
      if (v._id === user._id) {
        v.statePrices = formatted;
        v.rejectionApproval = rejectionApproval;
        return true;
      }
      return false;
    });
    this.setState({ users: [...this.state.users] });
    Axios.post("api/businessAccount/saveStates/" + user._id, {
      statePrices: user.statePrices,
      rejectionApproval: user.rejectionApproval,
    });
  }

  setRetainer(e, user) {
    this.state.users.some((v) => {
      if (v._id === user._id) {
        v.retainer = !v.retainer;
        return true;
      }
      return false;
    });
    this.setState({ users: [...this.state.users] });
    Axios.post("api/businessAccount/saveStates/" + user._id, {
      retainer: user.retainer,
    });
  }

  toggleMorgan(user) {
    this.state.users.some((v) => {
      if (v._id === user._id) {
        v.morganConnect = !v.morganConnect;
        return true;
      }
      return false;
    });
    this.setState({ users: [...this.state.users] });
    Axios.post("api/businessAccount/saveStates/" + user._id, {
      morganConnect: user.morganConnect,
    });
  }

  saveMorganSource(e, user) {
    e.preventDefault();
    this.state.users.some((v) => {
      if (v._id === user._id) {
        v.morganSource = e.target.morganSource.value;
        return true;
      }
      return false;
    });
    this.setState({ users: [...this.state.users] });
    Axios.post("api/businessAccount/saveStates/" + user._id, {
      morganSource: user.morganSource,
    });
  }

  saveLac(e, user) {
    e.preventDefault();
    this.state.users.some((v) => {
      if (v._id === user._id) {
        v.lac = e.target.lac.value;
        return true;
      }
      return false;
    });
    this.setState({ users: [...this.state.users] });
    Axios.post("api/businessAccount/saveStates/" + user._id, {
      lac: user.lac,
    });
  }

  updateUserWeight(user) {
    this.state.users.some((v) => {
      if (v._id === user._id) {
        v.subIDWeight = { ...user.subIDWeight };
        return true;
      }
      return false;
    });
    this.setState({ users: [...this.state.users] });
  }

  updateUserWeightName(user) {
    this.state.users.some((v) => {
      if (v._id === user._id) {
        v.subIDNames = { ...user.subIDNames };
        return true;
      }
      return false;
    });
    this.setState({ users: [...this.state.users] });
  }

  render() {
    if (this.props.user === null) return <Redirect to="/signin" />;
    return (
      <div id="admin-page">
        {/* <div className={this.state.newLeadModal ? "modal show" : "modal"}>
					<button
						className="close"
						onClick={() => this.setState({ newLeadModal: false })}
					>
						<MdClose />
					</button>
					<h2>Latest regular user alerts</h2>
					{this.state.newLeadModal ? (
						<UserAlertsList
							alertToLead={alertToLead => this.setState({ alertToLead })}
						/>
					) : null}
					<div className="footer">
						<button
							onClick={() => this.setState({ newLeadModal: false })}
							className="outline"
						>
							Cancel
						</button>
					</div>
				</div> */}
        <SendReportsToUser
          sendToUserCase={this.state.sendToUser}
          accidentId={
            this.state.sendToUser ? this.state.sendToUser.accident_id : null
          }
          email={this.state.sendToUser ? this.state.sendToUser.email : null}
          close={() => this.setState({ sendToUser: undefined })}
        />

        <div className={this.state.alertToLead ? "modal show" : "modal"}>
          <button
            className="close"
            onClick={() => this.setState({ alertToLead: undefined })}
          >
            <MdClose />
          </button>
          <h2>Convert to lead?</h2>
          <div className="row">
            <div className="column">
              <label>Target User:</label>
              <select
                value={this.state.targetLeadUser}
                onChange={(e) =>
                  this.setState({ targetLeadUser: e.target.value })
                }
              >
                <option>Select user</option>
                {this.state.users.map((u, k) => (
                  <option value={u._id} key={u._id}>
                    {u.email} {u.firstName} {u.lastName}
                  </option>
                ))}
              </select>
            </div>
            <div className="column">
              <label>Lead hotness:</label>
              <select
                value={this.state.targetLeadHotness}
                onChange={(e) =>
                  this.setState({ targetLeadHotness: e.target.value })
                }
              >
                <option value="cold">Cold</option>
                <option value="warm">Warm</option>
                <option value="hot">Hot</option>
              </select>
            </div>
          </div>
          <div className="footer">
            <button
              onClick={() => this.setState({ alertToLead: undefined })}
              className="outline"
            >
              Cancel
            </button>
            <button
              onClick={() => this.convertToLead()}
              disabled={!this.state.targetLeadUser}
            >
              CONVERT
            </button>
          </div>
        </div>

        <h1>Admin</h1>
        <div id="tabs">
          <button
            className={clsx({ active: this.state.tab === 0 })}
            onClick={() => {
              this.setState({ tab: 0 });
              window.lastActiveAdminTab = 0;
            }}
          >
            Leads
          </button>
          <button
            className={clsx({ active: this.state.tab === 5 })}
            onClick={() => {
              this.setState({ tab: 5 });
              window.lastActiveAdminTab = 5;
            }}
          >
            Lead Stats
          </button>
          <button
            className={clsx({ active: this.state.tab === 10 })}
            onClick={() => {
              this.setState({ tab: 10 });
              window.lastActiveAdminTab = 10;
            }}
          >
            Lead Stats New
          </button>
          <button
            className={clsx({ active: this.state.tab === 1 })}
            onClick={() => {
              this.setState({ tab: 1 });
              window.lastActiveAdminTab = 1;
            }}
          >
            Requests
          </button>
          {/* <button
            className={clsx({ active: this.state.tab === 2 })}
            onClick={() => {
              this.setState({ tab: 2 });
              window.lastActiveAdminTab = 2;
            }}
          >
            Business Alerts
          </button> */}
          <button
            className={clsx({ active: this.state.tab === 3 })}
            onClick={() => {
              this.loadUsers();
              this.setState({ tab: 3 });
              window.lastActiveAdminTab = 3;
            }}
          >
            Business Users
          </button>
          <button
            className={clsx({ active: this.state.tab === 4 })}
            onClick={() => {
              this.setState({ tab: 4 });
              window.lastActiveAdminTab = 4;
            }}
          >
            Affiliate Users
          </button>
          <button
            className={clsx({ active: this.state.tab === 8 })}
            onClick={() => {
              this.setState({ tab: 8 });
              window.lastActiveAdminTab = 8;
            }}
          >
            Affiliate Stats
          </button>
          <button
            className={clsx({ active: this.state.tab === 9 })}
            onClick={() => {
              this.setState({ tab: 9 });
              window.lastActiveAdminTab = 9;
            }}
          >
            Retainer Stats
          </button>
          <button
            className={clsx({ active: this.state.tab === 6 })}
            onClick={() => {
              this.setState({ tab: 6 });
              window.lastActiveAdminTab = 6;
            }}
          >
            Blacklist
          </button>
          <button
            className={clsx({ active: this.state.tab === 7 })}
            onClick={() => {
              this.setState({ tab: 7 });
              window.lastActiveAdminTab = 7;
            }}
          >
            + Lead
          </button>
          <button
            className={clsx({ active: this.state.tab === 11 })}
            onClick={() => {
              this.setState({ tab: 11 });
              window.lastActiveAdminTab = 11;
            }}
          >
            Bidding
          </button>
        </div>
        <div className={clsx("tab", { show: this.state.tab === 0 })}>
          {this.state.showDuplicates ? (
            <div>
              Showing just leads with email: {this.state.showDuplicates}{" "}
              <a
                onClick={() =>
                  this.setState({ showDuplicates: "" }, () => this.loadLeads())
                }
              >
                Click here to show all.
              </a>
              <br />
              <br />
            </div>
          ) : (
            <>
              <LeadFilter
                applyFilters={(leadsFilter) =>
                  this.setState({ leadsFilter }, () => this.loadLeads())
                }
                disabled={this.state.loadingLeads || this.state.loadingUsers}
                users={this.state.users}
              />
              <ColumnSwitcher />
              {this.state.unreadCount && !this.state.unreadFilter ? (
                <div>
                  <span className="badge">{this.state.unreadCount}</span>{" "}
                  {this.state.unreadCount > 1 ? "leads" : "lead"} with unread
                  sms.{" "}
                  <a onClick={() => this.toggleUnreadFilter()}>
                    Click here to show just unread sms leads.
                  </a>
                  <br />
                  <br />
                </div>
              ) : null}

              {this.state.unreadFilter ? (
                <div>
                  Showing just unread sms leads.{" "}
                  <a onClick={() => this.toggleUnreadFilter()}>Show all.</a>
                  <br />
                  <br />
                </div>
              ) : null}
            </>
          )}

          {this.state.loadingLeads || this.state.loadingUsers ? (
            <div>
              <img
                src="/img/ajax-loader.gif"
                alt="loadingRequests..."
                className="loader-gif"
              />
              &nbsp;Loading Leads...
            </div>
          ) : (
            <div>
              {this.state.leads.length ? (
                <>
                  {/* <button
                    style={{ float: "left", height: 36 }}
                    onClick={() => this.loadLeads()}
                  >
                    <MdRefresh size="18" />
                  </button>
                  <button
                    style={{ float: "right" }}
                    onClick={() => this.leadsCSV()}
                  >
                    Download CSV
                  </button> */}
                  <AdminLeadsTable
                    leads={this.state.leads}
                    users={this.state.users}
                    updateLeads={(leads) => this.setState({ leads })}
                    refresh={() => this.loadLeads()}
                    leadsCSV={(full) => this.leadsCSV(full)}
                    unreadFilter={this.state.unreadFilter}
                    showDuplicates={(e) => {
                      this.setState({ showDuplicates: e }, () =>
                        this.loadLeads()
                      );
                    }}
                  />
                  <div style={{ margin: 10, textAlign: "center" }}>
                    Showing last {this.state.leadLimit} leads. Show last:{" "}
                    <a onClick={() => this.setLeadLimit(100)}>100</a>{" "}
                    <a onClick={() => this.setLeadLimit(500)}>500</a>{" "}
                    <a onClick={() => this.setLeadLimit(1000)}>1000</a>{" "}
                    <a onClick={() => this.setLeadLimit(10000)}>10000</a>
                  </div>
                </>
              ) : (
                <div style={{ margin: 50, textAlign: "center" }}>
                  No results.
                </div>
              )}
            </div>
          )}
        </div>
        <div className={clsx("tab", { show: this.state.tab === 1 })}>
          {this.state.loadingRequests ? (
            <div>
              <img
                src="/img/ajax-loader.gif"
                alt="loadingRequests..."
                className="loader-gif"
              />
              &nbsp;Loading requests...
            </div>
          ) : (
            <div>
              <input
                type="file"
                accept=".pdf"
                onChange={(e) => this.uploadDoc(e)}
                id="file-input"
                style={{ display: "none" }}
              />
              <ReactDatatable
                config={this.config}
                records={this.state.requests}
                columns={this.columnsRequests}
              />
            </div>
          )}
        </div>
        <div className={clsx("tab", { show: this.state.tab === 2 })}>
          {this.state.loadingAlerts ? (
            <div>
              <img
                src="/img/ajax-loader.gif"
                alt="loadingRequests..."
                className="loader-gif"
              />
              &nbsp;Loading alerts...
            </div>
          ) : (
            <div>
              <AdminAlertsTable
                alerts={this.state.alerts}
                convertToLead={(alertToLead) => this.setState({ alertToLead })}
              />
            </div>
          )}
        </div>
        <div className={clsx("tab", { show: this.state.tab === 3 })}>
          {this.state.loadingUsers ? (
            <div>
              <img
                src="/img/ajax-loader.gif"
                alt="loadingRequests..."
                className="loader-gif"
              />
              &nbsp;Loading users...
            </div>
          ) : (
            <AdminUsersTable
              users={this.state.users.filter(
                (v) => v.account_type === "business"
              )}
              user={this.props.user}
              delete={(u) => this.deleteUser(u)}
              toggleUserState={(id, country) =>
                this.toggleUserState(id, country)
              }
              toggleAutoSMS={(v) => this.toggleAutoSMS(v)}
              saveAutoSMStext={(v, e) => {
                e.preventDefault();
                this.saveAutoSMStext(v, e);
              }}
              saveStatePrices={(e, user) => this.saveStatePrices(e, user)}
              setRetainer={(e, user) => this.setRetainer(e, user)}
              toggleMorgan={(user) => this.toggleMorgan(user)}
              saveMorganSource={(e, user) => this.saveMorganSource(e, user)}
              saveLac={(e, user) => this.saveLac(e, user)}
            />
          )}
        </div>
        <div className={clsx("tab", { show: this.state.tab === 4 })}>
          {this.state.loadingUsers ? (
            <div>
              <img
                src="/img/ajax-loader.gif"
                alt="loadingRequests..."
                className="loader-gif"
              />
              &nbsp;Loading users...
            </div>
          ) : (
            <AdminAffiliateUsers
              users={this.state.users.filter(
                (v) => v.account_type === "affiliate"
              )}
              allUsers={this.state.users}
              user={this.props.user}
              delete={(u) => this.deleteUser(u)}
              toggleUserState={(id, country) =>
                this.toggleUserState(id, country)
              }
              toggleAutoSMS={(v) => this.toggleAutoSMS(v)}
              toggleRejectionTracking={(v) => this.toggleRejectionTracking(v)}
              toggleAdoptContractor={(v) => this.toggleAdoptContractor(v)}
              saveAutoSMStext={(v, e) => {
                e.preventDefault();
                this.saveAutoSMStext(v, e);
              }}
              saveStatePrices={(e, user) => this.saveStatePrices(e, user)}
              updateUserWeight={(user) => this.updateUserWeight(user)}
              updateUserWeightName={(user) => this.updateUserWeightName(user)}
            />
          )}
        </div>
        <div className={clsx("tab", { show: this.state.tab === 5 })}>
          {this.state.tab === 5 ? <LeadStats users={this.state.users} /> : null}
        </div>
        <div className={clsx("tab", { show: this.state.tab === 6 })}>
          {this.state.tab === 6 ? <Blacklist /> : null}
        </div>
        <div className={clsx("tab", { show: this.state.tab === 7 })}>
          {this.state.tab === 7 ? (
            <AddLead
              users={this.state.users}
              loadLeads={() => this.loadLeads()}
            />
          ) : null}
        </div>
        <div className={clsx("tab", { show: this.state.tab === 8 })}>
          {this.state.tab === 8 ? (
            <AffiliateStats
              users={this.state.users.reduce((result, item) => {
                result[item._id] = item; //a, b, c
                return result;
              }, {})}
            />
          ) : null}
        </div>
        <div className={clsx("tab", { show: this.state.tab === 9 })}>
          {this.state.tab === 9 ? (
            <RetainerStats
              users={this.state.users.reduce((result, item) => {
                result[item._id] = item; //a, b, c
                return result;
              }, {})}
            />
          ) : null}
        </div>
        <div className={clsx("tab", { show: this.state.tab === 10 })}>
          {this.state.tab === 10 ? <LeadStatsNew /> : null}
        </div>
        <div className={clsx("tab", { show: this.state.tab === 11 })}>
          {this.state.tab === 11 ? (
            <Bidding
              users={this.state.users.reduce((result, item) => {
                result[item._id] = item; //a, b, c
                return result;
              }, {})}
            />
          ) : null}
        </div>
      </div>
    );
  }
}

export default withRouter(AdminPage);
