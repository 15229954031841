import swal from "sweetalert2";
import Axios from "axios";
import md5 from "md5";
import moment from "moment";
import { hasCommercial } from "./components/Helpers";
import { fourLegalDate, jsonToQueryString } from "./pinger";

/* istanbul ignore file */

//my dev id: 5e1d73d94228a03e77ade40d
//torklaw id: 5f8de9f032878c4ad4b22d79
//eLocal id: 5fd69ae52533130bac0f143f
//4legal id: 6037cb1630a4d2001997e032
//ring id: 6047a500c703c700193c2bc4
//bestcase Highend: 60998495ee010c0019b86114
//bestcase backstop: 609984e6ee010c0019b86115
//bestcase mailer: 60afcffa2fd3e80019814e27
//smartadvocate: 62324716c85dd8001913edab
//morgan: 62cc553aea9d0b001930cf63
//morgan2: 63756e3e145cfe0019a130df

const morganUsers = {
  "63756e3e145cfe0019a130df": "Rainboth Murphy & Lown",
  "637c0dfbb6f2f200196b8236": "White Law",
  "637c0f63b6f2f200196b82ba": "MB Legal Group PLLC",
  "638e1dabb8ef410019d61361": "Logue Law Group",
  "6390f0bcdb3fd500197be8de": "Joseph J Cariglia",
  "6398e43666216e0019cc9559": "Pacin Levine Attorneys at Law",
  "63a0cbdc30814b0019a76991": "O’Hara Law Firm",
  "63dab765d4fee80019c0d5bf": "Hairston & Crooks Legal Group",
  "63ffbb1d5fda3100197bfbe0": "Cordova Law Firm",
  "640a105a2352020019750f1f": "Brown & Crouppen",
  "6408eb94b451280019aef48d": "Hammack Law Firm",
  "640b9697022c5900192d0a6d": "Mass Injury Group",
  "641a1ee8b813af0019201a1d": "Law Offices of Ronald J. Resmini",
  "64233fcb7040d40019d75658": "Brady Injury Law, PLLC",
  "6436fa65c09f510019e1df60": "Garza & Salinas PLLC",
  "6439ac1a5f30d50019e4a82e": "George Feldman McDonald",
  "6439aca15f30d50019e4b7c7":
    "David J. George, P.A. and Law Offices of Lori G. Feldman PLLC",
  "646bda886b2cef00194f6383": "Oppenheim Law",
  "64b5c1a449ce91001959e7a5": "Gelber Law Group",
  "64c0306ffc9d4b001929544c": "Madalon Law",
  "64ca7ba5bc93b5001942f903": "The Lobb Law Firm",
  "64f75e9155edd600192c9c03": "Bartlett & Grippe (T3)",
  "64fb7617b47a690019ddd5d5": "Commonwealth Law Group (T3)",
  "65033aa7c0c81900190a735f": "Phillips Law Group PC (T3)",
  "6515a12ff4f1a200197b8afe": "Mark A. Shapiro Esq. (T3)",
  "651aea39434edb0019bfb9ec": "The Law Office of Henry E. Gare (T3)",
  "6531a22f60f9030019f9584d": "The Bishop Law Group (T3)",
  "65368bcc96275f00199ecffd": "Castelblanco Law Group (T3)",
  "65416856ed27580019e85f6c": "Simeone & Miller (T3)",
  "654526d32572b00019629287": "London Disability (T3)",
  "654a7b302f233b0019dd39b7": "Stampone Law (T3)",
  "656a4d3936d4f0001938fbf2": "The Attkisson Law Firm (T3)",
  "6570f85dcbc8920019f12f11": "Stan Derwin Brown Law Office (T3)",
  "65779617f7f9640019e558dc": "Burnetti Law Firm P.A (T3)",
  "657b95360c5de800195f1876": "Bressman Law (T3)",
  "6581f8e431f528001932c7d6": "Calandra Law Group, LLC (T3)",
  "658332614c317e0019b631cb": "McMullin & Associates (T3)",
  "658dcdd4c61e140019391105": "PA Medical Malpractice (T3)",
  "658de189c61e1400193945e8": "Dismuke Law (T3)",
  "658de2c7c61e14001939474f": "Wilk Law (T3)",
  "65b0353aabae1a0019dbf80c": "The O'Toole Law Group (T3)",
};

const accidentDate = (lead) => {
  if (lead.extra_details?.accidentDetails?.date) {
    return lead.extra_details?.accidentDetails?.date;
  }
  if (lead.alert) {
    return lead.alert.startDate;
  }
  return lead.createdAt;
};

export const sourceSubIDs = {
  "Ad form": "yO108",
  Alert: "m97I8",
  affiliate: "l2P27",
  alert: "6Sm41",
  calc: "9pS35",
  car: "l1K75",
  download: "zF754",
  find: "7lQ70",
  "tawk.to": "E00b4",
  direct: "7lQ71",
};
export const poster = (
  lead,
  targetLeadHotness,
  user_id,
  callbackState,
  callbackData,
  updateLead
) => {
  console.log(lead);
  const posterProviders = {
    "6307fc3b904ab200190b1f1c": (resolve) => {
      /* 
      // cpgdigital LAW POSTER START
      */

      const trusted_form_cert_id =
        document.getElementById("xxTrustedFormCertUrl_0") &&
        document.getElementById("xxTrustedFormCertUrl_0").value !== ""
          ? document.getElementById("xxTrustedFormCertUrl_0").value
          : "";

      callbackState("running");
      callbackData(`Contacting paul@cpgdigital.tv API...`);
      const formData = {
        proxy_url: "https://leads-inst329-client.phonexa.com/lead/",
        apiId: "AD610C8892E445DFA274ADFA0B1744F8",
        apiPassword: "822e49529d",
        productId: 132,
        price: 0,
        userIp: lead.ip_address || "8.8.8.8",
        userAgent: lead.user_agent || navigator.userAgent,
        webSiteUrl: "https://myaccident.org",
        trustedForm: lead.trusted_form_cert || trusted_form_cert_id || "",
        firstName: lead.firstName,
        lastName: lead.lastName,
        email: lead.email,
        phone: lead.phone,
        city: lead.city,
        state: lead.state,
        zip: lead.zip,
        accidentOccurredInDays: 1,
        injuryType: "AA",
        attorneyRepresented: "NO",
        atFault: lead.fault ? "YES" : "NO",
        caseSettled: "NO",
        situationDescription: `${targetLeadHotness} lead,
                    Source: ${lead.source},
                    Injured: ${lead.injured},
                    At fault: ${lead.fault},
                    Legal consult: ${lead.legal_consult},
                    City: ${lead.city},
                    createdAt: ${lead.createdAt}`,
      };

      callbackData(
        `Sending POST request with data: <blockquote>${JSON.stringify(
          formData,
          null,
          2
        )}</blockquote>`
      );

      Axios.post("/api/leads/proxy", formData)
        .then((d) => {
          callbackData(
            d.data.status === "sold"
              ? "✅"
              : "❌" +
                  `paul@cpgdigital.tv API response: <blockquote>${JSON.stringify(
                    d.data,
                    null,
                    2
                  )}</blockquote>`
          );

          if (d.data.price) {
            callbackData(`✅ received price: $${d.data.price || 2}.<br>`);
            updateLead(lead.external_apis, 2);
          }

          callbackState("done");
          resolve();
        })
        .catch((e) => {
          swal.fire(
            "Could not contact paul@cpgdigital.tv API:",
            JSON.stringify(e),
            "error"
          );
          callbackState("done");
        });
      /* 
      // cpgdigital LAW POSTER END
      */
    },
    "5f8de9f032878c4ad4b22d79": (resolve) => {
      /* 
      // TORK LAW POSTER START
      */
      callbackState("running");
      callbackData(`Contacting TorkLaw API...`);
      const formData = {
        First: lead.firstName,
        Last: lead.lastName,
        Email: lead.email,
        Summary: `${targetLeadHotness} lead,
                    Source: ${lead.source},
                    Injured: ${lead.injured},
                    At fault: ${lead.fault},
                    Legal consult: ${lead.legal_consult},
                    City: ${lead.city},
                    createdAt: ${lead.createdAt}`,
      };
      if (targetLeadHotness === "hot") {
        formData.Phone = lead.phone;
      }

      Axios.post("/api/leads/torklaw", formData)
        .then((d) => {
          callbackData(
            `TorkLaw API response: <blockquote>${JSON.stringify(
              d.data,
              null,
              2
            )}</blockquote>`
          );
          callbackState("done");
          resolve();
        })
        .catch((e) => {
          swal.fire(
            "Could not contact TorkLaw API:",
            JSON.stringify(e),
            "error"
          );
          callbackState("done");
        });
      /* 
      // TORK LAW POSTER END
      */
    },
    "5fd69ae52533130bac0f143f": async (resolve) => {
      /* 
      // eLOCAL POSTER START
      */

      const eLocalNeedIDs = {
        DUILawyer: "8012-8162",
        DefectiveAirbag: "",
        CALawyer: "8031-10217",
        AutoBody: "",
        dValue: "",
        AutoRepair: "",
        NSInsurance: "",
        Chiropractor: "",
        Construction: "",
        MedMal: "",
        WorkComp: "8029-8216",
        SlipFall: "8001-8294",
        WDeath: "",
        CAMPLJ: "",
        ready: "",
      };

      if (!lead.zip) {
        swal.fire(
          "Can't send lead to elocal API.",
          "Lead doesn't have a ZIP code. Try to ping this lead manually first to enter the zip.",
          "error"
        );
        return false;
      }

      const trusted_form_cert_id =
        document.getElementById("xxTrustedFormCertUrl_0") &&
        document.getElementById("xxTrustedFormCertUrl_0").value !== ""
          ? document.getElementById("xxTrustedFormCertUrl_0").value
          : "";

      // if (trusted_form_cert_id === "") {
      //   swal
      //     .fire({
      //       title: "Can't send lead to eLocal API.",
      //       text: "Trusted form certificate is missing. Probably this can be fixed by reloading the page. Do you want to reload this page and try again?",
      //       confirmButtonText: "YES, reload",
      //       reverseButtons: true,
      //       type: "error",
      //       showCancelButton: true,
      //     })
      //     .then(({ value }) => {
      //       if (value) window.location.reload();
      //     });
      //   return false;
      // }

      callbackState("running");
      callbackData(`Contacting eLocal API...`);

      let description = "";
      if (lead.accident) {
        description =
          description +
          `
          VIN: ${lead.vin}
          Vehicle: ${lead.occupant}
          State: ${lead.accident.state}
        `;
      }

      if (lead.alert) {
        description =
          description +
          `
        ${
          lead.alert.vehicle1Maker ? "Vehicle: " + lead.alert.vehicle1Maker : ""
        }
        ${
          lead.alert.vehicle1Year
            ? "Vehicle Year: " + lead.alert.vehicle1Year
            : ""
        }
        ${lead.alert.vin ? "VIN: " + lead.alert.vin : ""}
        ${lead.alert.case_id ? "Case Id: " + lead.alert.case_id : ""}
        `;
      }

      let ping_token =
        lead.external_apis &&
        lead.external_apis.eLocal &&
        lead.external_apis.eLocal.ping_token
          ? lead.external_apis.eLocal.ping_token
          : "";

      let pingFormData = {
        proxy_url: "https://api.elocal.com/lead/ping",
        ping: {
          key: "df7a010d2a174d8ec7904917f51312c02388bf90",
          zip_code: lead.zip,
          need_id: eLocalNeedIDs[lead.lead_type]
            ? eLocalNeedIDs[lead.lead_type]
            : "",
          tcpa_consent: true,
          sender_id: "",
          sender_origin_key: "",
          exclusive: true,
          slots_available: 3,
          leadid_identifier: "0",
        },
      };
      if (!ping_token) {
        callbackData(
          `Sending ping request for zip ${
            lead.zip
          }: <blockquote>${JSON.stringify(
            pingFormData.ping,
            null,
            2
          )}</blockquote>`
        );
        const tokenResult = await Axios.post("/api/leads/proxy", pingFormData);
        ping_token = tokenResult.data.response.token;

        callbackData(
          (tokenResult.data.response.status === "success" ? "✅" : "❌") +
            ` Received response for ping:<blockquote>${JSON.stringify(
              tokenResult.data.response,
              null,
              2
            )}</blockquote>`
        );
        if (tokenResult.data.response.status !== "success") {
          callbackData(`❌ Status not success, canceling.`);
          callbackState("done");
          return;
        }
        // tokenResult.data.response.price = 101;
        if (tokenResult.data.response.price) {
          callbackData(
            `✅ received price: $${tokenResult.data.response.price}.<br>`
          );
          if (!lead.external_apis) lead.external_apis = {};

          lead.external_apis.eLocal = {
            price: tokenResult.data.response.price,
            ping_token,
          };
          lead.value = tokenResult.data.response.price;
          updateLead(lead);
        }
      }
      const postFormData = {
        proxy_url: "https://api.elocal.com/lead/post",
        post: {
          key: "df7a010d2a174d8ec7904917f51312c02388bf90",
          zip_code: lead.zip,
          need_id: eLocalNeedIDs[lead.lead_type]
            ? eLocalNeedIDs[lead.lead_type]
            : "",
          tcpa_consent: true,
          sender_id: "",
          sender_origin_key: "",
          first_name: lead.firstName,
          last_name: lead.lastName,
          phone: lead.phone,
          email: lead.email,
          description,
          exclusive: true,
          leadid_identifier: "0",
          ping_token,
          trusted_form_cert_id: lead.trusted_form_cert || trusted_form_cert_id,
          web_lead_source_url:
            lead.source === "Ad form"
              ? "https://myaccident.org"
              : "https://app.myaccident.org",
        },
      };
      callbackData(
        `Sending POST request with data: <blockquote>${JSON.stringify(
          postFormData.post,
          null,
          2
        )}</blockquote>`
      );

      const postResult = await Axios.post("/api/leads/proxy", postFormData);

      callbackData(
        (postResult.data.response.status === "success" ? "✅" : "❌") +
          ` eLocal POST request result: <blockquote>${JSON.stringify(
            postResult.data.response,
            null,
            2
          )}</blockquote>`
      );
      lead.value = lead.external_apis.eLocal.price;

      updateLead(lead.external_apis, lead.value);

      callbackState("done");
      resolve();
      /* 
      // eLOCAL POSTER END
      */
    },
    "6037cb1630a4d2001997e032": async (resolve) => {
      /* 
      // 4legal POSTER START
      */
      if (!lead.zip) {
        swal.fire(
          "Can't send lead to 4legal API.",
          "Lead doesn't have a ZIP code. Try to ping this lead manually first to enter the zip.",
          "error"
        );
        return false;
      }

      if (!lead.alert && lead.source !== "direct") {
        swal.fire(
          "Can't send lead to 4legal API.",
          "Only direct or leads that have alert can be sent to 4legal API.",
          "error"
        );
        return false;
      }

      callbackState("running");
      callbackData(`Contacting 4Legal API...`);

      const trusted_form_cert_id =
        document.getElementById("xxTrustedFormCertUrl_0") &&
        document.getElementById("xxTrustedFormCertUrl_0").value !== ""
          ? document.getElementById("xxTrustedFormCertUrl_0").value
          : "";

      let ping_token =
        lead.external_apis &&
        lead.external_apis["4legal"] &&
        lead.external_apis["4legal"].ping_token
          ? lead.external_apis["4legal"].ping_token
          : "";

      let pingFormData = {
        proxy_url: "https://leads.4legalleads.com/apiJSON.php",
        Request: {
          Key: "66170c43dcf14e33edc357b2cb4e14688d986d7023995ffea3e0f6816bb717bb",
          Zip: lead.zip,
          API_Action: "pingPostLead",
          SRC: "MA",
          Mode: "ping",
          Return_Best_Price: "1",
          Language: "English",
          TYPE: "31",
          Sub_ID: sourceSubIDs[lead.source] ? sourceSubIDs[lead.source] : "",
          TCPA: "Yes",
          Hash_Type: "md5",
          Email_Hash: md5(lead.email),
          Phone_Hash: md5(lead.phone),
          Have_Attorney: "No",
          Type_Of_Legal_Problem: "Auto Accident Injury",
          Format: "XML",
          Incident_Date: fourLegalDate(lead),
          Doctor_Treatment: "Yes",
          Were_You_At_Fault: "No",
          User_Agent: lead.user_agent || navigator.userAgent,
          Trusted_Form_URL: lead.trusted_form_cert || trusted_form_cert_id,
        },
      };
      if (!ping_token) {
        callbackData(
          `Sending ping request for zip ${
            lead.zip
          }: <blockquote>${JSON.stringify(
            pingFormData.Request,
            null,
            2
          )}</blockquote>`
        );
        const tokenResult = await Axios.post("/api/leads/proxy", pingFormData);
        ping_token = tokenResult.data.response.lead_id;

        callbackData(
          (tokenResult.data.response.status === "Matched" ? "✅" : "❌") +
            ` Received response for ping:<blockquote>${JSON.stringify(
              tokenResult.data.response,
              null,
              2
            )}</blockquote>`
        );
        if (tokenResult.data.response.status !== "Matched") {
          callbackData(`❌ Status not success, canceling.`);
          callbackState("done");
          return;
        }
        // tokenResult.data.response.price = 101;
        if (tokenResult.data.response.price) {
          callbackData(
            `✅ received price: $${tokenResult.data.response.price}.<br>`
          );
          if (!lead.external_apis) lead.external_apis = {};

          lead.external_apis["4legal"] = {
            price: tokenResult.data.response.price,
            ping_token,
          };
          lead.value = tokenResult.data.response.price;
          updateLead(lead);
        }
      }
      const postFormData = {
        proxy_url: "https://leads.4legalleads.com/apiJSON.php",
        Request: {
          Key: "66170c43dcf14e33edc357b2cb4e14688d986d7023995ffea3e0f6816bb717bb",
          Zip: lead.zip,
          API_Action: "pingPostLead",
          SRC: "MA",
          Mode: "post",
          Language: "English",
          TYPE: "31",
          Sub_ID: sourceSubIDs[lead.source] ? sourceSubIDs[lead.source] : "",
          TCPA: "Yes",
          Email: lead.email,
          Phone: lead.phone,
          First_Name: lead.firstName,
          Last_Name: lead.lastName,
          Have_Attorney: "No",
          Type_Of_Legal_Problem: "Auto Accident Injury",
          Format: "XML",
          Incident_Date: fourLegalDate(lead),
          Doctor_Treatment: "Yes",
          Were_You_At_Fault: "No",
          Lead_ID: ping_token,
          Landing_Page: "LandingPage",
          Comments: lead.notes || "",
          IP_Address: lead.ip_address || "8.8.8.8",
          User_Agent: lead.user_agent || navigator.userAgent,
          Hash_Type: "md5",
          Email_Hash: md5(lead.email),
          Phone_Hash: md5(lead.phone),
          Trusted_Form_URL: lead.trusted_form_cert || trusted_form_cert_id,
        },
      };
      callbackData(
        `Sending POST request with data: <blockquote>${JSON.stringify(
          postFormData.Request,
          null,
          2
        )}</blockquote>`
      );

      const postResult = await Axios.post("/api/leads/proxy", postFormData);
      console.log(postResult);
      callbackData(
        (postResult.data.response.status === "Matched" ? "✅" : "❌") +
          ` 4Legal POST request result: <blockquote>${JSON.stringify(
            postResult.data.response,
            null,
            2
          )}</blockquote>`
      );
      lead.value = lead.external_apis["4legal"].price;

      updateLead(lead.external_apis, lead.value);

      callbackState("done");
      resolve();
      /* 
      // eLOCAL POSTER END
      */
    },
    "6047a500c703c700193c2bc4": async (resolve) => {
      /* 
      // ring POSTER START
      */
      if (!lead.zip) {
        swal.fire(
          "Can't send lead to ring API.",
          "Lead doesn't have a ZIP code. Try to ping this lead manually first to enter the zip.",
          "error"
        );
        return false;
      }

      const trusted_form_cert_id =
        document.getElementById("xxTrustedFormCertUrl_0") &&
        document.getElementById("xxTrustedFormCertUrl_0").value !== ""
          ? document.getElementById("xxTrustedFormCertUrl_0").value
          : "";

      if (trusted_form_cert_id === "") {
        swal
          .fire({
            title: "Can't send lead to ring API.",
            text: "Trusted form certificate is missing. Probably this can be fixed by reloading the page. Do you want to reload this page and try again?",
            confirmButtonText: "YES, reload",
            reverseButtons: true,
            type: "error",
            showCancelButton: true,
          })
          .then(({ value }) => {
            if (value) window.location.reload();
          });
        return false;
      }

      callbackState("running");
      callbackData(`Contacting ring API...`);

      let ping_token =
        lead.external_apis &&
        lead.external_apis.ring &&
        lead.external_apis.ring.ping_token
          ? lead.external_apis.ring.ping_token
          : "";

      let pingFormData = {
        publisher_id: "DP11eb7235630139f0a40cc5749cc0ea1f",
        api_campaign_id:
          lead.lead_type === "AutoBody"
            ? "6229f26ff4226340051db00c67c0d67827ed80c7"
            : "AC11e92e58745d44a08f061ff13b505353",
        zip: lead.zip,
        tcpa: 1,
        has_attorney: 0,
        received_treatment: 0,
        incident_occurred: moment(lead.createdAt).format("YYYY-MM-DD"),
        physically_injured: 1,
        comments: lead.notes,
        were_you_at_fault: 0,
        type_of_injury: "Car Accident",
        accident_type: "Car Accident",
        landing_page_url: "https://myaccident.org",
        MVA_Accident_Type: hasCommercial(lead) ? "Commercial Vehicle" : "Car",
        county: lead.county || "",
      };
      if (!ping_token) {
        callbackData(
          `Sending ping request for zip ${
            lead.zip
          }: <blockquote>${JSON.stringify(pingFormData, null, 2)}</blockquote>`
        );
        pingFormData.proxy_url = "https://forms.ringpartner.com/ping";
        const tokenResult = await Axios.post("/api/leads/proxy", pingFormData);
        ping_token = tokenResult.data.lead_id;

        callbackData(
          (tokenResult.data.status === "ok" ? "✅" : "❌") +
            ` Received response for ping:<blockquote>${JSON.stringify(
              tokenResult.data,
              null,
              2
            )}</blockquote>`
        );
        if (tokenResult.data.status !== "ok") {
          callbackData(`❌ Status not success, canceling.`);
          callbackState("done");
          return;
        }
        // tokenResult.data.bid = 101;
        if (tokenResult.data.bid) {
          callbackData(`✅ received price: $${tokenResult.data.bid}.<br>`);
          if (!lead.external_apis) lead.external_apis = {};

          lead.external_apis.ring = {
            price: tokenResult.data.bid,
            ping_token,
          };
          lead.value = tokenResult.data.bid;
          updateLead(lead);
        }
      }
      const postFormData = {
        publisher_id: "DP11eb7235630139f0a40cc5749cc0ea1f",
        api_campaign_id:
          lead.lead_type === "AutoBody"
            ? "6229f26ff4226340051db00c67c0d67827ed80c7"
            : "AC11e92e58745d44a08f061ff13b505353",
        tcpa: 1,
        has_attorney: 0,
        received_treatment: 0,
        incident_occurred: moment(lead.createdAt).format("YYYY-MM-DD"),
        physically_injured: 1,
        zip: lead.zip,
        comments: lead.notes,
        email: lead.email,
        phone: lead.phone,
        first_name: lead.firstName,
        last_name: lead.lastName,
        ip: lead.ip_address || "8.8.8.8",
        were_you_at_fault: 0,
        lead_id: ping_token,
        trusted_form_url: lead.trusted_form_cert || trusted_form_cert_id,
        type_of_injury: "Car Accident",
        accident_type: "Car Accident",
        landing_page_url: "https://myaccident.org",
        MVA_Accident_Type: hasCommercial(lead) ? "Commercial Vehicle" : "Car",
        county: lead.county || "",
      };
      callbackData(
        `Sending POST request with data: <blockquote>${JSON.stringify(
          postFormData,
          null,
          2
        )}</blockquote>`
      );
      postFormData.proxy_url = "https://forms.ringpartner.com/post";

      const postResult = await Axios.post("/api/leads/proxy", postFormData);

      callbackData(
        (postResult.data.status === "ok" ? "✅" : "❌") +
          ` ring POST request result: <blockquote>${JSON.stringify(
            postResult.data,
            null,
            2
          )}</blockquote>`
      );
      if (postResult.data.status === "ok" && postResult.data.bid) {
        lead.external_apis.ring.price = postResult.data.bid;
      }
      lead.value = lead.external_apis.ring.price;

      updateLead(lead.external_apis, lead.value);

      callbackState("done");
      resolve();
      /* 
      // ring POSTER END
      */
    },
    "60998495ee010c0019b86114": async (resolve) => {
      /* 
      // BestCase HighEnd POSTER START
      */
      if (!lead.zip) {
        swal.fire(
          "Can't send lead to ring API.",
          "Lead doesn't have a ZIP code. Try to ping this lead manually first to enter the zip.",
          "error"
        );
        return false;
      }

      callbackState("running");
      callbackData(`Contacting BestCase API...`);

      let ping_token =
        lead.external_apis &&
        lead.external_apis.bestCaseHE &&
        lead.external_apis.bestCaseHE.ping_token
          ? lead.external_apis.bestCaseHE.ping_token
          : "";

      let pingFormData = {
        convertToForm: {
          lp_campaign_id: "60996456ec912",
          lp_campaign_key: "qd3wNyFbvLBxkV2tWmc4",
          lp_s1: "MyAccident.org",
          lp_response: "JSON",
          zip_code: lead.zip,
          // email: lead.email,
          // phone: lead.phone,
          // first_name: lead.firstName,
          // last_name: lead.lastName,
          has_attorney: "No",
          // ip_address: lead.ip_address,
          treatment: "No",
          injury_year: moment(lead.createdAt).format("YYYY"),
          injury_month: moment(lead.createdAt).format("MM"),
          mva_physical_injury: "Yes",
          case_description: lead.notes,
          mva_accident_fault: "No",
          external_lead_id: lead._id,
          landing_page: "https://myaccident.org",
          // user_agent: "Chrome",
          mva_accident_type: hasCommercial(lead)
            ? "Commercial Vehicle Accident"
            : "Car Accident",
        },
      };
      if (!ping_token) {
        callbackData(
          `Sending ping request for zip ${
            lead.zip
          }: <blockquote>${JSON.stringify(
            pingFormData.convertToForm,
            null,
            2
          )}</blockquote>`
        );
        pingFormData.proxy_url =
          "https://bestcaseleads.leadspediatrack.com/ping.do";
        const tokenResult = await Axios.post("/api/leads/proxy", pingFormData);
        ping_token = tokenResult.data.ping_id;

        callbackData(
          (tokenResult.data.result === "success" ? "✅" : "❌") +
            ` Received response for ping:<blockquote>${JSON.stringify(
              tokenResult.data,
              null,
              2
            )}</blockquote>`
        );
        if (tokenResult.data.result !== "success") {
          callbackData(`❌ Status not success, canceling.`);
          callbackState("done");
          return;
        }
        // tokenResult.data.price = 101;
        if (tokenResult.data.price) {
          callbackData(`✅ received price: $${tokenResult.data.price}.<br>`);
          if (!lead.external_apis) lead.external_apis = {};

          lead.external_apis.bestCaseHE = {
            price: tokenResult.data.price,
            ping_token,
          };
          lead.value = tokenResult.data.price;
          updateLead(lead);
        }
      }
      const postFormData = {
        convertToForm: {
          lp_campaign_id: "60996456ec912",
          lp_campaign_key: "qd3wNyFbvLBxkV2tWmc4",
          lp_s1: "MyAccident.org",
          lp_response: "JSON",
          zip_code: lead.zip,
          email_address: lead.email,
          phone_home: lead.phone,
          first_name: lead.firstName,
          last_name: lead.lastName,
          county: lead.county || "",
          has_attorney: "No",
          ip_address: lead.ip_address,
          treatment: "No",
          injury_year: moment(lead.createdAt).format("YYYY"),
          injury_month: moment(lead.createdAt).format("MM"),
          mva_physical_injury: "Yes",
          case_description: lead.notes,
          mva_accident_fault: "No",
          external_lead_id: lead._id,
          landing_page: "https://myaccident.org",
          lp_ping_id: ping_token,
          // user_agent: "Chrome",
          mva_accident_type: hasCommercial(lead)
            ? "Commercial Vehicle Accident"
            : "Car Accident",
        },
      };
      callbackData(
        `Sending POST request with data: <blockquote>${JSON.stringify(
          postFormData.convertToForm,
          null,
          2
        )}</blockquote>`
      );
      postFormData.proxy_url =
        "https://bestcaseleads.leadspediatrack.com/post.do";

      const postResult = await Axios.post("/api/leads/proxy", postFormData);

      callbackData(
        (postResult.data.result === "success" ? "✅" : "❌") +
          ` BestCase HighEnd POST request result: <blockquote>${JSON.stringify(
            postResult.data,
            null,
            2
          )}</blockquote>`
      );
      if (postResult.data.result === "success" && postResult.data.price) {
        lead.external_apis.bestCaseHE.price = postResult.data.price;
      }
      lead.value = lead.external_apis.bestCaseHE.price;

      updateLead(lead.external_apis, lead.value);

      callbackState("done");
      resolve();
      /* 
      // BestCase HighEnd POSTER END
      */
    },
    "609984e6ee010c0019b86115": async (resolve) => {
      /* 
      // BestCase BackStop POSTER START
      */
      if (!lead.zip) {
        swal.fire(
          "Can't send lead to ring API.",
          "Lead doesn't have a ZIP code. Try to ping this lead manually first to enter the zip.",
          "error"
        );
        return false;
      }

      callbackState("running");
      callbackData(`Contacting BestCase API...`);

      let ping_token =
        lead.external_apis &&
        lead.external_apis.bestCaseBS &&
        lead.external_apis.bestCaseBS.ping_token
          ? lead.external_apis.bestCaseBS.ping_token
          : "";

      let pingFormData = {
        convertToForm: {
          lp_campaign_id: "609964afe4d49",
          lp_campaign_key: "NnzrfwLFj2GVWZ6qQpyb",
          lp_s1: "MyAccident.org",
          lp_response: "JSON",
          zip_code: lead.zip,
          // email: lead.email,
          // phone: lead.phone,
          // first_name: lead.firstName,
          // last_name: lead.lastName,
          has_attorney: "No",
          // ip_address: lead.ip_address,
          treatment: "No",
          injury_year: moment(lead.createdAt).format("YYYY"),
          injury_month: moment(lead.createdAt).format("MM"),
          mva_physical_injury: "Yes",
          case_description: lead.notes,
          mva_accident_fault: "No",
          external_lead_id: lead._id,
          landing_page: "https://myaccident.org",
          // user_agent: "Chrome",
          mva_accident_type: hasCommercial(lead)
            ? "Commercial Vehicle Accident"
            : "Car Accident",
        },
      };
      if (!ping_token) {
        callbackData(
          `Sending ping request for zip ${
            lead.zip
          }: <blockquote>${JSON.stringify(
            pingFormData.convertToForm,
            null,
            2
          )}</blockquote>`
        );
        pingFormData.proxy_url =
          "https://bestcaseleads.leadspediatrack.com/ping.do";
        const tokenResult = await Axios.post("/api/leads/proxy", pingFormData);
        ping_token = tokenResult.data.ping_id;

        callbackData(
          (tokenResult.data.result === "success" ? "✅" : "❌") +
            ` Received response for ping:<blockquote>${JSON.stringify(
              tokenResult.data,
              null,
              2
            )}</blockquote>`
        );
        if (tokenResult.data.result !== "success") {
          callbackData(`❌ Status not success, canceling.`);
          callbackState("done");
          return;
        }
        // tokenResult.data.price = 101;
        if (tokenResult.data.price) {
          callbackData(`✅ received price: $${tokenResult.data.price}.<br>`);
          if (!lead.external_apis) lead.external_apis = {};

          lead.external_apis.bestCaseBS = {
            price: tokenResult.data.price,
            ping_token,
          };
          lead.value = tokenResult.data.price;
          updateLead(lead);
        }
      }
      const postFormData = {
        convertToForm: {
          lp_campaign_id: "609964afe4d49",
          lp_campaign_key: "NnzrfwLFj2GVWZ6qQpyb",
          lp_s1: "MyAccident.org",
          lp_response: "JSON",
          zip_code: lead.zip,
          email_address: lead.email,
          phone_home: lead.phone,
          first_name: lead.firstName,
          last_name: lead.lastName,
          county: lead.county || "",
          has_attorney: "No",
          ip_address: lead.ip_address,
          treatment: "No",
          injury_year: moment(lead.createdAt).format("YYYY"),
          injury_month: moment(lead.createdAt).format("MM"),
          mva_physical_injury: "Yes",
          case_description: lead.notes,
          mva_accident_fault: "No",
          external_lead_id: lead._id,
          landing_page: "https://myaccident.org",
          lp_ping_id: ping_token,
          // user_agent: "Chrome",
          mva_accident_type: hasCommercial(lead)
            ? "Commercial Vehicle Accident"
            : "Car Accident",
        },
      };
      callbackData(
        `Sending POST request with data: <blockquote>${JSON.stringify(
          postFormData.convertToForm,
          null,
          2
        )}</blockquote>`
      );
      postFormData.proxy_url =
        "https://bestcaseleads.leadspediatrack.com/post.do";

      const postResult = await Axios.post("/api/leads/proxy", postFormData);

      callbackData(
        (postResult.data.result === "success" ? "✅" : "❌") +
          ` BestCase HighEnd POST request result: <blockquote>${JSON.stringify(
            postResult.data,
            null,
            2
          )}</blockquote>`
      );
      if (postResult.data.result === "success" && postResult.data.price) {
        lead.external_apis.bestCaseBS.price = postResult.data.price;
      }
      lead.value = lead.external_apis.bestCaseBS.price;

      updateLead(lead.external_apis, lead.value);

      callbackState("done");
      resolve();
      /* 
      // BestCase BackStop POSTER END
      */
    },
    "60afcffa2fd3e80019814e27": async (resolve) => {
      /* 
      // BestCase BackStop mailer START
      */
      if (!lead.zip) {
        swal.fire(
          "Can't send lead to ring API.",
          "Lead doesn't have a ZIP code. Try to edit this lead and enter a zip.",
          "error"
        );
        return false;
      }

      callbackState("running");
      callbackData(`Contacting BestCase API...`);
      /*
      let ping_token =
        lead.external_apis &&
        lead.external_apis.bestCaseBS &&
        lead.external_apis.bestCaseBS.ping_token
          ? lead.external_apis.bestCaseBS.ping_token
          : "";

      let pingFormData = {
        convertToForm: {
          lp_campaign_id: "609964afe4d49",
          lp_campaign_key: "NnzrfwLFj2GVWZ6qQpyb",
          lp_s1: "MyAccident.org",
          lp_response: "JSON",
          zip_code: lead.zip,
          // email: lead.email,
          // phone: lead.phone,
          // first_name: lead.firstName,
          // last_name: lead.lastName,
          has_attorney: "No",
          // ip_address: lead.ip_address,
          treatment: "No",
          injury_year: moment(lead.createdAt).format("YYYY"),
          injury_month: moment(lead.createdAt).format("MM"),
          mva_physical_injury: "Yes",
          case_description: lead.notes,
          mva_accident_fault: "No",
          external_lead_id: lead._id,
          landing_page: "https://myaccident.org",
          // user_agent: "Chrome",
          mva_accident_type: hasCommercial(lead)
            ? "Commercial Vehicle Accident"
            : "Car Accident",
        },
      };
      if (!ping_token) {
        callbackData(
          `Sending ping request for zip ${
            lead.zip
          }: <blockquote>${JSON.stringify(
            pingFormData.convertToForm,
            null,
            2
          )}</blockquote>`
        );
        pingFormData.proxy_url =
          "https://bestcaseleads.leadspediatrack.com/ping.do";
        const tokenResult = await Axios.post("/api/leads/proxy", pingFormData);
        ping_token = tokenResult.data.ping_id;

        callbackData(
          (tokenResult.data.result === "success" ? "✅" : "❌") +
            ` Received response for ping:<blockquote>${JSON.stringify(
              tokenResult.data,
              null,
              2
            )}</blockquote>`
        );
        if (tokenResult.data.result !== "success") {
          callbackData(`❌ Status not success, canceling.`);
          callbackState("done");
          return;
        }
        // tokenResult.data.price = 101;
        if (tokenResult.data.price) {
          callbackData(`✅ received price: $${tokenResult.data.price}.<br>`);
          if (!lead.external_apis) lead.external_apis = {};

          lead.external_apis.bestCaseBS = {
            price: tokenResult.data.price,
            ping_token,
          };
          lead.value = tokenResult.data.price;
          updateLead(lead);
        }
      }*/
      const postFormData = {
        convertToForm: {
          lp_campaign_id: "60aeb57b14a81",
          lp_campaign_key: "dLRGcqzBF269JxDbmNjw",
          lp_s1: "MyAccident.org",
          lp_response: "JSON",
          zip_code: lead.zip,
          email_address: lead.email,
          phone_home: lead.phone,
          first_name: lead.firstName,
          last_name: lead.lastName,
          county: lead.county || "",
          has_attorney: "No",
          ip_address: lead.ip_address,
          treatment: "No",
          injury_year: moment(lead.createdAt).format("YYYY"),
          injury_month: moment(lead.createdAt).format("MM"),
          mva_physical_injury: "Yes",
          case_description: lead.notes,
          mva_accident_fault: "No",
          external_lead_id: lead._id,
          landing_page: "https://myaccident.org",
          // user_agent: "Chrome",
          mva_accident_type: hasCommercial(lead)
            ? "Commercial Vehicle Accident"
            : "Car Accident",
        },
      };
      callbackData(
        `Sending POST request with data: <blockquote>${JSON.stringify(
          postFormData.convertToForm,
          null,
          2
        )}</blockquote>`
      );
      postFormData.proxy_url =
        "https://bestcaseleads.leadspediatrack.com/post.do";

      const postResult = await Axios.post("/api/leads/proxy", postFormData);

      callbackData(
        (postResult.data.result === "success" ? "✅" : "❌") +
          ` BestCase Mailer POST request result: <blockquote>${JSON.stringify(
            postResult.data,
            null,
            2
          )}</blockquote>`
      );
      if (postResult.data.result === "success" && postResult.data.price) {
        lead.external_apis = { bestCaseML: { price: postResult.data.price } };
      }
      lead.value = lead.external_apis.bestCaseML.price;

      updateLead(lead.external_apis, lead.value);

      callbackState("done");
      resolve();
      /* 
      // BestCase BackStop mailer END
      */
    },
    "62324716c85dd8001913edab": async (resolve) => {
      /* 
      // Smart Advocate mailer START
      */

      const fixPhone = (n) => {
        if (!n) return "";
        if (String(n).startsWith("+1")) return String(n).substring(2);
        if (String(n).startsWith("1")) return String(n).substring(1);
      };

      callbackState("running");
      callbackData(`Contacting SmartAdvocate API...`);

      const postFormData = {
        Zip: lead.zip,
        email: lead.email,
        phone: fixPhone(lead.phone),
        "first name": lead.firstName,
        "last name": lead.lastName,
        City: lead.city,
        State: lead.state,
        CaseType: "Auto Accident",
        Office: lead.state === "SC" ? "South Carolina" : "North Carolina",
        summary: `${targetLeadHotness} lead,
                    Source: ${lead.source},
                    Injured: ${lead.injured},
                    At fault: ${lead.fault},
                    Legal consult: ${lead.legal_consult},
                    City: ${lead.city},
                    createdAt: ${lead.createdAt}`,
        "Intake Date": moment(lead.createdAt).format("YYYY"),
        "paid advertisement": "MyAccident.org",
        TenantID: "1315",
      };

      callbackData(
        `Sending POST request with data: <blockquote>${JSON.stringify(
          postFormData,
          null,
          2
        )}</blockquote>`
      );
      postFormData.proxy_url =
        "https://app.smartadvocate.com/sasvc/sawebservice.svc/Receiver/officecalls/e9b88ddb974e4d9791380744444cecd4";

      const postResult = await Axios.post("/api/leads/proxy", postFormData);

      callbackData(
        // (postResult.data.result === "success" ? "✅" : "❌") +
        `✅ SmartAdvocate API POST request result: <blockquote>${JSON.stringify(
          postResult.data,
          null,
          2
        )}</blockquote>`
      );
      // if (postResult.data.result === "success" && postResult.data.price) {
      //   lead.external_apis = { bestCaseML: { price: postResult.data.price } };
      // }
      // lead.value = lead.external_apis.bestCaseML.price;

      // updateLead(lead.external_apis, lead.value);

      callbackState("done");
      resolve();
      /* 
      // Smart Advocate mailer END
      */
    },
    "62cc553aea9d0b001930cf63": async (resolve) => {
      /* 
      // Morgan&Morgan START
      */

      callbackState("running");
      callbackData(`Contacting Morgan&Morgan API...`);

      const postFormData = {
        form: 180,
        raw_data: [
          {
            name: "first_name",
            value: lead.firstName,
          },
          {
            name: "last_name",
            value: lead.lastName,
          },
          {
            name: "phone",
            value: lead.phone,
          },
          {
            name: "litigation",
            value: "Personal Injury",
          },
          {
            name: "case_type",
            value: "Automobile Accident",
          },
          {
            name: "status",
            value: "Lead",
          },
          {
            name: "status_detail",
            value: "Needs to be Qualified",
          },
          {
            name: "marketing_source",
            value: "Lead Generation",
          },
          {
            name: "marketing_sub_source",
            value: "Myaccident.org",
          },
          {
            name: "lead_details",
            value: `${targetLeadHotness} lead,
                    Source: ${lead.source},
                    Injured: ${lead.injured},
                    At fault: ${lead.fault},
                    Legal consult: ${lead.legal_consult},
                    City: ${lead.city},
                    createdAt: ${lead.createdAt}`,
          },
          {
            name: "tracked_landing_page",
            value: "https://app.myaccident.org",
          },
          {
            name: "tracked_landing_timestamp",
            value: moment(lead.createdAt).format("YYYY-MM-DD HH:mm:ss"),
          },
        ],
      };

      callbackData(
        `Sending POST request with data: <blockquote>${JSON.stringify(
          postFormData,
          null,
          2
        )}</blockquote>`
      );
      postFormData.proxy_url =
        "https://apollo.forthepeople.com/api/v1/submissions";

      const postResult = await Axios.post("/api/leads/proxy", postFormData);

      callbackData(
        // (postResult.data.result === "success" ? "✅" : "❌") +
        `✅ Morgan&Morgan API POST request result: <blockquote>${JSON.stringify(
          postResult.data,
          null,
          2
        )}</blockquote>`
      );
      // if (postResult.data.result === "success" && postResult.data.price) {
      //   lead.external_apis = { morgan: { price: postResult.data.price } };
      // }
      // lead.value = lead.external_apis.morgan.price;

      // updateLead(lead.external_apis, lead.value);

      callbackState("done");
      resolve();
      /* 
      // Morgan&Morgan END
      */
    },
    "6362c709e874c300193e716a": async (resolve) => {
      /* 
      // Craigaph@gmail.com START
      */

      const fixPhone = (n) => {
        if (!n) return "";
        n = n.replace(/\D/g, "");
        if (String(n).startsWith("+1")) return String(n).substring(2);
        if (String(n).startsWith("1")) return String(n).substring(1);
      };

      callbackState("running");
      callbackData(`Contacting Craigaph@gmail.com API...`);

      const postFormData = {
        convertToForm: {
          first_name: lead.firstName,
          last_name: lead.lastName,
          email: lead.email,
          phone_no: fixPhone(lead.phone),
          city: lead.city,
          zipcode: lead.zip,
          state: lead.state,
          youInAccident: "YES",
          yourFault: lead.atFault || lead.fault ? "YES" : "NO",
          dateOfAccident: moment(lead.createdAt).format("YYYY-MM-DD HH:mm:ss"),
          caseId: lead.alert && lead.alert.case_id ? lead.alert.case_id : "",
          vin: lead.vin ? lead.vin : "",
          vehicle_make:
            lead.alert && lead.alert.vehicle1Maker
              ? lead.alert.vehicle1Maker
              : "",
          vehicle_year:
            lead.alert && lead.alert.vehicle1Year
              ? lead.alert.vehicle1Year
              : "",
        },
      };

      callbackData(
        `Sending POST request with data: <blockquote>${JSON.stringify(
          postFormData,
          null,
          2
        )}</blockquote>`
      );
      postFormData.proxy_url = "https://7medreport.com/new_api/new_lead.php";
      postFormData.headers = { "x-api-key": "oPNjVefApwL5aNDPNDBBTKVcc8xX22" };

      const postResult = await Axios.post("/api/leads/proxy", postFormData);

      callbackData(
        (postResult.data.code === "101" ? "✅" : "❌") +
          ` Craigaph@gmail.com API POST request result: <blockquote>${JSON.stringify(
            postResult.data,
            null,
            2
          )}</blockquote>`
      );
      // if (postResult.data.result === "success" && postResult.data.price) {
      //   lead.external_apis = { morgan: { price: postResult.data.price } };
      // }
      // lead.value = lead.external_apis.morgan.price;

      // updateLead(lead.external_apis, lead.value);

      callbackState("done");
      resolve();
      /* 
      // Craigaph@gmail.com END
      */
    },
    "63b72ea5f6070000193eceed": async (resolve) => {
      /* 
      // BKBB START
      */

      const fixPhone = (n) => {
        if (!n) return "";
        if (String(n).startsWith("+1")) return String(n).substring(2);
        if (String(n).startsWith("1")) return String(n).substring(1);
      };

      callbackState("running");
      callbackData(`Contacting Microsoft Login API...`);

      const loginApi = {
        convertToForm: {
          grant_type: "client_credentials",
          client_id: "a5201f27-006b-4db0-846e-61b120e1deb7",
          client_secret: "xr08Q~txoRjPZxdl8bJNN2J-PYsh8DxL4G9BOdqW",
          resource: "api://629a0ecd-3b05-4e6a-97c1-5172207c4780",
          scope: "api://629a0ecd-3b05-4e6a-97c1-5172207c4780/.default",
        },
        proxy_url:
          "https://login.microsoftonline.com/711ce59d-51d6-47dc-b188-829a396ec483/oauth2/token",
      };

      // callbackData(
      //   `Sending POST request with data: <blockquote>${JSON.stringify(
      //     loginApi,
      //     null,
      //     2
      //   )}</blockquote>`
      // );

      const tokenResult = await Axios.post("/api/leads/proxy", loginApi);
      if (tokenResult.data.error) {
        callbackData(
          `❌ Microsoft login API returned error: ${tokenResult.data.error_description}`
        );
        callbackState("done");
        resolve();
        return;
      }

      callbackData(`✅ Got token from Microsoft login API`);

      const postFormData = {
        Source: "9caa1bea-d56e-ed11-9562-000d3a314175",
        subject: "New Lead 2022-11-23",
        firstName: lead.firstName,
        lastName: lead.lastName,
        email: lead.email,
        mobile: fixPhone(lead.phone),
        caseSubType: "ce824672-9b3c-ec11-b6e5-00224828ac54", // Retrieved from metadata API
        caseType: "34c1e16c-9a3c-ec11-b6e5-00224828ac54", // Retrieved from metadata API
      };

      callbackData(`Contacting BKBB API...`);

      callbackData(
        `Sending POST request with data: <blockquote>${JSON.stringify(
          postFormData,
          null,
          2
        )}</blockquote>`
      );

      postFormData.proxy_url =
        "https://createupdateleadrizeware.azurewebsites.net/lead";
      postFormData.headers = {
        Authorization: "Bearer " + tokenResult.data.access_token,
      };

      const postResult = await Axios.post("/api/leads/proxy", postFormData);
      // console.log(postResult);

      if (postResult && postResult.data) {
        callbackData(
          `${
            postResult.data.status === "Error" ? "❌" : "✅"
          } BKBB API POST request result: <blockquote>${JSON.stringify(
            postResult.data,
            null,
            2
          )}</blockquote>`
        );
      }

      callbackState("done");
      resolve();
      /* 
      // BKBB END
      */
    },
    "645ab3ee7b92bd001916dd78": async (resolve) => {
      /* 
      // Boberdoo 300 START
      */

      const fixPhone = (n) => {
        if (!n) return "";
        if (String(n).startsWith("+1")) return String(n).substring(2);
        if (String(n).startsWith("1")) return String(n).substring(1);
      };

      const trusted_form_cert_id =
        document.getElementById("xxTrustedFormCertUrl_0") &&
        document.getElementById("xxTrustedFormCertUrl_0").value !== ""
          ? document.getElementById("xxTrustedFormCertUrl_0").value
          : "";

      callbackState("running");

      const postFormData = {
        Request: {
          Mode: "full",
          Key: "676a43f212f44ae5a87602a4a540e827511363ac0e71551ccf37d6ed150e1688",
          API_Action: "pingPostLead",
          TYPE: "30",
          SRC: "myaccident",
          Landing_Page: "myaccident.org",
          IP_Address: lead.ip_address,
          TCPA: "Yes",
          First_Name: lead.firstName,
          Last_Name: lead.lastName,
          Zip: lead.zip,
          Primary_Phone: fixPhone(lead.phone),
          Email: lead.email,
          Have_Attorney: "No",
          Incident_Date: moment(lead.createdAt).format("MM/DD/YYYY"),
          Trusted_Form_URL: lead.trusted_form_cert || trusted_form_cert_id,
          Comments: `${targetLeadHotness} lead,
                    Source: ${lead.source},
                    Injured: ${lead.injured},
                    At fault: ${lead.fault},
                    Legal consult: ${lead.legal_consult},
                    City: ${lead.city},
                    createdAt: ${lead.createdAt}`,
          Type_Of_Legal_Problem: "Auto Accident Injury",
          Sub_ID: lead.affiliate ? lead.affiliate.slice(-5) : "",
          Cost: "300",
        },
      };
      callbackData(`Contacting Boberdoo API...`);

      callbackData(
        `Sending POST request with data: <blockquote>${JSON.stringify(
          postFormData,
          null,
          2
        )}</blockquote>`
      );

      postFormData.proxy_url =
        "https://walkeradvertising.leadportal.com/apiJSON.php";

      const postResult = await Axios.post("/api/leads/proxy", postFormData);
      // console.log(postResult);

      if (postResult && postResult.data.response) {
        callbackData(
          `${
            postResult.data.response.status === "Error" ? "❌" : "✅"
          } Boberdoo API POST request result: <blockquote>${JSON.stringify(
            postResult.data,
            null,
            2
          )}</blockquote>`
        );
      }

      callbackState("done");
      resolve();
      /* 
      // Boberdoo 300 END
      */
    },
    "63c833f968e6d00019d05f8b": async (resolve) => {
      /* 
      // Boberdoo 200 START
      */

      const fixPhone = (n) => {
        if (!n) return "";
        if (String(n).startsWith("+1")) return String(n).substring(2);
        if (String(n).startsWith("1")) return String(n).substring(1);
      };

      const trusted_form_cert_id =
        document.getElementById("xxTrustedFormCertUrl_0") &&
        document.getElementById("xxTrustedFormCertUrl_0").value !== ""
          ? document.getElementById("xxTrustedFormCertUrl_0").value
          : "";

      callbackState("running");

      const postFormData = {
        Request: {
          Mode: "full",
          Key: "676a43f212f44ae5a87602a4a540e827511363ac0e71551ccf37d6ed150e1688",
          API_Action: "pingPostLead",
          TYPE: "30",
          SRC: "myaccident",
          Landing_Page: "myaccident.org",
          IP_Address: lead.ip_address,
          TCPA: "Yes",
          First_Name: lead.firstName,
          Last_Name: lead.lastName,
          Zip: lead.zip,
          Primary_Phone: fixPhone(lead.phone),
          Email: lead.email,
          Have_Attorney: "No",
          Incident_Date: moment(lead.createdAt).format("MM/DD/YYYY"),
          Trusted_Form_URL: lead.trusted_form_cert || trusted_form_cert_id,
          Comments: `${targetLeadHotness} lead,
                    Source: ${lead.source},
                    Injured: ${lead.injured},
                    At fault: ${lead.fault},
                    Legal consult: ${lead.legal_consult},
                    City: ${lead.city},
                    createdAt: ${lead.createdAt}`,
          Type_Of_Legal_Problem: "Auto Accident Injury",
          Sub_ID: lead.affiliate ? lead.affiliate.slice(-5) : "",
          Cost: "200",
        },
      };
      callbackData(`Contacting Boberdoo API...`);

      callbackData(
        `Sending POST request with data: <blockquote>${JSON.stringify(
          postFormData,
          null,
          2
        )}</blockquote>`
      );

      postFormData.proxy_url =
        "https://walkeradvertising.leadportal.com/apiJSON.php";

      const postResult = await Axios.post("/api/leads/proxy", postFormData);
      // console.log(postResult);

      if (postResult && postResult.data.response) {
        callbackData(
          `${
            postResult.data.response.status === "Error" ? "❌" : "✅"
          } Boberdoo API POST request result: <blockquote>${JSON.stringify(
            postResult.data,
            null,
            2
          )}</blockquote>`
        );
      }

      callbackState("done");
      resolve();
      /* 
      // Boberdoo 200 END
      */
    },
    "63e196e1926c80001985ad1c": async (resolve) => {
      /* 
      // Boberdoo 100 START
      */

      const fixPhone = (n) => {
        if (!n) return "";
        if (String(n).startsWith("+1")) return String(n).substring(2);
        if (String(n).startsWith("1")) return String(n).substring(1);
      };

      const trusted_form_cert_id =
        document.getElementById("xxTrustedFormCertUrl_0") &&
        document.getElementById("xxTrustedFormCertUrl_0").value !== ""
          ? document.getElementById("xxTrustedFormCertUrl_0").value
          : "";

      callbackState("running");

      const postFormData = {
        Request: {
          Mode: "full",
          Key: "676a43f212f44ae5a87602a4a540e827511363ac0e71551ccf37d6ed150e1688",
          API_Action: "pingPostLead",
          TYPE: "30",
          SRC: "myaccident",
          Landing_Page: "myaccident.org",
          IP_Address: lead.ip_address,
          TCPA: "Yes",
          First_Name: lead.firstName,
          Last_Name: lead.lastName,
          Zip: lead.zip,
          Primary_Phone: fixPhone(lead.phone),
          Email: lead.email,
          Have_Attorney: "No",
          Incident_Date: moment(lead.createdAt).format("MM/DD/YYYY"),
          Trusted_Form_URL: lead.trusted_form_cert || trusted_form_cert_id,
          Comments: `${targetLeadHotness} lead,
                    Source: ${lead.source},
                    Injured: ${lead.injured},
                    At fault: ${lead.fault},
                    Legal consult: ${lead.legal_consult},
                    City: ${lead.city},
                    createdAt: ${lead.createdAt}`,
          Type_Of_Legal_Problem: "Auto Accident Injury",
          Sub_ID: lead.affiliate ? lead.affiliate.slice(-5) : "",
          Cost: "125",
        },
      };
      callbackData(`Contacting Boberdoo API...`);

      callbackData(
        `Sending POST request with data: <blockquote>${JSON.stringify(
          postFormData,
          null,
          2
        )}</blockquote>`
      );

      postFormData.proxy_url =
        "https://walkeradvertising.leadportal.com/apiJSON.php";

      const postResult = await Axios.post("/api/leads/proxy", postFormData);
      // console.log(postResult);

      if (postResult && postResult.data.response) {
        callbackData(
          `${
            postResult.data.response.status === "Error" ? "❌" : "✅"
          } Boberdoo API POST request result: <blockquote>${JSON.stringify(
            postResult.data,
            null,
            2
          )}</blockquote>`
        );
      }

      callbackState("done");
      resolve();
      /* 
      // Boberdoo 100 END
      */
    },
    "641e0e53c054d70019ddfefb": async (resolve) => {
      /* 
      // Boberdoo 350 START
      */

      const fixPhone = (n) => {
        if (!n) return "";
        if (String(n).startsWith("+1")) return String(n).substring(2);
        if (String(n).startsWith("1")) return String(n).substring(1);
      };

      const trusted_form_cert_id =
        document.getElementById("xxTrustedFormCertUrl_0") &&
        document.getElementById("xxTrustedFormCertUrl_0").value !== ""
          ? document.getElementById("xxTrustedFormCertUrl_0").value
          : "";

      callbackState("running");

      const postFormData = {
        Request: {
          Mode: "full",
          Key: "676a43f212f44ae5a87602a4a540e827511363ac0e71551ccf37d6ed150e1688",
          API_Action: "pingPostLead",
          TYPE: "30",
          SRC: "myaccident",
          Landing_Page: "myaccident.org",
          IP_Address: lead.ip_address,
          TCPA: "Yes",
          First_Name: lead.firstName,
          Last_Name: lead.lastName,
          Zip: lead.zip,
          Primary_Phone: fixPhone(lead.phone),
          Email: lead.email,
          Have_Attorney: "No",
          Incident_Date: moment(lead.createdAt).format("MM/DD/YYYY"),
          Trusted_Form_URL: lead.trusted_form_cert || trusted_form_cert_id,
          Comments: `${targetLeadHotness} lead,
                    Source: ${lead.source},
                    Injured: ${lead.injured},
                    At fault: ${lead.fault},
                    Legal consult: ${lead.legal_consult},
                    City: ${lead.city},
                    createdAt: ${lead.createdAt}`,
          Type_Of_Legal_Problem: "Auto Accident Injury",
          Sub_ID: lead.affiliate ? lead.affiliate.slice(-5) : "",
          Cost: "350",
        },
      };
      callbackData(`Contacting Boberdoo API...`);

      callbackData(
        `Sending POST request with data: <blockquote>${JSON.stringify(
          postFormData,
          null,
          2
        )}</blockquote>`
      );

      postFormData.proxy_url =
        "https://walkeradvertising.leadportal.com/apiJSON.php";

      const postResult = await Axios.post("/api/leads/proxy", postFormData);
      // console.log(postResult);

      if (postResult && postResult.data.response) {
        callbackData(
          `${
            postResult.data.response.status === "Error" ? "❌" : "✅"
          } Boberdoo API POST request result: <blockquote>${JSON.stringify(
            postResult.data,
            null,
            2
          )}</blockquote>`
        );
      }

      callbackState("done");
      resolve();
      /* 
      // Boberdoo 350 END
      */
    },
    "641e0e58c054d70019ddff07": async (resolve) => {
      /* 
      // Boberdoo 250 START
      */

      const fixPhone = (n) => {
        if (!n) return "";
        if (String(n).startsWith("+1")) return String(n).substring(2);
        if (String(n).startsWith("1")) return String(n).substring(1);
      };

      const trusted_form_cert_id =
        document.getElementById("xxTrustedFormCertUrl_0") &&
        document.getElementById("xxTrustedFormCertUrl_0").value !== ""
          ? document.getElementById("xxTrustedFormCertUrl_0").value
          : "";

      callbackState("running");

      const postFormData = {
        Request: {
          Mode: "full",
          Key: "676a43f212f44ae5a87602a4a540e827511363ac0e71551ccf37d6ed150e1688",
          API_Action: "pingPostLead",
          TYPE: "30",
          SRC: "myaccident",
          Landing_Page: "myaccident.org",
          IP_Address: lead.ip_address,
          TCPA: "Yes",
          First_Name: lead.firstName,
          Last_Name: lead.lastName,
          Zip: lead.zip,
          Primary_Phone: fixPhone(lead.phone),
          Email: lead.email,
          Have_Attorney: "No",
          Incident_Date: moment(lead.createdAt).format("MM/DD/YYYY"),
          Trusted_Form_URL: lead.trusted_form_cert || trusted_form_cert_id,
          Comments: `${targetLeadHotness} lead,
                    Source: ${lead.source},
                    Injured: ${lead.injured},
                    At fault: ${lead.fault},
                    Legal consult: ${lead.legal_consult},
                    City: ${lead.city},
                    createdAt: ${lead.createdAt}`,
          Type_Of_Legal_Problem: "Auto Accident Injury",
          Sub_ID: lead.affiliate ? lead.affiliate.slice(-5) : "",
          Cost: "250",
        },
      };
      callbackData(`Contacting Boberdoo API...`);

      callbackData(
        `Sending POST request with data: <blockquote>${JSON.stringify(
          postFormData,
          null,
          2
        )}</blockquote>`
      );

      postFormData.proxy_url =
        "https://walkeradvertising.leadportal.com/apiJSON.php";

      const postResult = await Axios.post("/api/leads/proxy", postFormData);
      // console.log(postResult);

      if (postResult && postResult.data.response) {
        callbackData(
          `${
            postResult.data.response.status === "Error" ? "❌" : "✅"
          } Boberdoo API POST request result: <blockquote>${JSON.stringify(
            postResult.data,
            null,
            2
          )}</blockquote>`
        );
      }

      callbackState("done");
      resolve();
      /* 
      // Boberdoo 250 END
      */
    },
    "63ffe62a5fda3100197c3d42": async (resolve) => {
      /* 
      // Boberdoo colds START
      */

      const fixPhone = (n) => {
        if (!n) return "";
        if (String(n).startsWith("+1")) return String(n).substring(2);
        if (String(n).startsWith("1")) return String(n).substring(1);
      };

      const trusted_form_cert_id =
        document.getElementById("xxTrustedFormCertUrl_0") &&
        document.getElementById("xxTrustedFormCertUrl_0").value !== ""
          ? document.getElementById("xxTrustedFormCertUrl_0").value
          : "";

      callbackState("running");

      const postFormData = {
        Request: {
          Mode: "full",
          Key: "676a43f212f44ae5a87602a4a540e827511363ac0e71551ccf37d6ed150e1688",
          API_Action: "pingPostLead",
          TYPE: "30",
          SRC: "myaccidentdatacampaign",
          Landing_Page: "myaccident.org",
          IP_Address: lead.ip_address,
          TCPA: "Yes",
          First_Name: lead.firstName,
          Last_Name: lead.lastName,
          Zip: lead.zip,
          Primary_Phone: fixPhone(lead.phone),
          Email: lead.email,
          Have_Attorney: "No",
          Incident_Date: moment(lead.createdAt).format("MM/DD/YYYY"),
          Trusted_Form_URL: lead.trusted_form_cert || trusted_form_cert_id,
          Comments: `${targetLeadHotness} lead,
                    Source: ${lead.source},
                    Injured: ${lead.injured},
                    At fault: ${lead.fault},
                    Legal consult: ${lead.legal_consult},
                    City: ${lead.city},
                    createdAt: ${lead.createdAt}`,
          Type_Of_Legal_Problem: "Auto Accident Injury",
          Return_Best_Price: 1,
        },
      };
      callbackData(`Contacting Boberdoo API...`);

      callbackData(
        `Sending POST request with data: <blockquote>${JSON.stringify(
          postFormData,
          null,
          2
        )}</blockquote>`
      );

      postFormData.proxy_url =
        "https://walkeradvertising.leadportal.com/apiJSON.php";

      const postResult = await Axios.post("/api/leads/proxy", postFormData);
      // console.log(postResult);

      if (postResult && postResult.data.response) {
        callbackData(
          `${
            postResult.data.response.status === "Error" ? "❌" : "✅"
          } Boberdoo API POST request result: <blockquote>${JSON.stringify(
            postResult.data,
            null,
            2
          )}</blockquote>`
        );
      }

      callbackState("done");
      resolve();
      /* 
      // Boberdoo colds END
      */
    },
    "63d041eb244d6000191d46d5": async (resolve) => {
      /* 
      // RIDDLE & BRANTLEY START
      */

      const fixPhone = (n) => {
        if (!n) return "";
        if (String(n).startsWith("+1")) return String(n).substring(2);
        if (String(n).startsWith("1")) return String(n).substring(1);
      };

      callbackState("running");

      const postFormData = {
        firstName: lead.firstName,
        lastName: lead.lastName,
        email: lead.email,
        phone: fixPhone(lead.phone),
        websource: "MyAccident",
        sourceType: "Internet",
        intakeStatus: "Working",
        caseType: "Automobile Accident",
        date: moment(lead.createdAt).format("MM/DD/YYYY"),
      };
      callbackData(`Contacting RIDDLE & BRANTLEY API...`);

      callbackData(
        `Sending POST request with data: <blockquote>${JSON.stringify(
          postFormData,
          null,
          2
        )}</blockquote>`
      );

      postFormData.proxy_url =
        "https://riddle.secure.force.com/api/services/apexrest/litify_pm/api/v1/intake/create";

      const postResult = await Axios.post("/api/leads/proxy", postFormData);
      // console.log(postResult);

      if (postResult && postResult.data) {
        callbackData(
          `${
            postResult.data.message !== "Success" ? "❌" : "✅"
          } RIDDLE & BRANTLEY API POST request result: <blockquote>${JSON.stringify(
            postResult.data,
            null,
            2
          )}</blockquote>`
        );
      }

      callbackState("done");
      resolve();
      /* 
      // RIDDLE & BRANTLEY END
      */
    },
    "64b5a8e96bd96f0019d93b66": async (resolve) => {
      /* 
      // Oppenheim Law START
      */

      callbackState("running");
      callbackData(`Contacting Morgan&Morgan2 API...`);

      const postFormData = {
        form: 180,
        raw_data: [
          {
            name: "first_name",
            value: lead.firstName,
          },
          {
            name: "last_name",
            value: lead.lastName,
          },
          {
            name: "phone",
            value: lead.phone,
          },
          {
            name: "litigation",
            value: "Product Liability",
          },
          {
            name: "case_type",
            value: "Airbag Injury",
          },
          {
            name: "status",
            value: "Lead",
          },
          {
            name: "status_detail",
            value: "Needs to be Qualified",
          },
          {
            name: "marketing_source",
            value: "Lead Generation",
          },
          {
            name: "marketing_sub_source",
            value: "Myaccident.org",
          },
          {
            name: "lead_details",
            value: `${targetLeadHotness} lead,
                    Source: ${lead.source},
                    Injured: ${lead.injured},
                    At fault: ${lead.fault},
                    Legal consult: ${lead.legal_consult},
                    City: ${lead.city},
                    createdAt: ${lead.createdAt}`,
          },
          {
            name: "tracked_landing_page",
            value: "https://app.myaccident.org",
          },
          {
            name: "tracked_landing_timestamp",
            value: moment(lead.createdAt).format("YYYY-MM-DD HH:mm:ss"),
          },
        ],
      };

      callbackData(
        `Sending POST request with data: <blockquote>${JSON.stringify(
          postFormData,
          null,
          2
        )}</blockquote>`
      );
      postFormData.proxy_url =
        "https://apollo.forthepeople.com/api/v1/submissions";

      const postResult = await Axios.post("/api/leads/proxy", postFormData);

      callbackData(
        // (postResult.data.result === "success" ? "✅" : "❌") +
        `✅ Morgan&Morgan2 API POST request result: <blockquote>${JSON.stringify(
          postResult.data,
          null,
          2
        )}</blockquote>`
      );
      // if (postResult.data.result === "success" && postResult.data.price) {
      //   lead.external_apis = { morgan: { price: postResult.data.price } };
      // }
      // lead.value = lead.external_apis.morgan.price;

      // updateLead(lead.external_apis, lead.value);

      callbackState("done");
      resolve();
      /* 
      // Oppenheim Law END
      */
    },
    "64b6b7e2b8f8340019a40cfd": async (resolve) => {
      /* 
      // Boberdoo colds START
      */

      const fixPhone = (n) => {
        if (!n) return "";
        if (String(n).startsWith("+1")) return String(n).substring(2);
        if (String(n).startsWith("1")) return String(n).substring(1);
      };

      callbackState("running");

      const postFormData = {
        firstName: lead.firstName,
        lastName: lead.lastName,
        phone: fixPhone(lead.phone),
        email: lead.email,
        zip: lead.zip,
        state: lead.state,
        city: lead.city,
        DOL: lead.createdAt,
        caseType: "auto",
        subType: "automobile crash",
      };
      callbackData(
        `Contacting https://events.hookdeck.com/e/src_JgJGMYPcdju3 API...`
      );

      callbackData(
        `Sending POST request with data: <blockquote>${JSON.stringify(
          postFormData,
          null,
          2
        )}</blockquote>`
      );

      postFormData.proxy_url = "https://events.hookdeck.com/e/src_JgJGMYPcdju3";

      const postResult = await Axios.post("/api/leads/proxy", postFormData);
      // console.log(postResult);

      if (postResult && postResult.data) {
        callbackData(
          ` https://events.hookdeck.com/e/src_JgJGMYPcdju3 API POST request result: <blockquote>${JSON.stringify(
            postResult.data,
            null,
            2
          )}</blockquote>`
        );
      }

      callbackState("done");
      resolve();
      /* 
      // Boberdoo colds END
      */
    },
    "64b9523f3220f700196b4892": async (resolve) => {
      /* 
      // Bwalkeradvertising.leadportal.com START
      */

      const fixPhone = (n) => {
        if (!n) return "";
        if (String(n).startsWith("+1")) return String(n).substring(2);
        if (String(n).startsWith("1")) return String(n).substring(1);
      };

      const trusted_form_cert_id =
        document.getElementById("xxTrustedFormCertUrl_0") &&
        document.getElementById("xxTrustedFormCertUrl_0").value !== ""
          ? document.getElementById("xxTrustedFormCertUrl_0").value
          : "";

      callbackState("running");

      const postFormData = {
        Request: {
          Mode: "full",
          Key: "676a43f212f44ae5a87602a4a540e827511363ac0e71551ccf37d6ed150e1688",
          API_Action: "pingPostLead",
          TYPE: "30",
          SRC: "myaccidentDUI",
          Landing_Page: "myaccident.org",
          IP_Address: lead.ip_address,
          TCPA: "Yes",
          First_Name: lead.firstName,
          Last_Name: lead.lastName,
          Zip: lead.zip,
          Primary_Phone: fixPhone(lead.phone),
          Email: lead.email,
          Have_Attorney: "No",
          Incident_Date: moment(lead.createdAt).format("MM/DD/YYYY"),
          Trusted_Form_URL: lead.trusted_form_cert || trusted_form_cert_id,
          Comments: `${targetLeadHotness} lead,
                    Source: ${lead.source},
                    Injured: ${lead.injured},
                    At fault: ${lead.fault},
                    Legal consult: ${lead.legal_consult},
                    City: ${lead.city},
                    createdAt: ${lead.createdAt}`,
          Type_Of_Legal_Problem: "DUI",
          Sub_ID: lead.affiliate ? lead.affiliate.slice(-5) : "",
          Cost: "50",
        },
      };
      callbackData(`Contacting walkeradvertising.leadportal.com API...`);

      callbackData(
        `Sending POST request with data: <blockquote>${JSON.stringify(
          postFormData,
          null,
          2
        )}</blockquote>`
      );

      postFormData.proxy_url =
        "https://walkeradvertising.leadportal.com/apiJSON.php";

      const postResult = await Axios.post("/api/leads/proxy", postFormData);
      // console.log(postResult);

      if (postResult && postResult.data.response) {
        callbackData(
          `${
            postResult.data.response.status === "Error" ? "❌" : "✅"
          } Boberdoo API POST request result: <blockquote>${JSON.stringify(
            postResult.data,
            null,
            2
          )}</blockquote>`
        );
      }

      callbackState("done");
      resolve();
      /* 
      // Bwalkeradvertising.leadportal.com END
      */
    },
    "64d52100916848001999507d": async (resolve) => {
      /* 
      // Garces, Grabler & LeBrocq START
      */

      callbackState("running");
      callbackData(
        `Contacting Garces, Grabler & LeBrocqGarces, Grabler & LeBrocq API...`
      );

      const postFormData = {
        email: lead.email,
        "first name": lead.firstName,
        "last name": lead.lastName,
        phone: lead.phone,
        "paid advertisement": "MyAccident.org",
        "advertising source": "MyAccident.org",
        // Address: "123 Test st",
        City: lead.city,
        State: lead.state,
        Zip: lead.zip,
        "Intake Date": lead.createdAt,
        // DOB: "01/03/1981",
        // Gender: "Female",
        CaseType: "Auto Accident",
        // "Referral Lawfirm": "MyAccident.org",
        summary: `${targetLeadHotness} lead,
                    Injured: ${lead.injured},
                    At fault: ${lead.fault},
                    Legal consult: ${lead.legal_consult},
                    `,
      };

      callbackData(
        `Sending POST request with data: <blockquote>${JSON.stringify(
          postFormData,
          null,
          2
        )}</blockquote>`
      );
      postFormData.proxy_url =
        "https://sa.garcesgrabler.com/SAservice/sawebservice.svc/Receiver/OfficeCalls/f32d5c47470c458e8a3e17eb1bac3405";

      const postResult = await Axios.post("/api/leads/proxy", postFormData);

      callbackData(
        // (postResult.data.result === "success" ? "✅" : "❌") +
        `✅ Garces, Grabler & LeBrocq API POST request result: <blockquote>${JSON.stringify(
          postResult.data,
          null,
          2
        )}</blockquote>`
      );
      // if (postResult.data.result === "success" && postResult.data.price) {
      //   lead.external_apis = { morgan: { price: postResult.data.price } };
      // }
      // lead.value = lead.external_apis.morgan.price;

      // updateLead(lead.external_apis, lead.value);

      callbackState("done");
      resolve();
      /* 
      // Garces, Grabler & LeBrocq END
      */
    },
    "64ef74b67bfa3a0019dd8fbe": async (resolve) => {
      /* 
      // BARTLETT & GRIPPE, LLC START
      */

      callbackState("running");
      callbackData(`Contacting BARTLETT & GRIPPE, LLC API...`);

      const postFormData = {
        convertToForm: {
          LeadProvider: "MyAccident.org",
          Hear: "MyAccident",
          CaseType: "Personal Injury",
          CellPhone: lead.phone,
          Key: "BFD35CED574B457FBE7B5E8BE5BCD",
          FirstName: lead.firstName,
          LastName: lead.lastName,
          Email1: lead.email,
          Zip: lead.zip,
          State: lead.state,
          City: lead.city,
          ReturnJSON: "True",
        },
        proxy_url:
          "https://bartlettlegalgroup.lawruler.com/api-legalcrmapp.aspx",
      };

      callbackData(
        `Sending POST request with data: <blockquote>${JSON.stringify(
          postFormData.convertToForm,
          null,
          2
        )}</blockquote>`
      );

      const postResult = await Axios.post("/api/leads/proxy", postFormData);

      callbackData(
        (postResult.data.code === 200 ? "✅" : "❌") +
          `BARTLETT & GRIPPE, LLC API POST request result: <blockquote>${JSON.stringify(
            postResult.data,
            null,
            2
          )}</blockquote>`
      );
      // if (postResult.data.result === "success" && postResult.data.price) {
      //   lead.external_apis = { morgan: { price: postResult.data.price } };
      // }
      // lead.value = lead.external_apis.morgan.price;

      // updateLead(lead.external_apis, lead.value);

      callbackState("done");
      resolve();
      /* 
      // BARTLETT & GRIPPE, LLC END
      */
    },
    "655265f7e30a5b0019455dd3": async (resolve) => {
      /* 
      // The Lobb Law Firm START
      */

      callbackState("running");
      callbackData(`Contacting The Lobb Law Firm API...`);

      const postFormData = {
        convertToForm: {
          Phone: lead.phone,
          Key: "19dfe900-3f67-44f4-b929-d86c032a3ba4",
          First: lead.firstName,
          Last: lead.lastName,
          Email: lead.email,
        },
        proxy_url:
          "https://thelobblawfirm.leaddocket.com/Opportunities/Form/6?apikey=ad255ead",
      };

      callbackData(
        `Sending POST request with data: <blockquote>${JSON.stringify(
          postFormData.convertToForm,
          null,
          2
        )}</blockquote>`
      );

      const postResult = await Axios.post("/api/leads/proxy", postFormData);

      callbackData(
        (postResult.data.success ? "✅" : "❌") +
          ` The Lobb Law Firm API POST request result: <blockquote>${JSON.stringify(
            postResult.data,
            null,
            2
          )}</blockquote>`
      );
      // if (postResult.data.result === "success" && postResult.data.price) {
      //   lead.external_apis = { morgan: { price: postResult.data.price } };
      // }
      // lead.value = lead.external_apis.morgan.price;

      // updateLead(lead.external_apis, lead.value);

      callbackState("done");
      resolve();
      /* 
      // The Lobb Law Firm END
      */
    },
    "655cf6b6f45cec0019ca6981": async (resolve) => {
      /* 
      // Quantify POSTER START
      */
      if (!lead.zip) {
        swal.fire(
          "Can't send lead to Quantify API.",
          "Lead doesn't have a ZIP code. Try to ping this lead manually first to enter the zip.",
          "error"
        );
        return false;
      }

      callbackState("running");
      callbackData(`Contacting Quantify API...`);

      const trusted_form_cert_id =
        document.getElementById("xxTrustedFormCertUrl_0") &&
        document.getElementById("xxTrustedFormCertUrl_0").value !== ""
          ? document.getElementById("xxTrustedFormCertUrl_0").value
          : "";

      let ping_token =
        lead.external_apis &&
        lead.external_apis["quantify"] &&
        lead.external_apis["quantify"].ping_token
          ? lead.external_apis["quantify"].ping_token
          : "";

      const baseUrl = "https://leads.quantify.com/new_api/api.php";
      const pingRequest = {
        Key: "f30854e1f72146e17b6a51937a8790bb01d014c93f3ac8821de5b92398d02dad",
        Zip: lead.zip, //l.zip
        API_Action: "pingPostLead",
        SRC: "myaccident",
        Mode: "ping",
        Return_Best_Price: "1",
        TYPE: "25",
        Got_Atty: "No",
        Injury_Cause: "Auto Accident",
        Format: "JSON",
        Incident_Date: moment(accidentDate(lead)).format("MM/DD/YYYY"),
        At_Fault: "No",
        Physical_Injury: "Yes",
      };

      if (!ping_token) {
        callbackData(
          `Sending ping request for zip ${
            lead.zip
          }: <blockquote>${JSON.stringify(pingRequest, null, 2)}</blockquote>`
        );
        const tokenResult = await Axios.post("/api/leads/proxy", {
          proxy_url: baseUrl + jsonToQueryString(pingRequest),
        });
        ping_token = tokenResult.data.response.lead_id;

        callbackData(
          (tokenResult.data.response.status === "Matched" ? "✅" : "❌") +
            ` Received response for ping:<blockquote>${JSON.stringify(
              tokenResult.data.response,
              null,
              2
            )}</blockquote>`
        );
        if (tokenResult.data.response.status !== "Matched") {
          callbackData(`❌ Status not success, canceling.`);
          callbackState("done");
          return;
        }
        // tokenResult.data.response.price = 101;
        if (tokenResult.data.response.price) {
          callbackData(
            `✅ received price: $${tokenResult.data.response.price}.<br>`
          );
          if (!lead.external_apis) lead.external_apis = {};

          lead.external_apis["quantify"] = {
            price: tokenResult.data.response.price,
            ping_token,
          };
          lead.value = tokenResult.data.response.price;
          updateLead(lead);
        }
      }

      const postFormData = {
        Key: "f30854e1f72146e17b6a51937a8790bb01d014c93f3ac8821de5b92398d02dad",
        Zip: lead.zip,
        City: lead.city,
        State: lead.state,
        API_Action: "pingPostLead",
        SRC: "myaccident",
        Mode: "post",
        TYPE: "25",
        Email: lead.email,
        Day_Phone: lead.phone,
        First_Name: lead.firstName,
        Last_Name: lead.lastName,
        Got_Atty: "No",
        Injury_Cause: "Auto Accident",
        Format: "JSON",
        Incident_Date: moment(accidentDate(lead)).format("MM/DD/YYYY"),
        Incident_Age_Days: moment(moment()).diff(accidentDate(lead), "days"),
        At_Fault: "No",
        Physical_Injury: "Yes",
        Hospitalization: lead.injured ? "Yes" : "No",
        Lead_ID: ping_token,
        Landing_Page: "abc",
        Case_Description: lead.notes || "",
        IP_Address: lead.ip_address || "8.8.8.8",
        User_Agent: lead.user_agent || navigator.userAgent,
        Trusted_Form_URL: lead.trusted_form_cert || trusted_form_cert_id,
        Address: "123 Default St",
      };
      callbackData(
        `Sending POST request with data: <blockquote>${JSON.stringify(
          postFormData,
          null,
          2
        )}</blockquote>`
      );

      const postResult = await Axios.post("/api/leads/proxy", {
        proxy_url: baseUrl + jsonToQueryString(postFormData),
      });
      console.log(postResult);
      callbackData(
        (postResult.data.response.status === "Matched" ? "✅" : "❌") +
          ` Quantify POST request result: <blockquote>${JSON.stringify(
            postResult.data.response,
            null,
            2
          )}</blockquote>`
      );
      lead.value = lead.external_apis["quantify"].price;

      updateLead(lead.external_apis, lead.value);

      callbackState("done");
      resolve();
      /* 
      // Quantify POSTER END
      */
    },
  };

  const postToMorgan = async (
    resolve,
    additional_marketing_source_information
  ) => {
    callbackState("running");
    callbackData(
      `Contacting ${additional_marketing_source_information} API...`
    );

    const postFormData = {
      form: 193,
      raw_data: [
        {
          name: "first_name",
          value: lead.firstName,
        },
        {
          name: "email",
          value: lead.email,
        },
        {
          name: "last_name",
          value: lead.lastName,
        },
        {
          name: "phone",
          value: lead.phone,
        },
        {
          name: "litigation",
          value: "Personal Injury",
        },
        {
          name: "case_type",
          value: "Automobile Accident",
        },
        {
          name: "status",
          value: "Lead",
        },
        {
          name: "status_detail",
          value: "Needs to be Qualified",
        },
        {
          name: "marketing_source",
          value: "External Attorney",
        },
        {
          name: "marketing_sub_source",
          value: "Myaccident.org",
        },
        {
          name: "lead_details",
          value: `${targetLeadHotness} lead,
                    Source: ${lead.source},
                    Injured: ${lead.injured},
                    At fault: ${lead.fault},
                    Legal consult: ${lead.legal_consult},
                    City: ${lead.city},
                    createdAt: ${lead.createdAt}`,
        },
        {
          name: "tracked_landing_page",
          value: "https://app.myaccident.org",
        },
        {
          name: "tracked_landing_timestamp",
          value: moment(lead.createdAt).format("x"),
        },
        {
          name: "additional_marketing_source_information",
          value: additional_marketing_source_information,
        },
      ],
    };

    callbackData(
      `Sending POST request with data: <blockquote>${JSON.stringify(
        postFormData,
        null,
        2
      )}</blockquote>`
    );
    postFormData.proxy_url =
      "https://apollo.forthepeople.com/api/v1/submissions";

    const postResult = await Axios.post("/api/leads/proxy", postFormData);

    callbackData(
      // (postResult.data.result === "success" ? "✅" : "❌") +
      `✅ Morgan&Morgan2 API POST request result: <blockquote>${JSON.stringify(
        postResult.data,
        null,
        2
      )}</blockquote>`
    );
    callbackState("done");
    resolve();
  };

  return new Promise((resolve) => {
    if (posterProviders[user_id]) posterProviders[user_id](resolve);
    else if (morganUsers[user_id]) postToMorgan(resolve, morganUsers[user_id]);
    else resolve();
  });
};


