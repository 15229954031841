/* eslint-disable no-undef */
import React from "react";
import { Link } from "react-router-dom";

export default function AdPlans({ pricingPage }) {
  return (
    <div className="content-box city">
      <h1 className="HomePage__main-title HomePage__title">
        <span>AD PLANS TAILORED TO FIT YOR NEEDS</span>
      </h1>

      <div className="row">
        <div className="column">
          <div className="greenish-box">
            <strong>
              Texas Ads {pricingPage && <span>($799.99/mo)</span>}
            </strong>
            The Texas Ad plan will show your ad to all reports in the state of
            Texas.
          </div>
          <div className="greenish-box">
            <strong>
              Regional Ads {pricingPage && <span>($499.99/mo)</span>}
            </strong>
            Show your ad to targeted regions. Use the Map to select region(s)
            you would like to advertise in. (You can select multiple).
          </div>
          <div className="greenish-box">
            <strong>
              Basic Ads {pricingPage && <span>($199.95/mo)</span>}
            </strong>
            Show your ad to all reports within a radius of 50 miles from your
            office location.
          </div>
          <div className="row">
            <div className="column">
              <Link
                className="button"
                style={{ width: 200, margin: "auto" }}
                to={"/marketing"}
              >
                Learn more
              </Link>
            </div>
          </div>
        </div>
      </div>
      <ul>
        <li>
          Your ad will display on all accident reports that occurred in the
          counties shown in the region(s) plan you select as illustrated on this
          map
        </li>
        <li>Ad’s are displayed evenly between advertising partners</li>
        <li>Markets have limited availability</li>
        <li>
          All leads generated on your ad are sent directly to your email/SMS and
          MyAccident.org Business Dashboard.
        </li>
      </ul>
    </div>
  );
}
