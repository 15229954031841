import axios from "axios";

const axiosFn = () => {
  let baseUrlApi = 'https://api-dev.myaccident.org/';
  let baseUrlApp = 'http://localhost:5000';
  if (
    window.location.host === "business.myaccident.org" ||
    window.location.host === "business-live.myaccident.org" ||
    window.location.host === "business-test.myaccident.org"
  ) {
    baseUrlApi = "https://api.myaccident.org/";
    baseUrlApp = "https://app.myaccident.org/";
  } else if (
    window.location.host === "business-dev.myaccident.org" ||
    window.location.host.indexOf("pages.dev") > 0
  ) {
    baseUrlApi = "https://api-dev.myaccident.org/";
    baseUrlApp = "https://app-dev.myaccident.org/";
  }
  return {
    api: axios.create({
      baseURL: baseUrlApi,
      withCredentials: false,
    }),
    app: axios.create({
      baseURL: baseUrlApp,
      withCredentials: true,
    }),
  }
}

const axiosInstance = {
  api: {
    get: axiosFn().api.get,
    post: axiosFn().api.post,
    put: axiosFn().api.put,
    delete: axiosFn().api.delete,
  },
  app: {
    get: axiosFn().app.get,
    post: axiosFn().app.post,
    put: axiosFn().app.put,
    delete: axiosFn().app.delete,
  }
}

export default axiosInstance;
