import React, { useState } from "react";
import { Link } from "react-router-dom";

export default function AboutOurLeads({ showLearnMore }) {
  const [modal1, setModal1] = useState(false);

  return (
    <div>
      {" "}
      <div
        className={
          modal1 ? "modal advertising-plans show" : "modal advertising-plans"
        }
      >
        <h2 style={{ color: "#FFF", marginBottom: 50 }}>
          OUR Clients Not Clicks Guarantee
        </h2>
        <p>
          We understand that ultimately you don’t want ‘Leads’ you want clients.
          We also know there are a lot of claims in the market about the quality
          of leads. Based on the last 6 months of our data and feedback from our
          clients we are seeing an average 1 in 5 of our leads turn into
          clients.
        </p>
        <p>
          We are willing to put our money where our mouth is and will guarantee
          the quality of our leads we send to your law firm.
        </p>
        <p>
          When you sign up, if you have not had a client after 5 leads, we will
          either fully refund you for the leads you have purchased OR send you
          another 5 for free. It’s up to you.
        </p>
        <p>
          We understand that MyAccident.org isn’t right for every law firm and
          the specific criteria each law firm has for their client intake
          process. The only criteria we ask is that you can help clients
          anywhere in the State of Texas. Leave the rest to us.
        </p>
        <div style={{ clear: "both", height: 30 }} />
        <div className="footer">
          <button style={{ visibility: "hidden" }}>No</button>
          <button onClick={() => setModal1(false)}>Close</button>
        </div>
      </div>
      <div className="content-box">
        <img
          src="/img/clientsnotclicks.png"
          className="clients-not-clicks"
          alt="Clients not clicks"
        />
        <div className="green-gradient">
          <h1 className="HomePage__main-title HomePage__title">
            <span>Purchase Additional Lead Packages</span>
          </h1>
        </div>
        <br />
        <br />
        <br />
        <br />
        <p className="title">
          Leads can be purchased as discounted packages or individually.
        </p>
        {showLearnMore && (
          <div className="greenish-box right">
            <img
              src="/img/lead-types-screens/car-lead-1.png"
              width="160"
              alt="car lead"
            />
            <br />
            <br />
            <img
              src="/img/lead-types-screens/car-lead-2.png"
              width="160"
              alt="car lead"
            />
            <Link to="/leads" className="button">
              Learn more
            </Link>
          </div>
        )}
        <br />
        <br />
        <strong>1. How it works</strong>
        <ul className="orange">
          <li>
            Leads are generated by users requesting to talk with a lawyer on our
            site.
          </li>
          <li>
            All leads are contacted prior to being forwarded to your law firm.
          </li>
          <li>
            It is your option how to receive leads via SMS, email, or a warm
            call transfer.
          </li>
        </ul>
        <br />
        <br />
        <strong>2. Benefits</strong>
        <ul className="orange">
          <li>All leads are exclusive and only shared with one firm.</li>
          <li>Leads are instantly forwarded.</li>
          <li>We do not compete with you; our traffic is 99+% organic.</li>
          <li>
            Our current conversion rate is 1 in 5 leads converts into a client.
          </li>
          <li>The information shared by our leads is industry-leading</li>
          <li>
            Generated solely by MyAccident.org.
            {/* backed by our{" "}
						<u onClick={() => setModal1(true)} style={{ cursor: "pointer" }}>
							Clients Not Clicks Guarantee.
						</u> */}
          </li>
        </ul>
        <div style={{ clear: "both" }} />
        {showLearnMore && (
          <div className="greenish-box mobile">
            <img
              src="/img/lead-types-screens/car-lead-1.png"
              width="160"
              alt="car lead"
            />
            <br />
            <br />
            <img
              src="/img/lead-types-screens/car-lead-2.png"
              width="160"
              alt="car lead"
            />
            <Link to="/leads" className="button">
              Learn more
            </Link>
          </div>
        )}
      </div>
    </div>
  );
}
