/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { NavLink } from "react-router-dom";
import { withRouter } from "react-router";
import swal from "sweetalert2";
import Axios from "axios";

class Header extends React.Component {
  state = {
    mobileMenuOpen: false,
    insight: localStorage.getItem("me"),
  };

  componentDidMount() {
    document.addEventListener("click", this.globalMenuClose);
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.globalMenuClose);
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      document.getElementsByTagName("body")[0].classList.remove("mobile-menu");
    }
  }

  signOut() {
    swal
      .fire({
        title: "Are you sure you want to sign out?",
        showCancelButton: true,
        reverseButtons: true,
        type: "question",
      })
      .then((v) => {
        if (v.value) {
          this.props.signOut();
          this.props.history.push("/");
        }
      });
  }

  toggleMenu = () => {
    const { mobileMenuOpen } = this.state;

    this.setState({ mobileMenuOpen: !mobileMenuOpen });
  };

  globalMenuClose = (event) => {
    if (this.menuCollapse && !this.menuCollapse.contains(event.target)) {
      this.setState({ mobileMenuOpen: false });
    }
  };

  returnToAdmin() {
    Axios.post("/api/users/getInsightsToken", { id: this.state.insight }).then(
      (d) => {
        if (d.data.success) {
          localStorage.removeItem("me");
          window.location.href = "/";
        }
      }
    );
  }

  render() {
    const { mobileMenuOpen } = this.state;
    return (
      <div>
        <header
          className={`${
            !this.props.user || this.props.user.account_type === "regular"
              ? "Header"
              : ""
          } ${window.location.pathname === "/marketing" ? "" : ""}`}
        >
          {this.props.user &&
          (this.props.user.account_type === "business" ||
            this.props.user.account_type === "affiliate") ? (
            <nav>
              <img src="/img/logo.png" alt="Logo" className="main-logo" />
              <span className="hide-on-mobile">
                Welcome, {this.props.user.firstName}
              </span>
              <a href="#" onClick={() => this.signOut()}>
                Sign Out
              </a>
              <button
                className="mobile-menu-toggle"
                onClick={() =>
                  document
                    .getElementsByTagName("body")[0]
                    .classList.toggle("mobile-menu")
                }
              >
                <span></span>
                <span></span>
                <span></span>
              </button>
              {this.state.insight ? (
                <div>
                  You are spying on this account.{" "}
                  <a
                    href="#"
                    onClick={() => this.returnToAdmin()}
                    style={{ color: "red" }}
                  >
                    Go back to your account.
                  </a>
                </div>
              ) : null}
            </nav>
          ) : (
            <>
              <NavLink to="/">
                <img
                  src="/img/myaccident-logo.png"
                  alt="Logo"
                  className="main-logo"
                />
              </NavLink>

              <div>
                <button
                  className="mobile-menu-toggle"
                  onClick={this.toggleMenu}
                  ref={(el) => {
                    this.menuCollapse = el;
                  }}
                  data-testid="mobile-menu-toggle"
                >
                  <span></span>
                  <span></span>
                  <span></span>
                </button>
                <nav
                  className={`container Header__menu gray-bkg Header__menu--white-links ${
                    mobileMenuOpen ? "Header__menu--open" : ""
                  }`}
                >
                  {/* <NavLink activeClassName="active" exact to={"/affiliate"}>
                    Affiliate
                  </NavLink> */}
                  {/*<NavLink activeClassName="active" exact to={"/about"}>*/}
                  {/*  About*/}
                  {/*</NavLink>*/}
                  {/*<NavLink activeClassName="active" exact to={"/pricing"}>*/}
                  {/*  Pricing*/}
                  {/*</NavLink>*/}
                  {/*<a*/}
                  {/*  target="_blank"*/}
                  {/*  rel="noopener noreferrer"*/}
                  {/*  href="https://myaccident.typeform.com/to/JSJxbT"*/}
                  {/*>*/}
                  {/*  Request a Proposal*/}
                  {/*</a>*/}
                  {/*<span className="dash">|</span>*/}
                  <NavLink
                    activeClassName="active"
                    exact
                    to={"/signin"}
                    className="signIn"
                  >
                    Sign in
                  </NavLink>
                  <NavLink
                    activeClassName="active"
                    exact
                    to={"/signup"}
                    className="signUp-btn"
                  >
                    <span className="signUp-btn-text">Sign up</span>
                  </NavLink>
                </nav>
              </div>
            </>
          )}
        </header>
      </div>
    );
  }
}
export default withRouter(Header);
