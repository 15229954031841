import React, { useState } from "react";
import IntlTelInput from "react-intl-tel-input";
import "react-intl-tel-input/dist/main.css";
import axios from "axios";
import clsx from "clsx";

const SmsActivation = ({ user, onComplete }) => {
	const [dialCode, setDialCode] = useState(1);
	const [number, setNumber] = useState(user.businessPhone);
	const [ajax, setAjax] = useState(false);
	const [code, setCode] = useState("");
	const [codeSent, setCodeSent] = useState(false);
	const [error, setError] = useState("");

	const sendCode = e => {
		e.preventDefault();
		setAjax(true);
		setError("");
		// setTimeout(() => {
		// 	setAjax(false);
		// 	setCodeSent(true);
		// }, 1000);
		axios
			.post("/api/users/sms/send", {
				country_code: dialCode,
				phone: number
			})
			.then(d => {
				setAjax(false);
				setCodeSent(true);
			})
			.catch(e => {
				// console.log(e.response.status);
				if (e.response.status === 400) {
					setError(e.response.data.error.message);
				} else {
					setError("Unknown network error.");
				}
				// console.log(e.response.data.error.message);
				setAjax(false);
				setCodeSent(false);
			});
		// console.log("send code");
		// console.log(dialCode);
		// console.log(number);
	};

	const checkCode = e => {
		e.preventDefault();
		setAjax(true);
		setError("");
		axios
			.post("/api/users/sms/verify", {
				country_code: dialCode,
				phone: number,
				code,
				user_id: user._id
			})
			.then(d => {
				// console.log(d);
				setAjax(false);
				if (d.data.success) {
					onComplete();
				}
			})

			.catch(e => {
				setAjax(false);
				if (e.response.status === 400) {
					setError(e.response.data.error.message);
				} else {
					setError("Unknown network error.");
				}
			});
		// setTimeout(() => {
		// 	setAjax(false);
		// 	onComplete();
		// }, 1000);
	};
	return (
		<div id="activation-page">
			<h1>SMS Activation</h1>
			<p>Before we continue we need to confirm your phone number.</p>
			<br />
			<br />
			{codeSent ? (
				<form onSubmit={e => checkCode(e)} className={clsx({ ajax })}>
					<label>Enter code you received in SMS:</label>
					<input
						className="code"
						placeholder="----"
						type="number"
						value={code}
						onChange={e => setCode(e.target.value)}
					/>
					<br />
					<br />
					{error ? <div className="error">{error}</div> : null}
					<button disabled={code.length < 4}>Check Code</button>
				</form>
			) : (
				<form onSubmit={e => sendCode(e)} className={clsx({ ajax })}>
					<label>Phone Number:</label>
					<IntlTelInput
						onlyCountries={["us", "rs", "se"]}
						preferredCountries={["us"]}
						placeholder=""
						value={number}
						onPhoneNumberChange={(a, phoneNumber, c) => {
							// console.log(phoneNumber, c.dialCode);
							setDialCode(c.dialCode);
							setNumber(phoneNumber);
						}}
					/>
					<br />
					<br />
					{error ? <div className="error">{error}</div> : null}
					<button>Text me</button>
				</form>
			)}
		</div>
	);
};
export default SmsActivation;
