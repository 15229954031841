/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import Axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { MdRefresh } from "react-icons/md";
import { availableStates } from "./LeadFilter";
import affiliateIcons from "../shared/affiliateIcons";

const renderAffiliateIcon = (id) => {
  if (affiliateIcons[id])
    return (
      <img
        src={`/img/${affiliateIcons[id]}`}
        alt={affiliateIcons[id]}
        title={affiliateIcons[id]}
        width={32}
        height={32}
        style={{ borderRadius: 5, marginTop: 3 }}
      />
    );
  return null;
};

export default function Bidding({ users }) {
  const [loading, setLoading] = useState(true);
  const [pings, setPings] = useState([]);
  const [dateType, setDateType] = useState("limit");
  const [from, setFrom] = useState(moment().format("YYYY-MM-DD"));
  const [to, setTo] = useState(moment().format("YYYY-MM-DD"));
  const [states, setStates] = useState([]);
  const [showStatesFilter, setShowStatesFilter] = useState(false);
  const [limit, setLimit] = useState(100);
  const [status, setStatus] = useState("");
  const [affiliates, setAffiliates] = useState([""]);

  const load = (filter, l) => {
    setLoading(true);
    Axios.post(
      `/api/leads/pings?limit=${dateType !== "limit" ? 10000 : l ? l : limit}`,
      {
        filter: filter || {},
      }
    ).then((d) => {
      setPings(d.data.pings);
      setLoading(false);
    });
  };

  const toggleState = (v) => {
    if (states.includes(v)) {
      setStates(states.filter((vv) => v !== vv));
    } else {
      states.push(v);
      setStates([...states]);
    }
  };

  useEffect(() => {
    let filter = {};

    if (states.length) filter.state = { $in: states };

    if (status === "won") filter.lead_id = { $exists: true };
    if (status === "lost") {
      filter.lead_id = { $exists: false };
      filter.price = { $gt: 0 };
    }
    if (status === "no_bid") filter.price = 0;

    if (dateType !== "limit")
      filter.createdAt = {
        $gte: moment(from).startOf("day").toDate(),
        $lt: moment(to).endOf("day").toDate(),
      };

    if (affiliates[0]) filter.affiliate = { $in: affiliates };

    // console.log(filter);

    load(filter, limit);
  }, [states, from, to, dateType, limit, status, affiliates]);

  const renderBidStatus = (v) => {
    if (v.price === 0) return <span className="pending">NO BID</span>;
    if (v.lead) return <span className="pending Accepted">WON</span>;
    return <span className="hotness bad hot">LOST</span>;
  };

  if (loading)
    return (
      <div>
        <h2>Bidding</h2>
        <img
          src="/img/ajax-loader.gif"
          alt="loading stats..."
          className="loader-gif"
        />
        &nbsp;Loading bidding...
      </div>
    );

  return (
    <div>
      <h2>Bidding</h2>

      <div className="filters">
        <select
          value={status}
          onChange={(e) => setStatus(e.target.value)}
          data-testid="status-select"
        >
          <option value="">All statuses...</option>
          <option value="won">Won</option>
          <option value="lost">Lost</option>
          <option value="no_bid">No bid</option>
        </select>
        <select
          value={dateType}
          onChange={(e) => setDateType(e.target.value)}
          data-testid="date-type"
        >
          <option value="limit">Show last X leads</option>
          <option value="period">Choose period</option>
        </select>
        {dateType === "period" ? (
          <>
            <div>
              From:
              <input
                type="date"
                value={from}
                style={{ width: 150 }}
                onChange={(e) => setFrom(e.target.value)}
                data-testid="from"
              />
            </div>
            <div style={{ marginLeft: 10, marginRight: 10 }}>
              To:
              <input
                type="date"
                value={to}
                style={{ width: 150 }}
                onChange={(e) => setTo(e.target.value)}
                data-testid="to"
              />
            </div>
          </>
        ) : null}
        <select
          value={affiliates[0]}
          onChange={(e) => setAffiliates([e.target.value])}
        >
          <option value="">All affiliates...</option>
          {Object.keys(users)
            .filter(
              (v) => users[v].account_type === "affiliate" && !users[v].archived
            )
            .map((v) => (
              <option key={v} value={v}>
                {users[v].businessName}
              </option>
            ))}
        </select>
      </div>
      <div className="filters states" style={{ marginLeft: 0 }}>
        <div
          style={{
            border: "#DDD 1px solid",
            borderRadius: 4,
            width: showStatesFilter ? "auto" : 200,
          }}
        >
          <div
            style={{ padding: "0.5rem", cursor: "pointer" }}
            onClick={() => setShowStatesFilter(!showStatesFilter)}
          >
            States...
          </div>
          <div
            style={{
              display: showStatesFilter ? "block" : "none",
              padding: 10,
            }}
            data-testid="states-container"
          >
            <div>
              {availableStates.map((s) => (
                <a
                  onClick={() => toggleState(s)}
                  className={states.includes(s) ? "active" : ""}
                  key={s}
                >
                  {s}
                </a>
              ))}
              {states.length ? (
                <a
                  onClick={() => setStates([])}
                  style={{ fontSize: 11 }}
                  data-testid="clear-state-button"
                >
                  Clear
                </a>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <button
        style={{ float: "left", height: 38 }}
        onClick={() => load()}
        data-testid="reload-pings"
      >
        <MdRefresh size="18" />
      </button>

      <table id="leadsTable">
        <thead>
          <tr>
            <th>Date Added</th>
            <th>State</th>
            <th>Affiliate</th>
            <th>SubId</th>
            <th style={{ textAlign: "center" }}>Status</th>
            <th style={{ textAlign: "center" }}>Our Bid</th>
            <th style={{ textAlign: "center" }}>State Price</th>
            <th style={{ textAlign: "center" }}>Weight</th>
          </tr>
        </thead>
        <tbody>
          {pings.map((v) => (
            <tr key={v._id}>
              <td>{moment(v.createdAt).format("lll")}</td>
              <td>{v.state}</td>
              <td>
                <div style={{ display: "flex", alignItems: "center", gap: 5 }}>
                  {renderAffiliateIcon(v.affiliate)}
                  {users[v.affiliate].businessName}
                </div>
              </td>
              <td>{users[v.affiliate].subIDNames?.[v.subID] || v.subID}</td>
              <td style={{ textAlign: "center" }}>{renderBidStatus(v)}</td>
              <td style={{ textAlign: "center" }}>
                ${Number(v.price).toFixed(2)}
              </td>
              <td style={{ textAlign: "center" }}>
                {users[v.affiliate].statePrices?.[v.state]?.bidPrice
                  ? "$" + users[v.affiliate].statePrices?.[v.state]?.bidPrice
                  : "N/A"}
              </td>
              <td style={{ textAlign: "center" }}>{v.weight + "%" || "N/A"}</td>
              <td />
            </tr>
          ))}
        </tbody>
      </table>
      {dateType === "limit" ? (
        <div style={{ margin: 10, textAlign: "center" }}>
          Showing last {limit} pings. Show last:{" "}
          <a onClick={() => setLimit(100)}>100</a>{" "}
          <a onClick={() => setLimit(500)} data-testid="500">
            500
          </a>{" "}
          <a onClick={() => setLimit(1000)}>1000</a>{" "}
          <a onClick={() => setLimit(10000)}>10000</a>
        </div>
      ) : null}
    </div>
  );
}
