/* eslint-disable react/jsx-no-target-blank */
import React from "react";

export default function LegalFooter() {
	return (
		<div className="content-box legal">
			<p className="title">
				<a href="https://myaccident.org" target="_blank">
					MyAccident.org
				</a>{" "}
				PBC is an advertising service.{" "}
				<a href="https://myaccident.org" target="_blank">
					MyAccident.org
				</a>{" "}
				PBC is not a referral service.
			</p>
			<p>
				<a href="https://myaccident.org" target="_blank">
					MyAccident.org
				</a>{" "}
				PBC is sponsored by legal, medical, insurance, automotive, and collision
				repair professionals.{" "}
				<a href="https://myaccident.org" target="_blank">
					MyAccident.org
				</a>{" "}
				PBC makes no claim or warranty of any kind to our users regarding the
				quality of our sponsors or the services they provide.
				<br />{" "}
				<a href="https://myaccident.org" target="_blank">
					MyAccident.org
				</a>{" "}
				PBC provides a free, redacted version of police accident reports.
				<br />
				All of the information relating to accident reports is public data.
				<br />
				Our online reports do not contain any private information such as names
				or contact details of the participants involved in the accident.
				However, the reports are easy to identify by people who have a
				legitimate connection to the accident.{" "}
				<a href="https://myaccident.org/about" target="_blank">
					Learn more
				</a>
				.
			</p>
		</div>
	);
}
