/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import clsx from "clsx";
import { MdClose } from "react-icons/md";
import swal from "sweetalert2";
import { FaCheck } from "react-icons/fa";
import Axios from "axios";
import { stringTable } from "../pages/owners";

const CancelToken = Axios.CancelToken;
let cancelAxios = {};

export default class SendReportsToUser extends Component {
  state = {
    emailPreviewText: "",
    step: 1,
    businessPDF: "",
    ownersHTML: "",
    cr3PDF: "",
    vin_numbers: [],
    previewOwners: false,
    email: "",
  };

  prepareEmail(v) {
    console.log(v);
    let consultLink = `https://myaccident.org/request-free-legal-consultation?usr_name=${
      v.firstName
    }%20${v.lastName}&email=${v.business_case_id}&case_id=${
      v.accidentId && v.accidentId.caseID ? v.accidentId.caseID : ""
    }`;
    let emailPreviewText =
      this.props.user.email === "andrew@myaccident.org"
        ? `Dear ${v.firstName},\n\nPlease find attached a copy of your accident reports\n\nIf you would like a free legal consultation regarding your accident, please complete this request form: <a href='${consultLink}'>Schedule Free Legal Consult For ${v.firstName} ${v.lastName}</a>\n\nThanks,\nAndrew`
        : "";

    let vin_numbers = [];
    if (v.accident && v.accident.vehicles) {
      v.accident.vehicles.forEach((vv) => {
        if (vv.vin_code) vin_numbers.push({ vin: vv.vin_code, loading: true });
      });
    }
    this.setState({
      emailPreviewText,
      vin_numbers,
      email: this.props.sendToUserCase.business_case_id,
    });
  }
  prepareEmailAdmin(sendToUserCase, email, accidentId) {
    console.log(sendToUserCase, email, accidentId);
    let vin_numbers = [];
    Axios.get("/api/accident/" + accidentId + "/detailed").then((d) => {
      console.log(d.data);
      if (d.data.accident && d.data.accident.vehicles) {
        d.data.accident.vehicles.forEach((vv) => {
          if (vv.vin_code)
            vin_numbers.push({ vin: vv.vin_code, loading: true });
        });
      }
      this.setState({ vin_numbers });
    });
    this.setState({
      emailPreviewText: "",
      email,
    });
  }
  sendEmail() {
    // console.log(this.state.emailPreviewText);
    // console.log(this.state.email);
    // console.log(this.state.businessPDF);
    // console.log(this.state.ownersHTML);
    // console.log(this.state.cr3PDF);
    this.setState({ step: 3 });
    Axios.post("/api/accident/report/send-all-reports", {
      email: this.state.email,
      cc: this.props.user ? this.props.user.email : "",
      htmlBody: this.state.emailPreviewText.replace(/\n/g, "<br />"),
      businessPDF: this.state.businessPDF,
      cr3PDF: this.state.cr3PDF,
      ownersHTML: btoa(this.state.ownersHTML),
      crash_id:
        this.props.sendToUserCase.accidentId &&
        this.props.sendToUserCase.accidentId.crash_id
          ? this.props.sendToUserCase.accidentId.crash_id
          : "",
    })
      .then((d) => {
        console.log(d);
        this.props.close();
        swal.fire("Email sent", "", "success");
      })
      .catch((e) => {
        console.error(e);
        this.props.close();
        swal.fire(
          "Error ocurred",
          "Could not send email. Check the console log for details.",
          "error"
        );
      });
  }

  getAPIhost() {
    // let url;
    // if (window.location.hostname === "business-dev.myaccident.org")
    //   url = "https://app-dev.myaccident.org";
    // else if (window.location.hostname === "business.myaccident.org")
    //   url = "https://app.myaccident.org";
    // else url = "http://localhost:5000";

    return Axios.defaults.baseURL;
  }

  prepareOwnersInfo(owners) {
    let html = `
			<!DOCTYPE html>
			<html>
			<head>
				<meta charset="utf-8">
				<meta http-equiv="X-UA-Compatible" content="IE=edge">
				<title>Owner Report</title>
				<meta name="viewport" content="width=device-width, initial-scale=1">
				<style>
				#owners-page {
					font-family:sans-serif;
				}
				#owners-page #holder {
					display: flex;
    			flex-wrap: wrap;
				}
				#owners-page .field {
					width:25%;
					float:left;
					padding:10px;
					box-sizing:border-box;
					border-bottom:#CCC 1px solid;
				}
				#owners-page .field i {
					display:block;
					font-weight:bold;
				}
				</style>
			</head>

			<body><div id='owners-page'>`;
    owners.forEach((v) => {
      if (v.data[0]) {
        html =
          html +
          `<h1>${
            v.data[0].dataitem.textdata.field[22]._text +
            " " +
            v.data[0].dataitem.textdata.field[23]._text +
            " - " +
            v.data[0].dataitem.textdata.field[0]._text
          }</h1>`;
        html =
          html +
          `<div id="holder">${stringTable(
            v.data[0].dataitem.textdata.field
          )}</div><br /><br />`;
      }
    });
    html = html + `</div></body></html>`;

    return html;
  }

  step2() {
    this.setState({
      step: 2,
      cr3PDF: this.props.sendToUserCase.report_url || "",
    });
    if (this.props.accidentId) {
      Axios.get(`api/accident/pdf/${this.props.accidentId}/save`)
        .then((d) => {
          this.setState({
            businessPDF:
              this.getAPIhost() +
              `api/businessAccount/pdf-get/${this.props.accidentId}.pdf`,
          });
        })
        .catch((e) => {
          swal.fire("Error", "Couldn't get generate business PDF.", "error");
        });
    } else {
      Axios.get(
        `api/businessAccount/pdf/${this.props.sendToUserCase._id}/${this.props.user._id}/save`
      )
        .then((d) => {
          this.setState({
            businessPDF:
              this.getAPIhost() +
              `api/businessAccount/pdf-get/${this.props.sendToUserCase._id}_${this.props.user._id}.pdf`,
          });
        })
        .catch((e) => {
          swal.fire("Error", "Couldn't get generate business PDF.", "error");
        });
    }
    this.ownersArray = [];
    this.state.vin_numbers.forEach((v) => {
      Axios.get(`/api/business/public-data/find/vin?vin=${v.vin}`, {
        cancelToken: new CancelToken((c) => {
          cancelAxios[v.vin] = c;
        }),
      })
        .then((res) => {
          if (res.data.success) {
            this.ownersArray.push(res.data);
            if (this.ownersArray.length === this.state.vin_numbers.length) {
              this.setState({
                ownersHTML: this.prepareOwnersInfo(this.ownersArray),
              });
            }
            this.state.vin_numbers.some((vv) => {
              if (vv.vin === v.vin) {
                vv.loading = false;
                return true;
              }
              return false;
            });
            this.setState({ vin_numbers: this.state.vin_numbers });
          } else {
            swal.fire(
              "Error",
              "Couldn't get some vin number owner info.",
              "error"
            );
          }
        })
        .catch((e) => {
          // swal.fire(
          // 	"Error",
          // 	"Couldn't get some vin number owner info.",
          // 	"error"
          // );
          console.log(e);
        });
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.sendToUserCase && this.props.sendToUserCase) {
      // console.log(this.props.sendToUserCase);
      if (this.props.accidentId) {
        this.prepareEmailAdmin(
          this.props.sendToUserCase,
          this.props.email,
          this.props.accidentId
        );
      } else {
        this.prepareEmail(this.props.sendToUserCase);
      }
    }
    if (prevProps.sendToUserCase && !this.props.sendToUserCase) {
      this.setState({
        emailPreviewText: "",
        step: 1,
        businessPDF: "",
        ownersHTML: "",
        cr3PDF: "",
        vin_numbers: [],
        previewOwners: false,
      });
    }
  }

  cancelVin(k) {
    cancelAxios[this.state.vin_numbers[k].vin]();
    this.state.vin_numbers.splice(k, 1);
    this.setState({ vin_numbers: this.state.vin_numbers }, () => {
      if (this.ownersArray.length === this.state.vin_numbers.length) {
        this.setState({
          ownersHTML: this.prepareOwnersInfo(this.ownersArray),
        });
      }
    });
  }

  render() {
    return (
      <>
        <div
          className={clsx("modal", {
            show: this.props.sendToUserCase ? true : false,
          })}
        >
          {this.state.step < 3 ? (
            <button data-testid="close-step-1" className="close" onClick={() => this.props.close()}>
              <MdClose />
            </button>
          ) : null}

          {this.state.step === 1 ? (
            <div>
              <h2>Send To User</h2>
              <input
                type="email"
                value={this.state.email}
                onChange={(e) => this.setState({ email: e.target.value })}
              />
              <textarea
                value={this.state.emailPreviewText}
                onChange={(e) =>
                  this.setState({
                    emailPreviewText: e.target.value,
                  })
                }
                rows={12}
              />

              <div className="footer">
                <button onClick={() => this.props.close()} className="outline">
                  Cancel
                </button>
                <button
                  onClick={() => this.step2()}
                  disabled={!this.state.emailPreviewText || !this.state.email}
                >
                  Next
                </button>
              </div>
            </div>
          ) : null}

          {this.state.step === 2 ? (
            <div>
              <h2>Generating files</h2>

              {this.state.businessPDF ? (
                <p>
                  <FaCheck color="#4CAF50" />{" "}
                  <a
                    href={this.state.businessPDF}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Business PDF
                  </a>
                </p>
              ) : (
                <p>
                  <img src="img/ajax-loader.gif" alt="Loader" /> Generating
                  Business PDF...
                </p>
              )}
              <br />
              {this.state.ownersHTML ? (
                <p>
                  <FaCheck color="#4CAF50" />{" "}
                  <a
                    data-testid="previewOwners-true"
                    onClick={() => this.setState({ previewOwners: true })}
                    style={{ cursor: "pointer" }}
                  >
                    Owners info HTML
                  </a>
                </p>
              ) : (
                <p>
                  <img src="img/ajax-loader.gif" alt="Loader" /> Generating
                  Owners Info PDF...
                </p>
              )}
              {this.state.vin_numbers.map((v, k) => (
                <p key={v.vin} style={{ marginLeft: 20 }}>
                  {v.loading ? (
                    <img src="img/ajax-loader.gif" alt="Loader" />
                  ) : (
                    <FaCheck color="#4CAF50" />
                  )}{" "}
                  Getting VIN {v.vin}{" "}
                  {v.loading ? (
                    <a
                      onClick={() => this.cancelVin(k)}
                      style={{ cursor: "pointer" }}
                    >
                      Cancel
                    </a>
                  ) : (
                    ""
                  )}
                </p>
              ))}
              <br />
              {this.state.cr3PDF ? (
                <p>
                  <FaCheck color="#4CAF50" />{" "}
                  <a
                    href={this.state.cr3PDF}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    CR3 PDF
                  </a>
                </p>
              ) : (
                <p>Uh oh... Looks like cr3 pdf has not been uploaded.</p>
              )}

              <div className="footer">
                <button onClick={() => this.props.close()} className="outline">
                  Cancel
                </button>
                <button
                  onClick={() => this.sendEmail()}
                  disabled={
                    !this.state.businessPDF ||
                    !this.state.ownersHTML ||
                    !this.state.cr3PDF
                  }
                >
                  Send Email
                </button>
              </div>
            </div>
          ) : null}
          {this.state.step === 3 ? (
            <div>
              <h2>Sending email</h2>
              <p>
                <img src="img/ajax-loader.gif" alt="Loader" />
                <br />
                Collecting all files on server side, it might take a minute...
                <br />
                Please wait while it's done. Don't change the page and don't
                close the browser.
              </p>
            </div>
          ) : null}
        </div>
        <div
          data-testid="className-previewOwners"
          className={clsx("modal search", {
            show: this.state.previewOwners,
          })}
        >
          <h2>Owners Info</h2>
          <button
            data-testid="previewOwners-false"
            className="close"
            onClick={() => this.setState({ previewOwners: false })}
          >
            <MdClose />
          </button>
          <div
            dangerouslySetInnerHTML={{
              __html: this.state.ownersHTML,
            }}
          />
        </div>
      </>
    );
  }
}
