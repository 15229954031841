/* eslint-disable react/no-direct-mutation-state */
import React, { Component } from "react";
import Axios from "axios";
import { Formik, Field, ErrorMessage, Form } from "formik";
import { Redirect } from "react-router-dom";
import clsx from "clsx";
import PlanChooser from "../components/PlanChooser";
import { MdClose } from "react-icons/md";
import { Elements, StripeProvider } from "react-stripe-elements";
import NewCardForm from "../components/NewCardForm";
import swal from "sweetalert2";
import ReportsPage from "./reports";
import Invoices from "../components/Invoices";
import AdvertisingOptions from "../components/AdvertisingOptions";
import BuyLeads from "../components/BuyLeads";
import Rss from "../components/Rss";

class AccountPage extends Component {
  state = {
    user: this.props.user,
    isAdmin:
      (this.props.user && this.props.user.role.id === 10) ||
      (this.props.user && this.props.user.role.id === 11)
        ? true
        : false,
    tab: window.lastAccountTab || 0,
    logoStatus: "(Click to change)",
    loading: true,
    default_source: {},
    default_source_error: null,
    sources: [],
    addNewModal: false,
    cardModalInDom: false,
    saveStatus: false,
    sub_plan: this.props.user ? this.props.user.sub_plan : "",
    touched: false,
    errs: {},
    markets:
      this.props.user && this.props.user.markets
        ? { ...this.props.user.markets }
        : {},
  };

  componentDidMount() {
    if (this.state.isAdmin) {
      Axios.get("/api/users/me").then((d) => {
        window.localStorage.setItem("user", JSON.stringify(d.data.user));
        // d.data.user.businessType = { value: "lawyers", label: "Law Firm" };
        this.setState({ user: d.data.user });
      });
      Axios.get("/api/stripe/customer/retrieve")
        .then((d) => {
          // console.log(d.data.customer.default_source);
          // console.log(d.data);
          if (!d.data.success) {
            return this.setState({
              default_source_error: d.data.error,
              loading: false,
            });
          }
          this.setState({
            default_source: d.data.customer.default_source,
            sources: d.data.customer.sources.data,
            loading: false,
          });
        })
        .catch((e) => {
          this.setState({
            default_source_error: "Couldn't get payment methods.",
            loading: false,
          });
        });
    }
  }
  save(e) {
    e.preventDefault();
    this.setState({ isSubmitting: true });
    Axios.put("/api/businessAccount/user", this.state.user).then((d) => {
      // console.log(d.data);
      this.setState({ saveStatus: true, isSubmitting: false, touched: false });
      setTimeout(() => {
        this.setState({ saveStatus: false });
      }, 2500);
    });
  }
  checkEmail(email) {
    return new Promise((resolve) => {
      if (!email) {
        resolve("");
      }
      if (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
        // Axios.get("/api/businessAccount/checkEmail?email=" + email).then(d => {
        // console.log(d.data);
        // if (d.data.found) resolve("Email already registered.");
        // else resolve("");
        resolve("");
        // });
      } else {
        resolve("Email is not valid.");
      }
    });
  }
  checkBusinessName(name) {
    return new Promise((resolve) => {
      // 	if (!name) {
      // 		resolve("Law firm name field is required");
      // 	} else {
      // 		Axios.get("/api/businessAccount/checkBusinessName?businessName=" + name).then(d => {
      // 			// console.log(d.data);
      // 			if (d.data.found) resolve("Business name already registered.");
      // 			else resolve("");
      // 		});
      // 	}
      resolve("");
    });
  }

  uploadLogo(e) {
    this.state.user.logo_url = URL.createObjectURL(e.target.files[0]);
    this.setState({
      user: this.state.user,
      logoStatus:
        '<img src="/img/ajax-loader.gif" alt="Uploading..." /> Uploading...',
    });
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    Axios.post(`/api/businessAccount/upload/logo`, formData)
      .then((response) => {
        // console.log(response);
        this.state.user.logo_url = response.data.logo_url;
        this.setState({
          user: this.state.user,
          logoStatus: "Logo change saved!",
        });
        setTimeout(() => {
          this.setState({ logoStatus: "(Click to change)" });
        }, 2500);
      })
      .catch((error) => {
        // console.log(error);
      });
  }

  addSource(s) {
    Axios.post("/api/stripe/source/create", { source_id: s.id })
      .then((d) => {
        // console.log(d.data);
        if (d.data.success) {
          this.state.sources.unshift(d.data.source);
          this.setState({
            default_source: s.id,
            sources: this.state.sources,
          });
          this.props.setPayment(true);
          this.addCardModalClose();
        } else {
          this.addCardModalClose();
          swal.fire("Error", d.data.message, "error");
        }
      })
      .catch((e) => {
        this.addCardModalClose();
        swal.fire("Error", "Couldn't add credit card. Server error.", "error");
      });
  }
  setDefaultSource(s) {
    this.setState({ default_source: s.id });
    Axios.post("/api/stripe/source/default", { source_id: s.id });
  }
  removeSource(s) {
    if (s.id === this.state.default_source) {
      swal.fire(
        "Can't remove default card.",
        "In order to remove default card, set some other card to default.",
        "info"
      );
      return false;
    }
    swal
      .fire({
        type: "question",
        title: "Are you sure you want to remove this card?",
        text: "This action cannot be undone.",
        showCancelButton: true,
        reverseButtons: true,
      })
      .then((v) => {
        if (v.value) {
          Axios.post("/api/stripe/source/remove", { source_id: s.id });
          let key;
          this.state.sources.some((vv, k) => {
            if (vv.id === s.id) {
              key = k;
              return true;
            }
            return false;
          });
          if (key !== undefined) {
            this.state.sources.splice(key, 1);
            this.setState({ sources: this.state.sources });
          }
        }
      });
  }
  addCardModalShow() {
    this.setState({ cardModalInDom: true });
    setTimeout(() => {
      this.setState({ addNewModal: true });
    }, 100);
  }
  addCardModalClose() {
    this.setState({ addNewModal: false });
    setTimeout(() => {
      this.setState({ cardModalInDom: false });
    }, 300);
  }
  changePassword(values, actions) {
    actions.setSubmitting(true);
    Axios.post("/api/businessAccount/passwordChange", {
      currentPassword: values.oldPassword,
      newPassword: values.password,
    })
      .then((d) => {
        // console.log(d.data);
        actions.resetForm();
        this.setState({ changePasswordModal: false });
        swal.fire("Done", "Your password has been changed.", "success");
      })
      .catch((e) => {
        actions.setSubmitting(false);
        swal.fire("Error", e.response.data.error, "error");
      });
  }

  changePlan(sub_plan, markets) {
    this.setState({ sub_plan, markets });
  }

  changeDisabled() {
    if (this.state.sub_plan !== this.props.user.sub_plan) return false;
    let marketsSame = true;
    Object.keys(this.props.user.markets).some((v) => {
      if (this.props.user.markets[v] !== this.state.markets[v]) {
        marketsSame = false;
        return true;
      }
      return false;
    });
    return marketsSame;
  }

  contactUs(plan) {
    swal
      .fire({
        title: "Change Plan?",
        html: "Please allow 24 Hours for your changes to be reflected on your account. <br /> <br />Optional message for MyAccident Team:",
        input: "textarea",
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonText: "Send",
      })
      .then((v) => {
        if (v.hasOwnProperty("value")) {
          console.log(v.value);
          Axios.post("/api/businessAccount/contact", { text: v.value }).then(
            (d) => {
              swal.fire(
                "Done!",
                "Your request has been sent. Someone will contact you shortly.",
                "success"
              );
            }
          );
        }
      });
  }

  updateField = async (e) => {
    this.state.user[e.target.name] = e.target.value;
    // if (e.target.name === "leadEmail") {
    // 	const res = await this.checkEmail(e.target.value);
    // 	if (res === "") delete this.state.errs.leadEmail;
    // 	else this.state.errs.leadEmail = res;
    // }
    this.setState({
      user: this.state.user,
      touched: true,
      errs: this.state.errs,
    });
  };

  render() {
    if (this.props.user === null) return <Redirect to="/signin" />;
    // if (!this.props.user.active) {
    //   return (
    //     <div id="admin-page">
    //       Your account is not active, please click here to activate your account
    //     </div>
    //   );
    // }

    if (this.state.isAdmin || this.props.user.role.id === 15) {
      return (
        <div id="admin-page">
          {this.state.cardModalInDom ? (
            <div className={clsx("modal", { show: this.state.addNewModal })}>
              <button
                className="close"
                onClick={() => this.addCardModalClose()}
              >
                <MdClose />
              </button>
              <h2>Add New Card</h2>

              <StripeProvider
                apiKey={
                  window.location.host === "business.myaccident.org"
                    ? "pk_live_6AXrcQz8hxjZCWZgUXjBDWUp"
                    : "pk_test_EVC0B5mhQUgnyKKzwqsWevuj"
                }
              >
                <Elements>
                  <NewCardForm
                    close={() => this.addCardModalClose()}
                    addSource={(s) => this.addSource(s)}
                  />
                </Elements>
              </StripeProvider>
              <div className="error">{this.state.error}</div>
            </div>
          ) : null}

          <h1>Account</h1>

          <div id="tabs">
            <button
              className={clsx({ active: this.state.tab === 0 })}
              onClick={() => this.setState({ tab: 0 })}
            >
              Basic Info
            </button>
            {this.props.user.role.id !== 15 ? (
              <>
                <button
                  className={clsx({ active: this.state.tab === 1 })}
                  onClick={() => this.setState({ tab: 1 })}
                >
                  Payment
                </button>
                <button
                  className={clsx({ active: this.state.tab === 7 })}
                  onClick={() => this.setState({ tab: 7 })}
                >
                  Package
                </button>
                <button
                  className={clsx({ active: this.state.tab === 2 })}
                  onClick={() => this.setState({ tab: 2 })}
                >
                  Advertising
                </button>
                {/* <button
								className={clsx({ active: this.state.tab === 6 })}
								onClick={() => this.setState({ tab: 6 })}
							>
								Leads
							</button> */}
                <button
                  className={clsx({ active: this.state.tab === 3 })}
                  onClick={() => this.setState({ tab: 3 })}
                >
                  Password
                </button>
                <button
                  className={clsx({ active: this.state.tab === 4 })}
                  onClick={() => this.setState({ tab: 4 })}
                >
                  Usage Stats
                </button>
                <button
                  className={clsx({ active: this.state.tab === 5 })}
                  onClick={() => this.setState({ tab: 5 })}
                >
                  Receipts
                </button>
                <button
                  className={clsx({ active: this.state.tab === 8 })}
                  onClick={() => this.setState({ tab: 8 })}
                >
                  RSS feed
                </button>
              </>
            ) : null}
          </div>
          <div className={clsx("tab", { show: this.state.tab === 0 })}>
            <label>Logo:</label>

            <input
              type="file"
              accept=".jpg,.png"
              onChange={(e) => this.uploadLogo(e)}
              id="file-input"
              style={{ display: "none" }}
            />
            {this.state.user.logo_url ? (
              <span>
                <img
                  src={this.state.user.logo_url}
                  alt="User Logo"
                  className="account-logo"
                  onClick={() =>
                    window.document.getElementById("file-input").click()
                  }
                />
                <br />
                <span
                  dangerouslySetInnerHTML={{
                    __html: this.state.logoStatus,
                  }}
                />
              </span>
            ) : (
              <button
                onClick={() =>
                  window.document.getElementById("file-input").click()
                }
              >
                Upload logo
              </button>
            )}
            <br />
            <br />
            <form onSubmit={(e) => this.save(e)}>
              <div className="row">
                <div className="column">
                  <label>Account Owner First Name:</label>
                  <input
                    type="text"
                    value={this.state.user.firstName}
                    name="firstName"
                    disabled
                  />
                </div>
                <div className="column">
                  <label>Account Owner Last Name:</label>
                  <input
                    type="text"
                    value={this.state.user.lastName}
                    name="lastName"
                    disabled
                  />
                </div>
              </div>

              <div className="row">
                <div className="column">
                  <label>Business Name:</label>
                  <input
                    type="text"
                    value={this.state.user.businessName}
                    name="businessName"
                    // onChange={e => this.updateField(e)}
                    disabled
                  />
                </div>
                <div className="column">
                  <label>Business Email:</label>
                  <input
                    type="text"
                    name="businessEmail"
                    disabled
                    value={this.state.user.businessEmail}
                  />
                </div>
              </div>
              <div className="row">
                <div className="column">
                  <label>Account Owner Email:</label>
                  <input
                    value={this.state.user.email}
                    type="email"
                    name="email"
                    disabled
                  />
                </div>
                <div className="column">
                  <label>Phone Number:</label>
                  <input
                    value={this.state.user.businessPhone}
                    type="tel"
                    name="businessPhone"
                    onChange={(e) => this.updateField(e)}
                  />
                </div>
              </div>

              <div className="row">
                <div className="column">
                  <label>Lead Email:</label>
                  <input
                    value={this.state.user.leadEmail}
                    type="text"
                    name="leadEmail"
                    onChange={(e) => this.updateField(e)}
                  />
                  {this.state.errs.leadEmail ? (
                    <div className="error">{this.state.errs.leadEmail}</div>
                  ) : null}
                </div>
                <div className="column">
                  <label>Lead Phone Number:</label>
                  <input
                    value={this.state.user.leadPhone}
                    type="tel"
                    name="leadPhone"
                    onChange={(e) => this.updateField(e)}
                  />
                </div>
              </div>

              <label>Website:</label>
              <input
                value={this.state.user.website}
                type="text"
                name="website"
                onChange={(e) => this.updateField(e)}
              />

              <label>Address:</label>
              <input
                value={this.state.user.address}
                type="text"
                name="address"
                onChange={(e) => this.updateField(e)}
              />

              <div className="row">
                <div className="column">
                  <label>State:</label>
                  <input
                    value={this.state.user.state}
                    type="text"
                    name="state"
                    disabled
                    onChange={(e) => this.updateField(e)}
                  />
                </div>
                <div className="column">
                  <label>City:</label>
                  <input
                    value={this.state.user.city}
                    type="text"
                    name="city"
                    disabled
                    onChange={(e) => this.updateField(e)}
                  />
                </div>
                <div className="column">
                  <label>Zip Code:</label>
                  <input
                    value={this.state.user.zip}
                    type="text"
                    name="zip"
                    disabled
                  />
                </div>
              </div>

              <div className="row">
                <div className="column">
                  <label>Business Type:</label>
                  <input
                    value={this.state.user.businessType}
                    type="text"
                    name="businessType"
                    disabled
                  />
                </div>
                <div className="column" />
              </div>

              <div className="footer">
                <span />
                <button
                  type="submit"
                  disabled={
                    !this.state.touched ||
                    Object.keys(this.state.errs).length ||
                    this.state.isSubmitting
                  }
                >
                  {this.state.isSubmitting ? (
                    <img src="/img/ajax-loader.gif" alt="Loading" />
                  ) : (
                    "Save Changes"
                  )}
                </button>
              </div>
              <div
                className={
                  this.state.saveStatus ? "saveStatus show" : "saveStatus"
                }
              >
                Changes have been saved!
              </div>
            </form>
          </div>
          <div className={clsx("tab", { show: this.state.tab === 1 })}>
            {this.state.loading ? (
              <div>
                <img src="/img/ajax-loader.gif" alt="loader" />
                &nbsp; Loading...
              </div>
            ) : (
              <div>
                <h2>Credit Cards</h2>
                {this.state.sources.length ? (
                  <table>
                    <thead>
                      <tr>
                        <th>Card Data</th>
                        <th>Brand</th>
                        <th>Expiration</th>
                        <th>Status</th>
                        <th />
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.sources.map((v, k) => {
                        if (v.card)
                          return (
                            <tr key={v.id}>
                              <td>
                                {v.owner.name}
                                <br />
                                xxxx-xxxx-xxxx-{v.card.last4}
                              </td>
                              <td>{v.card.brand}</td>
                              <td>
                                {v.card.exp_month} / {v.card.exp_year}
                              </td>
                              <td>{v.status}</td>
                              <td>
                                <button
                                  disabled={v.id === this.state.default_source}
                                  onClick={() => this.setDefaultSource(v)}
                                  style={{ width: "49%", marginRight: "1%" }}
                                >
                                  {v.id === this.state.default_source
                                    ? "Default card"
                                    : "Set as default"}
                                </button>
                                {this.state.sources.length > 1 ? (
                                  <button
                                    className="red"
                                    onClick={() => this.removeSource(v)}
                                    style={{ width: "50%" }}
                                  >
                                    Remove
                                  </button>
                                ) : (
                                  ""
                                )}
                              </td>
                            </tr>
                          );
                        return null;
                      })}
                    </tbody>
                  </table>
                ) : null}
                <button
                  onClick={() => this.addCardModalShow()}
                  className="add-new"
                >
                  Add New Credit Card
                </button>
              </div>
            )}
          </div>
          <div className={clsx("tab", { show: this.state.tab === 7 })}>
            {this.state.tab === 7 ? (
              <PlanChooser plan={this.state.user.sub_plan} />
            ) : null}
          </div>
          <div className={clsx("tab", { show: this.state.tab === 2 })}>
            {this.state.tab === 2 ? (
              <AdvertisingOptions
                choosePlan={(plan, markets) => this.changePlan(plan, markets)}
                plan={this.props.user.adPlan}
                markets={this.props.user.markets}
                changePlan={() => this.contactUs()}
                changePlanDisabled={this.changeDisabled()}
              />
            ) : null}
          </div>
          <div className={clsx("tab", { show: this.state.tab === 3 })}>
            <h2>Change Owner Account Password</h2>
            <Formik
              initialValues={{
                oldPassword: "",
                password: "",
                password2: "",
              }}
              validate={(values) => {
                let errors = {};
                if (!values.password) errors.password = "Password is required.";
                if (!values.password2)
                  errors.password2 = "You need to repeat password.";
                else if (values.password !== values.password2)
                  errors.password2 = "Passwords don't match";
                if (!values.oldPassword)
                  errors.oldPassword = "Old password is required";
                return errors;
              }}
              onSubmit={(values, actions) => {
                this.changePassword(values, actions);
              }}
            >
              {({ errors, touched, isSubmitting }) => (
                <Form>
                  <fieldset>
                    <div className="row">
                      <div className="column">
                        <label>Old Password:</label>
                        <ErrorMessage
                          name="oldPassword"
                          render={(msg) => <div className="error">{msg}</div>}
                        />
                        <Field type="password" name="oldPassword" />
                      </div>
                    </div>
                    <div className="row">
                      <div className="column">
                        <label>New Password:</label>
                        <ErrorMessage
                          name="password"
                          render={(msg) => <div className="error">{msg}</div>}
                        />
                        <Field type="password" name="password" />
                      </div>
                    </div>
                    <div className="row">
                      <div className="column">
                        <label>Repeat New Password:</label>
                        <ErrorMessage
                          name="password2"
                          render={(msg) => <div className="error">{msg}</div>}
                        />
                        <Field type="password" name="password2" />
                      </div>
                    </div>
                  </fieldset>
                  <div className="footer">
                    <span />
                    <button
                      type="submit"
                      disabled={
                        !(
                          Object.keys(touched).length &&
                          !Object.keys(errors).length
                        ) || isSubmitting
                      }
                    >
                      {isSubmitting ? (
                        <img src="/img/ajax-loader.gif" alt="Loading" />
                      ) : (
                        "Change Password"
                      )}
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
          <div className={clsx("tab", { show: this.state.tab === 4 })}>
            {this.state.tab === 4 ? (
              <ReportsPage user={this.props.user} />
            ) : null}
          </div>
          <div className={clsx("tab", { show: this.state.tab === 5 })}>
            {this.state.tab === 5 &&
            window.location.hostname === "localhost" ? (
              <Invoices />
            ) : (
              "Coming soon"
            )}
          </div>
          <div className={clsx("tab", { show: this.state.tab === 6 })}>
            {this.state.tab === 6 && <BuyLeads user={this.state.user} />}
          </div>
          <div className={clsx("tab", { show: this.state.tab === 8 })}>
            {this.state.tab === 8 && <Rss />}
          </div>
        </div>
      );
    } else {
      return (
        <div id="admin-page">Currently only admin can manage account.</div>
      );
    }
  }
}
export default AccountPage;
