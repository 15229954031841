/* eslint-disable array-callback-return */
/* global google */

export const filterAccidents = (ctx, moment, param, swal) => {
  const { date, factor, maker, numVehicles, numParticipants } =
    JSON.parse(param);
  const { accidents } = ctx.state;
  let sortedAccidents = [];

  if (date && date === "asc") {
    sortedAccidents = accidents.sort((a, b) => {
      if (moment(a.date) > moment(b.date)) {
        return 1;
      } else if (moment(a.date) < moment(b.date)) {
        return -1;
      } else {
        return 0;
      }
    });

    ctx.setState({ accidents: sortedAccidents });
  } else if (date && date === "desc") {
    sortedAccidents = accidents.sort((a, b) => {
      if (moment(a.date) < moment(b.date)) {
        return 1;
      } else if (moment(a.date) > moment(b.date)) {
        return -1;
      } else {
        return 0;
      }
    });

    ctx.setState({ accidents: sortedAccidents });
  } else if (maker) {
    swal.fire({
      html: "Please wait...",
      showConfirmButton: false,
      title: "Filtering results",
      type: "info",
    });

    ctx.setState({ accidents: ctx.state.accidentsCopy }, () => {
      sortedAccidents = ctx.state.accidents.filter((accident) => {
        let numVehicles = accident.vehicles.length;
        let indexes = [];

        for (let index = 0; index < numVehicles; index++) {
          if (
            accident.vehicles[index] &&
            Object.keys(accident.vehicles[index]).length
          ) {
            indexes.push(index);
          }
        }

        return indexes.reduce((a, b) => {
          return accident.vehicles[a] && accident.vehicles[b]
            ? Object.values(accident.vehicles[a]).indexOf(maker) > -1 ||
                Object.values(accident.vehicles[b]).indexOf(maker) > -1
            : Object.values(accident.vehicles[b]).indexOf(maker) > -1;
        }, 0);
      });

      ctx.setState({ accidents: sortedAccidents }, () => {
        swal.close();
      });
    });
  } else if (factor) {
    swal.fire({
      html: "Please wait...",
      showConfirmButton: false,
      title: "Filtering results",
      type: "info",
    });

    ctx.setState({ accidents: ctx.state.accidentsCopy }, () => {
      sortedAccidents = ctx.state.accidents.filter((accident) => {
        let result = null;

        accident.vehicles.forEach((vehicle) => {
          if (
            vehicle.contributing_factor1 &&
            vehicle.contributing_factor1 !== "NONE"
          ) {
            if (vehicle.contributing_factor2) {
              if (vehicle.contributing_factor2 === factor) {
                result = accident;
              }
            } else {
              if (vehicle.contributing_factor1 === factor) {
                result = accident;
              }
            }
          }
        });

        if (result !== null) {
          return result;
        }
      });

      ctx.setState({ accidents: sortedAccidents }, () => {
        swal.close();
      });
    });
  } else if (numVehicles) {
    swal.fire({
      html: "Please wait...",
      showConfirmButton: false,
      title: "Filtering results",
      type: "info",
    });

    ctx.setState({ accidents: ctx.state.accidentsCopy }, () => {
      sortedAccidents = ctx.state.accidents.filter((accident) => {
        if (Number.parseInt(numVehicles) === accident.vehicles.length) {
          return accident;
        }
      });

      ctx.setState({ accidents: sortedAccidents }, () => {
        swal.close();
      });
    });
  } else if (numParticipants) {
    swal.fire({
      html: "Please wait...",
      showConfirmButton: false,
      title: "Filtering results",
      type: "info",
    });

    ctx.setState({ accidents: ctx.state.accidentsCopy }, () => {
      sortedAccidents = ctx.state.accidents.filter((accident) => {
        let participants = 0;

        accident.vehicles.forEach((vehicle) => {
          participants += vehicle.participants.length;
        });

        if (Number.parseInt(numParticipants) === participants) {
          return accident;
        }
      });

      ctx.setState({ accidents: sortedAccidents }, () => {
        swal.close();
      });
    });
  }
};

export const isVehicleTowingInfoValid = (vehicle) => {
  return (
    vehicle.towing_company.length &&
    vehicle.towing_location.length &&
    vehicle.towing_company.indexOf("DRIVER") === -1 &&
    vehicle.towing_company.indexOf("DRIVEN FROM SCENE") === -1 &&
    vehicle.towing_company.indexOf("DRIVEN AWAY") === -1 &&
    vehicle.towing_location !== "DRIVERS HOME" &&
    vehicle.towing_location !== "CAR DEALERSHIP" &&
    vehicle.towing_location !== "BODY SHOP" &&
    vehicle.towing_location !== "PRIVATE"
  );
};

export const drawMap = (ctx, icon, iconHover, props) => {
  let mapOptions =
    props.lat && props.lng
      ? {
          zoom: 11,
          center: new google.maps.LatLng(props.lat, props.lng),
          mapTypeControl: false,
          zoomControl: false,
          streetViewControl: false,
          fullscreenControl: false,
        }
      : {
          zoom: 13,
          center: new google.maps.LatLng(0, 0),
          mapTypeControl: false,
          zoomControl: false,
          streetViewControl: false,
          fullscreenControl: false,
        };

  ctx.map = new google.maps.Map(document.getElementById("map-canvas"), {
    options: mapOptions,
    gestureHandling: "greedy",
  });

  ctx.setState({ mapStarted: true });
};

export const accidentDescription = (AccidentDescriptions, data, parsedDate) => {
  return `This accident${
    data.vehicles
      ? data.vehicles.length > 1
        ? `, involving ${data.vehicles.length} vehicles`
        : `, involving ${data.vehicles.length} vehicle`
      : ""
  }
                  occurred on ${parsedDate.format("dddd, MMMM Do YYYY")} at
                  approximately ${parsedDate.format("h:mm a")}${
    data.vehicles &&
    data.vehicles[0] &&
    data.vehicles[0].contributing_factor1 &&
    AccidentDescriptions[data.vehicles[0].contributing_factor1].text.length
      ? `, when the driver of the ${
          data.vehicles && data.vehicles[0]
            ? `${data.vehicles[0].vehicle_model_year || ""} ${
                data.vehicles[0].vehicle_color
              } ${
                data.vehicles[0].vehicle_maker === "UNKNOWN"
                  ? "vehicle"
                  : data.vehicles[0].vehicle_maker
              } ${
                AccidentDescriptions[data.vehicles[0].contributing_factor1].text
              }`
            : null
        }`
      : "."
  } ${
    data.total_injury_count > 0
      ? "Injuries were reported."
      : "There were no injuries, to either drivers or passengers reported."
  } ${
    data.weather_conditions
      ? AccidentDescriptions[data.weather_conditions]
        ? AccidentDescriptions[data.weather_conditions].text
        : ""
      : ""
  } An officer from ${data.agency}
                  was present at the scene and recorded the information
                  contained in this report.`;
};

export const loaderString = () =>
  `
          <div class="preloader-wrapper big active">
            <div class="spinner-layer spinner-blue-only">
              <div class="circle-clipper left">
                <div class="circle"></div>
              </div><div class="gap-patch">
                <div class="circle"></div>
              </div><div class="circle-clipper right">
                <div class="circle"></div>
              </div>
            </div>
          </div>
        `;

export const fetchFilterValues = (ctx) => {
  if (ctx.props.accidents.length) {
    const { accidents } = ctx.props;
    let carMakerList = [];
    let factorList = [];
    let numVehiclesList = [];
    let numParticipantsList = [];

    accidents.forEach((accident) => {
      accident.vehicles.forEach((vehicle) => {
        if (
          !carMakerList.includes(vehicle.vehicle_maker) &&
          vehicle.vehicle_maker
        ) {
          carMakerList.push(vehicle.vehicle_maker);
        }

        if (
          vehicle.contributing_factor1 &&
          vehicle.contributing_factor1 !== "NONE"
        ) {
          if (vehicle.contributing_factor2) {
            if (
              !factorList.includes(vehicle.contributing_factor1) &&
              !factorList.includes(vehicle.contributing_factor2)
            ) {
              factorList.push(vehicle.contributing_factor1);
              factorList.push(vehicle.contributing_factor2);
            } else if (!factorList.includes(vehicle.contributing_factor2)) {
              factorList.push(vehicle.contributing_factor2);
            } else if (!factorList.includes(vehicle.contributing_factor1)) {
              factorList.push(vehicle.contributing_factor1);
            }
          } else if (!factorList.includes(vehicle.contributing_factor1)) {
            factorList.push(vehicle.contributing_factor1);
          }
        }

        let numberOfParticipants = String(vehicle.participants.length);

        if (
          !numParticipantsList.includes(numberOfParticipants) &&
          vehicle.participants.length
        ) {
          numParticipantsList.push(numberOfParticipants);
        }
      });

      let numberOfVehicles = String(accident.vehicles.length);

      if (!numVehiclesList.includes(numberOfVehicles)) {
        numVehiclesList.push(numberOfVehicles);
      }
    });

    ctx.setState({
      carMakerList: carMakerList.sort(),
      factorList: factorList.sort(),
      numVehiclesList: numVehiclesList.sort(),
      numParticipantsList: numParticipantsList.sort(),
      selectedCarMaker: "",
    });
  }
};

export const hasCommercial = (lead) => {
  if (lead.calc_data && lead.calc_data.commercial) return true;
  if (!lead.accident) return false;
  let gotCommercial = false;

  lead.accident.vehicles.some((v) => {
    if (v.vehicle_commercial === "Yes") {
      gotCommercial = true;
      return true;
    }
    return false;
  });
  return gotCommercial;
};
