/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import * as CanvasJSReact from "../../canvasjs.react";
import { defaultGraphOptions } from "../LeadStats";
import moment from "moment";
import Axios from "axios";

const CanvasJSChart = CanvasJSReact.CanvasJSChart;

export default function RetainerStatsGraph({ leads, users }) {
  const [graphData, setGraphData] = useState({});
  const [view, setView] = useState("daily");
  const [userFilter, setUserFilter] = useState("");
  const [loading, setLoading] = useState(false);

  const prepareGraph = (data, view) => {
    const gd = { ...defaultGraphOptions };
    gd.toolTip = {
      shared: true,
      // content: "{label}<hr />{name}: {y}",
    };
    gd.data = [
      {
        type: "stackedColumn",
        color: "#4472C3",
        showInLegend: true,
        name: "Total Leads",
        dataPoints: Object.keys(data).map((vv) => {
          return {
            label: view === "daily" ? moment(vv).format("ll") : vv,
            y: data[vv].total,
            toolTipContent:
              "<span style='color:#4472C3'>Total leads:</span> " +
              data[vv].total,
          };
        }),
      },
      {
        type: "line",
        color: "#FF0102",
        showInLegend: true,
        name: "Retained leads",
        dataPoints: Object.keys(data).map((vv) => {
          return {
            label: view === "daily" ? moment(vv).format("ll") : vv,
            y: data[vv].retained,
            toolTipContent:
              "<span style='color:#FF0102'>Retained leads:</span> " +
              data[vv].retained,
          };
        }),
      },
      {
        type: "stackedColumn",
        color: "#FFF",
        showInLegend: false,
        toolTipContent: null,
        name: "Percent",
        dataPoints: Object.keys(data).map((vv) => {
          return {
            label: view === "daily" ? moment(vv).format("ll") : vv,
            y: 0,
            indexLabel:
              ((data[vv].retained / data[vv].total) * 100).toFixed(2) + "%",
            toolTipContent:
              "<span style='color:#333'>Percent:</span> " +
              ((data[vv].retained / data[vv].total) * 100).toFixed(2) +
              "%",
          };
        }),
      },
    ];
    // console.log(data);
    setGraphData(gd);
  };
  useEffect(() => {
    if (view !== "daily") return;
    let data = {};
    leads.forEach((v) => {
      const dur = moment.duration({ to: moment(), from: v.createdAt }).asDays();

      if (view === "daily" && dur > 30) return;

      let period;
      if (view === "daily")
        period = new moment(v.createdAt).format("YYYY-MM-DD");
      if (view === "monthly") period = v.createdAt.substr(0, 7);
      if (view === "weekly") {
        period =
          "Week " +
          moment(v.createdAt).isoWeek() +
          ", " +
          new Date(v.createdAt).getFullYear();
      }
      if (!data[period])
        data[period] = {
          total: 0,
          retained: 0,
        };

      if (userFilter === v.accepted) {
        console.log(userFilter, userFilter === v.accepted, v.accepted);
      }

      if (
        v.lead_level === "sold" &&
        v.accepted &&
        users[v.accepted]?.retainer &&
        (userFilter === "" || userFilter === v.accepted)
      ) {
        data[period].total = data[period].total + 1;
        if (v.retainerSuccess)
          data[period].retained = data[period].retained + 1;
      }
    });
    prepareGraph(data, "daily");
  }, [view, userFilter, leads]);

  const changeView = (e) => {
    if (e !== "daily") {
      loadCalculated(e, userFilter);
      return;
    }
    setView(e);
  };

  const loadCalculated = (v, u) => {
    setLoading(true);
    let url = `/api/leads/stats-calculated?view=${v}&justSold=1`;
    if (u) url += `&userFilter=${u}`;
    Axios.get(url).then((d) => {
      setLoading(false);
      const data = {};
      d.data.graphs.retainer.retained.forEach((v, k) => {
        data[v.label] = {
          total: d.data.graphs.retainer.total[k].y,
          retained: d.data.graphs.retainer.retained[k].y,
        };
      });
      prepareGraph(data, v);
      setView(v);
    });
  };

  const changeUserFilter = (e) => {
    if (view !== "daily") {
      loadCalculated(view, e);
      setUserFilter(e);
    } else setUserFilter(e);
  };

  if (loading)
    return (
      <div>
        <img
          src="/img/ajax-loader.gif"
          alt="loading stats..."
          className="loader-gif"
        />
        &nbsp;Loading stats...
      </div>
    );

  return (
    <div>
      <div className="row" style={{ gap: 20 }}>
        <select onChange={(e) => changeView(e.target.value)} value={view}>
          <option value="daily">Daily</option>
          <option value="weekly">Weekly</option>
          <option value="monthly">Monthly</option>
        </select>
        <select
          onChange={(e) => changeUserFilter(e.target.value)}
          value={userFilter}
        >
          <option value="">All Users</option>
          {Object.keys(users)
            .filter((u) => users[u].retainer)
            .map((v) => {
              return (
                <option key={v} value={v}>
                  {users[v].businessName}
                </option>
              );
            })}
        </select>
      </div>
      <CanvasJSChart options={graphData} />
    </div>
  );
}
