import React, { Component } from "react";
import { MdClose } from "react-icons/md";
import Axios from "axios";
import swal from "sweetalert2";
export default class SmsConversation extends Component {
	state = {
		detail: undefined,
		messages: [],
		loading: true,
		newMessage: ""
	};
	componentDidMount() {
		window.addEventListener("open-sms-conversation", ({ detail }) => {
			// console.log(detail);
			this.setState({ detail, loading: true });
			Axios.get("api/twilio/sms/list-messages?lead_id=" + detail._id)
				.then((d) => {
					if (d.data.success) {
						this.setState({
							loading: false,
							messages: d.data.messages
						});

						window.dispatchEvent(
							new CustomEvent("sms-mark-read", {
								detail: {
									lead: detail._id
								}
							})
						);
					} else {
						this.setState({ loading: false });
						swal.fire("Error", "Couldn't get messages.", "error");
					}
				})
				.catch((e) => {
					this.setState({ loading: false });
					swal.fire("Error", "Couldn't get messages.", "error");
				});
		});
		setInterval(() => {
			this.silentlyCheckForNewMessages();
		}, 5000);
	}
	silentlyCheckForNewMessages() {
		if (this.state.detail) {
			Axios.get(
				"api/twilio/sms/list-messages?lead_id=" + this.state.detail._id
			).then((d) => {
				if (d.data.success) {
					this.setState({
						messages: d.data.messages
					});
				}
			});
		}
	}
	sendMessage() {
		this.setState({ loading: true });
		Axios.post("/api/twilio/sms/send", {
			lead_id: this.state.detail._id,
			text: this.state.newMessage
		})
			.then((d) => {
				console.log(d.data);
				if (d.data.success) {
					this.state.messages.push(d.data.sms);
					this.setState({ loading: false, newMessage: "" }, () => {
						window.dispatchEvent(
							new CustomEvent("sms-sent", {
								detail: {
									lead: this.state.detail._id,
									sms_count: this.state.messages.length
								}
							})
						);
						document.querySelector(".sms-conversation").scrollTo({
							top: document.querySelector(".sms-conversation").scrollHeight,
							behavior: "smooth"
						});
					});
				} else {
					this.setState({ loading: false });
					swal.fire("Error", "Couldn't send message.", "error");
				}
			})
			.catch((e) => {
				this.setState({ loading: false });
				swal.fire("Error", "Couldn't send message.", "error");
			});
	}
	render() {
		return (
			<div className={this.state.detail ? "modal show" : "modal"}>
				<button
					className="close"
					onClick={() => this.setState({ detail: undefined })}
				>
					<MdClose />
				</button>
				{this.state.detail ? (
					<div>
						<h2>SMS conversation: {this.state.detail.phone}</h2>
						<div
							className={
								this.state.loading
									? "sms-conversation ajax"
									: "sms-conversation"
							}
						>
							{this.state.messages.map((m, k) => (
								<article key={k} className={m.type}>
									<span>
										{m.type === "out"
											? "MyAccident.org"
											: this.state.detail.firstName}
										, {new Date(m.time).toLocaleString()}
									</span>
									<p>{m.text}</p>
									<span style={{ opacity: 0.5 }}>{m.status}</span>
								</article>
							))}
							{!this.state.loading && this.state.messages.length === 0 ? (
								<div className="empty">No messages.</div>
							) : null}
						</div>
					</div>
				) : null}

				<div className="footer">
					<input
						type="text"
						placeholder="Type your message here, press enter to send..."
						value={this.state.newMessage}
						onChange={(e) => this.setState({ newMessage: e.target.value })}
						onKeyDown={(k) => {
							if (k.keyCode === 13) this.sendMessage();
						}}
					/>
				</div>
			</div>
		);
	}
}
