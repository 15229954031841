import React, { useState } from "react";
import Axios from "axios";
import swal from "sweetalert2";
import LeadPlans from "./LeadPlans";

export default function BuyLeads({ user }) {
	const leadPackages = [
		{
			price: 5000,
			leads: 20
		},
		{
			price: 9500,
			leads: 50
		},
		{
			price: 18000,
			leads: 100
		}
	];

	const formatter = new Intl.NumberFormat("en-US", {
		style: "currency",
		currency: "USD"
	});

	const buy = (k) => {
		swal
			.fire({
				type: "question",
				title: "Confirm Purchase",
				text:
					"Your account will be billed: " +
					formatter.format(leadPackages[k].price),
				showCancelButton: true,
				reverseButtons: true
			})
			.then((v) => {
				if (v.value) {
					setAjax(true);
					Axios.post("/api/users/buyCredits", {
						package: k
					})
						.then((d) => {
							setAjax(false);
							if (d.data.success) {
								setLeads(d.data.leads);
								swal.fire(
									"Success!",
									`You now have ${d.data.leads} leads.`,
									"success"
								);
							} else {
								swal.fire("Error", d.data.error, "error");
							}
						})
						.catch((e) => {
							setAjax(false);
							// console.log(e.response);
							swal.fire("Error", e.response.data.error, "error");
						});
				}
			});
	};

	const [leads, setLeads] = useState(user.prepaid_leads || 0);
	const [ajax, setAjax] = useState(false);

	const changePlan = (plan) => {
		// console.log(plan);
		swal
			.fire({
				title: "Change Plan?",
				text: "Optional message for MyAccident Team:",
				input: "textarea",
				showCancelButton: true,
				reverseButtons: true,
				confirmButtonText: "Send"
			})
			.then((v) => {
				if (v.hasOwnProperty("value")) {
					Axios.post("/api/businessAccount/contact", {
						text:
							`User would like to change LEAD plan to ${plan}. Message: ` +
							v.value
					}).then((d) => {
						swal.fire(
							"Done!",
							"Your request has been sent. Someone will contact you shortly.",
							"success"
						);
					});
				}
			});
	};

	return (
		<div className={ajax ? "ajax" : ""}>
			<h2>Leads</h2>
			<p>Leads Available: {leads}</p>
			<br />
			<h3>Monthly Reoccurring Bundles</h3>
			<LeadPlans
				currentLeadPlan={user.leadPlan}
				choosePlan={(plan) => changePlan(plan)}
			/>
			<h3>One time purchase bundles</h3>
			<div className="row" style={{ padding: "0 17px" }}>
				{leadPackages.map((v, k) => (
					<div className="column leads-buy" key={k}>
						<h3>{v.leads} Leads</h3>
						{formatter.format(v.price)}
						<br />
						<button onClick={() => buy(k)} data-testid={`purchase-${v.leads}`}>Purchase now</button>
					</div>
				))}
			</div>
		</div>
	);
}
