/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react-hooks/exhaustive-deps */
import Axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import moment from "moment";

export default function PoliceAgencies({ user }) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [q, setQ] = useState(window.seo_title || "");
  const history = useHistory();
  useEffect(() => {
    if (!user || user.role.id !== 10) {
      history.push("/signin");
    } else {
    }
    if (q) search();
  }, []);

  const search = (e) => {
    if (e) e.preventDefault();
    // console.log(q);
    if (!q) return;
    // setLoading(true);
    window.seo_title = "";
    if (q.indexOf("/") > -1) {
      window.seo_title = q.split("/");
      window.seo_title = window.seo_title[window.seo_title.length - 1];
    } else {
      window.seo_title = q;
    }
    setLoading(true);

    Axios.post("/api/police-agency/search", {
      seo_title: window.seo_title,
    }).then((d) => {
      // console.log(d.data);
      setLoading(false);
      setData(d.data.data);
    });
    // console.log("SEARCH", window.seo_title);
  };

  return (
    <div id="admin-page">
      <h2>POLICE AGENCIES</h2>
      {loading ? (
        <div>
          <img
            src="/img/ajax-loader.gif"
            alt="loadingRequests..."
            className="loader-gif"
          />
          &nbsp;Loading posts...
        </div>
      ) : (
        <div>
          <form onSubmit={(e) => search(e)}>
            <input
              value={q}
              onChange={(e) => setQ(e.target.value)}
              placeholder={"Search for police agencies..."}
              style={{ width: "calc(100% - 110px)", marginRight: 10 }}
            />
            <button>Search</button>
          </form>
          {!data.length ? (
            <div></div>
          ) : (
            <div>
              <table>
                <thead>
                  <tr>
                    <th>Title</th>
                    <th>Last update</th>
                    <th>State</th>
                    <th>City</th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {data.map((p) => (
                    <tr key={p._id}>
                      <td>{p.name}</td>
                      <td>{moment(p.updated_at).format("lll")}</td>
                      <td>{p.state}</td>
                      <td>{p.city}</td>
                      <td>
                        <a
                          href={
                            "https://myaccident.org/" +
                            p.state.toLowerCase().replace(/\W/g, "-") +
                            "-accident-reports/" +
                            p.seo_title
                          }
                          className="button"
                          style={{ width: "49%", marginRight: "1%" }}
                          target="_blank"
                        >
                          Open
                        </a>
                        <Link
                          to={"/police-agencies-edit/" + p._id}
                          className="button"
                          style={{ width: "50%" }}
                        >
                          Edit
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
