import React from "react";

const Footer = (props) => {
	const renderLinks = () => {
		const links = {
			"Term of use": "https://myaccident.org/terms-of-use",
			"Privacy policy": "https://myaccident.org/privacy-policy",
			Disclaimer: "https://myaccident.org/disclaimer",
			"Review Us On Google": "https://g.page/r/CR5ATV8gbBaeEAI/review"
		};

		return Object.entries(links).map((link) => {
			return (
				<a
					key={link[0]}
					href={link[1]}
					className="Footer__link"
					target="_blank"
					rel="noopener noreferrer"
				>
					{link[0]}
				</a>
			);
		});
	};

	return (
		<footer className={props.user ? "Footer loggedin" : "Footer"}>
			<p className="Footer__right-text">
				&copy; MyAccident PBC, All rights reserved.
			</p>
			<div>{renderLinks()}</div>
		</footer>
	);
};

export default Footer;
