/* eslint-disable no-undef */
import React from "react";
import { Link } from "react-router-dom";

import NumberOne from "../Shared/NumberOne";
import NumberTwo from "../Shared/NumberTwo";
import NumberThree from "../Shared/NumberThree";
import AboutOurLeads from "./AboutOurLeads";
import AdPlans from "./AdPlans";

const InsuranceProgramHome = () => {
  return (
    <div className="InsuranceProgram">
      <div className="AdvertisementPage__top-bkg reduce-height">
        <div className="InsurancePage__top">
          <h1>PERSONAL INJURY ADVERTISING & LEAD GENERATION</h1>
          <div className="row one-two-three">
            <div className="column">
              <NumberOne />
              <h2>
                Over 50K unique new visitors a month (+1% request services from
                our advertisers).
              </h2>
            </div>
            <div className="column">
              <NumberTwo />
              <h2>Simple monthly reporting showing your Ad’s performance</h2>
            </div>
            <div className="column">
              <NumberThree />
              <h2>
                HIGH QUALITY MARKETING SERVICES
                <br />
                Thousands of users request services directly using form fills
                and our chat service
              </h2>
            </div>
          </div>
          <article>
            <h1>
              <span>GROW</span> YOUR
              <br />
              AUTO PLAINTIFF
              <br />
              FIRM <span>WITH</span>
              <br />
              MYACCIDENT.ORG
            </h1>
            <Link to="/free-trial" className="button">
              <span>Get My Free Trial</span>
            </Link>
          </article>
          <img src="img/phone-black@2x.png" alt="phone img" />
        </div>
      </div>
      <div className="dark-bg">
        <h1 className="section-h1">Advertising</h1>
        <AdPlans />
        <br />
        <br />
        <div className="dark-bg">
          <h1 className="section-h1">Leads</h1>
          <AboutOurLeads showLearnMore />
        </div>
      </div>
    </div>
  );
};

export default InsuranceProgramHome;
