import React from "react";
import moment from "moment";

export default function Expander(props) {
	const { accident } = props.data;
	// console.log(props.data);
	if (accident)
		return (
			<div className="exp">
				{accident.vehicles
					? accident.vehicles.map((v, k) => (
							<p key={k}>
								<strong>Vehicle {k + 1}: </strong>
								{v.vehicle_maker}, {v.vehicle_model_year}, {v.vehicle_color}
							</p>
					  ))
					: null}
				<p>
					<strong>Crash ID: </strong>
					{accident.crash_id}
				</p>
				<p>
					<strong>Case ID: </strong>
					{accident.caseID}
				</p>
				<p>
					<strong>Date/Time: </strong>
					{moment(accident.date).format("llll")}
				</p>
				<p>
					<strong>Address: </strong>
					{accident.location_address}
				</p>
				<p>
					<strong>Accident Contributing Factors: </strong>
					{accidentSummary(AccidentSummary, accident, moment(accident.date))}
				</p>
			</div>
		);
	else
		return (
			<div className="exp">
				{props.data.address ? (
					<p>
						<strong>Address: </strong>
						{props.data.address}
					</p>
				) : null}
				{props.data.vin ? (
					<p>
						<strong>Vin: </strong>
						{props.data.vin}
					</p>
				) : null}
				{props.data.caseId ? (
					<p>
						<strong>CASE ID: </strong>
						{props.data.caseId}
					</p>
				) : null}
				{props.data.firstName ? (
					<p>
						<strong>Client First Name: </strong>
						{props.data.firstName}
					</p>
				) : null}
				{props.data.lastName ? (
					<p>
						<strong>Client Last Name: </strong>
						{props.data.lastName}
					</p>
				) : null}
			</div>
		);
}

const accidentSummary = (AccidentSummary, data, parsedDate) => {
	return `${
		data.vehicles
			? data.vehicles.length > 1
				? `${data.vehicles.length} vehicles involved`
				: `${data.vehicles.length} vehicle involved`
			: ""
	}. 
  Occurred on ${parsedDate.format("dddd, MMMM Do YYYY")} at
  approximately ${parsedDate.format("h:mm a")}.
  ${
		data.vehicles &&
		data.vehicles[0] &&
		data.vehicles[0].contributing_factor1 &&
		AccidentSummary[data.vehicles[0].contributing_factor1].text.length
			? `Driver of the ${
					data.vehicles && data.vehicles[0]
						? `${data.vehicles[0].vehicle_model_year || ""} 
      ${data.vehicles[0].vehicle_color || ""} 
      ${
				data.vehicles[0].vehicle_maker === "UNKNOWN" ||
				!data.vehicles[0].vehicle_maker
					? "vehicle"
					: data.vehicles[0].vehicle_maker
			} 
      ${AccidentSummary[data.vehicles[0].contributing_factor1].text}`
						: null
			  }`
			: ""
	} 
    ${data.total_injury_count > 0 ? "Injuries were reported" : "No injuries"}. 
    ${
			data.weather_conditions
				? AccidentSummary[data.weather_conditions]
					? AccidentSummary[data.weather_conditions].text
					: ""
				: ""
		}. An officer from ${data.agency} was present`;
};

const AccidentSummary = {
	UNKNOWN: {
		text: ""
	},
	RAIN: {
		text: "It was raining."
	},
	"SLEET/HAIL": {
		text: "It was sleet/hail."
	},
	SNOW: {
		text: "It was snow."
	},
	FOG: {
		text: "It was fog."
	},
	"BLOWING SAND/SNOW": {
		text: "Blizzard conditions."
	},
	"SEVERE CROSSWINDS": {
		text: "Severe crosswinds."
	},
	"OTHER (EXPLAIN IN NARRATIVE)": {
		text: ""
	},
	CLEAR: {
		text: "Weather conditions were clear."
	},
	CLOUDY: {
		text: "It was cloudy."
	},
	DAYLIGHT: {
		text: "Light conditions were good."
	},
	DAWN: {
		text: "Daylight was reduced."
	},
	"DARK, NOT LIGHTED": {
		text: "It was dark."
	},
	"DARK, LIGHTED": {
		text: "There was light."
	},
	DUSK: {
		text: "Daylight was reduced."
	},
	"DARK, UNKNOWN LIGHTING": {
		text: "Dark light conditions."
	},
	"IF NO CHARGE for Both Vehicles": {
		text: "No charges."
	},
	"IF BOTH DRIVERS WERE CHARGED": {
		text: "Both drivers were charged."
	},
	NONE: {
		text: ""
	},
	"ANIMAL ON ROAD - DOMESTIC": {
		text: ""
	},
	"ANIMAL ON ROAD - WILD": {
		text: ""
	},
	"BACKED WITHOUT SAFETY": {
		text: "backed out unsafely."
	},
	"CHANGED LANE WHEN UNSAFE": {
		text: "changed lanes in an un-safe mannor."
	},
	"DISABLED IN TRAFFIC LANE": {
		text: ""
	},
	"DISREGARD STOP AND GO SIGNAL": {
		text: "failed to stop at a 'Stop and Go' signal."
	},
	"DISREGARD STOP SIGN OR LIGHT": {
		text: "failed to stop at a sign or light."
	},
	"DISREGARD TURN MARKS AT INTERSECTION": {
		text: "disregarded turn marks at the intersection."
	},
	"DISREGARD WARNING SIGN AT CONSTRUCTION": {
		text: "disregarded a construction warning sign."
	},
	"DISTRACTION IN VEHICLE": {
		text: "was distracted in the vehicle."
	},
	"DRIVER INATTENTION": {
		text: "was not paying attention."
	},
	"DROVE WITHOUT HEADLIGHTS": {
		text: "was driving without headlights."
	},
	"FAILED TO CONTROL SPEED": {
		text: "failed to control their speed."
	},
	"FAILED TO DRIVE IN SINGLE LANE": {
		text: "failed to drive in a single lane."
	},
	"FAILED TO GIVE HALF OF ROADWAY": {
		text: "failed to give half of the roadway."
	},
	"FAILED TO HEED WARNING SIGN": {
		text: "failed to heed a warning sign."
	},
	"FAILED TO PASS TO LEFT SAFELY": {
		text: "failed to pass to the left safely."
	},
	"FAILED TO PASS TO RIGHT SAFELY": {
		text: "failed to pass to the right safely."
	},
	"FAILED TO SIGNAL OR GAVE WRONG SIGNAL": {
		text: "failed to signal or gave the wrong signal."
	},
	"FAILED TO STOP AT PROPER PLACE": {
		text: "failed to stop at the proper place."
	},
	"FAILED TO STOP FOR SCHOOL BUS": {
		text: "failed to stop for a school bus."
	},
	"FAILED TO STOP FOR TRAIN": {
		text: "failed to stop for a train."
	},
	"FAILED TO YIELD RIGHT OF WAY - EMERGENCY VEHICLE": {
		text: "failed to yield the right of way to an emergency vehicle."
	},
	"FAILED TO YIELD RIGHT OF WAY - OPEN INTERSECTION": {
		text: "failed to yield the right of way at an open intersection."
	},
	"FAILED TO YIELD RIGHT OF WAY - PRIVATE DRIVE": {
		text: "failed to yield the right of way on a private drive."
	},
	"FAILED TO YIELD RIGHT OF WAY - STOP SIGN": {
		text: "failed to yield the right of way at a stop sign."
	},
	"FAILED TO YIELD RIGHT OF WAY - TO PEDESTRIAN": {
		text: "failed to yield the right of way to a pedestrian."
	},
	"FAILED TO YIELD RIGHT OF WAY - TURNING LEFT": {
		text: "failed to yield the right of way when turning left."
	},
	"FAILED TO YIELD RIGHT OF WAY - TURN ON RED": {
		text: "failed to yield the right of way when turning on red."
	},
	"FAILED TO YIELD RIGHT OF WAY - YIELD SIGN": {
		text: "failed to yield the right of way at a yield sign."
	},
	"FATIGUED OR ASLEEP": {
		text: "was sleepy or asleep while driving."
	},
	"FAULTY EVASIVE ACTION": {
		text: "took faulty evasive action."
	},
	"FIRE IN VEHICLE": {
		text: ""
	},
	"FLEEING OR EVADING POLICE": {
		text: "was fleeing or evading police."
	},
	"FOLLOWED TOO CLOSELY": {
		text: "was following too closely."
	},
	"HAD BEEN DRINKING": {
		text: "had been drinking."
	},
	"HANDICAPPED DRIVER (EXPLAIN IN NARRATIVE)": {
		text: ""
	},
	"ILL (EXPLAIN IN NARRATIVE)": {
		text: ""
	},
	"IMPAIRED VISIBILITY (EXPLAIN IN NARRATIVE)": {
		text: ""
	},
	"IMPROPER START FROM PARKED POSITION": {
		text: "made an improper start from a parked position."
	},
	"LOAD NOT SECURED": {
		text: "did not secure their load correctly."
	},
	"OPENED DOOR INTO TRAFFIC LANE": {
		text: "opened their door into a traffic lane."
	},
	"OVERSIZED VEHICLE OR LOAD": {
		text: ""
	},
	"OVERTAKE AND PASS INSUFFICIENT CLEARANCE": {
		text: "attempted to overtake and pass with insufficient clearance."
	},
	"PARKED AND FAILED TO SET BRAKES": {
		text: "parked thier car, but failed to set their brakes."
	},
	"PARKED IN TRAFFIC LANE": {
		text: "parked their car in a traffic lane."
	},
	"PARKED WITHOUT LIGHTS": {
		text: "parked without lights."
	},
	"PASSED IN NO PASSING LANE": {
		text: "passed in a 'No Passing' lane."
	},
	"PASSED ON RIGHT SHOULDER": {
		text: "passed on the right shoulder."
	},
	"PEDESTRIAN FAILED TO YIELD RIGHT OF WAY TO VEHICLE": {
		text: ""
	},
	"UNSAFE SPEED": {
		text: "was traveling at an un-safe speed."
	},
	"SPEEDING - (OVERLIMIT)": {
		text: "was speeding."
	},
	"TAKING MEDICATION (EXPLAIN IN NARRATIVE)": {
		text: ""
	},
	"TURNED IMPROPERLY - CUT CORNER ON LEFT": {
		text: "turned improperly and cut the corner on the the left."
	},
	"TURNED IMPROPERLY - WIDE RIGHT": {
		text: "turned wide right improperly."
	},
	"TURNED IMPROPERLY - WRONG LANE": {
		text: "turned improperly (wrong lane)."
	},
	"TURNED WHEN UNSAFE": {
		text: "turned when it was not safe to do so."
	},
	"UNDER INFLUENCE - ALCOHOL": {
		text: "was under the influence of alchohol."
	},
	"UNDER INFLUENCE - DRUG": {
		text: "was under the influecne of drugs."
	},
	"WRONG SIDE - APPROACH OR INTERSECTION": {
		text: "approached the intersection on the wrong side."
	},
	"WRONG SIDE - NOT PASSING": {
		text: ""
	},
	"WRONG WAY - ONE WAY ROAD": {
		text: "went the wrong way on a one way road."
	},
	"CELL/MOBILE": {
		text: "was using their cell phone."
	},
	"ROAD RAGE": {
		text: "had road rage."
	},
	"CELL/MOBILE DEVICE USE - TALKING": {
		text: "was talking on their cell phone."
	},
	"CELL/MOBILE DEVICE USE - TEXTING": {
		text: "was texting on their cell phone."
	},
	"CELL/MOBILE DEVICE USE - OTHER": {
		text: "was using their cell phone."
	},
	"CELL/MOBILE DEVICE USE - UNKNOWN": {
		text: "was using their cell phone."
	},
	"0": {
		text:
			"There were no injuries, to either drivers or passengers reported at the time of the incident."
	},
	"Anything other than 0": {
		text: "Injuries were reported at this accident."
	},
	DRY: {
		text: "Road conditions were reported as dry."
	},
	WET: {
		text: "The road was reported as wet."
	},
	"STANDING WATER": {
		text: "The road was reported to have had standing water."
	},
	SLUSH: {
		text: "There was melted snow and slush on the roads."
	},
	ICE: {
		text: "The roads were reported to have had ice on them."
	},
	"SAND, MUD, DIRT": {
		text: "There was sand, mud and dirt on the roads."
	}
};
