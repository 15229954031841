import React, { useEffect, useState } from "react";
import Checkbox from "./Shared/Checkbox";
import Autocomplete from "react-google-autocomplete";

export default function Rss() {
  const [type, setType] = useState("state");
  const [url, setUrl] = useState(
    "https://app.myaccident.org/api/rss/get?state=TX"
  );
  const [state, setState] = useState("TX");
  const [lat, setLat] = useState(32.776665);
  const [lng, setLng] = useState(-96.796989);
  const [copy, setCopy] = useState(false);
  const [commercial, setCommercial] = useState(false);

  useEffect(() => {
    let u = "https://app.myaccident.org/api/rss/get?";

    if (type === "state") {
      u = u + "state=" + state;
    }

    if (type === "city") {
      u = u + "lat=" + lat + "&lng=" + lng;
    }

    if (commercial) {
      u = u + "&commercial=1";
    }

    setUrl(u);
  }, [state, type, lat, lng, commercial]);

  const copyToClipboard = () => {
    setCopy(true);
    const el = document.querySelector("#url-value");

    let oldContentEditable = el.contentEditable,
      oldReadOnly = el.readOnly,
      range = document.createRange();
    range.selectNodeContents(el);

    let selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);
    el.setSelectionRange(0, 999999);

    el.contentEditable = oldContentEditable;
    el.readOnly = oldReadOnly;

    el.select();
    document.execCommand("copy");
    setTimeout(() => {
      setCopy(false);
    }, 1000);
  };

  return (
    <div id="rss-generator">
      <h2>RSS generator</h2>
      RSS url:
      <br />
      <input type="text" value={url} onChange={(e) => {}} id="url-value" />{" "}
      <button onClick={() => copyToClipboard()}>
        {copy ? "URL Copied!" : "Copy URL"}
      </button>
      <br />
      <br />
      <p>Options:</p>
      {type === "state" ? (
        <div>
          <select value={state} onChange={(e) => setState(e.target.value)}>
            <option value="TX">Texas</option>
            <option value="CA">California</option>
            <option value="KY">Kentucky</option>
            <option value="IL">Illinois</option>
          </select>
        </div>
      ) : (
        <div>
          <br />
          <Autocomplete
            componentRestrictions={{ country: "us" }}
            onPlaceSelected={(e) => {
              setLat(e.geometry.location.lat());
              setLng(e.geometry.location.lng());
            }}
            types={["(regions)"]}
            placeholder="Start typing, then select city from the dropdown list"
          />
        </div>
      )}
      <p className="type">
        <Checkbox
          text="State"
          checked={type === "state"}
          onClick={() => setType("state")}
        />
        <Checkbox
          text="City"
          checked={type === "city"}
          onClick={() => setType("city")}
        />
        <Checkbox
          text="Only commercial vehicles"
          checked={commercial}
          onClick={() => setCommercial(!commercial)}
        />
      </p>
    </div>
  );
}
