/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
// import { Chart } from "frappe-charts/dist/frappe-charts.min.esm";
import { MdClose } from "react-icons/md";
import Expander from "./Expander";
import { withRouter } from "react-router-dom";
import NewCaseModal from "../components/NewCaseModal";

import * as CanvasJSReact from "../canvasjs.react";
const CanvasJS = CanvasJSReact.CanvasJS;
const CanvasJSChart = CanvasJSReact.CanvasJSChart;

const SearchStats = ({ user, history }) => {
	// console.log(user.address, user.city, user.state);

	const end = new Date(new Date().setHours(23, 59, 59));
	const last14 = moment().subtract(14, "d").toDate();

	const icon = {
		url: "https://myaccident.org/images/location-pin-02.webp",
		scaledSize: new window.google.maps.Size(50, 50),
		origin: new window.google.maps.Point(0, 0),
		anchor: new window.google.maps.Point(25, 25)
	};
	CanvasJS.addColorSet("customColorSet", ["#2196f3"]);
	const defaultGraphOptions = {
		animationEnabled: true,
		colorSet: "customColorSet",
		title: {
			fontFamily: "Montserrat",
			fontSize: 18,
			fontWeight: "bold",
			fontColor: "#666666",
			horizontalAlign: "left",
			margin: 10,
			text: ""
		},
		axisX: {
			labelAngle: -70,
			gridDashType: "line",
			gridThickness: 2,
			gridColor: "#EEEEEE",
			lineColor: "#DDDDDD",
			labelFontColor: "#999999",
			tickColor: "#EEEEEE",
			tickThickness: 2
		},
		axisY: {
			gridDashType: "line",
			gridThickness: 2,
			gridColor: "#EEEEEE",
			lineColor: "#FFFFFF",
			tickColor: "#FFFFFF",
			labelFontColor: "#999999"
		}
	};

	const [count, setCount] = useState(0);
	const [selected, setSelected] = useState(undefined);
	const [addCaseModal, setAddCaseModal] = useState(false);
	const [accidentsCount, setAccidentsCount] = useState({});
	const [injuriesCount, setInjuriesCount] = useState({});
	const [fatalitiesCount, setFatalitiesCount] = useState({});
	const [graphsMode, setGraphsMode] = useState("last14");
	const [loading, setLoading] = useState(false);
	// const [start, setStart] = useState(moment().subtract(14, "d").toDate());

	const addToDash = () => {
		setAddCaseModal(true);
	};
	const caseAdded = () => {
		setAddCaseModal(false);
		setSelected(undefined);
	};

	useEffect(() => {
		let map;
		const markers = [];
		const initMap = () => {
			map = new window.google.maps.Map(document.getElementById("map-dash"), {
				center: {
					lat: 31.9686,
					lng: -99.9018
				},
				zoom: 6,
				options: {
					streetViewControl: false,
					mapTypeControl: false,
					fullscreenControl: false,
					draggableCursor: "default"
				},
				gestureHandling: "greedy"
			});

			axios
				.get(
					`/api/accident/find?address=${user.city},TX,USA&startDate=${last14}&endDate=${end}`
				)
				.then((d) => {
					if (d.data.accidents && d.data.accidents.length) {
						d.data.accidents.forEach((accident) => {
							// console.log(accident);
							if (accident.location_lat !== 0 || accident.location_lng !== 0) {
								markers.push(
									new window.google.maps.Marker({
										position: {
											lat: accident.location_lat,
											lng: accident.location_lng
										},
										map: map,
										icon,
										metadata: accident
									})
								);
							}
						});
						if (markers.length > 1) {
							let bounds = new window.google.maps.LatLngBounds();
							for (let i = 0; i < markers.length; i++) {
								bounds.extend(markers[i].getPosition());
							}
							map.fitBounds(bounds);
						} else {
							if (markers.length === 1) {
								map.setCenter({
									lat: d.data.accidents[0].location_lat,
									lng: d.data.accidents[0].location_lng
								});
							}
						}
						markers.forEach((m, k) => {
							if (m) {
								m.addListener("click", () => {
									setSelected(m.metadata);
								});
							}
						});
						setCount(markers.length);
					}
				});

			const switchToStats = () => {
				if (markers.length > 1) {
					let bounds = new window.google.maps.LatLngBounds();
					for (let i = 0; i < markers.length; i++) {
						bounds.extend(markers[i].getPosition());
					}
					setTimeout(() => {
						map.fitBounds(bounds);
					}, 100);
				} else {
					if (markers.length === 1) {
						this.map.setCenter({
							lat: markers[0].location_lat,
							lng: markers[0].location_lng
						});
					}
				}
			};

			window.addEventListener("switch-to-stats", () => switchToStats());
		};
		if (!map) initMap();
		loadGraphs("last14");
	}, []);

	const loadGraphs = (view) => {
		const byDay = {};
		let ss;
		switch (view) {
			case "last14":
				ss = moment().subtract(14, "d").toDate();
				break;

			case "1month":
				ss = moment().subtract(1, "month").toDate();
				break;

			case "6months":
				ss = moment().subtract(6, "month").toDate();
				break;

			case "year":
				ss = moment().subtract(1, "y").toDate();
				break;

			default:
				ss = moment().subtract(14, "d").toDate();
				break;
		}
		// setStart(ss);
		setGraphsMode(view);
		if (!loading) {
			setLoading(true);
			// console.log(ss);
			axios
				.get(
					`/api/accident/find?address=${user.city},TX,USA&startDate=${ss}&endDate=${end}`
				)
				.then((d) => {
					setLoading(false);
					if (d.data.accidents && d.data.accidents.length) {
						d.data.accidents.forEach((accident) => {
							if (view === "last14" || view === "1month") {
								if (!byDay[moment(accident.date).format("MMMM Do")])
									byDay[moment(accident.date).format("MMMM Do")] = {
										count: 0,
										injuries: 0,
										fatalities: 0
									};
								byDay[moment(accident.date).format("MMMM Do")].count++;
								byDay[moment(accident.date).format("MMMM Do")].injuries =
									byDay[moment(accident.date).format("MMMM Do")].injuries +
									accident.possible_injury_count;
								byDay[moment(accident.date).format("MMMM Do")].fatalities =
									byDay[moment(accident.date).format("MMMM Do")].fatalities +
									accident.death_count;
							} else {
								if (!byDay[moment(accident.date).format("MMMM YYYY")])
									byDay[moment(accident.date).format("MMMM YYYY")] = {
										count: 0,
										injuries: 0,
										fatalities: 0
									};
								byDay[moment(accident.date).format("MMMM YYYY")].count++;
								byDay[moment(accident.date).format("MMMM YYYY")].injuries =
									byDay[moment(accident.date).format("MMMM YYYY")].injuries +
									accident.possible_injury_count;
								byDay[moment(accident.date).format("MMMM YYYY")].fatalities =
									byDay[moment(accident.date).format("MMMM YYYY")].fatalities +
									accident.death_count;
							}
						});
						// console.log(byDay);

						const accidentsGraph = { ...defaultGraphOptions };
						accidentsGraph.data = [
							{
								type: "line",
								dataPoints: Object.keys(byDay)
									.reverse()
									.map((v) => {
										return { label: v, y: byDay[v].count };
									})
							}
						];
						setAccidentsCount(accidentsGraph);
						const injuriesGraph = { ...defaultGraphOptions };
						injuriesGraph.data = [
							{
								type: "line",
								dataPoints: Object.keys(byDay)
									.reverse()
									.map((v) => {
										return { label: v, y: byDay[v].injuries };
									})
							}
						];
						setInjuriesCount(injuriesGraph);
						const fatalitiesGraph = { ...defaultGraphOptions };
						fatalitiesGraph.data = [
							{
								type: "line",
								dataPoints: Object.keys(byDay)
									.reverse()
									.map((v) => {
										return { label: v, y: byDay[v].fatalities };
									})
							}
						];
						setFatalitiesCount(fatalitiesGraph);
					}
				});
		}
	};

	return (
		<div>
			<div className={selected ? "modal show" : "modal"}>
				<button className="close" onClick={() => setSelected(undefined)}>
					<MdClose />
				</button>
				<h2>Accident</h2>
				<Expander data={{ accident: selected ? selected : {} }} />
				<div className="footer">
					<button onClick={() => setSelected(undefined)} className="outline">
						Cancel
					</button>
					<button onClick={() => addToDash()}>Add to dashboard</button>
				</div>
			</div>
			<NewCaseModal
				show={addCaseModal}
				close={() => caseAdded()}
				accidentID={selected ? selected.custom_id : null}
				user={user}
			/>
			Last 14 days accidents in {user.city}, TX, USA ({count} found)
			<div id="map-dash" />
			{loading ? (
				<div>
					<img src="/img/ajax-loader.gif" alt="loading" /> Loading graph data...
				</div>
			) : (
				<div>
					<div className="button-group">
						<a
							onClick={() => loadGraphs("last14")}
							className={graphsMode === "last14" ? "active" : ""}
						>
							Last 14 days
						</a>
						<a
							onClick={() => loadGraphs("1month")}
							className={graphsMode === "1month" ? "active" : ""}
						>
							Last month
						</a>
						<a
							onClick={() => loadGraphs("6months")}
							className={graphsMode === "6months" ? "active" : ""}
						>
							Last 6 months
						</a>
						<a
							onClick={() => loadGraphs("year")}
							className={graphsMode === "year" ? "active" : ""}
						>
							Last year
						</a>
					</div>
					{count ? (
						<div>
							<h2>ACCIDENTS COUNT</h2>
							<CanvasJSChart options={accidentsCount} />
						</div>
					) : null}
					{/*count ? <div id="accidents-graph" /> : null */}
					{count ? (
						<div className="row" style={{ marginTop: 40 }}>
							<div className="column">
								{/* <div id="injuries-graph" /> */}
								<h2>INJURIES COUNT</h2>
								<CanvasJSChart options={injuriesCount} />
							</div>
							<div className="column">
								{/* <div id="fatalities-graph" /> */}
								<h2>FATALITIES COUNT</h2>
								<CanvasJSChart options={fatalitiesCount} />
							</div>
						</div>
					) : null}
				</div>
			)}
		</div>
	);
};

export default withRouter(SearchStats);
