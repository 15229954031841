import React, { Component, Fragment } from "react";
import moment from "moment";
import axios from "axios";
import swal from "sweetalert2";
import Select from "react-select";
import DatePicker from "react-datepicker";
import Autocomplete from "react-google-autocomplete";
import { withRouter } from "react-router";

let yearRange = [];
for (let i = 2019; i >= 1900; i--) {
  yearRange.push({ value: i, label: i });
}

class AlertPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 1,
      zip: [31.169621, -99.683617],
      address: "",
      end: moment().toDate(),
      start: moment().subtract(14, "days").toDate(),
      vehicleMakerList: [],
      vehicle1Maker: "",
      vehicle1Year: "",
      vehicle2Maker: "",
      vehicle2Year: "",
      vehicle2Enabled: false,
      caseId: "",
      firstName: "",
      lastName: "",
      requestPR: false,
      showMap: false,
      state: "TX",
    };
    this.circle = undefined;
    this.marker = undefined;
    this.mapOnConfirmArea = undefined;
    this.mapInitilized = false;
    this.markerInitialized = false;
  }

  // static getDerivedStateFromProps(nextProps, prevState) {
  //   if (
  //     nextProps.zip.split(',')[0] !== prevState.zip[0] &&
  //     nextProps.zip.split(',')[1] !== prevState.zip[1] &&
  //     prevState.zip.length === 0
  //   ) {
  //     return {
  //       zip: [nextProps.zip.split(',')[0], nextProps.zip.split(',')[1]]
  //     };
  //   }
  //   return null;
  // }

  componentDidMount() {
    axios.get("/api/vehicle-maker-list").then((res) =>
      this.setState((state) => {
        res.data.vehicleMakerList.map((vehicleMaker) => {
          const vehicles = state.vehicleMakerList;
          return {
            vehicleMakerList: vehicles.push({
              label: vehicleMaker,
              value: vehicleMaker,
            }),
          };
        });
      })
    );
    if (this.state.address === "") {
      // axios.get(`/api/place?address=31.169621,-99.683617`).then((res) => {
      //   this.setAddress(res.data.address);
      // });
    }
  }

  componentDidUpdate() {
    if (
      (this.state.step === 1 || this.state.step === 4) &&
      !this.mapInitilized &&
      this.props.radius !== undefined
    ) {
      this.initMap();
    }
  }

  onPlaceSelected = async (place) => {
    if (place.formatted_address) {
      // if (
      //   place.formatted_address.indexOf("TX") === -1 &&
      //   place.formatted_address.indexOf("Texas") === -1
      // ) {
      //   swal.fire(
      //     "Error",
      //     "My Accident ONLY has data for the state of Texas. Keep checking for new states as we launch them",
      //     "error"
      //   );
      // } else {

      const { name, vicinity, geometry, address_components } = place;

      address_components.forEach((a) => {
        if (
          a.types.includes("administrative_area_level_1") &&
          a.types.includes("political")
        ) {
          this.setState({ state: a.short_name });
        }
      });

      if (name !== vicinity) {
        this.setAddress(`${name}, ${vicinity || ""}, Texas`);
      } else {
        this.setAddress(place.formatted_address);
      }

      this.setZip([geometry.location.lat(), geometry.location.lng()]);

      if (this.marker) {
        this.marker.setMap(null);
        this.circle.setMap(null);
      }

      let marker = new window.google.maps.Marker({
        position: place.geometry.location,
        map: this.mapOnConfirmArea,
        draggable: true,
      });

      let circle = new window.google.maps.Circle({
        map: this.mapOnConfirmArea,
        radius: parseInt(this.props.radius),
        fillColor: "#AA0000",
      });
      circle.bindTo("center", marker, "position");

      this.marker = marker;
      this.circle = circle;

      await this.setState({
        zip: [marker.position.lat(), marker.position.lng()],
      });
      const geocoder = new window.google.maps.Geocoder();
      this.geocodeLatLng(geocoder, [
        this.marker.getPosition().lat(),
        this.marker.getPosition().lng(),
      ]);

      this.mapOnConfirmArea.setCenter(marker.position);

      const geocodeLatLng = (geocoder, coordinates) => {
        const latlng = {
          lat: parseFloat(coordinates[0]),
          lng: parseFloat(coordinates[1]),
        };
        geocoder.geocode({ location: latlng }, (results, status) => {
          if (status === "OK") {
            if (results[0]) {
              this.setAddress(results[0].formatted_address);
              this.setZip([
                results[0].geometry.location.lat(),
                results[0].geometry.location.lng(),
              ]);
            } else {
              window.alert("No results found");
            }
          } else {
            window.alert("Geocoder failed due to: " + status);
          }
        });
      };

      window.google.maps.event.addListener(marker, "dragend", function (event) {
        geocodeLatLng(geocoder, [event.latLng.lat(), event.latLng.lng()]);
      });
      // }
    }
  };

  initMap = () => {
    this.mapOnConfirmArea = new window.google.maps.Map(
      document.getElementById("map-canvas-alert"),
      {
        center: {
          lat: parseFloat(this.state.zip[0]),
          lng: parseFloat(this.state.zip[1]),
        },
        zoom: this.props.zoom || 9,
        options: {
          streetViewControl: false,
          mapTypeControl: false,
          fullscreenControl: false,
          draggableCursor: "default",
        },
        gestureHandling: "greedy",
      }
    );
    const lat = this.state.zip[0];
    const lng = this.state.zip[1];

    if (!this.markerInitialized) {
      const marker = new window.google.maps.Marker({
        position: new window.google.maps.LatLng(lat, lng),
        map: this.mapOnConfirmArea,
        draggable: true,
      });

      marker.setMap(this.mapOnConfirmArea);
      const circle = new window.google.maps.Circle({
        map: this.mapOnConfirmArea,
        radius: parseInt(this.props.radius),
        fillColor: "#AA0000",
      });

      circle.bindTo("center", marker, "position");
      this.mapOnConfirmArea.setCenter(marker.getPosition());
      this.marker = marker;
      this.circle = circle;
      this.mapInitilized = true;
    }

    const placeMarker = async (location) => {
      if (this.marker) {
        this.marker.setMap(null);
        this.circle.setMap(null);
      }

      let marker = new window.google.maps.Marker({
        position: location,
        map: this.mapOnConfirmArea,
        draggable: true,
      });

      let circle = new window.google.maps.Circle({
        map: this.mapOnConfirmArea,
        radius: parseInt(this.props.radius),
        fillColor: "#AA0000",
      });
      circle.bindTo("center", marker, "position");

      this.marker = marker;
      this.circle = circle;

      this.mapOnConfirmArea.setCenter(marker.position);

      await this.setState({
        zip: [marker.position.lat(), marker.position.lng()],
      });
      const geocoder = new window.google.maps.Geocoder();
      this.geocodeLatLng(geocoder, [
        this.marker.getPosition().lat(),
        this.marker.getPosition().lng(),
      ]);

      window.google.maps.event.addListener(marker, "dragend", function (event) {
        setZip([event.latLng.lat(), event.latLng.lng()]);
      });
    };
    const setZip = (val) => {
      this.setState({ zip: val });
      this.mapOnConfirmArea.setCenter(this.marker.getPosition());

      const geocoder = new window.google.maps.Geocoder();
      const latlng = {
        lat: parseFloat(val[0]),
        lng: parseFloat(val[1]),
      };
      geocoder.geocode({ location: latlng }, (results, status) => {
        if (status === "OK") {
          if (results[0]) {
            this.setState({ address: results[0].formatted_address });
          } else {
            window.alert("No results found");
          }
        } else {
          window.alert("Geocoder failed due to: " + status);
        }
      });
    };
    window.google.maps.event.addListener(
      this.mapOnConfirmArea,
      "click",
      function (event) {
        placeMarker(event.latLng);
      }
    );
    window.google.maps.event.addListener(
      this.marker,
      "dragend",
      function (event) {
        setZip([event.latLng.lat(), event.latLng.lng()]);
      }
    );
  };

  setStep = (val) => {
    if (this.state.step < 4) {
      this.setState({ step: this.state.step + val }, () => {
        if (this.state.step === 2) {
          this.mapInitilized = false;
        }
        if (this.state.step === 4) {
          this.mapInitilized = false;
        }
      });
    }
  };

  setAddress = (val) => {
    this.setState({ address: val });
  };

  setZip = (val) => {
    this.setState({ zip: val });
    const geocoder = new window.google.maps.Geocoder();
    this.geocodeLatLng(geocoder, val);
  };

  geocodeLatLng = (geocoder, coordinates) => {
    const latlng = {
      lat: parseFloat(coordinates[0]),
      lng: parseFloat(coordinates[1]),
    };
    geocoder.geocode({ location: latlng }, (results, status) => {
      // console.log(results);
      if (status === "OK") {
        if (results[0]) {
          this.setAddress(results[0].formatted_address);
          results[0].address_components.forEach((a) => {
            if (
              a.types.includes("administrative_area_level_1") &&
              a.types.includes("political")
            ) {
              this.setState({ state: a.short_name });
            }
          });
        } else {
          window.alert("No results found");
        }
      } else {
        window.alert("Geocoder failed due to: " + status);
      }
    });
  };

  setVehicle1Year = (val) => {
    this.setState({ vehicle1Year: val });
  };

  setVehicle2Year = (val) => {
    this.setState({ vehicle2Year: val });
  };

  setVehicle1Maker = (val) => {
    this.setState({ vehicle1Maker: val });
  };

  setVehicle2Maker = (val) => {
    this.setState({ vehicle2Maker: val });
  };

  setVehicle2Enabled = (val) => {
    this.setState({ vehicle2Enabled: val });
  };

  setStartDate = (val) => {
    this.setState({ start: val });
  };
  setEndDate = (val) => {
    this.setState({ end: val });
  };

  storeData = async () => {
    axios
      .post("/api/businessAccount/cases/alert", {
        address: this.state.address,
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        business_case_id: this.state.caseId,
        startDate: this.state.start,
        endDate: this.state.end,
        vehicle1Maker: this.state.vehicle1Maker.value,
        vehicle1Year: this.state.vehicle1Year.value,
        vehicle2Maker: this.state.vehicle2Maker.value,
        vehicle2Year: this.state.vehicle2Year
          ? this.state.vehicle2Year.value
          : null,
        radius: parseInt(this.props.radius),
        requestPR: this.state.requestPR,
        alertOnMobile: false,
        alertOnEmail: false,
        state: this.state.state,
      })
      .then((d) => {
        // console.log(d.data);

        swal
          .fire({
            title: "Alert added!",
            text: "Alert is added to your account. You can find it in your dashboard.",
            type: "success",
            showCancelButton: true,
            cancelButtonText: "OK",
            confirmButtonText: "Go to dashboard",
            reverseButtons: true,
          })
          .then((v) => {
            this.setState({
              step: 0,
              start: moment().toDate(),
              end: moment().subtract(14, "days").toDate(),
              vehicle1Maker: "",
              vehicle1Year: "",
              vehicle2Maker: "",
              vehicle2Year: "",
              vehicle2Enabled: false,
              caseId: "",
              firstName: "",
              lastName: "",
              requestPR: false,
              state: "",
            });

            if (v.value) this.props.history.push("/");
          });
      })
      .catch((e) => {
        console.log(e);
        swal.fire("Error adding case!", e.response.data.error, "error");
      });
  };

  getMiles = (i) => {
    return Math.ceil(i * 0.000621371192);
  };

  render() {
    const {
      step,
      address,
      start,
      end,
      vehicle1Maker,
      vehicle1Year,
      vehicle2Maker,
      vehicle2Year,
      vehicle2Enabled,
      vehicleMakerList,
      caseId,
      firstName,
      lastName,
    } = this.state;
    const ContinueButton = () => {
      return (
        <button
          className="button"
          style={{
            opacity:
              (step === 1 && address === "") ||
              (step === 2 && vehicle1Maker === "") ||
              (step === 2 && vehicle1Year === "") ||
              (step === 2 && vehicle2Enabled && vehicle2Maker === "") ||
              (step === 2 && vehicle2Enabled && vehicle2Year === "")
                ? 0.5
                : 1,
          }}
          disabled={
            (step === 1 && address === "") ||
            (step === 2 && vehicle1Maker === "") ||
            (step === 2 && vehicle1Year === "") ||
            (step === 2 && vehicle2Enabled && vehicle2Maker === "") ||
            (step === 2 && vehicle2Enabled && vehicle2Year === "")
          }
          onClick={() => {
            if (step === 1) {
              this.mapInitilized = false;
            }
            if (step === 4) {
              this.storeData();
            }

            if (
              !(
                (step === 1 && address === "") ||
                (step === 2 && vehicle1Maker === "") ||
                (step === 2 && vehicle2Enabled && vehicle2Maker === "")
              )
            ) {
              this.setStep(1);
            }
          }}
        >
          {step === 0
            ? "Notify Me"
            : step > 0
            ? step === 4
              ? "Add alert to dashboard"
              : "Continue"
            : null}
        </button>
      );
    };

    return (
      <div id="admin-page">
        <div className="setup-alert">
          <div className="text">
            {step === 0 ? (
              <>
                <h2>Set Up Report Alert</h2>
                <p>
                  Texas Transportation Code (TTC) §550.062 requires a law
                  enforcement officer who investigates a crash and completes a
                  Texas Peace Officer&amp;s Report (CR3) to submit the report to
                  the Texas Department of Transportation (TxDOT) not later than
                  the 10th day after the date of the crash. If you can not find
                  your report, it maybe that it has not yet been filed by the
                  officer who worked on your case.
                </p>
                <p>
                  Set up your MyAccident Alert and we will notify you when your
                  report is filed.
                </p>
              </>
            ) : null}
            {step === 1 ? (
              <>
                <h2>Confirm the area</h2>
                <p>Select the area where your accident took place.</p>
                <p>
                  (Note the wider the area, the more notifications you may
                  receive)
                </p>
                {this.props.radius && (
                  <p>
                    Radius around this address we will look for accidents is:{" "}
                    {this.getMiles(this.props.radius)} Miles
                  </p>
                )}
                <Autocomplete
                  id="autocomplete"
                  componentRestrictions={{ country: "us" }}
                  onChange={(e) => {
                    this.setAddress(e.target.value);
                  }}
                  placeholder="Start typing and pick place from the list"
                  onPlaceSelected={(place) => this.onPlaceSelected(place)}
                  types={["(regions)"]}
                  value={address}
                  inputAutocompleteValue={"off"}
                />
              </>
            ) : null}
            {step === 2 ? (
              <>
                <h2>Narrow your accident details:</h2>
                <div className="row">
                  <div className="column">
                    <label>Involved vehicle (required):</label>
                    <Select
                      classNamePrefix="vehicle-list-select"
                      options={vehicleMakerList}
                      onChange={(value) => this.setVehicle1Maker(value)}
                      placeholder="Vehicle maker"
                      value={vehicle1Maker}
                    />
                  </div>
                  <div className="column">
                    <label>Year (estimate) (required):</label>
                    <Select
                      classNamePrefix="vehicle-list-select"
                      options={yearRange}
                      onChange={(value) => this.setVehicle1Year(value)}
                      placeholder="Vehicle Manuf. Year"
                      value={vehicle1Year}
                    />
                  </div>
                </div>
                <br />
                {!vehicle2Enabled ? (
                  <button onClick={() => this.setVehicle2Enabled(true)}>
                    Add another vehicle
                  </button>
                ) : (
                  <>
                    <div className="row">
                      <div className="column">
                        <label>Involved vehicle (required):</label>
                        <Select
                          classNamePrefix="vehicle-list-select"
                          options={vehicleMakerList}
                          onChange={(value) => this.setVehicle2Maker(value)}
                          placeholder="Vehicle maker"
                          value={vehicle2Maker}
                        />
                      </div>
                      <div className="column">
                        <label>Year (estimate) (required):</label>
                        <Select
                          classNamePrefix="vehicle-list-select"
                          options={yearRange}
                          onChange={(value) => this.setVehicle2Year(value)}
                          placeholder="Vehicle Manuf. Year"
                          value={vehicle2Year}
                        />
                      </div>
                    </div>
                    <br />
                    <button
                      onClick={() => {
                        this.setVehicle2Enabled(false);
                        this.setVehicle2Maker("");
                        this.setVehicle2Year("");
                      }}
                    >
                      Remove vehicle
                    </button>
                  </>
                )}
                <br />
                <br />
                <div>
                  <h2>The Date Accident Occurred:</h2>
                  <div className="row">
                    <div className="column">
                      <label>From:</label>
                      <DatePicker
                        className="datepicker"
                        onChange={(date) =>
                          this.setStartDate(new Date(date.setHours(23, 59, 59)))
                        }
                        popperPlacement={"top"}
                        selected={new Date(start)}
                      />
                    </div>
                    <div className="column">
                      <label>To:</label>
                      <DatePicker
                        maxDate={new Date()}
                        minDate={this.state.start}
                        className="datepicker"
                        onChange={(date) =>
                          this.setEndDate(new Date(date.setHours(0, 0, 0)))
                        }
                        popperPlacement={"top"}
                        selected={new Date(end)}
                      />
                    </div>
                    <div className="column" />
                    <div className="column" />
                    <div className="column" />
                  </div>
                </div>
              </>
            ) : null}
            {step === 3 ? (
              <Fragment>
                <h2>Additional Data (optional)</h2>
                <fieldset>
                  <label>Case #:</label>
                  <input
                    type="text"
                    name="caseId"
                    value={caseId}
                    onChange={(e) => this.setState({ caseId: e.target.value })}
                  />
                  <div className="row">
                    <div className="column">
                      <label>Client First Name</label>
                      <input
                        type="text"
                        name="firstName"
                        value={firstName}
                        onChange={(e) =>
                          this.setState({ firstName: e.target.value })
                        }
                      />
                    </div>
                    <div className="column">
                      <label>Client Last Name</label>
                      <input
                        type="text"
                        name="lastName"
                        value={lastName}
                        onChange={(e) =>
                          this.setState({ lastName: e.target.value })
                        }
                      />
                    </div>
                  </div>

                  <input
                    type="checkbox"
                    id="cbx"
                    style={{ display: "none" }}
                    readOnly
                    checked={this.state.requestPR}
                    className="inp-cbx"
                  />
                  <label
                    className="cbx"
                    htmlFor="cbx"
                    onClick={() =>
                      this.setState({ requestPR: !this.state.requestPR })
                    }
                  >
                    <span>
                      <svg width="12px" height="10px" viewBox="0 0 12 10">
                        <polyline points="1.5 6 4.5 9 10.5 1" />
                      </svg>
                    </span>
                    <span>Purchase Complete Pro Report ($18.95)</span>
                  </label>
                </fieldset>
              </Fragment>
            ) : null}
            {step === 4 ? (
              <Fragment>
                <p>WHEN AN ACCIDENT REPORT IS FILED INVOLVING:</p>
                <ul className="setup-alert__confirm-list">
                  <li>Vehicle: {vehicle1Maker.value}</li>
                  {vehicle2Maker.value && vehicle2Maker.value.length ? (
                    <li>Vehicle 2: {vehicle2Maker.value}</li>
                  ) : null}
                  {vehicle1Year.value && vehicle1Year.value > 0 ? (
                    <li>
                      Vehicle manufacturing year: Between{" "}
                      {vehicle1Year.value - 1} and {vehicle1Year.value + 1}
                    </li>
                  ) : null}
                  {vehicle2Maker.value &&
                  vehicle2Maker.value.length &&
                  vehicle2Year.value &&
                  vehicle2Year.value > 0 ? (
                    <li>
                      Vehicle 2 manufacturing year: Between{" "}
                      {vehicle2Year.value - 1} and {vehicle2Year.value + 1}
                    </li>
                  ) : null}
                  {start ? (
                    <li>
                      Between: {start.toISOString().substring(0, 10)} and{" "}
                      {end.toISOString().substring(0, 10)}
                    </li>
                  ) : null}
                  <li>In the Area of: {address}</li>
                  {this.props.radius && (
                    <li>
                      Radius around this address we will look for accidents is:{" "}
                      {this.getMiles(this.props.radius)} Miles
                    </li>
                  )}
                  <li>
                    With name involved: {firstName} {lastName}
                  </li>
                </ul>
              </Fragment>
            ) : null}
          </div>
          {step === 1 ? (
            <button
              onClick={() => this.setState({ showMap: !this.state.showMap })}
            >
              {this.state.showMap ? "Hide" : "Show"} Map
            </button>
          ) : null}
          {(step === 1 || step === 4) && this.props.radius !== undefined ? (
            <div style={{ height: "100%", marginTop: "10px" }}>
              <div
                style={{
                  height: this.state.showMap ? "250px" : "0",
                  borderRadius: "10px",
                  display: "block",
                }}
                id={"map-canvas-alert"}
              />
            </div>
          ) : null}
        </div>
        <div className="footer">
          <button
            className="outline"
            style={{ visibility: step > 0 ? "visible" : "hidden" }}
            onClick={() => {
              step > 1
                ? this.setStep(-1)
                : this.props.history.push("/pre-alert");
            }}
          >
            Back
          </button>
          <ContinueButton />
        </div>
      </div>
    );
  }
}
export default withRouter(AlertPage);
