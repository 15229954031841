import React, { useEffect, useState } from "react";
import Axios from "axios";
import moment from "moment";
import clsx from "clsx";
import swal from "sweetalert2";
import { MdClose } from "react-icons/md";
import JoditEditor from "jodit-react";

export default function EmailEditor({ alert, close, onSuccess, matches }) {
  const [emailText, setEmailText] = useState("");
  const [smsText, setSmsText] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [ajax, setAjax] = useState(true);
  const [emailData, setEmailData] = useState({});
  const [agency, setAgency] = useState({});
  const [subject, setSubject] = useState("");

  const send = async () => {
    setAjax(true);

    if (smsText) {
      await Axios.post(`/api/alert/notify/sms`, {
        alert,
        smsText,
      });
    }

    Axios.post(`/api/alert/notify/email`, {
      alert,
      input: btoa(unescape(encodeURIComponent(emailText))),
      subject,
    })
      .then((response) => {
        setAjax(false);
        if (response.data.success) {
          onSuccess(smsText ? true : false);
          close();
        }
        swal.fire({
          type: response.data.success ? "success" : "error",
          title: response.data.success ? "Success" : "Error",
          text: response.data.message,
          confirmButtonText: "Ok!",
        });
      })
      .catch(() => {
        swal.fire({
          type: "error",
          title: "Unable to send the report",
          confirmButtonText: "Ok!",
        });
      });
  };

  useEffect(() => {
    setAjax(true);
    (async () => {
      if (alert) {
        let emailDataResponse;

        try {
          emailDataResponse = await Axios.get(
            `/api/email-data?state=${alert.state}&city=${alert.city}`
          );
        } catch (error) {
          emailDataResponse = error;
        }

        if (emailDataResponse.data) {
          setEmailData(emailDataResponse.data.emailData);
          setAgency(emailDataResponse.data.agency);
        } else {
          setEmailData({});
          setAgency({});
        }

        if (alert.user && alert.user.email) {
          setEmail(alert.user.email);
          setPhone(alert.user.phone);
        } else {
          setEmail(alert.email);
          setPhone(alert.phone);
        }

        setAjax(false);
      } else {
        setEmailText("");
      }
    })();
  }, [alert]);

  const prepareText = (w) => {
    let firstName;
    if (alert.user && alert.user.firstName) {
      firstName = alert.user.firstName;
    } else {
      firstName = alert.firstName;
    }
    firstName = firstName[0].toUpperCase() + firstName.slice(1).toLowerCase();
    if (w === 1) {
      setSubject("Unable to find your free accident report - MyAccident.org");
      setEmailText(
        `<b>Search Details:<br>Accident Date: ${moment(alert.startDate).format(
          "llll"
        )} to ${moment(alert.endDate).format("llll")}<br>Accident Location: ${
          alert.city
        }, ${alert.state}${
          alert.vehicle1Maker
            ? `<br>Car Make/Year: ${alert.vehicle1Maker} ${alert.vehicle1Year}`
            : ""
        }${alert.vin ? `<br>VIN: ${alert.vin}` : ""}${
          alert.case_id ? `<br>CASEID: ${alert.case_id}` : ""
        }</b><br><br>
          Dear ${firstName},<br><br>
          Unfortunately, we do not yet have access to accident reports in the state of ${
            emailData && emailData.state ? emailData.state : "STATE_UNKNOWN"
          }.
          We’re sorry that we cannot offer you a free copy of your accident report at this time, however we have found the following information which will hopefully be useful.<br><br>
          Accident Report Information For ${
            agency && agency.name ? agency.name : "AGENCY_UNKNOWN"
          }:<br>
          ${
            agency && agency.address ? agency.address : "AGENCY_ADDRESS_UNKNOWN"
          }, ${
          agency && agency.non_emergency ? agency.non_emergency : ""
        }<br><br>
        ${
          emailData && emailData.state_database
            ? `There is also a statewide database for accident reports in ${
                emailData && emailData.state ? emailData.state : "STATE_UNKOWN"
              } on the following web page: ${emailData.state_database}<br><br>`
            : ""
        }
          More Useful Accident Tips:<br>
          <a href="https://myaccident.org/how-the-police-accident-report-will-impact-your-insurance-claim">How your accident will impact your insurance premium.</a><br>
          <a href="https://myaccident.org/protect-your-rights">Protect your rights</a><br>
          <a href="https://myaccident.org/request-free-legal-consultation">Request A Free Legal Consultation</a><br><br>
          <p style="font-size:12px"><a href="https://myaccident.org/">MyAccident.org</a> is not a law firm, nor a lawyer referral service nor is it a substitute for hiring an attorney or law firm and does not provide legal advice. <a href="https://myaccident.org/">MyAccident.org</a> does not endorse or recommend any participating Third-Party Legal Professionals that pay to participate in this advertising. An attorney-client relationship is not formed when calling the number on this site or filling out a form. Services are not available in all states or for all legal categories. All persons depicted in a photo or video are actors or models and not clients of any firm.</p>`
      );
      setSmsText("");
    }
    if (w === 2) {
      setSubject("Unable to find your free accident report - MyAccident.org");
      setEmailText(
        `<b>Search Details:<br>Accident Date: ${moment(alert.startDate).format(
          "llll"
        )} to ${moment(alert.endDate).format("llll")}<br>Accident Location: ${
          alert.city
        }, ${alert.state}${
          alert.vehicle1Maker
            ? `<br>Car Make/Year: ${alert.vehicle1Maker} ${alert.vehicle1Year}`
            : ""
        }${alert.vin ? `<br>VIN: ${alert.vin}` : ""}${
          alert.case_id ? `<br>CASEID: ${alert.case_id}` : ""
        }</b><br><br>
          Dear ${firstName},<br><br>
          We cannot locate the accident report with those parameters in our database.
          We’re sorry that we cannot offer you a free copy of your accident report at this time. However, we have found the following information which will hopefully be useful.<br><br>
          For information on obtaining your accident report, you can contact the reporting police agency.<br><br>
          Contact information for the ${alert.city} police is below:<br>
          ${agency && agency.name ? agency.name : "AGENCY_UNKNOWN"}:
          ${
            agency && agency.address ? agency.address : "AGENCY_ADDRESS_UNKNOWN"
          }, ${
          agency && agency.non_emergency ? agency.non_emergency : ""
        }<br><br>
          ${
            emailData && emailData.state_database
              ? `There is also a statewide database for accident reports in ${
                  emailData && emailData.state
                    ? emailData.state
                    : "STATE_UNKOWN"
                } on the following web page: ${
                  emailData.state_database
                }<br><br>`
              : ""
          }
          You might also find the following information helpful:<br>
          <a href="https://myaccident.org/how-the-police-accident-report-will-impact-your-insurance-claim">How your accident will impact your insurance premium.</a><br>
          <a href="https://myaccident.org/protect-your-rights">Protect your rights</a><br>
          <a href="https://myaccident.org/request-free-legal-consultation">Request A Free Legal Consultation</a><br><br>
          <p style="font-size:12px"><a href="https://myaccident.org/">MyAccident.org</a> is not a law firm, nor a lawyer referral service nor is it a substitute for hiring an attorney or law firm and does not provide legal advice. <a href="https://myaccident.org/">MyAccident.org</a> does not endorse or recommend any participating Third-Party Legal Professionals that pay to participate in this advertising. An attorney-client relationship is not formed when calling the number on this site or filling out a form. Services are not available in all states or for all legal categories. All persons depicted in a photo or video are actors or models and not clients of any firm.</p>`
      );
      setSmsText("");
    }
    if (w === 3) {
      setSubject("Accident report located! - MyAccident.org");
      setEmailText(
        `<b>MyAccident Update!</b>
        <br />
        <br />
        <p>Hello ${firstName}</p>
        <br />
        <p>We have found a report that looks to be a match for your search alert. Please click on the link below and review the accident information here to see if it is the report for which you are seeking:</p>
        <br />
        <p>
        ${matches
          .filter((m) => m.selected)
          .map(
            (a) =>
              `<a href="https://app.myaccident.org/accident/${a.custom_id}">Accident link</a><br />`
          )
          .join("")}
        </p>
        <br />
        <p>There is an option to download the report at the top of the page. Please let us know if this is not the correct accident report, or if you have any other questions. Have a great day,</p>
        <br />
        <p>Ben<br />myaccident.org</p>
        `
      );
      setSmsText(
        `MyAccident Update!\nHi ${firstName}, we have found a report that looks to be a match for your alert.\nPlease click on the link and review the accident information here: ${matches
          .filter((m) => m.selected)
          .map((a) => `https://app.myaccident.org/accident/${a.custom_id}`)
          .join(
            ", \n"
          )}\n\nPlease let us know if this is not the correct accident report. Have a great day,\nBen`
      );
    }
    if (w === 4) {
      setSubject("Unable to find your free accident report - MyAccident.org");
      setEmailText(
        `<b>Search Details:<br>Accident Date: ${moment(alert.startDate).format(
          "llll"
        )} to ${moment(alert.endDate).format("llll")}<br>Accident Location: ${
          alert.city
        }, ${alert.state}${
          alert.vehicle1Maker
            ? `<br>Car Make/Year: ${alert.vehicle1Maker} ${alert.vehicle1Year}`
            : ""
        }${alert.vin ? `<br>VIN: ${alert.vin}` : ""}${
          alert.case_id ? `<br>CASEID: ${alert.case_id}` : ""
        }</b><br><br>
          Dear ${firstName},<br><br>
          Unfortunately, we do not yet have access to accident reports in the state of ${
            emailData && emailData.state ? emailData.state : "STATE_UNKNOWN"
          }.
          However we have found the following information which will hopefully be useful.
          <br><br>
          Accident Report Information For ${emailData.state}:
          <br><br>
          <a href="https://myaccident.org/${emailData.state.toLowerCase()}-accident-reports">https://myaccident.org/${emailData.state.toLowerCase()}-accident-reports</a>
          <br><br>
        ${
          emailData && emailData.state_database
            ? `There is also a statewide database for accident reports in ${
                emailData && emailData.state ? emailData.state : "STATE_UNKOWN"
              } on the following web page: ${emailData.state_database}<br><br>`
            : ""
        }
          More Useful Accident Tips:<br>
          <a href="https://myaccident.org/how-the-police-accident-report-will-impact-your-insurance-claim">How your accident will impact your insurance premium.</a><br>
          <a href="https://myaccident.org/protect-your-rights">Protect your rights</a><br>
          <a href="https://myaccident.org/request-free-legal-consultation">Request A Free Legal Consultation</a><br><br>
          <p style="font-size:12px"><a href="https://myaccident.org/">MyAccident.org</a> is not a law firm, nor a lawyer referral service nor is it a substitute for hiring an attorney or law firm and does not provide legal advice. <a href="https://myaccident.org/">MyAccident.org</a> does not endorse or recommend any participating Third-Party Legal Professionals that pay to participate in this advertising. An attorney-client relationship is not formed when calling the number on this site or filling out a form. Services are not available in all states or for all legal categories. All persons depicted in a photo or video are actors or models and not clients of any firm.</p>`
      );
      setSmsText("");
    }
    if (w === 5) {
      setSubject("Unable to find your free accident report - MyAccident.org");
      setEmailText(
        `<b>Search Details:<br>Accident Date: ${moment(alert.startDate).format(
          "llll"
        )} to ${moment(alert.endDate).format("llll")}<br>Accident Location: ${
          alert.city
        }, ${alert.state}${
          alert.vehicle1Maker
            ? `<br>Car Make/Year: ${alert.vehicle1Maker} ${alert.vehicle1Year}`
            : ""
        }${alert.vin ? `<br>VIN: ${alert.vin}` : ""}${
          alert.case_id ? `<br>CASEID: ${alert.case_id}` : ""
        }</b><br><br>
          Dear ${firstName},<br><br>
          We cannot locate the accident report with those parameters in our database, it looks like we’re missing some information from you. We’re sorry that we cannot offer you a free copy of your accident report at this time.<br><br>
          If you have other information that you think can help us find the report, click <a href='https://app.myaccident.org/find-my-report' target='_blank'>here</a> to provide us with more details about your accident.<br>
          If for whatever reason we still can’t locate your report, we’ll reach out with some helpful information about how to get your report.<br><br>
          You might also find the following information helpful::<br>
          <a href="https://myaccident.org/how-the-police-accident-report-will-impact-your-insurance-claim">How your accident will impact your insurance premium.</a><br>
          <a href="https://myaccident.org/protect-your-rights">Protect your rights</a><br>
          <a href="https://myaccident.org/request-free-legal-consultation">Request A Free Legal Consultation</a><br><br>
          <p style="font-size:12px"><a href="https://myaccident.org/">MyAccident.org</a> is not a law firm, nor a lawyer referral service nor is it a substitute for hiring an attorney or law firm and does not provide legal advice. <a href="https://myaccident.org/">MyAccident.org</a> does not endorse or recommend any participating Third-Party Legal Professionals that pay to participate in this advertising. An attorney-client relationship is not formed when calling the number on this site or filling out a form. Services are not available in all states or for all legal categories. All persons depicted in a photo or video are actors or models and not clients of any firm.</p>`
      );
      setSmsText("");
    }
  };

  return (
    <div className={clsx(["modal", "search", { show: alert }, { ajax }])}>
      <button className="close" onClick={() => close()}>
        <MdClose />
      </button>
      <h2>Confirm email</h2>
      <p>Sending notification to {email}</p>
      <p>Email subject: {subject}</p>
      <div style={{ marginTop: 10 }}>
        <button onClick={() => prepareText(1)}>NO Out of State - agency</button>
        {emailData.state ? (
          <button onClick={() => prepareText(4)} style={{ marginLeft: 10 }}>
            NO Out of State - state
          </button>
        ) : null}
        <button onClick={() => prepareText(2)} style={{ marginLeft: 10 }}>
          NO In-State
        </button>
        <button onClick={() => prepareText(3)} style={{ marginLeft: 10 }}>
          Report Located
        </button>
        <button onClick={() => prepareText(5)} style={{ marginLeft: 10 }}>
          Missing information
        </button>
      </div>
      <br />
      <JoditEditor
        value={emailText}
        config={{
          readonly: false, // all options from https://xdsoft.net/jodit/doc/
        }}
        tabIndex={1} // tabIndex of textarea
        onBlur={(newContent) => setEmailText(newContent)} // preferred to use only this option to update the content for performance reasons
        onChange={(newContent) => {}}
      />
      {smsText ? (
        <>
          <br />
          <br />
          <h3>SMS text to: {phone}</h3>
          <textarea
            value={smsText}
            onChange={(e) => setSmsText(e.target.value)}
            rows={10}
          />
        </>
      ) : null}
      <div className="footer">
        <button onClick={() => close()} className="outline">
          Cancel
        </button>
        <button onClick={() => send()}>
          {smsText ? "Send Email & SMS" : "Send Email"}
        </button>
      </div>
    </div>
  );
}
