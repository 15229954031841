import React, { useState, useEffect } from "react";
import { MdClose } from "react-icons/md";
import Axios from "axios";

const smsTemplates = {
  real_time:
    "Hi [FIRST_NAME], this is Ben with MyAccident.org - I got your request to speak with someone about your accident in [CITY], [STATE]. Is now a good time for me to call?",
  no_state:
    "Hi [FIRST_NAME], this is Ben with MyAccident.org - I got your request to speak with someone about an accident. Is now a good time for me to call?",
  after_hours:
    "[FIRST_NAME], this is Ben from MyAccident.org - I got your request to talk to someone about an accident. Would it work for me to call you tomorrow morning or do you need to speak with someone immediately?",
  weekend:
    "Hi [FIRST_NAME], this is Ben from MyAccident.org - I got your request to speak to someone about your accident. Would it work for me to call you Monday morning or do you need to speak with someone immediately?",
  affiliate:
    "Hello [FIRST_NAME], this is Ben with MyAccident. I'm reaching out regarding your request for a free legal consultation about an accident. Is now a good time to call? Respond STOP to cancel the request. Thank you.",
  missed:
    "Hi [FIRST_NAME], I tried to reach you but the call went to voicemail. Do you still want to talk to somebody about your car accident? If you would still like to speak with someone in our help network, feel free to call me directly at (469) 962-6214 or just respond to this text. Thanks.",
  chiro:
    "Hi [FIRST_NAME], this is Ben with MyAccident.org - I got your request to speak with a chiropractor about an accident. To get connected with a chiropractor, please call (484) 285-4648. If you need to speak with a legal consult in regards to your accident, please text this number back and I will get you connected with someone in our help network. Thanks!",
  dui: "Hi [FIRST_NAME], this is Ben with MyAccident.org. We received your request to speak with a DUI lawyer. If you’d still like to get in contact with one for a free evaluation please call 484-271-0720. If you need to speak a legal consult in regards to your accident, please text this number back and I will get you connected with someone in our help network. Thanks!",
  "M&M":
    "Hello [FIRST_NAME], this is Ben with MyAccident.org - we received your request for a free legal consultation. Your request has been assigned to the law firm: MORGAN & MORGAN. Please expect a call from them shortly.",
  "W-A":
    "Hi [FIRST_NAME], this is Ben with MyAccident.org. Please feel free to call 8338156925 for your free legal consultation. Thank you!",
  date: "Hi [FIRST_NAME], what was the date of your accident?",
};

export function capitalizeFirstLetter(str) {
  var splitStr = str.toLowerCase().split(" ");
  for (var i = 0; i < splitStr.length; i++) {
    // You do not need to check if i is larger than splitStr length, as your for does that for you
    // Assign it back to the array
    splitStr[i] =
      splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  // Directly return the joined string
  return splitStr.join(" ");
}

export default function MultiSMS() {
  const [leads, setLeads] = useState([]);
  const [text, setText] = useState("");
  const [ajax, setAjax] = useState(false);

  useEffect(() => {
    window.addEventListener("open-multi-sms", ({ detail }) => {
      setLeads([...detail.leads]);
    });
  }, []);

  const send = () => {
    leads.forEach((l) => {
      l.sending = true;
      l.error = false;
      l.sent = false;
      let firstName = l.firstName || l.name || "";
      let city = l.city || "unknown city";
      let st = l.state || "unknown state";
      const text2 = text
        .replace(/\[FIRST_NAME\]/g, capitalizeFirstLetter(firstName))
        .replace(/\[CITY\]/g, capitalizeFirstLetter(city))
        .replace(/\[STATE\]/g, st.toUpperCase());

      Axios.post("/api/twilio/sms/send", {
        lead_id: l._id,
        text: text2,
      })
        .then((d) => {
          l.sent = true;
          l.sending = false;
          setLeads([...leads]);
          checkSending();
          window.dispatchEvent(
            new CustomEvent("sms-sent", {
              detail: {
                lead: l._id,
                sms_count: l.sms_count + 1,
              },
            })
          );
        })
        .catch((e) => {
          l.error = true;
          l.sending = false;
          setLeads([...leads]);
          checkSending();
        });

    });
    setLeads([...leads]);
  };

  const checkSending = () => {
    let sending = false;
    leads.some((v) => {
      if (v.sending) {
        sending = true;
        return true;
      }
      return false;
    });
    setAjax(sending);
  };

  const close = () => {
    leads.forEach((l) => {
      delete l.sending;
      delete l.sent;
      delete l.error;
    });
    setLeads([]);
  };

  return (
    <div className={leads.length ? "modal show" : "modal"}>
      {!ajax ? (
        <button className="close" onClick={() => close()}>
          <MdClose />
        </button>
      ) : null}
      <h2>Multi SMS</h2>
      Recipients:
      {leads.map((l) => (
        <div key={l._id}>
          {l.error ? <>❌ error, message not sent to </> : null}
          {l.sent ? <>✅ message sent to </> : null}
          {l.sending ? (
            <>
              <img src="img/ajax-loader.gif" alt="Loader" /> sending to{" "}
            </>
          ) : null}
          {capitalizeFirstLetter(l.firstName || l.name || "")} {l.phone}
        </div>
      ))}
      <div className="multi-sms-buttons">
        {Object.keys(smsTemplates).map((t) => (
          <button onClick={() => setText(smsTemplates[t])} key={t}>
            {t.replace("_", " ")}
          </button>
        ))}
      </div>
      <textarea
        placeholder="Type message here"
        value={text}
        onChange={(e) => setText(e.target.value)}
        disabled={ajax}
        rows={4}
      ></textarea>
      * use [FIRST_NAME] as variable for the first name
      <br />
      * use [CITY] as variable for the city
      <br />
      * use [STATE] as variable for the state
      <br />
      <br />
      <div className="footer">
        <button onClick={() => close()} className="outline" disabled={ajax}>
          Cancel
        </button>
        <button onClick={() => send()} disabled={!text || ajax}>
          Send
        </button>
      </div>
    </div>
  );
}
